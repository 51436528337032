import { FormikProps, getIn } from 'formik';
import React, {
  FC, useCallback, useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { MasterServiceDtoType } from 'config/apiFunus/generated/data-contracts';
import { useFeatures } from 'hooks/useFeatures/useFeatures';
import MasterService, {
  SaveService,
} from 'models/MasterService';
import { RootState } from 'store';

import SidebarButton from './SidebarButton';

import './index.scss';

type ServiceSidebarProps = {
  formikProps: FormikProps<SaveService>;
  onChangeService(service: MasterService): void;
  selected?: MasterService | undefined;
  erpId?: number;
  serviceInfo: SaveService;
};

const errorEquivalent = {
  [MasterServiceDtoType.CEREMONIA]: 'ceremonyServiceDto',
  [MasterServiceDtoType.INCINERACION]: 'cremationServiceDto',
  [MasterServiceDtoType.DEPOSIT]: 'depositServiceDto',
  [MasterServiceDtoType.INHUMACION]: 'intermentServiceDto',
  [MasterServiceDtoType.PREPARACION]: 'preparationServiceDto',
  [MasterServiceDtoType.TRANSPORTE]: 'transferServiceDto',
  [MasterServiceDtoType.VELATORIO]: 'vigilServiceDto',
  [MasterServiceDtoType.MOVIMIENTO]: 'movementServiceDto',
};

const ServiceSidebar: FC<ServiceSidebarProps> = ({
  formikProps,
  onChangeService,
  selected,
  serviceInfo,
  erpId,
}) => {
  const { t } = useTranslation();
  const { canViewFlowers } = useFeatures();
  const history = useHistory();
  const { errors } = formikProps;
  const { masterData } = useSelector((state: RootState) => state.masterData);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [serviceStatus, setServiceStatus] = useState<any>({
    ceremonyServiceDto: false,
    cremationServiceDto: false,
    depositServiceDto: false,
    intermentServiceDto: false,
    movementServiceDto: false,
    preparationServiceDto: false,
    transferServiceDto: false,
    vigilServiceDto: false,
  });

  useEffect(
    () => {
      setServiceStatus({
        ceremonyServiceDto: !!serviceInfo?.ceremonyServiceDto,
        cremationServiceDto: !!serviceInfo?.cremationServiceDto,
        depositServiceDto: !!serviceInfo?.depositServiceDto,
        intermentServiceDto: !!serviceInfo?.intermentServiceDto,
        movementServiceDto: !!serviceInfo?.movementServiceDto,
        preparationServiceDto: !!serviceInfo?.preparationServiceDto,
        transferServiceDto: !!serviceInfo?.transferServiceDto,
        vigilServiceDto: !!serviceInfo?.vigilServiceDto,
      });
    },
    [
      serviceInfo?.ceremonyServiceDto,
      serviceInfo?.cremationServiceDto,
      serviceInfo?.depositServiceDto,
      serviceInfo?.intermentServiceDto,
      serviceInfo?.movementServiceDto,
      serviceInfo?.preparationServiceDto,
      serviceInfo?.transferServiceDto,
      serviceInfo?.vigilServiceDto],
  );

  const gotoRecordRequests = useCallback(
    () => {
      history.push(`/articles/flowers/${erpId}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [erpId],
  );

  useEffect(() => {
    if (!selected && masterData?.length) {
      onChangeService(masterData[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData, selected]);

  return (
    <div className="service-sidebar">
      {masterData
        && masterData.length > 0
        && masterData.map((serv: MasterService) => (
          <SidebarButton
            key={serv.type}
            errors={
              errors && getIn(errors, errorEquivalent[serv.type])
                ? Object.keys(getIn(errors, errorEquivalent[serv.type]))?.length
                : 0
            }
            icon={<CustomIcon icon={serv.type} />}
            isInUseService={serviceStatus[errorEquivalent[serv.type]]}
            selected={selected?.type === serv.type}
            text={t(`service.${serv.type}`)}
            onClick={() => onChangeService(serv)}
          />
        ))}
      {canViewFlowers && (
      <SidebarButton
        key="record-article-requests"
        errors={0}
        icon={<CustomIcon icon={OtherTypes.FLOWERS} />}
        selected={false}
        text={t('article.flowers.showAllFlowerRequest')}
        onClick={() => gotoRecordRequests()}
      />
      )}
    </div>
  );
};

export default ServiceSidebar;
