import React, {
  FC,
  Fragment,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';

import Textarea from 'components/base/Textarea';

import { DashboardEnquiry } from 'models/Enquiry';

import './index.scss';

type FinishEnquiryProps = {
  placeholder?: string;
  selectClassName?: string;
  setComment: Dispatch<SetStateAction<string>>;
  testId?: string;
  title?: string;
  enquiry: DashboardEnquiry;
};

const FinishEnquiry: FC<FinishEnquiryProps> = ({
  setComment,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Textarea
        aria-label="finish enquiry comment"
        maxLength={100}
        name="finish enquiry comment"
        placeholder={t('common.comment')}
        onChange={(val: string) => setComment(val)}
      />

    </Fragment>
  );
};

export default FinishEnquiry;
