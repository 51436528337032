import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast } from 'utils/toasts';

import { adviceController } from 'config/apiFunus';
import { Client, MasterDataDto, MasterServiceDtoType } from 'config/apiFunus/generated/data-contracts';
import { Event } from 'models/Event';

import { AdviceHook } from './types';

export const useAdvice = (): AdviceHook => {
  const [clients, setClients] = useState<MasterDataDto[]>([]);
  const [loadingServiceInfo, setLoadingServiceInfo] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [serviceInfo, setServiceInfo] = useState<any>();
  const { t } = useTranslation();
  const [events, setEvents] = useState<Event[]>([]);
  const [eventsLoading, seteventsLoading] = useState<boolean>(false);

  const fetchClients = useCallback(
    async (
      id: number,
    ) => {
      await adviceController
        .getAdviceDataUsingGet(id)
        .then((response) => {
          const excludeInvalidClients = (
            client: Client,
          ) => client.identifier !== null && client.identifier !== 'A00000000';
          const mapToMasterData = (client: Client) => ({
            code: client.identifier,
            description: client.name,
          });

          const clientsList = response.clients
            ? response.clients?.filter(excludeInvalidClients)
              .map(mapToMasterData)
            : [];
          setClients(clientsList);

          return response;
        })
        .catch((error) => error);
    },
    [],
  );

  const fetchServiceInfo = useCallback(
    async (serviceId, postCeremonyId) => {
      setLoadingServiceInfo(true);
      await adviceController
        .getServiceInfo(serviceId, postCeremonyId)
        .then((response) => {
          setServiceInfo(response);

          return response;
        })
        .catch((error) => {
          showErrorToast(t(error.message));
        })
        .finally(() => {
          setLoadingServiceInfo(false);
        });
    },
    [t],
  );
  const fetchEvents = useCallback(async (date: Date, serviceTypes: MasterServiceDtoType[]) => {
    seteventsLoading(true);
    await config.apiFunus.adviser
      .getCalendar(
        `0${date.getMonth() + 1}`.slice(-2),
        date.getFullYear().toString(),
        serviceTypes,
      )
      .then((res) => {
        seteventsLoading(false);
        setEvents(res?.data?.items);
        return res;
      })
      .finally(() => {
        seteventsLoading(false);
      });
  }, []);

  return {
    clients,
    events,
    eventsLoading,
    fetchClients,
    fetchEvents,
    fetchServiceInfo,
    loadingServiceInfo,
    serviceInfo,
  };
};
