import {
  checkHaveAtLeastOnePermission,
  checkHaveThisPermissions,
  checkPermission,
} from 'utils/permissions';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { StateType } from 'hooks/useProvidedAuth';

export const dispatchTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_LANDING_PAGE: 'SET_LANDING_PAGE',
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
};

export const initialState: StateType = {
  loading: true,
  landingPage: undefined,
  roleInfo: {
    isOperator: false,
    isWorkshopAdmin: false,
    isAdmin: false,
  },
  user: undefined,
};

type Action = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any | undefined;
};

export const reducer = (
  state: StateType = initialState,
  action: Action,
): StateType => {
  const { type, payload } = action;
  switch (type) {
    case dispatchTypes.SET_LOADING:
      return { ...state, loading: payload };
    case dispatchTypes.SET_LANDING_PAGE:
      return { ...state, landingPage: payload };
    case dispatchTypes.SET_USER:
      return {
        ...state,
        user: payload,
        roleInfo: {
          isOperator:
            checkHaveThisPermissions(
              [
                SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ,
                SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_WRITE,
              ],
              payload?.role?.permissions,
            )
            || checkHaveThisPermissions(
              [
                SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ,
                SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_WRITE,
              ],
              payload?.role?.permissions,
            ),
          isWorkshopAdmin:
            checkHaveThisPermissions(
              [
                SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ,
                SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_WRITE,
                SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_ADD,
              ],
              payload?.role?.permissions,
            )
            || checkHaveThisPermissions(
              [
                SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ,
                SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_WRITE,
                SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_ADD,
              ],
              payload?.role?.permissions,
            ),
          isAdmin:
            checkPermission(
              SimpleUserRequestPermissionNames.USER_READ,
              payload?.role?.permissions,
            )
            && checkHaveAtLeastOnePermission(
              [
                SimpleUserRequestPermissionNames.USER_WRITE,
                SimpleUserRequestPermissionNames.USER_ADD,
              ],
              payload?.role?.permissions,
            ),
        },
      };
    case dispatchTypes.REMOVE_USER:
      return { ...state, user: undefined };
    default:
      return state;
  }
};
