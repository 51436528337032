import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import './index.scss';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { sortedQuestions } from 'modules/budget/data/sortedQuestions';
import { BudgetHelper } from 'modules/budget/helpers/BudgetHelper';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { QBackButtonComponent } from './types';

const QBackButton: FC<QBackButtonComponent> = (
  { disabled, question },
) => {
  const { t } = useTranslation();
  const { GoPrevQuestion, questions, collected } = useBudget();

  const inBudget = useMemo(() => {
    const budgetHelper = new BudgetHelper(sortedQuestions);

    return budgetHelper
      .availableQuestions(questions, collected).includes(question);
  }, [collected, question, questions]);

  return (
    <React.Fragment>
      {inBudget && (
      <Button
        className="q-back-button"
        disabled={disabled}
        leftAddon={<CustomIcon icon={OtherTypes.MOVE_BACK} />}
        text={t('budget.wizard.backButton')}
        onClick={GoPrevQuestion}
      />
      )}
    </React.Fragment>
  );
};

QBackButton.defaultProps = {
  disabled: false,
};

export { QBackButton };
