export const initialInsuranceData = {
  agent: null,
  amount: null,
  companyDelegation: null,
  maxCoverage: null,
  personalObjects: null,
  policyNumber: null,
  renounce: false,
  sinister: null,
};
