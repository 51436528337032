import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import Select from 'components/base/Select';

import './index.scss';
import { useWorkshopTvView } from 'hooks/useWorkshopTvView';

type PriorityOption = {
  label: string;
  value: string;
};

type ButtonsState = {
  state: number;
  setState: (x: number) => void;
};

type PriorityState = {
  state: PriorityOption;
  setState: (x: PriorityOption) => void;
};

type SelectOrderMobileProps = {
  buttonsState: ButtonsState;
  priorityState: PriorityState;
  options: Array<PriorityOption>;
};

const SelectOrderMobile: FC<SelectOrderMobileProps> = ({
  buttonsState,
  priorityState,
  options,
}) => {
  const { t } = useTranslation();
  const { isTvView } = useWorkshopTvView();

  return (
    <div className="div-select-main">
      <h4>{t('order.workOrders')}</h4>
      <div className="div-status">
        <Button
          className={classNames({ active: buttonsState.state === 1 })}
          color="transparent"
          onClick={() => buttonsState.setState(1)}
        >
          {t('order.status.pending')}
        </Button>
        <Button
          className={classNames({ active: buttonsState.state === 2 })}
          color="transparent"
          onClick={() => buttonsState.setState(2)}
        >
          {t('order.status.inProgress')}
        </Button>
        {!isTvView && (
        <Button
          className={classNames({ active: buttonsState.state === 3 })}
          color="transparent"
          onClick={() => buttonsState.setState(3)}
        >
          {t('order.status.completed')}
        </Button>
        )}
      </div>
      <div className="div-select">
        <Select
          data-testid="priority-order-selector"
          getLabel={(option) => option.label}
          getValue={(option) => option.value}
          name="priority-order-selector"
          options={options}
          placeholder={t('order.selectPriority')}
          value={priorityState.state}
          onChange={(option) => priorityState.setState(option as PriorityOption)}
        />
      </div>
    </div>
  );
};

export default SelectOrderMobile;
