import classNames from 'classnames';
import { ca, es } from 'date-fns/locale';
import React, {
  cloneElement,
  FC,
  ForwardedRef,
  ReactElement,
  useState,
} from 'react';
import DatepickerLibrary, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { formatHour, removeTimezone, parseHour } from 'utils/dateManager';

import useWindowSize from 'hooks/useWindowSize';
import { i18n } from 'i18n';
import { Pick } from 'typings/helper';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

registerLocale('ca', ca);
registerLocale('es', es);

export type DatepickerProps = Pick<
  ReactDatePickerProps,
  Exclude<keyof ReactDatePickerProps, 'onChange'>
> & {
  className?: string | undefined;
  clearable?: boolean | undefined;
  error?: string | boolean | undefined;
  innerRef?: ForwardedRef<HTMLDivElement> | undefined;
  leftAddon?: ReactElement | undefined;
  name: string;
  onChange?(
    date: Date | [Date, Date] /* for selectsRange */ | string | null
  ): void | undefined;
  placeholder?: string | undefined;
  rightAddon?: ReactElement | undefined;
  selected?: string | Date | null;
};

const Datepicker: FC<DatepickerProps> = ({
  className,
  clearable,
  disabled,
  error,
  innerRef,
  leftAddon,
  maxDate,
  minDate,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  rightAddon,
  selected,
  showMonthYearPicker,
  showFullMonthYearPicker,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals = 15,
}) => {
  const [focused, setFocused] = useState(false);
  const { lgOrSmaller } = useWindowSize();
  const { t } = useTranslation();
  const locale = i18n.language.substring(0, 2);

  const getFormat = () => {
    let res = 'dd/MM/yyyy';

    if (showTimeSelectOnly) {
      return 'HH:mm';
    }

    if (showMonthYearPicker) {
      return 'MM/yyyy';
    }

    if (showTimeSelect) {
      res = `${res} - HH:mm`;
    }

    return res;
  };

  const change = (date: Date | [Date, Date] | null) => {
    if (onChange) {
      if (showTimeSelectOnly && date) {
        onChange(formatHour(date as Date));
      } else if (Array.isArray(date)) {
        onChange(date);
      } else {
        onChange(date ? removeTimezone(date as Date) : date);
      }
    }
  };

  return (
    <div
      ref={innerRef}
      className={classNames(
        'funus-datepicker',
        {
          disabled,
          error: !!error,
          filled: !!selected,
          focused,
          prefixed: !!leftAddon,
        },
        className,
      )}
    >
      {placeholder && <label htmlFor={name}>{placeholder}</label>}
      <div>
        <div>
          {leftAddon
            && cloneElement(leftAddon, {
              className: classNames(
                'input-prefix',
                { disabled },
                leftAddon.props.className,
              ),
            })}
          <DatepickerLibrary
            autoComplete="new-password"
            dateFormat={getFormat()}
            disabled={disabled || false}
            dropdownMode="select"
            id={name}
            isClearable={clearable || false}
            locale={locale}
            maxDate={maxDate}
            minDate={minDate}
            name={name}
            readOnly={readOnly}
            selected={
              showTimeSelectOnly
                ? parseHour(selected as string)
                : (selected && new Date(selected)) || null
            }
            showFullMonthYearPicker={showFullMonthYearPicker}
            showMonthYearPicker={showMonthYearPicker}
            showTimeSelect={showTimeSelectOnly}
            showTimeSelectOnly={showTimeSelectOnly}
            timeInputLabel={t('common.hour')}
            timeIntervals={timeIntervals}
            withPortal={lgOrSmaller}
            wrapperClassName={className}
            onBlur={(e) => {
              if (!disabled) {
                setFocused(false);

                if (onBlur) {
                  onBlur(e);
                }
              }
            }}
            onChange={change}
            onFocus={(e) => {
              setFocused(true);
              if (onFocus) {
                onFocus(e);
              }
            }}
          />
          {rightAddon
            && cloneElement(rightAddon, {
              className: classNames(
                'input-suffix',
                { disabled },
                rightAddon.props.className,
              ),
            })}
        </div>
        <div className="bottom-line" />
      </div>
      <span className={classNames({ hidden: !error })}>{error}</span>
    </div>
  );
};

export default Datepicker;
