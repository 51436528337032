import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, {
  Fragment, FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import config from 'config';

import { formatDateForSearch, getAgeLiteral } from 'utils/dateManager';
// import { identifiersValidator } from 'utils/userUtils';

import Datepicker from 'components/base/Datepicker';
import IdentifierSelector, { IdentifierTypes } from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
// import Loader from 'components/base/Loader';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import { useEnv } from 'hooks/useEnv';
import { CityType, IMasterDataDto } from 'models/MasterData';
import { PersonalDataDocumentDto } from 'models/PersonalDataDocumentDto';
import { SaveRecord } from 'models/Record';
import { IaDocumentScanner } from 'modules/ia/components/document-scanner';
import { useIA } from 'modules/ia/hooks/useIa';
import { RootState } from 'store';

import { COUNTRY_CODE_SPAIN } from '../../../hooks/useCountries/constants';

import { mapBase64ToPersonalDataDocument } from './mapBase64ToPersonalDataDocument';
import { mapDocumentTypeCode } from './mapDocumentTypeCode';

const today = new Date();

type DeceasedFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

const DeceasedFields: FC<DeceasedFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values, setValues,
  },
}) => {
  const { iaEnableScanDocument } = useEnv();
  const { isEnabledDocumentType } = useIA();
  const
    [documentType,
      setdocumentType] = useState<IdentifierTypes | null>(IdentifierTypes.IDENTIFIER);

  const { genders, maritalStatus } = useSelector(
    (state: RootState) => state.masterData,
  );
  const { normalized } = useSelector((state: RootState) => state);
  const { t } = useTranslation();

  const changeBirthday = (birthDate: string) => {
    setFieldValue('deceasedData.birthday', birthDate);
  };

  useEffect(() => {
    const birthDate = formatDateForSearch(values.deceasedData?.birthday);
    const deathDate = formatDateForSearch(values.deathData?.deathDate);
    setFieldValue(
      'deceasedData.age',
      birthDate && deathDate
        ? getAgeLiteral(birthDate, deathDate)
        : '',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.deceasedData?.birthday, values.deathData?.deathDate]);

  const onChangeIdentifier = (key: string, val: string) => {
    setFieldValue(`deceasedData.${key}`, val);
    setFieldValue('deceasedDataDocumentDto', null);
  };

  const onChangeCountry = (val: string) => {
    setFieldValue('deceasedData.birthAddress.country', val);
    if (val === COUNTRY_CODE_SPAIN) {
      setFieldValue('deceasedData.nationality', 'ESPANYOLA');
    }
  };

  return (
    <Fragment>
      {/* {loading && <Loader fullscreen />} */}
      <fieldset>
        <legend>{t('record.deceasedData.holder')}</legend>
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deceasedData.name')}
          name="deceasedData.name"
          placeholder={t('common.name')}
          type="text"
          value={values.deceasedData?.name || ''}
          onBlur={handleBlur}
          onChange={(val) => {
            setFieldValue('deceasedData.name', val);
            setFieldValue(
              'deceasedData.nickname',
              classNames(
                val,
                values.deceasedData?.firstSurname,
                values.deceasedData?.secondSurname,
              ),
            );
          }}
        />
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deceasedData.firstSurname')}
          name="deceasedData.firstSurname"
          placeholder={t('common.firstSurname')}
          type="text"
          value={values.deceasedData?.firstSurname || ''}
          onBlur={handleBlur}
          onChange={(val) => {
            setFieldValue('deceasedData.firstSurname', val);
            setFieldValue(
              'deceasedData.nickname',
              classNames(
                values.deceasedData?.name,
                val,
                values.deceasedData?.secondSurname,
              ),
            );
          }}
        />
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deceasedData.secondSurname')}
          name="deceasedData.secondSurname"
          placeholder={t('common.secondSurname')}
          type="text"
          value={values.deceasedData?.secondSurname || ''}
          onBlur={handleBlur}
          onChange={(val) => {
            setFieldValue('deceasedData.secondSurname', val);
            setFieldValue(
              'deceasedData.nickname',
              classNames(
                values.deceasedData?.name,
                values.deceasedData?.firstSurname,
                val,
              ),
            );
          }}
        />
        {!createMode && (
          <Input
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.nickname')}
            name="deceasedData.nickname"
            placeholder={t('record.deceasedData.nickname')}
            type="text"
            value={values.deceasedData?.nickname || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.nickname', val)}
          />
        )}
        {!createMode && (
          <div style={{ display: 'flex' }}>
            <IdentifierSelector
              disabled={disabled || isSubmitting}
              errors={{
                identifier: getIn(errors, 'deceasedData.identifier'),
                nie: getIn(errors, 'deceasedData.nie'),
                passport: getIn(errors, 'deceasedData.passport'),
              }}
              name="deceasedData.identifier"
              placeholder={t('common.identifierDocument')}
              showCif={false}
              type="text"
              values={{
                identifier: values.deceasedData?.identifier,
                nie: values.deceasedData?.nie,
                passport: values.deceasedData?.passport,
              }}
              onBlur={handleBlur}
              onChange={onChangeIdentifier}
              onSelectChange={(v) => {
                if (v && isEnabledDocumentType(v as IdentifierTypes)) {
                  setdocumentType(v as IdentifierTypes);
                } else {
                  setdocumentType(null);
                }
              }}
            />
            {documentType && iaEnableScanDocument() && (
            <IaDocumentScanner
              documentType={documentType}
              infoFormlayout="deceased"
              onConfirm={(iaData, fileType) => {
                const doc: PersonalDataDocumentDto = {
                  backDocument: iaData.backCapture ? mapBase64ToPersonalDataDocument(iaData.backCapture, fileType) : '',
                  documentName: `DIFUNT_${mapDocumentTypeCode(documentType)}_${iaData.idCard?.toUpperCase()}`,
                  frontDocument: iaData.frontCapture ? mapBase64ToPersonalDataDocument(iaData.frontCapture, fileType) : '',
                  mediaType: fileType,
                  type: documentType.toUpperCase(),
                };

                let deceasedDataBirthAddress = values.deceasedData?.birthAddress;
                if (iaData.birthAddress.city
                    || iaData.birthAddress.country
                    || iaData.birthAddress.province
                ) {
                  deceasedDataBirthAddress = {
                    ...deceasedDataBirthAddress,
                    city: iaData.birthAddress.city
                        || values.deceasedData?.birthAddress?.city,
                    country: iaData.birthAddress.country
                        || values.deceasedData?.birthAddress?.country,
                    province: iaData.birthAddress.province
                        || values.deceasedData?.birthAddress?.province,
                  };
                }

                let deceasedDataDeceasedAddress = values.deceasedData?.deceasedAddress;
                if (iaData.address.city
                  || iaData.address.country
                  || iaData.address.province
                ) {
                  deceasedDataDeceasedAddress = {
                    ...deceasedDataDeceasedAddress,
                    city: iaData.address.city
                      || values.deceasedData?.deceasedAddress?.city,
                    country: iaData.address.country
                      || values.deceasedData?.deceasedAddress?.country,
                    province: iaData.address.province
                      || values.deceasedData?.deceasedAddress?.province,
                  };
                }

                setValues({
                  ...values,
                  deceasedData: {
                    ...values.deceasedData,
                    birthAddress: deceasedDataBirthAddress,
                    birthday: iaData.birthDate || values.deceasedData?.birthday,
                    deceasedAddress: deceasedDataDeceasedAddress,
                    [documentType]: iaData.idCard,
                    fatherName: iaData.firstParent || values.deceasedData?.fatherName || '',
                    firstSurname: iaData.firstSurname || values.deceasedData?.firstSurname || '',
                    gender: iaData.genre || values.deceasedData?.gender,
                    motherName: iaData.secondParent || values.deceasedData?.motherName || '',
                    name: iaData.name || values.deceasedData?.name || '',
                    nationality: iaData.nationality || values.deceasedData?.nationality,
                    secondSurname: iaData.secondSurname || values.deceasedData?.secondSurname || '',
                  },
                  deceasedDataDocumentDto: doc,
                });
              }}
            />
            )}

          </div>
        )}
        {!createMode && (
          <Select<IMasterDataDto>
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.gender')}
            getLabel={({ description }) => description}
            getValue={({ code }) => code}
            name="deceasedData.gender"
            options={genders}
            placeholder={t('common.gender')}
            value={
              values.deceasedData?.gender
                ? normalized.genders[values.deceasedData?.gender]
                : undefined
            }
            searchable
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.gender', val?.code)}
          />
        )}
      </fieldset>
      <fieldset>
        {!createMode && <legend>{t('record.deceasedData.birth')}</legend>}
        {!createMode && (
          <Datepicker
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.birthday')}
            maxDate={today}
            name="deceasedData.birthday"
            placeholder={t('common.birthday')}
            selected={values.deceasedData?.birthday}
            onBlur={handleBlur}
            onChange={changeBirthday}
          />
        )}
        {!createMode && (
          <Input
            name="deceasedData.age"
            placeholder={t('common.age')}
            value={values.deceasedData?.age}
            disabled
          />
        )}
        {!createMode && (
          <SelectCountryProvinceCity
            cityProps={{
              error: getIn(errors, 'deceasedData.birthAddress.city'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'deceasedData.birthAddress-c',
              onBlur: handleBlur,
              onChange: (val: string) => setFieldValue('deceasedData.birthAddress.city', val),
              placeholder: t('common.city'),
              value: values.deceasedData?.birthAddress?.city || '',
            }}
            countryProps={{
              error: getIn(errors, 'deceasedData.birthAddress.country'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'deceasedData.birthAddress-co',
              onBlur: handleBlur,
              onChange: onChangeCountry,
              placeholder: t('common.country'),
              value: values.deceasedData?.birthAddress?.country || '',
            }}
            disabled={disabled || isSubmitting}
            initialize={false}
            provinceProps={{
              error: getIn(errors, 'deceasedData.birthAddress.province'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'deceasedData.birthAddress.p',
              onBlur: handleBlur,
              onChange: (val: string) => setFieldValue('deceasedData.birthAddress.province', val),
              placeholder: t('common.province'),
              value: values.deceasedData?.birthAddress?.province || '',
            }}
          />
        )}
        {!createMode && (
          <Input
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.fatherName')}
            name="deceasedData.fatherName"
            placeholder={t('record.deceasedData.fatherName')}
            type="text"
            value={values.deceasedData?.fatherName || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.fatherName', val)}
          />
        )}
        {!createMode && (
          <Input
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.motherName')}
            name="deceasedData.motherName"
            placeholder={t('record.deceasedData.motherName')}
            type="text"
            value={values.deceasedData?.motherName || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.motherName', val)}
          />
        )}
        {!createMode && (
          <Input
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.nationality')}
            name="deceasedData.nationality"
            placeholder={t('record.deceasedData.nationality')}
            type="text"
            value={values.deceasedData?.nationality || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.nationality', val)}
          />
        )}
      </fieldset>
      {!createMode && (
        <fieldset>
          <legend>{t('record.deceasedData.maritalStatus')}</legend>
          <Select<IMasterDataDto>
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.maritalStatus')}
            getLabel={({ description }) => description}
            getValue={({ code }) => code}
            name="deceasedData.maritalStatus"
            options={maritalStatus}
            placeholder={t('record.deceasedData.maritalStatus')}
            value={
              values.deceasedData?.maritalStatus
                ? normalized.maritalStatus[values.deceasedData?.maritalStatus]
                : undefined
            }
            searchable
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.maritalStatus', val?.code)}
          />
          <Textarea
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.partnerName')}
            name="deceasedData.partnerName"
            placeholder={t('record.deceasedData.partnerName')}
            value={values.deceasedData?.partnerName || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.partnerName', val)}
          />
          <Textarea
            disabled={isSubmitting || disabled}
            error={getIn(errors, 'deceasedData.childrenNames')}
            name="deceasedData.childrenNames"
            placeholder={t('record.deceasedData.childrenNames')}
            value={values.deceasedData?.childrenNames || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.childrenNames', val)}
          />
        </fieldset>
      )}
      {!createMode && (
        <fieldset>
          <legend>{t('record.deceasedData.home')}</legend>
          <SelectCountryProvinceCity
            cityProps={{
              error: getIn(errors, 'deceasedData.deceasedAddress.city'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'deceasedData.deceasedAddress.c',
              onBlur: handleBlur,
              onChange: (val: string) => setFieldValue('deceasedData.deceasedAddress.city', val),
              onChangeFull: (val?: CityType) => setFieldValue(
                'deceasedData.deceasedAddress.postalCode',
                val?.postalCode,
              ),
              placeholder: t('common.city'),
              value: values.deceasedData?.deceasedAddress?.city || '',
            }}
            countryProps={{
              error: getIn(errors, 'deceasedData.deceasedAddress.country'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'deceasedData.deceasedAddress.co',
              onBlur: handleBlur,
              onChange: (val: string) => setFieldValue('deceasedData.deceasedAddress.country', val),
              placeholder: t('common.country'),
              value: values.deceasedData?.deceasedAddress?.country || '',
            }}
            disabled={disabled || isSubmitting}
            initialize={false}
            provinceProps={{
              error: getIn(errors, 'deceasedData.deceasedAddress.province'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'deceasedData.deceasedAddress.p',
              onBlur: handleBlur,
              onChange: (val: string) => setFieldValue('deceasedData.deceasedAddress.province', val),
              placeholder: t('common.province'),
              value: values.deceasedData?.deceasedAddress?.province || '',
            }}
          />
          <Textarea
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.deceasedAddress.streetName')}
            name="deceasedData.deceasedAddress.streetName"
            placeholder={t('common.domicile')}
            value={values.deceasedData?.deceasedAddress?.streetName || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.deceasedAddress.streetName', val)}
          />
          <Input
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.deceasedAddress.postalCode')}
            name="deceasedData.deceasedAddress.postalCode"
            placeholder={t('common.postalCode')}
            type="text"
            value={values.deceasedData?.deceasedAddress?.postalCode || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.deceasedAddress.postalCode', val)}
          />
          <Input
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deceasedData.phone')}
            name="deceasedData.phone"
            placeholder={t('common.phone')}
            type="text"
            value={values.deceasedData?.phone || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deceasedData.phone', val)}
          />
        </fieldset>
      )}
    </Fragment>
  );
};

export default DeceasedFields;
