/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
/* eslint-env browser */

import { APPLICATION_KEYS, BACKEND_ORIGIN } from './constants.js';
import { EncryptionFactory } from './encryption/encryption-factory.js';
import { PushClient } from './push-client.js';

export class AppController {
  constructor(onStateChange, onSubscriptionUpdate, errorHandler, successHandler) {
    this._encryptionHelper = EncryptionFactory.generateHelper();
    this.errorHandler = errorHandler;

    this._pushClient = new PushClient(
      onStateChange,
      onSubscriptionUpdate,
      APPLICATION_KEYS.publicKey,
      errorHandler,
      successHandler,
    );
  }

  subscribeDevice(userId) {
    return this._pushClient.subscribeDevice(userId);
  }

  unsubscribeDevice(userId) {
    return this._pushClient.unsubscribeDevice(userId);
  }

  registerServiceWorker() {
    // Check that service workers are supported
    if ('serviceWorker' in navigator) {
      return navigator.serviceWorker.register('./service-worker.js')
        .catch((err) => {
          this.errorHandler(err);
          this.showErrorMessage(
            'Unable to Register SW',
            'Sorry this demo requires a service worker to work and it '
                + 'failed to install - sorry :(',
          );
        });
    }
    return Promise.resolve().then(() => {
      this.showErrorMessage(
        'Service Worker Not Supported',
        'Sorry this demo requires service worker support in your browser. '
            + 'Please try this demo in Chrome or Firefox Nightly.',
      );
      return null;
    });
  }

  _subscriptionUpdate(subscription) {
    this._currentSubscription = subscription;
  }

  toBase64(arrayBuffer, start, end) {
    const partialBuffer = new Uint8Array(arrayBuffer
      .slice(start || 0, end || arrayBuffer.byteLength));
    return window.btoa(String.fromCharCode.apply(null, partialBuffer));
  }

  toHex(arrayBuffer) {
    return [...new Uint8Array(arrayBuffer)]
      .map((x) => x.toString(16).padStart(2, '0'))
      .join(' ');
  }

  showErrorMessage(title, message) {
    this.errorHandler(`${title}: ${message}`);
  }
}
