import { IMasterDataDto } from 'models/MasterData';
import { NormalizedEntity, NormalizedType } from 'models/MasterService';

export const normalizeMasterData = (
  masterData: IMasterDataDto[],
): NormalizedType => {
  const normalized: NormalizedType = {};
  if (masterData?.length) {
    masterData.forEach((data: IMasterDataDto) => {
      normalized[data.code] = data;
    });
  }

  return normalized;
};

export const normalizeEntity = <T>(
  masterData: T[],
  key: keyof T,
): NormalizedEntity<T> => {
  const normalized: NormalizedEntity<T> = {};
  if (masterData?.length) {
    masterData.forEach((data: T) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      normalized[data[key] as any] = data;
    });
  }

  return normalized;
};

export const normalizeFunction = <T>(
  masterData: T[],
  getKey: (elem: T) => string,
): NormalizedEntity<T> => {
  const normalized: NormalizedEntity<T> = {};
  if (masterData?.length) {
    masterData.forEach((data: T) => {
      const key = getKey(data);
      normalized[key] = data;
    });
  }

  return normalized;
};
