import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import Input from 'components/base/Input';
import { Row } from 'components/base/Row';
import Tooltip from 'components/base/Tooltip';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { ArticleAmountComponent } from './types';
import { useArticleAmountValidation } from './useArticleAmountValidation';

import './index.scss';

export const ArticleAmount: FC<ArticleAmountComponent> = ({ article, onUpdated, onCancel }) => {
  const [amount, setAmount] = useState<number>();
  const { t } = useTranslation();
  const { validate } = useArticleAmountValidation();
  const { SetArticleAmount } = useBudget();

  useEffect(
    () => {
      setAmount(article.amount || 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className="f-article-amount">
      <Formik
        initialValues={{
          amount,
        }}
        validationSchema={validate}
        enableReinitialize
        validateOnChange
        onSubmit={(values) => {
          if (values.amount && article.id && article.type) {
            SetArticleAmount(
              article.id,
              article.type,
              values.amount,
            );
            onUpdated();
          }
          return Promise.resolve();
        }}
      >
        {({
          setFieldValue, values, errors, isValid,
        }: FormikProps<{amount?: number}>) => (
          <Form autoComplete="off">
            <Row justifyContent="flex-end" style={{ alignItems: 'center' }}>
              <Tooltip
                key="cancel-change-amount"
                content={t('common.cancel')}
              >
                <Button
                  color="transparent"
                  type="button"
                  onClick={onCancel}
                >
                  <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />
                </Button>
              </Tooltip>
              <Input
                error={errors.amount}
                id="price"
                name="price"
                placeholder={t('article.amount')}
                type="text"
                value={values.amount?.toString().replace('.', ',')}
                onBlur={() => false}
                onChange={(val) => setFieldValue('amount', val)}
              />
              <Tooltip
                key="confirm-price"
                content={t('budget.wizard.summary.confirmAmount')}
              >
                <Button
                  color="transparent"
                  disabled={!isValid}
                  type="submit"
                >
                  <CustomIcon icon={OtherTypes.ACCEPT_FLOWERS_REQUEST} />
                </Button>
              </Tooltip>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
