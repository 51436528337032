import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { ShoppingBag } from 'react-feather';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import './index.scss';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import { Row } from 'components/base/Row';

import { questionsWithMinInvoiceAmount } from 'modules/budget/data/questionsWithMinInvoiceAmount';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { ArticleType } from 'modules/budget/models/Article/types';
import { IBudgetSumarizeArticle } from 'modules/budget/models/BudgetSumarizeArticle/types';

import { priceFormat } from '../budgetSummary/tools';
import { LoadingItems } from '../commons/LoadingItems';

export const BudgetCart: FC<Record<string, unknown>> = () => {
  const { t } = useTranslation();
  const [expand, setexpand] = useState<boolean>(false);
  const {
    summarize,
    summarizeLoading,
    DiscardArticle,
  } = useBudget();

  const discardArticle = useCallback(
    (article) => {
      DiscardArticle({
        amount: article.amount,
        code: article.code,
        description: article.description,
        price: article.price,
        questionCode: article.questionCode || undefined,
        subType: article.subType,
        type: article.type,
        vat: article.vat,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return ((
    <div className="f-budget-cart">
      <Button
        className="f-exit-wizard-button"
        onClick={() => setexpand(!expand)}
      >
        <ShoppingBag />
        <span>{summarize?.articles.length}</span>
      </Button>
      <div className="f-budget-cart-container">

        <div className={classNames('f-budget-cart-content', { 'f-budget-cart-content-visible': expand })}>
          {!summarizeLoading
            ? (
              <React.Fragment>
                <div className="f-budget-cart-detail">
                  {summarize?.articles
                    .map(
                      (article: IBudgetSumarizeArticle) => {
                        const invoiceAmountInfo = article.questionCode
                        && questionsWithMinInvoiceAmount
                          .find((i) => i.questionCode === article.questionCode);

                        const amount = invoiceAmountInfo
                        && article.amount < invoiceAmountInfo.minInvoiceAmount
                          ? invoiceAmountInfo.minInvoiceAmount
                          : article.amount;

                        return (
                          <Row
                            key={article.code}
                            className="f-budget-cart-detail-line"
                            justifyContent="flex-end"
                            style={{
                              alignItems: 'center',
                              flexWrap: 'nowrap',
                            }}
                          >
                            <div className="f-budget-cart-detail-line-description">{article.description}</div>
                            <div className="f-budget-cart-detail-line-price">
                              {priceFormat(
                                (article.price || 0) * amount,
                              )}
                            </div>
                            <div
                              className="f-budget-cart-detail-remove"
                            >
                              {article.type === ArticleType.AUTOMATIC
                                ? (
                                  <div
                                    role="presentation"
                                    onClick={() => {
                                      discardArticle(article);
                                    }}
                                    onKeyDown={() => false}
                                  >
                                    <CustomIcon icon={OtherTypes.DELETE} />
                                  </div>
                                )
                                : (<div>&nbsp;</div>)}
                            </div>
                          </Row>
                        );
                      },
                    )}
                </div>
                <Row className="f-budget-cart-summary" justifyContent="space-between" style={{ alignItems: 'center' }}>
                  <div className="f-budget-cart-summary-caption">{`${t('budget.totalAmount')}:`}</div>
                  <div className="f-budget-cart-summary-amount">{priceFormat(summarize?.total || 0)}</div>
                </Row>

              </React.Fragment>
            )
            : (<Row justifyContent="space-around"><LoadingItems /></Row>)}

        </div>

      </div>

    </div>
  ));
};
