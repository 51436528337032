import { BudgetQuestionCodes } from '../../data/budgetQuestionCodes';
import { Audit } from '../Audit';

import {
  ArticleType, ArticleCollectionTypes, IArticlePrice, IArticle,
} from './types';

export class Article extends Audit implements IArticle {
  constructor(data: IArticle) {
    super(data);
    this.code = data.code;
    this.description = data.description;
    this.image = data.image;
    this.prices = data.prices;
    this.price = data.price;
    this.vat = data.vat;
    this.questionCode = data.questionCode;
    this.subType = data.subType;
    this.type = data.type;
    this.amount = data.amount;
    this.provider = data.provider;
    this.client = data.client;
    this.fixedPrice = data.fixedPrice;
  }

  public setAmount(amount: number): Article {
    this.amount = amount;
    return this;
  }

  client?: string;

  provider?: string;

  code: string;

  description: string;

  prices?: IArticlePrice[];

  subType?: ArticleCollectionTypes;

  questionCode?: BudgetQuestionCodes;

  image?: string | undefined;

  price?: number | null;

  vat?: number | null;

  type?: ArticleType | null;

  amount?: number;

  fixedPrice?: boolean;
}
