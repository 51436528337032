import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Loader from 'components/base/Loader';
import { Row } from 'components/base/Row';

import { ArticleClientSelector } from 'modules/budget/components/ArticleClientSelector';
import { ArticleProviderSelector } from 'modules/budget/components/ArticleProviderSelector';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { InvoiceArticle } from 'modules/budget/models/InvoiceArticle/types';
import { SetInvoiceArticles, SetInvoiceClients } from 'modules/budget/store/budgetActions';

import { ArticleBulkClientSelector } from '../../ArticleBulkClientSelector';

import { BudgetInvoiceComponent } from './types';

import './index.scss';

export const BudgetInvoice: FC<BudgetInvoiceComponent> = ({ budgetId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    fetchInvoiceArticles,
    invoiceArticles,
    invoiceArticlesLoading,
    invoiceClients,
  } = useBudget();

  useEffect(
    () => {
      if (budgetId) {
        fetchInvoiceArticles(budgetId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgetId],
  );

  useEffect(
    () => () => {
      dispatch(SetInvoiceArticles({ articles: [] }));
      dispatch(SetInvoiceClients({ clients: [] }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const ArticleRow = (item: InvoiceArticle): JSX.Element => (
    <tr key={item.id} className="f-budget-invoice-article-row">
      <td className="f-budget-invoice-article-description">{item.description}</td>
      <td>
        <ArticleProviderSelector
          article={item}
          idBudget={budgetId}
        />
      </td>
      <td>
        <ArticleClientSelector
          article={item}
          clients={invoiceClients}
          idBudget={budgetId}
        />
      </td>
    </tr>
  );

  return (
    <div>
      {!invoiceArticlesLoading && invoiceArticles.length !== 0 && (
      <table>
        <tbody>
          <tr className="f-budget-invoice-article-row">
            <td>&nbsp;</td>
            <td>
              &nbsp;
            </td>
            <td>
              <ArticleBulkClientSelector
                articles={invoiceArticles}
                clients={invoiceClients}
                idBudget={budgetId}
              />
            </td>
          </tr>
          {invoiceArticles.map(ArticleRow)}
        </tbody>
      </table>
      )}
      {!invoiceArticlesLoading && invoiceArticles.length === 0 && (
        <Row><div>{t('common.noItems')}</div></Row>
      )}
      {invoiceArticlesLoading && (<Loader />)}
    </div>
  );
};
