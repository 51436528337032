import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import { EmailFormValidationHook } from './types';

export const useEmailFormValidation = (): EmailFormValidationHook => {
  const { t } = useTranslation();

  const validate = useCallback(
    () => object().shape({
      email: string()
        .required(t('error.required'))
        .email(t('error.emailFormat'))
      ,
    }),
    [t],
  );

  return {
    validate,
  };
};
