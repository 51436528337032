import {
  ContentType,
  HttpClient,
  RequestParams,
} from 'config/apiFunus/generated/http-client';
import { DocumentDNIScanData } from 'modules/ia/models/DocumentDNIScanData';
import { DocumentDNIScanScore } from 'modules/ia/models/DocumentDNIScanScore';

import { handleErrors } from './handleErrors';
import { mapIAScores } from './mapIAScores';
import { mapIAValues } from './mapIAValues';
import { ApiDNIScanResponse } from './types/ApiDNIScanResponse';
import { ScanBySinglePhotoRequest } from './types/ScanBySinglePhotoRequest';
import { ScanByTwoPhotosRequest } from './types/ScanByTwoPhotosRequest';
import { ScanDocumentFromFileRequest } from './types/ScanDocumentFromFileRequest';
import { ScanDocumentFromSingleFileParams } from './types/ScanDocumentFromSingleFileParams';
import { ScanDocumentFromSinglePhotoParams } from './types/ScanDocumentFromSinglePhotoParams';
import { ScanDocumentFromTwoFileParams } from './types/ScanDocumentFromTwoFileParams';
import { ScanDocumentFromTwoPhotoParams } from './types/ScanDocumentFromTwoPhotoParams';

export class IAClient<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  ScanDNI = async (
    {
      front,
      back,
    }: ScanDocumentFromTwoPhotoParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const body: ScanByTwoPhotosRequest = {
      backImage: back.data,
      frontImage: front.data,
    };

    return this.http
      .request<ApiDNIScanResponse, void>({
        body,
        method: 'POST',
        path: '/document-identifiers/IDENTIFIER',
        secure: true,
        type: ContentType.Json,
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }

  ScanNIE = async (
    {
      front,
      back,
    }: ScanDocumentFromTwoPhotoParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const body: ScanByTwoPhotosRequest = {
      backImage: back.data,
      frontImage: front.data,
    };

    return this.http
      .request<ApiDNIScanResponse, void>({
        body,
        method: 'POST',
        path: '/document-identifiers/NIE',
        secure: true,
        type: ContentType.Json,
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }

  ScanPassport = async (
    {
      front,
    }: ScanDocumentFromSinglePhotoParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const body: ScanBySinglePhotoRequest = {
      image: front.data,
    };

    return this.http
      .request<ApiDNIScanResponse, void>({
        body,
        method: 'POST',
        path: '/document-identifiers/PASSPORT',
        secure: true,
        type: ContentType.Json,
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }

  ScanCIF = async (
    {
      front,
    }: ScanDocumentFromSinglePhotoParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const body: ScanBySinglePhotoRequest = {
      image: front.data,
    };

    return this.http
      .request<ApiDNIScanResponse, void>({
        body,
        method: 'POST',
        path: '/document-identifiers/CIF',
        secure: true,
        type: ContentType.Json,
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }

  ScanDNIFromFile = async (
    {
      file1, file2,
    }: ScanDocumentFromTwoFileParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const data: ScanDocumentFromFileRequest = new FormData();

    if (file1) {
      data.append('document', file1, file1.name);
    }
    if (file2) {
      data.append('extraDocument', file2, file2.name);
    }

    return this.http
      .request<ApiDNIScanResponse, void>({
        body: data,
        method: 'POST',
        path: '/document-identifiers/IDENTIFIER/upload',
        secure: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }

  ScanNIEFromFile = async (
    {
      file1, file2,
    }: ScanDocumentFromTwoFileParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const data: ScanDocumentFromFileRequest = new FormData();

    if (file1) {
      data.append('document', file1, file1.name);
    }
    if (file2) {
      data.append('extraDocument', file2, file2.name);
    }

    return this.http
      .request<ApiDNIScanResponse, void>({
        body: data,
        method: 'POST',
        path: '/document-identifiers/NIE/upload',
        secure: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }

  ScanCIFFromFile = async (
    {
      file1,
    }: ScanDocumentFromSingleFileParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const data: ScanDocumentFromFileRequest = new FormData();

    if (file1) {
      data.append('document', file1, file1.name);
    }

    return this.http
      .request<ApiDNIScanResponse, void>({
        body: data,
        method: 'POST',
        path: '/document-identifiers/CIF/upload',
        secure: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }

  ScanPassportFromFile = async (
    {
      file1,
    }: ScanDocumentFromSingleFileParams,
    params: RequestParams = {},
  ): Promise<{
    data: DocumentDNIScanData,
    score: DocumentDNIScanScore
  }> => {
    const data: ScanDocumentFromFileRequest = new FormData();

    if (file1) {
      data.append('document', file1, file1.name);
    }

    return this.http
      .request<ApiDNIScanResponse, void>({
        body: data,
        method: 'POST',
        path: '/document-identifiers/PASSPORT/upload',
        secure: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...params,
      })
      .then((response) => ({
        data: mapIAValues(response.values),
        score: mapIAScores(response.score),
      }))
      .catch(handleErrors);
  }
}
