import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';
import { BudgetSummaryHeaderComponent } from './types';

export const BudgetSummaryHeader: FC<BudgetSummaryHeaderComponent> = ({ showActions }) => {
  const { t } = useTranslation();

  return (
    <thead className="budget-summary-header">
      <tr>
        <th className="budget-summary-header-code">{t('budget.wizard.summary.article')}</th>
        <th className="budget-summary-header-description">{t('budget.wizard.summary.description')}</th>
        <th className="budget-summary-header-amount">{t('budget.wizard.summary.quantity')}</th>
        <th className="budget-summary-header-price">{t('budget.wizard.summary.price')}</th>
        {showActions && (<th>&nbsp;</th>)}
      </tr>
    </thead>
  );
};
