import BaseModel from 'models/BaseModel';

export enum StatusCodes {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

type OrderStatus = BaseModel & {
  code: StatusCodes;
};

export default OrderStatus;
