import classNames from 'classnames';
import React, {
  FC,
  Fragment,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import Loader from 'components/base/Loader';

import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useWindowSize from 'hooks/useWindowSize';
import './index.scss';
import { DashboardEnquiry } from 'models/Enquiry';

// eslint-disable-next-line import/no-cycle
import EnquiriesGroup from './enquiriesGroup';
import { useEnquiriesDashboard } from './useEnquiriesDashboard';

export enum ListStatusCode {
  pending = 'pending',
  introduced = 'introduced',
  completed = 'completed',
}

type ItemsType = {
  introduced: DashboardEnquiry[];
  pending: DashboardEnquiry[];
  completed: DashboardEnquiry[];
};

const initItems: ItemsType = {
  completed: [],
  introduced: [],
  pending: [],
};

const kindListOrders = [
  { code: ListStatusCode.introduced, id: 1 },
  { code: ListStatusCode.pending, id: 2 },
  { code: ListStatusCode.completed, id: 3 },
];

const EnquiryDashboardPage: FC = () => {
  const { t } = useTranslation();
  const [buttonsState, setButtonState] = useState(1);
  const [items, setItems] = useState<ItemsType>(initItems);
  const { lgOrSmaller } = useWindowSize();
  const { user } = useProvidedAuth();

  const {
    completed, introduced, pending, isLoading, loadEnquiries,
  } = useEnquiriesDashboard();

  useEffect(() => {
    setItems({
      completed,
      introduced,
      pending,
    });
  }, [completed, introduced, pending]);

  const renderWorkOrderGroup = (workOrderId: number, code: ListStatusCode) => {
    const list: DashboardEnquiry[] = items?.[code] || [];

    return (
      <EnquiriesGroup
        key={workOrderId}
        code={code}
        id={workOrderId}
        initialRequest={loadEnquiries}
        list={list}
      />
    );
  };

  return (
    <Fragment>
      <div className={classNames({ fullpage: !user })} id="enquiries-page">
        {lgOrSmaller && (
        <div className="div-select-main">
          <h4>{t('enquiry.enquiries')}</h4>
          <div aria-label="enquiry type selector" className="div-status" role="menubar">
            <Button
              className={classNames({ active: buttonsState === 1 })}
              color="transparent"
              onClick={() => setButtonState(1)}
            >
              {t('order.status.pending')}
            </Button>
            <Button
              className={classNames({ active: buttonsState === 2 })}
              color="transparent"
              onClick={() => setButtonState(2)}
            >
              {t('order.status.inProgress')}
            </Button>
            <Button
              className={classNames({ active: buttonsState === 3 })}
              color="transparent"
              onClick={() => setButtonState(3)}
            >
              {t('order.status.completed')}
            </Button>
          </div>
        </div>
        )}

        <div
          className={classNames('group-cards', { mobile: lgOrSmaller })}
        >
          {isLoading && <Loader />}
          {!isLoading && (
            <Fragment>
              {lgOrSmaller
                ? renderWorkOrderGroup(
                  buttonsState,
                  kindListOrders[buttonsState - 1].code,
                )
                : kindListOrders.map(
                  ({ id: workOrderId, code }) => renderWorkOrderGroup(workOrderId, code),
                )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EnquiryDashboardPage;
