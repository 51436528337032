import React, { FC, useEffect, useState } from 'react';

import useWindowSize from 'hooks/useWindowSize';
import {
  DNI_NIE_CIF_CAPTURE_RESOLUTION_HEIGHT,
  DNI_NIE_CIF_CAPTURE_RESOLUTION_WIDTH,
  DNI_SIZE_RATIO,
} from 'modules/ia/constants';

import { DocumentCamera } from '../document-camera';

import { DniNieCifCameraComponent } from './types';

export const DniNieCifCamera: FC<DniNieCifCameraComponent> = (
  { onAccept, isFrontCameraActive, onIsFrontCameraUpdate },
) => {
  const [
    cameraFrameHeight,
    setCameraFrameHeight,
  ] = useState<number>(0);
  const [
    cameraFrameWidth,
    setCameraFrameWidth,
  ] = useState<number>(0);

  const { width: windowWidth, smOrSmaller } = useWindowSize();

  useEffect(() => {
    if (windowWidth) {
      const widthPercentage = smOrSmaller ? 80 : 60;
      const frameWidth = (windowWidth * widthPercentage) / 100;
      setCameraFrameWidth(frameWidth);
      setCameraFrameHeight(frameWidth / DNI_SIZE_RATIO);
    }
  }, [smOrSmaller, windowWidth]);

  return (
    <DocumentCamera
      cameraFrameHeight={cameraFrameHeight}
      cameraFrameWidth={cameraFrameWidth}
      cameraResolutionHeight={DNI_NIE_CIF_CAPTURE_RESOLUTION_HEIGHT}
      cameraResolutionWidth={DNI_NIE_CIF_CAPTURE_RESOLUTION_WIDTH}
      isFrontCameraActive={isFrontCameraActive}
      onConfirmPhoto={(capture) => onAccept(capture)}
      onIsFrontCameraUpdate={onIsFrontCameraUpdate}
    />
  );
};
