import { ApiFunusAdviser } from './types';
import axios from 'config/axios';

export const adviser: ApiFunusAdviser = {
  getAdviserInfo: (id) => axios.get(`/advicedata/${id}`),
  getCalendar: (month, year, services, day) => axios.get(
    `/advice/calendar?${day ? `day=${day}&` : ''}month=${month}&year=${year}${services.reduce(
      (init, serv) => `${init}&services=${serv}`,
      ''
    )}`
  ),
  saveAdviser: (params, id) => axios.put(`/advicedata/${id}`, params),
  searchClient: (params) => axios.post('/advice/clients', params),
  signRecord: (id, signature) => axios.put(`/record/${id}/signature`, { signature }),
  validateAdviser: (params, id) => axios.put(`/validate/advice/${id}`, params),
  getServiceInfo: (id, postCeremonyId = null) => {
    let queryParams = ''
    if (postCeremonyId) {
      queryParams = `${queryParams}?id_post_ceremonia=${postCeremonyId}`
    }
    return axios.get(` /advices/services/${id}${queryParams}`)
  },
};
