import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';

import Button from 'components/base/Button';

import './index.scss';

export type ActionsProps = {
  icon?: JSX.Element;
  key: string;
  onClick: () => void;
  text: string;
};

type MoreActionsProps = {
  options: Array<ActionsProps>;
  toggleIcon: JSX.Element;
};

const MoreActions: FC<MoreActionsProps> = ({ options, toggleIcon }) => (
  <Dropdown
    className="dropdown-main"
  >
    <Dropdown.Toggle data-testid="more-actions-toogle-button" variant="link">{toggleIcon}</Dropdown.Toggle>

    <Dropdown.Menu>
      {options.map((opt: ActionsProps) => (
        <Button
          key={opt.key}
          color="transparent"
          leftAddon={opt.icon}
          text={opt.text}
          onClick={() => opt.onClick()}
        />
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default MoreActions;
