import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

export const multiselectQuestions: Array<BudgetQuestionCodes> = [
  BudgetQuestionCodes.Q13,
  BudgetQuestionCodes.Q14,
  BudgetQuestionCodes.Q15,
  BudgetQuestionCodes.Q21,
  BudgetQuestionCodes.Q24,
  BudgetQuestionCodes.Q29,
  BudgetQuestionCodes.Q30,
  BudgetQuestionCodes.Q43,
  BudgetQuestionCodes.Q44,
];

export const quantityQuestions: Array<BudgetQuestionCodes> = [
  BudgetQuestionCodes.Q13,
  BudgetQuestionCodes.Q15,
  BudgetQuestionCodes.Q21,
  BudgetQuestionCodes.Q29,
  BudgetQuestionCodes.Q30,
];
