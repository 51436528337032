import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { isBoolean, isBooleanAndFalse, isBooleanAndTrue } from 'utils/helpers';

import { Column } from 'components/base/Column';

import { CitySelector } from 'modules/budget/components/commons/CitySelector';
import { Separator } from 'modules/budget/components/commons/Separator';
import { YesNoSelector } from 'modules/budget/components/commons/YesNoSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionLabels } from 'modules/budget/hooks/useQuestionLabels';
import { Address } from 'modules/budget/models/Address';
import { dismissServiceId } from 'modules/budget/store/data';

export interface Q17Response {
  dismissService?: boolean;
  address: Address | null;
  autorespond: boolean;
}

interface Q17FormComponent {
  onChange: (value: Q17Response) => void;
  question: BudgetQuestionCodes;
}

export const Q17Form: FC<Q17FormComponent> = ({ onChange, question }) => {
  const { yes, no } = useQuestionLabels();
  const { GetQuestionValue } = useBudget();
  const [wantService, setWantService] = useState<boolean>();
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.address) {
        onChange({
          address: new Address(storedValue?.address),
          autorespond: false,
          dismissService: false,
        });
        setSelectedAddress(new Address(storedValue?.address));
        setWantService(true);
      } else if (storedValue === dismissServiceId) {
        onChange({
          address: null,
          autorespond: false,
          dismissService: true,
        });
        setWantService(false);
        setSelectedAddress(null);
      } else {
        setSelectedAddress(null);
      }
    },
    [onChange, storedValue],
  );

  return (
    <Formik
      initialValues={{
        address: selectedAddress,
        autorespond: false,
        dismissService: isBooleanAndTrue(wantService) || isBooleanAndFalse(wantService)
          ? !wantService
          : undefined,
      }}
      validate={(values) => {
        onChange({
          address: values.address,
          autorespond: values.autorespond,
          dismissService: values.dismissService,
        });
        setSelectedAddress(values.address);
        setWantService(isBooleanAndTrue(values.dismissService)
          || isBooleanAndFalse(values.dismissService)
          ? !values.dismissService
          : undefined);

        return {};
      }}
      enableReinitialize
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues, values,
      }: FormikProps<Q17Response>) => {
        const hideElements = values.dismissService === undefined
        || isBooleanAndTrue(values.dismissService);

        return (
          <Column>
            <YesNoSelector
              noCaption={no(question)}
              value={isBooleanAndTrue(values.dismissService)
                || isBooleanAndFalse(values.dismissService)
                ? !values.dismissService
                : undefined}
              yesCaption={yes(question)}
              onChange={(v) => {
                setValues({
                  address: null,
                  autorespond: isBooleanAndFalse(v),
                  dismissService: isBoolean(v) ? !v : undefined,
                });
              }}
            />
            <Separator />
            <CitySelector
              cityCode={values.address?.city || ''}
              cityWork={Boolean(values.address?.work)}
              className={classNames({ 'f-budget-hidden': hideElements })}
              countryCode={values.address?.country || ''}
              disabled={Boolean(values.dismissService)}
              provinceCode={values.address?.province || ''}
              emitPartial
              onChange={(val) => {
                setValues({
                  address: new Address(val),
                  autorespond: false,
                  dismissService: values.dismissService,
                });
              }}
            />
          </Column>
        );
      } }
    </Formik>
  );
};
