const user = {
  amount: 'Cantidad',
  detail: 'Detalle de venta',
  new: 'Nueva venta',
  product: 'Producto',
  saveKo: 'Se ha producido un error al crear la venta.',
  saveOk: 'La venta se ha creado satisfactoriamente.',
  search: 'Buscar ventas',
  title: 'Ventas',
};
export default user;
