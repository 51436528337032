import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { clearSummarize, fetchSummarize } from 'modules/budget/store/budgetActions';

import { BudgetSumarizeHook } from './types';

export const useBudgetSumarize = (): BudgetSumarizeHook => {
  const dispatch = useDispatch();

  const fetchSumarize = useCallback(
    async (id) => {
      dispatch(fetchSummarize(id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const clear = useCallback(
    () => {
      dispatch(clearSummarize());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    clearSummarize: clear,
    fetchSumarize,
  };
};
