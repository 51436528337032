import React, {
  FC, useEffect, useState,
} from 'react';
import { Document, Page } from 'react-pdf';

import { useReactPdf } from './useReactPdf';

export type PdfViewrLoadSuccess = {
  objectUrl: string;
};

interface PdfVewerProps {
  base64: string;
  onLoadSuccess: (data: PdfViewrLoadSuccess) => void
 }

export const PdfViewer: FC<PdfVewerProps> = ({ base64, onLoadSuccess }) => {
  useReactPdf();
  const [base64Pdf, setbase64Pdf] = useState<string>(`${base64}`);
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    setbase64Pdf(`${base64}`);
  }, [base64]);

  // https://github.com/wojtekmaj/react-pdf#document
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const documentLoadSuccess = async (event: any) => {
    const documentData = await event.getData();
    if (documentData) {
      const blob = new Blob([documentData], { type: 'application/pdf' });
      onLoadSuccess({ objectUrl: URL.createObjectURL(blob) });
    }
    setNumPages(event.numPages);
  };

  return (
    <React.Fragment>
      <Document
        file={base64Pdf}
        onLoadSuccess={documentLoadSuccess}
      >
        {[...Array(numPages)]
          .map((value, pageIndex) => pageIndex)
          .map((pageIndex) => <Page key={`page-${pageIndex}`} pageNumber={pageIndex + 1} />)}
      </Document>
    </React.Fragment>
  );
};
