export interface StepsHook {
  currentStep: StepPositions;
  setStep: (step: StepPositions) => void;
  isFirstStep: boolean;
  isSecondStep: boolean;
  isThirdStep: boolean;
  reset: () => void;
}

export enum StepPositions {
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  SEVENTH,
  EIGHTH,
  NINETH,
  TENTH,
}
