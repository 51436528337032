import React, {
  FC, useMemo, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { ButtonProps } from 'components/base/Button';
import Input from 'components/base/Input';
import Loader from 'components/base/Loader';
import Modal, { ModalProps } from 'components/base/Modal';
import Select from 'components/base/Select';
import Textarea from 'components/base/Textarea';

import { i18n } from 'i18n';
import OrderPriority from 'models/OrderPriority';
import { SimpleUser } from 'models/User';

import './index.scss';

type CreateUpdateOrderModal = ModalProps & {
  recordId?: string | number;
  workorderId?: string | number;
  callBack?: () => void;
};

export type PriorityOption = {
  label: string;
  value: OrderPriority;
};

const getPriorityOptions = (): Array<PriorityOption> => [
  {
    label: i18n.t('order.enums.priority.NORMAL'),
    value: OrderPriority.NORMAL,
  },
  {
    label: i18n.t('order.enums.priority.HIGH'),
    value: OrderPriority.HIGH,
  },
  {
    label: i18n.t('order.enums.priority.URGENT'),
    value: OrderPriority.URGENT,
  },
];

const CreateUpdate: FC<CreateUpdateOrderModal> = ({
  title,
  show,
  recordId,
  workorderId,
  onHide,
  callBack,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const priorityOptions = useMemo(() => getPriorityOptions(), [t]);
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [vehicle, setVehicle] = useState<string>('');
  const [assignedUsers, setAssignedUsers] = useState<Array<SimpleUser>>([]);
  const [priority, setPriority] = useState<PriorityOption>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [workorder, setWorkorder] = useState<any>();

  const sendWorkorder = () => {
    setLoading(true);

    const workorderToCreate = {
      info: {
        description,
        vehicle,
      },
      priority: priority?.value,
      recordId: recordId as string,
      startDate: new Date().toISOString(),
      workOrderUserDtos: assignedUsers.map((x) => ({ id: x.id })),
    };

    config.apiFunus.workOrder
      .createWorkOrderHandmade(workorderToCreate)
      .then((response) => {
        setLoading(false);
        showSuccessToast(t('order.created'));
        closeModal();

        return response;
      })
      .catch(() => {
        setLoading(false);
        showErrorToast(t('order.createError'));
      });
  };

  const updateWorkorder = () => {
    setLoading(true);

    const workorderToUpdate = {
      id: workorder.id,
      info: {
        description,
        vehicle,
      },
      priority: priority?.value,
      workOrderUserDtos: assignedUsers.map(({ id }) => ({ id })),
    };

    config.apiFunus.workOrder
      .updateWorkOderHandmade(workorderToUpdate)
      .then((response) => {
        setLoading(false);
        showSuccessToast(t('order.updated'));
        closeModal();
        if (callBack) {
          callBack();
        }

        return response;
      })
      .catch(() => {
        setLoading(false);
        showErrorToast(t('order.updateError'));
      });
  };

  const buttons: Array<ButtonProps> = workorderId
    ? [
      {
        color: 'primary',
        id: 'save',
        onClick: updateWorkorder,
        text: t('common.save'),
        type: 'button',
      },
    ]
    : [
      {
        color: 'primary',
        id: 'create',
        onClick: sendWorkorder,
        text: t('order.create'),
        type: 'button',
      },
    ];

  useEffect(() => {
    if (show && workorderId) {
      setLoading(true);
      config.apiFunus.workOrder
        .getWorkOrderById(workorderId)
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            setWorkorder(data);
            setDescription(data.info?.description);
            setVehicle(data.info?.vehicle);
            setPriority(
              priorityOptions.find((x) => x.value === data?.priority),
            );
            setLoading(false);
          }

          return response;
        })
        .catch(() => {
          setLoading(false);
          showErrorToast(t('order.getError'));
        });
    } else {
      setPriority(priorityOptions[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, workorderId]);

  const closeModal = () => {
    onHide();
    setDescription('');
    setVehicle('');
    setPriority(priorityOptions[0]);
    setAssignedUsers([]);
  };

  return (
    <Modal buttons={buttons} show={show} title={title} onHide={closeModal}>
      {loading && <Loader />}
      <Textarea
        className="mb-3"
        id="create-update-description"
        maxRows={5}
        name="create-update-description"
        placeholder={t('common.description')}
        value={description || ''}
        onChange={(val: string) => setDescription(val)}
      />
      <Input
        id="create-update-vehicle"
        maxLength={150}
        name="create-update-vehicle"
        placeholder={t('order.vehicle')}
        value={vehicle || ''}
        onChange={(val: string) => setVehicle(val)}
      />
      <Select
        className="my-3 create-update-priority"
        getLabel={(option) => option.label}
        getValue={(option) => option.value}
        id="create-update-priority"
        name="create-update-priority"
        options={priorityOptions}
        placeholder={t('order.priority')}
        value={priority}
        onChange={(option) => setPriority(option as PriorityOption)}
      />
    </Modal>
  );
};

export default CreateUpdate;
export { getPriorityOptions };
