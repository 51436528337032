import Stomp, { Client } from 'stompjs';

const SOCKET_URL = process.env.REACT_APP_WS_URL
  || 'wss://prefunus.cabrejunqueras.cat/funus/ws/workorder';
const getSubscription = () => '/topic/workorder';
const getChannel = (workshop: string, type: string) => `/funus/workOrder/${workshop}/${type}`;

let client: Client | null;

type SocketType = {
  client: Client | null;
  connectSocket: (cb?: () => void) => void;
  disconnectSocket: (cb?: () => void) => void;
  getChannel(workshop: string, type: string): string;
  getSubscription(): string;
};

export const useSocket = (): SocketType => {
  const connectSocket = (cb?: () => void) => {
    client = Stomp.client(SOCKET_URL, ['v12.stomp']);
    client.connect(
      {},
      () => {
        if (cb) {
          cb();
        }
      },
      (error) => {
        // eslint-disable-next-line no-alert
        window.alert(JSON.stringify(error));
      },
    );
  };

  const disconnectSocket = (cb?: () => void) => {
    // TODO:
    client?.disconnect(() => {
      if (cb) {
        cb();
      }
    });
  };

  return {
    client,
    connectSocket,
    disconnectSocket,
    getChannel,
    getSubscription,
  };
};
