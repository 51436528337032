/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable camelcase */
export const IP_LOCATION_NET_URL = 'https://api.iplocation.net/?cmd=get-ip';

export interface IpLocationNet {
  ip: string;
  id_version: number;
  response_code: number;
  response_message: string;
}
