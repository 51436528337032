import classNames from 'classnames';
import React, { FC } from 'react';

import './index.scss';
import { SeparatorStyles, SeparatorComponent } from './types';

export const Separator: FC<SeparatorComponent> = ({ className, layout = SeparatorStyles.NONE }) => (
  <div
    className={classNames(
      'f-separator',
      className,
      { 'f-separator-border-one': layout === SeparatorStyles.BORDER_ONE },
    )}
  >
    &nbsp;

  </div>
);
