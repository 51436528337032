import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps } from 'components/base/Button';
import Modal from 'components/base/Modal';

import { ArticleInfoResponseDto, ArticleInfoResponseDtoStatus } from 'config/apiFunus/generated/data-contracts';
import './index.scss';

type ConfirmDeliverModalComponent = {
  show: boolean;
  articleDetail: ArticleInfoResponseDto | undefined;
  onAccept: () => void;
  onCancel: () => void;
}

export const ArticleDetailModal: FC<ConfirmDeliverModalComponent> = ({
  show,
  articleDetail,
  onAccept,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [buttons, setButtons] = useState<Array<ButtonProps>>([]);

  useEffect(
    () => {
      setButtons(articleDetail?.erpId
        ? [
          {
            onClick: () => {
              onCancel();
            },
            text: articleDetail?.status === ArticleInfoResponseDtoStatus.FINISHED
              ? t('common.close')
              : t('common.cancel'),
          },
          ...articleDetail?.status === ArticleInfoResponseDtoStatus.FINISHED
            ? []
            : [{
              onClick: () => {
                if (articleDetail?.erpId) {
                  onAccept();
                }
              },
              text: t('common.accept'),
            }],
        ]
        : []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleDetail?.erpId, t],
  );

  return (
    <Modal
      buttons={buttons}
      className="article-info-modal"
      show={show}
      showCancel={false}
      title={t('article.detail')}
      onHide={() => false}
    >
      {articleDetail && (
      <React.Fragment>
        <div>{articleDetail?.erpId}</div>
        <div>{`${articleDetail?.deceasedName || ''} ${articleDetail?.deceasedFirstSurname || ''} ${articleDetail?.deceasedSecondSurname || ''}`}</div>
        <div>
          {articleDetail?.code}
          {' '}
          -
          {' '}
          {articleDetail?.description}
        </div>
        <div>{articleDetail?.dedicatory}</div>
        <div>{articleDetail?.vigilRoom}</div>
        {articleDetail?.status === ArticleInfoResponseDtoStatus.FINISHED
          ? <div className="info-message">{t('article.flowers.finishedStatusText')}</div>
          : <React.Fragment />}
      </React.Fragment>
      )}
    </Modal>
  );
};
