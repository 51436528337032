import { Formik, FormikProps } from 'formik';
import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  isBoolean, isBooleanAndFalse, isBooleanAndTrue, isNull,
} from 'utils/helpers';

import { Column } from 'components/base/Column';
import { Row } from 'components/base/Row';

import { DeceasedStatusInfo } from 'modules/budget/components/commons/DeceasedStatusInfo';
import { YesNoSelector } from 'modules/budget/components/commons/YesNoSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { BudgetResponseValue } from 'modules/budget/store/types';

import './index.scss';

export interface YesNoQuestionResponse {
  responseValue: BudgetResponseValue;
  autorespond: boolean;
}

interface YesNoQuestionFormComponent {
  onChange: (value: YesNoQuestionResponse) => void;
  question: BudgetQuestionCodes;

}

export const YesNoQuestionForm: FC<YesNoQuestionFormComponent> = ({ onChange, question }) => {
  const { t } = useTranslation();
  const { GetQuestionValue, idRecord } = useBudget();
  const [yesNo, setYesNo] = useState<boolean | undefined>();

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (isBooleanAndTrue(storedValue?.yesNo)) {
        onChange({
          autorespond: false,
          responseValue: true,
        });
        setYesNo(true);
      } else if (isBooleanAndFalse(storedValue?.yesNo)) {
        onChange({
          autorespond: false,
          responseValue: false,
        });
        setYesNo(false);
      }
    },
    [onChange, storedValue?.yesNo],
  );

  const deceasedStatus = useCallback(
    (status: string | null): string => (!isNull(status)
      ? t('budget.wizard.collectedYes', { status })
      : ''),
    [t],
  );

  return (
    <Formik
      initialValues={{
        autorespond: false,
        responseValue: null,
      }}
      validate={(values) => {
        onChange({
          autorespond: values.autorespond,
          responseValue: values.responseValue,
        });
        setYesNo(isBoolean(values.responseValue) ? Boolean(values.responseValue) : undefined);
        return {};
      }}
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues,
      }: FormikProps<YesNoQuestionResponse>) => (
        <Column className="f-yes-no-question-form">
          <YesNoSelector
            question={question}
            value={yesNo}
            onChange={(v) => {
              setValues({
                autorespond: isBoolean(v),
                responseValue: isBoolean(v) ? v : undefined,
              });
            }}
          />
          {question === BudgetQuestionCodes.Q9
            && idRecord
            ? (
              <Row justifyContent="space-around">
                <div>&nbsp;</div>
                <div className="yes-no-form-deceased-status">
                  <DeceasedStatusInfo
                    caption={deceasedStatus}
                    recordId={idRecord}
                  />
                </div>
                <div>&nbsp;</div>
              </Row>
            )
            : <React.Fragment />}

        </Column>
      )}
    </Formik>
  );
};
