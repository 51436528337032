import { Middleware } from 'redux';

import { showErrorToast } from 'utils/toasts';

import { i18n } from 'i18n';

import { budgetController } from '../../api';
import { setSummarize, setSummarizeLoading } from '../budgetActions';
import { BudgetActionTypes } from '../budgetActions/types';
import { BudgetState } from '../budgetReducer/types';

export const FetchSummarize: Middleware = (storeApi) => (next) => async (action) => {
  if (action.type === BudgetActionTypes.SET_BUDGET) {
    const result = next(action);
    const storedBudget = storeApi.getState().budget as BudgetState;

    const budgetId = action.payload?.budgetId || storedBudget.id || null;

    if (budgetId) {
      const load = async () => budgetController.GetBudgetSummary(budgetId);

      try {
        storeApi.dispatch(setSummarizeLoading(true));
        const response = await load();

        storeApi.dispatch(setSummarize(response));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        showErrorToast(i18n.t(error.message));
      } finally {
        storeApi.dispatch(setSummarizeLoading(false));
      }
    }

    return result;
  }

  return next(action);
};
