import { useTranslation } from 'react-i18next';
import {
  lazy, mixed, object, string,
} from 'yup';

import config from 'config';

import { ValidationSchemas, ValidatorHook } from './types';

export const useValidator = (): ValidatorHook => {
  const { t } = useTranslation();

  const IADniSchema = object().shape({
    idCard: string()
      .required(t('validationRules.requiredDocument'))
      .test(
        'identifierValidation',
        t('validationRules.string.identifier'),
        (value: string | undefined) => {
          if (value) {
            const nifIsValid = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i.test(value);

            if (nifIsValid) {
              const letters = 'TRWAGMYFPDXBNJZSQVHLCKET';
              const number = value.substring(0, value.length - 1);
              const parseNumber = parseInt(number, 10);
              const remainder = parseNumber % 23;
              const letter = value.slice(-1);
              const correctLetter = letters.substring(remainder, remainder + 1);

              return correctLetter.toUpperCase() === letter.toUpperCase();
            }

            return false;
          }
          return true;
        },
      )
      .nullable(),
  });

  const IACIFSchema = object().shape({
    idCard: string()
      .required(t('validationRules.requiredDocument'))
      .test(
        'identifierValidation',
        t('validationRules.string.cif'),
        (value: string | undefined) => {
          if (value) {
            const regex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
            return regex.test(value);
          }
          return true;
        },
      )
      .nullable(),
  });

  const IANIESchema = object().shape({
    idCard: string()
      .required(t('validationRules.requiredDocument'))
      .test(
        'identifierValidation',
        t('validationRules.string.nie'),
        (value: string | undefined) => {
          if (value) {
            const nifIsValid = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i.test(value);

            if (nifIsValid) {
              const letters = 'TRWAGMYFPDXBNJZSQVHLCKET';
              const number = value.substring(0, value.length - 1);
              const parseNumber = parseInt(number, 10);
              const remainder = parseNumber % 23;
              const letter = value.slice(-1);
              const correctLetter = letters.substring(remainder, remainder + 1);

              return correctLetter.toUpperCase() === letter.toUpperCase();
            }
          }
          return true;
        },
      )
      .nullable(),
  });

  const IAPassportSchema = object().shape({
    idCard: string()
      .required(t('validationRules.string.passport'))
      .nullable(),
  });

  const IAUploadDocumentSchema = lazy(({ fileNumber }) => object()
    .shape({
      file1: mixed()
        .required(t('validationRules.requiredDocument'))
        .test(
          'is-file-of-correct-type',
          'File is not of supported type',
          (file) => {
            let valid = true;
            const type = file?.type.split('/')[1];
            const validTypes = [
              'pdf',
            ];
            if (!validTypes.includes(type)) {
              valid = false;
            }

            return valid;
          },
        ),
      file2: fileNumber === '2'
        ? mixed()
          .required(t('validationRules.requiredDocument'))
          .test(
            'is-file-of-correct-type',
            'File is not of supported type',
            (file) => {
              let valid = true;
              const type = file?.type.split('/')[1];
              const validTypes = [
                'pdf',
              ];
              if (!validTypes.includes(type)) {
                valid = false;
              }

              return valid;
            },
          )
        : mixed()
          .test(
            'is-file-of-correct-type',
            'File is not of supported type',
            (file) => {
              if (file === null) return true;
              let valid = true;
              const type = file?.type.split('/')[1];
              const validTypes = [
                'pdf',
              ];
              if (!validTypes.includes(type)) {
                valid = false;
              }

              return valid;
            },
          ),
    }));

  return {
    IACIFSchema,
    IADniSchema,
    IANIESchema,
    IAPassportSchema,
    IAUploadDocumentSchema,
    validateArticleAddRequest: async (
      values,
    ) => config.validator(values, ValidationSchemas.ARTICLE),
    validateArticleDisagreement: async (
      values,
    ) => config.validator(values, ValidationSchemas.ARTICLE_DISAGREEMENT),
    validateBudgetRule: async (
      values,
    ) => config.validator(values, ValidationSchemas.BUDGET_RULE),
  };
};
