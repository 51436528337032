import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { ResponsesTableComponent } from './types';

export const ResponsesTable: FC<ResponsesTableComponent> = ({
  responses, responseCodes, onDelete, name,
}) => {
  const { t } = useTranslation();

  return (
    <table style={{ width: '100%' }}>
      <thead>

        <tr>
          <th>{t('budget.response')}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>

        {responses
          .map((response) => responseCodes
            .find((responseCodesItem) => responseCodesItem.code === response.code))
          .map((response) => (
            <tr
              key={response?.code}
            >
              <td data-testid={`${name}-${response?.code}`}>{`${response?.code} - ${response?.description}`}</td>
              <td style={{ width: '48px' }}>
                <Button
                  color="transparent"
                  testid={`${name}-remove-${response?.code}`}
                  onClick={() => {
                    if (response) {
                      onDelete(response);
                    }
                  }}
                >
                  <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
