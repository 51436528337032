const article = {
  addDisagreement: 'Afegeix desacord',
  addFlowers: 'Afegir flors',
  amount: 'Quantitat',
  articleDetail: 'Informació de l\'article',
  articleQr: 'Escanegeu el QR associat a l\'article',
  confirmAddRequest: 'Estàs segur que vols enviar {{article}}: {{observations}}, al proveïdor {{supplier}} perquè les prepari?',
  createArticleRequestOK: 'Sol·licitud creada correctament',
  createDisagreementOK: 'desacord creat correctament',
  detail: 'Detall article',
  disagreementPhoto: 'Foto de desacord',
  externalFlowerSender: 'Remitent',
  firstComment: 'Text primari',
  flowers: {
    articleInStock: 'Exp',
    articleInStockTooltip: 'Article de l\'expositor',
    category: 'Tipus de flor',
    channels: {
      EXTERNAL: 'Flors externes',
      PROCESSING: 'Cabré Junqueras',
      RECEPTION: 'Via recepció',
      WEB: 'Via web',
    },
    confirmAcceptKO: 'Error acceptant comanda',
    confirmAcceptOK: 'Comanda acceptada',
    confirmAcceptRequestText: 'Està segur que vol acceptar aquesta comanda?',
    confirmAcceptRequestTitle: 'Acceptar comanda',
    confirmCancelKO: 'Error cancel·lant comanda',
    confirmCancelOK: 'Comanda cancel·lada correctament',
    confirmCancelText: 'Esteu segur que voleu cancel·lar aquesta comanda?',
    confirmCancelTitle: 'Cancel·lar comanda',
    confirmDeleteKO: 'Error eliminar comanda',
    confirmDeleteOK: 'Comanda eliminada correctament',
    confirmDeleteText: 'Estàs segur que es vol eliminar les flors de l\'expedient? Recorda avisar al proveïdor i eliminar la línea a EKON.',
    confirmDeleteTitle: 'Eliminar comanda',
    date: 'Data límit de lliurament',
    dedicatory: 'Dedicatòria',
    editDraft: 'Editar esborrany',
    finishedStatusText: 'Aquestes flors ja estan en sala o destí. No es poden realitzar més accions amb aquesta comanda.',
    observations: 'Observacions de l\'article',
    pendingStatusText: 'El proveïdor encara no ha acceptat aquesta comanda.',
    price: 'Preu sense IVA',
    printComercialTitle: 'Document detall comercial',
    printTitle: 'Imprimir comanda',
    showAllFlowerRequest: 'Flors expedient',
    states: {
      CANCELLED: 'Cancel·lat',
      DRAFT: 'Esborrany',
      FINISHED: 'Flor a sala / destí',
      IN_PROGRESS: 'Validat pel proveïdor',
      PENDING: 'Pendent de validar pel proveïdor',
      RECEIVED: 'Flor al carro',
    },
    statusUpdatedOk: 'Estat actualitzat correctament',
    statusUpdatedOK: 'El canvi d\'estat no s\'ha pogut fer',
    time: 'Hora límit de lliurament',
    title: 'Flors',
    totalFlowers: 'Total flors',
    updateDraftSuccess: 'Esborrany actualitzat correctament',
  },
  needReplace: 'cal reemplaçament',
  otherCategoryComment: 'Comentari d\'altres categories',
  photo: {
    confirm: 'Confirmar',
    repeat: 'Repetir',
    take: 'Fer fotografia',
  },
  photoReminder: 'Recuerda sacar una foto de las flores',
  requestType: 'Tipus de sol·licitud de flors',
  secondComment: 'Text secundari',
  takeDeliveredPhoto: 'Afegir foto de les flors',
  types: {
    DEDICATORY: 'Dedicatòria',
    DELIVERY: 'Entrega',
    ERROR: 'Error',
    STATUS: 'Estat',
  },
  updateStatusOK: 'Estat actualitzat amb èxit',
  viewPhoto: 'Veure fotografia',
};
export default article;
