import React, {
  ReactElement, useEffect, useState,
} from 'react';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import Tooltip from 'components/base/Tooltip';

import {
  TableActionsProps, ActionsColumnProps, ActionProp,
} from './types';

import './index.scss';

const ActionsColumn = <T extends Record<string, unknown>> ({
  actions,
  index,
  onExpand,
  row,
  selectedRow,
  showTooltip,
}: ActionsColumnProps<T>): ReactElement => {
  const [allActions, setAllActions] = useState<TableActionsProps>({});

  useEffect(() => {
    if (actions) {
      setAllActions(actions(row.original, index));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, row]);

  const checkTo = (readOnly: boolean, key: string, url: string) => {
    if (readOnly && !['edit', 'remove'].includes(key)) {
      return '';
    }
    return url;
  };

  const isSelectedRow = selectedRow === row.id;

  const discardUndefined = (
    actionKey: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): boolean => (allActions as any)[actionKey] !== undefined;

  const excludeExtra = (actionKey: string) => actionKey !== 'extra';

  const showOnSelectedActions = Object.keys(allActions)
    .filter(discardUndefined)
    .filter(excludeExtra)
    .map((actionKey) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const action: ActionProp = (allActions as any)[actionKey];
      return {
        ...action,
        key: actionKey,
      };
    })
    .filter((action) => action.isExtra);

  const actionsToShow = Object.keys(allActions)
    .filter(discardUndefined)
    .filter(excludeExtra)
    .map((actionKey) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const action: ActionProp = (allActions as any)[actionKey];
      return {
        ...action,
        key: actionKey,
      };
    })
    .filter((action) => !action.isExtra || (action.isExtra && isSelectedRow));

  const actionButtonComponent = (action: ActionProp & { key: string; }) => (
    <Tooltip key={action.key} content={action.tooltipCaption} disabled={!showTooltip}>
      {action?.url ? (
        <Button
          className={`${action.key}-button`}
          disabled={!!row.original?.readOnly || false}
          testid={`${action.key}-button`}
          to={checkTo(!!row.original?.readOnly, action.key, action.url)}
        >
          {action?.icon ? (action?.icon) : <React.Fragment />}
        </Button>
      ) : (
        <Button
          className={`${action.key}-button`}
          disabled={!!row.original?.readOnly || false}
          testid={`${action.key}-button`}
          onClick={() => {
            if (!row.original?.readOnly) {
              action?.onClick?.(action?.callback);
              onExpand('');
            }
          }}
        >
          {action?.icon ? (action?.icon) : <React.Fragment />}
        </Button>
      )}
    </Tooltip>
  );
  return (
    <td className="action-column">
      <div>
        {showOnSelectedActions.length !== 0 && (
        <div>
          <Button
            className="expand-row-button"
            testid="expand-row-button"
            onClick={() => onExpand(selectedRow === row.id ? '' : row.id)}
          >
            {selectedRow === row.id
              ? (<CustomIcon icon={FeatherIconTypes.CHEVRONRIGHT} />)
              : (<CustomIcon icon={FeatherIconTypes.CHEVRONLEFT} />)}
          </Button>
        </div>
        )}

        {actionsToShow
          .map(actionButtonComponent)}
      </div>
    </td>
  );
};

ActionsColumn.defaultProps = {
  selectedRow: undefined,
  showTooltip: true,
};

export default ActionsColumn;
