import { useCallback, useEffect, useState } from 'react';

import { normalizeMasterData } from 'utils/normalizer';

import { getVigilLocations } from 'actions/masterData';

import { IMasterDataDto, VigilLocation } from 'models/MasterData';
import { NormalizedType } from 'models/MasterService';

export type VigilLocationHook = {
  vigilLocations: VigilLocation[];
  normalizedVigilLocations: NormalizedType;
  getVigilLocation: (
    code: string) => VigilLocation | undefined;
  fetchVigilLocations: (code: string) => void
};

export const useVigilLocation = (cityCode?: string): VigilLocationHook => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [normalizedVigilLocations, setNormalizedVigilLocations] = useState<any>({});
  const [vigilLocations, setVigilLocations] = useState<VigilLocation[]>([]);

  const getLocations = async () => {
    getVigilLocations(cityCode)
      .then((response) => {
        setVigilLocations(response.data as IMasterDataDto[]);
        return response.data;
      })
      .catch(() => {
        setVigilLocations([]);
      });
  };

  const fetchVigilLocations = (code: string) => {
    getVigilLocations(code)
      .then((response) => {
        setVigilLocations(response.data as IMasterDataDto[]);
        return response.data;
      })
      .catch(() => {
        setVigilLocations([]);
      });
  };

  const getVigilLocationByCity = useCallback(
    (code: string) => normalizedVigilLocations && normalizedVigilLocations[code],
    [normalizedVigilLocations],
  );

  useEffect(() => {
    if (cityCode !== cityLoaded) {
      setCityLoaded(cityCode);
      getLocations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  useEffect(() => {
    setNormalizedVigilLocations(normalizeMasterData(vigilLocations));
  }, [vigilLocations]);

  return {
    fetchVigilLocations,
    getVigilLocation: getVigilLocationByCity,
    normalizedVigilLocations,
    vigilLocations,
  };
};

type VigilLocationDescriptionHook = {
  description: string | undefined;
  setCode:(s: string) => void;
  setCityCode:(s: string) => void;
}

export const useVigilLocationDescription = (): VigilLocationDescriptionHook => {
  const [code, setCode] = useState<string>();
  const [cityCode, setCityCode] = useState<string>();
  const [description, setDescription] = useState<string | undefined>();

  const { fetchVigilLocations, getVigilLocation } = useVigilLocation();

  useEffect(
    () => {
      if (cityCode) {
        fetchVigilLocations(cityCode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityCode],
  );

  useEffect(
    () => {
      if (code) {
        setDescription(getVigilLocation(code)?.location);
      }
    },
    [code, getVigilLocation],
  );

  return {
    description,
    setCityCode: (s) => setCityCode(s),
    setCode: (s) => setCode(s),
  };
};
