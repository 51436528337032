import { AxiosError } from "axios";

export class UnprocessableEntityError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    if (error.response?.data) {
      this.message = `order.${error.response?.data}`;
    } else {
      this.message = 'error.unprocessableEntity'
    }
    this.name = "UnprocessableEntityError";
  }
}
