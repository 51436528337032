import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/base/Select';

import { useQuestionOptions } from 'modules/budget/hooks/useQuestionOptions';
import { Article } from 'modules/budget/models/Article';
import { IArticle, ArticleCollectionTypes } from 'modules/budget/models/Article/types';

import { LoadingItems } from '../../commons/LoadingItems';
import { OptionSelector } from '../../commons/OptionSelector';

import { InsuranceSelectorComponent } from './types';

export const mapStringToOnChangePayload = (code: string): {code: string} => ({ code });

export const InsuranceSelector: FC<InsuranceSelectorComponent> = (
  {
    disabled,
    onChange,
    className,
    question,
    value,
  },
) => {
  const { t } = useTranslation();
  const { fetchOptions, items, loading } = useQuestionOptions();
  const [highlightInsurances] = useState<string[]>(['900000', '900010', '013667', '024440', '900163', '900179']);

  useEffect(
    () => {
      fetchOptions(question, ArticleCollectionTypes.ARTICLE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question],
  );

  return loading
    ? (<LoadingItems className={className} />)
    : (
      <React.Fragment>
        <OptionSelector
          className={classNames('f-item-selector', className)}
          items={items.filter((article) => highlightInsurances.includes(article.code))}
          value={value
            ? [
              new Article({
                amount: 1,
                code: value.code,
                description: '',
              }),
            ]
            : []}
          onChange={(articles) => {
            if (articles[0]) {
              onChange({
                ...mapStringToOnChangePayload(articles[0].code),
                autorespond: true,
              });
            }
          }}
        />
        <Select<IArticle>
          key="insurance-selector"
          className={classNames('f-budget-location-selector', className)}
          disabled={disabled}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="insurance-selector"
          options={items.filter((article) => !highlightInsurances.includes(article.code))}
          placeholder={t('budget.insurance')}
          value={value ? items
            .filter((article) => !highlightInsurances.includes(article.code))
            .find((e) => e.code === value.code) : undefined}
          searchable
          onChange={(val) => {
            onChange(val?.code
              ? {
                ...mapStringToOnChangePayload(val?.code),
                autorespond: false,
              }
              : null);
          }}
        />
      </React.Fragment>

    );
};
