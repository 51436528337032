import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';

import Box from 'components/base/Box';

import './index.scss';

type BoxProps = {
  className?: string;
  content: ReactElement;
  header?: ReactElement;
  icon?: ReactElement;
  loading?: boolean;
  title: string;
};

type TwoBoxesPageProps = {
  className?: string;
  leftBox: BoxProps;
  rightBox: BoxProps;
  oneBox?: boolean;
};

const TwoBoxesPage: FC<TwoBoxesPageProps> = ({
  className,
  leftBox,
  rightBox,
  oneBox,
}) => (
  <div
    className={classNames('two-boxes-page', { 'one-box': oneBox }, className)}
  >
    <div>
      <Box
        className={classNames('left-box', leftBox.className)}
        headerContent={leftBox.header}
        icon={leftBox.icon}
        title={leftBox.title}
      >
        {leftBox.content}
      </Box>
      <Box
        className={classNames('right-box', rightBox.className)}
        headerContent={rightBox.header}
        icon={rightBox.icon}
        title={rightBox.title}
      >
        {rightBox.content}
      </Box>
    </div>
  </div>
);

export default TwoBoxesPage;
