import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import config from 'config';

import { showErrorToast } from 'utils/toasts';

import { BudgetArticleRuleForm } from 'modules/budget/components/budgetConfig/budgetForm';
import { useBudgetForm } from 'modules/budget/components/budgetConfig/budgetForm/helper';
import { ArticleCollectionTypes } from 'modules/budget/models/Article/types';
import { BudgetConfiguration } from 'modules/budget/models/BudgetConfiguration';
import { IBudgetConfiguration } from 'modules/budget/models/BudgetConfiguration/types';

import { ArticleRulePageParams } from './types';

export const ArticleRule: FC<Record<string, unknown>> = () => {
  const { code } = useParams<ArticleRulePageParams>();
  const history = useHistory();

  const { fetchConfiguration, saveConfiguration, coffinSizes } = useBudgetForm();
  const [currentArticle, setCurrentArticle] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const emptyConfig = {
    active: false,
    article: null,
    bloc: null,
    excludedArticles: [],
    excludedClients: [],
    excludedLocations: [],
    image: null,
    includedArticles: [],
    includedClients: [],
    includedLocations: [],
    mandatoryResponses: [],
    optionalResponses: [],
    order: null,
    questionCode: null,
    subType: ArticleCollectionTypes.ARTICLE,
    type: null,
  };
  const [
    selectedConfiguration,
    setSelectedConfiguration,
  ] = useState<IBudgetConfiguration>(emptyConfig);

  const fconfg = useCallback(
    async (articleCode: string) => {
      setLoading(true);
      await fetchConfiguration(articleCode)
        .then((configuration) => {
          setSelectedConfiguration(configuration);
          return configuration;
        })
        .catch((r) => {
          showErrorToast(r);
        }).finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (code) {
      setCurrentArticle(code);
      fconfg(code);
    }
  }, [code, fconfg]);

  return (
    <React.Fragment>
      <BudgetArticleRuleForm
        articleCode={code}
        coffinSizes={coffinSizes}
        loading={loading}
        value={selectedConfiguration}
        onAccept={(configuration) => {
          if (currentArticle) {
            saveConfiguration(
              currentArticle,
              new BudgetConfiguration({
                active: configuration.active,
                article: configuration.article,
                bloc: configuration.bloc,
                coffinSize: configuration.coffinSize,
                createdBy: configuration.createdBy,
                createdDate: configuration.createdDate,
                excludedArticles: configuration.excludedArticles,
                excludedClients: configuration.excludedClients,
                excludedLocations: configuration.excludedLocations,
                id: configuration.id,
                idVersion: configuration.idVersion,
                image: configuration.image,
                includedArticles: configuration.includedArticles,
                includedClients: configuration.includedClients,
                includedLocations: configuration.includedLocations,
                mandatoryResponses: configuration.mandatoryResponses,
                optionalResponses: configuration.optionalResponses,
                order: configuration.order,
                questionCode: configuration.questionCode,
                subType: configuration.subType,
                type: configuration.type,
              }),
            )
              .then((r) => {
                setSelectedConfiguration(emptyConfig);
                setCurrentArticle('');
                if (code) {
                  history.push(config.url.budgetsArticles);
                }
                return r;
              })
              .catch((r) => {
                showErrorToast(r);
              });
          }
        }}
        onChange={(articleId, values) => {
          if (articleId !== currentArticle) {
            setCurrentArticle(articleId);

            fconfg(articleId);
          } else {
            setSelectedConfiguration(values);
          }
        }}
      />
    </React.Fragment>
  );
};
