import {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { yesNoValues } from 'modules/budget/components/commons/YesNoSelector/types';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { sortedQuestions } from 'modules/budget/data/sortedQuestions';
import { BudgetHelper } from 'modules/budget/helpers/BudgetHelper';
import { IArticle, ArticleCollectionTypes } from 'modules/budget/models/Article/types';
import { IArticlesCollection } from 'modules/budget/models/ArticlesCollection/types';
import { ServiceTypes, NicheOwnershipTypes } from 'modules/budget/store/data';
import { RootState } from 'store';

import { budgetController } from '../../api';
import { yesNoForms } from '../../data/yesNoQuestions';
import { useBudget } from '../useBudget';
import { useQuestionLabels } from '../useQuestionLabels';

import { serviceTypes, nicheOwnershipTypes } from './data';
import {
  mapMasterDataToArticle,
  includeAllowedCeremonyTypes,
} from './tools';
import { QuestionOptionsHook } from './types';

export const useQuestionOptions = (): QuestionOptionsHook => {
  const { insurances, ceremonies } = useSelector((state: RootState) => state.masterData);
  const { t } = useTranslation();
  const { no, yes } = useQuestionLabels();
  const { id, questions } = useBudget();
  const [items, setItems] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(true);

  useEffect(() => () => {
    setMounted(false);
  }, []);

  const mapServiceTypeToIArticle = useCallback(
    (type: ServiceTypes): IArticle => ({
      code: type,
      description: t(`budget.serviceTypes.${type}`),
    }),
    [t],
  );

  const mapNicheOwershipTypesToIArticle = useCallback(
    (type: NicheOwnershipTypes): IArticle => ({
      code: type,
      description: t(`budget.nicheProperties.${type}`),
    }),
    [t],
  );

  const fetchOptions = useCallback(
    (question, collectionType: ArticleCollectionTypes) => {
      if (question === BudgetQuestionCodes.Q1) {
        Promise.resolve(serviceTypes)
          .then((response) => {
            setItems(response.map(mapServiceTypeToIArticle));
            return response;
          })
          .catch(() => false);
      } else if (question === BudgetQuestionCodes.Q4) {
        Promise.resolve(insurances)
          .then((response) => response.map(mapMasterDataToArticle))
          .then((response) => {
            setItems(response);
            return response;
          })
          .catch(() => false);
      } else if (question === BudgetQuestionCodes.Q22) {
        Promise.resolve(ceremonies)
          .then((response) => response.filter(includeAllowedCeremonyTypes))
          .then((response) => response.map(mapMasterDataToArticle))
          .then((response) => {
            setItems(response);
            return response;
          })
          .catch(() => false);
      } else if (question === BudgetQuestionCodes.Q31
        && collectionType === ArticleCollectionTypes.ARTICLE) {
        Promise.resolve(nicheOwnershipTypes)
          .then((response) => response.map(mapNicheOwershipTypesToIArticle))
          .then((response) => {
            setItems(response);
            return response;
          })
          .catch(() => false);
      } else if (yesNoForms.includes(question)) {
        const optionItems: IArticle[] = [
          {
            code: yesNoValues.YES,
            description: yes(question),
          },
          {
            code: yesNoValues.NO,
            description: no(question),
          },
        ];
        Promise.resolve(optionItems)
          .then((response) => {
            setItems(response);
            return response;
          })
          .catch(() => false);
      } else {
        setLoading(true);

        const budgetHelper = new BudgetHelper(sortedQuestions);

        const inBudget = budgetHelper
          .availableQuestions(questions).includes(question);

        budgetController
          .GetArticlesByQuestionAndRestrict(question, Number(id), inBudget)
          .then((response: IArticlesCollection) => {
            if (mounted) setLoading(false);
            const articleList = response[collectionType] || [];
            setItems(articleList);
            return response;
          })
          .catch(() => {
            setLoading(false);
          });
      }
    },
    [
      mapServiceTypeToIArticle,
      insurances,
      ceremonies,
      mapNicheOwershipTypesToIArticle,
      yes,
      no,
      id,
      questions,
      mounted,
    ],
  );

  return {
    fetchOptions,
    items,
    loading,
  };
};
