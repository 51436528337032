import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/base/Input';
import Modal from 'components/base/Modal';
import Sign from 'components/base/Sign';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { BudgetSummary } from '../../budgetSummary';
import { BudgetSummaryLayout } from '../../budgetSummary/types';

import { BudgetSignModalComponent } from './types';
import './index.scss';

export const BudgetSignModal: FC<BudgetSignModalComponent> = ({
  onCancel,
  show,
  budgetId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { SignBudget, GetBudget } = useBudget();
  const [sign, setSign] = useState<string | undefined>();
  const [signed, setsigned] = useState<boolean>(false);
  const [canSign, setcanSign] = useState<boolean>();

  useEffect(
    () => {
      if (show) {
        GetBudget(budgetId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgetId, show],
  );

  useEffect(
    () => {
      setcanSign(Boolean(sign) || signed);
    },
    [sign, signed],
  );

  useEffect(
    () => {
      if (signed) {
        setSign(undefined);
      }
    },
    [signed],
  );

  const onPressSign = (signature?: string) => {
    setSign(signature);
  };

  return (
    <Modal
      buttons={[
        {
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          disabled: !canSign,
          onClick: () => {
            if (canSign) {
              SignBudget(budgetId, {
                sign,
                signed,
              })
                .then(() => {
                  onSuccess();
                  return {};
                })
                .catch(() => false);
            }
          },
          text: t('common.sign'),
        },
      ]}
      className="f-budget-sign-modal"
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.actions.sign')}
      onHide={onCancel}
    >
      {show
        ? (
          <BudgetSummary
            budgetId={budgetId}
            layout={BudgetSummaryLayout.SIGN}
            showGotoTracking={false}
          />
        )
        : <React.Fragment />}

      <Input
        checked={signed}
        id="budget-signed"
        name="budget-signed"
        placeholder={t('budget.actions.alreadySigned')}
        type="checkbox"
        onChange={(val) => {
          setsigned(Boolean(val));
        }}
      />
      {!signed && (
      <Sign
        acceptCaption={t('budget.actions.signConfirm')}
        height={200}
        showCancel={false}
        width={450}
        showAccept
        showClear
        onCancel={onCancel}
        onClear={() => setSign(undefined)}
        onSign={onPressSign}
      />
      )}
    </Modal>
  );
};
