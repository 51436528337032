import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { LogOut, User, Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Notifications } from 'react-push-notification';
import { Switch, Route, useLocation } from 'react-router-dom';

import config from 'config';

// eslint-disable-next-line import/no-named-as-default
import getMenuByPermissions from 'utils/permissionManager';

import Sidebar, { SidebarOption } from 'components/base/Sidebar';
import { SidebarButtonProps } from 'components/base/Sidebar/SidebarButton';
import Topbar from 'components/base/Topbar';

import LandingPage from 'components/pages/Landing';
import NotFoundPage from 'components/pages/NotFound';
import ViewQr from 'components/pages/qr/table';
import ChangePasswordPage from 'components/pages/user/ChangePassword';
import UserProfilePage from 'components/pages/user/Profile';

import { useCities } from 'hooks/useCities';
import { useNotifications } from 'hooks/useNotifications';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useWindowSize from 'hooks/useWindowSize';
import { ArticleRule } from 'modules/budget/pages/articleRule';
import { BudgetArticles } from 'modules/budget/pages/articles';
import { BudgetSummaryPage } from 'modules/budget/pages/summary';
import { BudgetsTracking } from 'modules/budget/pages/tracking';
import { Wizard } from 'modules/budget/pages/wizard';
import { WizardNew } from 'modules/budget/pages/wizardNew';
import { usePushNotifications } from 'modules/push-notifications/hooks/usePushNotifications';
import WebSocketExample from 'webSocketExample';

import { ArticleInfo } from '../ArticleInfo';
import { Flowers } from '../articles/flowers';

import routesByPermissions, { routesAdmin } from './routesByPermissions';

const Layout: FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [options, setOptions] = useState<SidebarOption[]>([]);
  const {
    exit, user, roleInfo, landingPage,
  } = useProvidedAuth();
  const { lgOrSmaller } = useWindowSize();
  const { t } = useTranslation();
  const [isBudgetWizardLocation, setIsBudgetWizardLocation] = useState<boolean>(false);
  useCities();
  useNotifications();
  const { subscribe, unSubscribe, enabled } = usePushNotifications();
  const [refreshPushSubscribePending, setRefreshPushSubscribePending] = useState<boolean>(true);
  const location = useLocation();

  useEffect(
    () => {
      if (enabled && refreshPushSubscribePending) {
        setRefreshPushSubscribePending(false);
        unSubscribe()
          .then((unSubscribeResponse) => {
            // eslint-disable-next-line promise/no-nesting
            subscribe()
              .then((subscribeResponse) => subscribeResponse)
              .catch(() => false);
            return unSubscribeResponse;
          })
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled, refreshPushSubscribePending],
  );

  useEffect(
    () => {
      const isBudgetWizardPage = location.pathname.includes('budget/wizard');
      const isBudgetSummaryPage = location.pathname.includes('budget/summary');
      setIsBudgetWizardLocation(isBudgetWizardPage || isBudgetSummaryPage);
    },
    [location],
  );

  useEffect(() => {
    if (user?.role?.name) {
      const menu: SidebarOption[] = getMenuByPermissions(
        user.role.permissions,
      );

      if (lgOrSmaller) {
        menu.push({
          content: user?.name,
          icon: <User />,
          key: 'user',
          subsections: [
            {
              content: t('menu.userProfile'),
              exact: true,
              icon: <Edit />,
              key: 'profile',
              url: config.url.userProfile,
            },
            {
              content: t('menu.logout'),
              exact: true,
              icon: <LogOut />,
              key: 'logout',
              onClick: exit,
            },
          ],
        });
      }
      const filtered = landingPage === config.url.landing || !landingPage
        ? menu
        : menu.filter((item: SidebarButtonProps) => item.url !== landingPage);
      setOptions(filtered);
    } else {
      setOptions([]);
    }
  }, [
    lgOrSmaller,
    t,
    exit,
    landingPage,
    user?.role?.name,
    user?.role.permissions,
    user?.name,
  ]);

  let routes = routesByPermissions(user?.role?.permissions) || [];
  if (roleInfo.isAdmin) {
    routes = [...routes, ...routesAdmin()];
  }
  return (
    <main className="app">
      <Notifications />
      {!isBudgetWizardLocation && (
      <aside>
        <Sidebar
          expanded={expanded}
          options={options}
          setExpanded={setExpanded}
        />
      </aside>
      )}

      {!isBudgetWizardLocation && (
      <header className={classNames({ expanded })}>
        <nav>
          <Topbar />
        </nav>
      </header>
      )}

      <div
        className={classNames(expanded ? 'sidebar-expanded' : undefined, { 'f-budget-layout': isBudgetWizardLocation })}
        id="page-container"
      >
        <Switch>
          {routes.map((route) => route)}
          <Route component={WebSocketExample} path="/websocket" exact />
          <Route
            component={ChangePasswordPage}
            path={`${config.url.userProfile}${config.url.changePassword}`}
            exact
          />
          <Route
            component={UserProfilePage}
            path={config.url.userProfile}
            exact
          />
          <Route component={LandingPage} path={config.url.landing} exact />
          <Route component={ViewQr} path={config.url.viewqr} exact />
          <Route
            key={config.url.flowersTraking}
            component={Flowers}
            path={config.url.flowersTraking}
            exact
          />
          <Route
            key={config.url.flowersTrakingByRecord}
            component={Flowers}
            path={config.url.flowersTrakingByRecord}
            exact
          />
          <Route
            key={config.url.articleInfo}
            component={ArticleInfo}
            path={config.url.articleInfo}
            exact
          />
          <Route
            key={config.url.budgetsTracking}
            component={BudgetsTracking}
            path={config.url.budgetsTracking}
            exact
          />
          <Route
            key={config.url.budgetsWizardNew}
            component={WizardNew}
            path={config.url.budgetsWizardNew}
            exact
          />
          <Route
            key={config.url.budgetsWizard}
            component={Wizard}
            path={config.url.budgetsWizard}
            exact
          />
          <Route
            key={config.url.budgetSummary}
            component={BudgetSummaryPage}
            path={config.url.budgetSummary}
            exact
          />
          <Route
            key={config.url.budgetsArticles}
            component={BudgetArticles}
            path={config.url.budgetsArticles}
            exact
          />
          <Route
            key={config.url.budgetsArticleRules}
            component={ArticleRule}
            path={config.url.budgetsArticleRules}
            exact
          />
          <Route
            key={config.url.budgetsArticleIdRules}
            component={ArticleRule}
            path={config.url.budgetsArticleIdRules}
            exact
          />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </main>
  );
};

export default Layout;
