import { MaxFrontOrBackSidesExcededIAError } from './MaxFrontOrBackSidesExcededIAError';
import { RequiredBackSideIAError } from './RequiredBackSideIAError';
import { RequiredFrontSideIAError } from './RequiredFrontSideIAError';
import { RequiredPDFTypeIAError } from './RequiredPDFTypeIAError';
import { IAStatusCodes } from './types/IAStatusCodes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleErrors: ((reason: any) => PromiseLike<never>) | null | undefined = (e) => {
  if (e.status === IAStatusCodes.RequiredPDFType) {
    throw new RequiredPDFTypeIAError(e);
  } else if (e.status === IAStatusCodes.RequiredBackSide) {
    throw new RequiredBackSideIAError(e);
  } else if (e.status === IAStatusCodes.RequiredFrontSide) {
    throw new RequiredFrontSideIAError(e);
  } else if (e.status === IAStatusCodes.MaxFrontOrBackSidesExceded) {
    throw new MaxFrontOrBackSidesExcededIAError(e);
  }

  throw e;
};
