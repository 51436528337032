import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import { useFuneraryOrigins } from 'hooks/useFuneraryOrigins';
import { ReceptionBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, displayDate, displayHour, WorkshopCardHeaderDetail,
} from './CardDetails';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ReceptionDetails = ({ order }: { order: ReceptionBaseWorkOrder }) => {
  const { t } = useTranslation();
  const { getFuneraryOrigin, fetchFuneraryOrigins } = useFuneraryOrigins();

  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);

  useEffect(() => {
    fetchFuneraryOrigins();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDetails([
      {
        label: t('record.reception.funeraryOrigin'),
        value: getFuneraryOrigin(order.funeraryOrigin)?.description || displayNoValue,
      },
      {
        label: t('common.date'),
        value: displayDate(order.receptionDate),
      },
      {
        label: t('common.hour'),
        value: displayHour(order.receptionTime),
      },
    ]);
  }, [
    order,
    t,
    getFuneraryOrigin,
  ]);

  return (<CardDetails details={details} />);
};
