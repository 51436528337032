const enquiry = {
  advisor: 'Assesor',
  center: 'Centre',
  comment: 'Assumpte',
  createdBy: 'Creat per',
  dashboard: {
    card: {
      name: 'nombre',
    },
    completed: 'Resoltes',
    introduced: 'Entrades',
    noEnquiries: 'No hi ha consultes',
    pending: 'Pendents',
  },
  deliveredBudget: 'Lliurat pressupost/estimació',
  detail: 'Detall de consulta',
  edit: 'Edita consulta',
  enquiries: 'Consultes',
  enquiry: 'Consulta',
  finishEnquiry: 'Finalitzar consulta',
  last: 'Últimes consultes',
  new: 'Nova consulta',
  notAssigned: 'No assignada',
  preneed: 'Prenecessitat',
  saveKo: 'S\'ha produït un error a l\'hora de crear la consulta.',
  saveOk: 'La consulta s\'ha creat satisfactòriament.',
  search: 'Cercar consultes',
  states: {
    completed: 'Resolta',
    introduced: 'Entrada',
    pending: 'Pendent',
  },
  status: 'Estat',
  title: 'Consultes',
};
export default enquiry;
