import { useEffect, useState } from 'react';

import { normalizeMasterData } from 'utils/normalizer';

import { getCemeterySections } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { NormalizedType } from 'models/MasterService';

type CemeterySectionType = {
  cemeterySections: IMasterDataDto[];
  normalizedCemeterySections: NormalizedType;
};

const useCemeterySection = (
  cityCode?: string,
  cemeteryCode?: string,
): CemeterySectionType => {
  const [cemeteryLoaded, setCemeteryLoaded] = useState<string>();
  const [cityLoaded, setCityLoaded] = useState<string>();
  const [normalizedCemeterySections, setNormalizedCemeterySections] = useState(
    {},
  );
  const [cemeterySections, setCemeterySections] = useState<IMasterDataDto[]>(
    [],
  );

  const getSections = async () => {
    getCemeterySections(cityCode, cemeteryCode)
      .then((response) => {
        setCemeterySections(response.data as IMasterDataDto[]);
        return response.data;
      })
      .catch(() => {
        setCemeterySections([]);
      });
  };

  useEffect(() => {
    if (cityCode !== cityLoaded || cemeteryCode !== cemeteryLoaded) {
      setCemeteryLoaded(cemeteryCode);
      setCityLoaded(cityCode);
      getSections();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode, cemeteryCode]);

  useEffect(() => {
    setNormalizedCemeterySections(normalizeMasterData(cemeterySections));
  }, [cemeterySections]);

  return { cemeterySections, normalizedCemeterySections };
};

export default useCemeterySection;
