import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatHour } from 'utils/dateManager';

import { useCenters } from 'hooks/useCenters';
import { useDeathLocation } from 'hooks/useDeathLocation';
import Address from 'models/Address';
import { CollectionOrder } from 'models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const PickupInfo: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { getCenter } = useCenters();
  const { t } = useTranslation();
  const { getDeathLocationByCode, deathLocations } = useDeathLocation(
    ((order.info as CollectionOrder)?.location as Address)?.city,
  );

  useEffect(() => {
    const info: CollectionOrder = order.info as CollectionOrder;

    const newDetails: DetailType[] = [
      {
        text: t('order.details.creationHour'),
        value: formatHour(order.createdDate),
      },
      {
        text: t('order.details.location'),
        value: info?.pickupLocation,
      },
      {
        text: t('common.location'),
        value: getDeathLocationByCode(info?.deathLocation)?.description,
      },
      {
        text: t('common.phone'),
        value: info?.declarant?.phone,
      },
      {
        text: t('order.details.destination'),
        value: getCenter(info?.destination)?.description,
      },
      {
        text: t('order.vehicle'),
        value: info?.vehicle,
      },
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.adviserComment'),
        value: info?.deathComment,
      },
      {
        text: t('order.driver'),
        value: info?.driver,
      },
      {
        text: t('order.details.funerariComment'),
        value: info?.deposit?.comment,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
    ];
    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, deathLocations]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default PickupInfo;
