import { useEffect, useState } from 'react';

import { normalizeMasterData } from 'utils/normalizer';

import { getDeposits } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { NormalizedType } from 'models/MasterService';

type DepositType = {
  deposits: IMasterDataDto[];
  normalizedDeposits: NormalizedType;
};

const useDeposit = (cityCode?: string): DepositType => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  const [normalizedDeposits, setNormalizedDeposits] = useState({});
  const [deposits, setDeposits] = useState<IMasterDataDto[]>([]);

  const getDeposit = async () => {
    getDeposits(cityCode)
      .then((response) => {
        setDeposits(response.data as IMasterDataDto[]);
        return response.data;
      })
      .catch(() => {
        setDeposits([]);
      });
  };

  useEffect(() => {
    if (cityCode !== cityLoaded) {
      setCityLoaded(cityCode);
      getDeposit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  useEffect(() => {
    setNormalizedDeposits(normalizeMasterData(deposits));
  }, [deposits]);

  return { deposits, normalizedDeposits };
};

export default useDeposit;
