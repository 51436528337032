import { FormikProps } from 'formik';
import React, { Fragment } from 'react';

import Input from 'components/base/Input';
import Select from 'components/base/Select';
import Textarea from 'components/base/Textarea';

import { useCenters } from 'hooks/useCenters';
import useUsers from 'hooks/useUsers';
import { i18n } from 'i18n';
import { SaveEnquiry } from 'models/Enquiry';
import { IMasterDataDto } from 'models/MasterData';

export const EnquireStates = [
  {
    code: 'INTRODUCED',
    description: i18n.t('enquiry.states.introduced'),
  },
  {
    code: 'PENDING',
    description: i18n.t('enquiry.states.pending'),
  },
  {
    code: 'COMPLETED',
    description: i18n.t('enquiry.states.completed'),
  },
];

export const GetEnquiryFormFields = (
  {
    errors,
    handleBlur,
    isSubmitting,
    setFieldValue,
    values,
  }: FormikProps<SaveEnquiry>,
  disabled?: boolean,
  editing?: boolean,
): JSX.Element => {
  const { users } = useUsers([]);
  const { centers } = useCenters();

  return (
    <Fragment>
      <Input
        data-testid="clientName"
        disabled={isSubmitting || disabled}
        error={errors.clientName}
        id="clientName"
        name="clientName"
        placeholder={i18n.t('common.name')}
        type="text"
        value={values.clientName || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('clientName', val)}
      />
      <Input
        data-testid="firstSurname"
        disabled={disabled || isSubmitting}
        error={errors.firstSurname}
        id="firstSurname"
        name="firstSurname"
        placeholder={i18n.t('common.firstSurname')}
        type="text"
        value={values.firstSurname || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('firstSurname', val)}
      />
      <Input
        data-testid="secondSurname"
        disabled={disabled || isSubmitting}
        error={errors.secondSurname}
        id="secondSurname"
        name="secondSurname"
        placeholder={i18n.t('common.secondSurname')}
        type="text"
        value={values.secondSurname || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('secondSurname', val)}
      />
      <Input
        data-testid="email"
        disabled={isSubmitting || disabled}
        error={errors.email}
        id="email"
        name="email"
        placeholder={i18n.t('common.email')}
        type="text"
        value={values.email || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('email', val)}
      />
      <Input
        data-testid="phone"
        disabled={isSubmitting || disabled}
        error={errors.phone}
        id="phone"
        name="phone"
        placeholder={i18n.t('common.phone')}
        type="text"
        value={values.phone || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('phone', val)}
      />
      <div />
      <Select
        disabled={isSubmitting || disabled}
        getLabel={(option: IMasterDataDto) => option.description}
        getValue={(option: IMasterDataDto) => option.code}
        id="center"
        name="center"
        options={centers}
        placeholder={i18n.t('enquiry.center')}
        value={centers.find((e: IMasterDataDto) => e.code === values.center) || undefined}
        onChange={(val) => setFieldValue('center', (val as IMasterDataDto)?.code)}
      />
      <Textarea
        data-testid="comment"
        disabled={editing || isSubmitting || disabled}
        error={errors.comment}
        id="comment"
        name="comment"
        placeholder={i18n.t('enquiry.comment')}
        value={values.comment || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('comment', val)}
      />
      {(editing || (!editing && disabled)) && (
        <Select
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getLabel={(option: any) => option.description}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getValue={(option: any) => option.code}
          id="status"
          name="status"
          options={EnquireStates}
          placeholder={i18n.t('enquiry.status')}
          value={EnquireStates.find((e) => e.code === values.status) || ''}
          disabled
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(val) => setFieldValue('status', (val as any)?.code)}
        />
      )}
      {(editing || (!editing && disabled)) && (
        <Input
          error={errors.coordinatorComment}
          id="coordinatorComment"
          name="coordinatorComment"
          placeholder={i18n.t('common.coordinatorComment')}
          type="text"
          value={values.coordinatorComment || ''}
          disabled
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('coordinatorComment', val)}
        />
      )}
      {(editing || (!editing && disabled)) && (
        <Select
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getLabel={(option: any) => `${option.name} ${option?.firstSurname || ''} ${
            option?.secondSurname || ''
          }`}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getValue={(option: any) => option.id}
          id="advisor"
          name="advisor"
          options={users}
          placeholder={i18n.t('enquiry.advisor')}
          value={users.find((u) => u.id === values?.advisor?.id)}
          disabled
          onChange={(val) => setFieldValue('advisor', val)}
        />
      )}
      {(editing || (!editing && disabled)) && (
        <Input
          error={errors.advisorComment}
          id="advisorComment"
          name="advisorComment"
          placeholder={i18n.t('common.adviserComment')}
          type="text"
          value={values.advisorComment || ''}
          disabled
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('advisorComment', val)}
        />
      )}
      {(editing || (!editing && disabled)) && (
        <Input
          error={errors.createdBy}
          id="creator"
          name="creator"
          placeholder={i18n.t('enquiry.createdBy')}
          type="text"
          value={`${values.creator?.name || ''} ${values.creator?.firstSurname || ''} ${values.creator?.secondSurname || ''}` || ''}
          disabled
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('createdBy', val)}
        />
      )}
    </Fragment>
  );
};

export const enquiryFormInitialValues = {
  clientName: '',
  comment: '',
  email: '',
  firstSurname: '',
  phone: '',
  secondSurname: '',
};
