import classNames from 'classnames';
import React, { FC, Ref } from 'react';
import { X } from 'react-feather';

import Button from 'components/base/Button';

import './index.scss';

type ExpandableBoxProps = {
  className?: string | undefined;
  divRef?: Ref<HTMLDivElement> | undefined;
  expanded?: boolean | undefined;
  onClose?(): void | undefined;
  showClose?: boolean | undefined;
};

const ExpandableBox: FC<ExpandableBoxProps> = ({
  children,
  className,
  divRef,
  expanded,
  onClose,
  showClose,
}) => {
  const onClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      ref={divRef}
      className={classNames('expandable-box', className, { expanded })}
    >
      {showClose && (
        <Button className="close-button" color="transparent" onClick={onClick}>
          <X />
        </Button>
      )}
      {children}
    </div>
  );
};

ExpandableBox.defaultProps = {
  className: undefined,
  divRef: undefined,
  expanded: false,
  onClose: undefined,
  showClose: true,
};

export default ExpandableBox;
