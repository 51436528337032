export enum NicheOwnershipTypes {
  NEW = 'NEW',
  OLD = 'OLD'
}

export enum ServiceTypes {
  DEFUNCIO = 'DEFUNCIO',
  AMPUTACIO = 'AMPUTACIO',
  AVORTAMENT = 'AVORTAMENT',
  DESPULLES = 'DESPULLES',
  INHUMACIO_CENDRES = 'INHUMACIO_CENDRES'
}

export const dismissServiceId = 'DISMISS_SERVICE';
