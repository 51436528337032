import classNames from 'classnames';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps } from 'components/base/Button';
import Modal from 'components/base/Modal';
import Select from 'components/base/Select';

import {
  OrderLineChannel, MasterDataDto, OrderLine,
} from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';

import { AddArticleRequestForm } from '../addArticleRequestForm';
import { AddExternalFlowersForm, ExternalFlower } from '../addExternalFlowersForm';

import './index.scss';

type AddFlowersRequestModalComponent = {
  onCancel: () => void;
  onSuccess: (requestChannel?: OrderLineChannel, articleId?: number) => void;
  show: boolean;
  erpId: string | number | undefined;
  id: string | number | undefined;
  vigilHour?: string | undefined;
  vigilDate?: string | undefined;
}

export const AddFlowersRequestModal: FC<AddFlowersRequestModalComponent> = (
  {
    show, onCancel, onSuccess, erpId, id, vigilHour, vigilDate,
  },
) => {
  const { t } = useTranslation();
  const [payload, setPayload] = useState<OrderLine>();
  const { createArticle, articleRequestTypes, fetchArticleRequestTypes } = useArticles();
  const [requestChannel, setRequestChannel] = useState<OrderLineChannel | undefined>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [articleDescription, setArticleDescription] = useState<string>('');
  const [observations, setObservations] = useState<string>('');
  const [supplierDescription, setSupplierDescription] = useState<string>('');
  const [formValid, setFormValid] = useState<boolean>(false);
  const [modalButtons, setModalButtons] = useState<ButtonProps[]>([]);
  const [confirmationMessage, setConfirmationMessage] = useState<string>();

  useEffect(() => {
    setConfirmationMessage(t('article.confirmAddRequest', { article: articleDescription, observations, supplier: supplierDescription }));
  }, [articleDescription, observations, supplierDescription, t]);

  const onConfirm = useCallback(
    async () => {
      if (payload) {
        await createArticle(payload)
          .then((response) => {
            setShowConfirm(false);
            onSuccess(requestChannel);
            return response;
          })
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSuccess, payload, requestChannel],
  );

  const accept = useCallback(
    async () => {
      if (payload && onSuccess) {
        if (requestChannel === OrderLineChannel.PROCESSING) {
          setShowConfirm(true);
        } else {
          await createArticle(payload)
            .then((response) => {
              onSuccess(requestChannel, response);
              return response;
            })
            .catch(() => false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payload, onSuccess, requestChannel],
  );

  useEffect(() => {
    const actionButtons: ButtonProps[] = [{
      onClick: () => {
        if (showConfirm) {
          setShowConfirm(false);
        } else {
          setRequestChannel(undefined);
          onCancel();
        }
      },
      text: t('common.cancel'),
    }];

    if (!showConfirm) {
      actionButtons.push({
        disabled: !requestChannel || !formValid,
        onClick: () => {
          accept();
        },
        text: t('common.accept'),
      });
    }

    if (showConfirm) {
      actionButtons.push({
        onClick: () => {
          onConfirm();
        },
        text: t('common.confirm'),
      });
    }

    setModalButtons(actionButtons);
  }, [accept, formValid, onCancel, onConfirm, requestChannel, showConfirm, t]);

  useEffect(() => {
    setObservations(payload?.observations || '');
  }, [payload?.observations]);

  useEffect(
    () => {
      if (show) {
        fetchArticleRequestTypes()
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  );

  return (
    <React.Fragment>
      <Modal
        buttons={modalButtons}
        className="add-flowers-request-modal"
        show={show}
        showCancel={false}
        showXButton={false}
        title={t('article.addFlowers')}
        onHide={() => false}
      >
        <Select<MasterDataDto>
          key="request-type"
          className={classNames('request-type', { hidden: showConfirm })}
          getLabel={({ description }) => description || ''}
          getValue={({ code }) => code || ''}
          name="article.requestType"
          options={articleRequestTypes}
          placeholder={t('article.requestType')}
          value={articleRequestTypes.find((requestType) => requestType.code === requestChannel)}
          searchable
          onChange={(val) => setRequestChannel(val?.code as OrderLineChannel)}
        />
        {requestChannel === OrderLineChannel.RECEPTION && (
        <AddArticleRequestForm
          articleDisabled={false}
          articleTypeDisabled={false}
          channel={OrderLineChannel.RECEPTION}
          clientDisabled={false}
          erpId={erpId}
          id={id}
          priceDisabled={false}
          providerDisabled={false}
          onChange={(p) => setPayload(p)}
          onIsValidUpdate={(isValid) => setFormValid(isValid)}
        />
        )}

        {requestChannel === OrderLineChannel.WEB && (
        <AddArticleRequestForm
          articleDisabled={false}
          articleTypeDisabled={false}
          channel={OrderLineChannel.WEB}
          clientDisabled={false}
          erpId={erpId}
          id={id}
          priceDisabled={false}
          providerDisabled={false}
          vigilDate={vigilDate}
          vigilHour={vigilHour}
          onChange={(p) => setPayload(p)}
          onIsValidUpdate={(isValid) => setFormValid(isValid)}
        />
        )}

        {requestChannel === OrderLineChannel.PROCESSING
          && (
            <div className={classNames({ hidden: showConfirm })}>
              <AddArticleRequestForm
                articleDisabled={false}
                articleTypeDisabled={false}
                channel={OrderLineChannel.PROCESSING}
                clientDisabled={false}
                erpId={erpId}
                id={id}
                priceDisabled={false}
                providerDisabled={false}
                vigilDate={vigilDate}
                vigilHour={vigilHour}
                onArticleChange={(value) => setArticleDescription(value)}
                onChange={(p) => setPayload(p)}
                onIsValidUpdate={(isValid) => setFormValid(isValid)}
                onSupplierChange={(value) => setSupplierDescription(value)}
              />
            </div>
          )}

        {requestChannel === OrderLineChannel.PROCESSING && showConfirm
          && (<div>{confirmationMessage}</div>)}

        {requestChannel === OrderLineChannel.EXTERNAL && (
        <AddExternalFlowersForm
          erpId={erpId}
          onChange={(values: ExternalFlower) => setPayload(
            {
              erpId: Number(erpId),
              ...values,
            },
          )}
          onIsValidUpdate={(isValid) => setFormValid(isValid)}
        />
        )}

      </Modal>

    </React.Fragment>

  );
};
