import { Middleware } from 'redux';

import {
  GoNextQuestion, GotoSummary, saveBudget,

} from '../budgetActions';
import { BudgetActionTypes } from '../budgetActions/types';

export const RefreshBudgetWhenAnswerQuestion: Middleware = (
  storeApi,
) => (next) => async (action) => {
  if (
    action.type === BudgetActionTypes.ANSWER_QUESTION) {
    const { gotoSummary } = action.payload;
    const result = next(action);

    storeApi.dispatch(saveBudget({
      callBack: () => {
        if (!gotoSummary) {
          storeApi.dispatch(GoNextQuestion({
            currentQuestion: storeApi.getState().budget.currentQuestion,
            questionCollection: storeApi.getState().budget.questions,
          }));
        } else {
          storeApi.dispatch(GotoSummary());
        }
      },
    }));

    return result;
  }

  return next(action);
};
