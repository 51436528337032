import React, {
  FC, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useWindow } from 'hooks/useWindow';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

import { ViewFlowerModalComponent } from './types';
import { useFlowerPhotos } from './useFlowerPhotos';

export const ViewFlowerModal: FC<ViewFlowerModalComponent> = ({ onHide, show, id }) => {
  const { t } = useTranslation();
  const { openInNewTab } = useWindow();
  const { fetchPhoto, data } = useFlowerPhotos();

  useEffect(() => {
    if (id && show) {
      fetchPhoto(id);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [id, show]);

  const openForPrint = useCallback(
    () => {
      if (data) {
        openInNewTab(new Base64Data({ data, mimetype: MimeTypes.IMAGE_PNG }).toBlob());
      }
    },
    [data, openInNewTab],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onHide();
          },
          text: t('common.close'),
        },
        {
          disabled: !data,
          onClick: () => {
            openForPrint();
          },
          text: t('common.print'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('article.flowers.printTitle')}
      onHide={onHide}
    >
      <span>
        {data && (
        <img
          alt="Add icon"
          className="image"
          src={new Base64Data({ data, mimetype: MimeTypes.IMAGE_PNG }).toDataUrl()}
        />
        )}
      </span>
      {' '}

    </Modal>
  );
};
