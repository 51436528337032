const enquiry = {
  advisor: 'Coordinador',
  center: 'Centro',
  comment: 'Asunto',
  createdBy: 'Creado por',
  dashboard: {
    card: {
      name: 'Nombre',
    },
    completed: 'Resueltas',
    introduced: 'Pendientes',
    noEnquiries: 'No hay consultas',
    pending: 'Entradas',
  },
  deliveredBudget: 'Entregado presupuesto/estimación',
  detail: 'Detalle de consulta',
  edit: 'Editar consulta',
  enquiries: 'Consultas',
  enquiry: 'Consulta',
  finishEnquiry: 'Finalizar consulta',
  last: 'Últimas consultas',
  new: 'Nueva consulta',
  notAssigned: 'No asignada',
  preneed: 'Prenecesidad',
  saveKo: 'Se ha producido un error al crear la consulta.',
  saveOk: 'La consulta se ha creado satisfactoriamente.',
  search: 'Buscar consultas',
  states: {
    completed: 'Resuelta',
    introduced: 'Pendiente',
    pending: 'Entrada',
  },
  status: 'Estado',
  title: 'Consultas',
};
export default enquiry;
