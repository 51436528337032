import { ServiceTypes, NicheOwnershipTypes } from 'modules/budget/store/data';

export const serviceTypes = [
  ServiceTypes.DEFUNCIO,
  ServiceTypes.AMPUTACIO,
  ServiceTypes.AVORTAMENT,
  ServiceTypes.DESPULLES,
  ServiceTypes.INHUMACIO_CENDRES,
];

export const nicheOwnershipTypes = [
  NicheOwnershipTypes.NEW,
  NicheOwnershipTypes.OLD,
];
