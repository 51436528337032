import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

export const mapBase64ToPersonalDataDocument = (data: Base64Data, type: MimeTypes): string => {
  if (type === MimeTypes.IMAGE_PNG) {
    return data.toDataUrl();
  } if (type === MimeTypes.APPLICATION_PDF) {
    return data.data;
  }
  return '';
};
