import {
  Form, Formik, FormikProps, getIn,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { Row } from 'components/base/Row';
import Textarea from 'components/base/Textarea';

import { EmailFormProps, EmailFormComponent } from './types';
import { useEmailFormValidation } from './validation';

export const EmailForm: FC<EmailFormComponent> = ({ onChange }) => {
  const { t } = useTranslation();
  const { validate } = useEmailFormValidation();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      isInitialValid={false}
      validationSchema={validate}
      validateOnChange
      onSubmit={(values, { resetForm }) => {
        onChange({
          email: values.email,
        });
        resetForm();
      }}
    >
      {({
        errors, handleBlur, setFieldValue, values, isValid,
      }: FormikProps<EmailFormProps>) => (
        <React.Fragment>
          <Form autoComplete="off">
            <Row justifyContent="space-around">
              <Textarea
                error={getIn(errors, 'email')}
                id="email"
                name="email"
                placeholder={t('common.email')}
                value={values.email}
                onBlur={handleBlur}
                onChange={(val) => setFieldValue('email', val)}
              />
              <Button
                disabled={!isValid}
                text="add"
                type="submit"
              />
            </Row>
          </Form>
        </React.Fragment>
      )}
    </Formik>
  );
};
