enum OrderType {
  GRAVESTONE = 'LAPIDA',
  INTERNAL_MOVEMENT = 'MOVIMIENTO_INTERNO',
  RECEPTION = 'RECEPCION',
  PICKUP = 'RECOGIDA',
  TRANSFER = 'TRASLADO',
  TRANSFER_REMAINS = 'TRASLADO_RESTOS',
  TRANSPORT = 'TRANSPORTE',
  CEREMONY = 'CEREMONIA',
  INCINERATION = 'INCINERACION',
  INHUMATION = 'INHUMACION',
  PREPARATION_NICHO = 'PREPARACION_NICHO',
  PREPARATION = 'PREPARACION',
  SANITARY_PRACTICE = 'PRACTICA_SANITARIA',
  HANDBOOK = 'MANUAL_TALLER',
  HANDBOOK_CEMETERY = 'MANUAL_CEMENTERIO',
  MOVEMENT = 'MOVIMIENTO',
  VIGIL = 'VELATORIO',
}

export default OrderType;
