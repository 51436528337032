import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { isBoolean } from 'utils/helpers';

import { Column } from 'components/base/Column';

import { ItemSelector } from 'modules/budget/components/articleSelectors/ItemSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionSettings } from 'modules/budget/hooks/useQuestionSettings';
import { Article } from 'modules/budget/models/Article';
import { dismissServiceId } from 'modules/budget/store/data';

export interface Q11Response {
  dismissService?: boolean;
  articles: Article[] | null;
  autorespond: boolean;
}

interface Q11FormComponent {
  onChange: (value: Q11Response) => void;
  question: BudgetQuestionCodes;
}

export const Q11Form: FC<Q11FormComponent> = (
  { onChange, question },
) => {
  const { GetQuestionValue } = useBudget();
  const { isMultiselect, isQuantityEnabled } = useQuestionSettings();
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [selectedDismiss, setSelectedDismiss] = useState<boolean>();

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.articles) {
        onChange({
          articles: storedValue?.articles,
          autorespond: false,
          dismissService: storedValue?.articles.length === 0,
        });
        setSelectedArticles(storedValue?.articles);
        setSelectedDismiss(storedValue?.articles.length === 0);
      }
    },
    [onChange, storedValue?.articles],
  );

  return (
    <Formik
      initialValues={{
        articles: selectedArticles,
        autorespond: false,
        dismissService: selectedDismiss,
      }}
      validate={(values) => {
        onChange({
          articles: values.articles || [],
          autorespond: values.autorespond,
          dismissService: isBoolean(values.dismissService) ? values.dismissService : undefined,
        });
        setSelectedArticles(values.articles || []);
        setSelectedDismiss(isBoolean(values.dismissService) ? values.dismissService : undefined);
        return {};
      }}
      enableReinitialize
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues, values,
      }: FormikProps<Q11Response>) => (
        <Column>
          <ItemSelector
            dismissService={values.dismissService}
            multiselect={isMultiselect(question)}
            question={question}
            value={values.articles || []}
            onChange={(articles?: Article[]) => {
              if (articles?.find((article) => article.code === dismissServiceId)) {
                setValues({
                  articles: [],
                  autorespond: !isMultiselect(question) && !isQuantityEnabled(question),
                  dismissService: true,
                });
              } else if (articles?.length !== 0) {
                setValues({
                  articles: articles || [],
                  autorespond: !isMultiselect(question) && !isQuantityEnabled(question),
                  dismissService: false,
                });
              } else {
                setValues({
                  articles: null,
                  autorespond: false,
                  dismissService: false,
                });
              }
            }}
          />
        </Column>
      )}
    </Formik>
  );
};
