import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useCemetery from 'hooks/useCemetery';
import { NichePreparation } from 'models/OrderInfo';
import { RootState } from 'store';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const NichePreparationInfo: FC<DefaultInfoProps> = ({
  expanded,
  loading,
  order,
}) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();
  const { normalized } = useSelector((state: RootState) => state);
  const { normalizedCemeteries } = useCemetery(
    (order.info as NichePreparation)?.location?.city,
  );

  useEffect(() => {
    const info: NichePreparation = (order.info || {}) as NichePreparation;

    const newDetails: DetailType[] = [
      {
        text: t('order.details.cemetery'),
        value:
          info.cemetery && normalizedCemeteries[info.cemetery]?.description,
      },
      {
        text: t('common.address'),
        value: info.location,
      },
      {
        text: t('service.interment.nichePreparationDate'),
        value: info.date,
      },
      {
        text: t('service.interment.nichePreparationTime'),
        value: info.time,
      },
      {
        text: t('service.interment.ashes'),
        value: info.ashes,
      },
      {
        text: t('order.details.niche'),
        value: info.niche
          ? normalized.nicheTypes[info.niche]?.description
          : info.niche,
      },
      {
        text: t('service.interment.number'),
        value: info.nicheNumber,
      },
      {
        text: t('service.interment.section'),
        value: info.nicheSection,
      },
      {
        text: t('service.interment.floor'),
        value: info.nicheTier,
      },
      {
        text: t('service.interment.measurementsAndPhotos'),
        value: info.measurementsAndPhotos,
      },
      {
        text: t('record.advisers'),
        value: info.mainNegociator,
      },
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.adviserComment'),
        value: info?.comment,
      },
      {
        text: t('service.interment.concession.holder'),
        value: info.holder,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
    ];
    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, normalizedCemeteries]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default NichePreparationInfo;
