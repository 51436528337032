import { isBoolean } from 'utils/helpers';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { BudgetHelper } from 'modules/budget/helpers/BudgetHelper';
import { IArticle, ArticleCollectionTypes } from 'modules/budget/models/Article/types';
import { InvoiceArticle } from 'modules/budget/models/InvoiceArticle';

import { sortedQuestions } from '../../data/sortedQuestions';
import { Budget } from '../../models/Budget';
import { BudgetActionTypes, BudgetAction } from '../budgetActions/types';

import { initialBudgetState } from './data';
import { BudgetState } from './types';

export const budgetReducer = (
  state: BudgetState = initialBudgetState,
  action: BudgetAction,
): BudgetState => {
  switch (action.type) {
    case BudgetActionTypes.RESET: {
      return {
        ...initialBudgetState,
      };
    }

    case BudgetActionTypes.SET_BUDGET: {
      const budget = action.payload.budget as Budget;

      return {
        ...state,
        automaticArticles: budget.automaticArticles,
        collected: budget.collected,
        createdBy: budget.createdBy,
        createdDate: budget.createdDate,
        discardedArticles: budget.discardedArticles,
        id: budget.id,
        idRecord: budget.idRecord,
        idVersion: budget.idVersion,
        questions: budget.questions,
        status: budget.status,
      };
    }

    case BudgetActionTypes.SET_Q10_SIMULATION: {
      const budget = action.payload.budget as Budget;
      const { articleId } = action.payload;

      const updatedQ10Simulations = state.Q10Simulations;
      updatedQ10Simulations.set(articleId, budget);

      return {
        ...state,
        Q10Simulations: new Map(Array.from(updatedQ10Simulations)),
      };
    }

    case BudgetActionTypes.CLEAR_Q10_SIMULATION: {
      const { articleId } = action.payload;
      const updatedQ10Simulations = state.Q10Simulations;
      updatedQ10Simulations.delete(articleId);

      return {
        ...state,
        Q10Simulations: new Map(Array.from(updatedQ10Simulations)),
      };
    }

    case BudgetActionTypes.CLEAR_ARTICLES: {
      return {
        ...state,
        articles: [],
      };
    }

    case BudgetActionTypes.GO_NEXT: {
      const budgetHelper = new BudgetHelper(sortedQuestions);

      if (state.currentQuestion) {
        return {
          ...state,
          currentQuestion: budgetHelper
            .nextQuestion(state.currentQuestion, state.questions, state.collected),
        };
      }

      return state;
    }

    case BudgetActionTypes.GO_SUMMARY: {
      return {
        ...state,
        currentQuestion: BudgetQuestionCodes.SUMMARY,
      };
    }

    case BudgetActionTypes.GO_BACK: {
      const budgetHelper = new BudgetHelper(sortedQuestions);

      return {
        ...state,
        currentQuestion: state.currentQuestion
          ? budgetHelper
            .prevQuestion(state.currentQuestion, state.questions, state.collected)
          : state.currentQuestion,
      };
    }

    case BudgetActionTypes.SET_CURRENT_QUESTION: {
      const { questionCode } = action.payload;

      return {
        ...state,
        currentQuestion: questionCode,
      };
    }

    case BudgetActionTypes.ADD_DISCARDED_ARTICLE: {
      const { article } = action.payload;

      const discardedList = state.discardedArticles.slice();
      discardedList.push(article);

      return {
        ...state,
        discardedArticles: discardedList,
      };
    }

    case BudgetActionTypes.REMOVE_DISCARDED_ARTICLE: {
      const { article } = action.payload;

      const discardedList = state.discardedArticles.slice();
      const discardedIndex = discardedList.findIndex((listItem) => listItem.code === article.code);
      discardedList.splice(discardedIndex, 1);

      return {
        ...state,
        discardedArticles: discardedList,
      };
    }

    case BudgetActionTypes.SET_ARTICLE_COLLECTION_PRICE: {
      const {
        articles,
        overwrite,
      } = action.payload;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updateQuestions: any = state?.questions;
      let updatedAutomaticArticles = state.automaticArticles.slice();
      articles.forEach((article: IArticle) => {
        const { fixedPrice } = article;
        if (article.prices?.length !== 0) {
          if (article.subType
          && article.prices
          && article.prices?.[0]) {
            const { price } = article.prices[0];
            const { vat } = article.prices[0];

            if (article.questionCode) {
              const question = article.questionCode;
              const { subType } = article;
              const storedQuestion = updateQuestions[question as BudgetQuestionCodes];
              const questionArticles = storedQuestion?.articles;
              const questionArticlesByType = questionArticles?.[subType as ArticleCollectionTypes];
              let articlesCollection = questionArticlesByType?.slice();

              if (articlesCollection) {
                const currentArticle = articlesCollection.find(
                  (articleItem: IArticle) => articleItem.code === article.code,
                );
                const currentFixedPrice = isBoolean(fixedPrice)
                  ? fixedPrice
                  : currentArticle.fixedPrice;
                const doUpdatePrice = !currentFixedPrice || (currentFixedPrice && overwrite);
                if (currentArticle && doUpdatePrice) {
                  articlesCollection = [
                    ...articlesCollection.filter(
                      (item: IArticle) => item.code !== article.code,
                    ),
                    {
                      ...currentArticle,
                      fixedPrice: Boolean(currentFixedPrice),
                      price,
                      vat,
                    },
                  ];
                }

                updateQuestions[question] = {
                  ...state.questions[question as BudgetQuestionCodes],
                  articles: {
                    ...state.questions[question as BudgetQuestionCodes]?.articles,
                    [subType]: articlesCollection,
                  },
                };
              }
            } else {
              const currentArticle = updatedAutomaticArticles
                .find((automaticArticle: IArticle) => automaticArticle.code === article.code);
              const currentFixedPrice = isBoolean(fixedPrice)
                ? fixedPrice
                : currentArticle?.fixedPrice;
              const doUpdatePrice = !currentFixedPrice || (currentFixedPrice && overwrite);
              if (currentArticle && doUpdatePrice) {
                updatedAutomaticArticles = [
                  ...updatedAutomaticArticles.filter(
                    (articleItem) => articleItem.code !== article.code,
                  ),
                  {
                    ...currentArticle,
                    fixedPrice: Boolean(currentFixedPrice),
                    price,
                    vat,
                  },
                ];
              }
            }
          }
        }
      });

      return {
        ...state,
        automaticArticles: updatedAutomaticArticles,
        questions: {
          ...state.questions,
          ...updateQuestions,
        },
      };
    }

    case BudgetActionTypes.UPDATE_ARTICLE_PROVIDER: {
      const {
        articleId, providerId,
      } = action.payload;

      return {
        ...state,
        invoiceArticles: state.invoiceArticles
          .map((article) => {
            if (article.id !== articleId) return article;

            return new InvoiceArticle({
              ...article,
              provider: providerId,
            });
          }),
      };
    }

    case BudgetActionTypes.UPDATE_ARTICLE_CLIENT: {
      const {
        articleId, clientId,
      } = action.payload;

      return {
        ...state,
        invoiceArticles: state.invoiceArticles
          .map((article) => {
            if (article.id !== articleId) return article;

            return new InvoiceArticle({
              ...article,
              client: clientId,
            });
          }),
      };
    }

    case BudgetActionTypes.SET_INVOICE_ARTICLES: {
      const {
        articles,
      } = action.payload;

      return {
        ...state,
        invoiceArticles: articles,
      };
    }

    case BudgetActionTypes.SET_INVOICE_CLIENTS: {
      const {
        clients,
      } = action.payload;

      return {
        ...state,
        invoiceClients: clients,
      };
    }

    case BudgetActionTypes.SET_INVOICE_ARTICLES_LOADING: {
      const { loading } = action.payload;

      return {
        ...state,
        invoiceArticlesLoading: loading,
      };
    }

    case BudgetActionTypes.CLEAR_QUESTION: {
      const { questionCode } = action.payload;
      return {
        ...state,
        questions: {
          ...state.questions,
          [questionCode]: null,
        },
      };
    }

    case BudgetActionTypes.SET_SUMMARIZE_LOADING: {
      return {
        ...state,
        summarizeLoading: action.payload,
      };
    }

    case BudgetActionTypes.SET_SUMMARIZE: {
      const { summarize } = action.payload;
      return {
        ...state,
        summarize,
      };
    }

    case BudgetActionTypes.CLEAR_SUMMARIZE: {
      return {
        ...state,
        summarize: null,
      };
    }

    case BudgetActionTypes.SET_BUDGET_LOADING: {
      return {
        ...state,
        budgetLoading: action.payload,
      };
    }

    case BudgetActionTypes.ANSWER_QUESTION: {
      const { question, response } = action.payload;
      const budgetHelper = new BudgetHelper(sortedQuestions);

      switch (question) {
        case BudgetQuestionCodes.Q1:
        case BudgetQuestionCodes.Q2:
        case BudgetQuestionCodes.Q3:
        case BudgetQuestionCodes.Q4:
        case BudgetQuestionCodes.Q5:
        case BudgetQuestionCodes.Q6:
        case BudgetQuestionCodes.Q7:
        case BudgetQuestionCodes.Q8:
        case BudgetQuestionCodes.Q9:
        case BudgetQuestionCodes.Q10:
        case BudgetQuestionCodes.Q11:
        case BudgetQuestionCodes.Q12:
        case BudgetQuestionCodes.Q13:
        case BudgetQuestionCodes.Q14:
        case BudgetQuestionCodes.Q15:
        case BudgetQuestionCodes.Q17:
        case BudgetQuestionCodes.Q19:
        case BudgetQuestionCodes.Q21:
        case BudgetQuestionCodes.Q22:
        case BudgetQuestionCodes.Q23:
        case BudgetQuestionCodes.Q24:
        case BudgetQuestionCodes.Q25:
        case BudgetQuestionCodes.Q27:
        case BudgetQuestionCodes.Q28:
        case BudgetQuestionCodes.Q29:
        case BudgetQuestionCodes.Q30:
        case BudgetQuestionCodes.Q31:
        case BudgetQuestionCodes.Q36:
        case BudgetQuestionCodes.Q38:
        case BudgetQuestionCodes.Q39:
        case BudgetQuestionCodes.Q40:
        case BudgetQuestionCodes.Q42:
        case BudgetQuestionCodes.Q43:
        case BudgetQuestionCodes.Q44: {
          return {
            ...state,
            questions: budgetHelper
              .getUpdatedQuestionCollection(state.questions, question, response),
          };
        }

        default:
          return state;
      }
    }

    default:
      return state;
  }
};
