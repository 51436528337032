import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';
import { priceFormat } from '../tools';

type BudgetSummaryTotalComponent = {
  net: number;
  vat: number;
  total: number;
}

export const BudgetSummaryTotal: FC<BudgetSummaryTotalComponent> = ({ net, vat, total }) => {
  const { t } = useTranslation();

  return (
    <tr className="budget-summary-total">
      <td className="budget-summary-total-data" colSpan={4}>
        <div>
          <div>
            <span className="budget-summary-total-label">{`${t('budget.clientServicesAmount')}: `}</span>
            {priceFormat(net)}
          </div>
          <div>
            <span className="budget-summary-total-label">{`${t('budget.vatAmount')}: `}</span>
            {priceFormat(vat)}
          </div>
          <div>
            <span className="budget-summary-total-label budget-summary-total-label-total">{`${t('budget.totalAmount')}: `}</span>
            {priceFormat(total)}
          </div>
        </div>
      </td>
      <td />
    </tr>
  );
};
