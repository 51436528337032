import {
  Form, Formik, FormikProps,
} from 'formik';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from 'components/base/Image';
import Input from 'components/base/Input';
import { PhotoCamera } from 'components/base/PhotoCamera';
import Select from 'components/base/Select';

import { DisagreementRequestDto, MasterDataDto } from 'config/apiFunus/generated/data-contracts';
import { useDisagreementTypes } from 'hooks/useDisagreementTypes';
import './index.scss';
import { useValidator } from 'hooks/useValidator';

type AddArticleDisagreementFormComponent = {
  onChange: (values: DisagreementRequestDto) => void;
  onIsValidUpdate?: (isValid: boolean) => void;
}
export const AddArticleDisagreementForm: FC<AddArticleDisagreementFormComponent> = (
  {
    onChange,
    onIsValidUpdate,
  },
) => {
  const { t } = useTranslation();
  const { validateArticleDisagreement } = useValidator();
  const { types, getDisagreementType, fetchTypes } = useDisagreementTypes();

  useEffect(
    () => {
      fetchTypes();
      onChange({
        detail: '',
        replacement: false,
        type: undefined,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Formik
      initialValues={{
        detail: '',
        replacement: false,
        type: undefined,
      }}
      isInitialValid={false}
      validate={(values) => {
        onChange(values);
        return validateArticleDisagreement({
          ...values,
          image: undefined,
        });
      }}
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setFieldValue, values, errors, isValid,
      }: FormikProps<DisagreementRequestDto>) => {
        onIsValidUpdate?.(isValid);
        return (
          <Form autoComplete="off">
            <Select<MasterDataDto>
              key="article.type"
              error={errors.type}
              getLabel={({ description }) => description || ''}
              getValue={({ code }) => code || ''}
              name="article.type"
              options={types}
              placeholder={t('common.type')}
              value={values?.type ? getDisagreementType(values.type) : undefined}
              onChange={(val) => setFieldValue('type', val?.code)}
            />
            <Input
              error={errors.detail}
              id="article.detail"
              name="article.detail"
              placeholder={t('article.detail')}
              type="text"
              value={values.detail || ''}
              onBlur={() => false}
              onChange={(val) => setFieldValue('detail', val)}
            />
            <Input
              checked={values.replacement}
              className="input-margins"
              error={errors.replacement}
              id="article.replacement"
              name="article.replacement"
              placeholder={t('article.needReplace')}
              type="checkbox"
              onChange={(val) => setFieldValue('replacement', val)}
            />
            <legend className="input-margins">{t('article.disagreementPhoto')}</legend>
            {values.image && (<Image src={values.image} onDelete={() => { setFieldValue('image', undefined); }} />)}
            {!values.image && (
            <PhotoCamera
              onConfirmPhoto={(capture) => setFieldValue('image', capture)}
            />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
