import { MasterDataQuestionType } from 'modules/budget/api/types';

import { Audit } from '../Audit';

import { IBudgetConfigurationLocation } from './types';

export class BudgetConfigurationLocation extends Audit implements IBudgetConfigurationLocation {
  constructor(params: IBudgetConfigurationLocation) {
    super(params);
    this.country = params.country;
    this.province = params.province;
    this.city = params.city;
    this.location = params.location;
    this.type = params.type;
  }

  type: MasterDataQuestionType;

  country: string;

  province?: string | undefined;

  city?: string | undefined;

  location?: string | undefined;
}
