import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

// eslint-disable-next-line import/no-cycle
import AssignUsers from 'components/base/AssignUsers';
import Modal from 'components/base/Modal';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import Record from 'models/Record';
import { SimpleUser } from 'models/User';

import './index.scss';

type AssignAdviserProps = {
  callBack?: () => void;
  onHide: () => void;
  record?: Record;
  setIsLoading?: (loading: boolean) => void;
  show: boolean;
};

const AssignAdviser: FC<AssignAdviserProps> = ({
  callBack,
  onHide,
  record,
  setIsLoading,
  show,
}) => {
  const { t } = useTranslation();
  const [userOptions, setUserOptions] = useState<Array<SimpleUser>>([]);
  const [headlineAdviser, setHeadlineAdviser] = useState<Array<SimpleUser>>([]);
  const [secondaryAdviser, setSecondaryAdviser] = useState<Array<SimpleUser>>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(
    () => {
      if (setIsLoading) {
        setIsLoading(loading);
      }
    },
    [loading, setIsLoading],
  );

  const sendAssignAdviser = () => {
    const userIds = [];
    if (headlineAdviser[0]?.id) {
      userIds.push(headlineAdviser[0]?.id);
    }
    if (secondaryAdviser[0]?.id) {
      userIds.push(secondaryAdviser[0]?.id);
    }

    const dataToSend = {
      recordId: record?.id,
      userIds,
    };

    setLoading(true);

    config.apiFunus.record
      .assignAdvisers(dataToSend)
      .then((response) => {
        showSuccessToast(t('record.adviserAssigned'));
        closeModal();
        setLoading(false);
        if (callBack) {
          callBack();
        }

        return response;
      })
      .catch((err) => {
        showErrorToast(err.message ? err.message : t('record.assignAdviserError'));
        setLoading(false);
      });
  };

  const closeModal = () => {
    onHide();
    setUserOptions([]);
    setHeadlineAdviser([]);
    setSecondaryAdviser([]);
  };

  useEffect(() => {
    if (show) {
      setLoadingUsers(true);
      config.apiFunus.users
        .getSimpleUsers({ permissionNames: [SimpleUserRequestPermissionNames.ADVICE_ADD] })
        .then((response) => {
          if (response?.data) {
            setUserOptions(response.data);
            setLoadingUsers(false);
          }

          return response;
        })
        .catch(() => setUserOptions([]));
    }
  }, [show]);

  return (
    <Modal
      buttons={[
        {
          color: 'primary',
          disabled: loading,
          id: 'accept',
          onClick: sendAssignAdviser,
          text: t('common.accept'),
          type: 'button',
        },
      ]}
      show={show}
      title={t('record.assignAdviser')}
      onHide={closeModal}
    >
      {record && (
        <React.Fragment>
          <AssignUsers
            assignedUsers={headlineAdviser}
            loadingUsers={loadingUsers}
            placeholder={t('record.selectHeadlineAdviser')}
            record={record}
            setAssignedUsers={setHeadlineAdviser}
            setUserOptions={setUserOptions}
            testId="headline"
            title={t('record.firstNegotiator')}
            userOptions={userOptions}
            headline
            onlyOne
            usersRequestedFromFather
          />
          <AssignUsers
            assignedUsers={secondaryAdviser}
            headline={false}
            loadingUsers={loadingUsers}
            placeholder={t('record.selectSecondaryAdviser')}
            record={record}
            selectClassName="my-3"
            setAssignedUsers={setSecondaryAdviser}
            setUserOptions={setUserOptions}
            testId="secondary"
            title={t('record.secondNegotiator')}
            userOptions={userOptions}
            onlyOne
            usersRequestedFromFather
          />
        </React.Fragment>
      )}
    </Modal>
  );
};

export default AssignAdviser;
