import React, { FC, useEffect, useState } from 'react';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const TransferRemainsInfo: FC<DefaultInfoProps> = ({
  expanded,
  loading,
  order,
}) => {
  const [details, setDetails] = useState<DetailType[]>([]);

  useEffect(() => {
    const newDetails: DetailType[] = [
      // TODO: Cambiar cuando tengamos la info del back
    ];
    setDetails(newDetails);
  }, [order]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default TransferRemainsInfo;
