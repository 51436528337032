import { AxiosError } from "axios";

export class ValidationError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = 'Error de validació';
    try {
      const errorFields = Object.keys(error.response?.data?.errors);
      if (errorFields.length > 0) {
        this.message = `Error de validació ${errorFields.reduce((prev, current) => {
          return `${prev} [${current}]`;
        }, '')}.`;
      }
    } catch { }

    this.name = "ValidationError";
  }
}
