import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { showErrorToast } from 'utils/toasts';

import { IFilter } from 'config/apiFunus/types';
import { budgetController } from 'modules/budget/api';
import { BudgetsTrackingItem } from 'modules/budget/api/BudgetsTrackingItem';
import { BudgetsTrackingFilter } from 'modules/budget/components/tracking/table/types';
import { ResetWizard, SetBudget } from 'modules/budget/store/budgetActions';

import { UseBudgetsHook } from './types';

export const useBudgets = (): UseBudgetsHook => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [trackingList, setTrackingList] = useState<BudgetsTrackingItem[]>([]);

  const fetchBudgetsTracking = useCallback(
    (filter: IFilter<BudgetsTrackingFilter>) => {
      setLoading(true);
      return budgetController
        .GetBudgetsTracking(filter)
        .then((response) => {
          setLoading(false);
          setTrackingList(response.list);
          return response;
        })
        .catch((error) => {
          showErrorToast(t(error.message));
          setLoading(false);
          return false;
        });
    },
    [t],
  );

  const GetBudgetInfoFromRecord = useCallback(
    (id: number) => {
      dispatch(ResetWizard());

      return budgetController
        .GetBudgetDataFromRecord(id)
        .then((response) => {
          dispatch(SetBudget(response));
          return response;
        })
        .catch((error) => {
          showErrorToast(t(error.message));
          return false;
        });
    },
    [dispatch, t],
  );

  return {
    fetchBudgetsTracking,
    GetBudgetInfoFromRecord,
    loading,
    trackingList,
  };
};
