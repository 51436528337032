import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Modal from 'components/base/Modal';

import { ArticleStatusRequestDtoEvent } from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';

import { DeleteRequestModalComponent } from './types';

export const DeleteRequestModal
 :FC<DeleteRequestModalComponent> = ({
   show,
   id,
   onHide,
   onSuccess,
 }) => {
   const { t } = useTranslation();
   const { updateArticleStatus } = useArticles();

   const deleteRequest = useCallback(
     async () => {
       if (id) {
         await updateArticleStatus(
           id,
           ArticleStatusRequestDtoEvent.DELETE,
         )
           .then(() => {
             onHide();
             showSuccessToast(t('article.flowers.confirmDeleteOK'));
             onSuccess?.();
             return true;
           })
           .catch(() => {
             showErrorToast(t('article.flowers.confirmDeleteKO'));
           });
       }
     },
     // eslint-disable-next-line react-hooks/exhaustive-deps
     [id, onHide, onSuccess, t],
   );

   return (
     <Modal
       buttons={[
         {
           color: 'secondary',
           onClick: onHide,
           text: t('common.cancel'),
         },
         {
           onClick: deleteRequest,
           text: t('common.accept'),
         },
       ]}
       show={show}
       showCancel={false}
       title={t('article.flowers.confirmDeleteTitle')}
       onHide={onHide}
     >
       <React.Fragment>{t('article.flowers.confirmDeleteText')}</React.Fragment>
     </Modal>
   );
 };
