import { useCallback, useState } from 'react';

import config from 'config';

import { showErrorToast } from 'utils/toasts';

import { orderLineController } from 'config/apiFunus';
import { RecordInfoDto } from 'config/apiFunus/record/types';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

export interface RecordHook {
  getArticlePdf: (articleId: number) => Promise<string>,
  getInfo: (id: number) => Promise<RecordInfoDto>
  getRecord:(id: number) => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [name: string]: any;
    erpId: number;
}>;
  base64Pdf: string | undefined;
}

const arrayBufferToBase64 = (input: ArrayBuffer, base64Data = `data:${MimeTypes.APPLICATION_PDF}${Base64Data.BASE64_GLU}`) => {
  const unit8 = new Uint8Array(input);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const binary = String.fromCharCode.apply(null, unit8 as any);
  const base64 = btoa(binary);
  return `${base64Data}${base64}`;
};

export const useRecord = (): RecordHook => {
  const [base64Pdf, setbase64Pdf] = useState<string>();

  const getInfo = useCallback(
    async (erpId) => config.apiFunus.record.getInfo(erpId)
      .then((response) => response.data)
      .catch((error) => {
        showErrorToast(error.message);
        throw new Error(error);
      }),
    [],
  );

  const getRecord = useCallback(
    (id: number) => config.apiFunus.record.getRecordById(id)
      .then((response) => response.data),
    [],
  );

  return {
    base64Pdf,
    getArticlePdf: async (articleId) => orderLineController
      .generateDocumentUsingGet(articleId)
      .then((response) => {
        const base64 = arrayBufferToBase64(response);
        setbase64Pdf(base64);

        return base64;
      })
      .catch((error) => {
        showErrorToast(error.message);

        throw new Error(error);
      }),
    getInfo,
    getRecord,
  };
};
