import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/base/Tooltip';

import { ArticleAmount } from '../ArticleAmount';

import { BudgetSummaryLineAmountComponent } from './types';

export const BudgetSummaryLineAmount: FC<BudgetSummaryLineAmountComponent> = (
  { article, disabled },
) => {
  const [editMode, seteditMode] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div>
      {editMode && !disabled ? (
        <ArticleAmount
          article={article}
          onCancel={() => seteditMode(false)}
          onUpdated={() => {
            seteditMode(false);
          }}
        />
      ) : (
        <Tooltip
          key="edit-amount"
          className="f-budget-summary-actions-group-item"
          content={t('budget.wizard.summary.amountEdit')}
        >
          <span
            role="presentation"
            onClick={() => seteditMode(true)}
            onKeyDown={() => false}
          >
            {article.amount}
          </span>
        </Tooltip>
      )}
    </div>
  );
};
