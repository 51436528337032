import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Nullable } from 'utils/types/Nullable/types';

import { getCities } from 'actions/masterData';

import { CityType } from 'models/MasterData';
import { RootState } from 'store';

export interface CitiesHook {
  cities: CityType[];
  getCityByNormalizedCode: (
    countryCode: string | undefined,
    provinceCode: string | undefined,
    cityCode: string | undefined) => CityType | undefined;
  getCitiesByName: (name: string) => Nullable<Array<CityType> | CityType>;
  getCityByCode: (name: string) => Nullable<CityType>;
}

export const useCities = (): CitiesHook => {
  const dispatch = useDispatch();

  const { items, normalizedCities } = useSelector(
    (state: RootState) => ({
      items: state.masterData.cities,
      normalizedCities: state.normalized.cities,
    }),
  );

  const getCityByNormalizedCode = useCallback(
    (
      countryCode: string | undefined,
      provinceCode: string | undefined,
      cityCode: string | undefined,
    ) => {
      const normalizedCityCode = `${countryCode}${provinceCode}${cityCode}`;
      return normalizedCityCode
        && normalizedCities[normalizedCityCode]
        ? items.find((city: CityType) => city.code === normalizedCities[normalizedCityCode].code)
        : undefined;
    },
    [normalizedCities, items],
  );

  const getCitiesByName = useCallback(
    (name: string) => {
      const cities = items?.filter(
        (i: CityType) => i.description?.toUpperCase() === name?.toUpperCase(),
      );
      if (cities?.length === 0) {
        return null;
      }
      return cities?.length === 1 ? cities[0] : cities;
    },
    [items],
  );

  const getCityByCode = useCallback(
    (code: string) => items
      .find((i: CityType) => i.code === code) || null,
    [items],
  );

  useEffect(() => {
    dispatch(getCities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cities: items || [],
    getCitiesByName,
    getCityByCode,
    getCityByNormalizedCode,
  };
};
