import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDeposit from 'hooks/useDeposit';
import { Transfer } from 'models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const TransportInfo: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();
  const { normalizedDeposits } = useDeposit(
    (order.info as Transfer)?.deposit?.address?.city,
  );

  useEffect(() => {
    const info: Transfer = order.info as Transfer;

    const newDetails: DetailType[] = [
      {
        text: t('order.deposit'),
        value:
          info?.deposit?.deposit
          && normalizedDeposits[info?.deposit?.deposit]?.description,
      },
      {
        text: t('service.recipient.name'),
        value: info?.recipient?.name,
      },
      {
        text: t('service.recipient.address'),
        value: info?.recipient?.address,
      },
      {
        text: t('common.phone'),
        value: info?.recipient?.phone,
      },
      {
        text: t('common.email'),
        value: info?.recipient?.email,
      },
      {
        text: t('service.transfer.type'),
        value:
          info?.transport?.type
          && `${t(`service.transfer.types.${info?.transport?.type}`)}`,
      },
      // ***************
    ];

    // TODO: Revisar cuando tengamos la excel correcta
    if (info?.transport?.roadTransport) {
      newDetails.push(
        {
          text: t('common.exitDate'),
          value: info?.transport?.roadTransport?.exitDate,
        },
        {
          text: t('common.exitTime'),
          value: info?.transport?.roadTransport?.exitTime,
        },
        {
          text: t('service.transfer.estimatedArrivalDate'),
          value: info?.transport?.roadTransport?.estimatedArrivalDate,
        },
        {
          text: t('service.transfer.estimatedArrivalTime'),
          value: info?.transport?.roadTransport?.estimatedArrivalTime,
        },
        {
          text: t('service.transfer.departureAirport'),
          value: null,
        },
        {
          text: t('service.transfer.destinationAirport'),
          value: null,
        },
        {
          text: t('service.transfer.flightNumber'),
          value: null,
        },
      );
    } else if (info?.transport?.planeTransport?.flights?.length) {
      const multipleFlights = info.transport.planeTransport.flights.length > 1;
      info.transport.planeTransport.flights.forEach((flight, idx) => newDetails.push(
        {
          text: `${t('service.transfer.departureDate')}${
            multipleFlights ? ` ${idx + 1}` : ''
          }`,
          value: flight.departureDate,
        },
        {
          text: `${t('service.transfer.departureTime')}${
            multipleFlights ? ` ${idx + 1}` : ''
          }`,
          value: flight.departureTime,
        },
        {
          text: `${t('service.transfer.deliveryDate')}${
            multipleFlights ? ` ${idx + 1}` : ''
          }`,
          value: flight.deliveryDate,
        },
        {
          text: `${t('service.transfer.deliveryTime')}${
            multipleFlights ? ` ${idx + 1}` : ''
          }`,
          value: flight.deliveryTime,
        },
        {
          text: `${t('service.transfer.departureAirport')}${
            multipleFlights ? ` ${idx + 1}` : ''
          }`,
          value: flight.departureAirport,
        },
        {
          text: `${t('service.transfer.destinationAirport')}${
            multipleFlights ? ` ${idx + 1}` : ''
          }`,
          value: flight.destinationAirport,
        },
        {
          text: `${t('service.transfer.flightNumber')}${
            multipleFlights ? ` ${idx + 1}` : ''
          }`,
          value: flight.flightNumber,
        },
      ));
    } else {
      newDetails.push(
        {
          text: t('service.transfer.departureDate'),
          value: null,
        },
        {
          text: t('service.transfer.departureTime'),
          value: null,
        },
        {
          text: t('service.transfer.deliveryDate'),
          value: null,
        },
        {
          text: t('service.transfer.deliveryTime'),
          value: null,
        },
        {
          text: t('service.transfer.departureAirport'),
          value: null,
        },
        {
          text: t('service.transfer.destinationAirport'),
          value: null,
        },
        {
          text: t('service.transfer.flightNumber'),
          value: null,
        },
      );
    }

    newDetails.push(
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.adviserComment'),
        value: info?.recipient?.remarks,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
    );

    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, normalizedDeposits]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default TransportInfo;
