import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

import { BudgetsTrackingFilterStatus } from '../../components/tracking/table/types';

import { BudgetState } from './types';

export const initialBudgetState: BudgetState = {
  articles: [],
  articlesLoading: false,
  automaticArticles: [],
  budgetLoading: false,
  collected: false,
  createdBy: null,
  createdDate: null,
  currentQuestion: BudgetQuestionCodes.Q1,
  discardedArticles: [],
  id: null,
  idRecord: null,
  idVersion: null,
  invoiceArticles: [],
  invoiceArticlesLoading: false,
  invoiceClients: [],
  Q10Simulations: new Map(),
  questions: {
    [BudgetQuestionCodes.Q1]: null,
    [BudgetQuestionCodes.Q2]: null,
    [BudgetQuestionCodes.Q3]: null,
    [BudgetQuestionCodes.Q4]: null,
    [BudgetQuestionCodes.Q5]: null,
    [BudgetQuestionCodes.Q6]: null,
    [BudgetQuestionCodes.Q7]: null,
    [BudgetQuestionCodes.Q8]: null,
    [BudgetQuestionCodes.Q9]: null,
    [BudgetQuestionCodes.Q10]: null,
    [BudgetQuestionCodes.Q11]: null,
    [BudgetQuestionCodes.Q12]: null,
    [BudgetQuestionCodes.Q13]: null,
    [BudgetQuestionCodes.Q14]: null,
    [BudgetQuestionCodes.Q15]: null,
    [BudgetQuestionCodes.Q17]: null,
    [BudgetQuestionCodes.Q19]: null,
    [BudgetQuestionCodes.Q21]: null,
    [BudgetQuestionCodes.Q22]: null,
    [BudgetQuestionCodes.Q23]: null,
    [BudgetQuestionCodes.Q24]: null,
    [BudgetQuestionCodes.Q25]: null,
    [BudgetQuestionCodes.Q27]: null,
    [BudgetQuestionCodes.Q28]: null,
    [BudgetQuestionCodes.Q29]: null,
    [BudgetQuestionCodes.Q30]: null,
    [BudgetQuestionCodes.Q31]: null,
    [BudgetQuestionCodes.Q38]: null,
    [BudgetQuestionCodes.Q39]: null,
    [BudgetQuestionCodes.Q36]: null,
    [BudgetQuestionCodes.Q40]: null,
    [BudgetQuestionCodes.Q42]: null,
    [BudgetQuestionCodes.Q43]: null,
    [BudgetQuestionCodes.Q44]: null,
    [BudgetQuestionCodes.SUMMARY]: null,
  },
  status: BudgetsTrackingFilterStatus.PENDING,
  summarize: null,
  summarizeLoading: false,
};
