import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCenters } from 'hooks/useCenters';
import { DashboardEnquiry } from 'models/Enquiry';

import Info, { DetailType } from './Info';

export type DefaultInfoProps = {
  expanded?: boolean;
  loading?: boolean;
  enquiry: DashboardEnquiry;
};

const DefaultInfo: FC<DefaultInfoProps> = ({ expanded, loading, enquiry }) => {
  const { t } = useTranslation();
  const { getCenter } = useCenters();

  const [details, setDetails] = useState<DetailType[]>([
    {
      text: t('enquiry.comment'),
      value: null,
    },
  ]);

  useEffect(() => {
    setDetails([
      {
        text: t('enquiry.comment'),
        value: enquiry?.detail?.subject,
      },
      {
        text: t('common.phone'),
        value: enquiry?.detail?.phone,
      },
      {
        text: t('common.email'),
        value: enquiry?.detail?.email,
      },
      {
        text: t('enquiry.center'),
        value: getCenter(enquiry?.detail?.center)?.description || null,
      },
      {
        text: t('enquiry.createdBy'),
        value: enquiry?.detail?.creator?.name
        || enquiry?.detail?.creator?.firstSurname
        || enquiry?.detail?.creator?.secondSurname
          ? `${enquiry?.detail?.creator?.name || ''} ${enquiry?.detail?.creator?.firstSurname || ''} ${enquiry?.detail?.creator?.secondSurname || ''}`
          : null,
      },
      {
        text: t('common.coordinatorComment'),
        value: enquiry?.detail?.coordinatorComment,
      },
      {
        text: t('common.adviserComment'),
        value: enquiry?.detail?.advisorComment,
      },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry, t]);

  return (
    <Info
      assignedUser={enquiry.assignedTo}
      details={details}
      expanded={expanded}
      loading={loading}
    />
  );
};

export default DefaultInfo;
