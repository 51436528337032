import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/base/Select';

import { useDeathLocation } from 'hooks/useDeathLocation';
import { IMasterDataDto } from 'models/MasterData';
import { Location } from 'modules/budget/models/Location';
import { ILocation } from 'modules/budget/models/Location/types';

interface DeathLocationSelectorComponent {
  city: string;
  value: string;
  disabled: boolean;
  onChange: (value: ILocation | null) => void
}

export const DeathLocationSelector: FC<DeathLocationSelectorComponent> = (
  {
    city, disabled, onChange, value,
  },
) => {
  const { t } = useTranslation();
  const {
    deathLocations, getDeathLocationByCode,
  } = useDeathLocation(city);

  return (
    <Select<IMasterDataDto>
      key="deathPlace"
      disabled={disabled}
      getLabel={({ description }) => description}
      getValue={({ code }) => code}
      name="deathPlace"
      options={deathLocations}
      placeholder={t('record.deathData.deathPlace')}
      value={getDeathLocationByCode(value)}
      searchable
      onChange={(val) => {
        onChange(val?.code
          ? new Location({
            id: val?.code,
            work: false,
          })
          : null);
      }}
    />
  );
};
