import { BudgetQuestionCodes } from './budgetQuestionCodes';

export const sortedQuestions: Array<BudgetQuestionCodes> = [
  BudgetQuestionCodes.Q1,
  BudgetQuestionCodes.Q4,
  BudgetQuestionCodes.Q3,
  BudgetQuestionCodes.Q43,
  BudgetQuestionCodes.Q36,
  BudgetQuestionCodes.Q9,
  BudgetQuestionCodes.Q12,
  BudgetQuestionCodes.Q5,
  BudgetQuestionCodes.Q6,
  BudgetQuestionCodes.Q22,
  BudgetQuestionCodes.Q23,
  BudgetQuestionCodes.Q38,
  BudgetQuestionCodes.Q7,
  BudgetQuestionCodes.Q28,
  BudgetQuestionCodes.Q8,
  BudgetQuestionCodes.Q31,
  BudgetQuestionCodes.Q42,
  BudgetQuestionCodes.Q11,
  BudgetQuestionCodes.Q13,
  BudgetQuestionCodes.Q40,
  BudgetQuestionCodes.Q39,
  BudgetQuestionCodes.Q17,
  BudgetQuestionCodes.Q19,
  BudgetQuestionCodes.Q15,
  BudgetQuestionCodes.Q14,
  BudgetQuestionCodes.Q24,
  BudgetQuestionCodes.Q21,
  BudgetQuestionCodes.Q25,
  BudgetQuestionCodes.Q27,
  BudgetQuestionCodes.Q29,
  BudgetQuestionCodes.Q30,
  BudgetQuestionCodes.Q44,
  BudgetQuestionCodes.Q10,
  BudgetQuestionCodes.SUMMARY,
];
