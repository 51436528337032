/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { showSuccessToast } from 'utils/toasts';

import { usePushNotifications } from 'modules/push-notifications/hooks/usePushNotifications';

import './index.scss';

const NotificationsToogle: React.FC = () => {
  const { enableNotifications, unSubscribe, enabled } = usePushNotifications();
  const { t } = useTranslation();

  const handleToogle = React.useCallback(
    () => {
      if (enabled) {
        unSubscribe()
          .then((response) => {
            showSuccessToast(t('notifications.unsubscriptionSuccess'));
            return response;
          })
          .catch(() => false);
      } else {
        enableNotifications()
          .then((response) => {
            showSuccessToast(t('notifications.subscriptionSuccess'));
            return response;
          })
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled],
  );

  return (
    <React.Fragment>

      <label
        className="switch"
      >
        <input
          checked={enabled}
          type="checkbox"
          onClick={handleToogle}
        />
        <span className="slider" />
      </label>
    </React.Fragment>
  );
};

export default NotificationsToogle;
