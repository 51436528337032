import React, {
  FC,
  Fragment,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { formatName } from 'utils/helpers';

import Select from 'components/base/Select';
// eslint-disable-next-line import/no-cycle
import Table from 'components/base/Table';

import WorkOrderAssignParameters from 'components/workOrder/AssignParameters';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { i18n } from 'i18n';
import { AssignUsersParams } from 'models/OrderAssign';
import Record from 'models/Record';
import { SimpleUser } from 'models/User';
import Workorder from 'models/Workorder';

import './index.scss';
import CustomIcon from '../CustomIcon';
import { FeatherIconTypes } from '../CustomIcon/types';
import { TableColumn } from '../Table/types';

type ToRecord = {
  headline: boolean;
  loadingUsers: boolean;
  record: Record;
  usersRequestedFromFather: boolean;
  workorder?: Workorder;
};

type ToWorkOrder = {
  headline?: boolean;
  loadingUsers?: boolean;
  record?: Record;
  usersRequestedFromFather?: boolean;
  workorder: Workorder;
};

type AssignUsersProps = (ToRecord | ToWorkOrder) & {
  assignedUsers: SimpleUser[];
  info?: AssignUsersParams;
  onlyOne?: boolean;
  placeholder?: string;
  selectClassName?: string;
  setAssignedUsers: Dispatch<SetStateAction<SimpleUser[]>>;
  setInfo?: (params: AssignUsersParams) => void;
  setUserOptions: Dispatch<SetStateAction<SimpleUser[]>>;
  testId?: string;
  title?: string;
  userOptions: SimpleUser[];
};

const getSimpleUserCols = (): TableColumn<SimpleUser>[] => [
  { accessor: 'id', Header: 'Id', hidden: true },
  {
    accessor: 'name',
    Cell: ({ row: { original } }) => formatName(original),
    Header: `${i18n.t('common.fullName')}`,
  },
];

const AssignUsers: FC<AssignUsersProps> = ({
  assignedUsers,
  info,
  headline,
  loadingUsers,
  onlyOne,
  placeholder,
  record,
  selectClassName,
  setAssignedUsers,
  setInfo,
  setUserOptions,
  testId,
  title,
  userOptions,
  usersRequestedFromFather,
  workorder,
}) => {
  const { t } = useTranslation();
  const { user } = useProvidedAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const simpleUserCols = useMemo(() => getSimpleUserCols(), [t]);

  // TODO contemplar caso futuro en el que el gerente tiene
  // WORK_ORDER_CEMETERY_ADD y WORK_ORDER_WORKSHOP_ADD
  const permissionsToFilter = () => {
    const result = [];
    if (
      user?.role?.permissions?.find(
        (x) => x.name === SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_ADD,
      )
    ) {
      result.push(SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ);
      result.push(SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_WRITE);
    } else if (
      user?.role?.permissions?.find(
        (x) => x.name === SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_ADD,
      )
    ) {
      result.push(SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ);
      result.push(SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_WRITE);
    }

    return result;
  };

  const updateLists = () => {
    if (record?.negotiators) {
      const option = record.negotiators[headline ? 0 : 1];
      if (option) {
        onChangeUser(option);
      }
    } else if (workorder?.assignedUsers) {
      workorder.assignedUsers.map((u: SimpleUser) => onChangeUser(u));
    }
  };

  const onChangeUser = (option: SimpleUser) => {
    if (option) {
      setAssignedUsers((array) => array.concat(option));
      setUserOptions((array) => array.filter((x) => x.id !== option.id));
    }
  };

  const removeUser = (row: SimpleUser) => {
    setAssignedUsers((array) => array.filter((x) => x.id !== row.id));
    setUserOptions((array) => array.concat(row));
  };

  useEffect(() => {
    if (!usersRequestedFromFather) {
      config.apiFunus.users
        .getSimpleUsers({ permissionNames: permissionsToFilter() })
        .then((response) => {
          if (response?.data) {
            setUserOptions(response.data);
            updateLists();
          }

          return response;
        })
        .catch(() => {
          setUserOptions([]);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workorder?.id]);

  useEffect(() => {
    if (loadingUsers === false && userOptions.length > 0) {
      updateLists();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUsers]);

  return (
    <Fragment>
      <Select
        className={selectClassName || 'mb-3'}
        data-testid={testId || 'assign-select'}
        disabled={
          (onlyOne && assignedUsers.length === 1) || assignedUsers.length > 1
        }
        getLabel={(option: SimpleUser) => `${option.name} ${option.firstSurname} ${option.secondSurname || ''}`}
        getValue={(option: SimpleUser) => option.id}
        name="assign-user-selector"
        options={userOptions}
        placeholder={placeholder || t('user.assignUser')}
        value={undefined}
        searchable
        onChange={onChangeUser}
      />
      <div className="assigned-users">
        <span>{title || t('user.assignedUsers')}</span>
        <Table<SimpleUser>
          actions={(row: SimpleUser) => ({
            remove: {
              icon: <CustomIcon icon={FeatherIconTypes.TRASH} />,
              onClick: () => removeUser(row),
              tooltipCaption: t('common.remove'),
            },
          })}
          columns={simpleUserCols}
          data={assignedUsers}
          pagination={false}
        />
      </div>
      {workorder?.id && info && setInfo && (
        <WorkOrderAssignParameters
          info={info}
          order={workorder}
          setInfo={setInfo}
        />
      )}
    </Fragment>
  );
};

export default AssignUsers;
