import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

export const yesNoForms = [
  BudgetQuestionCodes.Q2,
  BudgetQuestionCodes.Q9,
  BudgetQuestionCodes.Q12,
  BudgetQuestionCodes.Q23,
  BudgetQuestionCodes.Q25,
  BudgetQuestionCodes.Q28,
  BudgetQuestionCodes.Q36,
  BudgetQuestionCodes.Q38,
  BudgetQuestionCodes.Q40,
];
