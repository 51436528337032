import { ApiFunusUsers } from './types';
import axios from 'config/axios';
import { defaultFilter } from '.';

export const users: ApiFunusUsers = {
  editUser: ({ id, values }) => axios.put(`/user/${id}`, values),
  getUser: (id) => axios.get(`/user/${id}`),
  getProfile: () => axios.get('/user/profile'),
  getUsers: (params = defaultFilter) => axios.post('/users', params),
  getSimpleUsers: (params) => axios.post('/simple/users', params),
  saveUser: (params) => axios.post('/user', params),
  updateImage: (params) => axios.put('/user', params),
  resendPasswordMail: (id) => axios.get(`/user/email/${id}`),
};
