import { addHours, formatDateAndHour } from 'utils/dateManager';
import { version } from '../../package.json';

const storage = {
  appVersion: 'funus-version',
  expiration: 'funus-expiration',
  token: 'funus-token',
};

const getToken = () => localStorage.getItem(storage.token);
const removeToken = () => localStorage.removeItem(storage.token);
const setToken = (token: string) => localStorage.setItem(storage.token, token);

const getVersion = () => localStorage.getItem(storage.appVersion);
const setVersion = () => localStorage.setItem(storage.appVersion, `${version}`);
const getExpiration = () => localStorage.getItem(storage.expiration);
const setExpiration = () =>
  localStorage.setItem(storage.expiration, formatDateAndHour(addHours(new Date(), 1)));

export default {
  getToken,
  removeToken,
  setToken,
  getVersion,
  setVersion,
  getExpiration,
  setExpiration,
};
