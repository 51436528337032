import { Article } from '../Article';
import { IArticle } from '../Article/types';

import { IArticlesCollection } from './types';

export class ArticleCollection implements IArticlesCollection {
  constructor(params: IArticlesCollection) {
    const mapToArticle = (article: IArticle): Article => new Article(article);

    this.ARTICLE = params.ARTICLE
      ? params?.ARTICLE.map(mapToArticle)
      : undefined;

    this.CONCESSION = params.CONCESSION
      ? params?.CONCESSION.map(mapToArticle)
      : undefined;

    this.TAX = params.TAX
      ? params?.TAX.map(mapToArticle)
      : undefined;

    this.TRANSFER = params?.TRANSFER
      ? params?.TRANSFER.map(mapToArticle)
      : undefined;
  }

  ARTICLE?: IArticle[];

  CONCESSION?: IArticle[];

  TAX?: IArticle[];

  TRANSFER?: IArticle[];
}
