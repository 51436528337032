import React, { FC, useEffect, useState } from 'react';

import { PdfViewer } from 'components/base/PdfViewer';

import { useArticles } from 'hooks/useArticles';

type ComercialPdfComponent = {
  erpId: number | undefined;
  onLoadSuccess: ({ objectUrl }: { objectUrl: string }) => void;
}

export const ComercialPdf: FC<ComercialPdfComponent> = ({
  erpId,
  onLoadSuccess,
}) => {
  const { getComercialPdf, comercialBase64 } = useArticles();
  const [pdfStream, setPdfStream] = useState<string>();

  useEffect(() => {
    setPdfStream(comercialBase64);
  }, [comercialBase64]);

  useEffect(
    () => {
      if (erpId) {
        getComercialPdf(erpId)
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [erpId],
  );

  return (
    <div>
      {pdfStream && <PdfViewer base64={pdfStream} onLoadSuccess={onLoadSuccess} />}
    </div>
  );
};
