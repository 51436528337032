import React, { FC } from 'react';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { Code } from 'modules/budget/models/Code';

import { sortByCode } from '../helper';
import { BudgetFormArticle } from '../types';

interface ArticlesTableComponent {
  articles: Code[];
  onDelete: (article: BudgetFormArticle) => void;
  name?: string;
  articleList: BudgetFormArticle[];
}

export const ArticlesTable: FC<ArticlesTableComponent> = ({
  articles, articleList, onDelete, name,
}) => (
  <table style={{ width: '100%' }}>
    <tbody>
      {
        articles
          .map((articleCode) => articleList
            .find((articlesItem) => articlesItem.code === articleCode.code))
          .sort(sortByCode)
          .map((article) => (
            <tr
              key={article?.code}
            >
              <td data-testid={`${name}-${article?.code}`}>{`${article?.code} ${article?.description}`}</td>
              <td style={{ width: '48px' }}>
                <Button
                  color="transparent"
                  testid={`${name}-remove-${article?.code}`}
                  onClick={() => {
                    if (article?.code) {
                      onDelete(article);
                    }
                  }}
                >
                  <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />
                </Button>
              </td>
            </tr>
          ))
}
    </tbody>
  </table>
);
