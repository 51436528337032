import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { IdentifierTypes } from 'components/base/IdentifierSelector';
import Modal from 'components/base/Modal';

import { useSteps } from 'hooks/useSteps';
import { StepPositions } from 'hooks/useSteps/types';

import { CIFDataCollectorByPhoto } from '../cif-data-collector-by-photo';
import { DNINIEDataCollectorByPhoto } from '../dni-nie-data-collector-by-photo';
import { DocumentDataCollectorByUpload } from '../document-data-collector-by-upload';
import { PassportDataCollectorByPhoto } from '../passport-data-collector-by-photo';

import { IAScanDocumentContext } from './IAScanDocumentContext';
import { ScanDocumentModalDocument } from './types';

import './index.scss';

export const ScanDocumentModal: FC<ScanDocumentModalDocument> = ({
  show, onCancel, title, documentType, onAccept, infoFormlayout,
}) => {
  const { t } = useTranslation();
  const {
    isFirstStep: selectFormatStep,
    isSecondStep: takePhotoStep,
    setStep,
    reset,
    isThirdStep: uploadPdfStep,
  } = useSteps();

  useEffect(
    () => {
      if (!show) {
        reset();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  );

  return (
    <Modal
      className="ia-modal"
      closeOnFocusLost={false}
      show={show}
      showFooter={false}
      title={title}
      onHide={() => {
        onCancel();
      }}
    >
      {show && (
        <IAScanDocumentContext.Provider value={{ documentType, infoFormlayout }}>
          <React.Fragment>
            {selectFormatStep
            && (
              <div className="buttons-panel">
                <Button
                  text={t('ia.takePhoto')}
                  onClick={() => setStep(StepPositions.SECOND)}
                />
                <Button
                  text={t('ia.uploadFiles')}
                  onClick={() => setStep(StepPositions.THIRD)}
                />
              </div>
            )}

            {takePhotoStep && (
            <React.Fragment>
              {[
                IdentifierTypes.IDENTIFIER,
                IdentifierTypes.NIE,
              ]
                .includes(documentType)
                && (
                  <DNINIEDataCollectorByPhoto
                    onCancel={onCancel}
                    onConfirm={onAccept}
                  />
                )}

              {[IdentifierTypes.CIF]
                .includes(documentType)
                && (
                  <CIFDataCollectorByPhoto
                    onCancel={onCancel}
                    onConfirm={onAccept}
                  />
                )}

              {[IdentifierTypes.PASSPORT]
                .includes(documentType)
                && (
                  <PassportDataCollectorByPhoto
                    onCancel={onCancel}
                    onConfirm={onAccept}
                  />
                )}
            </React.Fragment>
            )}

            {uploadPdfStep
            && (
              <DocumentDataCollectorByUpload
                onCancel={onCancel}
                onConfirm={onAccept}
              />
            )}
          </React.Fragment>
        </IAScanDocumentContext.Provider>
      )}
    </Modal>
  );
};
