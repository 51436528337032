import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { Column } from 'components/base/Column';

import { ItemSelector } from 'modules/budget/components/articleSelectors/ItemSelector';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionSettings } from 'modules/budget/hooks/useQuestionSettings';
import { Article } from 'modules/budget/models/Article';
import { dismissServiceId } from 'modules/budget/store/data';

import { Q43Response, Q43FormComponent } from './types';

export const Q43Form: FC<Q43FormComponent> = (
  { onChange, question },
) => {
  const { GetQuestionValue } = useBudget();
  const { isMultiselect, isQuantityEnabled } = useQuestionSettings();
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [selectedDismiss, setSelectedDismiss] = useState<boolean>();

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.articles) {
        onChange({
          articles: storedValue?.articles,
          autorespond: false,
          dismissService: storedValue?.articles.length === 0,
        });
        setSelectedArticles(storedValue?.articles);
        setSelectedDismiss(storedValue?.articles.length === 0);
      }
    },
    [onChange, storedValue?.articles],
  );

  return (
    <Formik
      initialValues={{
        articles: [],
        autorespond: false,
        dismissService: false,
      }}
      validate={(values) => {
        onChange({
          articles: values.articles,
          autorespond: values.autorespond,
          dismissService: values.dismissService,
        });
        setSelectedArticles(values.articles);
        setSelectedDismiss(values.dismissService);

        return {};
      }}
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues,
      }: FormikProps<Q43Response>) => {
        const autoRespond = !isMultiselect(question) && !isQuantityEnabled(question);

        return (
          <Column>
            <ItemSelector
              dismissService={selectedDismiss}
              multiselect={isMultiselect(question)}
              question={question}
              value={selectedArticles}
              onChange={(articles?: Article[]) => {
                if (articles?.find((article) => article.code === dismissServiceId)) {
                  setValues({
                    articles: [],
                    autorespond: true,
                    dismissService: true,
                  });
                } else {
                  setValues({
                    articles: articles || [],
                    autorespond: autoRespond,
                    dismissService: false,
                  });
                }
              }}
            />
          </Column>
        );
      }}
    </Formik>
  );
};
