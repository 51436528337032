import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { isBoolean, isBooleanAndFalse } from 'utils/helpers';

import { Column } from 'components/base/Column';
import { QuantitySelector } from 'components/base/QuantitySelector';
import { Row } from 'components/base/Row';
import Select from 'components/base/Select';

import { Separator } from 'modules/budget/components/commons/Separator';
import { YesNoSelector } from 'modules/budget/components/commons/YesNoSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionLabels } from 'modules/budget/hooks/useQuestionLabels';
import { useQuestionOptions } from 'modules/budget/hooks/useQuestionOptions';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes, IArticle } from 'modules/budget/models/Article/types';
import { dismissServiceId } from 'modules/budget/store/data';

export interface Q19Response {
  dismissService?: boolean,
  articles: Article[],
  autorespond: boolean;
}

interface Q19FormComponent {
  onChange: (value: Q19Response) => void;
  question: BudgetQuestionCodes;
}

export const Q19Form: FC<Q19FormComponent> = (
  { onChange, question },
) => {
  const { fetchOptions, items } = useQuestionOptions();
  const { t } = useTranslation();
  const { yes, no } = useQuestionLabels();
  const { GetQuestionValue } = useBudget();
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [wantService, setWantService] = useState<boolean>();

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      fetchOptions(question, ArticleCollectionTypes.ARTICLE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question],
  );

  useEffect(
    () => {
      if (storedValue?.articles) {
        onChange({
          articles: storedValue?.articles,
          autorespond: false,
          dismissService: false,
        });
        setWantService(true);
        setSelectedArticles(storedValue?.articles);
      } else if (storedValue === dismissServiceId) {
        onChange({
          articles: [],
          autorespond: false,
          dismissService: true,
        });
        setWantService(false);
        setSelectedArticles(storedValue?.articles);
      }
    },
    [onChange, storedValue],
  );

  return (
    <Formik
      initialValues={{
        articles: selectedArticles,
        autorespond: false,
        dismissService: isBoolean(wantService) ? !wantService : undefined,
      }}
      validate={(values) => {
        onChange({
          articles: values.articles,
          autorespond: values.autorespond,
          dismissService: values.dismissService,
        });
        setWantService(isBoolean(values.dismissService) ? !values.dismissService : undefined);
        setSelectedArticles(values.articles);

        return {};
      }}
      enableReinitialize
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues, values,
      }: FormikProps<Q19Response>) => (
        <Column>
          <YesNoSelector
            noCaption={no(question)}
            value={isBoolean(values.dismissService) ? !values.dismissService : undefined}
            yesCaption={yes(question)}
            onChange={(v) => {
              setValues({
                articles: [],
                autorespond: isBooleanAndFalse(v),
                dismissService: isBoolean(v) ? !v : undefined,
              });
            }}
          />
          <Separator />
          <Row>
            <Select<IArticle>
              key="obituary-selector"
              className={classNames({ 'f-budget-hidden': !isBooleanAndFalse(values.dismissService) })}
              getLabel={({ description }) => description}
              getValue={({ code }) => code}
              name="obituary-selector"
              options={items}
              placeholder={t('budget.pressObituaries')}
              value={values.articles?.[0] ? items
                .find((e) => e.code === values.articles[0].code) : undefined}
              searchable
              onChange={(val) => {
                if (val?.code) {
                  const item = items
                    .find((e) => e.code === val.code);
                  setValues({
                    articles: [new Article({
                      ...item,
                      amount: 1,
                      code: `${item?.code}`,
                      description: `${item?.description}`,
                    })],
                    autorespond: false,
                    dismissService: false,
                  });
                }
              }}
            />
            <Separator />
            <QuantitySelector
              className={classNames({ 'f-budget-hidden': !isBooleanAndFalse(values.dismissService) })}
              code={values.articles?.[0]?.code}
              step={1}
              value={values.articles?.[0]?.amount || 0}
              onChange={(amount) => {
                setValues({
                  articles: [new Article({
                    ...values.articles?.[0],
                    amount,
                  })],
                  autorespond: false,
                  dismissService: false,
                });
              }}
            />
          </Row>
        </Column>
      )}
    </Formik>
  );
};
