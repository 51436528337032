import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import InputQRScan from 'components/base/InputQRScan';
import Modal from 'components/base/Modal';

import './index.scss';

type DeliveryObjectsProps = {
  recordId: number;
  showPersonalObjects: boolean;
  onHide: () => void;
};

const DeliveryObjects: FC<DeliveryObjectsProps> = ({
  recordId,
  showPersonalObjects,
  onHide,
}) => {
  const { t } = useTranslation();
  const [dataQR, setDataQR] = useState<string>('');

  const sendDeliveredObjects = () => {
    const dataToSend = {
      personalItemsQr: dataQR,
    };

    config.apiFunus.record
      .deliveryObjets(recordId, dataToSend)
      .then((response) => {
        showSuccessToast(t('record.objectsDelivered'));
        closeModal();

        return response;
      })
      .catch(() => {
        showErrorToast(t('record.objectsDeliveryError'));
      });
  };

  const closeModal = () => {
    onHide();
    setDataQR('');
  };

  return (
    <Modal
      buttons={[
        {
          color: 'primary',
          disabled: !dataQR,
          id: 'accept',
          onClick: sendDeliveredObjects,
          text: t('common.accept'),
          type: 'button',
        },
      ]}
      show={showPersonalObjects}
      title={t('record.deliveryObjects')}
      onHide={closeModal}
    >
      <InputQRScan data={dataQR} setData={setDataQR} />
    </Modal>
  );
};

export default DeliveryObjects;
