import classNames from 'classnames';
import React, {
  Fragment, FC, useState, useEffect,
} from 'react';
import { Clipboard, PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from 'config';

import { formatDate } from 'utils/dateManager';
import { checkHaveAtLeastOnePermission } from 'utils/permissions';

import Button from 'components/base/Button';
import Loader from 'components/base/Loader';
import { displayNoValue } from 'components/base/NoData';
import { TableColumn } from 'components/base/Table/types';
import TwoBoxesPage from 'components/base/TwoBoxesPage';

import { DateTimeInfo } from 'components/pages/Information/informationTable/DateTimeInfo';
import RecordTable from 'components/pages/record/Table';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import Record, { LandingRecords } from 'models/Record';
import { RootState } from 'store';

// FIXME: no borrar el comentario. Deshabilita el poder editar los no asignados
// const restRecordsActions = (row: Record) => ({
//   see: {
//     url: `${config.url.records}/${row.id}`,
//   },
//   extra: [
//     {
//       key: 'seeServices',
//       url: `${config.url.services}/${row.id}`,
//       icon: <Settings />,
//     },
//   ],
// });

const AdviserLanding: FC = () => {
  const [firstLoading, setFirstLoading] = useState(true);
  const [secondLoading, setSecondLoading] = useState(true);
  const { user } = useProvidedAuth();
  const { t } = useTranslation();
  const { cities } = useSelector((state: RootState) => state.masterData);
  const [columns, setColumns] = useState<TableColumn<Record>[]>([]);

  useEffect(() => {
    setColumns([
      {
        accessor: 'id',
        Header: 'ID',
        hidden: true,
      },
      {
        accessor: 'erpId',
        className: 'primary-dark-color',
        Header: `${t('record.number')}`,
      },
      {
        accessor: 'death.deathDate',
        Cell: ({
          row: {
            original: { deathData },
          },
        }) => {
          if (deathData?.deathDate) {
            return formatDate(deathData.deathDate);
          }
          return '';
        },
        Header: `${t('common.date')}`,
      },
      {
        accessor: 'deceased.name',
        Cell: ({
          row: {
            original: { deceasedData },
          },
        }) => (!!deceasedData
            && classNames(
              deceasedData.name,
              deceasedData.firstSurname,
              deceasedData.secondSurname,
            ))
          || '',
        Header: `${t('common.name')}`,
      },
      {
        accessor: 'negotiators',
        Cell: ({ cell: { value } }) => (
          <Fragment>
            <div>
              {value && value[0]
                ? `${value[0]?.firstSurname}, ${value[0]?.name}`
                : ''}
            </div>
            <div>
              {value && value[1]
                ? `${value[1]?.firstSurname}, ${value[1]?.name}`
                : ''}
            </div>
          </Fragment>
        ),
        collapse: true,
        Header: `${t('record.advisers')}`,
      },
      {
        accessor: 'deathData',
        Cell: ({ cell: { value } }) => {
          const city = cities
            && Object.keys(cities)
              .map((k) => cities[k])
              .find((c) => c.code === value?.address?.city);

          return (
            <React.Fragment>{city ? city.description : displayNoValue}</React.Fragment>
          );
        },
        Header: `${t('common.city')}`,
        sortable: false,
      },
      {
        accessor: 'appointmentDateTime',
        Cell: ({ cell: { value } }) => (
          value ? <DateTimeInfo date={value} time={value} /> : '--'
        ),
        Header: `${t('record.appointment')}`,
        sortable: false,
      },
    ]);
  }, [t, cities]);

  const getLeftBoxButton = () => {
    if (
      checkHaveAtLeastOnePermission(
        [
          SimpleUserRequestPermissionNames.RECORD_ADD,
          SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
        ],
        user?.role.permissions,
      )
    ) {
      return (
        <Button
          leftAddon={<PlusCircle />}
          text={t('record.new')}
          to={config.url.newRecord}
        />
      );
    }

    return undefined;
  };

  return (
    <Fragment>
      {(firstLoading || secondLoading) && <Loader />}
      <TwoBoxesPage
        leftBox={{
          className: 'enquiries-box',
          content: (
            <RecordTable
              columns={columns}
              getData={(index = 0, size = 10) => config.apiFunus.record.getLandingRecords({
                filter: [
                  {
                    negotiator: LandingRecords.ASSIGNED_TO_ME,
                  },
                ],
                page: {
                  index,
                  size,
                },
              })}
              setIsLoading={setFirstLoading}
              pagination
              showOnlyTable
            />
          ),
          header: getLeftBoxButton(),
          icon: <Clipboard />,
          title: t('record.myRecords'),
        }}
        rightBox={{
          className: 'records-box',
          content: (
            <RecordTable
              // FIXME: Habilitar si finalmente no pueden editar los no asignados
              // actions={restRecordsActions}
              columns={columns}
              getData={(index = 0, size = 10) => config.apiFunus.record.getLandingRecords({
                filter: [
                  {
                    negotiator: LandingRecords.ASSIGNED_OTHERS,
                  },
                ],
                page: {
                  index,
                  size,
                },
              })}
              setIsLoading={setSecondLoading}
              pagination
              showOnlyTable
            />
          ),
          icon: <Clipboard />,
          title: t('record.restRecords'),
        }}
      />
    </Fragment>
  );
};

export default AdviserLanding;
