import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

export const galleryQuestionsWithDismissItem = [
  BudgetQuestionCodes.Q10,
  BudgetQuestionCodes.Q13,
  BudgetQuestionCodes.Q14,
  BudgetQuestionCodes.Q15,
  BudgetQuestionCodes.Q21,
  BudgetQuestionCodes.Q24,
  BudgetQuestionCodes.Q27,
  BudgetQuestionCodes.Q29,
  BudgetQuestionCodes.Q30,
];
