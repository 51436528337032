import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Tooltip from 'components/base/Tooltip';

import { Permission, PermissionGroupDto } from 'models/UserRole';
import { RootState } from 'store';

import { PermissionInput } from './PermissionInput';
import './index.scss';

export type RigthsProps = {
  disabled: boolean;
  error?: string | boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleBlur: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: any;
  permissions: PermissionGroupDto[];
};

const RightsFieldsets = ({
  disabled,
  error,
  handleBlur,
  setFieldValue,
  permissions,
}: RigthsProps): ReactElement => {
  const { t } = useTranslation();
  const [allPermissionsGrouped, setAllPermissionsGrouped] = useState<
    Permission[]
  >([]);
  const { permissions: allPerms } = useSelector(
    (state: RootState) => state.roles,
  );
  const [permissionsDisabled, setPermissionsDisabled] = useState<Permission[]>(
    [],
  );
  const [flag, setFlag] = useState<boolean>(true);

  const isDisabled = (permissionDto: Permission) => !!permissionsDisabled.find(
    (p) => p.id === permissionDto.id,
  );

  useEffect(() => {
    if (!permissions.length) {
      const permissionsCopy = [...allPerms];
      permissionsCopy.forEach((perm: PermissionGroupDto) => {
        perm.permissionDtos.forEach((permission: Permission) => {
          // eslint-disable-next-line no-param-reassign
          permission.selected = false;
        });
      });

      setFieldValue('permissions', permissionsCopy);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPerms]);

  useEffect(() => {
    if (flag && allPermissionsGrouped.length > 0) {
      setFlag(false);
      if (
        allPermissionsGrouped.find((p) => p.selected && p.name.includes('USER'))
      ) {
        setPermissionsDisabled(
          allPermissionsGrouped.filter((p) => !p.name.includes('USER')),
        );
      }
      if (
        allPermissionsGrouped.find(
          (p) => p.selected && !p.name.includes('USER'),
        )
      ) {
        setPermissionsDisabled(
          allPermissionsGrouped.filter((p) => p.name.includes('USER')),
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPermissionsGrouped]);

  useEffect(() => {
    if (!allPermissionsGrouped.length) {
      setAllPermissionsGrouped(
        permissions.reduce(
          (res: Permission[], current) => [...res, ...current.permissionDtos],
          [],
        ),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <div>
      {permissions.map(
        (permission: PermissionGroupDto, keyPermissionGroupList: number) => (
          <fieldset key={permission.id}>
            <legend>{t(`role.permissions.${permission.description}`)}</legend>
            {permission?.permissionDtos?.map(
              (permissionDto: Permission, keyPermissionDto: number) => (
                <Tooltip
                  key={permissionDto.id}
                  content={permissionDto.description || t('role.noDescription')}
                >
                  <PermissionInput
                    allPermissionsGrouped={allPermissionsGrouped}
                    disabled={disabled || isDisabled(permissionDto)}
                    handleBlur={handleBlur}
                    keyPermission={keyPermissionDto}
                    keyPermissionGroupList={keyPermissionGroupList}
                    permission={permissionDto}
                    permissionPrefix={permission.description.replace(
                      '_PERMISSION_GROUP',
                      '',
                    )}
                    permissions={permission.permissionDtos}
                    setFieldValue={setFieldValue}
                    setPermissionsDisabled={setPermissionsDisabled}
                  />
                </Tooltip>
              ),
            )}
          </fieldset>
        ),
      )}
      <span className={classNames('text-error', { hidden: !error })}>
        {error}
      </span>
    </div>
  );
};

export default RightsFieldsets;
