/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line eslint-comments/no-duplicate-disable
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React, {
  FC, createRef, Fragment,
} from 'react';
import ReactDOM from 'react-dom';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from 'components/base/Button';

import useClickOutside from 'hooks/useClickOutside';

import './index.scss';

export type ModalProps = {
  buttons?: Array<ButtonProps>;
  className?: string;
  onHide: (e?: React.MouseEvent<HTMLElement>) => void;
  show: boolean;
  showCancel?: boolean;
  showFooter?: boolean;
  showXButton?: boolean;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerContent?: any;
  closeOnFocusLost?: boolean;
};
const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal-root');
document.body.appendChild(modalRoot);
const element = document.createElement('div');
modalRoot.appendChild(element);

const Modal: FC<ModalProps> = ({
  buttons,
  children,
  className,
  onHide,
  show,
  showCancel = true,
  showFooter = true,
  showXButton = true,
  title,
  headerContent,
  closeOnFocusLost = true,
}) => {
  const { t } = useTranslation();

  const modalRef = createRef<HTMLDivElement>();

  useClickOutside(modalRef, () => {
    if (closeOnFocusLost) {
      onHide();
    }
  });

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e?.stopPropagation();
  };

  return ReactDOM.createPortal(
    show ? (
      <div className={classNames('modal-background fadein', className)}>
        <div ref={modalRef} className="modal-dialog" role="dialog">
          <div
            className="modal-header"
            onClick={handleStopPropagation}
          >
            {title && (<h4>{title}</h4>)}
            {headerContent && (headerContent)}
            {showXButton && (
            <Button className="btn-close" color="transparent" onClick={onHide}>
              <X />
            </Button>
            )}
          </div>
          <div
            className="modal-body"
            onClick={handleStopPropagation}
          >
            {children}
          </div>
          {showFooter && (
            <div
              className="modal-footer"
              onClick={handleStopPropagation}
            >
              {buttons
                && buttons.length > 0
                && buttons.map((button) => (
                  <Button
                    key={button.id}
                    color={button.color}
                    disabled={button.disabled}
                    id={button.id}
                    text={button.text}
                    onClick={button.onClick}
                  />
                ))}
              {showCancel && (
                <Button
                  color="secondary"
                  text={t('common.cancel')}
                  onClick={onHide}
                />
              )}
            </div>
          )}
        </div>
      </div>
    ) : (
      <Fragment />
    ),
    element,
  );
};

export default Modal;
