import { useEffect } from 'react';

import { FullUser } from 'models/User';

import { CenterType } from './CenterType';
import { useInterval } from './useInterval';
import { Centers } from './useWorkshopTvView';
import { WorkshopTypes } from './WorkshopTypes';

export const useWorkshopRefresh = (
  callback: () => unknown,
  id: string,
  ip: string | undefined,
  user: FullUser | undefined,
  type: WorkshopTypes,
): void => {
  const { startInterval, stopInterval } = useInterval();
  const refreshFrecuency = parseInt(
    process.env.REACT_APP_WORKSHOP_INTERVAL || '30000',
    10,
  );
  let intervalId: unknown;

  useEffect(() => {
    if (!user && Centers[id as CenterType] && WorkshopTypes[type] && ip) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervalId = startInterval(callback, refreshFrecuency).intervalId;
    }
    return () => stopInterval(intervalId);
  }, [id, ip, user, type]);
};
