import { FormikProps, getIn } from 'formik';
import React, { Fragment, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Input from 'components/base/Input';
import Select from 'components/base/Select';

import { IMasterDataDto } from 'models/MasterData';
import { RecordTypeEnum, SaveRecord } from 'models/Record';
import { RootState } from 'store';

type AbortionFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

const AbortionFields: FC<AbortionFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const { t } = useTranslation();
  const { abortionTypes, genders } = useSelector(
    (state: RootState) => state.masterData,
  );
  const { normalized } = useSelector((state: RootState) => state);
  const isAbortion = values.type
    && normalized.recordTypes[values.type].code === RecordTypeEnum.ABORTION;

  return (
    <Fragment>
      <fieldset>
        {createMode && <legend>{t('record.steps.abortion')}</legend>}
        <Select<IMasterDataDto>
          disabled={disabled || isSubmitting || !isAbortion}
          error={getIn(errors, 'abortionData.gender')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="abortionData.gender"
          options={genders}
          placeholder={t('common.gender')}
          value={
            values.abortionData?.gender
              ? normalized.genders[values.abortionData?.gender]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('abortionData.gender', val?.code)}
        />
        <Input
          checked={values.abortionData?.declarantIsMother}
          disabled={disabled || isSubmitting || !isAbortion}
          error={getIn(errors, 'abortionData.declarantIsMother')}
          name="abortionData.declarantIsMother"
          placeholder={t('record.abortion.declarantIsMother')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('abortionData.declarantIsMother', val)}
        />
        <Input
          disabled={disabled || isSubmitting || !isAbortion}
          error={getIn(errors, 'abortionData.gestationWeeks')}
          max={50}
          min={1}
          name="abortionData.gestationWeeks"
          placeholder={t('record.abortion.gestationWeeks')}
          type="number"
          value={values.abortionData?.gestationWeeks || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('abortionData.gestationWeeks', val)}
        />
        <Input
          disabled={disabled || isSubmitting || !isAbortion}
          error={getIn(errors, 'abortionData.fatherName')}
          name="abortionData.fatherName"
          placeholder={`${t('common.name')} ${t('record.abortion.father')}`}
          type="text"
          value={values.abortionData?.fatherName || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('abortionData.fatherName', val)}
        />
        <Input
          disabled={disabled || isSubmitting || !isAbortion}
          error={getIn(errors, 'abortionData.fatherFirstSurname')}
          name="abortionData.fatherFirstSurname"
          placeholder={`${t('common.firstSurname')} ${t(
            'record.abortion.father',
          )}`}
          type="text"
          value={values.abortionData?.fatherFirstSurname || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('abortionData.fatherFirstSurname', val)}
        />
        <Input
          disabled={disabled || isSubmitting || !isAbortion}
          error={getIn(errors, 'abortionData.fatherSecondSurname')}
          name="abortionData.fatherSecondSurname"
          placeholder={`${t('common.secondSurname')} ${t(
            'record.abortion.father',
          )}`}
          type="text"
          value={values.abortionData?.fatherSecondSurname || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('abortionData.fatherSecondSurname', val)}
        />
        <Select<IMasterDataDto>
          disabled={disabled || isSubmitting || !isAbortion}
          error={getIn(errors, 'abortionData.type')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="abortionData.type"
          options={abortionTypes}
          placeholder={t('common.type')}
          value={
            values.abortionData?.type
              ? normalized.abortionTypes[values.abortionData?.type]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('abortionData.type', val?.code)}
        />
      </fieldset>
    </Fragment>
  );
};

export default AbortionFields;
