import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/base/Input';
import Textarea from 'components/base/Textarea';

import { AssignParametersProps } from 'models/OrderAssign';
import OrderType from 'models/OrderType';

const WorkOrderAssignParameters: FC<AssignParametersProps> = ({
  info,
  order,
  setInfo,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {(order?.type === OrderType.PICKUP || order?.type === OrderType.MOVEMENT)
      && (
      <Input
        aria-label="vehicle"
        id="vehicle"
        maxLength={50}
        name="vehicle"
        placeholder={t('order.vehicle')}
        value={info?.vehicle}
        onChange={(val: string) => setInfo({ ...info, vehicle: val })}
      />
      )}

      {(order.type !== OrderType.HANDBOOK && order.type !== OrderType.HANDBOOK_CEMETERY)
    && (
    <Textarea
      aria-label="comment"
      id="comment"
      maxLength={100}
      name="comment"
      placeholder={t('common.comment')}
      value={info?.comment}
      onChange={(val: string) => setInfo({ ...info, comment: val })}
    />
    )}

      {(order?.type === OrderType.PICKUP)
      && (
      <Input
        aria-label="driver"
        id="driver"
        maxLength={150}
        name="driver"
        placeholder={t('order.driver')}
        value={info?.driver}
        onChange={(val: string) => setInfo({ ...info, driver: val })}
      />
      )}
    </React.Fragment>
  );
};

export default WorkOrderAssignParameters;
