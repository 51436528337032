import classNames from 'classnames';
import React, { FC } from 'react';

import Button from 'components/base/Button';
import { Row } from 'components/base/Row';

import './index.scss';
import { Classable } from '../../types/helpers';

interface SelectableItemComponent extends Classable {
  id: string;
  caption: string;
  onClick: (id: string) => void;
  selected: boolean;
}

export const SelectableItem: FC<SelectableItemComponent> = (
  {
    id, caption, onClick, selected, className = '',
  },
) => (
  <Row
    className={classNames('selectable-item', className, selected ? 'selectable-item-selected' : '')}
  >
    <Button
      className="selectable-item-content"
      role="option"
      selected={selected}
      text={caption}
      onClick={() => onClick(id)}
    />
  </Row>
);
