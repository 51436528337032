import { DocumentDNIScanData } from 'modules/ia/models/DocumentDNIScanData';

import { mapBirthDate } from './mapBirthDate';
import { mapGenre } from './mapGenre';
import { ApiDNIScanResponseValues } from './types/ApiDNIScanResponseValues';

export const mapIAValues = (values: ApiDNIScanResponseValues): DocumentDNIScanData => ({
  firstSurname: values.firstSurname,
  birthAddress: {
    firstField: values.birthAddress.firstField,
    secondField: values.birthAddress.secondField,
  },
  birthDate: mapBirthDate(values.birthDate),
  firstParent: values.firstParent,
  genre: mapGenre(values.genre),
  nationality: values.nationality,
  secondParent: values.secondParent,
  name: values.name,
  secondSurname: values.secondSurname,
  idCard: values.idCard
    ? values.idCard.toString().toUpperCase()
    : values.idCard,
  address: {
    country: values.address.country,
    street: values.address.street,
    province: values.address.province,
    city: values.address.city,
  },
});
