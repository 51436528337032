import React, { FC } from 'react';
import { Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import config from 'config';

import Button from 'components/base/Button';
import { Row } from 'components/base/Row';

import { BudgetCart } from '../../BudgetCart';

import { BudgetWizardButtonsComponent } from './types';
import './index.scss';

export const BudgetWizardButtons: FC<BudgetWizardButtonsComponent> = (
  {
    showGotoTracking, showCart, budgetId, companyName, type,
  },
) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Row className="q-budget-wizard-buttons" justifyContent="space-between">
      <Row justifyContent="flex-start" style={{ alignItems: 'center' }}>
        <div>
          <div className="header-text-item">{`${t('budget.budgetNumber')}: ${budgetId || '-'}`}</div>
          {type && (<div>{`${t('common.type')}: ${t(`budget.wizard.summary.clientTypes.${type}`)}`}</div>)}
          {companyName && (<div>{companyName}</div>)}
        </div>

        <div>
          {showGotoTracking && (
          <Button
            color="secondary"
            onClick={() => history.push(config.url.budgetsTracking)}
          >
            <Users />
          </Button>
          )}
        </div>
      </Row>

      <Row justifyContent="flex-end">
        {showCart ? (<BudgetCart />) : <React.Fragment />}
      </Row>
    </Row>
  );
};
