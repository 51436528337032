import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatHour, getCurrentDate } from 'utils/dateManager';
import { showErrorToast } from 'utils/toasts';

import Datepicker from 'components/base/Datepicker';
import Input from 'components/base/Input';
import { Row } from 'components/base/Row';
import Select from 'components/base/Select';

import {
  MasterDataDto, OrderLine, OrderLineChannel,
} from 'config/apiFunus/generated/data-contracts';
import { useAdvice } from 'hooks/useAdvice';
import { useArticles } from 'hooks/useArticles';
import { MasterDataWithPrice } from 'hooks/useArticles/MasterDataWithPrice';
import { useValidator } from 'hooks/useValidator';
import { priceFormatWithoutCurrency } from 'modules/budget/components/budgetSummary/tools';

import { AddArticleRequestFormComponent } from './types';

export const AddArticleRequestForm: FC<AddArticleRequestFormComponent> = (
  {
    channel,
    erpId,
    id,
    value,
    onArticleChange,
    onChange,
    onIsValidUpdate,
    onSupplierChange,
    vigilDate,
    vigilHour,
    articleTypeDisabled,
    articleDisabled,
    priceDisabled,
    providerDisabled,
    clientDisabled,
  },
) => {
  const { t } = useTranslation();
  const { validateArticleAddRequest } = useValidator();
  const {
    articleCategories,
    fetchArticleCategories,
    fetchArticleByCategory,
    articlesByCategory,
    articleProviders,
    fetchArticleProviders,
  } = useArticles();
  const { fetchClients, clients } = useAdvice();
  const [selectedCategory, setselectedCategory] = useState<string>();
  const [selectedArticle, setselectedArticle] = useState<string>();
  const [defaultValues] = useState<OrderLine & {idSupplier?: string}>({
    amount: 1,
    articleDescription: value?.articleDescription || '',
    category: value?.category || '',
    channel: Object
      .values(OrderLineChannel)
      .find((channelOption) => channelOption as string === channel as string)
      || OrderLineChannel.PROCESSING,
    client: {
      identifier: value?.client?.identifier || '',
    },
    code: value?.code || '',
    comments: value?.comments || '',
    date: channel !== OrderLineChannel.RECEPTION
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ? (vigilDate as any)
      : getCurrentDate(),
    dedicatory: value?.dedicatory || '',
    erpId: Number(erpId),
    idSupplier: value?.idSupplier || '',
    observations: value?.observations || '',
    price: value?.price,
    supplier: value?.supplier || '',
    time: channel !== OrderLineChannel.RECEPTION
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ? (vigilHour as any)
      : formatHour(getCurrentDate()),
  });

  useEffect(
    () => {
      if (selectedArticle) {
        fetchArticleProviders(selectedArticle)
          .catch((error) => {
            showErrorToast(error.message);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedArticle],
  );

  useEffect(
    () => {
      if (selectedCategory) {
        fetchArticleByCategory(selectedCategory)
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCategory],
  );

  useEffect(
    () => {
      onChange(defaultValues);
      fetchArticleCategories()
        .catch(() => false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (erpId) {
        fetchClients(Number(id));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [erpId],
  );
  return (
    <Formik
      initialValues={defaultValues}
      validate={(values) => {
        setselectedCategory(values.category);
        setselectedArticle(values.code);
        onArticleChange?.(values?.articleDescription || '');
        onSupplierChange?.(values?.supplierDescription || '');
        const supplier = articleProviders.find((s) => s.code === values.idSupplier);
        const recordArticleRequest = {
          ...values,
          date: values.date ? new Date(values.date) : undefined,
          idSupplier: supplier?.code || '',
          price: values.price
            ? parseFloat(values.price?.toString().replace(',', '.'))
            : undefined,
          supplier: supplier?.email || '',
        };
        onChange(recordArticleRequest);

        return validateArticleAddRequest(recordArticleRequest);
      }}
      validateOnBlur
      validateOnChange
      validateOnMount
      onSubmit={() => Promise.resolve()}
    >
      {({
        setFieldValue, values, errors, isValid,
      }: FormikProps<OrderLine & {
        articleDescription?: string;
        supplierDescription?: string;
        idSupplier?: string;
      }>) => {
        onIsValidUpdate?.(isValid);
        const selectDefaultSuppier = () => {
          const defaultProvider = articleProviders.find((e) => e.isDefault === true);

          if (defaultProvider && values.idSupplier !== defaultProvider.code) {
            setFieldValue('idSupplier', defaultProvider.code);
          }
        };

        selectDefaultSuppier();
        return (
          <Form autoComplete="off">
            <Select<MasterDataDto>
              key="client.identifier"
              disabled={clientDisabled}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              error={(errors.client as any)?.identifier}
              getLabel={({ description }) => description || ''}
              getValue={({ code }) => code || ''}
              name="client.identifier"
              options={clients}
              placeholder={t('common.client')}
              value={clients.find((client) => client.code === values.client?.identifier)}
              searchable
              onChange={(client) => {
                setFieldValue('client.identifier', client?.code);
              }}
            />
            <Select<MasterDataDto>
              key="category"
              disabled={articleTypeDisabled}
              error={errors.category}
              getLabel={({ description }) => description || ''}
              getValue={({ code }) => code || ''}
              name="category"
              options={articleCategories}
              placeholder={t('article.flowers.category')}
              value={articleCategories.find((category) => category.code === values.category)}
              searchable
              onChange={(category) => {
                setFieldValue('category', category?.code);
              }}
            />
            <Select<MasterDataWithPrice>
              key="code"
              disabled={articleDisabled}
              error={errors.code}
              getLabel={({ description, priceWithVAT }) => {
                let label = '';
                if (description) {
                  label = `${description}`;
                }
                if (priceWithVAT) {
                  label = `${label} (${priceWithVAT}€)`;
                }

                return label;
              }}
              getValue={({ code }) => code || ''}
              itemContent={({ description, priceWithVAT }) => (
                <Row justifyContent="space-between" style={{ width: '100%' }}>
                  <div style={{ textAlign: 'left' }}>{description}</div>
                  <div style={{ textAlign: 'right' }}>
                    (
                    {priceWithVAT}
                    €)
                  </div>
                </Row>
              )}
              name="code"
              options={articlesByCategory}
              placeholder={t('common.articles')}
              value={articlesByCategory.find((article) => article.code === values.code)}
              searchable
              onChange={(article) => {
                setFieldValue('price', article?.price);
                setFieldValue('articleDescription', article?.description);
                setFieldValue('code', article?.code);
              }}
            />
            {values.code && (
            <Input
              disabled={priceDisabled}
              error={errors.price}
              id="price"
              name="price"
              placeholder={t('article.flowers.price')}
              type="text"
              value={values.price !== undefined && values.price !== null
                ? priceFormatWithoutCurrency(values.price) : ''}
              onBlur={() => false}
              onChange={(amount) => { setFieldValue('price', amount); }}
            />
            )}
            <Input
              error={errors.observations}
              id="observations"
              name="observations"
              placeholder={t('article.flowers.observations')}
              type="text"
              value={values.observations || ''}
              onBlur={() => false}
              onChange={(text) => setFieldValue('observations', text)}
            />
            <Input
              error={errors.dedicatory}
              id="dedicatory"
              name="dedicatory"
              placeholder={t('article.flowers.dedicatory')}
              type="text"
              value={values.dedicatory || ''}
              onBlur={() => false}
              onChange={(text) => setFieldValue('dedicatory', text)}
            />
            <Datepicker
              key="date"
              disabled={channel === OrderLineChannel.RECEPTION}
              error={errors.date}
              name="date"
              placeholder={t('article.flowers.date')}
              selected={values.date}
              onChange={(date) => setFieldValue('date', date)}
            />
            <Datepicker
              key="time"
              disabled={channel === OrderLineChannel.RECEPTION}
              error={errors.time}
              name="time"
              placeholder={t('article.flowers.time')}
              selected={values.time}
              showTimeSelectOnly
              onChange={(time) => setFieldValue('time', time)}
            />
            <Select<MasterDataDto>
              key="supplier"
              disabled={providerDisabled}
              error={errors.supplier}
              getLabel={({ description }) => description || ''}
              getValue={({ code }) => code || ''}
              name="supplier"
              options={articleProviders}
              placeholder={t('common.provider')}
              value={articleProviders.find((supplier) => supplier.code === values.idSupplier)}
              searchable
              onChange={(provider) => {
                setFieldValue('supplierDescription', provider?.description);
                setFieldValue('idSupplier', provider?.code);
              }}
            />
            <Input
              error={errors.comments}
              id="comments"
              name="comments"
              placeholder={t('common.comments')}
              type="text"
              value={values.comments || ''}
              onBlur={() => false}
              onChange={(text) => setFieldValue('comments', text)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
