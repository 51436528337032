import { ILocation } from './types';

export class Location implements ILocation {
  id: string;

  work: boolean;

  constructor(parameters: ILocation) {
    this.id = parameters.id;
    this.work = parameters.work;
  }
}
