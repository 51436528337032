import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';

import Input from 'components/base/Input';

import './index.scss';

export type RadioButtonProps<T> = {
  checked?: string | undefined;
  className?: string | undefined;
  clearable?: boolean | undefined;
  disabled?: boolean | undefined;
  error?: string | boolean | undefined;
  getLabel: (elem: T) => string;
  getValue: (elem: T) => string | number;
  name: string;
  id?: string;
  onChange(value: string | number): void;
  options: T[];
  placeholder?: string | undefined;
};

function RadioButton<T>({
  checked,
  className,
  clearable,
  disabled,
  error,
  getLabel,
  getValue,
  name,
  id,
  onChange,
  options,
  placeholder,
}: RadioButtonProps<T>): ReactElement {
  const [expanded] = useState(false);
  const onClickOption = (value: string | number) => {
    onChange(value);
  };

  return (
    <div
      className={classNames('funus-radio', className, {
        error: !!error,
        expanded,
      })}
    >
      {placeholder && (
        <label className="radio-placeholder" htmlFor={id}>
          {placeholder}
        </label>
      )}
      {options.length > 0
        && options.map((opt) => {
          const label = getLabel(opt);
          const value = getValue(opt);
          return (
            <Input
              key={value}
              aria-label={`${name}-${value}`}
              checked={checked === value}
              clearable={clearable}
              disabled={disabled}
              id={id}
              name={name}
              // onClick={() => changeExpanded(true)}
              placeholder={label}
              type="radio"
              value={value}
              readOnly
              onChange={() => onClickOption(value)}
            />
          );
        })}
      <span className={classNames({ hidden: !error })}>{error}</span>
    </div>
  );
}

export default RadioButton;
