import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Calendar, Views } from 'react-big-calendar';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import config from 'config';

import {
  getLocalizer,
  getMonth,
  getNextMonth,
  getPrevMonth,
  getWeekday,
  getYear,
} from 'utils/dateManager';
import { formatEvents } from 'utils/eventFormatter';

import Button from 'components/base/Button';
import Datepicker from 'components/base/Datepicker';
import Loader from 'components/base/Loader';

import EventCalendar from 'components/calendar/CalendarEvent';

import { MasterServiceDtoType } from 'config/apiFunus/generated/data-contracts';
import { useAdvice } from 'hooks/useAdvice';
import { i18n } from 'i18n';
import { ExtendedEvent } from 'models/Event';

import './index.scss';

export const CalendarPageWithoutInfo: FC = () => {
  const now = new Date();

  return (
    <Redirect
      from={config.url.calendarNoInfo}
      to={`${config.url.calendarNoInfo}/${getYear(now)}/${getMonth(now) + 1}`}
      exact
    />
  );
};

export const CalendarPage: FC = () => {
  const { month, year } = useParams<{year: string; month: string}>();
  const history = useHistory();
  const { events: calendarEvents, eventsLoading: loading, fetchEvents } = useAdvice();

  const [date, setDate] = useState<Date>(new Date(Number(year), Number(month) - 1));
  const [events, setEvents] = useState<ExtendedEvent[]>([]);

  const { t } = useTranslation();
  const culture = i18n.language.substring(0, 2);

  useEffect(() => {
    setDate(new Date(Number(year), Number(month) - 1));
  }, [month, year]);

  const loadDate = useCallback(
    (activeDate) => {
      history.push(`${config.url.calendarNoInfo}/${getYear(activeDate)}/${getMonth(activeDate) + 1}`);
    },
    [history],
  );

  const addMonth = () => {
    loadDate(getNextMonth(date));
  };

  const subtractMonth = () => {
    loadDate(getPrevMonth(date));
  };

  useEffect(
    () => {
      fetchEvents(
        date, [
          MasterServiceDtoType.CEREMONIA,
          MasterServiceDtoType.INCINERACION,
          MasterServiceDtoType.VELATORIO,
          MasterServiceDtoType.INHUMACION,
          MasterServiceDtoType.TRANSPORTE,
        ],
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date],
  );

  useEffect(() => {
    setEvents(formatEvents(calendarEvents));
  }, [calendarEvents]);

  return (
    <div className="calendar-page">
      {loading && <Loader />}
      <div className="calendar-toolbar">
        <Button
          leftAddon={<ChevronLeft />}
          text={t('common.prev')}
          onClick={subtractMonth}
        />
        <Datepicker
          name="date"
          placeholder={t('common.month')}
          selected={date}
          showFullMonthYearPicker
          showMonthYearPicker
          onChange={(dt: Date) => {
            loadDate(dt);
          }}
        />
        <Button
          rightAddon={<ChevronRight />}
          text={t('common.next')}
          onClick={addMonth}
        />
      </div>
      <Calendar
        components={{
          event: EventCalendar,
        }}
        culture={culture}
        date={date}
        defaultView={Views.MONTH}
        endAccessor="end"
        events={events}
        formats={{
          weekdayFormat: (dt: Date) => getWeekday(dt),
        }}
        localizer={getLocalizer()}
        messages={{ showMore: (count) => `${count} ${t('common.more')}` }}
        startAccessor="start"
        toolbar={false}
        views={{ month: true }}
        popup
      />
    </div>
  );
};
