import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import useCeremonyLocation from 'hooks/useCeremonyLocation';
import { CeremonyBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, displayDate, displayHour, WorkshopCardHeaderDetail,
} from './CardDetails';

type CeremonyDetailsProps = {
  order: CeremonyBaseWorkOrder;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CeremonyDetails = ({ order }: CeremonyDetailsProps) => {
  const { t } = useTranslation();
  const { getCeremonyLocation } = useCeremonyLocation(order.city);

  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);

  useEffect(() => {
    setDetails([
      {
        label: t('common.date'),
        value: displayDate(order.ceremonyDate),
      },
      {
        label: t('common.hour'),
        value: displayHour(order.time),
      },
      {
        label: t('common.location'),
        value: getCeremonyLocation(order.location)?.description || displayNoValue,
      },
    ]);
  }, [
    order,
    t,
    getCeremonyLocation,
  ]);

  return (<CardDetails details={details} />);
};
