import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import Tooltip from 'components/base/Tooltip';

import { useBudget } from 'modules/budget/hooks/useBudget';
import { ArticleType } from 'modules/budget/models/Article/types';

import { BudgetSummaryLineActionsComponent } from './types';

export const BudgetSummaryLineActions: FC<BudgetSummaryLineActionsComponent> = (
  { article, budgetId },
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { GoToQuestion, DiscardArticle, articlesLoading } = useBudget();
  const editQuestion = useCallback(
    () => {
      if (article.questionCode) {
        GoToQuestion(article.questionCode);
        history.push(
          {
            pathname: `/budget/wizard/${budgetId}/${article.questionCode}`,
            state: { gotoSummary: true },
          },
        );
      }
    },
    [GoToQuestion, article.questionCode, budgetId, history],
  );

  const discardArticle = useCallback(
    () => {
      DiscardArticle({
        amount: article.amount,
        code: article.code,
        description: article.description,
        price: article.price,
        questionCode: article.questionCode || undefined,
        subType: article.subType,
        type: article.type,
        vat: article.vat,
      });
    },
    [DiscardArticle, article],
  );

  return (
    <div className="f-budget-actions">
      {article.questionCode && (
      <Tooltip content={t('budget.actions.editQuestion')}>
        <Button
          color="transparent"
          disabled={articlesLoading}
          testid="see-button"
          onClick={editQuestion}
        >
          <CustomIcon icon={OtherTypes.BUDGET_EDIT_QUESTION} />
        </Button>
      </Tooltip>
      )}
      {article.type === ArticleType.AUTOMATIC && (
      <Tooltip content={t('budget.actions.discardAutomatic')}>
        <Button
          color="transparent"
          disabled={articlesLoading}
          testid="see-button"
          onClick={discardArticle}
        >
          <CustomIcon icon={OtherTypes.DELETE} />
        </Button>
      </Tooltip>
      )}
    </div>
  );
};
