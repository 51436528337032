import React, { useReducer, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import config from 'config';

import { getDate, isAfter } from 'utils/dateManager';
import {
  checkHaveAtLeastOnePermission,
  checkHaveThisPermissions,
  checkPermission,
} from 'utils/permissions';

import { fillMasterData } from 'actions/masterData';
import { addRoles } from 'actions/roles';

import { dispatchTypes, reducer, initialState } from 'reducers/authProvider';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { AuthContext } from 'hooks/useProvidedAuth';
import { FullUser } from 'models/User';
import { Permission } from 'models/UserRole';
import { RootState } from 'store';
import { masterDataTypes } from 'types/masterData';

import { version } from '../../../package.json';

const getLanding = (permissions: Permission[]): string | undefined => {
  if (
    checkPermission(SimpleUserRequestPermissionNames.USER_READ, permissions)
    && checkHaveAtLeastOnePermission(
      [SimpleUserRequestPermissionNames.USER_WRITE, SimpleUserRequestPermissionNames.USER_ADD],
      permissions,
    )
  ) {
    return config.url.users;
  }

  if (
    checkHaveThisPermissions(
      [SimpleUserRequestPermissionNames.RECORD_READ, SimpleUserRequestPermissionNames.ENQUIRY_READ],
      permissions,
    )
    || checkPermission(SimpleUserRequestPermissionNames.RECORD_ASSIGN, permissions)
  ) {
    return config.url.landing;
  }

  if (checkPermission(SimpleUserRequestPermissionNames.ENQUIRY_READ, permissions)) {
    return config.url.enquiries;
  }

  if (
    checkPermission(SimpleUserRequestPermissionNames.RECORD_READ, permissions)
    && !checkPermission(SimpleUserRequestPermissionNames.ADVICE_ADD, permissions)
  ) {
    return config.url.records;
  }

  if (
    checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ,
        SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ,
      ],
      permissions,
    )
  ) {
    return config.url.workshop;
  }

  return undefined;
};

const AuthProvider: FC = ({ children }) => {
  const [auth, dispatchAuth] = useReducer(reducer, initialState);
  const { countries, rehydrated } = useSelector(
    (state: RootState) => state.masterData,
  );
  const { normalized } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const setUser = (user: FullUser, cbFunction: () => void) => new Promise((resolve, reject) => {
    const lastVersion = config.storage.getVersion();
    const expirationDate = config.storage.getExpiration();

    const versionHasChanged = lastVersion !== version;
    const expirationDateExceded = !expirationDate
        || (!!expirationDate && isAfter(new Date(), getDate(expirationDate)));

    const masterDataExpired = versionHasChanged || expirationDateExceded;
    dispatchAuth({
      type: dispatchTypes.SET_LANDING_PAGE,
      payload: getLanding(user.role.permissions),
    });
    if (
      checkPermission(SimpleUserRequestPermissionNames.USER_READ, user.role.permissions)
        && checkHaveAtLeastOnePermission(
          [SimpleUserRequestPermissionNames.USER_WRITE, SimpleUserRequestPermissionNames.USER_ADD],
          user.role.permissions,
        )
    ) {
      dispatch(addRoles());
    }
    if (!countries?.length || masterDataExpired) {
      return fillMasterData(dispatch)
        .then(() => {
          dispatchAuth({ type: dispatchTypes.SET_USER, payload: user });
          if (
            (checkPermission(
              SimpleUserRequestPermissionNames.RECORD_READ,
              user.role.permissions,
            )
                && checkHaveAtLeastOnePermission(
                  [
                    SimpleUserRequestPermissionNames.RECORD_ADD,
                    SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
                    SimpleUserRequestPermissionNames.RECORD_WRITE,
                  ],
                  user.role.permissions,
                ))
              || checkPermission(
                SimpleUserRequestPermissionNames.RECORD_ASSIGN,
                user.role.permissions,
              )
          ) {
            resolve(user);
            cbFunction();
          }
          cbFunction();
          return resolve(user);
        })
        .catch(() => {
          cbFunction();
          dispatchAuth({ type: masterDataTypes.CLEAR_MASTERDATA });
          return reject();
        });
    }
    cbFunction();
    dispatchAuth({ type: dispatchTypes.SET_USER, payload: user });
    return resolve(user);
  });

  const setLoading = (loading: boolean) => {
    dispatchAuth({ type: dispatchTypes.SET_LOADING, payload: loading });
  };

  const autologin = async () => {
    try {
      const res = await config.apiFunus.login.autologin();
      if (res?.data?.id) {
        setUser(res.data, () => setLoading(false))
          .then(() => res.data)
          .catch(() => {
            exit();
          });
      } else {
        exit();
      }
    } catch {
      exit();
    }
  };

  const exit = () => {
    dispatchAuth({ type: dispatchTypes.REMOVE_USER });
    config.storage.removeToken();
    setLoading(false);
    history.push(config.url.landing);
  };

  useEffect(() => {
    if (rehydrated && normalized.rehydrated) {
      const token = config.storage.getToken();
      if (token) {
        autologin();
      } else {
        setLoading(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated, normalized.rehydrated]);

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        setLoading,
        setUser,
        exit,
      }}
    >
      {rehydrated && normalized.rehydrated && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
