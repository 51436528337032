const sale = {
  amount: 'Quantitat',
  detail: 'Detall de venda',
  new: 'Nova venda',
  product: 'Producte',
  saveKo: 'S\'ha produït un error a l\'hora de crear la venda.',
  saveOk: 'La venda s\'ha creat satisfactòriament.',
  search: 'Cercar vendes',
  title: 'Vendes',
};
export default sale;
