import { CoffinSizes } from 'hooks/useCoffins/types';
import { BlocTypes } from 'modules/budget/components/budgetConfig/budgetForm/BlocTypes';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { ArticleCollectionTypes, ArticleType } from 'modules/budget/models/Article/types';
import { Audit } from 'modules/budget/models/Audit';
import { AuditId, IAudit } from 'modules/budget/models/Audit/types';
import { BudgetArticle } from 'modules/budget/models/BudgetArticle/types';
import { BudgetConfigurationLocation } from 'modules/budget/models/BudgetConfigurationLocation';

export type BudgetArticleResponse = BudgetArticle[];

export interface ISaveConfigurationRequest extends IAudit {
  bloc: BlocTypes | null;
  coffinSize?: CoffinSizes | null;
  excludedArticles: string[];
  excludedClients: string[];
  excludedLocations: BudgetConfigurationLocation[];
  image?: string | null;
  includedArticles: string[]
  includedClients: string[];
  includedLocations: BudgetConfigurationLocation[];
  mandatoryResponses: string[];
  optionalResponses: string[];
  questionCode: BudgetQuestionCodes | null;
  subType: ArticleCollectionTypes | null;
  type: ArticleType;
  sorting: number | null;
  active: boolean;
}

export type ISaveConfigurationResponse = ISaveConfigurationRequest;

export class SaveConfigurationRequest extends Audit implements ISaveConfigurationRequest {
  constructor(params: ISaveConfigurationRequest) {
    super(params);
    this.excludedArticles = params.excludedArticles;
    this.excludedClients = params.excludedClients;
    this.excludedLocations = params.excludedLocations;
    this.includedArticles = params.includedArticles;
    this.includedClients = params.includedClients;
    this.includedLocations = params.includedLocations;
    this.mandatoryResponses = params.mandatoryResponses;
    this.optionalResponses = params.optionalResponses;
    this.questionCode = params.questionCode;
    this.subType = params.subType;
    this.type = params.type;
    this.coffinSize = params.coffinSize;
    this.bloc = params.bloc;
    this.image = params.image;
    this.sorting = params.sorting;
    this.active = params.active;
  }

  active: boolean;

  sorting: number | null;

  bloc: BlocTypes | null;

  coffinSize?: CoffinSizes | null;

  createdBy?: string | null | undefined;

  createdDate?: string | null | undefined;

  excludedArticles: Array<string>;

  excludedClients: string[];

  excludedLocations: BudgetConfigurationLocation[];

  id?: AuditId | undefined;

  idVersion?: number | null | undefined;

  image?: string | null;

  includedArticles: string[];

  includedClients: string[];

  includedLocations: BudgetConfigurationLocation[];

  mandatoryResponses: string[];

  optionalResponses: string[];

  questionCode: BudgetQuestionCodes | null;

  subType: ArticleCollectionTypes | null;

  type: ArticleType;
}
