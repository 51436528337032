import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useCities } from 'hooks/useCities';
import { CityType } from 'models/MasterData';

import Select from '../Select';

import { CitiesComponent } from './types';

export const Cities: FC<CitiesComponent> = ({
  disabled = false, value, onChange, countryCode, provinceCode,
}) => {
  const { t } = useTranslation();
  const { cities, getCityByNormalizedCode } = useCities();

  const city = getCityByNormalizedCode(countryCode, provinceCode, value);

  return (
    <Select<CityType>
      disabled={disabled}
      getLabel={(element) => element.description}
      getValue={(element) => element.code}
      limit={15}
      name="cities"
      options={cities}
      placeholder={t('common.city')}
      value={city || undefined}
      searchable
      onChange={(element) => {
        onChange({
          code: element?.code || '',
          countryCode: element?.countryCode || '',
          provinceCode: element?.provinceCode || '',
        });
      }}
    />
  );
};
