import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDate, formatDateAndHour, formatHour } from 'utils/dateManager';

import Input from 'components/base/Input';
import Select from 'components/base/Select';
import Textarea from 'components/base/Textarea';

import { useCoffins } from 'hooks/useCoffins';
import { IMasterDataDto } from 'models/MasterData';
import { RecordTypeEnum, SaveRecord } from 'models/Record';
import { RootState } from 'store';

type GeneralFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

const GeneralFields: FC<GeneralFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const { recordTypes } = useSelector((state: RootState) => state.masterData);
  const { normalized } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const { getCoffinSize } = useCoffins();

  const changeType = (val: IMasterDataDto) => {
    setFieldValue('type', val?.code);
    setFieldValue(
      'abortionData',
      val?.code === RecordTypeEnum.ABORTION ? {} : undefined,
    );
    setFieldValue(
      'amputationData',
      val?.code === RecordTypeEnum.AMPUTATION ? {} : undefined,
    );
  };

  return (
    <fieldset>
      {createMode && <legend>{t('record.steps.general')}</legend>}
      {!createMode && values.erpId && (
        <div className="info-field">
          <span data-testid="record-erpid">
            {`${t('record.erpId')}: `}
            <strong>{values.erpId}</strong>
          </span>
        </div>
      )}
      <div className="info-field">
        {!createMode && values.createdDate && (
          <span data-testid="record-creationdate">
            {`${t('record.creationDate')}: `}
            <strong>{formatDateAndHour(values.createdDate)}</strong>
          </span>
        )}

        {!createMode && values.creator && (
          <span data-testid="record-createdby" style={{ float: 'right' }}>
            {`${t('record.createdBy')}: `}
            <strong>{`${values.creator?.name} ${values.creator?.firstSurname}`}</strong>
          </span>
        )}
      </div>
      <Select<IMasterDataDto>
        disabled={disabled || isSubmitting}
        error={errors.type}
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="type"
        options={recordTypes}
        placeholder={t('record.type')}
        value={values.type ? normalized.recordTypes[values.type] : undefined}
        onBlur={handleBlur}
        onChange={changeType}
      />
      {!createMode && (
        <Input
          error={errors.negotiators}
          name="firstNegotiator"
          placeholder={t('record.firstNegotiator')}
          type="text"
          value={classNames(
            values.negotiators?.[0]?.name,
            values.negotiators?.[0]?.firstSurname,
          )}
          disabled
          onBlur={handleBlur}
        />
      )}
      {!createMode && (
        <Input
          error={errors.negotiators}
          name="secondNegotiator"
          placeholder={t('record.secondNegotiator')}
          type="text"
          value={classNames(
            values.negotiators?.[1]?.name,
            values.negotiators?.[1]?.firstSurname,
          )}
          disabled
          onBlur={handleBlur}
        />
      )}
      {!createMode && (
        <Input
          checked={!!values.publishCeremony}
          data-testid="publishCeremony"
          disabled={isSubmitting || disabled}
          name="publishCeremony"
          placeholder={t('record.publishCeremony')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('publishCeremony', val)}
        />
      )}
      {!createMode && (
        <Input
          checked={!!values.publishVigil}
          data-testid="publishVigil"
          disabled={isSubmitting || disabled}
          name="publishVigil"
          placeholder={t('record.publishVigil')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('publishVigil', val)}
        />
      )}
      {!createMode && (
        <Input
          checked={!!values.ageConfidential}
          data-testid="ageConfidential"
          disabled={isSubmitting || disabled}
          name="ageConfidential"
          placeholder={t('record.ageConfidential')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('ageConfidential', val)}
        />
      )}
      <Textarea
        disabled={isSubmitting || disabled}
        error={errors.comment}
        name="comment"
        placeholder={t('common.observations')}
        value={values.comment || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('comment', val)}
      />
      <Input
        error={errors.collectionComment}
        id="collectionComment"
        name="collectionComment"
        placeholder={t('record.collectionComment')}
        type="text"
        value={values.collectionComment || ''}
        disabled
        onBlur={handleBlur}
      />
      <div>
        <div>
          <span>{`${t('record.processing')}: `}</span>
          <span>{values.appointmentDateTime ? t('record.withAppointment') : t('record.noAppointment')}</span>
        </div>
        {values.appointmentDateTime && (<div>{`${t('common.date')}: ${formatDate(values.appointmentDateTime)}`}</div>)}
        {values.appointmentDateTime && (<div>{`${t('common.hour')}: ${formatHour(values.appointmentDateTime)}`}</div>)}
      </div>
      <div>
        <div>{`${t('common.personalObjects')}: ${values.deceasedData?.personalObjects || '-'}`}</div>
        <div>{`${t('common.size')}: ${values.deceasedData?.coffinSize ? getCoffinSize(values.deceasedData?.coffinSize)?.description || '-' : '-'}`}</div>
        <div>{`${t('order.details.defunctStatus')}: ${values.deceasedData?.defunctStatus || '-'}`}</div>
      </div>
    </fieldset>
  );
};

export default GeneralFields;
