import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { useCities } from 'hooks/useCities';
import { useCountries } from 'hooks/useCountries';
import { useProvinces } from 'hooks/useProvinces';
import { MasterDataQuestionType } from 'modules/budget/api/types';
import { useBudgetLocations } from 'modules/budget/hooks/useBudgetLocations';

import { useBudgetForm } from '../helper';

import { LocationsTableComponent } from './types';

const LocationDescription: FC<{
  country: string;
  province: string;
  city: string;
  code: string,
  questionType: MasterDataQuestionType;
}> = ({
  questionType, country, province, city, code,
}) => {
  const { fetchLocations, getLocation } = useBudgetLocations();

  useEffect(
    () => {
      if (country && province && city) {
        fetchLocations(questionType, country, province, city);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [city, country, province, questionType],
  );

  return (<React.Fragment>{getLocation(code)?.description}</React.Fragment>);
};

export const LocationsTable: FC<LocationsTableComponent> = (
  { locations, onDelete, name },
) => {
  const { t } = useTranslation();
  const { getCountry } = useCountries();
  const { getProvinceByNormalizedCode } = useProvinces();
  const { getCityByNormalizedCode } = useCities();
  const { getLocation } = useBudgetLocations();
  const { locationTypeLabels } = useBudgetForm();

  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>{t('common.country')}</th>
          <th>{t('common.province')}</th>
          <th>{t('common.city')}</th>
          <th>{t('budget.locationType')}</th>
          <th>{t('common.location')}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {locations
          .map((location) => ({
            city: location?.city,
            cityText: getCityByNormalizedCode(
              location?.country,
              location?.province,
              location?.city,
            )?.description,
            countryText: getCountry(location?.country)?.description,
            data: location,
            locationText: location?.location ? getLocation(location?.location)?.description : '',
            locationType: locationTypeLabels(location?.type),
            provinceText: getProvinceByNormalizedCode(
              location?.province,
              location?.country,
            )?.description,
          }))
          .map((location) => {
            const testid = `${location.data.country}${location.data.province}${location.data.city}${location.data.type}${location.data.location}`;

            return (
              <tr key={testid}>
                <td data-testid={`${name}-country-${testid}`}>{location.countryText || ''}</td>
                <td data-testid={`${name}-province-${testid}`}>{location.provinceText || ''}</td>
                <td data-testid={`${name}-city-${testid}`}>{location.cityText || ''}</td>
                <td data-testid={`${name}-type-${testid}`}>{location?.locationType}</td>
                <td data-testid={`${name}-location-${testid}`}>
                  <LocationDescription
                    city={location.data.city || ''}
                    code={location.data.location || ''}
                    country={location.data.country}
                    province={location.data.province || ''}
                    questionType={location.data.type}
                  />

                </td>
                <td style={{ width: '48px' }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      onDelete(location.data);
                    }}
                  >
                    <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
