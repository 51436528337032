import axios from 'config/axios';
import storage from 'config/storage';
import apiFunus from 'config/apiFunus';
import url from 'config/url';
import validator from 'config/validator';

export default {
  axios,
  storage,
  apiFunus,
  url,
  validator,
};
