import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import { DateLocalizer, momentLocalizer } from 'react-big-calendar';

export const DATE_TO_SHOW = 'DD/MM/YYYY';
export const MONTH_TO_SHOW = 'MM/YYYY';
export const HOUR_FORMAT = 'HH:mm';
export const FULL_HOUR_FORMAT = 'HH:mm:ss';
export const DATE_AND_HOUR_TO_SHOW = `${DATE_TO_SHOW} ${HOUR_FORMAT}`;
export const FULL_DATE_TO_SHOW = `${DATE_TO_SHOW} ${FULL_HOUR_FORMAT}`;

export const formatDateForSearch = (
  date: Date | string | undefined,
): string | undefined => {
  if (date) {
    return `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`;
  }
  return undefined;
};

export const formatDateForRequest = (
  date: Date | string | undefined,
): string | undefined => {
  if (date) {
    return `${moment(date).format('YYYY-MM-DD')}`;
  }
  return undefined;
};

export const formatDateAndHour = (date: Date | string): string => moment(date)
  .format(DATE_AND_HOUR_TO_SHOW);

export const getDate = (date: string): Date => moment(date, DATE_AND_HOUR_TO_SHOW).toDate();

export const formatFullDate = (date: Date | string): string => moment(date)
  .format(FULL_DATE_TO_SHOW);

export const formatDate = (date: Date | string): string => moment(date).format(DATE_TO_SHOW);

export const formatMonth = (date: Date | string): string => moment(date).format(MONTH_TO_SHOW);

export const formatHour = (date: Date | string): string => moment(date).format(HOUR_FORMAT);

export const parseHour = (date: string): Date | undefined => {
  if (date) {
    return moment(date, HOUR_FORMAT).toDate();
  }
  return undefined;
};

export const getYear = (date: Date | string): number => moment(date).year();
export const getMonth = (date: Date | string): number => moment(date).month();
export const getDay = (date: Date | string): number => moment(date).date();
export const removeTimezone = (date: Date | string): Date => moment(date).utc().add(moment(date).utcOffset(), 'm').toDate();

export const getAge = (date: Date | string): number => Math.abs(moment(date).diff(moment(), 'years'));

export const getDifferenceInYears = (
  date: Date | string,
  secondDate: Date | string,
): number => Math.abs(moment(date).diff(moment(secondDate), 'years'));

export const getAgeLiteral = (
  birthDate: Date | string,
  currentDate: Date | string,
): string => {
  let ageLiteral = '0';

  const years = Math.abs(moment(birthDate).diff(moment(currentDate), 'years'));
  const months = Math.abs(moment(birthDate).diff(moment(currentDate), 'months'));
  const days = Math.abs(moment(birthDate).diff(moment(currentDate), 'days'));

  if (years > 0) {
    ageLiteral = years === 1 ? `${years} any` : `${years} anys`;
  } else if (months > 0) {
    ageLiteral = months === 1 ? `${months} mes` : `${months} mesos`;
  } else if (days > 0) {
    ageLiteral = days === 1 ? `${days} dia` : `${days} dies`;
  }

  return ageLiteral;
};

export const isAfter = (date1: Date, date2: Date): boolean => moment(date1).isAfter(date2);

export const addDays = (date: Date, days: number): Date => moment(date).add(days, 'days').toDate();
export const subtractDays = (date: Date, days: number): Date => moment(date).subtract(days, 'days').toDate();

export const addHours = (date: Date, hours: number): Date => moment(date).add(hours, 'hour').toDate();
export const subtractHours = (date: Date, hours: number): Date => moment(date).subtract(hours, 'hour').toDate();

export const getPrevMonth = (date: Date): Date => moment(date).startOf('month').subtract(1, 'month').toDate();
export const getNextMonth = (date: Date): Date => moment(date).startOf('month').add(1, 'month').toDate();

export const getLocalizer = (): DateLocalizer => momentLocalizer(moment);
export const getWeekday = (date: Date): string => moment(date).format('dddd');

export const getCurrentDate = (): Date => new Date();
