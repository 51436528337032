import { ApiFunusWorkorder } from './types';
import axios from 'config/axios';

export const workOrder: ApiFunusWorkorder = {
  getWorkOrderById: (id) => axios.get(`/workorder/${id}`),
  assignUsers: (value) => axios.post('/workorder/users', value),
  findWorkOrders: (filter) => axios.post('/workorders', filter),
  findPublicWorkOrdersDetail: (filter) => axios.get(`/public/workorders?type=${filter.type}&workshop=${filter.center}`, {
    headers: { 'ip-address': filter.ip },
  }),
  findWorkOrdersDetail: (filter) => axios.post('/workorders', filter || {}),
  createWorkOrderHandmade: (workorder) => axios.post('/workorders/handmade', workorder),
  updateWorkOderHandmade: (workorder) => axios.put(`/workorders/handmade/${workorder.id}`, workorder),
  deleteWorkOrder: (id) => axios.delete(`/workorders/handmade/${id}`),
  changeStatus: (id, data) => axios.put(`/workorders/${id}`, data),
  getAssignableUsers: (id) => axios.get(`/workorders/${id}/users`)
};
