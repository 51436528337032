import axios from 'axios';
import { useEffect, useState } from 'react';

import { IP_LOCATION_NET_URL, IpLocationNet } from './IpLocationNet';
import { GeolocationHook } from './types';

export const useGeolocation = (): GeolocationHook => {
  const [IPv4, setIPv4] = useState<string>('');

  useEffect(() => {
    const getGeolocation = async () => {
      const res: {data: IpLocationNet} = await axios.get(IP_LOCATION_NET_URL);
      setIPv4(res.data.ip);
    };
    getGeolocation();
  }, []);

  return { IPv4 };
};
