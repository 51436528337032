import React, {
  FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/base/Tooltip';

import { MasterDataDto } from 'config/apiFunus/generated/data-contracts';
import { useBudget } from 'modules/budget/hooks/useBudget';

import Select from '../../../../components/base/Select';

import { ArticleClientSelectorComponent } from './types';

export const ArticleClientSelector: FC<ArticleClientSelectorComponent> = (
  { article, clients, idBudget },
) => {
  const { invoiceArticlesLoading, SetInvoiceArticleClient } = useBudget();
  const { t } = useTranslation();

  const [selectedClient, setSelectedClient] = useState<string>();

  useEffect(
    () => {
      if (clients.length === 1 && clients[0].code && !article.client) {
        setSelectedClient(clients[0].code);
        SetInvoiceArticleClient(article, clients[0].code, idBudget);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [article, clients],
  );

  useEffect(
    () => {
      if (article.client) {
        setSelectedClient(article.client);
      }
    },
    [article.client],
  );

  return (
    <Select<MasterDataDto>
      key="supplier"
      disabled={invoiceArticlesLoading}
      getLabel={({ description }) => description || ''}
      getValue={({ code }) => code || ''}
      itemContent={(
        option,
      ) => (<Tooltip content={option.description}><div>{option.description}</div></Tooltip>)}
      name="supplier"
      options={clients}
      placeholder={t('common.client')}
      title={clients.find((supplier) => supplier.code === selectedClient)?.description}
      value={clients.find((supplier) => supplier.code === selectedClient)}
      searchable
      onChange={(val) => {
        setSelectedClient(val?.code);
        SetInvoiceArticleClient(article, val?.code ? val?.code : null, idBudget);
      }}
    />
  );
};
