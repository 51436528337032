import { ClientType } from '../Article/types';
import { IBudgetSumarizeArticle } from '../BudgetSumarizeArticle/types';

import {
  IBudgetSumarize,
} from './types';

export class BudgetSumarize implements IBudgetSumarize {
  constructor(params: IBudgetSumarize) {
    this.companyName = params.companyName;
    this.type = params.type;
    this.net = params.net;
    this.vat = params.vat;
    this.total = params.total;
    this.articles = params.articles;
  }

  articles: IBudgetSumarizeArticle[];

  companyName: string;

  type: ClientType;

  net: number;

  vat: number;

  total: number;
}
