import { isNumber } from 'utils/helpers';

import { IAudit } from './types';

export class Audit implements IAudit {
  constructor(params: IAudit) {
    this.id = params.id || null;
    this.idVersion = isNumber(params.idVersion) ? params.idVersion : null;
    this.createdBy = params.createdBy || null;
    this.createdDate = params.createdDate || null;
  }

  createdDate?: string | null;

  createdBy?: string | null;

  id?: number | null;

  idVersion?: number | null;
}
