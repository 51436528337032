import { OrderDraft } from "models/OrderDraft";
import {
  ArticleDetailResponseDto,
  ArticleInfoResponseDto,
  ArticleRequestDto,
  ArticleStatusRequestDto,
  ArticleTrackingRequest,
  ArticleTrackingResponse,
  DisagreementRequestDto,
  DisagreementTypeResponseDto,
  EntryChannelResponseDto,
  InputStream,
  OrderLine,
} from "../generated/data-contracts";
import { ContentType, HttpClient, RequestParams } from "../generated/http-client";
import { IOrderDraft } from "models/OrderDraft/types";

export class OrderLineController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  addArticleUsingPost = (orderLine: OrderLine, params: RequestParams = {}) =>
    this.http.request<InputStream, void>({
      path: `/order-lines`,
      method: "POST",
      body: orderLine,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  commercialOverviewUsingGet = (erpId: number, params: RequestParams = {}) =>
    this.http.request<ArrayBuffer, void>({
      path: `/order-lines/commercial-overviews/${erpId}`,
      method: "GET",
      format: 'arraybuffer',
      type: ContentType.Pdf,
      secure: true,
      ...params,
    });

  getArticleInfoUsingPost = (request: ArticleRequestDto, params: RequestParams = {}) =>
    this.http.request<ArticleInfoResponseDto, void>({
      path: `/order-lines/details`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  getDisagreementTypeUsingGet = (params: RequestParams = {}) =>
    this.http.request<DisagreementTypeResponseDto, void>({
      path: `/order-lines/disagreements/types`,
      method: "GET",
      secure: true,
      ...params,
    });

  getEntryChannelsUsingGet = (params: RequestParams = {}) =>
    this.http.request<EntryChannelResponseDto, void>({
      path: `/order-lines/incoming-channels`,
      method: "GET",
      secure: true,
      ...params,
    });

  getArticleTrackingUsingPost = (request: ArticleTrackingRequest, params: RequestParams = {}, showOnlyRecent: boolean = false) =>
    this.http.request<ArticleTrackingResponse, void>({
      path: `/order-lines/trackings${showOnlyRecent ? '?filter=true' : ''}`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  getArticleDetailUsingGet = (id: number, params: RequestParams = {}) =>
    this.http.request<ArticleDetailResponseDto, void>({
      path: `/order-lines/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

    
  GetArticlePhoto = (id: number, params: RequestParams = {}) =>
  this.http.request<string, void>({
    path: `/order-lines/${id}/image`,
    method: "GET",
    secure: true,
    ...params,
  })

  getArticleDraftInfo = (id: number, params: RequestParams = {}) =>
    this.http.request<IOrderDraft
      , void>({
        path: `/order-lines/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }).then((response) => {
        return new OrderDraft(response)
      });

  updateStatusUsingPut = (id: number, request: ArticleStatusRequestDto, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/order-lines/${id}`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  createDisagreementUsingPost = (id: number, request: DisagreementRequestDto, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/order-lines/${id}/disagreements`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  generateDocumentUsingGet = (id: number, params: RequestParams = {}) =>
    this.http.request<ArrayBuffer, void>({
      path: `/order-lines/${id}/document`,
      method: "GET",
      format: 'arraybuffer',
      type: ContentType.Pdf,
      secure: true,
      ...params,
    });


}
