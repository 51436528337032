import {
  Form, Formik, FormikProps, getIn,
} from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';
import config from 'config';

import { formatDateForSearch } from 'utils/dateManager';

import Button from 'components/base/Button';
import Datepicker from 'components/base/Datepicker';
import IdentifierSelector, { IdentifierTypes } from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import InputQRScan from 'components/base/InputQRScan';
import Modal from 'components/base/Modal';
import Select from 'components/base/Select';
import Textarea from 'components/base/Textarea';

import { UpdateAshesDeliveryRequest, AshesInfoResponseDto } from 'config/apiFunus/record/types';
import useAshesDestinations from 'hooks/useAshesDestinations';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useUsers from 'hooks/useUsers';
import { IMasterDataDto } from 'models/MasterData';
import { SimpleUser } from 'models/User';
import { SchemaTypes } from 'models/ValidationSchemas';

type AshesDeliveryModalProps = {
  show: boolean;
  ashesDeliveryInfo: AshesInfoResponseDto | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAccept: (data: any) => void;
  onCancel: () => void
};

enum AshesDeliverySteps {
  AshesDeliveryStepOne = 'deliveryStep1',
  AshesDeliveryStepTwo= 'deliveryStep2'
}

export enum DeliveryDestinations {
  FAMILY = 'F'
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AshesDeliveryModal = ({
  show, ashesDeliveryInfo, onAccept, onCancel,
}: AshesDeliveryModalProps) => {
  const { t } = useTranslation();
  const { users, getUser } = useUsers([]);
  const { user } = useProvidedAuth();
  const { ashesDestinations, getAshesDestination } = useAshesDestinations();

  const [
    ashesDeliveryStep,
    setashesDeliveryStep,
  ] = useState<AshesDeliverySteps>(AshesDeliverySteps.AshesDeliveryStepOne);
  const [ashesDelivery, setAshesDelivery] = useState<UpdateAshesDeliveryRequest>({
    comment: undefined,
    date: undefined,
    deliveryHandler: undefined,
    destination: undefined,
    receiverCif: undefined,
    receiverIdentifier: undefined,
    receiverNie: undefined,
    receiverPassport: undefined,
  });

  useEffect(() => {
    if (!show) {
      setashesDeliveryStep(AshesDeliverySteps.AshesDeliveryStepOne);
    }
  }, [show]);

  useEffect(() => {
    setAshesDelivery(
      {
        comment: ashesDeliveryInfo?.comment,
        date: ashesDeliveryInfo?.date
          ? formatDateForSearch(ashesDeliveryInfo?.date)
          : new Date(),
        deliveryHandler: ashesDeliveryInfo?.deliveryHandler ? ashesDeliveryInfo?.deliveryHandler : `${user?.id}`,
        destination: ashesDeliveryInfo?.destination
          ? ashesDeliveryInfo?.destination
          : DeliveryDestinations.FAMILY,
        receiverCif: ashesDeliveryInfo?.receiverCif,
        receiverIdentifier: ashesDeliveryInfo?.receiverIdentifier,
        receiverName: ashesDeliveryInfo?.receiverName,
        receiverNie: ashesDeliveryInfo?.receiverNie,
        receiverPassport: ashesDeliveryInfo?.receiverPassport,
      },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ashesDeliveryInfo]);

  return (
    <Modal
      className="ashes-delivery-modal"
      show={show}
      showCancel={false}
      title={t('record.ashesDelivery')}
      onHide={onCancel}
    >
      <Formik
        initialValues={ashesDelivery}
        validate={(values) => config.validator(values, SchemaTypes.ASHES_DELIVERY)}
        validateOnBlur
        validateOnChange
        onSubmit={onAccept}
      >
        {({
          setFieldValue, values, handleBlur, errors, isValid,
        }: FormikProps<UpdateAshesDeliveryRequest>) => {
          const onChangeIdentifier = (identifierType: string, identifierValue: string) => {
            setFieldValue('receiverIdentifier', undefined);
            setFieldValue('receiverCif', undefined);
            setFieldValue('receiverPassport', undefined);
            setFieldValue('receiverNie', undefined);

            if (identifierType === IdentifierTypes.CIF) {
              setFieldValue('receiverCif', identifierValue || undefined);
            } else if (identifierType === IdentifierTypes.NIE) {
              setFieldValue('receiverNie', identifierValue || undefined);
            } else if (identifierType === IdentifierTypes.PASSPORT) {
              setFieldValue('receiverPassport', identifierValue || undefined);
            } else if (identifierType === IdentifierTypes.IDENTIFIER) {
              setFieldValue('receiverIdentifier', identifierValue || undefined);
            }
          };

          return (
            <Form>
              <legend>{t('service.cremation.ashes')}</legend>
              {ashesDeliveryStep === AshesDeliverySteps.AshesDeliveryStepOne && (
              <React.Fragment>
                <Select<IMasterDataDto>
                  key="ashesDestiny"
                  error={getIn(errors, 'destination')}
                  getLabel={({ description }) => description}
                  getValue={({ code }) => code}
                  name="ashesDestiny"
                  options={ashesDestinations}
                  placeholder={t('service.cremation.ashesDestiny')}
                  value={getAshesDestination(values.destination)}
                  clearable
                  searchable
                  onBlur={handleBlur}
                  onChange={(val) => {
                    setFieldValue('destination', val?.code);
                  }}
                />
                <Datepicker
                  key="ashesDeliveryDate"
                  error={getIn(errors, 'date')}
                  name="ashesDeliveryDate"
                  placeholder={t('service.cremation.ashesDeliveryDate')}
                  selected={new Date(values?.date || '')}
                  clearable
                  onBlur={handleBlur}
                  onChange={(val: Date) => {
                    setFieldValue('date', formatDateForSearch(val));
                  }}
                />
                <Select<SimpleUser>
                  key="ashesDeliveryHandler"
                  error={getIn(errors, 'deliveryHandler')}
                  getLabel={(option) => `${option.name} ${option.firstSurname} ${
                    option.secondSurname || ''
                  }`}
                  getValue={(option) => option.id}
                  name="ashesDeliveryHandler"
                  options={users}
                  placeholder={t('service.cremation.ashesDeliveryHandler')}
                  value={
                    getUser(values.deliveryHandler)
                  }
                  clearable
                  onBlur={handleBlur}
                  onChange={(val) => {
                    setFieldValue('deliveryHandler', `${val?.id}`);
                  }}
                />
                <Input
                  key="ashesReceiver"
                  error={getIn(errors, 'receiverName')}
                  id="ashesReceiver"
                  name="ashesReceiver"
                  placeholder={t('service.cremation.ashesReceiver')}
                  type="text"
                  value={values.receiverName}
                  clearable
                  onBlur={handleBlur}
                  onChange={(val) => {
                    setFieldValue('receiverName', val ? `${val}` : undefined);
                  }}
                />
                <IdentifierSelector
                  errors={{
                    cif: getIn(errors, 'receiverCif'),
                    identifier: getIn(errors, 'receiverIdentifier'),
                    nie: getIn(errors, 'receiverNie'),
                    passport: getIn(errors, 'receiverPassport'),
                  }}
                  name="ashesReceiverIdentifier"
                  placeholder={t('service.cremation.ashesReceiverIdentifier')}
                  type="text"
                  values={{
                    cif: values?.receiverCif,
                    identifier: values?.receiverIdentifier,
                    nie: values?.receiverNie,
                    passport: values?.receiverPassport,
                  }}
                  onBlur={handleBlur}
                  onChange={onChangeIdentifier}
                />
                <Textarea
                  key="comment"
                  error={getIn(errors, 'comment')}
                  id="comment"
                  name="comment"
                  placeholder={t('common.observation')}
                  value={values.comment}
                  onBlur={handleBlur}
                  onChange={(val) => {
                    setFieldValue('comment', val ? `${val}` : undefined);
                  }}
                />
              </React.Fragment>
              )}
              {ashesDeliveryStep === AshesDeliverySteps.AshesDeliveryStepTwo
                && (
                <InputQRScan
                  data={values.qr || ''}
                  placeholder={t('order.qr.urnQr')}
                  setData={(val) => {
                    setFieldValue('qr', val ? `${val}` : undefined);
                  }}
                />
                )}
              {ashesDeliveryStep === AshesDeliverySteps.AshesDeliveryStepOne
                && (
                <Button
                  disabled={!!errors.destination
                    || !!errors.date
                    || !!errors.deliveryHandler
                    || !!errors.receiverName
                    || !!errors.receiverCif
                    || !!errors.receiverIdentifier
                    || !!errors.receiverNie
                    || !!errors.receiverPassport
                    || !!errors.comment}
                  id="next"
                  text={t('common.next')}
                  onClick={() => setashesDeliveryStep(AshesDeliverySteps.AshesDeliveryStepTwo)}
                />
                )}
              {ashesDeliveryStep === AshesDeliverySteps.AshesDeliveryStepTwo
                && (
                <Button
                  disabled={!isValid}
                  id="accept"
                  text={t('common.accept')}
                  type="submit"
                />
                )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
