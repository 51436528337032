import { BudgetQuestionCodes } from '../../data/budgetQuestionCodes';
import { IAudit } from '../Audit/types';

export interface IArticle extends IAudit {
  code: string;
  description: string;
  questionCode?: BudgetQuestionCodes;
  subType?: ArticleCollectionTypes;
  image?: string;
  prices?: Array<IArticlePrice>;
  price?: number | null;
  vat?: number | null;
  type?: ArticleType | null;
  amount?: number;
  provider?: string;
  client?: string;
  fixedPrice?: boolean;
}

export interface IArticlePrice {
  price: number;
  vat: number;
}

export enum ArticleCollectionTypes {
  ARTICLE = 'ARTICLE',
  CONCESSION = 'CONCESSION',
  TAX = 'TAX',
  TRANSFER = 'TRANSFER'
}

export enum ArticleType {
  AUTOMATIC = 'AUTOMATIC',
  CATALOG = 'CATALOG'
}

export enum ClientType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}
