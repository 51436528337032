import { FormikProps, getIn } from 'formik';
import React, { Fragment, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Input from 'components/base/Input';

import { RecordTypeEnum, SaveRecord } from 'models/Record';
import { RootState } from 'store';

type AmputationFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

const AmputationFields: FC<AmputationFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const { t } = useTranslation();
  const { normalized } = useSelector((state: RootState) => state);
  const isAmputation = values.type
    && normalized.recordTypes[values.type].code === RecordTypeEnum.AMPUTATION;

  return (
    <Fragment>
      <fieldset>
        {createMode && <legend>{t('record.steps.amputation')}</legend>}
        <Input
          disabled={disabled || isSubmitting || !isAmputation}
          error={
            getIn(errors, 'amputationData.member')
            || getIn(errors, 'amputationData')
          }
          name="amputationData.member"
          placeholder={t('record.amputation.member')}
          type="text"
          value={values.amputationData?.member || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('amputationData.member', val)}
        />
      </fieldset>
    </Fragment>
  );
};

export default AmputationFields;
