import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

interface AffectedQuestionsInfoModalComponent {
  show: boolean;
  affectedQuestions: BudgetQuestionCodes[];
  onAccept: () => void
}
export const AffectedQuestionsInfoModal: FC<AffectedQuestionsInfoModalComponent> = (
  { show, affectedQuestions, onAccept },
) => {
  const { t } = useTranslation();

  return (
    <Modal
      buttons={[{
        onClick: () => {
          onAccept();
        },
        text: t('common.accept'),
      },
      ]}
      className="appointment-modal"
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.wizard.changeAffectedQuestions')}
      onHide={() => false}
    >
      <ul>
        {affectedQuestions.map((questionCode) => (
          <li key={questionCode}>
            <span>{t(`budget.wizard.${questionCode}`)}</span>
          </li>
        ))}
      </ul>
    </Modal>
  );
};
