import { FormikProps } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Assign, ObjectShape } from 'yup/lib/object';

import Input from 'components/base/Input';

import { i18n } from 'i18n';

export const changePasswordSchema = (
  forgot: boolean,
): Yup.ObjectSchema<Assign<ObjectShape, ObjectShape>> => Yup.object().shape(
  forgot
    ? {
      newPassword: Yup.string()
        .min(4, `${i18n.t('error.minLength')} 4`)
        .required(i18n.t('error.required')),
      repeatNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required(i18n.t('error.required')),
    }
    : {
      currentPassword: Yup.string().required(i18n.t('error.required')),
      newPassword: Yup.string()
        .min(4, `${i18n.t('error.minLength')} 4`)
        .required(i18n.t('error.required')),
      repeatNewPassword: Yup.string()
        .oneOf(
          [Yup.ref('newPassword'), null],
          i18n.t('error.passwordMatch'),
        )
        .required(i18n.t('error.required')),
    },
);

export type ForgotPasswordType = {
  newPassword: string;
  repeatNewPassword: string;
};

export type ChangePasswordType = ForgotPasswordType & {
  currentPassword: string;
};

export const getForgotPasswordFields = <T extends ForgotPasswordType>(
  {
    errors,
    handleBlur,
    isSubmitting,
    setFieldValue,
    touched,
    values,
  }: FormikProps<T>,
  disabled?: boolean,
): JSX.Element => (
  <Fragment>
    <Input
      autoComplete="off"
      data-testid="newPassword"
      disabled={disabled || isSubmitting}
      error={
        touched.newPassword && errors.newPassword
          ? `${errors.newPassword}`
          : undefined
      }
      name="newPassword"
      placeholder={i18n.t('user.newPassword')}
      type="password"
      value={values.newPassword || ''}
      onBlur={handleBlur}
      onChange={(val) => setFieldValue('newPassword', val)}
    />
    <Input
      autoComplete="off"
      data-testid="repeatNewPassword"
      disabled={disabled || isSubmitting}
      error={
        touched.repeatNewPassword && errors.repeatNewPassword
          ? `${errors.repeatNewPassword}`
          : undefined
      }
      name="repeatNewPassword"
      placeholder={i18n.t('user.repeatNewPassword')}
      type="password"
      value={values.repeatNewPassword || ''}
      onBlur={handleBlur}
      onChange={(val) => setFieldValue('repeatNewPassword', val)}
    />
  </Fragment>
  );

export const getChangePasswordFields = (
  props: FormikProps<ChangePasswordType>,
  disabled?: boolean,
): JSX.Element => (
  <Fragment>
    <Input
      autoComplete="off"
      data-testid="currentPassword"
      disabled={disabled || props.isSubmitting}
      error={props.touched.currentPassword && props.errors.currentPassword}
      name="currentPassword"
      placeholder={i18n.t('user.currentPassword')}
      type="password"
      value={props.values.currentPassword || ''}
      onBlur={props.handleBlur}
      onChange={(val) => props.setFieldValue('currentPassword', val)}
    />
    {getForgotPasswordFields<ChangePasswordType>(props, disabled)}
  </Fragment>
);

export const initialValuesForgot = {
  newPassword: '',
  repeatNewPassword: '',
};

export const initialValuesChange = {
  currentPassword: '',
  ...initialValuesForgot,
};
