import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { normalizeEntity } from 'utils/normalizer';

import Select from 'components/base/Select';
import { OptionsType } from 'components/base/SelectWithInput';

import './index.scss';
import { LanguageSelectorProps } from './types';

const LanguageSelector: FC<LanguageSelectorProps> = ({
  className,
  clearable,
  disabled,
  error,
  limit,
  name,
  onBlur,
  onChange,
  openUp,
  options,
  placeholder,
  searchable,
  value,
}) => {
  const [selectedValue, setSelectedValue] = useState<OptionsType>();

  const normalizedLanguages = useMemo(
    () => normalizeEntity<OptionsType>(options, 'value'),
    [options],
  );

  useEffect(() => {
    setSelectedValue(value ? normalizedLanguages[value] : undefined);
  }, [normalizedLanguages, value]);

  return (
    <Select<OptionsType>
      className={className}
      clearable={clearable}
      disabled={disabled}
      error={error}
      getLabel={(elem) => elem.label}
      getValue={(elem) => elem.value}
      limit={limit}
      name={name}
      openUp={openUp}
      options={options}
      placeholder={placeholder}
      searchable={searchable}
      value={selectedValue}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default LanguageSelector;
