import {
  createStore, applyMiddleware, combineReducers, Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';

import masterDataReducer from 'reducers/masterData';
import normalizedReducer from 'reducers/normalized';
import rolesReducer from 'reducers/roles';

import { budgetReducer } from 'modules/budget/store/budgetReducer';
import { FetchInvoiceArticles } from 'modules/budget/store/FetchInvoiceArticles';
import { FetchQ10Simulation } from 'modules/budget/store/FetchQ10Simulation';
import { LoadBudget } from 'modules/budget/store/LoadBudget';
import { PersistArticleClient } from 'modules/budget/store/PersistArticleClient';
import { PersistArticleClientBulk } from 'modules/budget/store/PersistArticleClientBulk';
import { PersistArticleProvider } from 'modules/budget/store/PersistArticleProvider';
import { PersistBudgetWhenDiscardedArticlesChange } from 'modules/budget/store/PersistBudgetWhenDiscardedArticlesChange';
import { PersistWhenSetArticleAmount } from 'modules/budget/store/PersistWhenSetArticleAmount';
import { PersistWhenSetArticlePrice } from 'modules/budget/store/PersistWhenSetArticlePrice';
import { RefreshBudgetWhenAnswerQuestion } from 'modules/budget/store/RefreshBudgetWhenAnswerQuestion';

import { FetchSummarize } from './modules/budget/store/FetchSummarize';
import { SaveBudget } from './modules/budget/store/SaveBudget';

const blacklist = [
  'cities',
  'deathTypes',
  'provinces',
  'centers',
  'groupCauses',
];

const persistMasterData = {
  key: 'masterData',
  storage,
  blacklist,
};

const persistNormalized = {
  key: 'normalized',
  storage,
  blacklist,
};

const rootReducer = combineReducers({
  masterData: persistReducer(persistMasterData, masterDataReducer),
  normalized: persistReducer(persistNormalized, normalizedReducer),
  roles: rolesReducer,
  budget: budgetReducer,
});

const middleware = [ReduxThunk];

const store: Store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      ...middleware,
      SaveBudget,
      FetchSummarize,
      RefreshBudgetWhenAnswerQuestion,
      PersistBudgetWhenDiscardedArticlesChange,
      PersistWhenSetArticleAmount,
      PersistWhenSetArticlePrice,
      LoadBudget,
      FetchInvoiceArticles,
      PersistArticleClient,
      PersistArticleProvider,
      PersistArticleClientBulk,
      FetchQ10Simulation,
    ),
  ),
);

export const persistor: Persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export default store;
