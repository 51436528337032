import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Address } from 'modules/budget/models/Address';
import { ILocation } from 'modules/budget/models/Location/types';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q39Form, Q39Response } from './form';

export const Q39: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q39;
  const { RespondQuestion } = useBudget();

  const [canRespond, setCanRespond] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

  useEffect(
    () => {
      const isAddressFullfilled = selectedAddress?.isFullFilled() || false;
      setCanRespond(isAddressFullfilled && Boolean(selectedLocation));
    },
    [selectedAddress, selectedLocation],
  );

  const response = useCallback(
    ({ gotoSummary }) => {
      if (selectedAddress && selectedLocation) {
        RespondQuestion({
          gotoSummary,
          question,
          response: {
            address: selectedAddress,
            location: selectedLocation,
          },
        });
      }
    },
    [RespondQuestion, question, selectedAddress, selectedLocation],
  );
  const onChange = useCallback((responseData: Q39Response) => {
    setSelectedAddress(responseData.address);
    setSelectedLocation(responseData.location);
  }, []);
  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q39Form
        question={BudgetQuestionCodes.Q39}
        onChange={onChange}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{
              address: selectedAddress,
              location: selectedLocation,
            }}
          />
          <QConfirmButton
            disabled={!canRespond}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
