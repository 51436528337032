import classNames from 'classnames';
import { isDate } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDateAndHour } from 'utils/dateManager';
import { isBooleanAndFalse } from 'utils/helpers';

import Loader from 'components/base/Loader';
import { displayNoValue } from 'components/base/NoData';

import { useCountries } from 'hooks/useCountries';
import { useProvinces } from 'hooks/useProvinces';
import Address from 'models/Address';
import {
  DeceasedLocationType,
  FinalDestinationType,
  CollectionType,
  PreparationType,
  OutfitType,
  SanitaryPracticeType,
  LocationType,
  TargetType,
  TransportType,
} from 'models/OrderInfo';
import { SimpleUser } from 'models/User';
import { RootState } from 'store';

type DetailValue =
  | string
  | string[]
  | number
  | boolean
  | Date
  | Record<string, unknown>
  | Address
  | null
  | undefined;

export type DetailType = {
  text: string;
  value: DetailValue;
  showDosPuntos?:boolean;
};

type InfoProps = {
  assignedUsers?: SimpleUser[];
  details: DetailType[];
  expanded?: boolean;
  loading?: boolean;
  priority?: string;
  testid?: string;
};

const enums: string[] = [
  ...Object.values(DeceasedLocationType),
  ...Object.values(FinalDestinationType),
  ...Object.values(CollectionType),
  ...Object.values(PreparationType),
  ...Object.values(OutfitType),
  ...Object.values(SanitaryPracticeType),
  ...Object.values(LocationType),
  ...Object.values(TargetType),
  ...Object.values(TransportType),
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAddress = (value: any): boolean => value
  && typeof value === 'object'
  && ((value as Address)?.city
    || (value as Address)?.province
    || (value as Address)?.country);

const Info: FC<InfoProps> = ({
  assignedUsers,
  details,
  expanded,
  loading,
  priority,
  testid,
}) => {
  const [noDetails, setNoDetails] = useState<boolean | undefined>(undefined);
  const { t } = useTranslation();
  const { countries, cities } = useSelector(
    (state: RootState) => state.normalized,
  );
  useCountries();
  const { getProvinceByNormalizedCode } = useProvinces();

  const formatAddress = (address: Address): string => {
    let res = '';

    if (address.streetName) {
      res = `${address.streetName}`;
    }
    if (address.postalCode) {
      res = `${res ? `${res}, ` : ''}${address.postalCode}`;
    }
    if (address.city && address.province && address.country) {
      res = `${res ? `${res}, ` : ''}${
        cities[`${address.country}${address.province}${address.city}`]
          ?.description || ''
      }, ${
        getProvinceByNormalizedCode(address.province, address.country)?.description || ''
      }`;
    } else if (address.city && address.province && address.country) {
      res = `${res ? `${res}, ` : ''}${
        getProvinceByNormalizedCode(address.province, address.country)?.description || ''
      }`;
    }
    if (address.country) {
      res = `${res ? `${res}, ` : ''}${
        countries[address.country]?.description || ''
      }`;
    }

    return res;
  };

  const formatValue = (value: DetailValue) => {
    if (typeof value === 'boolean') {
      return value ? t('common.yes') : t('common.no');
    }
    if (Array.isArray(value)) {
      const arrayValues = value.map((val: string) => {
        if (enums.includes(val)) {
          return t(`order.enums.info.${val}`);
        }
        return val;
      });
      return arrayValues.toString();
    }
    if (isAddress(value)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return formatAddress(value as any);
    }
    if (value && typeof value === 'object') {
      return JSON.stringify(value);
    }
    if (value && isDate(value)) {
      return formatDateAndHour(value);
    }
    if (typeof value === 'string' && enums.includes(value)) {
      return t(`order.enums.info.${value}`);
    }

    return value || displayNoValue;
  };

  useEffect(() => {
    if (!loading) {
      const hasDetails = details.some(({ value }) => !!value);
      setNoDetails(!hasDetails);
    }
  }, [details, loading]);

  return (
    <div
      className={classNames('work-order-details', priority?.toLowerCase(), {
        'hide-details': !expanded,
        'not-assigned': !assignedUsers?.length,
      })}
      data-testid={testid}
    >
      {!loading && !noDetails && (
        <table className="table-sm">
          <tbody>
            {details.map(({ text, value, showDosPuntos }) => (
              <tr key={text}>
                <th className="col-4">
                  {text}
                  {isBooleanAndFalse(showDosPuntos) ? '' : ':'}
                </th>
                <td className="col-8">{formatValue(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!loading && noDetails && (
        <div className="no-details">{t('order.details.noDetails')}</div>
      )}
      {loading && <Loader fullscreen={false} />}
    </div>
  );
};

export default Info;
