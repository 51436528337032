import React, {
  useRef,
  FC,
  Fragment,
  useEffect,
  useState,
  MutableRefObject,
} from 'react';
import { useTranslation } from 'react-i18next';

import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useWindowSize from 'hooks/useWindowSize';
import { DashboardEnquiry } from 'models/Enquiry';

// eslint-disable-next-line import/no-cycle
import { ListStatusCode } from '..';
import EnquiryCard from '../enquiryCard';

type WorkOrderGroupProps = {
  code: ListStatusCode;
  id: number;
  initialRequest: () => void;
  list: DashboardEnquiry[];
};

const EnquiriesGroup: FC<WorkOrderGroupProps> = ({
  code,
  list,
  id,
  initialRequest,
}) => {
  const [filteredList, setFilteredList] = useState<DashboardEnquiry[]>([]);
  const { t } = useTranslation();
  const { lgOrSmaller, height } = useWindowSize();
  const { user } = useProvidedAuth();
  const cardRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (!user && cardRef?.current?.clientHeight) {
      const maxCards = height
        ? Math.floor((height - 192) / cardRef.current.clientHeight) - 1
        : 0;
      list.splice(maxCards);
    }
    setFilteredList(list);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, height, cardRef?.current?.clientHeight]);

  return (
    <div key={id}>
      {!lgOrSmaller && <h4 aria-label="enquiry group title">{t(`enquiry.dashboard.${code}`)}</h4>}
      <div>
        {filteredList?.length > 0 ? (
          <Fragment>
            {filteredList.map((enquiry, idx) => (
              <EnquiryCard
                key={enquiry.id}
                callBack={initialRequest}
                cardRef={!idx ? cardRef : undefined}
                enquiry={enquiry}
              />
            ))}
            {filteredList.length < list.length && (
              <div className="more-card">
                <div className="card-number">
                  {list.length - filteredList.length}
                </div>
                <div>{t('order.andOther')}</div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="no-elements">
            <CustomIcon icon={OtherTypes.NO_ELEMENTS} />
            <span>{t('enquiry.dashboard.noEnquiries')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnquiriesGroup;
