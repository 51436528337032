import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import { useCities } from 'hooks/useCities';
import { TransferBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, displayDate, displayHour, WorkshopCardHeaderDetail,
} from './CardDetails';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TransferDetails = ({ order }: { order: TransferBaseWorkOrder }) => {
  const { t } = useTranslation();
  const { cities, getCityByNormalizedCode } = useCities();

  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);

  useEffect(() => {
    setDetails([{
      label: t('common.dateOut'),
      value: displayDate(order.exitDate),
    },
    {
      label: t('common.hourOut'),
      value: displayHour(order.exitTime),
    },
    {
      label: t('common.location'),
      value: getCityByNormalizedCode(
        order.country,
        order.province,
        order.city,
      )?.description || displayNoValue,
    },
    ]);
  }, [
    order,
    t,
    cities, getCityByNormalizedCode,
  ]);

  return (<CardDetails details={details} />);
};
