import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  object,
  number,
  SchemaOf,
} from 'yup';

interface ArticlePriceValidationHook {
  validate: () => SchemaOf<{
    price?: number;
  }>;
}

export const useArticlePriceValidation = (): ArticlePriceValidationHook => {
  const { t } = useTranslation();
  const validate = useCallback(
    () => object().shape({
      price: number()
        .transform((_, value) => {
          if (value.includes('.')) return null;
          return +value.replace(/,/, '.');
        })
        .min(0, t('validationRules.number.min', { min: 0 }))
        .typeError(t('validationRules.typeError'))
        .required(t('validationRules.required')),
    }),
    [t],
  );

  return {
    validate,
  };
};
