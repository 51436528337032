import {
  Formik, Form, FormikProps, FormikHelpers,
} from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';

import config from 'config';

import logo from 'assets/images/logo_funus.svg';

import { showSuccessToast } from 'utils/toasts';

import Button from 'components/base/Button';
import Loader from 'components/base/Loader';
import Message from 'components/base/Message';

import {
  ForgotPasswordType,
  getForgotPasswordFields,
  initialValuesForgot,
} from 'components/user/ChangePasswordForm';

import './index.scss';

type ParamsType = {
  token: string;
};

const NewPasswordPage: FC = () => {
  const [error, setError] = useState<string | undefined>();
  const [fullToken, setFullToken] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const { token } = useParams<ParamsType>();

  const onSubmit = (
    values: ForgotPasswordType,
    actions: FormikHelpers<ForgotPasswordType>,
  ) => {
    if (fullToken) {
      config.storage.setToken(fullToken);
    }
    config.apiFunus.login
      .restorePassword(values)
      .then((res) => {
        showSuccessToast(t('user.changePasswordOk'));
        config.storage.removeToken();
        setRedirect(true);
        if (res?.data) {
          return res.data;
        }
        return res;
      })
      .catch(() => {
        config.storage.removeToken();
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      config.apiFunus.login
        .verifyToken(token)
        .then((res) => {
          setFullToken(res.data.token);
          setLoading(false);
          if (res?.data) {
            return res.data;
          }
          return res;
        })
        .catch(() => {
          setLoading(false);
          setError(t('error.invalidToken'));
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (redirect) {
    return <Redirect to={config.url.landing} />;
  }

  return (
    <div id="new-password-page">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <img alt="Funus" src={logo} />
          {error ? (
            <div className="form-error">
              <Message type="error">
                {error}
                <Button
                  className="forgot-button"
                  text={t('common.go')}
                  to={config.url.forgot}
                />
              </Message>
            </div>
          ) : (
            <Formik initialValues={initialValuesForgot} onSubmit={onSubmit}>
              {(props: FormikProps<ForgotPasswordType>) => (
                <Form autoComplete="off">
                  {props.isSubmitting && <Loader />}
                  <h2>{t('user.newPassword')}</h2>
                  {getForgotPasswordFields(props)}
                  <Button
                    color="special"
                    disabled={props.isSubmitting || !!error || !fullToken}
                    text={t('user.changePassword')}
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
    </div>
  );
};

export default NewPasswordPage;
