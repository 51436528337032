import { useState } from 'react';

import { orderLineController } from 'config/apiFunus';
import {
  ArticleTrackingResponse,
  ArticleTrackingDto,
  ArticleTrackingFilter,
  Page,
  Sort,
} from 'config/apiFunus/generated/data-contracts';

import { GetArticleTrackingUsingPostHook } from './types';

export const useGetArticleTrackingUsingPost = (
  errorMessage: (message: string) => void,
): GetArticleTrackingUsingPostHook => {
  const [articles, setarticles] = useState<ArticleTrackingDto[]>([]);

  const fetchArticles = async (
    filter: ArticleTrackingFilter[] | undefined = [],
    page: Page | undefined = { index: 0, size: 10 },
    sort: Sort[] | undefined = [],
    showOnlyRecent = false,
  ): Promise<ArticleTrackingResponse> => orderLineController
    .getArticleTrackingUsingPost({
      filter, page, sort,
    }, {}, showOnlyRecent)
    .then((response: ArticleTrackingResponse) => {
      setarticles(response?.list || []);

      return response;
    })
    .catch((error) => {
      errorMessage(error.message);
      throw error;
    });

  return {
    articles,
    fetchArticles,
  };
};
