import React, { FC, useEffect, useState } from 'react';

import useWindowSize from 'hooks/useWindowSize';
import {
  PASSPORT_CAPTURE_RESOLUTION_HEIGHT,
  PASSPORT_CAPTURE_RESOLUTION_WIDTH,
  PASSPORT_SIZE_RATIO,
} from 'modules/ia/constants';

import { DocumentCamera } from '../document-camera';

import { PassportCameraComponent } from './types';

export const PassportCamera: FC<PassportCameraComponent> = (
  { onAccept, isFrontCameraActive, onIsFrontCameraUpdate },
) => {
  const [
    cameraFrameHeight,
    setCameraFrameHeight,
  ] = useState<number>(0);
  const [
    cameraFrameWidth,
    setCameraFrameWidth,
  ] = useState<number>(0);

  const { width: windowWidth, smOrSmaller } = useWindowSize();

  useEffect(() => {
    if (windowWidth) {
      const widthPercentage = smOrSmaller ? 80 : 60;
      const frameWidth = (windowWidth * widthPercentage) / 100;
      setCameraFrameWidth(frameWidth);
      setCameraFrameHeight(frameWidth / PASSPORT_SIZE_RATIO);
    }
  }, [smOrSmaller, windowWidth]);

  return (
    <DocumentCamera
      cameraFrameHeight={cameraFrameHeight}
      cameraFrameWidth={cameraFrameWidth}
      cameraResolutionHeight={PASSPORT_CAPTURE_RESOLUTION_HEIGHT}
      cameraResolutionWidth={PASSPORT_CAPTURE_RESOLUTION_WIDTH}
      isFrontCameraActive={isFrontCameraActive}
      onConfirmPhoto={(capture) => onAccept(capture)}
      onIsFrontCameraUpdate={onIsFrontCameraUpdate}
    />
  );
};
