import classNames from 'classnames';
import React, {
  Fragment, FC, useEffect, useMemo, useState,
} from 'react';
import {
  Package, Clipboard, PhoneIncoming, PlusCircle,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { formatDate } from 'utils/dateManager';
import {
  checkHaveAtLeastOnePermission,
  checkPermission,
} from 'utils/permissions';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import Table, { TableActionsProps } from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';
import TwoBoxesPage from 'components/base/TwoBoxesPage';

import DeliveryObjects from 'components/record/DeliveryObjects';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import Enquiry from 'models/Enquiry';
import Record from 'models/Record';

import './index.scss';

const ReceptionLanding: FC = () => {
  const [enquiries, setEnquiries] = useState<Enquiry[]>([]);
  const [enquiriesTotalPages, setEnquiriesTotalPages] = useState<number>(1);
  const [records, setRecords] = useState<Record[]>([]);
  const [recordTotalPages, setRecordTotalPages] = useState<number>(1);
  const [recordId, setRecordId] = useState<number>(0);
  const [loadingEnquiry, setLoadingEnquiry] = useState(true);
  const [loadingRecord, setLoadingRecord] = useState(true);
  const [showPersonalObjects, setShowPersonalObjects] = useState<boolean>(false);
  const { user } = useProvidedAuth();
  const { t } = useTranslation();

  const enquiriesCols = useMemo(
    (): TableColumn<Enquiry>[] => [
      { accessor: 'id', Header: 'Id', hidden: true },
      {
        accessor: 'createdDate',
        Cell: ({ cell: { value } }) => formatDate(value),
        Header: `${t('common.date')}`,
      },
      {
        accessor: 'clientName',
        Cell: ({
          row: {
            original: { clientName, firstSurname, secondSurname },
          },
        }) => classNames(clientName, firstSurname, secondSurname) || '',
        className: 'primary-dark-color',
        Header: `${t('common.name')}`,
      },
      {
        accessor: 'phone',
        Header: `${t('common.phone')}`,
      },
    ],
    [t],
  );
  const recordsCols = useMemo(
    (): TableColumn<Record>[] => [
      {
        accessor: 'erpId',
        className: 'primary-dark-color',
        Header: `${t('record.number')}`,
        width: '5%',
      },
      {
        accessor: 'death.deathDate',
        Cell: ({
          row: {
            original: { deathData },
          },
        }) => {
          if (deathData?.deathDate) {
            return formatDate(deathData.deathDate);
          }
          return '';
        },
        Header: `${t('record.deathData.deathDate')}`,
        width: '10%',
      },
      {
        accessor: 'deceased.name',
        Cell: ({
          row: {
            original: { deceasedData },
          },
        }) => (!!deceasedData
            && classNames(
              deceasedData.name,
              deceasedData.firstSurname,
              deceasedData.secondSurname,
            ))
          || '',
        Header: `${t('common.name')}`,
        width: '60%',
      },
      {
        accessor: 'negotiators',
        Cell: ({ cell: { value } }) => (
          <Fragment>
            <div>
              {value && value[0]
                ? `${value[0]?.firstSurname}, ${value[0]?.name}`
                : ''}
            </div>
            <div>
              {value && value[1]
                ? `${value[1]?.firstSurname}, ${value[1]?.name}`
                : ''}
            </div>
          </Fragment>
        ),
        Header: `${t('record.advisers')}`,
        width: '25%',
      },
    ],
    [t],
  );

  const openDeliveyObjets = (id: number) => {
    setRecordId(id);
    setShowPersonalObjects(true);
  };

  const getRecordActions = (row: Record): TableActionsProps => {
    let result: TableActionsProps = {};

    if (
      checkPermission(SimpleUserRequestPermissionNames.RECORD_WRITE, user?.role.permissions)
    ) {
      result = {
        deliveryObjects: {
          icon: <Package />,
          onClick: () => openDeliveyObjets(row.id as number),
          tooltipCaption: t('record.deliveryObjects'),
        },
        edit: {
          icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
          tooltipCaption: t('common.edit'),
          url: `${config.url.records}/${row.id}/edit`,
        },
        extra: [],
      };
    } else if (
      checkPermission(SimpleUserRequestPermissionNames.RECORD_READ, user?.role.permissions)
    ) {
      result = {
        see: {
          icon: <CustomIcon icon={FeatherIconTypes.EYE} />,
          tooltipCaption: t('common.see'),
          url: `${config.url.records}/${row.id}`,
        },
      };
    }

    return result;
  };

  const loadEnquiries = (current = 0, size = 10) => {
    const params = {
      filter: [],
      page: {
        index: current,
        size,
      },
    };
    setLoadingEnquiry(true);
    config.apiFunus.enquiries
      .setEnquiries(params)
      .then((res) => {
        setEnquiries(res.data.list);
        setEnquiriesTotalPages(res.data.numberOfPages);
        setLoadingEnquiry(false);
        return res;
      })
      .catch(() => {
        setLoadingEnquiry(false);
      });
  };

  const loadRecords = (current = 0, size = 10) => {
    const params = {
      filter: [],
      page: {
        index: current,
        size,
      },
    };
    setLoadingRecord(true);
    config.apiFunus.record
      .getRecords(params)
      .then((res) => {
        setRecords(res.data.list);
        setRecordTotalPages(res.data.numberOfPages);
        setLoadingRecord(false);
        return res;
      })
      .catch(() => {
        setLoadingRecord(false);
      });
  };

  useEffect(() => {
    loadEnquiries();
    loadRecords();
  }, []);

  return (
    <Fragment>
      <DeliveryObjects
        recordId={recordId}
        showPersonalObjects={showPersonalObjects}
        onHide={() => setShowPersonalObjects(false)}
      />
      <TwoBoxesPage
        className="reception-landing"
        leftBox={{
          className: 'records-box',
          content: checkPermission(
            SimpleUserRequestPermissionNames.RECORD_READ,
            user?.role.permissions,
          ) ? (
            <Table<Record>
              actions={getRecordActions}
              className="records-table"
              columns={recordsCols}
              data={records}
              fetchData={loadRecords}
              serverSide={{
                filter: false,
                pagination: true,
                sort: false,
              }}
              totalPages={recordTotalPages}
              pagination
            />
            ) : (
              <div />
            ),
          header: (
            <div>
              {checkHaveAtLeastOnePermission(
                [
                  SimpleUserRequestPermissionNames.RECORD_ADD,
                  SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
                ],
                user?.role.permissions,
              ) && (
                <Button
                  leftAddon={<PlusCircle />}
                  text={t('record.new')}
                  to={config.url.newRecord}
                />
              )}
            </div>
          ),
          icon: <Clipboard />,
          loading: loadingEnquiry || loadingRecord,
          title: t('record.last'),
        }}
        rightBox={{
          className: 'enquiries-box',
          content: checkPermission(
            SimpleUserRequestPermissionNames.ENQUIRY_READ,
            user?.role.permissions,
          ) ? (
            <Table<Enquiry>
              actions={(row: Enquiry) => ({
                edit: {
                  icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
                  tooltipCaption: t('common.edit'),
                  url: checkPermission(
                    SimpleUserRequestPermissionNames.ENQUIRY_WRITE,
                    user?.role.permissions,
                  )
                    ? `${config.url.enquiries}/${row.id}/edit`
                    : undefined,
                },
              })}
              className="enquiries-table"
              columns={enquiriesCols}
              data={enquiries}
              fetchData={loadEnquiries}
              loading={loadingEnquiry || loadingRecord}
              serverSide={{
                filter: false,
                pagination: true,
                sort: false,
              }}
              totalPages={enquiriesTotalPages}
              pagination
            />
            ) : (
              <div />
            ),
          header: (
            <div>
              {checkPermission(
                SimpleUserRequestPermissionNames.ENQUIRY_ADD,
                user?.role.permissions,
              ) && (
                <Button
                  leftAddon={<PlusCircle />}
                  text={t('enquiry.new')}
                  to={config.url.newEnquiry}
                />
              )}
            </div>
          ),
          icon: <PhoneIncoming />,
          loading: loadingEnquiry || loadingRecord,
          title: t('enquiry.last'),
        }}
      />
    </Fragment>
  );
};

export default ReceptionLanding;
