import { isNull } from 'utils/helpers';

import { ContentType, HttpClient, RequestParams } from 'config/apiFunus/generated/http-client';
import { IFilter } from 'config/apiFunus/types';
import { MasterData } from 'models/MasterDataDto';
import { BudgetsTrackingFilter } from 'modules/budget/components/tracking/table/types';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { IArticle } from 'modules/budget/models/Article/types';
import { IArticlesCollection } from 'modules/budget/models/ArticlesCollection/types';
import { IBudgetQuestion } from 'modules/budget/models/BudgetQuestion/types';
import { BudgetSumarize } from 'modules/budget/models/BudgetSumarize';
import { BudgetSumarizeArticle } from 'modules/budget/models/BudgetSumarizeArticle';
import { InvoiceArticle } from 'modules/budget/models/InvoiceArticle';
import { InvoiceArticleType } from 'modules/budget/models/InvoiceArticle/types';

import { BudgetHelper } from '../../helpers/BudgetHelper';
import { Article } from '../../models/Article';
import { ArticleCollection } from '../../models/ArticlesCollection';
import { Budget } from '../../models/Budget';
import { BudgetsTrackingItem } from '../BudgetsTrackingItem';
import {
  ApiArticle,
  ApiArticleQuestionResponse,
  ApiBudgetsTrackingResponse,
  ApiBudgetSumarizeResponse,
  BudgetsTrackingList,
} from '../types';

import { excludeInvalidClients } from './tools';
import { BudgetActions, ApiBudget, BudgetsArticlesResponse } from './types';

export class BudgetClient<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  SignBudget = async (
    id: number,
    signature: {
      sign?: string;
      signed?: boolean;
    },
    params: RequestParams = {},
  ): Promise<void> => {
    const base64withoutHeader = signature
      .sign?.substring(signature.sign.indexOf(',') + 1);
    return this.http
      .request<void, void>({
        body: signature.signed
          ? {
            signed: true,
          }
          : {
            image: base64withoutHeader,
          },
        method: 'PUT',
        path: `/budgets/${id}/${BudgetActions.SIGN}`,
        secure: true,
        type: ContentType.Json,
        ...params,
      });
  }

  DuplicateBudget = async (
    id: number,
    params: RequestParams = {},
  ): Promise<void> => this.http
    .request<void, void>({
      method: 'PUT',
      path: `/budgets/${id}/${BudgetActions.DUPLICATE}`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  InvoiceBudget = async (
    id: number,
    params: RequestParams = {},
  ): Promise<void> => this.http
    .request<void, void>({
      method: 'PUT',
      path: `/budgets/${id}/${BudgetActions.INVOICE}`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  AcceptBudget = async (
    id: number,
    params: RequestParams = {},
  ): Promise<void> => this.http.request<void, void>({
    method: 'PUT',
    path: `/budgets/${id}/${BudgetActions.ACCEPT}`,
    secure: true,
    type: ContentType.Json,
    ...params,
  })

  AssignRecord = async (
    id: number,
    erpId: number,
    params: RequestParams = {},
  ): Promise<void> => this.http.request<void, void>({
    body: {
      idRecord: erpId,
    },
    method: 'PUT',
    path: `/budgets/${id}/${BudgetActions.ASSIGN}`,
    secure: true,
    type: ContentType.Json,
    ...params,
  })

  GenerateDocumentUsingGet = async (
    id: number,
    params: RequestParams = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => this.http.request<ArrayBuffer, void>({
    format: 'arraybuffer',
    method: 'GET',
    path: `/budgets/${id}/document`,
    secure: true,
    type: ContentType.Pdf,
    ...params,
  });

  GetBudgetsTracking = async (
    filter: IFilter<BudgetsTrackingFilter>,
    params: RequestParams = {},
  ): Promise<BudgetsTrackingList> => this.http
    .request<ApiBudgetsTrackingResponse, void>({
      body: filter,
      method: 'POST',
      path: '/budgets/trackings',
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => ({
      list: response.list.map((trackingItem) => new BudgetsTrackingItem(trackingItem)),
      numberOfItems: response.numberOfItems,
      numberOfPages: response.numberOfPages,
    }))

  CancelBudget = async (
    id: number,
    params: RequestParams = {},
  ): Promise<void> => this.http.request<void, void>({
    method: 'PUT',
    path: `/budgets/${id}/${BudgetActions.CANCEL}`,
    secure: true,
    type: ContentType.Json,
    ...params,
  })

  BackwardBudget = async (
    id: number,
    params: RequestParams = {},
  ): Promise<void> => this.http.request<void, void>({
    method: 'PUT',
    path: `/budgets/${id}/${BudgetActions.BACKWARD}`,
    secure: true,
    type: ContentType.Json,
    ...params,
  })

  GetBudgetArticles = async (
    budget: Budget,
    params: RequestParams = {},
  ): Promise<IArticle[]> => this.http
    .request<{ articles: ApiArticle[] }, void>({
      body: {
        automaticArticles: budget.automaticArticles,
        discardedArticles: budget.discardedArticles,
        id: budget.id,
        idVersion: budget.idVersion,
        questions: Object.values(budget.questions)
          .filter((q) => !isNull(q)),
      },
      method: 'POST',
      path: '/budgets/articles',
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => response.articles
      .map((article: ApiArticle): IArticle => (new Article(article))))

  GetBudgetSummary = async (
    budgetId: number,
    params: RequestParams = {},
  ): Promise<BudgetSumarize> => this.http
    .request<ApiBudgetSumarizeResponse, void>({
      method: 'GET',
      path: `/budgets/${budgetId}/summarize`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => new BudgetSumarize({
      articles: response.articles.map((article) => new BudgetSumarizeArticle(article)),
      companyName: response.companyName,
      net: response.clientServices,
      total: response.totalCost,
      type: response.type,
      vat: response.totalVat,
    }))

  SendBudget = async (
    id: number,
    emails: Array<string>,
    params: RequestParams = {},
  ): Promise<void> => this.http
    .request<void, void>({
      body: {
        emails,
      },
      method: 'POST',
      path: `/budgets/${id}/email`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  GetArticlesByQuestionAndRestrict = async (
    question: BudgetQuestionCodes,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    budgetId: number,
    restrict: boolean,
    params: RequestParams = {},
  ): Promise<IArticlesCollection> => this.http
    .request<ApiArticleQuestionResponse, void>({
      method: 'GET',
      path: `/budgets/${budgetId}/articles/${question}?restrict=${restrict ? 'true' : 'false'}`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => new ArticleCollection(response.articles))

  SaveBudget = async (
    budget: Budget,
    params: RequestParams = {},
  ): Promise<Budget> => {
    const body: {
      automaticArticles: Article[];
      collected: boolean;
      createdBy?: string | null;
      createdDate?: string | null;
      discardedArticles: Article[];
      id?: number | null;
      idRecord: number | null;
      idVersion?: number | null;
      questions: Array<IBudgetQuestion>;
    } = {
      automaticArticles: budget.automaticArticles,
      collected: budget.collected,
      createdBy: budget.createdBy,
      createdDate: budget.createdDate,
      discardedArticles: budget.discardedArticles,
      id: budget.id,
      idRecord: budget.idRecord,
      idVersion: budget.idVersion,
      questions: BudgetHelper.mapQuestionCollectionToArray(budget.questions),
    };

    return this.http
      .request<ApiBudget, void>({
        body,
        method: 'POST',
        path: '/budgets',
        secure: true,
        type: ContentType.Json,
        ...params,
      })
      .then((response) => (new Budget({
        automaticArticles: response.automaticArticles,
        collected: response.collected,
        createdBy: response.createdBy,
        createdDate: response.createdDate,
        discardedArticles: response.discardedArticles,
        id: response.id,
        idRecord: response.idRecord,
        idVersion: response.idVersion,
        questions: BudgetHelper.mapQuestionsArrayToCollection(response.questions),
        status: response.status,
      })));
  }

  GetBudgetDataFromRecord = async (
    recordId: number,
    params: RequestParams = {},
  ): Promise<Budget> => this.http
    .request<ApiBudget, void>({
      method: 'GET',
      path: `/budgets/records/${recordId}`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => (new Budget({
      automaticArticles: response.automaticArticles || [],
      collected: response.collected,
      createdBy: response.createdBy,
      createdDate: response.createdDate,
      discardedArticles: response.discardedArticles || [],
      id: response.id,
      idRecord: response.idRecord,
      idVersion: response.idVersion,
      questions: BudgetHelper.mapQuestionsArrayToCollection(response.questions),
      status: response.status,
    })))

  GetBudget = async (
    id: string,
    params: RequestParams = {},
  ): Promise<Budget> => this.http
    .request<ApiBudget, void>({
      method: 'GET',
      path: `/budgets/${id}`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => (new Budget({
      automaticArticles: response.automaticArticles || [],
      collected: response.collected,
      createdBy: response.createdBy,
      createdDate: response.createdDate,
      discardedArticles: response.discardedArticles || [],
      id: response.id,
      idRecord: response.idRecord,
      idVersion: response.idVersion,
      questions: BudgetHelper.mapQuestionsArrayToCollection(response.questions),
      status: response.status,
    })))

  GetInvoiceArticles = async (
    idBudget: number,
    params: RequestParams = {},
  ): Promise<{
    articles: InvoiceArticle[];
    clients: MasterData[];
  }> => this.http
    .request<BudgetsArticlesResponse, void>({
      method: 'GET',
      path: `/budgets/${idBudget}/articles`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => ({
      articles: response.articles
        .map((article) => new InvoiceArticle({
          ...article,
          providers: article.providerList
            .map((provider) => new MasterData(provider)),
        })),
      clients: response.clientList
        .map((client) => new MasterData(client))
        .filter(excludeInvalidClients),
    }))

  SetInvoiceArticleProvider = async (
    idArticle: number,
    articleType: InvoiceArticleType,
    provider: string,
    params: RequestParams = {},
  ): Promise<unknown> => this.http
    .request<void, void>({
      body: {
        provider,
      },
      method: 'PUT',
      path: `/budgets/articles/${articleType}/${idArticle}`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then(() => false)

  SetInvoiceArticleClient = async (
    idArticle: number,
    articleType: InvoiceArticleType,
    client: string,
    params: RequestParams = {},
  ): Promise<unknown> => this.http
    .request<void, void>({
      body: {
        client,
      },
      method: 'PUT',
      path: `/budgets/articles/${articleType}/${idArticle}`,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then(() => false)

    SetBudgetArticlePriceAmount = async (
      idArticle: number | null | undefined,
      articleType: InvoiceArticleType | null | undefined,
      payload: {
        price?: number,
        amount?: number,
      },
      params: RequestParams = {},
    ): Promise<unknown> => {
      let bodyRequest = {};
      if (payload.price !== undefined) {
        bodyRequest = {
          ...bodyRequest,
          price: payload.price,
        };
      }
      if (payload.amount) {
        bodyRequest = {
          ...bodyRequest,
          amount: payload.amount,
        };
      }

      return this.http
        .request<void, void>({
          body: bodyRequest,
          method: 'PUT',
          path: `/budgets/articles/${articleType}/${idArticle}`,
          secure: true,
          type: ContentType.Json,
          ...params,
        })
        .then(() => false);
    }
}
