import { ApiFunusRoles } from './types';
import axios from 'config/axios';
import { permission } from './permission';
import { defaultFilter, modifiedResponse } from '.';

export const roles: ApiFunusRoles = {
  getPaginatedRole: async (params = defaultFilter) => axios.post('/roles', params),
  getRoles: async () => axios.get('/roles'),
  getRole: (id) => axios.get(`/role/${id}`),
  postRole: (params) => axios.post(`/role/`, params),
  editRole: ({ id, values }) => axios.put(`/role/${id}`, values),
  retrieveRoleAndPermissions: async (id) => {
    const roleData = await roles.getRole(id);
    const permissionData = await permission.getPermissionGroupListByRole(id);
    roleData.data.permissions = permissionData.data;
    return Promise.resolve({ ...modifiedResponse, data: roleData.data });
  },
  deleteRole: (idRole) => axios.delete(`/role/${idRole}`),
};
