import { FormikProps } from 'formik';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/base/Input';
import Select from 'components/base/Select';

import SelectRole from 'components/user/SelectRole';

import { useCenters } from 'hooks/useCenters';
import { i18n } from 'i18n';
import { IMasterDataDto } from 'models/MasterData';
import { UserFormType } from 'models/User';

import Upload from '../Upload';

type UserFieldsProps = {
  disabled?: boolean;
  formikProps: FormikProps<UserFormType>;
};

const UserFields: FC<UserFieldsProps> = ({
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  disabled,
}) => {
  const { t } = useTranslation();
  const { centers, getCenter } = useCenters();

  return (
    <Fragment>
      <Input
        disabled={disabled || isSubmitting}
        error={errors.name}
        id="name"
        maxLength={50}
        name="name"
        placeholder={i18n.t('common.name')}
        type="text"
        value={values.name || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('name', val)}
      />
      <Input
        disabled={disabled || isSubmitting}
        error={errors.firstSurname}
        maxLength={50}
        name="firstSurname"
        placeholder={i18n.t('common.firstSurname')}
        type="text"
        value={values.firstSurname || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('firstSurname', val)}
      />
      <Input
        disabled={disabled || isSubmitting}
        error={errors.secondSurname}
        maxLength={50}
        name="secondSurname"
        placeholder={i18n.t('common.secondSurname')}
        type="text"
        value={values.secondSurname || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('secondSurname', val)}
      />
      <Input
        className="lowercase"
        disabled={disabled || isSubmitting}
        error={errors.email}
        maxLength={50}
        name="email"
        placeholder={i18n.t('common.email')}
        type="text"
        value={values.email || ''}
        onBlur={handleBlur}
        onChange={(val: string) => {
          const [username] = val?.split('@');
          setFieldValue('username', username?.toLowerCase(), false);
          setFieldValue('email', val?.toLowerCase());
        }}
      />
      <Input
        className="lowercase"
        error={errors.username}
        name="username"
        placeholder={i18n.t('common.username')}
        type="text"
        value={values.username || ''}
        disabled
        readOnly
        onBlur={handleBlur}
      />
      <SelectRole
        disabled={disabled || isSubmitting}
        error={errors.idrole}
        name="role"
        setFieldValue={setFieldValue}
        values={values}
      />
      <Select<IMasterDataDto>
        disabled={disabled || isSubmitting}
        error={errors.workplace}
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="workplace"
        options={centers}
        placeholder={t('user.workplace')}
        value={getCenter(values.workplace)}
        searchable
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('workplace', val?.code)}
      />
      <Input
        checked={values.active}
        disabled={disabled || isSubmitting}
        error={errors.active}
        id="active"
        name="active"
        placeholder={t('common.active')}
        type="checkbox"
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('active', val)}
      />
      <Upload
        disabled={disabled || isSubmitting}
        name="image"
        placeholder={i18n.t('user.userImage')}
        value={values.image}
        onChange={(val) => setFieldValue('image', val)}
      />
    </Fragment>
  );
};

export default UserFields;
