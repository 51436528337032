import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCoffinSizes } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

import { CoffinsHook } from './types';

export const useCoffins = (): CoffinsHook => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { coffinSizes } = useSelector((state: RootState) => state.masterData);
  useEffect(() => {
    if (!coffinSizes) {
      dispatch(getCoffinSizes());
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [coffinSizes]);

  const coffinSizesWithDescription = useMemo(
    () => coffinSizes?.map((coffinSize: IMasterDataDto) => ({
      code: coffinSize.code,
      description: t(`record.coffinSizes.${coffinSize.code}`),
    })),
    [coffinSizes, t],
  );

  const getCoffinSize = useCallback(
    (code) => coffinSizesWithDescription?.find(
      (coffinSize: IMasterDataDto) => coffinSize.code === code,
    ),
    [coffinSizesWithDescription],
  );

  return {
    coffinSizes: coffinSizesWithDescription,
    getCoffinSize,
  };
};
