import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Article } from 'modules/budget/models/Article';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q19Form, Q19Response } from './form';

export const Q19: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q19;
  const { RespondQuestion } = useBudget();

  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [dismissService, setDismissService] = useState<boolean>(false);
  const [autorespond, setautorespond] = useState<boolean>(false);

  const canRespond = useMemo(
    () => dismissService || selectedArticles.length !== 0,
    [dismissService, selectedArticles],
  );

  const response = useCallback(
    ({ gotoSummary }) => {
      RespondQuestion({
        gotoSummary,
        question,
        response: {
          articles: selectedArticles,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedArticles],
  );

  const onChange = useCallback(
    (responseData: Q19Response) => {
      setDismissService(Boolean(responseData.dismissService));
      setSelectedArticles(responseData.articles);
      setautorespond(responseData.autorespond);
    },
    [],
  );

  useEffect(
    () => {
      if (autorespond && canRespond) {
        setautorespond(false);
        response({ gotoSummary: false });
      }
    },
    [autorespond, canRespond, response],
  );

  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q19Form
        question={question}
        onChange={onChange}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{
              articles: selectedArticles,
            }}
          />
          <QConfirmButton
            disabled={!canRespond}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
