import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { declarantNotificationsController } from 'config/apiFunus';

import { WhatsappHook } from './types';

export const useWhatsapp = (): WhatsappHook => {
  const { t } = useTranslation();
  const [languages, setlanguages] = useState<Array<string>>([]);

  const sendMessage = useCallback(
    ({
      number, language, recordId,
    }) => declarantNotificationsController
      .sendWhatsapp({
        language, number, recordId,
      })
      .then((response) => {
        showSuccessToast(t('record.whatsapp.sendSuccess'));
        return response;
      })
      .catch((e) => {
        showErrorToast(t(`error.${e.message}`));
        throw e;
      }),
    [t],
  );

  const loadLanguages = useCallback(() => {
    declarantNotificationsController.getNotificationLanguages()
      .then((response) => {
        setlanguages(response);
        return response;
      })
      .catch((e) => {
        setlanguages(['CA', 'ES', 'EN']);
        showErrorToast(e.message);
      });
  }, []);

  return {
    languages,
    loadLanguages,
    sendMessage,
  };
};
