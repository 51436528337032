/* eslint-env browser */

export const GCM_API_KEY = 'AIzaSyBBh4ddPa96rQQNxqiq_qQj7sq1JdsNQUQ';
export const APPLICATION_KEYS = {
  publicKey: 'BN2_V3z6MknFgCCZxMXKie2EPVs8qpdbcnEwYCrCR96NHOsP_tsMpsEVevNGpgrXxF9x7IKiBc861wZE4P0Udxc',
  privateKey: 'hFg89YR9Qdz-vhy_drjMqACtO_HwL9uw25IenEVtwM8',
};

// Hosting on vercel will have the API and frontend served from the
// same origin, so '' is fine.
// For local development the backend url param can be used.
const urlParams = new URLSearchParams(window.location.search);
const backendParam = urlParams.get('backend');
export const BACKEND_ORIGIN = backendParam || '';

export const BACKEND_API = process.env.REACT_APP_API_URL;
export const BACKEND_SUBSCRIBE = `${BACKEND_API}/subscribe`;
export const BACKEND_UNSUBSCRIBE = `${BACKEND_API}/unsubscribe`;
