import classNames from 'classnames';
import { FormikHelpers, FormikProps } from 'formik';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { PlusCircle } from 'react-feather';
import { SortingRule } from 'react-table';

import Box from 'components/base/Box';
import Button from 'components/base/Button';
import SideFilters from 'components/base/SideFilters';
import Table from 'components/base/Table';

import './index.scss';

import { IFilter } from 'config/apiFunus/types';
import Enquiry from 'models/Enquiry';

import { ExtraAction } from '../Table/ActionsColumn/types';
import { TableColumn } from '../Table/types';

type TextType = {
  search: string;
  title: string;
};

type CreateType = {
  title: string;
  url?: string | undefined;
  onClick?: () => void | undefined;
};

type ActionPropWithoutCallback = {
  onClick?(reload?: () => void): void;
  url?: string | undefined;
  icon?: ReactElement<unknown>;
  isExtra?: boolean;
  tooltipCaption?: string;
};

type ActionPropWithCallback = ActionPropWithoutCallback & {
  callback?: () => void;
};

type TableActionsPropsWraped = {
  acceptBudget?: ActionPropWithCallback;
  acceptFlowersRequest?: ActionPropWithCallback;
  appointment?: ActionPropWithCallback;
  ashesDelivery?: ActionPropWithCallback;
  assignEnquiry?: ActionPropWithCallback & {enquiry?: Enquiry};
  assignRecord?: ActionPropWithCallback;
  backwardBudget?: ActionPropWithCallback;
  budgetAssignRecord?: ActionPropWithCallback;
  canAddArticleDisagreement?: ActionPropWithCallback;
  cancelBudget?: ActionPropWithCallback;
  cancelFlowersRequest?: ActionPropWithCallback;
  canEditDraft?: ActionPropWithCallback;
  deleteFlowersRequest?: ActionPropWithCallback;
  duplicateBudget?: ActionPropWithCallback;
  edit?: ActionPropWithoutCallback;
  extra?: ExtraAction[];
  invoiceBudget?: ActionPropWithCallback;
  remove?: ActionPropWithoutCallback;
  see?: ActionPropWithoutCallback;
  showBudgetPdf?: ActionPropWithCallback;
  showFlowerPhoto?:ActionPropWithoutCallback;
  signBudget?: ActionPropWithCallback
};

export type FilteredPageProps<
  T extends Record<string, unknown>,
  K extends Record<string, unknown>
> = {
  actions(row: K, index: number): TableActionsPropsWraped | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiCall: (params: IFilter<T>) => Promise<any>;
  columns: TableColumn<K>[];
  className?: string | undefined;
  create?: CreateType | undefined;
  fields: (formikProps: FormikProps<T>) => ReactElement[];
  initialValues: T;
  setIsLoading?: (loading: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowClassName?: (index: number, row?: any) => string;
  showFilters?: boolean;
  text: TextType;
  onRefetchUpdate?: (callback: () => void) => void;
};

type SortProps = {
  descending: boolean;
  name: string;
};

type ApiProps<K> = {
  data: {
    list: K[];
    numberOfItems: number;
    numberOfPages: number;
  };
};

const FilteredPage = <
  T extends Record<string, unknown>,
  K extends Record<string, unknown>
>({
    actions,
    apiCall,
    className,
    columns,
    create,
    fields,
    initialValues,
    setIsLoading,
    showFilters = true,
    text,
    rowClassName,
    onRefetchUpdate,
  }: FilteredPageProps<T, K>): ReactElement => {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState<K[]>([]);
  const [filter, setFilter] = useState<T>(initialValues);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [sortRule, setSortRule] = useState<SortProps[]>([]);
  const [totalPages, setTotalPages] = useState(1);

  const loadData = async (
    filters: T,
    size = 10,
    index = 0,
    sort: SortProps[],
    callback?: () => void,
  ) => {
    setLoading(true);
    try {
      const res: ApiProps<K> = await apiCall({
        filter: [filters],
        page: { index, size },
        sort,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setData(res?.data?.list || res?.data || (res as any)?.list || []);
      setFilter(filters);
      setPageSize(size);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTotalPages(res?.data?.numberOfPages || (res as any).numberOfPages);
      setCurrentPage(index);
      setSortRule(sort);
      if (callback) {
        callback?.();
      }
      setLoading(false);
    } catch (err) {
      if (callback) {
        callback?.();
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(loading);
    }
  }, [setIsLoading, loading]);

  const onSearch = (filters: T, formikActions: FormikHelpers<T>) => {
    loadData(filters, pageSize, 0, sortRule, () => formikActions.setSubmitting(false));
  };

  const onFetch = (
    current: number,
    size: number,
    params?: SortingRule<K>[] | undefined,
  ) => {
    const newSort: SortProps[] = params?.length
      ? [{ descending: params[0].desc || false, name: params[0].id }]
      : [];
    loadData(filter, size, current, newSort);
  };

  /**
   * @description method to refetch data from server
   * on delete action, to rerender component correctly
   */
  const reFetch = useCallback(
    () => {
      loadData(filter, pageSize, currentPage, sortRule);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, filter, pageSize, sortRule],
  );

  useEffect(
    () => {
      onRefetchUpdate?.(reFetch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reFetch],
  );

  return (
    <div
      className={classNames('filtered-table-page', className, {
        'hidden-filters': !showFilters,
      })}
    >
      {showFilters && (
        <SideFilters<T>
          fields={fields}
          initialValues={initialValues}
          title={text.search}
          onSearch={onSearch}
        />
      )}
      <div className="table-content">
        <div className="table-header">
          <h3>{text.title}</h3>
          {create && (
            <Button
              leftAddon={<PlusCircle />}
              text={create.title}
              to={create.url}
              onClick={create.onClick}
            />
          )}
        </div>
        <Box>
          <Table<K>
            actions={(row, index) => {
              const allActions = actions ? actions(row, index) : {};
              return {
                ...allActions,
                acceptBudget: allActions?.acceptBudget
                  ? {
                    ...allActions.acceptBudget,
                    callback: !allActions?.acceptBudget?.callback
                      ? reFetch
                      : allActions?.acceptBudget?.callback,
                  }
                  : undefined,
                acceptFlowersRequest: allActions?.acceptFlowersRequest
                  ? {
                    ...allActions.acceptFlowersRequest,
                    callback: reFetch,
                  }
                  : undefined,
                appointment: allActions?.appointment
                  ? {
                    ...allActions?.appointment,
                    callback: reFetch,
                  }
                  : undefined,
                ashesDelivery: allActions?.ashesDelivery
                  ? { ...allActions?.ashesDelivery, callback: reFetch }
                  : undefined,
                assignEnquiry: allActions?.assignEnquiry
                  ? { ...allActions?.assignEnquiry, callback: reFetch }
                  : undefined,
                assignRecord: allActions?.assignRecord
                  ? {
                    ...allActions?.assignRecord,
                    callback: !allActions?.assignRecord?.callback
                      ? reFetch
                      : allActions?.assignRecord?.callback,
                  }
                  : undefined,
                backwardBudget: allActions?.backwardBudget
                  ? {
                    ...allActions.backwardBudget,
                    callback: !allActions?.backwardBudget?.callback
                      ? reFetch
                      : allActions?.backwardBudget?.callback,
                  }
                  : undefined,
                budgetAssignRecord: allActions?.budgetAssignRecord
                  ? {
                    ...allActions.budgetAssignRecord,
                    callback: !allActions?.budgetAssignRecord?.callback
                      ? reFetch
                      : allActions?.budgetAssignRecord?.callback,
                  }
                  : undefined,
                canAddArticleDisagreement: allActions?.canAddArticleDisagreement
                  ? {
                    ...allActions.canAddArticleDisagreement,
                    callback: reFetch,
                  }
                  : undefined,
                cancelBudget: allActions?.cancelBudget
                  ? {
                    ...allActions.cancelBudget,
                    callback: !allActions?.cancelBudget?.callback
                      ? reFetch
                      : allActions?.cancelBudget?.callback,
                  }
                  : undefined,
                cancelFlowersRequest: allActions?.cancelFlowersRequest
                  ? {
                    ...allActions.cancelFlowersRequest,
                    callback: reFetch,
                  }
                  : undefined,
                canEditDraft: allActions?.canEditDraft
                  ? {
                    ...allActions.canEditDraft,
                    callback: reFetch,
                  }
                  : undefined,
                deleteFlowersRequest: allActions?.deleteFlowersRequest
                  ? {
                    ...allActions.deleteFlowersRequest,
                    callback: reFetch,
                  }
                  : undefined,
                duplicateBudget: allActions?.duplicateBudget
                  ? {
                    ...allActions.duplicateBudget,
                    callback: !allActions?.duplicateBudget?.callback
                      ? reFetch
                      : allActions?.duplicateBudget?.callback,
                  }
                  : undefined,
                invoiceBudget: allActions?.invoiceBudget
                  ? {
                    ...allActions.invoiceBudget,
                    callback: !allActions?.invoiceBudget?.callback
                      ? reFetch
                      : allActions?.invoiceBudget?.callback,
                  }
                  : undefined,
                remove: allActions?.remove
                  ? { ...allActions?.remove, callback: reFetch }
                  : undefined,
                showBudgetPdf: allActions?.showBudgetPdf
                  ? {
                    ...allActions.showBudgetPdf,
                    callback: !allActions?.showBudgetPdf?.callback
                      ? reFetch
                      : allActions?.showBudgetPdf?.callback,
                  }
                  : undefined,
                showFlowerPhoto: allActions?.showFlowerPhoto
                  ? { ...allActions?.showFlowerPhoto, callback: reFetch }
                  : undefined,
                signBudget: allActions?.signBudget
                  ? {
                    ...allActions.signBudget,
                    callback: !allActions?.signBudget?.callback
                      ? reFetch
                      : allActions?.signBudget?.callback,
                  }
                  : undefined,
              };
            }}
            columns={columns}
            currentPage={currentPage}
            data={data}
            fetchData={onFetch}
            loading={loading}
            pageSize={pageSize}
            rowClassName={rowClassName}
            serverSide={{
              pagination: true,
              sort: true,
            }}
            totalPages={totalPages}
          />
        </Box>
      </div>
    </div>
  );
};

FilteredPage.defaultProps = {
  actions: undefined,
  className: undefined,
  enableAdd: true,
  validationSchema: undefined,
};

export default FilteredPage;
