import { AxiosError } from 'axios';

import { FunusCustomError } from 'config/apiErrors/FunusCustomError';

export class MaxFrontOrBackSidesExcededIAError extends FunusCustomError {
  constructor(error: AxiosError) {
    super(error);
    this.message = 'error.ia.maxFrontOrBackSidesExceded';
    this.name = 'RequiredPDFTypeIAError';
  }
}
