import classNames from 'classnames';
import React, {
  FC,
} from 'react';

import dismissServicePicture from 'assets/images/dismiss_service.svg';
import noPicture from 'assets/images/no_article_picture.svg';

import { Column } from 'components/base/Column';
import { RotatingLogo } from 'components/base/RotatingLogo';
import { Row } from 'components/base/Row';

import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';
import { Article } from 'modules/budget/models/Article';
import { dismissServiceId } from 'modules/budget/store/data';

import { GalleryImage } from '../GalleryImage';

import { ArticleGalleryComponent } from './types';

export const ArticleGallery: FC<ArticleGalleryComponent> = (
  {
    articles,
    onChange,
    allowSelectQuantity,
    multiselect,
    step,
    value,
    className,
    dismissServiceSelected,
    question,
  },
) => (
  <Column className={classNames('f-article-gallery', className)}>
    {articles.length === 0
      ? (<Row className="f-option-selector-no-itemes" justifyContent="space-around"><RotatingLogo /></Row>)
      : <React.Fragment />}
    <Row justifyContent="space-between">
      {articles.map((article) => {
        let isSelected = Boolean(value
          .find((
            selectedArticle,
          ) => selectedArticle.code === article.code
          && selectedArticle?.amount
          && selectedArticle?.amount > 0));

        const amount = value
          .find((selectedArticle) => selectedArticle.code === article.code)?.amount || 0;

        const isDismissItem = article.code === dismissServiceId;
        isSelected = isSelected || (isDismissItem && dismissServiceSelected);

        const allowZoom = Boolean(article.image) && !(article.code === dismissServiceId);

        let articleImageType = article.image ? 'gallery-image-with-picture' : 'gallery-image-without-picture';
        articleImageType = article.code === dismissServiceId ? 'gallery-image-dismiss-service' : articleImageType;

        let displayPicture = article.image
          ? `data:${MimeTypes.IMAGE_JPG}${Base64Data.BASE64_GLU}${article.image}`
          : noPicture;
        displayPicture = article.code === dismissServiceId
          ? dismissServicePicture
          : displayPicture;

        return (
          <GalleryImage
            key={article.code}
            allowSelectQuantity={allowSelectQuantity && !isDismissItem && isSelected}
            allowZoom={allowZoom}
            caption={article.description}
            className={articleImageType}
            question={question}
            selected={isSelected}
            step={step}
            url={displayPicture}
            value={new Article({
              ...article,
              amount,
            })}
            onChange={(itemSelection: Article) => {
              let temporalSelection = value.slice();
              const index = temporalSelection
                .findIndex((item) => item.code === itemSelection.code);
              if (itemSelection.amount !== 0) {
                if (index !== -1) {
                  temporalSelection.splice(
                    index,
                    1,
                    new Article({
                      ...itemSelection,
                      price: temporalSelection[index]?.price,
                      vat: temporalSelection[index]?.vat,
                    }),
                  );
                } else {
                  temporalSelection.push(itemSelection);
                }
              } else if (index !== -1 && itemSelection.code !== dismissServiceId) {
                if (multiselect) {
                  temporalSelection.splice(index, 1);
                }
              } else if (itemSelection.code === dismissServiceId) {
                temporalSelection.push(itemSelection);
              }
              if (!multiselect) {
                temporalSelection = temporalSelection
                  .filter((selectionItem) => selectionItem.code === itemSelection.code);
              }
              onChange?.(temporalSelection);
            }}
          />
        );
      })}
      {articles.length % 2 !== 0 ? (<div className="gallery-image ">&nbsp;</div>) : <React.Fragment />}
    </Row>
  </Column>
);
