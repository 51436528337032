import React, { FC } from 'react';
import { ChevronLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/base/Button';

import './index.scss';

type BackButtonProps = {
  to?: string;
};

const BackButton: FC<BackButtonProps> = ({ to }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      className="back-button"
      color="transparent"
      leftAddon={<ChevronLeft />}
      text={t('common.back')}
      to={to}
      onClick={() => (to ? undefined : history.goBack())}
    />
  );
};

export default BackButton;
