import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { priceFormat } from '../../budgetSummary/tools';

import { BudgetQ10SimulationSummaryHook } from './BudgetQ10SimulationSummaryHook';
import { BudgetQ10SimulationSummaryComponent } from './types';

export const BudgetQ10SimulationSummary: FC<BudgetQ10SimulationSummaryComponent> = (
  { articleId },
) => {
  const { t } = useTranslation();
  const { simulatedBudget, clearQ10Simulation } = BudgetQ10SimulationSummaryHook(articleId);

  useEffect(
    () => () => {
      clearQ10Simulation(articleId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleId],
  );

  return (
    <div>
      {simulatedBudget?.totalPriceWithVat(articleId)
        ? t('budget.wizard.totalServiceAmount', { amount: priceFormat(simulatedBudget?.totalPriceWithVat(articleId)) })
        : ''}
    </div>
  );
};
