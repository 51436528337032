import { IMasterDataDto } from 'models/MasterData';

export class MasterData implements IMasterDataDto {
  constructor(params: IMasterDataDto) {
    this.code = params.code;
    this.description = params.description;
  }

  code: string;

  description: string;
}
