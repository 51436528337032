import { FormikProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Datepicker from 'components/base/Datepicker';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import useDeposit from 'hooks/useDeposit';
import { IMasterDataDto } from 'models/MasterData';
import { SaveRecord } from 'models/Record';
import { ChangeStatusFormProps } from 'models/Workorder';

type DepositProps = {
  disabled?: boolean;
  formikProps: FormikProps<ChangeStatusFormProps>;
  record?: SaveRecord;
};

const DepositData: FC<DepositProps> = ({
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const { deposits, normalizedDeposits } = useDeposit(
    values.info?.deposit?.address?.city,
  );
  const { t } = useTranslation();
  return (
    <fieldset>
      <legend>{t('service.DEPOSIT')}</legend>
      <SelectCountryProvinceCity
        key="address"
        cityProps={{
          error: getIn(errors, 'info.deposit.address.city'),
          getLabel: ({ description }) => description,
          getValue: ({ code }) => code,
          name: 'address.c',
          onBlur: handleBlur,
          onChange: (val: string) => {
            setFieldValue('info.deposit.address.city', val);
            setFieldValue('info.deposit.address.crematorium', undefined);
            setFieldValue('info.deposit.address.oven', undefined);
            setFieldValue('info.deposit.address.deposit', undefined);
          },
          placeholder: t('common.city'),
          value: values.info?.deposit?.address?.city || '',
        }}
        countryProps={{
          error: getIn(errors, 'info.deposit.address.country'),
          getLabel: ({ description }) => description,
          getValue: ({ code }) => code,
          name: 'address.co',
          onBlur: handleBlur,
          onChange: (val: string) => setFieldValue('info.deposit.address.country', val),
          placeholder: t('common.country'),
          value: values.info?.deposit?.address?.country || '',
        }}
        disabled={disabled || isSubmitting}
        initialize={false}
        provinceProps={{
          error: getIn(errors, 'info.deposit.address.province'),
          getLabel: ({ description }) => description,
          getValue: ({ code }) => code,
          name: 'address.p',
          onBlur: handleBlur,
          onChange: (val: string) => setFieldValue('info.deposit.address.province', val),
          placeholder: t('common.province'),
          value: values.info?.deposit?.address?.province || '',
        }}
      />
      <Select<IMasterDataDto>
        key="deposit"
        disabled={
          isSubmitting || disabled || !values.info?.deposit?.address?.city
        }
        error={getIn(errors, 'info.deposit.deposit')}
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="deposit"
        options={deposits}
        placeholder={t('service.deposit.deposit')}
        value={
          values.info?.deposit?.deposit
            ? normalizedDeposits[values.info?.deposit?.deposit]
            : undefined
        }
        searchable
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('info.deposit.deposit', val?.code)}
      />
      <Datepicker
        key="entryDate"
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'info.deposit.entryDate')}
        minDate={
          record?.deathData?.deathDate && new Date(record.deathData.deathDate)
        }
        name="entryDate"
        placeholder={t('service.deposit.entryDate')}
        selected={values.info?.deposit?.entryDate}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('info.deposit.entryDate', val)}
      />
      <Datepicker
        key="entryTime"
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'info.deposit.entryTime')}
        name="entryTime"
        placeholder={t('service.deposit.entryTime')}
        selected={values.info?.deposit?.entryTime}
        showTimeSelectOnly
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('info.deposit.entryTime', val)}
      />
      <Datepicker
        key="exitDate"
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'info.deposit.exitDate')}
        name="exitDate"
        placeholder={t('common.exitDate')}
        selected={values.info?.deposit?.exitDate}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('info.deposit.exitDate', val)}
      />
      <Datepicker
        key="exitTime"
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'info.deposit.exitTime')}
        name="exitTime"
        placeholder={t('common.exitTime')}
        selected={values.info?.deposit?.exitTime}
        showTimeSelectOnly
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('info.deposit.exitTime', val)}
      />
      <Textarea
        disabled={isSubmitting || disabled}
        error={getIn(errors, 'info.deposit.comment')}
        id="info.deposit.comment"
        name="info.deposit.comment"
        placeholder={t('common.comment')}
        value={values.info?.deposit?.comment || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('info.deposit.comment', val)}
      />
    </fieldset>
  );
};

export default DepositData;
