import { AnyAction } from 'redux';

import { IAddress } from 'modules/budget/models/Address/types';
import { Article } from 'modules/budget/models/Article';
import { ArticleType, IArticle } from 'modules/budget/models/Article/types';
import { InvoiceArticle } from 'modules/budget/models/InvoiceArticle/types';
import { ILocation } from 'modules/budget/models/Location/types';

import { CeremonyTypes } from '../../data/ceremonyTypeCodes';
import { NicheOwnershipTypes, ServiceTypes } from '../data';

export interface SetArticleProviderPayload {
  article: InvoiceArticle;
  provider: string;
  idBudget: number;
}

export interface SetArticleClientPayload {
  article: InvoiceArticle;
  client: string;
  idBudget: number;
}

export interface SetArticlePricePayload {
  articleId: number;
  articleType: ArticleType;
  price: number;
}

export interface SetArticleAmountPayload {
  articleId: number;
  articleType: ArticleType;
  amount: number;
}

export interface SetArticleClientBulkPayload {
  articles: InvoiceArticle[];
  client: string;
  idBudget: number;
}
export type AnswerQuestionResponse = { service: ServiceTypes | CeremonyTypes; } |
{ yesNo: boolean; } |
{ address: IAddress; location: ILocation; } |
{ address: IAddress; location: ILocation | null; atHome: boolean } |
{ address: IAddress; articles: IArticle[]; } |
{ address: IAddress; } |
{ id: string | null; } |
{ articles: Article[] | null; } |
{ date: Date; } |
{
  nicheType: NicheOwnershipTypes;
  nameChange: boolean;
  concessions: Article[];
  taxes: Article[];
  transfers: Article[];
};

export enum BudgetActionTypes {
  ADD_DISCARDED_ARTICLE = 'ADD_DISCARDED_ARTICLE',
  ANSWER_QUESTION = 'ANSWER_QUESTION',
  CLEAR_ARTICLES = 'CLEAR_ARTICLES',
  CLEAR_Q10_SIMULATION = 'CLEAR_Q10_SIMULATION',
  CLEAR_QUESTION = 'CLEAR_QUESTION',
  CLEAR_SUMMARIZE = 'CLEAR_SUMMARIZE',
  FETCH_Q10_SIMULATION = 'FETCH_Q10_SIMULATION',
  FETCH_SUMMARIZE = 'FETCH_SUMMARIZE',
  GET_INVOICE_ARTICLES = 'GET_INVOICE_ARTICLES',
  GO_BACK = 'GO_BACK',
  GO_NEXT = 'GO_NEXT',
  GO_SUMMARY = 'GO_SUMMARY',
  LOAD_BUDGET = 'LOAD_BUDGET',
  REMOVE_DISCARDED_ARTICLE = 'REMOVE_DISCARDED_ARTICLE',
  RESET = 'RESET',
  SAVE_BUDGET = 'SAVE_BUDGET',
  SET_ARTICLE_CLIENT = 'SET_ARTICLE_CLIENT',
  SET_ARTICLE_CLIENT_BULK = 'SET_ARTICLE_CLIENT_BULK',
  SET_ARTICLE_COLLECTION_PRICE = 'SET_ARTICLE_COLLECTION_PRICE',
  SET_ARTICLE_PRICE = 'SET_ARTICLE_PRICE',
  SET_ARTICLE_AMOUNT = 'SET_ARTICLE_AMOUNT',
  SET_ARTICLE_PROVIDER = 'SET_ARTICLE_PROVIDER',
  SET_BUDGET = 'SET_BUDGET',
  SET_BUDGET_LOADING = 'SET_BUDGET_LOADING',
  SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION',
  SET_INVOICE_ARTICLES = 'SET_INVOICE_ARTICLES',
  SET_INVOICE_ARTICLES_LOADING = 'SET_INVOICE_ARTICLES_LOADING',
  SET_INVOICE_CLIENTS = 'SET_INVOICE_CLIENTS',
  SET_Q10_SIMULATION = 'SET_Q10_SIMULATION',
  SET_SUMMARIZE = 'SET_SUMMARIZE',
  SET_SUMMARIZE_LOADING = 'SET_SUMMARIZE_LOADING',
  UPDATE_ARTICLE_CLIENT = 'UPDATE_ARTICLE_CLIENT',
  UPDATE_ARTICLE_PROVIDER = 'UPDATE_ARTICLE_PROVIDER',
}

export type BudgetAction = AnyAction;

export interface FetchQ10SimulationPayload {
  articleId: string;
}
