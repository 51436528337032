import classNames from 'classnames';
import React, {
  FC, useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'components/base/Row';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';

import { Address } from 'modules/budget/models/Address';

import { Classable } from '../../types/helpers';

interface CitySelectorComponent extends Classable {
  onChange: (value: Address) => void
  disabled: boolean;
  cityCode: string;
  cityWork: boolean;
  provinceCode: string;
  countryCode: string;
  cityCaption?: string;
  provinceCaption?: string;
  countryCaption?: string;
  emitPartial?: boolean;
}

export const CitySelector: FC<CitySelectorComponent> = (
  {
    onChange,
    disabled,
    cityCode,
    provinceCode,
    countryCode,
    className,
    cityCaption,
    provinceCaption,
    countryCaption,
    cityWork,
    emitPartial = false,
  },
) => {
  const { t } = useTranslation();
  const [city, setCity] = useState<string>(cityCode);
  const [province, setProvince] = useState<string>(provinceCode);
  const [country, setCountry] = useState<string>(countryCode);
  const [work, setWork] = useState<boolean>(cityWork);

  useEffect(
    () => {
      setCity(cityCode);
    },
    [cityCode],
  );

  useEffect(
    () => {
      setWork(cityWork);
    },
    [cityWork],
  );

  useEffect(
    () => {
      setProvince(provinceCode);
    },
    [provinceCode],
  );

  useEffect(
    () => {
      setCountry(countryCode);
    },
    [countryCode],
  );

  useEffect(
    () => {
      const fullfilled = city && country && province && work !== undefined;
      if ((!emitPartial && fullfilled) || emitPartial) {
        onChange(new Address({
          city,
          country,
          province,
          work,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emitPartial, city, country, province, work],
  );

  useEffect(
    () => {
      if (disabled) {
        setCity('');
        setProvince('');
        setCountry('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled],
  );

  return (
    <Row className={classNames('f-city-selector', className)} justifyContent="space-between">
      <SelectCountryProvinceCity
        key="address"
        cityProps={{
          getLabel: ({ description }) => description,
          getValue: ({ code }) => code,
          name: 'budget-c',
          onChange: (cityVal: string, workVal: boolean) => {
            setCity(cityVal);
            setWork(workVal);
          },
          placeholder: cityCaption || t('common.city'),
          value: city || '',
        }}
        countryProps={{
          getLabel: ({ description }) => description,
          getValue: ({ code }) => code,
          name: 'budget-co',
          onChange: (countryVal: string) => {
            setCountry(countryVal);
          },
          placeholder: countryCaption || t('common.country'),
          value: country || '',
        }}
        disabled={disabled}
        initialize={false}
        provinceProps={{
          getLabel: ({ description }) => description,
          getValue: ({ code }) => code,
          name: 'budget-p',
          onChange: (provinceVal: string) => {
            setProvince(provinceVal);
          },
          placeholder: provinceCaption || t('common.province'),
          value: province || '',
        }}
      />
    </Row>
  );
};
