import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Address } from 'modules/budget/models/Address';
import { ILocation } from 'modules/budget/models/Location/types';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q5Form, Q5Response } from './form';

export const Q5: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q5;
  const { RespondQuestion } = useBudget();

  const [dismissService, setDismissService] = useState<boolean>();
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
  const [enabledReply, setEnabledReply] = useState<boolean>(false);
  const [autorespond, setautorespond] = useState<boolean>(false);

  useEffect(
    () => {
      const isFullfilled = selectedAddress?.isFullFilled() || false;
      setEnabledReply(dismissService
        || (dismissService === false && isFullfilled && Boolean(selectedLocation)));
    },
    [dismissService, selectedAddress, selectedLocation],
  );

  const response = useCallback(
    ({ gotoSummary }) => {
      if (!dismissService) {
        if (selectedAddress && selectedLocation) {
          RespondQuestion({
            gotoSummary,
            question,
            response: {
              address: selectedAddress,
              location: selectedLocation,
            },
          });
        }
      } else {
        RespondQuestion({
          gotoSummary,
          question,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dismissService, selectedAddress, selectedLocation],
  );

  useEffect(
    () => {
      if (autorespond && enabledReply) {
        response({ gotoSummary: false });
      }
    },
    [autorespond, enabledReply, response],
  );

  const onChange = useCallback(
    (responseData: Q5Response) => {
      setSelectedAddress(responseData.address);
      setSelectedLocation(responseData.location);
      setDismissService(Boolean(responseData.dismissService));
      setautorespond(responseData.autorespond);
    },
    [],
  );

  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q5Form
        question={BudgetQuestionCodes.Q5}
        onChange={onChange}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{
              address: selectedAddress,
              location: selectedLocation,
            }}
          />
          <QConfirmButton
            disabled={!enabledReply}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
