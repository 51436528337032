import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';

import Button from 'components/base/Button';

import './index.scss';

type ServiceSidebarButtonProps = {
  errors: number;
  icon: ReactElement;
  onClick(): void;
  selected?: boolean | undefined;
  text: string;
  isInUseService?: boolean;
};

const ServiceSidebarButton: FC<ServiceSidebarButtonProps> = ({
  errors,
  icon,
  onClick,
  selected,
  text,
  isInUseService = true,
}) => (
  <Button
    className={classNames('service-sidebar-button', {
      error: errors > 0,
      'is-in-use-service': isInUseService,
      selected,
    })}
    color="transparent"
    leftAddon={icon}
    text={text}
    onClick={onClick}
  >
    <div className="errors" />
  </Button>
);

export default ServiceSidebarButton;
