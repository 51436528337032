import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Classable } from 'modules/budget/components/types/helpers';

import Button from '../Button';
import { Column } from '../Column';
import { Row } from '../Row';

import './index.scss';

interface QuantitySelectorComponent extends Classable {
  onChange?: (qt: number) => void;
  value: number;
  step: number;
  code: string;
}

const QuantitySelector: FC<QuantitySelectorComponent> = (
  {
    value, onChange, step, code, className,
  },
) => {
  const { t } = useTranslation();

  const plus = (quantity: number) => quantity + step;
  const minus = (quantity: number) => (quantity > 0 ? quantity - step : quantity);

  return (
    <Row className={classNames('quantity-selector', className)}>
      <Button
        testid={`${code}-quantity-minus`}
        text={t('budget.wizard.minus')}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          onChange?.(minus(value));
        }}
      />
      <Column justifyContent="space-around">
        <div className="quantity-selector-qt" data-testid={`${code}-quantity`}>{value}</div>
      </Column>
      <Button
        testid={`${code}-quantity-plus`}
        text={t('budget.wizard.plus')}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          onChange?.(plus(value));
        }}
      />
    </Row>
  );
};

QuantitySelector.defaultProps = {
  onChange: () => false,
};

export { QuantitySelector };
