import { useState } from 'react';

import { orderLineController } from 'config/apiFunus';
import { ArticleInfoResponseDto } from 'config/apiFunus/generated/data-contracts';

import { GetArticleInfoUsingPostHook } from './types';

export const useGetArticleInfoUsingPost = (
  errorMessage: (message: string) => void,
): GetArticleInfoUsingPostHook => {
  const [articleDetail, setArticleDetail] = useState<ArticleInfoResponseDto>();

  const clearArticleDetail = () => {
    setArticleDetail(undefined);
  };

  const getArticleDetail = async (qr: string) => orderLineController
    .getArticleInfoUsingPost({ qr })
    .then((response: ArticleInfoResponseDto) => {
      setArticleDetail(response);

      return response;
    })
    .catch((error) => {
      errorMessage(error.message);
      throw error;
    });

  return {
    articleDetail,
    clearArticleDetail,
    getArticleDetail,
  };
};
