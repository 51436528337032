import classNames from 'classnames';
import React, { FC } from 'react';
import './index.scss';

interface ColumnStylesHook {
  column: React.CSSProperties,
  columnItem: React.CSSProperties
}

const useColumnStyles = (
  { justifyContent, overflow }: {
    justifyContent?: React.CSSProperties['justifyContent'],
    overflow?: React.CSSProperties['overflow']},
):ColumnStylesHook => ({
  column: {
    justifyContent,
    overflow,
  },
  columnItem: {
    flexBasis: 'content',
  },
});

interface ColumnComponent {
  justifyContent?: React.CSSProperties['justifyContent'];
  overflow?: React.CSSProperties['overflow'];
  style?: React.CSSProperties;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

const Column: FC<ColumnComponent> = ({
  children, justifyContent, style, className, overflow,
}: ColumnComponent) => {
  const { column, columnItem } = useColumnStyles({ justifyContent, overflow });
  const clones = React.Children.map(
    children,
    (child) => React.cloneElement(child, { style: columnItem }),
  );

  return (
    <div
      className={classNames('f-column', className)}
      style={{
        ...column,
        ...style,
      }}
    >
      {clones}
    </div>
  );
};

Column.defaultProps = {
  className: '',
  justifyContent: 'start',
  overflow: 'auto',
  style: {},
};

export { Column };
