export default {
  primary: '#D7C835',
  primaryDark: '#A29800',
  primaryLight: '#FFFB69',
  secondary: '#A2303E',
  secondaryDark: '#7A001E',
  secondaryLight: '#E7556F',
  accentBlue: '#4953DE',
  accentGreen: '#E3DDA3',
  accentGreenDark: '#B0AB74',
  accentGreenLight: '#FFFFD5',
  gray100: '#F9F9F9',
  gray300: '#E5E9E5',
  gray500: '#B7C1B7',
  gray700: '#798079',
  gray800: '#505850',
  gray900: '#182618',
  white: '#FFFFFF',
  success: '#83A200',
  error: '#F44336',
};
