import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { i18n } from 'i18n';

const getUrlTranslation = (pathname: string): string => {
  const array: string[] = pathname.split('/');

  if (array?.length === 2 && array[1]) {
    const translation = `${i18n.t(`menu.${array[1]}`)}`;
    return translation !== `menu.${array[1]}`
      ? translation
      : `${i18n.t('menu.notFound')}`;
  }
  if (array?.length === 2 && !array[1]) {
    return `${i18n.t('menu.home')}`;
  }
  if (array?.length === 3 && array[1] === 'user' && array[2] === 'password') {
    return `${i18n.t('menu.password')}`;
  }
  if (array?.length === 3 && array[2] === 'add') {
    return `${i18n.t(`menu.add${array[1]}`)}`;
  }
  if (array?.length === 3) {
    return `${i18n.t(`menu.detail${array[1]}`)}`;
  }
  if (array?.length === 4 && array[1] === 'calendar') {
    return `${i18n.t('menu.calendar')}`;
  }
  if (array?.length === 4) {
    return `${i18n.t(`menu.edit${array[1]}`)}`;
  }
  if (array?.length === 5 && array[1] === 'agenda') {
    return `${i18n.t('menu.agenda')}`;
  }

  return '';
};

type UrlTranslationProps = {
  translation: string;
  getUrlTranslation(pathname: string): string;
};

const useUrlTranslation: () => UrlTranslationProps = () => {
  const [translation, setTranslation] = useState<string>('');

  useEffect(() => {
    setTranslation(getUrlTranslation(window.location.pathname));
  }, []);
  const history = useHistory();

  useEffect(
    () => history.listen((location) => {
      setTranslation(getUrlTranslation(location.pathname));
    }),
    [history],
  );

  return { getUrlTranslation, translation };
};

export default useUrlTranslation;
