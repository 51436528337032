import classNames from 'classnames';
import React, {
  createRef,
  FC,
  Fragment,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

export type TooltipProps = {
  className?: string;
  content?: ReactElement | ReactNode | string | number;
  disabled?: boolean;
};
const tooltipRoot = document.createElement('div');
tooltipRoot.setAttribute('id', 'tooltip-root');
document.body.appendChild(tooltipRoot);
const element = document.createElement('div');
tooltipRoot.appendChild(element);

type Position = 'top' | 'bottom' | 'left' | 'right';

const Tooltip: FC<TooltipProps> = ({
  className,
  children,
  content,
  disabled,
}) => {
  const [target, setTarget] = useState<HTMLElement>();
  const [position, setPosition] = useState<Position>('top');
  const [tooltipX, setTooltipX] = useState<number>();
  const [tooltipY, setTooltipY] = useState<number>();
  const [show, setShow] = useState(false);
  const tooltipRef = createRef<HTMLDivElement>();

  const open = (e: MouseEvent<HTMLDivElement>) => {
    setShow(true);
    setTarget(e.target as HTMLElement);
  };

  useEffect(() => {
    if (!disabled && target && tooltipRef.current) {
      const {
        top, left, right, bottom, width, height,
      } = target.getBoundingClientRect();

      const { offsetHeight, offsetWidth } = tooltipRef.current || {};

      let newPosition: Position = 'top';
      let newX = top - (offsetHeight || 0);
      let newY = left - (offsetWidth || 0) / 2 + width / 2;

      if (top < (offsetHeight || 100)) {
        newPosition = 'bottom';
        newX = bottom;
      } else if (bottom > 100 && left < (offsetWidth || 100)) {
        newPosition = 'right';
        newX = top - (offsetHeight || 0) / 2 + height / 2;
        newY = right;
      } else if (
        bottom > 100
        && window.innerWidth - right < (offsetWidth || 100)
      ) {
        newPosition = 'left';
        newX = top - (offsetHeight || 0) / 2 + height / 2;
        newY = left - (offsetWidth || 0);
      }

      setPosition(newPosition);

      setTooltipX(newX);
      setTooltipY(newY);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, tooltipRef.current]);

  const close = () => {
    setShow(false);
  };

  if (disabled) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames('funus-tooltip', className)}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {show
        && ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className={classNames('tooltip-content fadein', position)}
            style={
              show
                ? {
                  left: tooltipY,
                  top: tooltipX,
                }
                : { opacity: 0, pointerEvents: 'none' }
            }
          >
            {content}
          </div>,
          element,
        )}
      <div className="tooltip-children">{children}</div>
    </div>
  );
};

export default Tooltip;
