import { isEmpty, isNumber } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Select from 'components/base/Select';

import { UserFormType } from 'models/User';
import UserRole from 'models/UserRole';
import { RootState } from 'store';

type FormattedRole = UserRole & {
  translation: string;
};

type SelectorProps = {
  disabled?: boolean;
  name: string;
  values: UserFormType;
  error?: string | boolean | undefined;
  setFieldValue: (param: string, id: string | number) => void;
};

const SelectRole = ({
  disabled,
  name,
  values,
  error,
  setFieldValue,
}: SelectorProps): ReactElement => {
  const [formattedRoles, setFormattedRoles] = useState<FormattedRole[]>([]);
  const [role, setRole] = useState<FormattedRole>();
  const { roles } = useSelector((state: RootState) => state.roles);
  const { t } = useTranslation();

  const handleChangeRole = (val: FormattedRole) => {
    setRole(val);
    setFieldValue('idrole', val?.id);
  };

  useEffect(() => {
    if (roles?.length && !formattedRoles?.length) {
      setFormattedRoles(
        roles.map((rol: UserRole) => ({
          ...rol,
          translation: t(`role.${rol.name}`, rol.name),
        })),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  useEffect(() => {
    if (isNumber(values.idrole) && isEmpty(role) && formattedRoles.length) {
      setRole(
        formattedRoles.find((opt: FormattedRole) => opt.id === values.idrole),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, formattedRoles]);

  return (
    <Select<FormattedRole>
      key="role"
      disabled={disabled}
      error={error}
      getLabel={(e) => e.translation}
      getValue={(e) => e.id}
      name={name}
      options={formattedRoles}
      placeholder={t('user.role.placeholder')}
      value={role}
      onChange={handleChangeRole}
    />
  );
};

SelectRole.defaultProps = {
  disabled: false,
  error: undefined,
};

export default SelectRole;
