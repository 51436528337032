import classNames from 'classnames';
import React, {
  FC, ReactElement, ReactNode, useState,
} from 'react';
import { Eye } from 'react-feather';

import Button from 'components/base/Button';
import Input, { InputProps } from 'components/base/Input';

import './index.scss';

export type ContentProps = {
  expanded: boolean;
  hide(): void;
};

export type InputWithContentProps = InputProps & {
  className?: string | undefined;
  content: (props: ContentProps) => ReactElement | ReactNode;
  disableButton?: boolean;
  icon?: JSX.Element;
};

const InputWithContent: FC<InputWithContentProps> = ({
  className,
  content,
  disabled,
  disableButton,
  error,
  name,
  placeholder,
  value,
  icon,
}) => {
  const [expanded, setExpanded] = useState(false);

  const expand = () => {
    setExpanded(true);
  };

  const hide = () => {
    setExpanded(false);
  };

  return (
    <div className={classNames('funus-input-content', className)}>
      <div className="input-container">
        <Input
          disabled={disabled}
          error={error}
          id={name}
          name={name}
          placeholder={placeholder}
          rightAddon={(
            <Button
              className="expand-button"
              disabled={disableButton}
              onClick={expand}
            >
              {icon || <Eye />}
            </Button>
          )}
          value={value}
          readOnly
          onClick={expand}
          onFocus={expand}
        />
      </div>
      <div className={classNames('content', { expanded })}>
        {content({ expanded, hide })}
      </div>
    </div>
  );
};

export default InputWithContent;
