import { FormikProps, getIn } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Datepicker from 'components/base/Datepicker';
import Input from 'components/base/Input';
import Select from 'components/base/Select';

import { useFuneraryOrigins } from 'hooks/useFuneraryOrigins';
import { useReceptionTypes } from 'hooks/useReceptionTypes';
import { IMasterDataDto, ReceptionType } from 'models/MasterData';
import { SaveRecord } from 'models/Record';

type ReceptionFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

export const ReceptionFields: FC<ReceptionFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },

}) => {
  const { t } = useTranslation();
  const [
    isReception,
    setIsReception,
  ] = useState<boolean>(!!values.receptionData);

  const { funeraryOrigins, getFuneraryOrigin } = useFuneraryOrigins();
  const { receptionTypes, getReceptionType } = useReceptionTypes();

  useEffect(
    () => {
      if (isReception) {
        if (!values.receptionData) {
          setFieldValue('receptionData', {
            entryDate: null,
            entryTime: null,
            funeraryOrigin: null,
            observations: null,
            operatorComment: null,
            receptionType: null,
          });
        }
      } else {
        setFieldValue('receptionData', null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReception],
  );

  return (
    <fieldset>
      {createMode && <legend>{t('record.steps.reception')}</legend>}
      <Input
        checked={!!values.receptionData}
        disabled={isSubmitting || disabled || values.hasPickUp || values.hasReception}
        id="receptionData.isReception"
        name="receptionData.isReception"
        placeholder={t('record.reception.isReception')}
        type="checkbox"
        onBlur={handleBlur}
        onChange={(val) => setIsReception(!!val)}
      />
      <Select<ReceptionType>
        disabled={disabled || isSubmitting || !isReception}
        error={getIn(errors, 'receptionData.receptionType')}
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="reception.receptionType"
        options={receptionTypes}
        placeholder={t('record.reception.receptionType')}
        value={values.receptionData?.receptionType
          ? getReceptionType(values.receptionData?.receptionType)
          : undefined}
        searchable
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('receptionData.receptionType', val?.code)}
      />
      <Select<IMasterDataDto>
        disabled={disabled || isSubmitting || !isReception}
        error={getIn(errors, 'receptionData.funeraryOrigin')}
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="reception.funeraryOrigin"
        options={funeraryOrigins}
        placeholder={t('record.reception.funeraryOrigin')}
        value={values.receptionData?.funeraryOrigin
          ? getFuneraryOrigin(values.receptionData?.funeraryOrigin)
          : undefined}
        searchable
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('receptionData.funeraryOrigin', val?.code)}
      />
      <Datepicker
        disabled={disabled || isSubmitting || !isReception}
        error={getIn(errors, 'receptionData.entryDate')}
        name="receptionData.entryDate"
        placeholder={t('record.reception.entryDate')}
        selected={values.receptionData?.entryDate}
        onBlur={handleBlur}
        onChange={(val) => {
          if (val) {
            setFieldValue('receptionData.entryDate', val);
          }
        }}
      />
      <Datepicker
        disabled={disabled || isSubmitting || !isReception}
        error={getIn(errors, 'receptionData.entryTime')}
        name="receptionData.entryTime"
        placeholder={t('record.reception.entryTime')}
        selected={values.receptionData?.entryTime}
        showTimeSelectOnly
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('receptionData.entryTime', val)}
      />
      <Input
        error={getIn(errors, 'receptionData.observations')}
        id="observations"
        name="observations"
        placeholder={t('common.observations')}
        type="text"
        value={values.receptionData?.observations || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('receptionData.observations', val)}
      />
      <Input
        error={getIn(errors, 'receptionData.coordinatorComment')}
        id="operatorComment"
        name="operatorComment"
        placeholder={t('common.operatorComment')}
        type="text"
        value={values.receptionData?.operatorComment || ''}
        disabled
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('receptionData.coordinatorComment', val)}
      />
    </fieldset>
  );
};
