import { IMasterDataDto } from 'models/MasterData';
import { CeremonyTypes } from 'modules/budget/data/ceremonyTypeCodes';
import { IArticle } from 'modules/budget/models/Article/types';

export const mapMasterDataToArticle = (item: IMasterDataDto): IArticle => ({
  code: item.code,
  description: item.description,
});

export const includeAllowedCeremonyTypes = (
  item: IMasterDataDto,
): boolean => Object.values(CeremonyTypes).includes(item.code as CeremonyTypes);
