import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { orderLineController } from 'config/apiFunus';
import { MasterDataDto, OrderLineChannel, EntryChannelResponseDto } from 'config/apiFunus/generated/data-contracts';

import { GetEntryChannelsUsingGetHook } from './types';

export const useGetEntryChannelsUsingGet = (
  errorMessage: (message: string) => void,
): GetEntryChannelsUsingGetHook => {
  const { t } = useTranslation();

  const [articleRequestTypes, setArticleRequestTypes] = useState<MasterDataDto[]>([]);

  const fetchArticleRequestTypes = useCallback(
    () => orderLineController
      .getEntryChannelsUsingGet()
      .then((response: EntryChannelResponseDto) => {
        const filterAvailableRequestTypes = (channel: OrderLineChannel): boolean => [
          OrderLineChannel.PROCESSING,
          OrderLineChannel.EXTERNAL,
        ].includes(channel);

        const mapToMasterData = (
          channel: OrderLineChannel,
        ): { code: OrderLineChannel; description: string; } => ({
          code: channel,
          description: t(`article.flowers.channels.${channel}`),
        });

        const availableChannels = response.types
          ? response.types
            .filter(filterAvailableRequestTypes)
            .map(mapToMasterData)
          : [];

        setArticleRequestTypes(availableChannels);

        return response;
      })
      .catch((error) => {
        errorMessage(error.message);
        throw error;
      }),
    [errorMessage, t],
  );

  return {
    articleRequestTypes,
    fetchArticleRequestTypes,
  };
};
