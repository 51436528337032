import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import FormPage from 'components/base/FormPage';

import {
  ChangePasswordType,
  getChangePasswordFields,
  initialValuesChange,
} from 'components/user/ChangePasswordForm';

import { useProvidedAuth } from 'hooks/useProvidedAuth';

const ChangePasswordPage: FC = () => {
  const { t } = useTranslation();
  const { user } = useProvidedAuth();

  const onError = () => {
    showErrorToast(t('user.changePasswordKo'));
  };

  const onSave = () => {
    showSuccessToast(t('user.changePasswordOk'));
  };

  return (
    <div id="change-password-page">
      {user?.id && (
        <FormPage<ChangePasswordType>
          backUrl={config.url.userProfile}
          fields={(props) => getChangePasswordFields(props, false)}
          initialValues={initialValuesChange}
          title={t('user.changePassword')}
          updateFunction={(params) => config.apiFunus.login.changePassword(params, user.id)}
          editing
          onError={onError}
          onSave={onSave}
        />
      )}
    </div>
  );
};

export default ChangePasswordPage;
