import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDate } from 'utils/dateManager';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import Datepicker from 'components/base/Datepicker';
import Input from 'components/base/Input';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Table from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';
import Textarea from 'components/base/Textarea';

import useCeremonyLocation from 'hooks/useCeremonyLocation';
import useUsers from 'hooks/useUsers';
import { IMasterDataDto } from 'models/MasterData';
import {
  PostCeremony,
  SaveService,
} from 'models/MasterService';
import { SaveRecord } from 'models/Record';
import { SimpleUser } from 'models/User';
import { RootState } from 'store';
import './index.scss';

type CeremonyProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
  record?: SaveRecord;
};

const CeremonyForm: FC<CeremonyProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const [disablePost, setDisablePost] = useState(true);
  const [fields, setFields] = useState<PostCeremony>({});
  const [selected, setSelected] = useState<number>();
  const { t } = useTranslation();
  const {
    ceremonyLocations,
    normalizedCeremonyLocations,
  } = useCeremonyLocation(values.ceremonyServiceDto?.ceremonyAddress?.city);
  const {
    ceremonyLocations: postLocations,
    normalizedCeremonyLocations: postNormalized,
  } = useCeremonyLocation(fields.address?.city);
  const {
    loading: loadingUsers,
    users,
    getUser,
  } = useUsers([]);

  const { ceremonies } = useSelector((state: RootState) => state.masterData);
  const { normalized } = useSelector((state: RootState) => state);
  const postCeremoniesCols = useMemo(
    (): TableColumn<PostCeremony>[] => [
      {
        accessor: 'date',
        Cell: ({ cell: { value } }) => (value ? formatDate(value as Date) : ''),
        className: 'primary-dark-color',
        Header: `${t('common.date')}`,
      },
      { accessor: 'time', Header: `${t('common.hour')}` },
      {
        accessor: 'type',
        Cell: ({ cell: { value } }) => normalized.ceremonies[value as string]?.description || '',
        Header: `${t('service.ceremony.type')}`,
      },
      { accessor: 'comment', Header: `${t('common.observations')}` },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, normalized, errors],
  );

  const clear = () => {
    setFields({});
    setSelected(undefined);
  };

  const save = () => {
    const newCeremonies = values.ceremonyServiceDto?.postCeremonies?.length
      ? [...values.ceremonyServiceDto?.postCeremonies]
      : [];
    if (selected === 0 || selected) {
      newCeremonies[selected] = fields;
    } else {
      newCeremonies.push(fields);
    }
    setFieldValue('ceremonyServiceDto.postCeremonies', newCeremonies);
    clear();
  };

  const removeCeremony = (row: PostCeremony, idx: number) => {
    const newCeremonies = values.ceremonyServiceDto?.postCeremonies
      ? [...values.ceremonyServiceDto?.postCeremonies]
      : [];
    newCeremonies.splice(idx, 1);
    setFieldValue('ceremonyServiceDto.postCeremonies', newCeremonies);
  };

  const changeHour = (val: string) => {
    setFieldValue('ceremonyServiceDto.ceremonyTime', val);

    if (
      values.vigilServiceDto
      && Object.keys(values.vigilServiceDto).length > 0
    ) {
      setFieldValue('vigilServiceDto.exitTime', val);
    }
  };

  const changeDate = (val: string) => {
    setFieldValue('ceremonyServiceDto.ceremonyDate', val);

    if (
      values.vigilServiceDto
      && Object.keys(values.vigilServiceDto).length > 0
    ) {
      setFieldValue('vigilServiceDto.exitDate', val);
    }
  };

  useEffect(() => {
    setDisablePost(!Object.values(fields).some((val) => !!val));
  }, [fields]);

  return (
    <div className={classNames('ceremony-form', className)}>
      <fieldset>
        <legend>{t('service.CEREMONIA')}</legend>
        <SelectCountryProvinceCity
          key="ceremonyAddress"
          cityProps={{
            error: getIn(errors, 'ceremonyServiceDto.ceremonyAddress.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'ceremonyAddress.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue('ceremonyServiceDto.ceremonyAddress.city', val);
              setFieldValue('ceremonyServiceDto.location', undefined);
            },
            placeholder: t('common.city'),
            value: values.ceremonyServiceDto?.ceremonyAddress?.city || '',
          }}
          countryProps={{
            error: getIn(errors, 'ceremonyServiceDto.ceremonyAddress.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'ceremonyAddress.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('ceremonyServiceDto.ceremonyAddress.country', val),
            placeholder: t('common.country'),
            value: values.ceremonyServiceDto?.ceremonyAddress?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(errors, 'ceremonyServiceDto.ceremonyAddress.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'ceremonyAddress.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('ceremonyServiceDto.ceremonyAddress.province', val),
            placeholder: t('common.province'),
            value: values.ceremonyServiceDto?.ceremonyAddress?.province || '',
          }}
        />
        <Select<IMasterDataDto>
          key="ceremony-location"
          disabled={
            isSubmitting
            || disabled
            || !values.ceremonyServiceDto?.ceremonyAddress?.province
          }
          error={getIn(errors, 'ceremonyServiceDto.location')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="location"
          options={ceremonyLocations}
          placeholder={t('common.location')}
          value={
            values.ceremonyServiceDto?.location
              ? normalizedCeremonyLocations[values.ceremonyServiceDto?.location]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('ceremonyServiceDto.location', val?.code)}
        />
        <Select<IMasterDataDto>
          key="type"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'ceremonyServiceDto.type')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="type"
          options={ceremonies}
          placeholder={t('service.ceremony.type')}
          value={
            values.ceremonyServiceDto?.type
              ? normalized.ceremonies[values.ceremonyServiceDto?.type]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('ceremonyServiceDto.type', val?.code)}
        />
        <Datepicker
          key="ceremonyDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'ceremonyServiceDto.ceremonyDate')}
          minDate={
            record?.deathData?.deathDate && new Date(record.deathData.deathDate)
          }
          name="ceremonyDate"
          placeholder={t('common.date')}
          selected={values.ceremonyServiceDto?.ceremonyDate}
          onBlur={handleBlur}
          onChange={changeDate}
        />
        <Datepicker
          key="ceremonyTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'ceremonyServiceDto.ceremonyTime')}
          name="ceremonyTime"
          placeholder={t('common.hour')}
          selected={values.ceremonyServiceDto?.ceremonyTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={changeHour}
        />
        <Input
          key="pressCommunication"
          checked={!!values.ceremonyServiceDto?.pressCommunication}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'ceremonyServiceDto.pressCommunication')}
          name="pressCommunication"
          placeholder={t('service.ceremony.pressCommunication')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('ceremonyServiceDto.pressCommunication', val)}
        />
        <Textarea
          key="ceremonyComment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'ceremonyServiceDto.ceremonyComment')}
          name="ceremonyComment"
          placeholder={t('common.observations')}
          value={values.ceremonyServiceDto?.ceremonyComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('ceremonyServiceDto.ceremonyComment', val)}
        />
        <Select<SimpleUser>
          disabled={loadingUsers}
          error={getIn(errors, 'ceremonyServiceDto.ceremonial')}
          getLabel={(option) => `${option.name} ${option.firstSurname} ${
            option.secondSurname || ''
          }`}
          getValue={(option) => option.id}
          name="ceremonial"
          options={users}
          placeholder={t('service.ceremony.ceremonial')}
          value={
            getUser(values.ceremonyServiceDto?.ceremonial)
          }
          clearable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('ceremonyServiceDto.ceremonial', val?.id)}
        />
      </fieldset>
      <fieldset>
        <legend>{t('service.ceremony.postInterment')}</legend>
        <Datepicker
          key="date"
          disabled={disabled || isSubmitting}
          error={
            selected !== undefined
            && selected > -1
            && getIn(errors, `ceremonyServiceDto.postCeremonies[${selected}].date`)
          }
          name="date"
          placeholder={t('common.date')}
          selected={fields.date}
          onBlur={handleBlur}
          onChange={(val: Date) => setFields({ ...fields, date: val })}
        />
        <Datepicker
          key="time"
          disabled={disabled || isSubmitting}
          error={
            selected !== undefined
            && selected > -1
            && getIn(errors, `ceremonyServiceDto.postCeremonies[${selected}].time`)
          }
          name="time"
          placeholder={t('common.hour')}
          selected={fields.time}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val: Date) => setFields({ ...fields, time: val })}
        />
        <SelectCountryProvinceCity
          key="address"
          cityProps={{
            error:
              selected !== undefined
              && selected > -1
              && getIn(
                errors,
                `ceremonyServiceDto.postCeremonies[${selected}].address.city`,
              ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.c',
            onBlur: handleBlur,
            onChange: (val: string) => setFields((flds) => ({
              ...flds,
              address: flds?.address
                ? { ...flds.address, city: val }
                : { city: val },
            })),
            placeholder: t('common.city'),
            value: fields.address?.city || '',
          }}
          countryProps={{
            error:
              selected !== undefined
              && selected > -1
              && getIn(
                errors,
                `ceremonyServiceDto.postCeremonies[${selected}].address.country`,
              ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFields((flds) => ({
              ...flds,
              address: flds?.address
                ? { ...flds.address, country: val }
                : { country: val },
            })),
            placeholder: t('common.country'),
            value: fields.address?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error:
              selected !== undefined
              && selected > -1
              && getIn(
                errors,
                `ceremonyServiceDto.postCeremonies[${selected}].address.province`,
              ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFields((flds) => ({
              ...flds,
              address: flds?.address
                ? { ...flds.address, province: val }
                : { province: val },
            })),
            placeholder: t('common.province'),
            value: fields.address?.province || '',
          }}
        />
        <Select<IMasterDataDto>
          key="post-ceremony-location"
          disabled={isSubmitting || disabled || !fields.address?.province}
          error={
            selected !== undefined
            && selected > -1
            && getIn(
              errors,
              `ceremonyServiceDto.postCeremonies[${selected}].location`,
            )
          }
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="location"
          options={postLocations}
          placeholder={t('common.location')}
          value={
            fields?.location ? postNormalized[fields?.location] : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFields({ ...fields, location: val?.code })}
        />
        <Select<IMasterDataDto>
          key="type"
          disabled={isSubmitting || disabled}
          error={
            selected !== undefined
            && selected > -1
            && getIn(errors, `ceremonyServiceDto.postCeremonies[${selected}].type`)
          }
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="type"
          options={ceremonies}
          placeholder={t('service.ceremony.type')}
          value={fields?.type ? normalized.ceremonies[fields?.type] : undefined}
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFields({ ...fields, type: val?.code })}
        />
        <Textarea
          key="comment"
          disabled={isSubmitting || disabled}
          error={
            selected !== undefined
            && selected > -1
            && getIn(
              errors,
              `ceremonyServiceDto.postCeremonies[${selected}].comment`,
            )
          }
          name="comment"
          placeholder={t('common.observations')}
          value={fields.comment || ''}
          onBlur={handleBlur}
          onChange={(val: string) => setFields({ ...fields, comment: val })}
        />
        <div className="post-ceremony-buttons">
          <Button
            disabled={disabled || disablePost}
            text={t('common.add')}
            onClick={save}
          />
          <Button
            color="secondary"
            disabled={disabled || disablePost}
            text={t('common.clear')}
            onClick={clear}
          />
        </div>
        <Table<PostCeremony>
          actions={(row, idx) => ({
            edit: {
              icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
              onClick: () => {
                setFields(row);
                setSelected(idx);
              },
              tooltipCaption: t('common.edit'),
            },
            remove: {
              icon: <CustomIcon icon={FeatherIconTypes.TRASH} />,
              onClick: () => removeCeremony(row, idx),
              tooltipCaption: t('common.remove'),
            },
          })}
          columns={postCeremoniesCols}
          data={values.ceremonyServiceDto?.postCeremonies || []}
          pagination={false}
          rowClassName={(index) => classNames({
            error: getIn(
              errors,
              `ceremonyServiceDto.postCeremonies[${index}]`,
            ),
          })}
        />
      </fieldset>
    </div>
  );
};

export default CeremonyForm;
