import { Middleware } from 'redux';

import { showErrorToast } from 'utils/toasts';

import { i18n } from 'i18n';

import { budgetController } from '../../api';
import { SetBudget } from '../budgetActions';
import { BudgetActionTypes } from '../budgetActions/types';

export const LoadBudget: Middleware = (storeApi) => (next) => async (action) => {
  if (
    action.type === BudgetActionTypes.LOAD_BUDGET) {
    const result = next(action);

    const load = async () => budgetController.GetBudget(action.payload.id);

    try {
      const response = await load();

      storeApi.dispatch(SetBudget(response));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrorToast(i18n.t(error.message));
    }

    return result;
  }

  return next(action);
};
