import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Modal from 'components/base/Modal';

import { ArticleStatusRequestDtoEvent } from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';

type AcceptRequestModalComponent = {
  show: boolean;
  id: number | undefined;
  onSuccess: () => void,
  onHide: () => void
}

export const AcceptRequestModal: FC<AcceptRequestModalComponent> = ({
  show,
  id,
  onSuccess,
  onHide,
}) => {
  const { t } = useTranslation();
  const { updateArticleStatus } = useArticles();
  const acceptRequest = useCallback(
    async () => {
      if (id) {
        await updateArticleStatus(
          id,
          ArticleStatusRequestDtoEvent.ACCEPT,
        ).then(() => {
          onHide();
          showSuccessToast(t('article.flowers.confirmAcceptOK'));
          onSuccess?.();
          return true;
        })
          .catch(() => {
            showErrorToast(t('article.flowers.confirmAcceptKO'));
          });
      }
    },
    [id, onHide, onSuccess, t, updateArticleStatus],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: onHide,
          text: t('common.cancel'),
        },
        {
          onClick: acceptRequest,
          text: t('common.accept'),
        },
      ]}
      show={show}
      showCancel={false}
      title={t('article.flowers.confirmAcceptRequestTitle')}
      onHide={onHide}
    >
      <React.Fragment>{t('article.flowers.confirmAcceptRequestText')}</React.Fragment>
    </Modal>
  );
};
