import { useState } from 'react';

import { masterController } from 'config/apiFunus';
import { MasterDataDto, ArticleCategoryTypes } from 'config/apiFunus/generated/data-contracts';

import { GetCategoryByArticleUsingGetHook } from './types';

export const useGetCategoryByArticleUsingGet = (
  errorMessage: (message: string) => void,
): GetCategoryByArticleUsingGetHook => {
  const [articleCategories, setArticleCategories] = useState<MasterDataDto[]>([]);

  const fetchArticleCategories = () => masterController
    .getCategoryByArticleUsingGet(ArticleCategoryTypes.FLOWERS)
    .then((response) => {
      setArticleCategories(response.categories || []);

      return response;
    })
    .catch((error) => {
      errorMessage(error.message);
      throw error;
    });

  return {
    articleCategories,
    fetchArticleCategories,
  };
};
