import { useCallback, useEffect, useState } from 'react';

import { normalizeMasterData } from 'utils/normalizer';

import { getDoctorsByDeathType } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { NormalizedType } from 'models/MasterService';
import { DeathType } from 'models/Record';

type DoctorType = {
  doctors: IMasterDataDto[];
  getDoctor: (code: string | undefined) => IMasterDataDto | undefined
};

const useDoctor = (deathType?: DeathType): DoctorType => {
  const [doctors, setDoctors] = useState<IMasterDataDto[]>([]);
  const [normalizedDoctors, setNormalizedDoctors] = useState<NormalizedType>({});
  const [typeLoaded, setTypeLoaded] = useState<string>();

  const getDoctors = () => {
    if (deathType) {
      getDoctorsByDeathType(deathType)
        .then((response) => {
          setDoctors(response.data as IMasterDataDto[]);
          return response.data;
        })
        .catch(() => {
          setDoctors([]);
          setNormalizedDoctors({});
        });
    } else {
      setDoctors([]);
      setNormalizedDoctors({});
    }
  };

  useEffect(() => {
    if (deathType !== typeLoaded) {
      setTypeLoaded(deathType);
      getDoctors();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deathType]);

  useEffect(() => {
    setNormalizedDoctors(normalizeMasterData(doctors));
  }, [doctors]);

  const getDoctor = useCallback(
    (code: string | undefined) => (code && normalizedDoctors[code]
      ? normalizedDoctors[code]
      : undefined),
    [normalizedDoctors],
  );

  return {
    doctors,
    getDoctor,
  };
};

export default useDoctor;
