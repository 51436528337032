import React, {
  FC, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateManager';

import { useCities } from 'hooks/useCities';
import { useVigilLocation } from 'hooks/useVigilLocation';
import { VigilWorkorder } from 'models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info from './Info';

const VigilInfo: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const { t } = useTranslation();
  const { getVigilLocation } = useVigilLocation(
    (order.info as VigilWorkorder)?.address?.city,
  );
  const { getCityByNormalizedCode } = useCities();

  const details = useMemo(() => {
    const info: VigilWorkorder = (order.info || {}) as VigilWorkorder;

    const startHour = info.entryDate && info.entryTime
      ? `${formatDate(info.entryDate)} ${info.entryTime}`
      : '-';

    const endHour = info.exitDate && info.exitTime
      ? `${formatDate(info.exitDate)} ${info.exitTime}`
      : '-';

    return [
      {
        text: t('common.location'),
        value: getVigilLocation(info.location)?.description || '-',
      },
      {
        text: t('common.address'),
        value: info.address,
      },
      {
        text: t('common.city'),
        value: getCityByNormalizedCode(
          info.address?.country, info.address?.province, info.address?.city,
        )?.description || '-',
      },
      {
        text: t('common.startHour'),
        value: startHour,
      },
      {
        text: t('common.endHour'),
        value: endHour,
      },
    ];
  }, [getCityByNormalizedCode, getVigilLocation, order.info, t]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default VigilInfo;
