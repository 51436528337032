import React, { FC, useEffect, useState } from 'react';

import SelectWithInput, {
  OptionsType,
  SelectWithInputProps,
} from 'components/base/SelectWithInput';

import { i18n } from 'i18n';
import './index.scss';

export enum IdentifierTypes {
  IDENTIFIER = 'identifier',
  CIF = 'cif',
  PASSPORT = 'passport',
  NIE = 'nie'
}

const identifierOptions: OptionsType[] = [
  { label: i18n.t('common.identifier'), value: IdentifierTypes.IDENTIFIER },
  { label: i18n.t('common.cif'), value: IdentifierTypes.CIF },
  { label: i18n.t('common.passport'), value: IdentifierTypes.PASSPORT },
  { label: i18n.t('common.nie'), value: IdentifierTypes.NIE },
];

const identifierOptionsNoCif: OptionsType[] = [
  { label: i18n.t('common.identifier'), value: IdentifierTypes.IDENTIFIER },
  { label: i18n.t('common.passport'), value: IdentifierTypes.PASSPORT },
  { label: i18n.t('common.nie'), value: IdentifierTypes.NIE },
];

type IdentifierSelectorProps = Pick<
  SelectWithInputProps,
  Exclude<keyof SelectWithInputProps, 'options'>
> & {
  showCif?: boolean;
  defaultValue?: string;
  onSelectChange?(value: string | null | undefined): void;
};

const IdentifierSelector: FC<IdentifierSelectorProps> = ({
  style,
  className,
  defaultValue,
  disabled,
  errors,
  name,
  onBlur,
  onChange,
  onClick,
  onSelectChange,
  onFocus,
  placeholder = i18n.t('common.identifierDocument'),
  showCif = true,
  values,
}) => {
  const [optionsAvailable, setOptionsAvailable] = useState(
    showCif ? identifierOptions : identifierOptionsNoCif,
  );

  useEffect(() => {
    if (defaultValue) {
      const foundValue = identifierOptions.find(
        ({ value }) => value === defaultValue,
      );

      if (foundValue) {
        setOptionsAvailable([foundValue]);
      }
    } else {
      setOptionsAvailable(showCif ? identifierOptions : identifierOptionsNoCif);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <SelectWithInput
      className={className}
      disabled={disabled}
      errors={errors}
      name={name}
      options={optionsAvailable}
      placeholder={placeholder}
      style={style}
      values={values}
      onBlur={onBlur}
      onChange={(key, val) => onChange(key, val ? val.toUpperCase() : val)}
      onClick={onClick}
      onFocus={onFocus}
      onSelectChange={(v) => {
        onSelectChange?.(v);
      }}
    />
  );
};
export default IdentifierSelector;
