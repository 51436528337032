import { Form, Formik, FormikProps } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/base/Input';

import { BudgetAssignRecordFormComponent, BudgetAssignRecordFormInputs } from './types';

export const BudgetAssignRecordForm: FC<BudgetAssignRecordFormComponent> = (
  { onChange },
) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        id: '',
      }}
      validate={(values) => {
        onChange(values.id);
        return {};
      }}
      validateOnBlur
      validateOnChange
      validateOnMount
      onSubmit={() => Promise.resolve()}
    >
      {({
        values, setValues,
      }: FormikProps<BudgetAssignRecordFormInputs>) => (
        <Form autoComplete="off">
          <Input
            disabled={false}
            id="record.erpId"
            name="record.erpId"
            placeholder={t('record.erpId')}
            type="text"
            value={values.id}
            onChange={(val) => {
              setValues({
                id: val ? `${val}` : '',
              });
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
