import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';

import { normalizeEntity } from 'utils/normalizer';

import UserRole, { Permission, PermissionGroupDto } from 'models/UserRole';
import type { RootState } from 'store';
import { rolesTypes } from 'types/roles';

export interface RolesAction extends AnyAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: UserRole[] | any;
}

export const addRoles = (): ThunkAction<Promise<void>,
  RootState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AnyAction> => async (dispatch: ThunkDispatch<RootState, any, AnyAction>): Promise<void> => {
  const res = await config.apiFunus.roles.getRoles();
  const roles = res.data;
  dispatch({
    payload: {
      masterData: roles,
      normalized: normalizeEntity<UserRole>(roles, 'id'),
    },
    type: rolesTypes.ADD_ROLES,
  });
  dispatch(addPermissions());
  return Promise.resolve();
};

const sortPermissionGroups = (a: PermissionGroupDto, b: PermissionGroupDto) => {
  if (a.description < b.description) {
    return -1;
  }
  if (a.description > b.description) {
    return 1;
  }
  return 0;
};

const sortPermissions = (a: Permission, b: Permission) => {
  if (
    (a.name.includes('READ') && !b.name.includes('READ'))
    || (!b.name.includes('READ')
      && ((a.name.includes('WRITE') && !b.name.includes('WRITE'))
        || (a.name < b.name && !b.name.includes('WRITE'))))
  ) {
    return -1;
  }
  if (
    (b.name.includes('READ') && !a.name.includes('READ'))
    || (b.name.includes('WRITE') && !a.name.includes('WRITE'))
  ) {
    return 1;
  }
  return a.name > b.name ? 1 : -1;
};

export const addPermissions = (): ThunkAction<Promise<void>,
  RootState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AnyAction> => async (dispatch: ThunkDispatch<RootState, any, AnyAction>): Promise<void> => {
  const res = await config.apiFunus.permission.getAllPermissionGroupList();
  const permissions = res.data;
  const newPerms = [...permissions]
    .map((permission: PermissionGroupDto) => ({
      ...permission,
      permissionDtos: [...permission.permissionDtos].sort(sortPermissions),
    }))
    .sort(sortPermissionGroups);
  dispatch({
    payload: newPerms,
    type: rolesTypes.ADD_PERMISSIONS,
  });
  return Promise.resolve();
};
