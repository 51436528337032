import { BudgetQuestionCodes } from '../../data/budgetQuestionCodes';
import { IArticlesCollection } from '../ArticlesCollection/types';
import { IAudit } from '../Audit/types';
import { IBudgetLocation } from '../BudgetLocation/types';

export interface IBudgetQuestion extends IAudit {
  response?: BudgetResponseCodes;
  code: BudgetQuestionCodes;
  location?: IBudgetLocation | null;
  articles?: IArticlesCollection;
  questionType?: BudgetQuestionTypes | null;
  insuranceCode?: string | null;
  date?: string | null;
  atHome?: boolean;
}

export enum BudgetResponseCodes {
  R1_1 = '1.1',
  R1_2 = '1.2',
  R1_3 = '1.3',
  R1_4 = '1.4',
  R1_5 = '1.5',
  R2_1 = '2.1',
  R2_2 = '2.2',
  R3_1 = '3.1',
  R3_2 = '3.2',
  R4_1 = '4.1',
  R4_2 = '4.2',
  R5_1 = '5.1',
  R5_2 = '5.2',
  R5_3 = '5.3',
  R5_4 = '5.4',
  R6_1 = '6.1',
  R6_2 = '6.2',
  R6_3 = '6.3',
  R6_4 = '6.4',
  R7_1 = '7.1',
  R7_2 = '7.2',
  R7_3 = '7.3',
  R7_4 = '7.4',
  R8_1 = '8.1',
  R8_2 = '8.2',
  R8_3 = '8.3',
  R8_4 = '8.4',
  R9_1 = '9.1',
  R9_2 = '9.2',
  R10_1 = '10.1',
  R10_2 = '10.2',
  R11_1 = '11.1',
  R11_2 = '11.2',
  R12_1 = '12.1',
  R12_2 = '12.2',
  R13_1 = '13.1',
  R13_2 = '13.2',
  R14_1 = '14.1',
  R14_2 = '14.2',
  R15_1 = '15.1',
  R15_2 = '15.2',
  R17_1 = '17.1',
  R17_2 = '17.2',
  R19_1 = '19.1',
  R19_2 = '19.2',
  R22_1 = '22.1',
  R22_2 = '22.2',
  R22_3 = '22.3',
  R21_1 = '21.1',
  R21_2 = '21.2',
  R23_1 = '23.1',
  R23_2 = '23.2',
  R24_1 = '24.1',
  R24_2 = '24.2',
  R25_1 = '25.1',
  R25_2 = '25.2',
  R27_1 = '27.1',
  R27_2 = '27.2',
  R28_1 = '28.1',
  R28_2 = '28.2',
  R29_1 = '29.1',
  R29_2 = '29.2',
  R30_1 = '30.1',
  R30_2 = '30.2',
  R31_1 = '31.1',
  R31_2 = '31.2',
  R31_3 = '31.3',
  R31_4 = '31.4',
  R31_5 = '31.5',
  R36_1 = '36.1',
  R36_2 = '36.2',
  R38_1 = '38.1',
  R38_2 = '38.2',
  R39_1 = '39.1',
  R40_1 = '40.1',
  R40_2 = '40.2',
  R42_1 = '42.1',
  R42_2 = '42.2',
  R43_1 = '43.1',
  R43_2 = '43.2',
  R44_1 = '44.1',
  R44_2 = '44.2',
}

export enum BudgetQuestionTypes {
  CEREMONY_TYPE = 'CEREMONY_TYPE',
  CEREMONY = 'CEREMONY',
  COFFIN = 'COFFIN',
  CREMATION = 'CREMATION',
  DEATH = 'DEATH',
  INSURANCE = 'INSURANCE',
  INTERMENT = 'INTERMENT',
  OBITUARY = 'OBITUARY',
  PACEMAKER = 'PACEMAKER',
  VIGIL = 'VIGIL'
}
