import React, { FC, useEffect } from 'react';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionLabels } from 'modules/budget/hooks/useQuestionLabels';
import { useQuestionOptions } from 'modules/budget/hooks/useQuestionOptions';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes } from 'modules/budget/models/Article/types';
import { dismissServiceId } from 'modules/budget/store/data';

import { galleryQuestionsWithDismissItem } from '../../../data/galleryQuestionsWithDismissItem';
import { ArticleGallery } from '../../commons/ArticleGallery';
import { LoadingItems } from '../../commons/LoadingItems';

interface GallerySelectorComponent {
  onChange: (value: Article[]) => void;
  question: BudgetQuestionCodes;
  allowSelectQuantity: boolean;
  multiselect: boolean;
  value: Article[];
  dismissServiceSelected: boolean;
}

export const GallerySelector:FC<GallerySelectorComponent> = ({
  onChange,
  question,
  allowSelectQuantity,
  multiselect,
  value,
  dismissServiceSelected,
}) => {
  const { dismiss } = useQuestionLabels();

  const { fetchOptions, items, loading } = useQuestionOptions();
  const { GetGalleryAmountStep } = useBudget();

  useEffect(
    () => {
      fetchOptions(question, ArticleCollectionTypes.ARTICLE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question],
  );

  return loading
    ? (<LoadingItems />)
    : (
      <ArticleGallery
        allowSelectQuantity={allowSelectQuantity}
        articles={[
          ...galleryQuestionsWithDismissItem.includes(question)
            ? [{
              code: dismissServiceId,
              description: dismiss(question),
            }]
            : [],
          ...items,
        ]}
        dismissServiceSelected={dismissServiceSelected}
        multiselect={multiselect}
        question={question}
        step={GetGalleryAmountStep(question)}
        value={value}
        onChange={(selection: Article[]) => {
          const dismissSelection = selection
            .find((selectionItem) => selectionItem.code === dismissServiceId);
          if (dismissSelection) {
            onChange([dismissSelection]);
          } else {
            onChange(selection);
          }
        }}
      />
    );
};
