import { Declarant } from "models/Declarant";
import { ContentType, HttpClient, RequestParams } from "../generated/http-client";
import { WhatsappSendRequest } from "./types";

export class DeclarantNotificacionsController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  sendWhatsapp = (data: WhatsappSendRequest, params: RequestParams = {}) =>
    this.http.request<Declarant, void>({
      path: `/notifications/whatsapp/send`,
      method: "POST",
      body: {
        number: data.number,
        language: data.language,
        recordId: data.recordId
      },
      secure: true,
      type: ContentType.Json,
      ...params,
    });

    getNotificationLanguages = (params: RequestParams = {}) =>
      this.http.request<Array<string>, void>({
        path: `/notifications/languages`,
        method: "GET",
        secure: true,
        ...params,
      });
}
