import { FormikProps } from 'formik';
import React, { Fragment, ReactElement } from 'react';

import Input from 'components/base/Input';

import { i18n } from 'i18n';
import { SearchIpProps } from 'models/Ip';

export const getIpFields = (
  {
    errors,
    handleBlur,
    isSubmitting,
    setFieldValue,
    touched,
    values,
  }: FormikProps<SearchIpProps>,
  disabled?: boolean,
): ReactElement => (
  <Fragment>
    <Input
      data-testid="name"
      disabled={isSubmitting || disabled}
      error={touched.name && errors.name}
      name="name"
      placeholder={i18n.t('common.name')}
      type="text"
      value={values.name || ''}
      onBlur={handleBlur}
      onChange={(val) => setFieldValue('name', val)}
    />
    <Input
      data-testid="ip"
      disabled={disabled || isSubmitting}
      error={touched.ip && errors.ip}
      name="ip"
      placeholder={i18n.t('ip.address')}
      type="text"
      value={values.ip || ''}
      onBlur={handleBlur}
      onChange={(val) => setFieldValue('ip', val)}
    />
  </Fragment>
);
