import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGroupCauses } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

interface CountriesHook {
  groupCauses: IMasterDataDto[],
  getGroupCause: (code: string | undefined) => IMasterDataDto
}

export const useGroupCauses = ():CountriesHook => {
  const dispatch = useDispatch();
  const { items, normalized } = useSelector(
    (state: RootState) => ({
      items: state.masterData.groupCauses,
      normalized: state.normalized.groupCauses,
    }),
  );

  const getGroupCause = useCallback(
    (code: string | undefined) => (code && normalized[code]
      ? normalized[code]
      : undefined),
    [normalized],
  );

  useEffect(() => {
    dispatch(getGroupCauses());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getGroupCause,
    groupCauses: items || [],
  };
};
