import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast } from 'utils/toasts';

import { ButtonProps } from 'components/base/Button';
import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import useUsers from 'hooks/useUsers';
import Enquiry, { DashboardEnquiry } from 'models/Enquiry';
import { SimpleUser } from 'models/User';

// eslint-disable-next-line import/no-cycle
import AssignUsers from '../Dashboard/enquiryCard/AssignUsers';

import { EnquiryAssignModalComponent } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EnquiryAssignModal = ({
  show, enquiry, onHide, onSuccess,
}: EnquiryAssignModalComponent) => {
  const { t } = useTranslation();
  const [assignedUsers, setAssignedUsers] = useState<Array<SimpleUser>>([]);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState<string>('');

  const { users } = useUsers([SimpleUserRequestPermissionNames.ENQUIRY_READ]);

  useEffect(() => {
    if (show) {
      const assignedUser = (enquiry as DashboardEnquiry)?.assignedTo
        ? users.find((u: SimpleUser) => u.id === (enquiry as DashboardEnquiry)?.assignedTo)
        : users.find((u: SimpleUser) => u.id === (enquiry as Enquiry)?.advisor?.id);
      setAssignedUsers(assignedUser ? [assignedUser] : []);
    }
  }, [users, enquiry, show]);

  useEffect(() => {
    if (show) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setComment((enquiry as any)?.coordinatorComment || '');
    }
  }, [enquiry, show]);

  const sendAddUsersRequest = useCallback(() => {
    setLoading(true);
    if (enquiry?.id) {
      config.apiFunus.enquiries
        .assignUser(enquiry?.id, assignedUsers.length === 1 ? assignedUsers[0].id : null, comment)
        .then((res) => {
          setLoading(false);
          onHide?.();
          onSuccess?.();
          return res;
        })
        .catch(() => {
          showErrorToast(t('order.usersError'));
          setLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedUsers, comment]);

  const buttonsAddUsersModal: ButtonProps[] = [{
    color: 'primary',
    disabled: false,
    id: 'accept',
    onClick: () => {
      sendAddUsersRequest();
    },
    text: t('common.accept'),
    type: 'button',
  }];

  return (
    <Modal
      buttons={buttonsAddUsersModal}
      className="enquiry-card-modal"
      show={show}
      title={t('user.assignUser')}
      showCancel
      onHide={() => {
        setAssignedUsers([]);
        onHide();
      }}
    >
      {enquiry && show && !loading && (
      <AssignUsers
        assignedUsers={assignedUsers}
        comment={comment}
        enquiry={enquiry}
        setAssignedUsers={setAssignedUsers}
        setComment={setComment}
        userOptions={users}
      />
      )}
      {loading && <Loader />}
    </Modal>
  );
};

EnquiryAssignModal.defaultProps = {
  onSuccess: undefined,
};

export { EnquiryAssignModal };
