import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useQuestionOptions } from 'modules/budget/hooks/useQuestionOptions';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes, IArticle } from 'modules/budget/models/Article/types';

import { OptionSelector } from '../OptionSelector';

import { yesNoValues } from './types';

interface YesNoSelectorComponent {
  question?: BudgetQuestionCodes,
  value?: boolean;
  onChange: (value: boolean | undefined) => void;
  yesCaption?: string;
  noCaption?: string;
}

export const YesNoSelector: FC<YesNoSelectorComponent> = (
  {
    onChange, question, yesCaption, noCaption, value,
  },
) => {
  const { t } = useTranslation();
  const { fetchOptions, items } = useQuestionOptions();
  const [options, setOptions] = useState<Array<IArticle>>([]);

  const [selection, setSelection] = useState<Article[]>([]);

  const mapBooleanToArticleItem = (val: boolean | undefined) => {
    if (val === true) {
      return [new Article({
        amount: 1,
        code: yesNoValues.YES,
        description: '',
      })];
    }
    if (val === false) {
      return [new Article({
        amount: 1,
        code: yesNoValues.NO,
        description: '',
      })];
    }
    return [];
  };

  const mapYesNoArticleItemToBoolean = (
    val: Article[],
  ): boolean | undefined => {
    if (val.length === 1) {
      if (val[0].code === yesNoValues.YES) {
        return true;
      }
      if (val[0].code === yesNoValues.NO) {
        return false;
      }
    }

    return undefined;
  };

  useEffect(
    () => {
      setSelection(mapBooleanToArticleItem(value));
    },
    [value],
  );

  useEffect(
    () => {
      if (!question) {
        setOptions([
          {
            code: yesNoValues.YES,
            description: yesCaption || t('common.yes'),
          },
          {
            code: yesNoValues.NO,
            description: noCaption || t('common.no'),
          },
        ]);
      }
    },
    [noCaption, question, t, yesCaption],
  );

  useEffect(
    () => {
      if (items.length !== 0 && question) {
        setOptions(items);
      }
    },
    [items, question],
  );

  useEffect(
    () => {
      if (question) {
        fetchOptions(question, ArticleCollectionTypes.ARTICLE);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question],
  );

  return (
    <Row className="f-yes-no-selector" justifyContent="space-around" style={{ width: '100%' }}>
      <OptionSelector
        items={options}
        value={selection}
        onChange={(val) => onChange(mapYesNoArticleItemToBoolean(val))}
      />
    </Row>
  );
};
