const qr = {
  coffinQr: 'QR Taüt',
  personalItemsQr: 'QR Objectes personals',
  qr: 'QR Polsera',
  search: 'Cercar consultes',
  title: 'Consultes',
  urnQr: 'QR Urna',
};

export default qr;
