import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { BudgetInvoice } from '../budgetInvoice';

import { BudgetInvoiceModalComponent } from './types';

import './index.scss';

export const BudgetInvoiceModal: FC<BudgetInvoiceModalComponent> = ({
  onCancel, show, budgetId, onSuccess, erpId,
}) => {
  const { t } = useTranslation();
  const {
    InvoiceBudget,
    invoiceArticlesLoading,
    invoiceArticles,
    invoiceBudgetLoading,
  } = useBudget();

  const [acceptEnabled, setAcceptEnabled] = useState<boolean>(false);

  useEffect(
    () => {
      const articlesWithPendingData = Boolean(invoiceArticles
        .find(
          (article) => !article.client || !article.provider,
        ));

      setAcceptEnabled(!invoiceArticlesLoading
        && !invoiceBudgetLoading
        && invoiceArticles.length !== 0
        && !articlesWithPendingData);
    },
    [invoiceArticles, invoiceArticlesLoading, invoiceBudgetLoading],
  );

  return (
    <Modal
      buttons={[
        {
          onClick: () => {
            onCancel();
          },
          text: t('common.close'),
        },
        {
          disabled: !acceptEnabled,
          onClick: () => {
            InvoiceBudget(budgetId)
              .then((response) => {
                onSuccess();
                return response;
              })
              .catch(() => false);
          },
          text: t('common.accept'),
        },
      ]}
      className="f-budget-invoice-modal"
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.actions.invoice')}
      onHide={onCancel}
    >
      {show && (
      <BudgetInvoice
        budgetId={budgetId}
        erpId={erpId}
      />
      )}
    </Modal>
  );
};
