import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useRecord } from 'hooks/useRecord';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { RecordInfoDto } from '../../../../../config/apiFunus/record/types';
import { BudgetAssignRecordForm } from '../budgetAssignRecordForm';

import { BudgetAssignRecordModalComponent } from './types';

export const BudgetAssignRecordModal: FC<BudgetAssignRecordModalComponent> = (
  {
    show, onCancel, budgetId, onSuccess,
  },
) => {
  const { t } = useTranslation();
  const { getInfo } = useRecord();
  const { AssignRecord } = useBudget();

  const [id, setId] = useState<string>();
  const [recordInfo, setRecordInfo] = useState<RecordInfoDto>();

  return (
    <Modal
      buttons={[
        {
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        ...id && !recordInfo ? [{
          disabled: !id,
          onClick: () => {
            if (id) {
              getInfo(Number(id))
                .then((info) => setRecordInfo(info))
                .catch(() => false);
            }
          },
          text: t('budget.actions.assignRecord'),
        }] : [],
        ...recordInfo ? [{
          disabled: !recordInfo,
          onClick: () => {
            AssignRecord({
              erpId: recordInfo.id,
              id: budgetId,
            })
              .then((response) => {
                onSuccess();

                return response;
              })
              .catch(() => false);
          },
          text: t('budget.actions.assignRecord'),
        }] : [],
      ]}
      className="appointment-modal"
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.actions.assignRecord')}
      onHide={() => false}
    >
      <BudgetAssignRecordForm
        onChange={(erpId) => {
          setRecordInfo(undefined);
          setId(erpId);
        }}
      />
      {recordInfo
        ? (
          <div>
            {t('budget.actions.confirmAssignRecord', {
              firstSurname: recordInfo.decesasedFirstSurname,
              name: recordInfo.decesasedName,
              secondSurname: recordInfo.decesasedSecondSurname,
            })}
          </div>
        )
        : <React.Fragment />}
    </Modal>
  );
};
