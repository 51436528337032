import { useCallback, useState } from 'react';

import { masterController } from 'config/apiFunus';
import { Supplier } from 'config/apiFunus/generated/data-contracts';

import { Provider } from '../Provider';

import { GetArticleSupplierUsingGetHook } from './types';

export const useGetArticleSupplierUsingGet = (): GetArticleSupplierUsingGetHook => {
  const [articleProviders, setArticleProviders] = useState<Provider[]>([]);

  const fetchArticleProviders = useCallback(
    (code: string) => masterController
      .getArticleSupplierUsingGet(code)
      .then((response) => {
        const mapToLocalSupplier = (
          supplier: Supplier,
        ): {
          code: string | undefined;
          description: string | undefined;
          isDefault: boolean | undefined;
          email: string | undefined;
        } => ({
          code: supplier.id,
          description: supplier.name,
          email: supplier.email,
          isDefault: supplier.selected,
        });

        const suppliersList = response.suppliers
          ? response.suppliers
            .map(mapToLocalSupplier)
          : [];
        setArticleProviders(suppliersList);

        return response;
      })
      .catch((error) => {
        throw error;
      }),
    [],
  );

  return {
    articleProviders,
    fetchArticleProviders,
  };
};
