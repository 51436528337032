import { AxiosError, AxiosResponse } from 'axios';
import {
  FormikErrors, FormikHelpers, FormikProps, FormikValues,
} from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { showErrorToast } from 'utils/toasts';

import BackButton from 'components/base/BackButton';
import FormGenerator from 'components/base/FormGenerator';
import Loader from 'components/base/Loader';
import StepsForm from 'components/base/StepsForm';

import { ConflictError } from 'config/apiErrors/ConflictError';

import { StepButtonProps, FormStep } from '../StepsForm/types';

import './index.scss';

interface TitleType<T> {
  add: string;
  edit: string | ((values: T) => string);
  see: string | ((values: T) => string);
  clone?: string;
}

export interface IEdit {
  id: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
}

type FormPageProps<T> = {
  backUrl?: string;
  buttons?: (values: FormikValues) => StepButtonProps[];
  cleanBeforeSubmit?: (data: T, isEdit: boolean, isClone: boolean) => T;
  clone?: boolean | undefined;
  editing?: boolean | undefined;
  fields?: (props: FormikProps<T>, disabled?: boolean) => ReactElement;
  id?: string | undefined;
  initialValues: T;
  getFunction?: (id: string | number) => Promise<AxiosResponse>;
  formatReceived?: (data: T, isEdit: boolean, isClone: boolean) => T;
  onError(error: AxiosError, sentParams?: T): void;
  onGet?: (params: T) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave(params?: T, sentParams?: any): void;
  redirectOnSave?: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveFunction?: (params: any) => Promise<AxiosResponse>;
  steps?: (values: T) => FormStep<T>[];
  stepsForm?: boolean | undefined;
  title?: TitleType<T> | string;
  subtitle?: (values: T) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateFunction?: (params: any) => Promise<AxiosResponse>;
  validateForm?: (values: T) => Promise<FormikErrors<FormikValues>>;
  validateOnBlur?: boolean | undefined;
  validateOnChange?: boolean | undefined;
};

const FormPage = <T extends Record<string, unknown>>({
  backUrl,
  buttons,
  cleanBeforeSubmit,
  clone,
  editing,
  fields,
  formatReceived,
  id,
  initialValues,
  getFunction,
  onError,
  onGet,
  onSave,
  redirectOnSave,
  saveFunction,
  steps,
  stepsForm,
  title,
  subtitle,
  updateFunction,
  validateForm,
  validateOnBlur,
  validateOnChange,
}: FormPageProps<T>): ReactElement => {
  const [entity, setEntity] = useState<T | undefined>();
  const [rawEntity, setRawEntity] = useState<T | undefined>();
  const [initialLoad, setInitialLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [titleValue, setTitleValue] = useState<string |((vals: T) => string)>('');
  const history = useHistory();
  const { t } = useTranslation();

  const formatEntity = (ent: T) => {
    setEntity(
      formatReceived
        ? formatReceived(ent, editing as boolean, clone as boolean)
        : ent,
    );
    setRawEntity(ent);
  };

  const onSaveForm = (values: T, actions: FormikHelpers<T>) => {
    if (!editing || updateFunction) {
      const apiCall = clone || (!editing && updateFunction) ? saveFunction : updateFunction;
      const rawParams = id && id !== 'add' ? { id, values } : values;
      const params = cleanBeforeSubmit
        ? cleanBeforeSubmit(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rawParams as any,
            editing as boolean,
            clone as boolean,
        )
        : rawParams;

      if (apiCall) {
        setLoading(true);
        apiCall(params)
          .then((response) => {
            onSave(response.data, params);
            actions.setValues(response.data, false);
            formatEntity(
              (id && id !== 'add' ? rawParams.values : response.data) as T,
            );
            setLoading(false);
            if (redirectOnSave) {
              setRedirect(true);
            } else {
              actions.setSubmitting(false);
            }
            return undefined;
          })
          .catch((error) => {
            if (error instanceof ConflictError) {
              showErrorToast(t(`error.${error.message}`));
            } else {
              onError(error, params as T);
            }
            setLoading(false);
            actions.setSubmitting(false);
          });
      }
    }
  };

  const loadEntity = () => {
    if (getFunction && id) {
      setInitialLoad(false);
      getFunction(id)
        .then((res) => {
          formatEntity(res.data);
          onGet?.(res.data);
          setLoading(false);
          setInitialLoad(true);
          return res.data;
        })
        .catch((error) => {
          setLoading(false);
          setInitialLoad(true);
          showErrorToast(error.message ? error.message : t('common.errorText'));
        });
    }
  };

  useEffect(() => {
    if (!entity && id && id !== 'add' && getFunction) {
      loadEntity();
    } else {
      setInitialLoad(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getFunction]);

  useEffect(() => {
    let res: string | ((vals: T) => string) = '';

    if (title && typeof title === 'string') {
      res = title;
    } else if (title && typeof title !== 'string') {
      if (clone) {
        res = title.clone as string;
      } else if ((id === 'add' || id === undefined) && !editing) {
        res = title.add;
      } else {
        res = !editing ? title.see : title.edit;
      }
    }

    setTitleValue(() => res);
  }, [editing, id, title, clone]);

  useEffect(() => {
    if (clone && !!entity?.name && formatReceived) {
      setEntity(entity);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clone, entity]);

  useEffect(() => {
    if (redirect) {
      history.goBack();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  if (redirect && backUrl && !loading) {
    return <Redirect to={backUrl} />;
  }

  const disabled = !(id === 'add' || id === undefined) && !editing;

  return (
    <div className="form-page">
      {(loading || !initialLoad) && <Loader />}
      <BackButton to={backUrl} />
      {!stepsForm && initialLoad && fields && (
        <FormGenerator<T>
          disabled={disabled}
          editing={editing}
          fields={fields}
          initialValues={entity || initialValues}
          showSave={!disabled}
          title={titleValue}
          validateForm={validateForm}
          validateOnBlur={validateOnBlur}
          validateOnChange={validateOnChange}
          onSave={onSaveForm}
        />
      )}
      {stepsForm && initialLoad && (
        <StepsForm<T>
          buttons={buttons}
          disabled={disabled}
          initialValues={entity || initialValues}
          rawEntity={rawEntity}
          steps={steps || (() => [])}
          subtitle={subtitle}
          title={titleValue}
          validateForm={validateForm}
          validateOnBlur={validateOnBlur}
          validateOnChange={validateOnChange}
          onButtonCallback={loadEntity}
          onSave={onSaveForm}
        />
      )}
    </div>
  );
};

FormPage.defaultProps = {
  backUrl: undefined,
  buttons: undefined,
  cleanBeforeSubmit: undefined,
  clone: false,
  editing: false,
  fields: undefined,
  formatReceived: undefined,
  getFunction: undefined,
  id: undefined,
  onGet: undefined,
  redirectOnSave: true,
  saveFunction: undefined,
  steps: [],
  stepsForm: false,
  subtitle: undefined,
  title: undefined,
  updateFunction: undefined,
  validateForm: undefined,
  validateOnBlur: undefined,
  validateOnChange: undefined,
};

export default FormPage;
