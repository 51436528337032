import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { isBoolean } from 'utils/helpers';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { YesNoQuestionForm, YesNoQuestionResponse } from './form';

interface YesNoQuestionComponent {
  question: BudgetQuestionCodes
}

export const YesNoQuestion: FC<YesNoQuestionComponent> = ({ question }) => {
  const { RespondQuestion } = useBudget();
  const [responseValue, setResponseValue] = useState<boolean | null>(null);
  const [autorespond, setautorespond] = useState<boolean>(false);

  const response = useCallback(
    ({ gotoSummary }) => {
      RespondQuestion({
        gotoSummary,
        question,
        response: {
          yesNo: Boolean(responseValue),
        },
      });
    },
    [RespondQuestion, question, responseValue],
  );

  const onChange = useCallback((responseData: YesNoQuestionResponse) => {
    if (isBoolean(responseData.responseValue)) {
      setResponseValue(Boolean(responseData.responseValue));
    } else {
      setResponseValue(null);
    }
    setautorespond(responseData.autorespond);
  }, []);

  useEffect(
    () => {
      if (autorespond && isBoolean(responseValue)) {
        setautorespond(false);
        response({ gotoSummary: false });
      }
    },
    [autorespond, responseValue, response],
  );

  return (
    <React.Fragment>
      <QHeader question={question} />
      <YesNoQuestionForm
        question={question}
        onChange={onChange}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{
              yesNo: Boolean(responseValue),
            }}
          />
          <QConfirmButton
            disabled={responseValue === null}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
