import { useCallback, useEffect, useState } from 'react';

import { getDeathLocations } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';

export type DeathLocationType = {
  deathLocations: IMasterDataDto[];
  getDeathLocationByCode: (code?: string) => IMasterDataDto | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchDeathLocations: (code: string) => Promise<any>;
};

export const useDeathLocation = (cityCode?: string): DeathLocationType => {
  const [items, setItems] = useState<IMasterDataDto[]>([]);

  const fetchDeathLocations = async (code: string) => getDeathLocations(code)
    .then((response) => {
      setItems(response.data as IMasterDataDto[]);
      return response.data;
    })
    .catch(() => {
      setItems([]);
    });

  const getDeathLocation = async () => {
    await getDeathLocations(cityCode)
      .then((response) => {
        setItems(response.data as IMasterDataDto[]);
        return response.data;
      })
      .catch(() => {
        setItems([]);
      });
  };

  useEffect(() => {
    getDeathLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  const getDeathLocationByCode = useCallback(
    (code?: string) => (items.find((item) => item.code === code)),
    [items],
  );

  return {
    deathLocations: items,
    fetchDeathLocations,
    getDeathLocationByCode,
  };
};
