import { useCallback, useEffect, useState } from 'react';

type WindowSizeType = {
  height?: number | undefined;
  width?: number | undefined;
  smOrSmaller: boolean;
  smOrBigger: boolean;
  mdOrSmaller: boolean;
  mdOrBigger: boolean;
  lgOrSmaller: boolean;
  lgOrBigger: boolean;
  xlOrSmaller: boolean;
  xlOrBigger: boolean;
};

const SCREEN_SM = 576;
const SCREEN_MD = 768;
export const SCREEN_LG = 992;
export const SCREEN_XL = 1200;

const useWindowSize: () => WindowSizeType = () => {
  const [mounted, setMounted] = useState<boolean>(true);
  useEffect(() => () => setMounted(false), []);

  const [windowSize, setWindowSize] = useState<WindowSizeType>({
    height: undefined,
    lgOrBigger: false,
    lgOrSmaller: false,
    mdOrBigger: false,
    mdOrSmaller: false,
    smOrBigger: false,
    smOrSmaller: false,
    width: undefined,
    xlOrBigger: false,
    xlOrSmaller: false,
  });

  const handleResize = useCallback(() => {
    const setSizes = () => {
      if (mounted) {
        setWindowSize({
          height: window.innerHeight,
          lgOrBigger: window.innerWidth >= SCREEN_LG,
          lgOrSmaller: window.innerWidth <= SCREEN_LG,
          mdOrBigger: window.innerWidth >= SCREEN_MD,
          mdOrSmaller: window.innerWidth <= SCREEN_MD,
          smOrBigger: window.innerWidth >= SCREEN_SM,
          smOrSmaller: window.innerWidth <= SCREEN_SM,
          width: window.innerWidth,
          xlOrBigger: window.innerWidth >= SCREEN_XL,
          xlOrSmaller: window.innerWidth <= SCREEN_XL,
        });
      }
    };
    setSizes();

    setTimeout(() => {
      if (window.innerWidth !== windowSize.width) {
        setSizes();
      }
    }, 200);
  }, [mounted, windowSize.width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};

export default useWindowSize;
