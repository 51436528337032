import { AxiosError } from "axios";

export class EkonError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    if (typeof error.response?.data === 'object') {
      try {
        const decoder = new TextDecoder('utf-8');
        const uint8Array = new Uint8Array(error.response?.data);
        const message = decoder.decode(uint8Array);
        this.message = message;
      } catch { }
    } else if (typeof error.response?.data === 'string') {
      this.message = error.response?.data;
    }

    this.name = "EkonError";
  }
}
