import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q2Form } from './form';

export const Q2: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q2;
  const { RespondQuestion } = useBudget();
  const [responseValue, setResponseValue] = useState<boolean | null>(null);

  useEffect(
    () => {
      setResponseValue(true);
    },
    [],
  );

  const response = useCallback(({ gotoSummary }) => {
    RespondQuestion({
      gotoSummary,
      question,
      response: {
        yesNo: Boolean(responseValue),
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseValue]);

  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q2Form
        question={question}
        onChange={(responseData: boolean) => {
          setResponseValue(responseData);
        }}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <QConfirmButton
          disabled={responseValue === null}
          question={question}
          onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
        />
      </Row>
    </React.Fragment>
  );
};
