import React from 'react';

import { formatDate, formatHour } from 'utils/dateManager';

import { displayNoValue } from 'components/base/NoData';

export type WorkshopCardHeaderDetail = {label: string, value: string}

type CardDetailsProps = {
  details: WorkshopCardHeaderDetail[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CardDetails = ({ details }: CardDetailsProps) => (
  <div className="workshop-card-header-detail-data">
    {details.map((item: WorkshopCardHeaderDetail) => (
      <div key={`${item.label}-${item.value}`} className="workshop-card-header-detail-data-item">
        <span>{`${item.label}:`}</span>
        <span>{' '}</span>
        <span>{item.value}</span>
      </div>
    ))}
  </div>
);

export const displayDate = (
  date: Date | string,
): string => (date ? formatDate(date) : displayNoValue);

export const displayHour = (
  date: Date | string,
): string => (date ? formatHour(date) : displayNoValue);
