import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { normalizeMasterData } from 'utils/normalizer';

import { getCemetery, getCemeteryByCity } from 'actions/masterData';

import { CemeteryType } from 'models/MasterData';
import { RootState } from 'store';

type CemeteryHook = {
  cemeteries: CemeteryType[];
  normalizedCemeteries: {
    [key: string]: CemeteryType
  };
  getCemetery: (
    code: string | undefined) => CemeteryType | undefined;
  cityCemeteries: CemeteryType[];
};

const useCemetery = (cityCode?: string): CemeteryHook => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [normalizedCemeteries, setNormalizedCemeteries] = useState<any>({});
  const [cemeteriesByCity, setCemeteriesByCity] = useState([]);

  const [cityCemeteries, setCityCemeteries] = useState([]);
  const { cemeteries } = useSelector((state: RootState) => state.masterData);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const findByCityCode = (list: any, code: any) => list.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cemeteryByCity: any) => cemeteryByCity.cityCode === code,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (cityCode !== cityLoaded) {
      setCityLoaded(cityCode);
      getCemetery(cityCode)
        .then((res) => setCityCemeteries(res.data))
        .catch(() => false);

      dispatch(getCemeteryByCity(cityCode));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  const getCemeteryByCityCode = useCallback(
    (code) => {
      const cemeteriesByCityList = findByCityCode(cemeteries, cityCode);
      return cemeteriesByCityList
        ? cemeteriesByCityList.cemeteries.find((i: CemeteryType) => i.code === code)
        : undefined;
    },
    [cemeteries, cityCode],
  );

  useEffect(() => {
    const cemeteriesByCityList = findByCityCode(cemeteries, cityCode);
    if (cemeteriesByCityList) {
      setNormalizedCemeteries(
        normalizeMasterData(cemeteriesByCityList.cemeteries),
      );
    }
  }, [cemeteries, cityCode]);

  useEffect(() => {
    const cemeteriesByCityList = findByCityCode(cemeteries, cityCode);

    setCemeteriesByCity(
      cemeteriesByCityList ? cemeteriesByCityList.cemeteries : [],
    );
  }, [cemeteries, cityCode]);

  return {
    cemeteries: cemeteriesByCity,
    cityCemeteries,
    getCemetery: getCemeteryByCityCode,
    normalizedCemeteries,
  };
};

export default useCemetery;
