import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import { useSanitaryPractices } from 'hooks/useSanitaryPractice';
import { SanitaryPracticeBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, displayDate, displayHour, WorkshopCardHeaderDetail,
} from './CardDetails';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SanitaryPracticeDetails = ({ order }: { order: SanitaryPracticeBaseWorkOrder }) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);
  const { getSanitaryPractice } = useSanitaryPractices();

  useEffect(() => {
    const sanitaryPracticeDetails = [{
      label: t('common.startDate'),
      value: displayDate(order.startDate),
    },
    {
      label: t('common.startHour'),
      value: displayHour(order.startTime),
    },
    {
      label: t('common.type'),
      value: getSanitaryPractice(order.sanitaryType)?.description || displayNoValue,
    }];

    setDetails(sanitaryPracticeDetails);
  }, [
    order,
    t,
    getSanitaryPractice,
  ]);

  return (<CardDetails details={details} />);
};
