import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { useBudget } from 'modules/budget/hooks/useBudget';
import { Article } from 'modules/budget/models/Article';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { GalleryQuestionForm } from './form';
import { GalleryQuestionResponse } from './form/types';
import { GalleryQuestionComponent } from './types';

export const GalleryQuestion: FC<GalleryQuestionComponent> = (
  { question, allowSelectQuantity, multiselect },
) => {
  const { RespondQuestion } = useBudget();

  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [dimissService, setDimissService] = useState<boolean>(false);
  const [autorespond, setautorespond] = useState<boolean>(false);

  const response = useCallback(
    ({ gotoSummary }) => {
      RespondQuestion({
        gotoSummary,
        question,
        response: {
          articles: selectedArticles,
        },
      });
    },
    [RespondQuestion, question, selectedArticles],
  );

  const onChange = useCallback(
    (responseData: GalleryQuestionResponse) => {
      setDimissService(responseData.dimissService);
      setSelectedArticles(responseData.articles);
      setautorespond(responseData.autorespond);
    },
    [],
  );

  const canRespond = useMemo(
    () => dimissService || selectedArticles.length !== 0,
    [dimissService, selectedArticles.length],
  );

  useEffect(
    () => {
      if (autorespond && canRespond) {
        setautorespond(false);
        response({ gotoSummary: false });
      }
    },
    [autorespond, canRespond, response],
  );
  return (
    <React.Fragment>
      <QHeader question={question} />
      <GalleryQuestionForm
        allowSelectQuantity={allowSelectQuantity}
        multiselect={multiselect}
        question={question}
        onChange={onChange}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{
              articles: selectedArticles,
            }}
          />
          <QConfirmButton
            disabled={!canRespond}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
