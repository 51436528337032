import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { showInfoToast, showSuccessToast } from 'utils/toasts';

import Button from 'components/base/Button';

import { ScanQrModal } from 'components/scanQrModal';

import { ArticleInfoResponseDtoStatus, ArticleStatusRequestDtoEvent, ArticleInfoResponseDtoChannel } from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';
import { useFeatures } from 'hooks/useFeatures/useFeatures';

import { AddDeliveryPhotoModal } from '../ArticlePhoto/AddDeliveryPhotoModal';
import { ArticleDetailModal } from '../articles/articleDetailModal';
import './index.scss';

type ArticleInfoComponent = Record<string, unknown>
export const ArticleInfo: FC<ArticleInfoComponent> = () => {
  const { t } = useTranslation();
  const [qr, setQr] = useState<string>();
  const [showScanQrModal, setShowScanQrModal] = useState<boolean>(true);
  const [showArticleDetailModal, setShowArticleDetailModal] = useState<boolean>(false);
  const [showTakeDeliveredPhoto, setShowTakeDeliveredPhoto] = useState<boolean>(false);
  const {
    articleDetail, getArticleDetail, updateArticleStatus, clearArticleDetail,
  } = useArticles();
  const { isArticleSupplier } = useFeatures();

  useEffect(
    () => {
      if (articleDetail) {
        setShowArticleDetailModal(true);
      }
    },
    [articleDetail],
  );

  useEffect(
    () => {
      if (qr) {
        getArticleDetail(qr)
          .catch(() => false);
        setQr(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qr],
  );

  const ToReceived = useCallback(
    () => {
      if (articleDetail?.idArticle) {
        updateArticleStatus(
          articleDetail.idArticle,
          ArticleStatusRequestDtoEvent.QR_SCAN,
        )
          .then(() => {
            showSuccessToast(t('article.flowers.statusUpdatedOk'));
            setShowArticleDetailModal(false);
            clearArticleDetail();
            return true;
          })
          .catch(() => {
            clearArticleDetail();
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleDetail?.idArticle, t, updateArticleStatus],
  );

  const ToFinishWithoutPhoto = useCallback(
    () => {
      if (articleDetail?.idArticle) {
        updateArticleStatus(
          articleDetail.idArticle,
          ArticleStatusRequestDtoEvent.QR_SCAN,
        )
          .then(() => {
            showSuccessToast(t('article.flowers.statusUpdatedOk'));
            setShowArticleDetailModal(false);

            return true;
          })
          .catch(() => false);
      }
    },
    [articleDetail?.idArticle, t, updateArticleStatus],
  );

  const ToFinishedSupplier = useCallback(
    (base64: string) => {
      if (articleDetail?.idArticle) {
        updateArticleStatus(
          Number(articleDetail.idArticle),
          ArticleStatusRequestDtoEvent.QR_SCAN,
          base64,
        )
          .then(() => {
            showSuccessToast(t('article.flowers.statusUpdatedOk'));
            setShowTakeDeliveredPhoto(false);
            clearArticleDetail();
            return true;
          })
          .catch(() => {
            clearArticleDetail();
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleDetail?.idArticle, t, updateArticleStatus],
  );

  const updateStatus = useCallback(
    (): void => {
      if (articleDetail?.idArticle) {
        if (articleDetail.status === ArticleInfoResponseDtoStatus.IN_PROGRESS) {
          ToReceived();
        } else if (articleDetail.status === ArticleInfoResponseDtoStatus.RECEIVED) {
          if (isArticleSupplier() || articleDetail.channel === ArticleInfoResponseDtoChannel.WEB) {
            setShowArticleDetailModal(false);
            setShowTakeDeliveredPhoto(true);
          } else {
            ToFinishWithoutPhoto();
          }
        } else if (articleDetail.status === ArticleInfoResponseDtoStatus.PENDING) {
          showInfoToast(t('article.flowers.pendingStatusText'));
          setShowArticleDetailModal(false);
        }
      }
    },
    [
      ToFinishWithoutPhoto,
      ToReceived,
      articleDetail?.channel,
      articleDetail?.idArticle,
      articleDetail?.status,
      isArticleSupplier,
      t,
    ],
  );

  const onScanSuccess = useCallback(
    (scannedQR: string): void => {
      setShowScanQrModal(false);
      setQr(scannedQR);
    },
    [],
  );

  return (
    <React.Fragment>
      <div className="article-info-mail">
        {t('article.articleDetail')}
        <div>
          <Button
            color="primary"
            text={t('menu.scan')}
            onClick={() => {
              setShowScanQrModal(true);
            }}
          />
        </div>
      </div>
      <ScanQrModal
        show={showScanQrModal}
        title={t('article.articleQr')}
        onCancel={() => {
          setShowScanQrModal(false);
        }}
        onScanSuccess={onScanSuccess}
      />
      <ArticleDetailModal
        articleDetail={articleDetail}
        show={showArticleDetailModal}
        onAccept={() => {
          updateStatus();
        }}
        onCancel={() => {
          setShowArticleDetailModal(false);
          clearArticleDetail();
        }}
      />
      <AddDeliveryPhotoModal
        show={showTakeDeliveredPhoto}
        onAccept={ToFinishedSupplier}
        onCancel={() => {
          setShowTakeDeliveredPhoto(false);
          clearArticleDetail();
        }}
      />

    </React.Fragment>
  );
};
