import React, {
  FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/base/Tooltip';

import { MasterDataDto } from 'config/apiFunus/generated/data-contracts';
import { useBudget } from 'modules/budget/hooks/useBudget';

import Select from '../../../../components/base/Select';

import { ArticleProviderSelectorComponent } from './types';

export const ArticleProviderSelector: FC<ArticleProviderSelectorComponent> = (
  { article, idBudget },
) => {
  const { t } = useTranslation();
  const {
    SetInvoiceArticleProvider,
    invoiceArticlesLoading,
  } = useBudget();

  const [selectedProvider, setSelectedProvider] = useState<string>();

  useEffect(
    () => {
      if (article.provider) {
        setSelectedProvider(article.provider);
      }
    },
    [article],
  );

  useEffect(
    () => {
      if (article.providers.length === 1
        && !article.provider) {
        setSelectedProvider(article.providers[0].code);
        SetInvoiceArticleProvider(article, article.providers[0].code, idBudget);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [article, idBudget],
  );

  return (
    <Select<MasterDataDto>
      key="supplier"
      disabled={invoiceArticlesLoading}
      getLabel={({ description }) => description || ''}
      getValue={({ code }) => code || ''}
      itemContent={(
        option,
      ) => (<Tooltip content={option.description}><div>{option.description}</div></Tooltip>)}
      name="supplier"
      options={article.providers}
      placeholder={t('common.provider')}
      title={article.providers.find((supplier) => supplier.code === selectedProvider)?.description}
      value={article.providers.find((supplier) => supplier.code === selectedProvider)}
      searchable
      onChange={(val) => {
        setSelectedProvider(val?.code);
        SetInvoiceArticleProvider(article, val?.code ? val?.code : null, idBudget);
      }}
    />
  );
};
