import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Budget } from 'modules/budget/models/Budget';
import { ClearQ10Simulation, FetchQ10Simulation } from 'modules/budget/store/budgetActions';
import { BudgetState } from 'modules/budget/store/budgetReducer/types';
import { RootState } from 'store';

import { BudgetQ10SimulationSummaryHelper } from './types';

export const BudgetQ10SimulationSummaryHook = (
  articleId: string,
): BudgetQ10SimulationSummaryHelper => {
  const dispatch = useDispatch();
  const [simulatedBudget, setSimulatedBudget] = useState<Budget | undefined>();
  const {
    Q10Simulations,
  } = useSelector((state: RootState) => state.budget as BudgetState);

  useEffect(
    () => {
      if (articleId) {
        setSimulatedBudget(Q10Simulations.get(articleId) as Budget);
      }
    },
    [Q10Simulations, articleId],
  );

  useEffect(
    () => {
      if (articleId) {
        dispatch(FetchQ10Simulation({ articleId }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleId],
  );

  const clearQ10Simulation = useCallback(
    (code: string) => {
      dispatch(ClearQ10Simulation({ articleId: code }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    clearQ10Simulation,
    simulatedBudget,
  };
};
