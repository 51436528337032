import { useCallback, useEffect, useState } from 'react';

import config from 'config';

import { objectsAreDifferent } from 'utils/helpers';
import { normalizeEntity } from 'utils/normalizer';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { IMasterDataDto } from 'models/MasterData';
import { NormalizedEntity } from 'models/MasterService';
import { SimpleUser } from 'models/User';

type UsersHook = {
  loading: boolean;
  normalizedUsers: NormalizedEntity<SimpleUser>;
  users: SimpleUser[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUser: (code: any) => SimpleUser | undefined
  fetchWorkorderAssignableUsers: (code: string) => void;
  workorderAssignableUsers: SimpleUser[]
};

const useUsers = (permissions?: SimpleUserRequestPermissionNames[]): UsersHook => {
  const [permissionsLoaded, setPermissionsLoaded] = useState<SimpleUserRequestPermissionNames[]>();
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [normalizedUsers, setNormalizedUsers] = useState<any>({});
  const [users, setUsers] = useState<SimpleUser[]>([]);

  const [assignableUsers, setAssignableUsers] = useState<SimpleUser[]>([]);

  const fetchWorkorderAssignableUsers = async (code: string) => {
    if (code) {
      const response = await config.apiFunus.workOrder.getAssignableUsers(code);
      setAssignableUsers(response.data);
    }
  };

  useEffect(() => {
    if (objectsAreDifferent(permissions, permissionsLoaded)) {
      setLoading(true);
      setPermissionsLoaded(permissions);
      config.apiFunus.users
        .getSimpleUsers({ permissionNames: permissions || [] })
        .then((response) => {
          if (response?.data) {
            setUsers(
              response.data.sort((a: SimpleUser, b: SimpleUser) => a.id - b.id),
            );
            setLoading(false);
          }

          return response;
        })
        .catch(() => {
          setUsers([]);
          setLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = useCallback(
    (code: string) => (normalizedUsers && normalizedUsers[code]
      ? normalizedUsers[code]
      : undefined),
    [normalizedUsers],
  );

  useEffect(() => {
    setNormalizedUsers(normalizeEntity<SimpleUser>(users, 'id'));
  }, [users]);

  return {
    fetchWorkorderAssignableUsers,
    getUser,
    loading,
    normalizedUsers,
    users,
    workorderAssignableUsers: assignableUsers,
  };
};

export default useUsers;

export const mapSimpleUserToMasterData = (e: SimpleUser): IMasterDataDto => ({
  code: e.id.toString(),
  description: `${e.name || ''} ${e.firstSurname || ''} ${e.secondSurname || ''}`,
});
