import { Classable } from '../../types/helpers';

export enum SeparatorStyles {
  NONE = 'NONE',
  BORDER_ONE = 'BORDER_ONE'
}

export interface SeparatorComponent extends Classable {
  layout?: SeparatorStyles;
}
