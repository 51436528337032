import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Article } from 'modules/budget/models/Article';
import { NicheOwnershipTypes } from 'modules/budget/store/data';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';

import { Q31Form, Q31FromSteps, Q31Response } from './form';

import './index.scss';

export const Q31: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q31;
  const { RespondQuestion } = useBudget();
  const [alreadyAnswered, setAlreadyAnswered] = useState<boolean>(false);
  const [canRespond, setCanRespond] = useState<boolean>(false);

  const [selectedOwnership, setSelectedOwnership] = useState<NicheOwnershipTypes | null>();
  const [isNameChange, setIsNameChange] = useState<boolean>(false);
  const [selectedConcession, setSelectedConcession] = useState<Article[]>([]);
  const [selectedTax, setSelectedTax] = useState<Article[]>([]);
  const [selectedTransfer, setSelectedTransfer] = useState<Article[]>([]);
  const [dismissConcessions, setDismissConcessions] = useState<boolean>();
  const [dismissTaxes, setDismissTaxes] = useState<boolean>();
  const [dismissTransfers, setDismissTransfers] = useState<boolean>();

  const [formStep, setFormStep] = useState<Q31FromSteps>();

  useEffect(
    () => {
      const caseNew = selectedOwnership === NicheOwnershipTypes.NEW;
      const caseOldNotChange = selectedOwnership === NicheOwnershipTypes.OLD
       && !isNameChange;
      const caseOldChange = selectedOwnership === NicheOwnershipTypes.OLD
       && isNameChange;

      const concessionReady = selectedConcession.length !== 0 || dismissConcessions;
      const taxesReady = selectedTax.length !== 0 || dismissTaxes;
      const transfersReady = selectedTransfer.length !== 0 || dismissTransfers;
      if (formStep === Q31FromSteps.RESPOND
        && (
          (caseNew && concessionReady && taxesReady)
          || (caseOldNotChange && taxesReady)
          || (caseOldChange && taxesReady && transfersReady)
        )) {
        setCanRespond(true);
      }
    },
    [
      dismissConcessions,
      dismissTaxes,
      dismissTransfers,
      formStep,
      isNameChange,
      selectedConcession.length,
      selectedOwnership,
      selectedTax.length,
      selectedTransfer.length,
    ],
  );

  const response = useCallback(
    ({ gotoSummary }) => {
      if (selectedOwnership && canRespond) {
        RespondQuestion({
          gotoSummary,
          question,
          response: {
            concessions: selectedConcession,
            nameChange: isNameChange,
            nicheType: selectedOwnership,
            taxes: selectedTax,
            transfers: selectedTransfer,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      canRespond,
      selectedConcession,
      isNameChange,
      selectedOwnership,
      selectedTax,
      selectedTransfer,
    ],
  );

  useEffect(
    () => {
      if (canRespond && !alreadyAnswered) {
        setAlreadyAnswered(true);
        response({ gotoSummary: false });
      }
    },
    [canRespond, response, alreadyAnswered],
  );

  const onChange = useCallback(
    (responseData: Q31Response): void => {
      setSelectedOwnership(responseData.nicheType);
      setIsNameChange(responseData.nameChange);
      setSelectedConcession(responseData.concessions);
      setSelectedTax(responseData.taxes);
      setSelectedTransfer(responseData.transfers);
      setFormStep(responseData.q31FormStep);
      setDismissConcessions(responseData.dismissConcessions);
      setDismissTaxes(responseData.dismissTaxes);
      setDismissTransfers(responseData.dismissTransfers);
    },
    [],
  );
  return (
    <React.Fragment>
      <Q31Form
        question={question}
        onChange={onChange}
      />

      {canRespond
        ? (
          <Row justifyContent="space-between">
            <QBackButton question={question} />
            <Row
              className="f-question-actions"
              justifyContent="flex-end"
            >
              <AffectedQuestions
                question={question}
                response={{
                  concessions: selectedConcession,
                  nameChange: isNameChange,
                  nicheType: selectedOwnership,
                  taxes: selectedTax,
                  transfers: selectedTransfer,
                }}
              />
              <QConfirmButton
                disabled={false}
                question={question}
                onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
              />
            </Row>
          </Row>
        )
        : <React.Fragment />}

    </React.Fragment>
  );
};
