import classNames from 'classnames';
import React, { FC, useMemo, useState } from 'react';

import { Column } from 'components/base/Column';
import { QuantitySelector } from 'components/base/QuantitySelector';
import { Row } from 'components/base/Row';

import './index.scss';
import { Article } from 'modules/budget/models/Article';
import { dismissServiceId } from 'modules/budget/store/data';

import { displayPriceQuestions } from '../../../data/displayPriceQuestions';
import { displaySimulationQuestions } from '../../../data/displaySimulationQuestions';
import { BudgetArticleDisplayPrice } from '../BudgetArticleDisplayPrice';
import { BudgetQ10SimulationSummary } from '../BudgetQ10SimulationSummary';
import { Separator } from '../Separator';

import { GalleryImagePicture } from './GalleryImagePicture';
import { PictureZoom } from './PictureZoom';
import { GalleryImageComponent } from './types';

export const GalleryImage: FC<GalleryImageComponent> = (
  {
    url,
    caption,
    selected,
    onChange,
    allowSelectQuantity,
    step,
    className,
    allowZoom = true,
    value,
    question,
  },
) => {
  const [showZoom, setShowZoom] = useState<boolean>(false);

  const showDisplayPrice = useMemo(
    () => displayPriceQuestions.includes(question),
    [question],
  );

  const showSimulationSummary = useMemo(
    () => displaySimulationQuestions.includes(question),
    [question],
  );

  return (
    <Row
      className={classNames('gallery-image', className, selected ? 'selected' : '', allowZoom ? 'gallery-image-zoom' : '')}
      justifyContent="space-between"
      onClick={() => {
        onChange(new Article({
          ...value,
          amount: selected ? 0 : step,
        }));
      }}
    >
      <GalleryImagePicture
        url={url}
        onClick={() => {
          if (allowZoom) {
            setShowZoom(true);
          }
        }}
      />
      {allowZoom
        ? (
          <PictureZoom
            image={url}
            show={showZoom}
            onHide={() => setShowZoom(false)}
          />
        )
        : <React.Fragment />}
      <div>&nbsp;</div>
      <Column className="gallery-image-info" justifyContent="space-between">
        <Separator />
        <div aria-selected={selected} role="option">{caption}</div>
        {showSimulationSummary && value?.code !== dismissServiceId
          ? <BudgetQ10SimulationSummary articleId={value?.code} />
          : <React.Fragment />}
        {showDisplayPrice && value?.code !== dismissServiceId
          ? (<BudgetArticleDisplayPrice article={value} />)
          : (<React.Fragment />)}

        {allowSelectQuantity
          ? (
            <QuantitySelector
              code={value.code}
              step={step}
              value={value?.amount || 0}
              onChange={(amount) => {
                onChange(new Article({
                  ...value,
                  amount,
                }));
              }}
            />
          )
          : <Separator className="quantity-selector-placeholder" />}
      </Column>
    </Row>
  );
};
