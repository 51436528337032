import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import { Row } from 'components/base/Row';

import { useBudgetLocations } from 'modules/budget/hooks/useBudgetLocations';
import { Article } from 'modules/budget/models/Article';
import { IBudgetLocation } from 'modules/budget/models/BudgetLocation/types';

import { OptionSelector } from '../OptionSelector';

import { decodeLocation, encodeLocation } from './tools';
import { CustomLocationSelectorComponent } from './types';

export const CustomLocationSelector: FC<CustomLocationSelectorComponent> = (
  {
    items, className, onChange, value, questionType,
  },
) => {
  const { fetchLocations, locations, getLocation } = useBudgetLocations();
  const [selectedBudgetLocation, setSelectedBudgetLocation] = useState<IBudgetLocation | null>();

  useEffect(
    () => {
      if (selectedBudgetLocation) {
        fetchLocations(
          questionType,
          selectedBudgetLocation.country,
          selectedBudgetLocation.province,
          selectedBudgetLocation.city,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionType, selectedBudgetLocation],
  );

  useEffect(
    () => {
      if (
        selectedBudgetLocation?.country
      && selectedBudgetLocation?.province
      && selectedBudgetLocation?.city
      && selectedBudgetLocation?.location) {
        const selectedLocation = getLocation(selectedBudgetLocation?.location);
        if (selectedLocation) {
          onChange({
            city: selectedBudgetLocation?.city,
            country: selectedBudgetLocation?.country,
            location: selectedLocation.code,
            locationWork: selectedLocation.work,
            province: selectedBudgetLocation?.province,
          });
        }
      } else {
        onChange(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      locations,
      selectedBudgetLocation,
    ],
  );

  return (
    <Row className={classNames(className)} justifyContent="space-around" style={{ width: '100%' }}>
      <OptionSelector
        items={items}
        value={[new Article({
          code: encodeLocation(value?.country, value?.province, value?.city, value?.location),
          description: '',
        })]}
        onChange={(val) => {
          if (val.length !== 0) {
            const {
              country, province, city, location,
            } = decodeLocation(val[0].code);

            setSelectedBudgetLocation({
              city,
              country,
              location,
              province,
            });
          } else {
            setSelectedBudgetLocation(null);
          }
        }}
      />
    </Row>
  );
};
