const qr = {
  coffinQr: 'QR Ataud',
  personalItemsQr: 'QR objetos personales',
  qr: 'QR Pulsera',
  search: 'Buscar consultas',
  title: 'Consultas',
  urnQr: 'QR Urna',
};

export default qr;
