const budget = {
  actions: {
    accept: 'Acceptar pressupost',
    acceptQuestion: 'Estàs segur que vols acceptar aquest pressupost?',
    acceptSuccess: 'Pressupost acceptat',
    allArticlesClient: 'Assignar client per a tots els articles',
    alreadySigned: 'La família ha signat manualment',
    assignRecord: 'Assignar expedient',
    assignSuccess: 'Pressupost assignat correctament a l\'expdent',
    backward: 'Retrocedir a estat anterior',
    backwardConfirm: {
      CANCELLED: 'Es canviarà l\'estat del pressupost de CANCEL·LAT a PENDENT, esteu segur?',
      INVOICED: 'Es canviarà l\'estat del pressupost de FACTURAT a SIGNAT, esteu segur?',
      PRE_ACCEPTED: 'Es canviarà l\'estat del pressupost de PRE ACCEPTAT a PENDENT, esteu segur?',
      SIGNED: 'Es canviarà l\'estat del pressupost de SIGNAT a PRE ACCEPTAT, esteu segur?',
    },
    backwardSuccess: 'Pressupost actualitzat',
    backwardSuccessInvoiced: 'Reviseu aplicació Ekon per a evitar duplicitat de serveis quan es torni a volcar la informació',
    cancel: 'Cancel·la',
    cancelSuccess: 'Pressupost cancel·lat correctament',
    confirmAssignRecord: 'Estàs segur que vols assignar aquest pressupost al expedient: {{name}} {{firstSurname}} {{secondSurname}}?',
    discardAutomatic: 'Descartar article automàtic',
    duplicate: 'Duplicar pressupost',
    duplicateSuccess: 'Pressupost duplicat correctament',
    editQuestion: 'Modificar resposta',
    invoice: 'Enviar línies a Ekon',
    invoiceSuccess: 'Línies enviades a Ekon correctament',
    restoreDiscarded: 'Restaura descartat',
    ruleSaveSuccess: 'Configuració actualitzada correctament',
    send: 'Enviar pressupost',
    sendSuccess: 'Pressupost enviat correctament',
    sign: 'Signar pressupost',
    signConfirm: 'Confirmar signatura',
    signSuccess: 'Signat correctament',
  },
  article: 'Article',
  articleImage: 'Imatge de l\'article',
  articleSubType: 'Subtipus d\'article',
  articleSubTypes: {
    ARTICLE: 'Article',
    CONCESSION: 'Concessió',
    TAX: 'Impost',
    TRANSFER: 'Transferència',
  },
  articleType: 'Tipus d\'article',
  articleTypes: {
    AUTOMATIC: 'Automàtic',
    CATALOG: 'Catàleg',
  },
  block: 'Bloc',
  budgetNumber: 'Nº de pressupost',
  ceremonyTypes: {
    CATOLIC: 'Catòlica',
    CIVIL: 'Civil',
    OTHER: 'Altres',
  },
  clientServicesAmount: 'Serveis client',
  coffinSize: 'Mida Fèretre',
  config: {
    article: 'Article',
    new: 'Nova configuració',
    question: 'Pregunta',
    title: 'Administrador Pressupost',
  },
  configurationAdd: 'Afegeix la configuració',
  discarded: 'Articles descartats',
  errors: {
    articleRequired: 'Heu de seleccionar un article',
    block: 'El bloc és requerit',
    coffinSizeRequired: 'La mida és requerida per als articles de fèretre',
    questionRequired: 'La pregunta associada és requerida als articles de catàleg',
    subTypeRequired: 'El subtipus d\'article és requerit',
    typeRequired: 'El tipus d\'article és requerit',
  },
  excludedArticles: 'Articles exclosos',
  excludedInsurance: 'Assegurances excloses',
  excludedInsurances: 'Assegurances excloses',
  excludedLocations: 'Ubicacions excloses',
  excludedResponse: 'Resposta opcional',
  hasArticleRule: 'Conf. per article',
  hasClientRule: 'Conf. per client',
  hasLocationRule: 'Conf. per localització',
  hasResponseRule: 'Conf. per resposta',
  hasRule: 'Té configuració',
  includedArticles: 'Articles inclosos',
  includedInsurances: 'Assegurances incloses',
  includedLocations: 'Ubicacions incloses',
  insurance: 'Assegurança',
  locations: 'Ubicacions',
  locationType: 'Tipus d\'ubicació',
  locationTypes: {
    DEATH: 'Lloc de defunció',
    INSURANCE: 'Assegurança',
    OBITUARY: 'Esquela',
  },
  mandatoryResponses: 'Respostes obligatòries',
  new: 'Nou pressupost',
  nicheProperties: {
    NEW: 'nova',
    OLD: 'antiga',
  },
  noInsurance: 'No teniu assegurança',
  optionalResponses: 'Respostes opcionals',
  pressObituaries: 'Esqueles premsa',
  processing: 'Tramitació',
  processor: 'Tramitador',
  question: 'Pregunta',
  rejectService: 'No vol el servei',
  relatedArticles: 'Artículos relacionados',
  response: 'Resposta',
  responses: {
    1.1: 'Servei de defunció',
    1.2: 'Servei d\'amputació',
    1.3: 'Servei d\'avortament',
    1.4: 'Servei de despulles',
    1.5: 'Servei d\'inhumació de cendres',
    2.1: 'Ja s\'ha produït la defunció',
    2.2: 'Encara no s\'ha produït la defunció',
    3.1: 'La població de defunció pertany a Cabré',
    3.2: 'La població de defunció no pertany a Cabré',
    4.1: 'Client principal particular',
    4.2: 'Client principal de companyia',
    5.1: 'Vetlla a instal·lacions de Cabré',
    5.2: 'Vetlla a instal·lacions fora de Cabré i població de Cabré',
    5.3: 'Vetlla a una població fora de Cabré',
    5.4: 'No vol Vetlla',
    6.1: 'Cerimònia a instal·lacions de Cabré',
    6.2: 'Cerimònia a instal·lacions fora de Cabré i població de Cabré',
    6.3: 'Cerimònia a una població fora de Cabré',
    6.4: 'No vol Cerimònia',
    7.1: 'Incineració a instal·lacions de Cabré',
    7.2: 'Incineració a instal·lacions fora de Cabré i població de Cabré',
    7.3: 'Incineració a una població fora de Cabré',
    7.4: 'No vol Incineració',
    8.1: 'Inhumació a instal·lacions de Cabré',
    8.2: 'Inhumació a instal·lacions fora de Cabré i població de Cabré',
    8.3: 'Inhumació a una població fora de Cabré',
    8.4: 'No vol Inhumació',
    9.1: 'Es requereix tantopràxia',
    9.2: 'NO es requereix tanatopràxia',
    10.1: 'Es vol fèretre',
    10.2: 'No es vol fèretre',
    11.1: 'Es vol  servei jurídic',
    11.2: 'NO es vol  servei jurídic',
    12.1: 'Es requereix servei de dipòsit',
    12.2: 'NO es requereix servei de dipòsit',
    13.1: 'Es vol servei de càtering',
    13.2: 'NO es vol servei de càtering',
    14.1: 'Es vol servei de flors',
    14.2: 'NO es vol servei de flors',
    15.1: 'Es volen recordatoris',
    15.2: 'NO es volen recordatoris',
    17.1: 'Es volen les esqueles de població',
    17.2: 'NO es volen les esqueles de població',
    19.1: 'Es vol esqueles premsa',
    19.2: 'NO es vol esqueles premsa',
    21.1: 'Es vol servei de taxis',
    21.2: 'NO es vol servei de taxis',
    22.1: 'Cerimònia catòlica',
    22.2: 'Cerimònia Civil',
    22.3: 'Altres tipus de cerimònia',
    23.1: 'El mossèn és proveïdor Propi',
    23.2: 'El mossèn és proveïdor de la família',
    24.1: 'Es vol servei musical',
    24.2: 'NO es vol servei musical',
    25.1: 'Es vol servei d\'Streaming',
    25.2: 'NO es vol servei d\'Streaming',
    27.1: 'Es vol urna',
    27.2: 'NO es vol urna',
    28.1: 'El difunt tenia marcapassos',
    28.2: 'El difunt NO tenia marcapassos',
    29.1: 'Es volen reliquiaris',
    29.2: 'NO es volen reliquiaris',
    30.1: 'Es volen penjolls',
    30.2: 'NO es volen penjolls',
    31.1: 'Nova',
    31.2: 'Antiga, menys de 5 anys, sense canvi nom',
    31.3: 'Antiga, més de 5 anys, sense canvi de nom',
    31.4: 'Antiga, més de 5 anys, amb canvi de nom',
    31.5: 'Antiga, menys de 5 anys, amb canvi de nom',
    36.1: 'SÍ, el difunt arriba encaixat',
    36.2: 'NO, és un protocol llitera',
    38.1: 'Proveïdor Propi',
    38.2: 'Proveïdor de la família',
    39.1: 'En cas de trasllat, de quin  cementri prové',
    40.1: 'NO es vol recull d\'imatges viu record',
    40.2: 'Es vol recull d\'imatges viu record',
    42.1: 'S\'utilitza sepulturer',
    42.2: 'NO es vol sepulturer',
    43.1: 'Se selecciona despatx i tramitació',
    43.2: 'No es vol despatx i tramitació',
    44.1: 'Es volen Articles addicionals',
    44.2: 'NO es volen Articles addicionals',
  },
  serviceTypes: {
    AMPUTACIO: 'Amputació',
    AVORTAMENT: 'Avortament',
    DEFUNCIO: 'Defunció',
    DESPULLES: 'Despulles',
    INHUMACIO_CENDRES: 'Inhumació cendres',
  },
  showNotActiveArticles: 'Mostrar només articles sense configuracio',
  sorting: 'Ordre',
  states: {
    CANCELLED: 'CANCEL·LAT',
    INVOICED: 'FACTURAT',
    PENDING: 'PENDENT',
    PRE_ACCEPTED: 'PRE ACCEPTAT',
    SIGNED: 'SIGNAT',
  },
  title: 'Seguiment pressupostos',
  totalAmount: 'Import total',
  vatAmount: 'IVA',
  wizard: {
    backButton: 'Anterior',
    cemeteryTaxesQuestion: 'Quin tipus de taxa és requerida?',
    changeAffectedQuestions: 'Aquestes respostes es perdran en modificar aquesta pregunta',
    city: {
      ceremony: 'Població de cerimònia',
      cremation: 'Població de cremació',
      death: 'Població de defunció',
      interment: 'Població de la inhumació',
      vigil: 'Població de vetlla',
    },
    collectedYes: 'A la recollida s\'ha observat que l\'estat del difunt és: "{{status}}"',
    commonDismiss: 'No vol el servei',
    concessionQuestion: 'Quin tipus de concessió es vol?',
    country: {
      ceremony: 'País de cerimònia',
      cremation: 'País de cremació',
      death: 'País de defunció',
      interment: 'País de la inhumació',
      vigil: 'País de vetlla',
    },
    date: 'Data',
    dismissItemCaption: {
      Q10: 'No es vol fèretre',
      Q11: 'No es vol contractar servei jurídic',
      Q13: 'No es vol càtering',
      Q14: 'No es vol flors',
      Q15: 'No es vol recordatoris',
      Q21: 'NO, no es vol servei de taxi',
      Q24: 'No es vol servei musical',
      Q27: 'No es vol urna',
      Q29: 'No es vol reliquiaris',
      Q30: 'No es vol joies o penjolls',
      Q31: 'No vol el servei',
      Q42: 'No vol el servei',
      Q43: 'No vol el servei',
      Q44: 'No vol el servei',
    },
    gotoTaxes: 'Seleccionar taxes',
    minus: '-',
    nameChange: 'Es vol un canvi de nom?',
    no: {
      Q2: 'No s\'ha produït la defunció',
      Q3: 'Altres',
      Q4: 'NO disposa d\'assegurança',
      Q5: 'NO, no es vol vetlla',
      Q6: 'NO, no es vol cerimònia',
      Q7: 'NO, no es vol incineració',
      Q8: 'NO, no es vol inhumació',
      Q9: 'NO, no es requereix tanatopràxia',
      Q12: 'No, no es requereix servei de dipòsit',
      Q17: 'NO, no es volen esqueles de població',
      Q19: 'NO, no es volen esqueles de premsa',
      Q21: 'NO, no es vol servei de taxi',
      Q23: 'Proveïdor de la família',
      Q25: 'No, NO es vol servei d\'Streaming',
      Q28: 'NO, no tenia marcapassos',
      Q36: 'NO, és un protocol llitera',
      Q38: 'Proveïdor de la família',
      Q40: 'No, NO es vol imatge viu record',
    },
    olderNiche: 'Antiga de més de 5 anys',
    otherCeremonyTypes: 'Altres tipus de cerimònia',
    plus: '+',
    province: {
      ceremony: 'Provincia de cerimònia',
      cremation: 'Provincia de cremació',
      death: 'Provincia de defunció',
      interment: 'Provincia de la inhumació',
      vigil: 'Provincia de vetlla',
    },
    Q1: 'Quin és el tipus de servei?',
    Q2: 'Ja s\'ha produït la defunció?',
    Q3: 'Població i lloc de defunció',
    Q4: 'En el cas que tingui assegurança, quina es disposa?',
    Q5: 'A quina població i a quina ubicació es vol la vetlla?',
    Q6: 'A quina població i a quina ubicació es vol la cerimònia?',
    Q7: 'A quina població i a quina ubicació es vol la incineració?',
    Q8: 'A quina població i a quina ubicació es vol la inhumació?',
    Q9: 'En cas que l\'assessor tingui informació de l\'estat del difunt. Es requereix tanatopràxia?',
    Q10: 'Quin fèretre es vol?',
    Q11: 'Es vol contractar el servei jurídic?',
    Q12: 'Es requereix servei de dipòsit?',
    Q13: 'Es vol servei de càtering?',
    Q14: 'Es vol servei de flors?',
    Q15: 'Es vol recordatoris?',
    Q16: 'Es vol esqueles a la població ?',
    Q17: 'A quina població es volen les esqueles de població?',
    Q19: 'Quin tipus d\'esqueles premsa es vol?',
    Q21: 'Es vol servei de taxis?',
    Q22: 'Quin tipus de cerimònia es vol?',
    Q23: 'Proveïdor del Mossèn?',
    Q24: 'Es vol servei musical?',
    Q25: 'Es vol servei d\'Streaming?',
    Q27: 'Quina urna es vol?',
    Q28: 'El difunt tenia marcapassos?',
    Q29: 'Es volen reliquiaris?',
    Q30: 'Es volen penjolls?',
    Q31: 'Tipus Sepultura?',
    Q36: 'En cas de no recollir nosaltres , el difunt arriba encaixat?',
    Q37: 'Es vol un canvi de nom?',
    Q38: 'Proveïdor Mestre Cerimònies?',
    Q39: 'En cas de trasllat, de quin cementri prové?',
    Q40: 'Es vol recull d\'imatges viu record?',
    Q42: 'Quins sepulturers volen fer servir?',
    Q43: 'Despatx i Tramitació',
    Q44: 'Vols afegir Articles addicionals?',
    responseButton: 'Següent',
    selectConcession: 'Seleccionar concessió',
    selectTax: 'Seleccionar taxa',
    selectTransfer: 'Seleccionar traspàs',
    serviceType: 'Tipus de servei',
    short: {
      Q10: 'Fèretre',
      Q13: 'Càtering',
      Q14: 'Flors',
      Q15: 'Recordatoris',
      Q17: 'Esqueles Pobl.',
      Q19: 'Esqueles Premsa',
      Q21: 'Taxis',
      Q24: 'Music',
      Q27: 'Urna',
      Q29: 'Reliquiaris',
      Q30: 'Penjolls',
      Q44: 'Art. addicionals',
    },
    summary: {
      amountEdit: 'Fes click per editar la quantitat',
      article: 'Article',
      clientTypes: {
        COMPANY: 'Companyia',
        INDIVIDUAL: 'Particular',
      },
      confirmAmount: 'Confirmar canvi de quantitat',
      confirmPrice: 'Confirmar canvi de preu',
      description: 'Descripció',
      price: 'Preu',
      priceEdit: 'Fes click per editar el preu',
      quantity: 'Quantitat',
      title: 'Pressupost',
      unitPrice: 'Preu per unitat',
    },
    totalServiceAmount: 'El total del servei és: {{amount}}',
    transferQuestion: 'Es vol un canvi de nom?',
    vigilDate: 'Data d\'inici de la vetlla',
    yes: {
      Q2: 'SÍ, s\'ha produït la defunció',
      Q3: 'Domicili',
      Q4: 'Disposa d\'assegurança',
      Q5: 'SÍ, es vol vetlla',
      Q6: 'SÍ, es vol cerimònia',
      Q7: 'SÍ, es vol incineració',
      Q8: 'SÍ, es vol inhumació',
      Q9: 'Sí, es requereix tanatopràxia',
      Q12: 'SÍ, requereix servei de dipòsit',
      Q17: 'SÍ, es volen esqueles de població',
      Q19: 'SÍ, es volen esqueles de premsa',
      Q21: 'SÍ, es vol servei de taxi',
      Q23: 'Proveïdor Propi',
      Q25: 'Sí, es vol servei d\'Streaming',
      Q28: 'SÍ, tenia maracapassos',
      Q36: 'SÍ, el difunt arriba encaixat',
      Q38: 'Proveïdor Propi',
      Q40: 'Sí, es vol imatge viu record',
    },
  },
};
export default budget;
