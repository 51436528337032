import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { masterController } from 'config/apiFunus';
import { FunusMovementTypes } from 'config/apiFunus/generated/data-contracts';
import { IMasterDataDto } from 'models/MasterData';

export type MovementTypesHook = {
  types: IMasterDataDto[];
  loadTypes: () => unknown;
  getMovememntType: (code: FunusMovementTypes) => IMasterDataDto | undefined
}
export const useMovementTypes = (): MovementTypesHook => {
  const { t } = useTranslation();
  const [types, setTypes] = useState<IMasterDataDto[]>([]);

  const loadTypes = async () => {
    const movementTypes = await masterController.getMovementTypesUsingGet()
      .then((response) => response)
      .catch((error) => error);
    const masterDataMovementTypes = Object.keys(movementTypes)
      .map((key: string) => ({
        code: movementTypes[key],
        description: t(`service.movement.type.${movementTypes[key]}`),
      }));
    setTypes(masterDataMovementTypes);
  };

  const getMovememntType = useCallback(
    (code: FunusMovementTypes) => types
      .find((e) => e.code === code),
    [types],
  );

  return ({
    getMovememntType,
    loadTypes,
    types,
  });
};
