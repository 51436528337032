import React, {
  FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import InputQRScan from 'components/base/InputQRScan';
import Modal from 'components/base/Modal';

type ScanQrModalComponent = {
  show: boolean;
  title: string;
  onScanSuccess: (s: string) => void
  onCancel: () => void
}

export const ScanQrModal: FC<ScanQrModalComponent> = ({
  show, title, onScanSuccess, onCancel,
}) => {
  const { t } = useTranslation();
  const [qr, setQr] = useState<string>('');

  useEffect(() => {
    if (qr) {
      onScanSuccess(qr);
      setQr('');
    }
  }, [onScanSuccess, qr]);

  return (
    <Modal
      buttons={[{
        onClick: () => {
          onCancel();
        },
        text: t('common.cancel'),
      },
      ]}
      className="appointment-modal"
      show={show}
      showCancel={false}
      showXButton={false}
      title={title}
      onHide={() => false}
    >
      <InputQRScan
        data={qr}
        placeholder={title}
        setData={(val) => {
          setQr(val);
        }}
      />
    </Modal>
  );
};
