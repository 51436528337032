import classNames from 'classnames';
import React, {
  Fragment, FC, useState, useEffect,
} from 'react';
import { Clipboard, PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from 'config';

import { formatDate } from 'utils/dateManager';
import { checkHaveAtLeastOnePermission } from 'utils/permissions';

import Button from 'components/base/Button';
import Loader from 'components/base/Loader';
import { displayNoValue } from 'components/base/NoData';
import { TableColumn } from 'components/base/Table/types';
import TwoBoxesPage from 'components/base/TwoBoxesPage';

import { DateTimeInfo } from 'components/pages/Information/informationTable/DateTimeInfo';
import RecordTable from 'components/pages/record/Table';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import Record, { LandingRecords } from 'models/Record';
import './index.scss';
import { RootState } from 'store';

const ManagerLanding: FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState<boolean | undefined>(true);
  const [loadingSecond, setLoadingSecond] = useState<boolean | undefined>(true);
  const [columns, setColumns] = useState<TableColumn<Record>[]>([]);
  const [noAdviserColumn, setNoAdviserColumn] = useState<TableColumn<Record>[]>(
    [],
  );

  const { user } = useProvidedAuth();
  const { t } = useTranslation();
  const { cities } = useSelector((state: RootState) => state.masterData);

  useEffect(() => {
    setNoAdviserColumn([
      {
        accessor: 'id',
        Header: 'ID',
        hidden: true,
      },
      {
        accessor: 'erpId',
        className: 'primary-dark-color',
        Header: `${t('record.number')}`,
        sortable: true,
      },
      {
        accessor: 'createdDate',
        Cell: ({ cell: { value } }) => formatDate(value),
        Header: `${t('common.date')}`,
        sortable: true,
      },
      {
        accessor: 'deceasedData',
        Cell: ({
          row: {
            original: { deceasedData },
          },
        }) => (!!deceasedData
            && classNames(
              deceasedData.name,
              deceasedData.firstSurname,
              deceasedData.secondSurname,
            ))
          || '',
        Header: `${t('common.name')}`,
      },
      {
        accessor: 'deathData',
        Cell: ({ cell: { value } }) => {
          const city = cities
            && Object.keys(cities)
              .map((k) => cities[k])
              .find((c) => c.code === value?.address?.city);

          return (
            <React.Fragment>{city ? city.description : displayNoValue}</React.Fragment>
          );
        },
        Header: `${t('common.city')}`,
        sortable: false,
      },
      {
        accessor: 'appointmentDateTime',
        Cell: ({ cell: { value } }) => (
          value ? <DateTimeInfo date={value} time={value} /> : '--'
        ),
        Header: `${t('record.appointment')}`,
        sortable: false,
      },
    ]);
  }, [t, cities]);

  useEffect(() => {
    setColumns([
      {
        accessor: 'id',
        Header: 'ID',
        hidden: true,
      },
      {
        accessor: 'erpId',
        className: 'primary-dark-color',
        Header: `${t('record.number')}`,
        sortable: true,
      },
      {
        accessor: 'death.deathDate',
        Cell: ({
          row: {
            original: { deathData },
          },
        }) => {
          if (deathData?.deathDate) {
            return formatDate(deathData.deathDate);
          }
          return '';
        },
        Header: `${t('common.date')}`,
        sortable: true,
      },
      {
        accessor: 'deceasedData',
        Cell: ({
          row: {
            original: { deceasedData },
          },
        }) => (!!deceasedData
            && classNames(
              deceasedData.name,
              deceasedData.firstSurname,
              deceasedData.secondSurname,
            ))
          || '',
        Header: `${t('common.name')}`,
      },
      {
        accessor: 'negotiators',
        Cell: ({ cell: { value } }) => (
          <Fragment>
            <div>
              {value && value[0]
                ? `${value[0]?.firstSurname}, ${value[0]?.name}`
                : ''}
            </div>
            <div>
              {value && value[1]
                ? `${value[1]?.firstSurname}, ${value[1]?.name}`
                : ''}
            </div>
          </Fragment>
        ),
        collapse: true,
        Header: `${t('record.advisers')}`,
        sortable: true,
      },
      {
        accessor: 'deathData',
        Cell: ({ cell: { value } }) => {
          const city = cities
            && Object.keys(cities)
              .map((k) => cities[k])
              .find((c) => c.code === value?.address?.city);

          return (
            <React.Fragment>{city ? city.description : displayNoValue}</React.Fragment>
          );
        },
        Header: `${t('common.city')}`,
        sortable: false,
      },
      {
        accessor: 'appointmentDateTime',
        Cell: ({ cell: { value } }) => (
          value ? <DateTimeInfo date={value} time={value} /> : '--'
        ),
        Header: `${t('record.appointment')}`,
        sortable: false,
      },
    ]);
  }, [t, cities]);

  const getLeftBoxButton = () => {
    if (
      checkHaveAtLeastOnePermission(
        [
          SimpleUserRequestPermissionNames.RECORD_ADD,
          SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
        ],
        user?.role.permissions,
      )
    ) {
      return (
        <Button
          leftAddon={<PlusCircle />}
          text={t('record.new')}
          to={config.url.newRecord}
        />
      );
    }

    return undefined;
  };

  const reload = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  };

  return (
    <Fragment>
      {(loadingFirst || loadingSecond || loading) && <Loader />}
      {!loading && (
        <TwoBoxesPage
          className="record-landing"
          leftBox={{
            className: 'record-left-box',
            content: (
              <RecordTable
                assignCallback={reload}
                columns={noAdviserColumn}
                getData={(index, size) => config.apiFunus.record.getLandingRecords({
                  filter: [
                    {
                      negotiator: LandingRecords.NOT_ASSIGNED,
                    },
                  ],
                  page: {
                    index,
                    size,
                  },
                })}
                setIsLoading={setLoadingFirst}
                pagination
                showOnlyTable
              />
            ),
            header: getLeftBoxButton(),
            icon: <Clipboard />,
            title: t('record.notAssignedRecords'),
          }}
          rightBox={{
            className: 'record-right-box',
            content: (
              <RecordTable
                assignCallback={reload}
                columns={columns}
                getData={(index, size) => config.apiFunus.record.getLandingRecords({
                  filter: [
                    {
                      negotiator: LandingRecords.ASSIGNED,
                    },
                  ],
                  page: {
                    index,
                    size,
                  },
                })}
                setIsLoading={setLoadingSecond}
                pagination
                showOnlyTable
              />
            ),
            icon: <Clipboard />,
            title: t('record.assignedRecords'),
          }}
        />
      )}
    </Fragment>
  );
};

export default ManagerLanding;
