import { MasterData } from 'models/MasterDataDto';
import { BudgetsTrackingFilterStatus } from 'modules/budget/components/tracking/table/types';
import { AuditId } from 'modules/budget/models/Audit/types';
import { IBudgetQuestion } from 'modules/budget/models/BudgetQuestion/types';
import { InvoiceArticleType } from 'modules/budget/models/InvoiceArticle/types';

import { ApiArticle } from '../types';

export enum BudgetActions {
  ACCEPT = 'ACCEPT',
  ASSIGN = 'ASSIGN',
  CANCEL = 'CANCEL',
  DUPLICATE = 'DUPLICATE',
  INVOICE = 'INVOICE',
  SIGN = 'SIGN',
  BACKWARD = 'BACKWARD',
}

export interface ApiBudget {
  id: AuditId;
  idVersion: number;
  idRecord: number;
  createdBy: string;
  createdDate: string;
  questions: Array<IBudgetQuestion>;
  automaticArticles: Array<ApiArticle>;
  discardedArticles: Array<ApiArticle>;
  collected: boolean;
  status: BudgetsTrackingFilterStatus;
}

export interface BudgetsArticlesResponse {
  articles: {
    id: number;
    code: string;
    type: InvoiceArticleType;
    description: string;
    provider: string;
    client: string;
    providerList: MasterData[];
  }[];
  clientList: MasterData[];
}
