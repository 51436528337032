import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Datepicker from 'components/base/Datepicker';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import { useMovementTypes } from 'hooks/useMovementTypes';
import { useVigilLocation, useVigilLocationDescription } from 'hooks/useVigilLocation';
import { IMasterDataDto, VigilLocation } from 'models/MasterData';
import { SaveService } from 'models/MasterService';

type MovementFormProps = {
  className?: string;
  formikProps: FormikProps<SaveService>;
  disabled?: boolean;
}

export const MovementForm: FC<MovementFormProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const { t } = useTranslation();
  const { loadTypes, types, getMovememntType } = useMovementTypes();
  const { vigilLocations, getVigilLocation, fetchVigilLocations } = useVigilLocation(
    values.vigilServiceDto?.address?.city,
  );
  const { description: locationDescription, setCityCode, setCode } = useVigilLocationDescription();

  useEffect(
    () => {
      if (values.movementServiceDto?.vigilPlace) {
        setCode(values.movementServiceDto?.vigilPlace);
      }
    },
    [setCode, values.movementServiceDto?.vigilPlace],
  );

  useEffect(
    () => {
      if (values.movementServiceDto?.address?.city) {
        setCityCode(values.movementServiceDto?.address?.city);
      }
    },
    [setCityCode, values.movementServiceDto?.address?.city],
  );

  useEffect(
    () => {
      setFieldValue('movementServiceDto.address.streetName', locationDescription);
    },
    [locationDescription, setFieldValue],
  );

  useEffect(
    () => {
      if (values.movementServiceDto?.address?.city) {
        fetchVigilLocations(values.movementServiceDto?.address?.city);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.movementServiceDto?.address?.city],
  );

  useEffect(() => {
    loadTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('movement-form', className)}>
      <fieldset>
        <legend>{t('service.MOVIMIENTO')}</legend>
        <Select<IMasterDataDto>
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'movementServiceDto.type')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="type"
          options={types}
          placeholder={t('common.type')}
          value={values.movementServiceDto?.type
            ? getMovememntType(values.movementServiceDto?.type)
            : undefined}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('movementServiceDto.type', val?.code)}
        />
        <SelectCountryProvinceCity
          key="ceremonyAddress"
          cityProps={{
            error: getIn(errors, 'movementServiceDto.address.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'movementServiceDto.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue('movementServiceDto.address.city', val);
            },
            placeholder: t('common.city'),
            value: values.movementServiceDto?.address?.city || '',
          }}
          countryProps={{
            error: getIn(errors, 'movementServiceDto.address.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'movementServiceDto.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('movementServiceDto.address.country', val),
            placeholder: t('common.country'),
            value: values.movementServiceDto?.address?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(errors, 'movementServiceDto.address.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'movementServiceDto.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('movementServiceDto.address.province', val),
            placeholder: t('common.province'),
            value: values.movementServiceDto?.address?.province || '',
          }}
        />
        <Select<VigilLocation>
          key="location"
          disabled={
            isSubmitting
            || disabled
          }
          error={getIn(errors, 'movementServiceDto.vigilPlace')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="location"
          options={vigilLocations}
          placeholder={t('common.location')}
          testid="location"
          value={values.movementServiceDto?.vigilPlace
            ? getVigilLocation(values.movementServiceDto?.vigilPlace)
            : undefined}
          searchable
          onBlur={handleBlur}
          onChange={(val) => {
            setFieldValue('movementServiceDto.vigilPlace', val?.code);
          }}
        />
        <Textarea
          key="address.streetName"
          error={getIn(errors, 'movementServiceDto.address.streetName')}
          id="address.streetName"
          name="address.streetName"
          placeholder={t('common.domicile')}
          value={values.movementServiceDto?.address?.streetName || ''}
          disabled
          onBlur={handleBlur}
        />
        <Datepicker
          key="date"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'movementServiceDto.date')}
          name="date"
          placeholder={t('common.date')}
          selected={values.movementServiceDto?.date}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('movementServiceDto.date', val)}
        />
        <Datepicker
          key="time"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'movementServiceDto.time')}
          name="time"
          placeholder={t('common.hour')}
          selected={values.movementServiceDto?.time}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('movementServiceDto.time', val)}
        />
        <Textarea
          key="observations"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'movementServiceDto.comment')}
          id="observations"
          name="observations"
          placeholder={t('common.observations')}
          value={values.movementServiceDto?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('movementServiceDto.comment', val)}
        />
      </fieldset>
    </div>
  );
};
