/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AppointmentRequest,
  AshesInfoDto,
  AshesInfoRequestDto,
  CreateWorkOrderRequest,
  GetPreneedRecordWithErpIdUsingGetParams,
  InputStream,
  RecordDtoReq,
  RecordDtoRes,
  RecordInformationPaginatedRequest,
  RecordInformationPaginatedResponse,
  RecordPaginatedRequest,
  RecordPersonalItemsQrDto,
  RecordQrDetailRequest,
  RecordQrDetailResponse,
  RecordUserRequest,
  RecordWorkOrderExcludedStatusPaginatedRequest,
  SignatureRequest,
  SimpleRecordPaginatedRequest,
  SimpleRecordPaginatedResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class RecordController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags record-controller
   * @name GetLandingRecordsUsingPost
   * @summary getLandingRecords
   * @request POST:/landing/records
   * @secure
   * @response `200` `SimpleRecordPaginatedResponse` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getLandingRecordsUsingPost = (simpleRecordRequest: SimpleRecordPaginatedRequest, params: RequestParams = {}) =>
    this.http.request<SimpleRecordPaginatedResponse, void>({
      path: `/landing/records`,
      method: "POST",
      body: simpleRecordRequest,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name SaveRecordUsingPost
   * @summary saveRecord
   * @request POST:/record
   * @secure
   * @response `200` `InputStream` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  saveRecordUsingPost = (recordDto: RecordDtoReq, params: RequestParams = {}) =>
    this.http.request<InputStream, void>({
      path: `/record`,
      method: "POST",
      body: recordDto,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetPreneedRecordWithErpIdUsingGet
   * @summary getPreneedRecordWithErpId
   * @request GET:/record/document-type/{document-type}/document/{document}
   * @secure
   * @response `200` `RecordDtoRes` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getPreneedRecordWithErpIdUsingGet = (
    { document, documentType, ...query }: GetPreneedRecordWithErpIdUsingGetParams,
    params: RequestParams = {},
  ) =>
    this.http.request<RecordDtoRes, void>({
      path: `/record/document-type/${documentType}/document/${document}`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name AssignUsersToRecordUsingPost
   * @summary assignUsersToRecord
   * @request POST:/record/users
   * @secure
   * @response `200` `InputStream` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  assignUsersToRecordUsingPost = (recordUserRequest: RecordUserRequest, params: RequestParams = {}) =>
    this.http.request<InputStream, void>({
      path: `/record/users`,
      method: "POST",
      body: recordUserRequest,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetRecordUsingGet
   * @summary getRecord
   * @request GET:/record/{id}
   * @secure
   * @response `200` `RecordDtoRes` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getRecordUsingGet = (id: number, params: RequestParams = {}) =>
    this.http.request<RecordDtoRes, void>({
      path: `/record/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name UpdateRecordUsingPut
   * @summary updateRecord
   * @request PUT:/record/{id}
   * @secure
   * @response `200` `InputStream` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  updateRecordUsingPut = (id: number, recordDto: RecordDtoReq, params: RequestParams = {}) =>
    this.http.request<InputStream, void>({
      path: `/record/${id}`,
      method: "PUT",
      body: recordDto,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name CloseUsingPut
   * @summary close
   * @request PUT:/record/{id}/close
   * @secure
   * @response `200` `void` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  closeUsingPut = (id: number, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/record/${id}/close`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name UpdatePersonalItemsQrUsingPut
   * @summary updatePersonalItemsQR
   * @request PUT:/record/{id}/personalItemsQr
   * @secure
   * @response `200` `InputStream` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  updatePersonalItemsQrUsingPut = (
    id: number,
    recordPersonalItemsQrDto: RecordPersonalItemsQrDto,
    params: RequestParams = {},
  ) =>
    this.http.request<InputStream, void>({
      path: `/record/${id}/personalItemsQr`,
      method: "PUT",
      body: recordPersonalItemsQrDto,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name CreatePickUpUsingPost
   * @summary createPickUp
   * @request POST:/record/{id}/pickup
   * @secure
   * @response `200` `void` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  createPickUpUsingPost = (id: number, recordDto: RecordDtoReq, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/record/${id}/pickup`,
      method: "POST",
      body: recordDto,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name SignUsingPut
   * @summary sign
   * @request PUT:/record/{id}/signature
   * @secure
   * @response `200` `void` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  signUsingPut = (id: number, request: SignatureRequest, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/record/${id}/signature`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetRecordsUsingPost
   * @summary getRecords
   * @request POST:/records
   * @secure
   * @response `200` `SimpleRecordPaginatedResponse` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getRecordsUsingPost = (recordPaginatedRequest: RecordPaginatedRequest, params: RequestParams = {}) =>
    this.http.request<SimpleRecordPaginatedResponse, void>({
      path: `/records`,
      method: "POST",
      body: recordPaginatedRequest,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetQrDetailsUsingPost
   * @summary getQrDetails
   * @request POST:/records/qrs
   * @secure
   * @response `200` `RecordQrDetailResponse` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getQrDetailsUsingPost = (recordQrDetailRequest: RecordQrDetailRequest, params: RequestParams = {}) =>
    this.http.request<RecordQrDetailResponse, void>({
      path: `/records/qrs`,
      method: "POST",
      body: recordQrDetailRequest,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetServicesUsingPost
   * @summary getServices
   * @request POST:/records/services
   * @secure
   * @response `200` `RecordInformationPaginatedResponse` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getServicesUsingPost = (request: RecordInformationPaginatedRequest, params: RequestParams = {}) =>
    this.http.request<RecordInformationPaginatedResponse, void>({
      path: `/records/services`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name DeleteRecordUsingDelete
   * @summary deleteRecord
   * @request DELETE:/records/{id}
   * @secure
   * @response `200` `void` OK
   * @response `204` `void` No Content
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  deleteRecordUsingDelete = (id: number, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/records/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetAppointmentUsingPut
   * @summary getAppointment
   * @request PUT:/records/{id}/appointment
   * @secure
   * @response `200` `void` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getAppointmentUsingPut = (id: number, appointment: AppointmentRequest, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/records/${id}/appointment`,
      method: "PUT",
      body: appointment,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetAshesDeliveryUsingGet
   * @summary getAshesDelivery
   * @request GET:/records/{id}/ashes/delivery
   * @secure
   * @response `200` `AshesInfoDto` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getAshesDeliveryUsingGet = (id: number, params: RequestParams = {}) =>
    this.http.request<AshesInfoDto, void>({
      path: `/records/${id}/ashes/delivery`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name UpdateAshesDeliveryUsingPut
   * @summary updateAshesDelivery
   * @request PUT:/records/{id}/ashes/delivery
   * @secure
   * @response `200` `void` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  updateAshesDeliveryUsingPut = (id: number, request: AshesInfoRequestDto, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/records/${id}/ashes/delivery`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name CreateWorkOrderUsingPut
   * @summary createWorkOrder
   * @request PUT:/records/{id}/workorders
   * @secure
   * @response `200` `void` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  createWorkOrderUsingPut = (id: number, request: CreateWorkOrderRequest, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/records/${id}/workorders`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags record-controller
   * @name GetRecordsWorkOrderUsingPost
   * @summary getRecordsWorkOrder
   * @request POST:/workorder/excluded/status/records
   * @deprecated
   * @secure
   * @response `200` `SimpleRecordPaginatedResponse` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getRecordsWorkOrderUsingPost = (
    recordWorkOrderPaginatedRequest: RecordWorkOrderExcludedStatusPaginatedRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleRecordPaginatedResponse, void>({
      path: `/workorder/excluded/status/records`,
      method: "POST",
      body: recordWorkOrderPaginatedRequest,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
