import React, { FC } from 'react';

import { Column } from 'components/base/Column';
import { Row } from 'components/base/Row';

import './index.scss';

import { QHeader2Component } from './types';

export const QHeader2: FC<QHeader2Component> = ({ caption }) => (
  <Row className="f-q-header-2">
    <Column justifyContent="flex-start">
      <div className="q-header">{caption}</div>
    </Column>
  </Row>
);
