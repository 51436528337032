import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Modal from 'components/base/Modal';

import { OrderLine, OrderLineChannel } from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';
import { OrderDraft } from 'models/OrderDraft';

import { AddArticleRequestForm } from '../addArticleRequestForm';

type EditDraftModalComponent = {
  show: boolean;
  articleId: number | undefined;
  onSuccess: () => void,
  onHide: () => void
}

export const EditDraftModal: FC<EditDraftModalComponent> = ({
  show,
  articleId,
  onSuccess,
  onHide,
}) => {
  const {
    getArticleDraft, acceptDraft, fetchArticleProviders, articleProviders,
  } = useArticles();
  const { t } = useTranslation();
  const [articleDraft, setArticleDraft] = useState<OrderDraft>();
  const [payload, setpayload] = useState<OrderLine & {idSupplier?: string}>();
  const [selectedArticle, setselectedArticle] = useState<string>();
  const [acceptDraftLoading, setAcceptDraftLoading] = useState<boolean>(false);
  const [isFormReady, setIsFormReady] = useState<boolean>(false);

  useEffect(
    () => {
      if (selectedArticle) {
        fetchArticleProviders(selectedArticle)
          .catch((error) => {
            showErrorToast(error.message);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedArticle],
  );

  useEffect(
    () => {
      if (articleId && show) {
        getArticleDraft(articleId)
          .then((response) => {
            setArticleDraft(response);
            return response;
          }).catch(() => false);
      } else if (!articleId) {
        setArticleDraft(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleId, show],
  );

  const acceptRequest = useCallback(
    async () => {
      if (payload && articleId) {
        const supplier = articleProviders.find((s) => s.code === payload.idSupplier);
        setAcceptDraftLoading(true);
        acceptDraft(articleId, {
          amount: 1,
          articleDescription: payload.articleDescription,
          category: payload.category,
          channel: OrderLineChannel.PROCESSING,
          client: payload.client,
          code: payload.code,
          comments: payload.comments,
          date: payload?.date ? new Date(payload?.date) : undefined,
          dedicatory: payload.dedicatory,
          erpId: payload.erpId,
          idSupplier: supplier?.code || '',
          observations: payload.observations,
          price: payload.price,
          supplier: supplier?.email || '',
          time: payload.time,
        }).then(() => {
          showSuccessToast(t('article.flowers.updateDraftSuccess'));
          onSuccess?.();
          setAcceptDraftLoading(false);
          return {};
        }).catch((error) => {
          showErrorToast(error.message);
          setAcceptDraftLoading(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleId, articleProviders, onSuccess, payload, t],
  );

  const onClose = useCallback(
    () => {
      setArticleDraft(undefined);
      onHide();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onClose();
          },
          text: t('common.cancel'),
        },
        {
          disabled: acceptDraftLoading || !isFormReady,
          onClick: acceptRequest,
          text: t('common.accept'),
        },
      ]}
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      title={t('article.flowers.editDraft')}
      onHide={() => {
        onClose();
      }}
    >
      {articleDraft
        ? (
          <AddArticleRequestForm
            channel={OrderLineChannel.PROCESSING}
            clientDisabled={false}
            erpId={articleDraft.erpId}
            id={articleDraft.recordId}
            priceDisabled={false}
            providerDisabled={false}
            value={{
              ...articleDraft,
              amount: 1,
              date: articleDraft.date ? new Date(articleDraft.date) : undefined,
              idSupplier: articleDraft.idSupplier,
              price: articleDraft.price,
              supplier: '',
              time: articleDraft.time ? new Date(articleDraft.time) : undefined,
            }}
            vigilDate={articleDraft.date}
            vigilHour={articleDraft.time}
            articleDisabled
            articleTypeDisabled
            onArticleChange={() => false}
            onChange={(p) => {
              setselectedArticle(p.code);
              setpayload(p);
            }}
            onIsValidUpdate={(isValid) => {
              setIsFormReady(isValid);
            }}
            onSupplierChange={() => false}
          />
        )
        : <React.Fragment />}
    </Modal>
  );
};
