import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { Column } from 'components/base/Column';

import { GallerySelector } from 'modules/budget/components/articleSelectors/GallerySelector';
import { Separator } from 'modules/budget/components/commons/Separator';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionSettings } from 'modules/budget/hooks/useQuestionSettings';
import { Article } from 'modules/budget/models/Article';
import { dismissServiceId } from 'modules/budget/store/data';

import { GalleryQuestionFormComponent, GalleryQuestionResponse } from './types';

export const GalleryQuestionForm: FC<GalleryQuestionFormComponent> = ({
  onChange, question, allowSelectQuantity, multiselect,
}) => {
  const { GetQuestionValue } = useBudget();
  const { isMultiselect, isQuantityEnabled } = useQuestionSettings();
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.articles) {
        onChange({
          articles: storedValue?.articles,
          autorespond: false,
          dimissService: storedValue?.articles.length === 0,
        });
        setSelectedArticles(storedValue?.articles);
      }
    },
    [onChange, storedValue?.articles],
  );

  return (
    <Formik
      initialValues={{
        articles: storedValue?.articles,
        autorespond: false,
        dimissService: storedValue?.articles.length === 0,
      }}
      validate={(values) => {
        onChange({
          articles: values.articles,
          autorespond: values.autorespond,
          dimissService: values.dimissService,
        });
        setSelectedArticles(values.articles);

        return {};
      }}
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues, values,
      }: FormikProps<GalleryQuestionResponse>) => {
        const autoRespond = !isMultiselect(question) && !isQuantityEnabled(question);
        return (
          <Column className="f-gallery-question-form">
            <Separator />
            <GallerySelector
              allowSelectQuantity={allowSelectQuantity}
              dismissServiceSelected={values.dimissService}
              multiselect={multiselect}
              question={question}
              value={selectedArticles}
              onChange={(articles: Article[]) => {
                if (articles.find((article) => article.code === dismissServiceId)) {
                  setValues({
                    articles: [],
                    autorespond: true,
                    dimissService: true,
                  });
                } else {
                  setValues({
                    articles,
                    autorespond: autoRespond,
                    dimissService: false,
                  });
                }
              }}
            />
          </Column>
        );
      }}
    </Formik>
  );
};
