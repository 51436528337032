export const crematoryPredefinedSelections = [
  {
    city: '08360',
    country: '011',
    description: 'Crematori tanatori de Mataró',
    location: '2',
    province: '08',
  },
  {
    city: '08360',
    country: '011',
    description: 'Crematori cementiri de les Valls de Mataró',
    location: '1',
    province: '08',
  },
  {
    city: '08285',
    country: '011',
    description: 'Crematori cementiri Granollers',
    location: '1',
    province: '08',
  },
];
