import React, {
  FC, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { DisagreementRequestDto } from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';

import { AddArticleDisagreementForm } from '../addArticleDisagreementForm';

type AddArticleDisagreementComponent = {
  show: boolean;
  articleId: number | string | undefined;
  onSuccess: () => void;
  onCancel: () => void;
}

export const AddArticleDisagreementModal: FC<AddArticleDisagreementComponent> = (
  {
    show, articleId, onSuccess, onCancel,
  },
) => {
  const { t } = useTranslation();
  const [payload, setpayload] = useState<DisagreementRequestDto>();
  const { createDisagreement } = useArticles();
  const [isFormReady, setIsFormReady] = useState<boolean>(false);

  const create = useCallback(
    () => {
      if (articleId && payload) {
        createDisagreement(Number(articleId), {
          ...payload,
          image: payload.image ? payload.image : undefined,
        })
          .then(() => {
            onSuccess();
            return true;
          })
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleId, payload],
  );

  return (
    <Modal
      buttons={[
        {
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          disabled: !isFormReady,
          onClick: () => {
            create();
          },
          text: t('common.accept'),
        },
      ]}
      show={show}
      showCancel={false}
      title={t('article.addDisagreement')}
      onHide={onCancel}
    >
      <AddArticleDisagreementForm
        onChange={(values) => setpayload(values)}
        onIsValidUpdate={(isValid) => {
          setIsFormReady(isValid);
        }}
      />
    </Modal>
  );
};
