export enum BudgetQuestionCodes {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
  Q5 = 'Q5',
  Q6 = 'Q6',
  Q7 = 'Q7',
  Q8 = 'Q8',
  Q9 = 'Q9',
  Q10 = 'Q10',
  Q11 = 'Q11',
  Q12 = 'Q12',
  Q13 = 'Q13',
  Q14 = 'Q14',
  Q15 = 'Q15',
  Q17 = 'Q17',
  Q19 = 'Q19',
  Q21 = 'Q21',
  Q22 = 'Q22',
  Q23 = 'Q23',
  Q24 = 'Q24',
  Q25 = 'Q25',
  Q27 = 'Q27',
  Q28 = 'Q28',
  Q29 = 'Q29',
  Q30 = 'Q30',
  Q31 = 'Q31',
  Q38 = 'Q38',
  Q39 = 'Q39',
  Q36 = 'Q36',
  Q40 = 'Q40',
  Q42 = 'Q42',
  Q43 = 'Q43',
  Q44 = 'Q44',
  SUMMARY = 'SUMMARY'
}
