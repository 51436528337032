import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

interface AshesDestinationsHook {
  ashesDestinations: IMasterDataDto[],
  getAshesDestination: (code: string | undefined) => IMasterDataDto | undefined
}

const useAshesDestinations = ():AshesDestinationsHook => {
  const { ashesDestinations } = useSelector(
    (state: RootState) => state.masterData,
  );

  const getAshesDestination = useCallback(
    (code: string | undefined) => (code
      ? ashesDestinations.find((i: IMasterDataDto) => i.code === code)
      : undefined),
    [ashesDestinations],
  );

  return {
    ashesDestinations,
    getAshesDestination,
  };
};

export default useAshesDestinations;
