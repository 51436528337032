import classNames from 'classnames';
import React, {
  FC, useState, MutableRefObject, useEffect, useCallback,
} from 'react';
import {
  User,
  ChevronDown,
  MoreVertical,
  PlusCircle,
  PhoneIncoming,
  Folder,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { formatDateAndHour } from 'utils/dateManager';
import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Button, { ButtonProps } from 'components/base/Button';
import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';

import CreateUpdate from 'components/workOrder/CreateUpdate';
import MoreActions, { ActionsProps } from 'components/workOrder/MoreActions';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useUsers from 'hooks/useUsers';
import { DashboardEnquiry } from 'models/Enquiry';
import { SimpleUser } from 'models/User';

import { EnquiryAssignModal } from '../../EnquiryAssignModal';
import EnquiryInfo from '../Info';

import './index.scss';
import FinishEnquiry from './finishEnquiry';

type EnquiryCardProps = {
  callBack?: () => void;
  cardRef?: MutableRefObject<HTMLDivElement>;
  enquiry: DashboardEnquiry;
};

type Modals = {
  finish: ModalAttributes;
};

type ModalAttributes = {
  buttons?: ButtonProps[];
  body: JSX.Element;
  showCancel: boolean;
  title: string;
};

const EnquiryCard: FC<EnquiryCardProps> = ({
  enquiry,
  callBack = () => false,
  cardRef,
}) => {
  const { t } = useTranslation();
  const { user } = useProvidedAuth();
  const [loading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [chosenModal, setChosenModal] = useState<keyof Modals>('finish');
  const [isAssignModalVisible, setIsAssignModalVisible] = useState<boolean>(false);
  const [assignedUsers, setAssignedUsers] = useState<Array<SimpleUser>>([]);
  const [comment, setComment] = useState<string>('');
  const { users } = useUsers([SimpleUserRequestPermissionNames.ENQUIRY_READ]);

  useEffect(() => {
    const assignedUser = users.find((u: SimpleUser) => u.id === enquiry.assignedTo);
    setAssignedUsers(assignedUser ? [assignedUser] : []);
  }, [users, enquiry.assignedTo]);

  const closeAndResetModal = () => {
    setShowConfigModal(false);
    callBack();
  };

  const successCloseAndResetModal = (success: string) => {
    setShowConfigModal(false);
    showSuccessToast(success);
    callBack();
  };

  const finishEnquiryRequest = useCallback(() => {
    setLoadingModal(true);
    if (user) {
      config.apiFunus.enquiries
        .finish(enquiry?.id, comment)
        .then((res) => {
          successCloseAndResetModal(t('order.usersUpdated'));
          setLoadingModal(false);
          setShowConfigModal(false);
          return res;
        })
        .catch(() => {
          showErrorToast(t('order.usersError'));
          setLoadingModal(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, comment, enquiry?.id, t]);

  const generateAcceptButton: (
    onClick?: () => void,
    disabled?: boolean
  ) => ButtonProps = (onClick, disabled) => ({
    color: 'primary',
    disabled,
    id: 'accept',
    onClick: () => (onClick ? onClick() : true),
    text: t('common.accept'),
    type: 'button',
  });

  const buttonsFinisheEnquiryModal = [generateAcceptButton(finishEnquiryRequest)];

  const modals: Modals = {
    finish: {
      body: (
        <FinishEnquiry
          enquiry={enquiry}
          setComment={setComment}
        />
      ),
      buttons: buttonsFinisheEnquiryModal,
      showCancel: true,
      title: t('enquiry.finishEnquiry'),
    },
  };

  const openConfigModal = (key: keyof Modals) => {
    setChosenModal(key);
    setShowConfigModal(true);
  };

  const getMoreActionsOptions = (changeFlag: boolean) => {
    const options: Array<ActionsProps> = [];

    if (changeFlag) {
      options.push({
        key: 'finish',
        onClick: () => openConfigModal('finish'),
        text: t('enquiry.finishEnquiry'),
      });
    }

    if (options.length === 0) {
      options.push({
        key: 'nothing',
        onClick: () => undefined,
        text: t('order.noOptions'),
      });
    }

    return options;
  };

  const expandDetails = () => {
    setShowDetails(!showDetails);
  };

  const showAssignModal = (): void => setIsAssignModalVisible(true);
  const closeAssignModal = () => {
    setIsAssignModalVisible(false);
    callBack();
  };

  return (
    <div
      ref={cardRef}
      className={classNames('enquiry-card', {
        normal: enquiry.assignedTo,
        'not-assigned': !enquiry.assignedTo,
      })}
    >
      <CreateUpdate
        callBack={callBack}
        show={showEdit}
        title={t('order.edit')}
        workorderId={enquiry.id}
        onHide={() => setShowEdit(false)}
      />
      <EnquiryAssignModal
        enquiry={enquiry}
        show={isAssignModalVisible}
        onHide={closeAssignModal}
      />
      <Modal
        buttons={modals[chosenModal].buttons}
        className="enquiry-card-modal"
        show={showConfigModal}
        showCancel={modals[chosenModal].showCancel}
        title={modals[chosenModal].title}
        onHide={closeAndResetModal}
      >
        {showConfigModal && !loading && modals[chosenModal].body}
        {loading && <Loader />}
      </Modal>
      <div className="enquiry-header">
        <div>
          <PhoneIncoming data-testid="phoneincoming-icon" />
          <div>{t('enquiry.enquiry')}</div>
        </div>
        <div className="enquiry-record">

          <Folder data-testid="folder-icon" />
          <div>
            <h6>{enquiry.id || ''}</h6>
            {`${enquiry.firstSurname || ''} ${enquiry.secondSurname || ''}, ${enquiry.name || ''}`}
          </div>
          <div className="icon-more-actions">
            <MoreActions
              options={getMoreActionsOptions(enquiry.changeFlag)}
              toggleIcon={
                <MoreVertical className="icon-in-right" data-testid="mote-vertical-icon" size={40} />
                }
            />
          </div>

        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="order-info">
          <span className="order-date">{formatDateAndHour(enquiry.dateTime)}</span>
          <div className="d-flex align-items-center">
            <User data-testid="user-icon" />
            <div className="d-flex align-items-center">
              {assignedUsers?.length > 0
                ? assignedUsers
                  .map((assignedUser) => `${assignedUser.firstSurname || ''} ${assignedUser.secondSurname || ''}, ${assignedUser.name || ''}`)
                  .join(', ')
                : t('order.notAssigned')}
              {enquiry.assignFlag && (
              <div className="icon-add-user">
                <Button
                  color="transparent"
                  id="button-add-assigned"
                  onClick={() => showAssignModal()}
                >
                  <PlusCircle className="icon-in-right" data-testid="plus-icon" size={18} />
                </Button>
              </div>
              )}
            </div>
          </div>
        </div>
        {!!user && (
          <Button
            className={classNames('enquiry-more-data',
              {
                normal: enquiry.assignedTo,
                'not-assigned': !enquiry.assignedTo,
              })}
            color="transparent"
            onClick={expandDetails}
          >
            <span className="order-comment">{t('order.details.details')}</span>
            <ChevronDown
              className={
                showDetails
                  ? classNames('chevron-down', 'rotate')
                  : 'chevron-down'
              }
            />
          </Button>
        )}
        <EnquiryInfo
          enquiry={enquiry}
          expanded={showDetails}
          loading={loading}
        />
      </div>
      {loadingModal && <Loader />}
    </div>
  );
};

export default EnquiryCard;
