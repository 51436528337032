import React, { FC, ReactElement, ReactNode } from 'react';

import './index.scss';

export type SidebarTextProps = {
  icon?: ReactElement | undefined;
  content: string | ReactElement | ReactNode;
};

const SidebarText: FC<SidebarTextProps> = ({ content, icon }) => (
  <div className="sidebar-text">
    {icon}
    {content}
  </div>
);

export default SidebarText;
