import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useCountries } from 'hooks/useCountries';
import { IMasterDataDto } from 'models/MasterData';

import Select from '../Select';

import { CountriesComponent } from './types';

export const Countries: FC<CountriesComponent> = ({ disabled = false, value, onChange }) => {
  const { t } = useTranslation();
  const { countries, getCountry } = useCountries();

  const country = getCountry(value);

  return (
    <Select<IMasterDataDto>
      disabled={disabled}
      getLabel={(element) => element.description}
      getValue={(element) => element.code}
      limit={15}
      name="countries"
      options={countries}
      placeholder={t('common.country')}
      value={country || undefined}
      searchable
      onChange={(element) => {
        onChange(element?.code || '');
      }}
    />
  );
};
