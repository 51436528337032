import classNames from 'classnames';
import React, {
  createRef,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import Button from 'components/base/Button';

import useClickOutside from 'hooks/useClickOutside';
import useWindowSize from 'hooks/useWindowSize';

import './index.scss';

type DropdownOptions = {
  color?: 'primary' | 'secondary' | 'attention' | 'special' | 'transparent';
  className?: string | undefined;
  icon?: ReactElement | undefined;
  key: string | number;
  onClick?(): void | undefined;
  text?: string | undefined;
  url?: string | undefined;
};

export type DropdownProps = {
  buttonContent?: ReactElement | ReactNode | undefined;
  buttonColor?:
    | 'primary'
    | 'secondary'
    | 'attention'
    | 'special'
    | 'transparent';
  className?: string | undefined;
  disabled?: boolean | undefined;
  options: DropdownOptions[];
};

const Dropdown: FC<DropdownProps> = ({
  buttonColor = 'transparent',
  buttonContent,
  className,
  disabled,
  options,
}) => {
  const [expanded, setExpanded] = useState(false);
  const dropdownRef = createRef<HTMLDivElement>();
  const { height, width } = useWindowSize();

  const closeDropdown = () => setExpanded(false);

  useClickOutside(dropdownRef, closeDropdown);

  const onClickOption = (optOnClick?: () => void) => {
    closeDropdown();
    if (optOnClick) {
      optOnClick();
    }
  };

  useEffect(() => {
    closeDropdown();
  }, [height, width]);

  return (
    <div ref={dropdownRef} className={classNames('funus-dropdown', className)}>
      <Button
        color={buttonColor}
        disabled={disabled}
        onClick={() => setExpanded(!expanded)}
      >
        {buttonContent}
      </Button>
      <div className={classNames('dropdown-content', { expanded })}>
        {options.map((opt) => {
          if (opt.url) {
            return (
              <Button
                key={opt.key}
                color="transparent"
                leftAddon={opt.icon}
                text={opt.text}
                to={opt.url}
                onClick={() => onClickOption(opt.onClick)}
              />
            );
          }

          return (
            <Button
              key={opt.key}
              color={opt.color}
              disabled={!expanded}
              leftAddon={opt.icon}
              text={opt.text}
              onClick={() => onClickOption(opt.onClick)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
