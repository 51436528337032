import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useWhatsapp } from 'hooks/useWhatsapp';

import { SendWhatsappModalComponent } from './types';

export const SendWhatsappModal: FC<SendWhatsappModalComponent> = ({
  show, number, onCancel, language, onSuccess, recordId,
}) => {
  const { t } = useTranslation();
  const { sendMessage } = useWhatsapp();

  const send = useCallback(
    () => {
      sendMessage({
        language, number, recordId,
      })
        .then((response) => {
          onSuccess(response);
          return response;
        })
        .catch(() => false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language, number],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          onClick: () => {
            send();
          },
          text: t('common.send'),
        },
      ]}
      className="ia-modal"
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      title={t('record.whatsapp.title')}
      onHide={() => {
        onCancel();
      }}
    >
      {show && (
        <div>
          {t('record.whatsapp.whatsappConfirmation', { number })}
        </div>
      )}
    </Modal>
  );
};
