import classNames from 'classnames';
import React, {
  createRef,
  FocusEventHandler,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChevronDown } from 'react-feather';

import Button from 'components/base/Button';
import Input from 'components/base/Input';

import useClickOutside from 'hooks/useClickOutside';
import useKeyboardClick from 'hooks/useKeyboardClick';
import useOnBlur from 'hooks/useOnBlur';
import useWindowSize from 'hooks/useWindowSize';

import './index.scss';

export type SelectProps<T> = {
  className?: string | undefined;
  clearable?: boolean | undefined;
  disabled?: boolean | undefined;
  error?: string | boolean | undefined;
  getLabel: (elem: T) => string;
  getValue: (elem: T) => string | number;
  limit?: number;
  name: string;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  onChange(elem?: T): void;
  options: T[];
  placeholder?: string | undefined;
  openUp?: boolean;
  searchable?: boolean | undefined;
  value?: T | undefined;
  id?: string;
  testid?: string;
  itemContent?: (data: T) => ReactElement<unknown>;
  title?: string;
};

function Select<T>({
  className,
  clearable,
  disabled,
  error,
  getLabel,
  getValue,
  limit,
  name,
  onBlur,
  onChange,
  openUp = false,
  options,
  placeholder,
  searchable,
  title,
  value,
  testid,
  itemContent,
  ...rest
}: SelectProps<T>): ReactElement {
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState<string>();
  const [filteredOptions, setFilteredOptions] = useState<T[]>([]);
  const [focusedOption, setFocusedOption] = useState<number>(-1);
  const [max, setMax] = useState<number>(5);
  const [min, setMin] = useState<number>(0);
  const [openTop, setOpenTop] = useState(false);
  const selectRef = createRef<HTMLDivElement>();
  const { height, width } = useWindowSize();
  const optionsRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const closeSelect = () => {
    if (expanded) {
      setExpanded(false);
      setFocusedOption(-1);
    }
  };

  useClickOutside(selectRef, closeSelect);
  useOnBlur(selectRef, closeSelect);

  const filterOptions = (val?: string) => {
    setFilter(val);
    if (val || val === '') {
      onChange();
    }
  };

  const onClickOption = (elem: T) => {
    filterOptions();
    onChange(elem);
    closeSelect();

    inputRef?.current?.focus();
    setExpanded(false);
  };

  const changeExpanded = (expand: boolean) => {
    if (!disabled) {
      setExpanded(expand);
    }
  };

  useEffect(() => {
    if (filter) {
      let newOptions = [...options].filter(
        (opt) => getLabel(opt)?.toLowerCase().includes(filter.toLowerCase()),
      );
      if (limit) {
        newOptions = newOptions.splice(0, limit);
      }
      setFilteredOptions(newOptions);
    } else {
      setFilteredOptions(
        !!limit && limit > 0 && options?.length
          ? [...options].splice(0, limit)
          : [...options] || [],
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, options, limit]);

  useEffect(() => {
    closeSelect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width]);

  useEffect(() => {
    if (selectRef?.current && height) {
      const { top } = selectRef.current.getBoundingClientRect();
      setOpenTop(top / height > 0.7 || openUp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, selectRef, height]);

  const getInputValue = (): string => {
    if (value) {
      return getLabel(value);
    }
    if (searchable && filter) {
      return filter;
    }
    return '';
  };

  const optionMouseOver = (index: number) => {
    setFocusedOption(index);
  };

  const onClickUp = (event: KeyboardEvent) => {
    if (expanded && focusedOption > -1 && event) {
      const newIndex = focusedOption - 1;
      setFocusedOption(newIndex);
      if (newIndex <= min) {
        (
          optionsRef.current?.childNodes?.[newIndex] as HTMLButtonElement
        )?.focus();
        setMin(newIndex);
        setMax(newIndex + 5);
      }
    }
  };

  const onClickDown = (event: KeyboardEvent) => {
    if (expanded && focusedOption < filteredOptions.length - 1 && event) {
      const newIndex = focusedOption + 1;
      setFocusedOption(newIndex);
      if (newIndex >= max && max < filteredOptions?.length) {
        (
          optionsRef.current?.childNodes?.[newIndex] as HTMLButtonElement
        )?.focus();
        setMax(newIndex);
        setMin(newIndex - 5);
      }
    }
  };

  const onClickEnter = () => {
    if (
      expanded
      && focusedOption > -1
      && focusedOption < filteredOptions.length
    ) {
      onClickOption(filteredOptions[focusedOption]);
    }
    closeSelect();
  };

  useKeyboardClick(selectRef, {
    onClickDown,
    onClickEnter,
    onClickEscape: closeSelect,
    onClickUp,
  });

  return (
    <div
      ref={selectRef}
      className={classNames('funus-select', className, {
        disabled,
        expanded,
        'open-top': openTop,
      })}
      data-testid={testid}
    >
      <Input
        autoComplete="false"
        clearable={clearable}
        data-testid={`${testid}-input`}
        disabled={disabled}
        error={error}
        id={name}
        inputRef={inputRef}
        name={name}
        placeholder={placeholder}
        readOnly={!searchable}
        rightAddon={(
          <ChevronDown
            className={classNames({ expanded })}
            onClick={() => changeExpanded(!expanded)}
          />
        )}
        title={title || ''}
        value={getInputValue()}
        onBlur={onBlur}
        onChange={
          searchable || clearable
            ? (val: string) => filterOptions(val)
            : undefined
        }
        onClick={() => changeExpanded(true)}
        onFocus={() => changeExpanded(true)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <div
        ref={optionsRef}
        className={classNames('select-options', { expanded })}
      >
        {filteredOptions?.length > 0
          && filteredOptions.map((opt, idx) => (!itemContent ? (
            <Button
              key={`${name}-${getLabel(opt)}-${getValue(opt)}`}
              className={classNames({
                focused: idx === focusedOption,
                selected: value === opt,
              })}
              color="transparent"
              disabled={!expanded}
              text={getLabel(opt)}
              onClick={() => onClickOption(opt)}
              onMouseOver={() => optionMouseOver(idx)}
            />
          ) : (
            <Button
              key={`${name}-${getLabel(opt)}-${getValue(opt)}`}
              className={classNames({
                focused: idx === focusedOption,
                selected: value === opt,
              })}
              color="transparent"
              disabled={!expanded}
              onClick={() => {
                onClickOption(opt);
              }}
              onMouseOver={() => optionMouseOver(idx)}
            >
              {itemContent(opt)}
            </Button>
          )))}
      </div>
    </div>
  );
}

export default Select;
