import OrderType from 'models/OrderType';
import Workorder from 'models/Workorder';

/**
 * 0-Recogida
 * 1-Manuales
 * 2-Práctica Sanitaria
 * 3-Preparación
 * 4-Ceremonia
 * 5-Incineración
 * 6-Traslado
 * -----
 * 7-Manuales
 * 8-Preparación de nicho
 * 9-Inhumación
 */

export const getNumberByType = (type: OrderType): number => {
  const sortedOrderTypes: OrderType[] = [
    OrderType.PICKUP,
    OrderType.RECEPTION,
    OrderType.HANDBOOK,
    OrderType.SANITARY_PRACTICE,
    OrderType.PREPARATION,
    OrderType.CEREMONY,
    OrderType.INCINERATION,
    OrderType.TRANSFER,
    OrderType.HANDBOOK_CEMETERY,
    OrderType.PREPARATION_NICHO,
    OrderType.INHUMATION,
  ];
  const orderTypeIndex = sortedOrderTypes.findIndex((orderType: OrderType) => orderType === type);

  return orderTypeIndex !== -1 ? orderTypeIndex : sortedOrderTypes.length;
};

export const sortOrderTypes = (
  a: OrderType, b: OrderType,
): number => (getNumberByType(a) > getNumberByType(b) ? 1 : -1);

const compareWorkorders = (first: Workorder, second: Workorder): number => {
  if (
    (first.assignedUsers?.length && second.assignedUsers?.length)
    || (!first.assignedUsers?.length && !second.assignedUsers?.length)
  ) {
    const firstOrder = getNumberByType(first.type);
    const secondOrder = getNumberByType(second.type);
    if (firstOrder > secondOrder) {
      return 1;
    }
    if (firstOrder < secondOrder) {
      return -1;
    }
  } else {
    if (first.assignedUsers?.length && !second.assignedUsers?.length) {
      return 1;
    }
    if (!first.assignedUsers?.length && second.assignedUsers?.length) {
      return -1;
    }
  }
  return 0;
};

export const sortWorkorders = (workorders: Workorder[]): Workorder[] => workorders.sort(
  (first, second) => compareWorkorders(first, second),
);
