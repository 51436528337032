import React, {
  useEffect, useState, useRef, FC,
} from 'react';
import { Message } from 'stompjs';

import { useSocket } from 'hooks/useSocket';

const WebSocketExample: FC = () => {
  const [allMessages, setAllMessages] = useState<string[]>([]);
  const [lastMessage, setLastMessage] = useState<string>('');
  const [connected, setConnected] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>();
  const {
    client, connectSocket, getChannel, getSubscription,
  } = useSocket();

  const onMessage = (message: Message) => {
    setAllMessages((arr) => [...arr, message.body || '']);
    setLastMessage(message.body || '');
  };

  useEffect(() => {
    connectSocket(() => setConnected(true));
  }, [connectSocket]);

  useEffect(() => {
    if (connected && client?.connected) {
      client.subscribe(getSubscription(), onMessage);
    }
  }, [client, connected, getSubscription]);

  const resetInput = () => {
    inputRef.current.value = '';
  };

  const sendData = (message: string, callBack?: () => void) => {
    if (message && client?.connected) {
      client.send(getChannel('MATARO', 'WORKSHOP'), {}, message);
      if (callBack) {
        callBack();
      }
    }
  };

  return (
    <div className="d-flex flex-column text-center m-4">
      <h4>WebSocket</h4>
      <div className="mb-4 mt-4">
        Status:
        {' '}
        {connected ? <strong>CONNECTED</strong> : 'DISCONNECTED'}
      </div>
      <div>
        <input ref={inputRef} disabled={!connected} type="text" />
        <button
          disabled={!connected}
          type="button"
          onClick={() => sendData(inputRef.current.value, resetInput)}
        >
          Send data
        </button>
      </div>
      <div className="mb-4 mt-4">
        Last message received:
        {' '}
        <strong>{lastMessage || '-'}</strong>
      </div>
      {allMessages?.length > 0
        && allMessages.map((msg, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`msg-${idx}`}>
            Message
            {' '}
            {idx + 1}
            :
            {' '}
            <strong>{msg}</strong>
          </div>
        ))}
    </div>
  );
};

export default WebSocketExample;
