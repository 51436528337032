import React, { FC } from 'react';

import OrderType from 'models/OrderType';

import Ceremony from './Ceremony';
import CremationInfo from './Cremation';
import DeceasedPreparationInfo from './DeceasedPreparation';
import DefaultInfo, { DefaultInfoProps } from './Default';
import GravestoneInfo from './Gravestone';
import IntermentInfo from './Interment';
import InternalMovements from './InternalMovements';
import { MovementInfo } from './Movement';
import NichePreparationInfo from './NichePreparation';
import PickupInfo from './Pickup';
import Reception from './Reception';
import SanitaryPractice from './SanitaryPractice';
import TransferRemainsInfo from './TransferRemains';
import TransfersInfo from './Transfers';
import TransportInfo from './Transport';
import VigilInfo from './Vigil';

const WorkOrderInfo: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  if (order?.type === OrderType.PICKUP) {
    return <PickupInfo expanded={expanded} loading={loading} order={order} />;
  }
  if (order?.type === OrderType.CEREMONY) {
    return <Ceremony expanded={expanded} loading={loading} order={order} />;
  }
  if (order?.type === OrderType.GRAVESTONE) {
    return (
      <GravestoneInfo expanded={expanded} loading={loading} order={order} />
    );
  }
  if (order?.type === OrderType.INCINERATION) {
    return (
      <CremationInfo expanded={expanded} loading={loading} order={order} />
    );
  }
  if (order?.type === OrderType.INHUMATION) {
    return (
      <IntermentInfo expanded={expanded} loading={loading} order={order} />
    );
  }
  if (order?.type === OrderType.INTERNAL_MOVEMENT) {
    return (
      <InternalMovements expanded={expanded} loading={loading} order={order} />
    );
  }
  if (order?.type === OrderType.RECEPTION) {
    return <Reception expanded={expanded} loading={loading} order={order} />;
  }
  if (order?.type === OrderType.SANITARY_PRACTICE) {
    return (
      <SanitaryPractice expanded={expanded} loading={loading} order={order} />
    );
  }
  if (order?.type === OrderType.PREPARATION_NICHO) {
    return (
      <NichePreparationInfo
        expanded={expanded}
        loading={loading}
        order={order}
      />
    );
  }
  if (order?.type === OrderType.PREPARATION) {
    return (
      <DeceasedPreparationInfo
        expanded={expanded}
        loading={loading}
        order={order}
      />
    );
  }
  if (order?.type === OrderType.TRANSFER_REMAINS) {
    return (
      <TransferRemainsInfo
        expanded={expanded}
        loading={loading}
        order={order}
      />
    );
  }
  if (order?.type === OrderType.TRANSFER) {
    return (
      <TransfersInfo expanded={expanded} loading={loading} order={order} />
    );
  }
  if (order?.type === OrderType.TRANSPORT) {
    return (
      <TransportInfo expanded={expanded} loading={loading} order={order} />
    );
  }

  if (order?.type === OrderType.MOVEMENT) {
    return (
      <MovementInfo expanded={expanded} loading={loading} order={order} />
    );
  }

  if (order.type === OrderType.VIGIL) {
    return (<VigilInfo expanded={expanded} loading={loading} order={order} />);
  }

  return <DefaultInfo expanded={expanded} loading={loading} order={order} />;
};

export default WorkOrderInfo;
