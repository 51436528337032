import classNames from 'classnames';
import React, { FC } from 'react';

import { Classable } from 'modules/budget/components/types/helpers';

import { useRowStyles } from './styles';

import './index.scss';

interface RowComponent extends Classable {
  justifyContent?: React.CSSProperties['justifyContent'];
  style?: React.CSSProperties;
  onClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

const Row: FC<RowComponent> = ({
  children, style, justifyContent, onClick, className,
}: RowComponent) => {
  const { rowStyle } = useRowStyles({ justifyContent });

  const clones = React.Children.map(
    children,
    (child) => React.cloneElement(child, { className: classNames(child.props.className, 'f-row-item') }),
  );

  return (
    <div
      className={classNames('f-row', className)}
      role="presentation"
      style={{
        ...rowStyle,
        ...style,
      }}
      onClick={() => onClick?.()}
      onKeyPress={() => false}
    >
      {clones}
    </div>
  );
};

Row.defaultProps = {
  justifyContent: 'start',
  onClick: () => false,
  style: {},
};

export { Row };
