import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import Tooltip from 'components/base/Tooltip';

import { useBudget } from 'modules/budget/hooks/useBudget';

import './index.scss';

export const DiscardedArticles: FC<Record<string, unknown>> = () => {
  const { t } = useTranslation();
  const { discardedArticles, RestoreDiscardedArticle, articlesLoading } = useBudget();

  return (
    <table className="f-discarded-articles">
      <tbody>
        {discardedArticles.map((item) => (
          <tr key={item.code}>
            <td>{item.code}</td>
            <td>{item.description}</td>
            <td className="f-discarded-articles-actions">
              <Tooltip content={t('budget.actions.restoreDiscarded')}>
                <Button
                  color="transparent"
                  disabled={articlesLoading}
                  onClick={() => {
                    RestoreDiscardedArticle(item);
                  }}
                >
                  <CustomIcon icon={OtherTypes.RESTORE} />
                </Button>
              </Tooltip>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
