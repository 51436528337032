import { ISaveConfigurationRequest } from 'modules/budget/api/budgetMasterDataClient/types';

export interface ValidatorHook {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IACIFSchema: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IADniSchema: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IANIESchema: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IAPassportSchema: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IAUploadDocumentSchema: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateArticleAddRequest: (values: any) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateArticleDisagreement: (values: any) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateBudgetRule: (values: ISaveConfigurationRequest) => Promise<any>;
}

export enum ValidationSchemas {
  ARTICLE = 'article',
  ARTICLE_DISAGREEMENT = 'articleDisagreement',
  BUDGET_RULE = 'budgetRule'
}
