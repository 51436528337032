import { RolesAction } from 'actions/roles';

import UserRole, { PermissionGroupDto } from 'models/UserRole';
import { rolesTypes } from 'types/roles';

export interface IRolesReducerState {
  normalizedRoles: NormalizedRole;
  roles: UserRole[];
  permissions: PermissionGroupDto[];
}

interface NormalizedRole {
  [key: string]: UserRole;
}

interface RolesNormalized {
  masterData: UserRole[];
  normalized: NormalizedRole;
}

const initState: IRolesReducerState = {
  normalizedRoles: {},
  roles: [],
  permissions: [],
};

export default (
  state: IRolesReducerState = initState,
  action: RolesAction,
): IRolesReducerState => {
  switch (action.type) {
    case rolesTypes.ADD_ROLES:
      return {
        ...state,
        roles: (action.payload as RolesNormalized).masterData,
        normalizedRoles: (action.payload as RolesNormalized).normalized,
      };
    case rolesTypes.ADD_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};
