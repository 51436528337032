import classNames from 'classnames';
import React, { FC } from 'react';
import {
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Clock,
  Edit, Eye, FilePlus, Package, Send, Settings, Trash, Trash2,
  Trello,
  UserPlus,
} from 'react-feather';
import { ReactSVG } from 'react-svg';

import acceptFlowersRequest from 'assets/icons/accept-flowers-request.svg';
import addDisagreement from 'assets/icons/add-article-disagreement.svg';
import affectedQuestions from 'assets/icons/affected_questions_alert.svg';
import assignRecord from 'assets/icons/assign_record.svg';
import budgets from 'assets/icons/budget.svg';
import camera from 'assets/icons/camera.svg';
import cancelFlowers from 'assets/icons/cancel-flowers.svg';
import ceremony from 'assets/icons/ceremonia.svg';
import acceptBudget from 'assets/icons/check-circle.svg';
import pendant from 'assets/icons/colgantes.svg';
import deposit from 'assets/icons/deposito.svg';
import invoice from 'assets/icons/dollar-sign.svg';
import duplicate from 'assets/icons/duplicate.svg';
import edit from 'assets/icons/edit.svg';
import fastForward from 'assets/icons/fast-forward.svg';
import flowers from 'assets/icons/flores.svg';
import cremation from 'assets/icons/incineracion.svg';
import interment from 'assets/icons/inhumacion.svg';
import gravestone from 'assets/icons/lapida.svg';
import email from 'assets/icons/mail.svg';
import movement from 'assets/icons/movement.svg';
import internalMovement from 'assets/icons/movimiento-interno.svg';
import noElements from 'assets/icons/no-elements.svg';
import manualWorkorder from 'assets/icons/orden-trabajo-manual.svg';
import sign from 'assets/icons/pen-tool.svg';
import add from 'assets/icons/plus-circle.svg';
import sanitaryPractice from 'assets/icons/practica-sanitaria.svg';
import nichePreparation from 'assets/icons/preparacion-nicho.svg';
import preparation from 'assets/icons/preparacion.svg';
import print from 'assets/icons/printer.svg';
import qr from 'assets/icons/qr.svg';
import reception from 'assets/icons/recepcion-cadaver.svg';
import pickup from 'assets/icons/recogida.svg';
import reliquary from 'assets/icons/relicarios.svg';
import budgetBackward from 'assets/icons/rewind.svg';
import restore from 'assets/icons/rotate-ccw.svg';
import save from 'assets/icons/save.svg';
import back from 'assets/icons/skip-back.svg';
import next from 'assets/icons/skip-forward.svg';
import budgetsConfig from 'assets/icons/tool.svg';
import trash from 'assets/icons/trash.svg';
import remainTransfer from 'assets/icons/traslado-restos.svg';
import transfer from 'assets/icons/traslado.svg';
import vigil from 'assets/icons/velatorio.svg';

import { MasterServiceDtoType } from 'config/apiFunus/generated/data-contracts';
import OrderType from 'models/OrderType';

import { OtherTypes, CustomIconProps, FeatherIconTypes } from './types';

const CustomIcon: FC<CustomIconProps> = ({ className, icon }) => {
  const typeIcon = {
    [MasterServiceDtoType.DEPOSIT]: deposit,
    [MasterServiceDtoType.MOVIMIENTO]: movement,
    [MasterServiceDtoType.TRANSPORTE]: transfer,
    [MasterServiceDtoType.VELATORIO]: vigil,
    // [MasterServiceDtoType.CEREMONIA]: ceremony,
    // [MasterServiceDtoType.INCINERACION]: cremation,
    // [MasterServiceDtoType.INHUMACION]: interment,
    // [MasterServiceDtoType.PREPARACION]: preparation,

    [OrderType.CEREMONY]: ceremony,
    [OrderType.GRAVESTONE]: gravestone,
    [OrderType.HANDBOOK_CEMETERY]: manualWorkorder,
    [OrderType.HANDBOOK]: manualWorkorder,
    [OrderType.INCINERATION]: cremation,
    [OrderType.INHUMATION]: interment,
    [OrderType.INTERNAL_MOVEMENT]: internalMovement,
    [OrderType.PICKUP]: pickup,
    [OrderType.PREPARATION_NICHO]: nichePreparation,
    [OrderType.PREPARATION]: preparation,
    [OrderType.RECEPTION]: reception,
    [OrderType.SANITARY_PRACTICE]: sanitaryPractice,
    [OrderType.TRANSFER_REMAINS]: remainTransfer,
    [OrderType.TRANSFER]: transfer,
    [OrderType.VIGIL]: vigil,

    [OtherTypes.ACCEPT_FLOWERS_REQUEST]: acceptFlowersRequest,
    [OtherTypes.ADD_ARTICLE_DISAGREEMENT]: addDisagreement,
    [OtherTypes.ADD]: add,
    [OtherTypes.AFFECTED_QUESTIONS_ALERT]: affectedQuestions,
    [OtherTypes.ARTICLE_DRAFT_EDIT]: edit,
    [OtherTypes.ASSIGN_RECORD]: assignRecord,
    [OtherTypes.BUDGET_ACCEPT]: acceptBudget,
    [OtherTypes.BUDGET_CONFIG]: budgetsConfig,
    [OtherTypes.BUDGET_EDIT_QUESTION]: edit,
    [OtherTypes.BUDGET]: budgets,
    [OtherTypes.CANCEL_FLOWERS]: cancelFlowers,
    [OtherTypes.DELETE]: trash,
    [OtherTypes.DUPLICATE]: duplicate,
    [OtherTypes.EMAIL]: email,
    [OtherTypes.FLOWERS]: flowers,
    [OtherTypes.INVOICE]: invoice,
    [OtherTypes.MOVE_BACK]: back,
    [OtherTypes.MOVE_END]: fastForward,
    [OtherTypes.MOVE_NEXT]: next,
    [OtherTypes.NO_ELEMENTS]: noElements,
    [OtherTypes.PENDANT]: pendant,
    [OtherTypes.PRINT_ARTICLE_COMERCIAL]: print,
    [OtherTypes.PRINT]: print,
    [OtherTypes.QR]: qr,
    [OtherTypes.RELIQUARY]: reliquary,
    [OtherTypes.SAVE]: save,
    [OtherTypes.SIGN]: sign,
    [OtherTypes.PHOTO]: camera,
    [OtherTypes.RESTORE]: restore,
    [OtherTypes.BUDGET_BACKWARD]: budgetBackward,

    [FeatherIconTypes.CHECKCIRCLE]: <CheckCircle />,
    [FeatherIconTypes.CHEVRONLEFT]: <ChevronLeft />,
    [FeatherIconTypes.CHEVRONRIGHT]: <ChevronRight />,
    [FeatherIconTypes.CLOCK]: <Clock />,
    [FeatherIconTypes.EDIT]: <Edit />,
    [FeatherIconTypes.EYE]: <Eye />,
    [FeatherIconTypes.FILEPLUS]: <FilePlus />,
    [FeatherIconTypes.PACKAGE]: <Package />,
    [FeatherIconTypes.SEND]: <Send />,
    [FeatherIconTypes.SETTINGS]: <Settings />,
    [FeatherIconTypes.TRASH]: <Trash />,
    [FeatherIconTypes.TRASH2]: <Trash2 />,
    [FeatherIconTypes.TRELLO]: <Trello />,
    [FeatherIconTypes.USERPLUS]: <UserPlus />,
  };

  if (Object.values(FeatherIconTypes).includes(icon as FeatherIconTypes)) {
    return (typeIcon[icon]);
  }

  return (
    <ReactSVG
      className={classNames('custom-icon', className, {
        fill: typeIcon[icon] === typeIcon[OrderType.PICKUP], // ||
        hand: typeIcon[icon] === typeIcon[OrderType.HANDBOOK],
      })}
      data-testid={`icon-${icon}`}
      src={typeIcon[icon]}
    />
  );
};

export default CustomIcon;
