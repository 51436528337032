import classNames from 'classnames';
import React, { FC } from 'react';

import './index.scss';

export type MessageProps = {
  className?: string | undefined;
  type: 'info' | 'error' | 'warning' | 'success';
};

const Message: FC<MessageProps> = ({ className, children, type }) => (
  <div className={classNames('funus-message', className, type)}>{children}</div>
);

export default Message;
