import React from 'react';

import { ServiceInformationDto } from 'config/apiFunus/types';
import '../index.scss';
import { useVigilLocation } from 'hooks/useVigilLocation';

import { DateTimeInfo } from '../DateTimeInfo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const VigilCell = ({ value }: { value: ServiceInformationDto}) => {
  const { getVigilLocation } = useVigilLocation(value.city);

  return (
    <React.Fragment>
      <div className="text-align-center">{`${getVigilLocation(value.location)?.description || '--'}`}</div>
      <DateTimeInfo date={value.date} time={`${value.date} ${value.time}`} />
    </React.Fragment>
  );
};
