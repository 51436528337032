export const masterDataTypes = {
  ADD_MASTER_DATA_ABORTION_TYPES: '[Master Data] ABORTION_TYPES ADD',
  ADD_MASTER_DATA_AIRPORTS: '[Master Data] AIRPORT ADD',
  ADD_MASTER_DATA_ASHES_DESTINATIONS: '[Master Data] ASHES_DESTINATION ADD',
  ADD_MASTER_DATA_CEMETERIES: '[Master Data] CEMETERIES ADD',
  ADD_MASTER_DATA_CENTERS: '[Master Data] CENTERS ADD',
  ADD_MASTER_DATA_CEREMONIES: '[Master Data] CEREMONIES ADD',
  ADD_MASTER_DATA_CITIES: '[Master Data] CITIES ADD',
  ADD_MASTER_DATA_COFFIN_SIZES: '[Master Data] ADD_MASTER_DATA_COFFIN_SIZES',
  ADD_MASTER_DATA_CONCESSION_TYPES: '[Master Data] CONCESSION_TYPE ADD',
  ADD_MASTER_DATA_COUNTRIES: '[Master Data] COUNTRIES ADD',
  ADD_MASTER_DATA_CREMATORIES: '[Master Data] CREMATORIES ADD',
  ADD_MASTER_DATA_DEATH_TYPES: '[Master Data] DEATH_TYPES ADD',
  ADD_MASTER_DATA_FUNERARY_ORIGINS: '[Master Data] FUNERARY_ORIGIN ADD',
  ADD_MASTER_DATA_GENDERS: '[Master Data] GENDER ADD',
  ADD_MASTER_DATA_GROUP_CAUSES: '[Master Data] GROUP_CAUSES ADD',
  ADD_MASTER_DATA_INSURANCES: '[Master Data] INSURANCE ADD',
  ADD_MASTER_DATA_MARITAL_STATUS: '[Master Data] MARITAL_STATUS ADD',
  ADD_MASTER_DATA_NICHE_TYPES: '[Master Data] NICHE_TYPE ADD',
  ADD_MASTER_DATA_OVEN: '[Master Data] OVEN ADD',
  ADD_MASTER_DATA_OWNERS: '[Master Data] OWNER ADD',
  ADD_MASTER_DATA_PROVINCES: '[Master Data] PROVINCES ADD',
  ADD_MASTER_DATA_RECORD_TYPES: '[Master Data] RECORD_TYPES ADD',
  ADD_MASTER_DATA_RELIQUARYS: '[Master Data] RELIQUARY ADD',
  ADD_MASTER_DATA_SANITARY_PRACTICES: '[Master Data] SANITARY_PRACTICE ADD',
  ADD_MASTER_DATA_THANATOLOGISTS: '[Master Data] THANATOLOGISTS ADD',
  ADD_MASTER_DATA_THANATOPRACTORS: '[Master Data] THANATOPRACTORS ADD',
  ADD_MASTER_DATA_URNS: '[Master Data] URN ADD',
  ADD_MASTER_DATA_VIGIL_ROOM: '[Master Data] VIGIL_ROOM ADD',
  ADD_MASTER_DATA: '[Master Data] MASTER DATA ADD',
  CLEAR_MASTERDATA: '[Master Data] CLEAR_MASTERDATA',
};
