export const ceremonyPredefinedSelections = [
  {
    city: '08360',
    country: '011',
    description: 'Oratori de Mataró',
    location: '17',
    province: '08',
  },
  {
    city: '08285',
    country: '011',
    description: 'Oratori de Granollers',
    location: '9',
    province: '08',
  },
  {
    city: '08486',
    country: '011',
    description: 'Oratori de Pineda',
    location: '6',
    province: '08',
  },
  {
    city: '08849',
    country: '011',
    description: 'Oratori de Tordera',
    location: '14',
    province: '08',
  },
];
