import React, { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

import config from 'config';

import logo from 'assets/images/logo_funus.svg';

import './index.scss';

type GeneralErrorFallbackProps = {
  componentStack?: string | undefined;
  error: Error;
};

const GeneralErrorFallback: FC<GeneralErrorFallbackProps> = ({
  componentStack,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <div className="home-container">
      <div className="error-logo">
        <img
          alt="Error"
          className="logo-error-img"
          id="element-home-logo"
          src={logo}
        />
      </div>
      <div className="error-container">
        <Alert variant="danger">
          <Alert.Heading>{t('error.fallbackError.title')}</Alert.Heading>
          <Accordion defaultActiveKey="-1">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                {error.message}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>{t('error.fallbackError.error')}</strong>
                    &#58;&nbsp;
                    {error.message}
                  </p>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    <strong>{t('error.fallbackError.stacktrace')}</strong>
                    &#58;&nbsp;
                    {componentStack}
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <span>
            {t('error.fallbackError.go')}
            &nbsp;
            <a href={config.url.landing}>
              {t('error.fallbackError.dashboard')}
            </a>
          </span>
        </Alert>
      </div>
    </div>
  );
};

export default GeneralErrorFallback;
