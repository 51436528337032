import classNames from 'classnames';
import {
  Formik,
  Form,
  FormikProps,
  FormikHelpers,
  FormikErrors,
  FormikValues,
} from 'formik';
import React, { KeyboardEvent, ReactElement } from 'react';
import { CheckCircle, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { objectsAreDifferent } from 'utils/helpers';

import Button from 'components/base/Button';

import { i18n } from 'i18n';

import './index.scss';
import { clearAudit } from '../StepsForm/helper';

type FormGeneratorProps<T> = {
  cancelText?: string | undefined;
  className?: string | undefined;
  disabled?: boolean | undefined;
  editing?: boolean | undefined;
  fields(
    props: FormikProps<T>,
    disabled?: boolean,
    editing?: boolean
  ): ReactElement;
  initialValues: T;
  onCancel?(): void | undefined;
  onSave(values: T, actions: FormikHelpers<T>): void;
  saveText?: string | undefined;
  sendOnEnter?: boolean;
  showCancel?: boolean | undefined;
  showSave?: boolean | undefined;
  title?: string | ((vals: T) => string) | undefined;
  validateForm?: (values: T) => Promise<FormikErrors<FormikValues>>;
  validateOnBlur?: boolean | undefined;
  validateOnChange?: boolean | undefined;
};

const FormGenerator = <T extends Record<string, unknown>>({
  cancelText,
  className,
  disabled,
  editing,
  fields,
  initialValues,
  onCancel,
  onSave,
  saveText,
  sendOnEnter,
  showCancel,
  showSave,
  title,
  validateForm,
  validateOnBlur,
  validateOnChange,
}: FormGeneratorProps<T>): ReactElement => {
  const { t } = useTranslation();
  const clickCancel = (props: FormikProps<T>) => {
    props.resetForm();
    if (onCancel) {
      onCancel();
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (
      !sendOnEnter
      && event.key === 'Enter'
      && event.target.toString() !== '[object HTMLTextAreaElement]'
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className={classNames('form-generator', className)}>
      <Formik
        initialValues={initialValues || {}}
        validate={validateForm}
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        onSubmit={onSave}
      >
        {(props: FormikProps<T>) => (
          <Form autoComplete="off" className="fields" onKeyDown={onKeyDown}>
            <Prompt
              message={t('common.unsaved')}
              when={
                !disabled
                && objectsAreDifferent<T>(
                  clearAudit(initialValues),
                  clearAudit(props.values),
                )
              }
            />
            {title && (
              <h3>
                {typeof title === 'function' ? title(props.values) : title}
              </h3>
            )}
            {fields(props, disabled, editing)}
            {(showSave || showCancel) && (
              <div className="form-buttons">
                {showSave && (
                  <Button
                    disabled={props.isSubmitting || !props.isValid}
                    leftAddon={<CheckCircle />}
                    text={saveText}
                    type="submit"
                  />
                )}
                {showCancel && onCancel && (
                  <Button
                    color="secondary"
                    disabled={props.isSubmitting}
                    leftAddon={<XCircle />}
                    text={cancelText}
                    onClick={() => clickCancel(props)}
                  />
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

FormGenerator.defaultProps = {
  cancelText: i18n.t('common.cancel'),
  className: undefined,
  disabled: false,
  editing: false,
  onCancel: undefined,
  saveText: i18n.t('common.accept'),
  sendOnEnter: false,
  showCancel: false,
  showSave: true,
  title: undefined,
  validateForm: undefined,
  validateOnBlur: true,
  validateOnChange: true,
};

export default FormGenerator;
