import {
  Formik, Form, FormikProps, FormikValues, FormikHelpers,
} from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import logo from 'assets/images/logo_funus.svg';

import Button from 'components/base/Button';
import Input from 'components/base/Input';
import Loader from 'components/base/Loader';
import Message from 'components/base/Message';

import './index.scss';

type FormValues = {
  username: string;
};

const ForgotPasswordPage: FC = () => {
  const [error, setError] = useState<string | undefined>();
  const [sent, setSent] = useState(false);
  const { t } = useTranslation();

  const onSubmit = (
    values: FormikValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    config.apiFunus.login
      .forgot(values.username)
      .then((res) => {
        setSent(true);
        if (res?.data) {
          return res.data;
        }
        return res;
      })
      .catch(() => {
        setError(t('error.forgot'));
        actions.setSubmitting(false);
      });
  };

  return (
    <div id="forgot-page">
      {sent && <Message type="success">{t('user.forgotPasswordOk')}</Message>}
      <div>
        <img alt="Funus" src={logo} />
        <Formik
          initialValues={{
            username: '',
          }}
          onSubmit={onSubmit}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }: FormikProps<FormValues>) => (
            <Form autoComplete="off">
              {isSubmitting && !sent && <Loader />}
              <h2>{t('user.recover')}</h2>
              <Input
                data-testid="username"
                disabled={isSubmitting}
                error={touched.username && errors.username}
                name="username"
                placeholder={t('user.username')}
                value={values.username}
                onBlur={handleBlur}
                onChange={(val: string) => {
                  setFieldValue('username', val);
                  setError(undefined);
                }}
              />
              {error && <Message type="error">{error}</Message>}
              <div className="form-buttons">
                <Button
                  color="special"
                  disabled={isSubmitting}
                  text={t('common.send')}
                  type="submit"
                />
                <Button
                  color="secondary"
                  text={t('common.back')}
                  to={config.url.landing}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
