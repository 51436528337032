import classNames from 'classnames';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button from 'components/base/Button';
import './index.scss';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { sortedQuestions } from 'modules/budget/data/sortedQuestions';
import { BudgetHelper } from 'modules/budget/helpers/BudgetHelper';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { QConfirmButtonComponent } from './types';

const QConfirmButton: FC<QConfirmButtonComponent> = (
  {
    onClick, disabled, caption, allowGotoSummary = true, question,
  },
) => {
  const { t } = useTranslation();
  const { budgetLoading, questions, collected } = useBudget();
  const [canRespond, setCanRespond] = useState<boolean>(false);
  const { state: locationState }: {state: {gotoSummary: boolean}} = useLocation();

  useEffect(
    () => {
      setCanRespond(!disabled && !budgetLoading);
    },
    [budgetLoading, disabled],
  );

  const inBudget = useMemo(() => {
    const budgetHelper = new BudgetHelper(sortedQuestions);

    return budgetHelper
      .availableQuestions(questions, collected).includes(question);
  }, [collected, question, questions]);

  return (
    <React.Fragment>
      {inBudget && (
      <Button
        className={classNames('q-confirm-button', { 'q-confirm-button-with-right-sibling': locationState?.gotoSummary })}
        disabled={!canRespond}
        rightAddon={(
          <React.Fragment>
            <CustomIcon icon={OtherTypes.MOVE_NEXT} />
          </React.Fragment>
)}
        text={caption || t('budget.wizard.responseButton')}
        onClick={() => {
          if (canRespond) {
            onClick();
          }
        }}
      />
      )}

      {allowGotoSummary && locationState?.gotoSummary && (
      <Button
        className={classNames('q-confirm-button-summary', 'q-confirm-button-with-left-sibling')}
        disabled={!canRespond}
        rightAddon={(
          <React.Fragment>
            <CustomIcon icon={OtherTypes.SAVE} />
            <CustomIcon icon={OtherTypes.MOVE_END} />
          </React.Fragment>
)}
        onClick={() => {
          if (canRespond) {
            onClick({ gotoSummary: true });
          }
        }}
      />
      )}
    </React.Fragment>
  );
};

QConfirmButton.defaultProps = {
  disabled: false,
};

export { QConfirmButton };
