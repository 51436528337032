import classNames from 'classnames';
import React, { createRef, FC } from 'react';
import { ChevronRight, Menu } from 'react-feather';

import logoCabre from 'assets/images/logo_cabre.svg';
import logo from 'assets/images/logo_funus.svg';

import Button from 'components/base/Button';

import useClickOutside from 'hooks/useClickOutside';
import useWindowSize from 'hooks/useWindowSize';

import SidebarButton, { SidebarButtonProps } from './SidebarButton';
import SidebarDropdown, { SidebarDropdownProps } from './SidebarDropdown';
import SidebarText, { SidebarTextProps } from './SidebarText';

import './index.scss';

export type SidebarOption = (
  | SidebarButtonProps
  | SidebarTextProps
  | SidebarDropdownProps
) & {
  key: string;
  permission?: string[];
};

type SidebarProps = {
  expanded: boolean;
  options: SidebarOption[];
  setExpanded: (expand: boolean) => void;
};

const isButton = (
  option: SidebarButtonProps | SidebarTextProps | SidebarDropdownProps,
): option is SidebarButtonProps => {
  if ((option as SidebarButtonProps).url) {
    return true;
  }
  return false;
};

const isDropdown = (
  option: SidebarButtonProps | SidebarTextProps | SidebarDropdownProps,
): option is SidebarDropdownProps => {
  if ((option as SidebarDropdownProps).subsections?.length) {
    return true;
  }
  return false;
};

const Sidebar: FC<SidebarProps> = ({ expanded, options, setExpanded }) => {
  const { smOrBigger, lgOrSmaller } = useWindowSize();
  const sidebarRef = createRef<HTMLDivElement>();

  const closeSidebar = () => {
    if (expanded && lgOrSmaller) {
      setExpanded(false);
    }
  };

  useClickOutside(sidebarRef, closeSidebar);

  return (
    <div ref={sidebarRef} className={classNames('funus-sidebar', { expanded })}>
      <div>
        <div className="funus-sidebar-brand">
          <SidebarButton
            content={<img alt="funus" src={logo} />}
            url="/"
            exact
          />
        </div>
        <div className="funus-sidebar-collapsable">
          <div className="sidebar-top">
            {options.map((opt) => {
              if (isDropdown(opt)) {
                return (
                  <SidebarDropdown
                    key={opt.key}
                    content={opt.content}
                    icon={opt.icon}
                    subsections={opt.subsections}
                    onClick={closeSidebar}
                  />
                );
              }
              if (isButton(opt)) {
                return (
                  <SidebarButton
                    key={opt.key}
                    content={opt.content}
                    exact={opt.exact}
                    icon={opt.icon}
                    url={opt.url}
                    onClick={closeSidebar}
                  />
                );
              }
              return (
                <SidebarText
                  key={opt.key}
                  content={opt.content}
                  icon={opt.icon}
                />
              );
            })}
          </div>
          <div className="sidebar-bottom">
            {smOrBigger && (
              <div className="logo-cabre">
                <img alt="cabre-junqueras" src={logoCabre} />
              </div>
            )}
            <Button
              className={classNames('expand-sidebar', { expanded })}
              color="transparent"
              testid="expand-sidebar"
              onClick={() => setExpanded(!expanded)}
            >
              {lgOrSmaller && !expanded ? <Menu /> : <ChevronRight />}
            </Button>
          </div>
        </div>
      </div>
      <Button
        className={classNames('responsive-expand', { expanded })}
        color="transparent"
        testid="menu-expand-button"
        onClick={() => setExpanded(!expanded)}
      >
        {lgOrSmaller && !expanded ? <Menu /> : <ChevronRight />}
      </Button>
    </div>
  );
};

export default Sidebar;
