import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/base/Input';

import { Permission } from 'models/UserRole';

export type PermissionInputProps = {
  disabled: boolean;
  handleBlur: () => void;
  keyPermissionGroupList: number;
  keyPermission: number;
  setFieldValue: (key: string, values: Permission) => void;
  permission: Permission;
  permissions: Permission[];
  permissionPrefix: string;
  allPermissionsGrouped: Permission[];
  setPermissionsDisabled: Dispatch<SetStateAction<Permission[]>>;
};

export const PermissionInput = ({
  disabled,
  handleBlur,
  keyPermissionGroupList,
  keyPermission,
  setFieldValue,
  permission,
  permissions,
  permissionPrefix,
  allPermissionsGrouped,
  setPermissionsDisabled,
}: PermissionInputProps): ReactElement => {
  const { t } = useTranslation();
  const changePermission = (perm: string, event: boolean) => {
    const permissionToChange = permissions.find((item) => item.name === perm);
    if (permissionToChange && permissionToChange.selected !== event) {
      permissionToChange.selected = event;
      const idx = permissions.indexOf(permissionToChange);
      setFieldValue(
        `permissions[${keyPermissionGroupList}].permissionDtos[${idx}]`,
        permissionToChange,
      );
    }
  };

  const lockUserPermissions = (perm: string, event: boolean) => {
    if (perm === 'USER') {
      if (event) {
        const arrayToChange: Permission[] = [];
        allPermissionsGrouped.forEach((permissionToChange) => {
          if (!permissionToChange.name.includes('USER')) {
            arrayToChange.push(permissionToChange);
          }
        });
        setPermissionsDisabled((state) => state.concat(arrayToChange));
      } else {
        const userPermissions = allPermissionsGrouped.filter(
          (p) => p.name.includes('USER') && p.id !== permission.id,
        );
        if (!userPermissions.find((p) => p.selected)) {
          setPermissionsDisabled([]);
        }
      }
    } else {
      const arrayToChange: Permission[] = [];
      if (event) {
        allPermissionsGrouped.forEach((permissionToChange) => {
          if (permissionToChange.name.includes('USER')) {
            arrayToChange.push(permissionToChange);
          }
        });
        setPermissionsDisabled((state) => state.concat(arrayToChange));
      } else {
        const userPermissions = allPermissionsGrouped.filter(
          (p) => !p.name.includes('USER') && p.id !== permission.id,
        );
        if (!userPermissions.find((p) => p.selected)) {
          setPermissionsDisabled([]);
        }
      }
    }
  };

  const lockWorkOrderPermissions = (perm: string, event: boolean) => {
    const disablePermission = (permissionToDisableOrEnable: string) => {
      if (event) {
        const arrayToChange: Permission[] = [];
        allPermissionsGrouped.forEach((permissionToChange) => {
          if (permissionToChange.name.includes(permissionToDisableOrEnable)) {
            arrayToChange.push(permissionToChange);
          }
        });
        setPermissionsDisabled((state) => state.concat(arrayToChange));
      } else {
        const cemeteryPermissions = allPermissionsGrouped.filter(
          (p) => p.name.includes(perm) && p.id !== permission.id,
        );
        if (!cemeteryPermissions.find((p) => p.selected)) {
          setPermissionsDisabled(
            (state) => state.filter((x) => !x.name.includes(permissionToDisableOrEnable)),
          );
        }
      }
    };

    if (perm === 'WORK_ORDER_WORKSHOP') {
      disablePermission('WORK_ORDER_CEMETERY');
    }
    if (perm === 'WORK_ORDER_CEMETERY') {
      disablePermission('WORK_ORDER_WORKSHOP');
    }
  };

  const handleChange = (event: boolean): void => {
    const permissionCopy = { ...permission, selected: event };
    let entendedPrefix = permissionPrefix;
    setFieldValue(
      `permissions[${keyPermissionGroupList}].permissionDtos[${keyPermission}]`,
      permissionCopy,
    );

    if (permissionCopy.name.includes('CEMETERY')) {
      entendedPrefix += '_CEMETERY';
    } else if (permissionCopy.name.includes('WORKSHOP')) {
      entendedPrefix += '_WORKSHOP';
    }

    if (permissionCopy.name !== `${entendedPrefix}_READ` && event) {
      changePermission(`${entendedPrefix}_READ`, event);
    }
    if (permissionCopy.name === `${entendedPrefix}_READ` && !event) {
      changePermission(`${entendedPrefix}_WRITE`, event);
      changePermission(`${entendedPrefix}_ADD`, event);
      changePermission(`${permissionPrefix}_ADD_NOTICE`, event);
      changePermission(`${permissionPrefix}_ADD_ENQUIRY`, event);
      changePermission(`${permissionPrefix}_ASSIGN`, event);
    } else if (permissionCopy.name === `${permissionPrefix}_WRITE` && !event) {
      changePermission(`${permissionPrefix}_ASSIGN`, event);
    } else if (permissionCopy.name === `${permissionPrefix}_ASSIGN` && event) {
      changePermission(`${permissionPrefix}_WRITE`, event);
    } else if (permissionCopy.name === `${permissionPrefix}_ADD` && event) {
      changePermission(`${permissionPrefix}_ADD_NOTICE`, !event);
    } else if (
      permissionCopy.name === `${permissionPrefix}_ADD_NOTICE`
      && event
    ) {
      changePermission(`${permissionPrefix}_ADD`, !event);
    }

    lockUserPermissions(permissionPrefix, event);
    lockWorkOrderPermissions(entendedPrefix, event);
  };

  return (
    <Input
      checked={permission?.selected || false}
      disabled={disabled}
      id={permission.name}
      name={permission.name}
      placeholder={t(`role.permissions.${permission.name}`)}
      type="checkbox"
      value={permission.name || ''}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
