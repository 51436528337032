import React, { FC } from 'react';
import { X } from 'react-feather';

import './index.scss';
import Button from '../Button';

export const Image: FC<{src: string, onDelete?: () => void}> = ({ src, onDelete }) => (
  <figure>
    <Button color="attention" onClick={() => onDelete?.()}>
      <X />
      {' '}
    </Button>
    <img alt="capture" src={src} />
  </figure>
);
