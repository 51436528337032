import {
  Form, Formik, FormikProps, getIn,
} from 'formik';
import React, {
  FC, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from 'utils/types/Nullable/types';

import Button from 'components/base/Button';
import { IdentifierTypes } from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import Loader from 'components/base/Loader';
import RadioButton from 'components/base/RadioButton';

import { useSteps } from 'hooks/useSteps';
import { StepPositions } from 'hooks/useSteps/types';
import { useValidator } from 'hooks/useValidator';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';
import { useDniScanInfo } from 'modules/ia/hooks/useDniScanInfo';
import { useIA } from 'modules/ia/hooks/useIa';

import { DataCollectorForm } from '../../models/DataCollectorForm';
import { IAInfoForm } from '../IAInfoForm';
import './index.scss';
import { IAScanDocumentContext } from '../scan-document-modal/IAScanDocumentContext';

import { DocumentDataCollectorByUploadComponent } from './types';

const matchUrlExpression = /^data:application\/(pdf);base64,/;

export const DocumentDataCollectorByUpload: FC<DocumentDataCollectorByUploadComponent> = (
  {
    onCancel, onConfirm,
  },
) => {
  const { documentType } = useContext(IAScanDocumentContext);
  const { t } = useTranslation();
  const { IAUploadDocumentSchema: IAUploadDNISchema } = useValidator();
  const {
    isFirstStep: isUploadFilesStep,
    isSecondStep: isGetInfoStep,
    isThirdStep: isDisplayDataStep,
    setStep,
  } = useSteps();
  const { fetchInfoFromFiles } = useIA();
  const {
    setDocInfo, docInfo, docScore, setDocScore,
  } = useDniScanInfo();
  const [selectedValues, setselectedValues] = useState<DataCollectorForm>();
  const [canAccept, setcanAccept] = useState<boolean>();

  const [frontPicture, setFrontPicture] = useState<Base64Data>();
  const [backPicture, setBackPicture] = useState<Base64Data>();

  const enableSecondFile = useMemo(() => [
    IdentifierTypes.IDENTIFIER,
    IdentifierTypes.NIE]
    .includes(documentType), [documentType]);

  const uploadOptions = useMemo(
    () => (enableSecondFile
      ? [{ code: '1', description: t('ia.uploadFileOne') }, { code: '2', description: t('ia.uploadFileTwo') }]
      : [{ code: '1', description: t('ia.uploadFileOne') }]),
    [enableSecondFile, t],
  );

  const extractBase64 = (file: File, side: 'document' | 'extraDocument') => {
    const reader = new FileReader();

    if (
      reader !== undefined
      && file !== undefined
      && file

    ) {
      reader.onloadend = async () => {
        const b = new Base64Data({
          data: (reader.result as string).replace(matchUrlExpression, ''),
          mimetype: MimeTypes.APPLICATION_PDF,
        });
        if (side === 'document') {
          setFrontPicture(b);
        } else if (side === 'extraDocument') {
          setBackPicture(b);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="f-upload-dni-form">
      {isUploadFilesStep && (
        <Formik
          initialValues={{
            file1: null,
            file2: null,
            fileNumber: '1',
          }}
          isInitialValid={false}
          validationSchema={IAUploadDNISchema}
          validateOnBlur
          validateOnChange
          validateOnMount
          onSubmit={(values) => {
            if (values.file1) {
              setStep(StepPositions.SECOND);
              fetchInfoFromFiles(documentType, {
                file1: values.file1,
                file2: values.file2,
              })
                .then((response) => {
                  setDocInfo(response.data);
                  setDocScore(response.score);
                  setStep(StepPositions.THIRD);
                  return response;
                })
                .catch(() => {
                  setStep(StepPositions.THIRD);
                });
            }
          }}
        >
          {({
            isValid, setFieldValue, values, errors,
          }: FormikProps<{
            file1: Nullable<File>,
            file2: Nullable<File>,
            fileNumber: string,
          }>) => (
            <Form autoComplete="off" className="fields">
              <RadioButton<{ description: string; code: string; }>
                checked={
                  values.fileNumber
                }
                getLabel={({ description }) => description}
                getValue={(el) => el.code}
                name="ai-file-number"
                options={uploadOptions}
                placeholder={t('ia.uploadFileQuestion')}
                onChange={(val) => {
                  if (val === '1') setFieldValue('file2', null);
                  setFieldValue('fileNumber', val);
                }}
              />
              <Input
                accept=".pdf"
                error={getIn(errors, 'file1')}
                name="file1"
                placeholder={values.fileNumber === '1' ? t('ia.both') : t('ia.front')}
                type="file"
                value={values.file1?.name || ''}
                onChange={(file) => {
                  setFieldValue('file1', file);
                  extractBase64(file as File, 'document');
                }}
              />
              {values.fileNumber === '2' && (
                <Input
                  accept=".pdf"
                  error={getIn(errors, 'file2')}
                  name="file2"
                  placeholder={t('ia.back')}
                  type="file"
                  value={values.file2?.name || ''}
                  onChange={(file) => {
                    setFieldValue('file2', file);
                    extractBase64(file as File, 'extraDocument');
                  }}
                />
              )}

              <div className="buttons-panel">
                <Button
                  className="photo-camera-button"
                  text={t('common.cancel')}
                  type="button"
                  onClick={onCancel}
                />
                <Button
                  className="document-upload-button"
                  disabled={!isValid}
                  text={t('common.send')}
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
      {isGetInfoStep && (
        <React.Fragment>
          <div className="step-header">{`${t('ia.scanningDocument')}`}</div>
          <Loader />
        </React.Fragment>
      )}
      {isDisplayDataStep && (
        <React.Fragment>
          <IAInfoForm
            docInfo={docInfo}
            docScore={docScore}
            onChange={({ isValid, values }) => {
              setcanAccept(isValid);
              setselectedValues(values);
            }}
          />
          <div className="buttons-panel">
            <Button
              className="photo-camera-button"
              text={t('common.cancel')}
              type="button"
              onClick={onCancel}
            />
            <Button
              className="photo-camera-button"
              disabled={!canAccept}
              text={t('common.accept')}
              type="button"
              onClick={() => {
                if (selectedValues) {
                  onConfirm(
                    {
                      ...selectedValues,
                      backCapture: backPicture,
                      frontCapture: frontPicture,
                    },
                    MimeTypes.APPLICATION_PDF,
                  );
                }
              }}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
