import React, { FC } from 'react';

import { checkHaveAtLeastOnePermission, checkHaveThisPermissions, checkPermission } from 'utils/permissions';

import ReceptionLanding from 'components/pages/reception/Landing';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';

import AdviserLanding from '../adviser/Landing';
import { ArticleInfo } from '../ArticleInfo';
import { Flowers } from '../articles/flowers';
import EnquiryTable from '../enquiry/Table';
import ManagerLanding from '../manager/Landing';
import WorkshopPage from '../operator/Workshop';
import RecordTable from '../record/Table';
import UserTable from '../user/Table';
import './index.scss';

const LandingPage: FC = () => {
  const { user, roleInfo } = useProvidedAuth();
  const renderLanding = (): JSX.Element => {
    const permissions = user?.role.permissions;

    const isReception = checkHaveThisPermissions(
      [
        SimpleUserRequestPermissionNames.ENQUIRY_READ,
        SimpleUserRequestPermissionNames.ENQUIRY_WRITE,
        SimpleUserRequestPermissionNames.ENQUIRY_ADD,
        SimpleUserRequestPermissionNames.RECORD_READ,
      ],
      permissions,
    )
    && checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.RECORD_WRITE,
        SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
      ],
      permissions,
    );

    const isManagement = checkPermission(
      SimpleUserRequestPermissionNames.RECORD_ASSIGN,
      permissions,
    );

    const isAdviser = checkPermission(
      SimpleUserRequestPermissionNames.ADVICE_ADD,
      permissions,
    );

    const isOperatorOrWorkshopAdmin = roleInfo.isOperator && !roleInfo.isWorkshopAdmin;

    const canViewRecords = checkPermission(
      SimpleUserRequestPermissionNames.RECORD_READ, permissions,
    );

    const canViewEnquiries = checkPermission(
      SimpleUserRequestPermissionNames.ENQUIRY_READ, permissions,
    );

    const canViewArticlesOrIsArticleSupplier = checkPermission(
      SimpleUserRequestPermissionNames.ARTICLE_READ, permissions,
    ) || checkPermission(SimpleUserRequestPermissionNames.ARTICLE_SUPPLIER, permissions);

    const canReadArticleQr = checkPermission(
      SimpleUserRequestPermissionNames.ARTICLE_QR_READ, permissions,
    );

    if (roleInfo.isAdmin) { return <UserTable />; }
    if (isReception) { return <ReceptionLanding />; }
    if (isManagement) { return <ManagerLanding />; }
    if (isAdviser) { return <AdviserLanding />; }
    if (isOperatorOrWorkshopAdmin) { return <WorkshopPage />; }
    if (canViewRecords) { return <RecordTable />; }
    if (canViewEnquiries) { return <EnquiryTable />; }
    if (canViewArticlesOrIsArticleSupplier) { return <Flowers />; }
    if (canReadArticleQr) { return <ArticleInfo />; }

    return <div>LANDING</div>;
  };

  return <div id="landing">{renderLanding()}</div>;
};

export default LandingPage;
