import React, { FC } from 'react';

import logo from 'assets/images/logo_funus.svg';

import './index.scss';

export const RotatingLogo: FC<Record<string, unknown>> = () => (
  <div className="rotating">
    <img alt="funus-loader" src={logo} />
  </div>
);
