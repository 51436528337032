import { AxiosError } from 'axios';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import './index.scss';
import FormPage from 'components/base/FormPage';

import {
  getUserProfileForm,
  profileFormInitialValues,
} from 'components/user/Profile';

import { ConflictError } from 'config/apiErrors/ConflictError';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { UpdateUserProfile } from 'models/User';

const UserProfilePage: FC = () => {
  const { t } = useTranslation();
  const { user, setUser } = useProvidedAuth();

  const handleError = (error: AxiosError) => {
    showErrorToast(
      error instanceof ConflictError ? t('user.duplicated') : t('user.profile.imageKo'),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateFunction = (data: any) => config.apiFunus.users
    .updateImage({ image: data.values.image });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSave = (params: UpdateUserProfile, sentParams: any) => {
    if (user) {
      setUser(
        { ...user, image: params?.image || sentParams?.values?.image },
        () => {
          showSuccessToast(t('user.profile.imageOk'));
        },
      );
    }
  };

  return (
    <div id="user-profile-page">
      <FormPage<UpdateUserProfile>
        backUrl={config.url.landing}
        fields={getUserProfileForm}
        getFunction={config.apiFunus.users.getProfile}
        id={user?.id as string}
        initialValues={profileFormInitialValues}
        redirectOnSave={false}
        updateFunction={updateFunction}
        editing
        onError={handleError}
        onSave={onSave}
      />
    </div>
  );
};

export default UserProfilePage;
