import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Article } from 'modules/budget/models/Article';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q4Form, Q4Response } from './form';

export const Q4: FC<Record<string, unknown>> = () => {
  const { RespondQuestion } = useBudget();
  const question = BudgetQuestionCodes.Q4;
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const [dismissService, setDismissService] = useState<boolean>();
  const [enabledReply, setEnabledReply] = useState<boolean>();
  const [autorespond, setautorespond] = useState<boolean>(false);

  useEffect(
    () => {
      setEnabledReply(dismissService
        || (dismissService === false && selectedArticles.length !== 0));
    },
    [dismissService, selectedArticles.length],
  );

  const response = useCallback(
    ({ gotoSummary }) => {
      RespondQuestion({
        gotoSummary,
        question,
        response: {
          id: selectedArticles.length === 1 ? selectedArticles[0].code : null,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedArticles],
  );

  useEffect(
    () => {
      if (autorespond && enabledReply) {
        setautorespond(false);
        response({ gotoSummary: false });
      }
    },
    [autorespond, enabledReply, response],
  );

  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q4Form
        question={question}
        onChange={(responseData: Q4Response) => {
          setDismissService(responseData.noInsurance);
          setSelectedArticles(responseData.articles);
          setautorespond(responseData.autorespond);
        }}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{
              id: selectedArticles.length === 1 ? selectedArticles[0].code : null,
            }}
          />
          <QConfirmButton
            disabled={!enabledReply}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
