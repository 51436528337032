import classNames from 'classnames';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import CanvasDraw from 'react-canvas-draw';
import { useTranslation } from 'react-i18next';

import colors from 'utils/colors';

import Button from 'components/base/Button';

import './index.scss';

type SignProps = {
  className?: string;
  disabled?: boolean;
  onCancel?: () => void;
  onClear?: () => void;
  onSign(canvas?: string): void;
  showAccept?: boolean;
  showCancel?: boolean;
  showClear?: boolean;
  acceptCaption?: string
  value?: string;
  height?: number | string;
  width?: number | string;
};

const Sign: FC<SignProps> = ({
  className,
  disabled,
  onCancel,
  onClear,
  onSign,
  showAccept,
  showCancel,
  showClear,
  value,
  acceptCaption,
  height = 400,
  width = 400,
}) => {
  const [background, setBackground] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [sign, setSign] = useState<string>();
  const [signed, setSigned] = useState(false);
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const onChange = (clearing?: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const image = (canvasRef?.current as any)?.canvasContainer?.childNodes[1].toDataURL()
      || '';
    if (showAccept) {
      setSign(clearing ? undefined : image);
    } else {
      onSign(clearing ? undefined : image);
    }
    setSigned(!clearing);
  };

  const clear = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (canvasRef?.current as any)?.clear();
    onChange(true);
    setBackground(undefined);
    setLoading(true);
    setTimeout(() => setLoading(false), 100);

    if (onClear) {
      onClear();
    }
  };

  useEffect(() => {
    setBackground(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('sign-container', className)}>
      <div
        className={classNames('sign', { filled: !loading && !!background })}
        style={{
          height: Number.isInteger(height) ? Number(height) + 5 : height,
          width: Number.isInteger(width) ? Number(width) + 5 : width,
        }}
      >
        {!loading && !background && (
          <CanvasDraw
            ref={canvasRef}
            backgroundColor={
              !!background || disabled ? colors.gray300 : undefined
            }
            brushRadius={0}
            canvasHeight={height}
            canvasWidth={width}
            className={!!background || disabled ? 'disabled' : undefined}
            disabled={disabled || !!background}
            imgSrc={background}
            lazyRadius={0}
            hideGrid
            hideInterface
            onChange={() => onChange()}
          />
        )}
        {!loading && !!background && <img alt="sign" src={background} />}
      </div>
      {(showAccept || showClear || showCancel) && (
        <div className="sign-buttons">
          {showAccept && !disabled && (
            <Button
              disabled={!!background}
              text={acceptCaption || t('common.accept')}
              onClick={() => onSign(sign)}
            />
          )}
          {showClear && !disabled && (
            <Button
              color="attention"
              disabled={!signed && !value}
              text={t('common.clear')}
              onClick={clear}
            />
          )}
          {showCancel && !disabled && (
            <Button
              color="secondary"
              text={t('common.cancel')}
              onClick={onCancel}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Sign;
