import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { ScanDocumentModal } from '../scan-document-modal';

import { IaDocumentScannerDocument } from './types';

export const IaDocumentScanner: FC<IaDocumentScannerDocument> = (
  {
    documentType, onConfirm, infoFormlayout, disabled = false,
  },
) => {
  const { t } = useTranslation();
  const [showScanDocumentModal, setShowScanDocumentModal] = useState<boolean>(false);
  const title = useMemo(
    () => {
      const docType = t(`common.${documentType}`);
      return `${t('ia.scanDocument')}: ${docType}`;
    },
    [documentType, t],
  );

  return (
    <React.Fragment>
      <Button
        className="secondary"
        disabled={disabled}
        onClick={() => setShowScanDocumentModal(true)}
      >
        <CustomIcon icon={OtherTypes.PHOTO} />
      </Button>
      <ScanDocumentModal
        documentType={documentType}
        infoFormlayout={infoFormlayout}
        show={showScanDocumentModal}
        title={title}
        onAccept={(values, fileType) => {
          setShowScanDocumentModal(false);
          onConfirm(values, fileType);
        }}
        onCancel={() => setShowScanDocumentModal(false)}
      />
    </React.Fragment>
  );
};
