import { useTranslation } from 'react-i18next';

import { ReceptionType } from 'models/MasterData';

export enum ReceptionTypes {
  WITH_COFFIN = 'WITH_COFFIN',
  WITHOUT_COFFIN = 'WITHOUT_COFFIN',
}

type ReceptionTypesHook = {
  receptionTypes: ReceptionType[]
  getReceptionType: (code: string) => ReceptionType | undefined
}

export const useReceptionTypes = (): ReceptionTypesHook => {
  const { t } = useTranslation();
  const receptionTypes: ReceptionType[] = [{
    code: ReceptionTypes.WITH_COFFIN,
    description: t('record.receptionType.withCoffin'),
    descriptionInfo: t('record.receptionTypeInfo.withCoffin'),
  }, {
    code: ReceptionTypes.WITHOUT_COFFIN,
    description: t('record.receptionType.withoutCoffin'),
    descriptionInfo: t('record.receptionTypeInfo.withoutCoffin'),
  }];

  const getReceptionType = (code: string) => receptionTypes.find((i) => i.code === code);

  return {
    getReceptionType,
    receptionTypes,
  };
};
