const service = {
  CEREMONIA: 'Cerimònia',
  ceremony: {
    accompanimentCars: 'Cotxes d\'acompanyament',
    agency: 'Gestoria',
    ceremonial: 'Cerimonial',
    ceremony: 'Cerimònia',
    currentCeremony: 'Cerimònia actual',
    flowers: 'Flors',
    hearses: 'Cotxes fúnebres',
    location: 'Ubicació de la cerimònia',
    music: 'Música',
    obituariesReminders: 'Esqueles i recordatoris',
    postInterment: 'Cerimònia post-enterrament',
    pressCommunication: 'Comunicació a la premsa',
    pressObituaries: 'Esqueles premsa',
    previousCeremony: 'Cerimònia anterior',
    streaming: 'Grabació / Streaming',
    type: 'Tipus de celebració',
  },
  ceremonyType: {
    LAICA: 'Laica',
    RELIGIOSA: 'Religiosa',
  },
  clear: 'Netejar pantalla',
  clientAssigned: 'El client està associat a un article. Abans d\'eliminar-lo, heu d\'eliminar aquesta associació.',
  coffinType: {
    ABIERTO: 'Obert',
    CERRADO: 'Tancat',
  },
  cremation: {
    ashes: 'Cendres',
    ashesDeliveryDate: 'Data entrega cendres',
    ashesDeliveryHandler: 'Tramitador entrega cendres',
    ashesDestiny: 'Destí cendres',
    ashesReceiver: 'Receptor cendres',
    ashesReceiverIdentifier: 'Document identificatiu receptor',
    cremation: 'Incineració',
    cremationDate: 'Data incineració',
    cremationTime: 'Hora incineració',
    crematorium: 'Crematori',
    documentationOK: 'OK Documentació per a Incinerar',
    documentationOkDetail: 'OK Incineració',
    documentationOkDetail2: 'Indica que es disposa de tota la documentació per a procedir a la incineració: <br/>MORT NORMAL: <br/>* Sol·licitud Família<br/>* CMD<br/>* Llicència<br/>MORT JUDICIAL:<br/>* Sol·licitud Família<br/>* Carta Ordre<br/>* Permís Judicial<br/>* Llicència',
    documentationTime: 'Ok Documentació Data i hora',
    documentationUser: 'OK Documentació Usuari',
    entryDate: 'Data arribada',
    entryTime: 'Hora arribada',
    familyPresence: 'Família present',
    jewels: 'Joies / Penjolls',
    judicial: 'Judicial',
    operator: 'Operari',
    originMortuary: 'Funerària procedència',
    oven: 'Forn',
    owner: 'Propietari urna',
    reliquary: 'Reliquiari',
    urnModel: 'Model d\'urna',
    zincBox: 'Caixa zenc',
  },
  deceasedLocation: {
    CAMARA: 'Càmera',
    CENTRO: 'Sense càmera',
  },
  deposit: {
    deposit: 'Dipòsit',
    entryDate: 'Data arribada',
    entryTime: 'Hora arribada',
  },
  DEPOSIT: 'Dipòsit',
  duplicatedClient: 'El client ja està inclòs al llistat.',
  finalDestination: {
    CEMENTERIO: 'Cementiri',
    CREMATORIO: 'Crematori',
  },
  INCINERACION: 'Incineració',
  INHUMACION: 'Inhumació',
  interment: {
    ashes: 'Inhumació cendres',
    cemetery: 'Cementiri',
    concession: {
      beneficiary: 'Beneficiari',
      holder: 'Titular',
      temporality: 'Temporalitat',
      title: 'Concessió',
      type: 'Tipus',
    },
    familyPresence: 'Família present',
    floor: 'Pis',
    idCard: 'DNI',
    interment: 'Inhumació',
    intermentDate: 'Data inhumació',
    intermentTime: 'Hora inhumació',
    judicial: 'Judicial',
    measurementsAndPhotos: 'Pendre mides i realitzar fotos',
    nichePreparationDate: 'Data preparació nínxol',
    nichePreparationTime: 'Hora preparació nínxol',
    number: 'Número',
    section: 'Secció',
    sepulture: 'Sepultura',
    zincBox: 'Caixa zenc',
  },
  loadKo: 'S\'ha produït un error a l\'hora de carregar l\'expedient.',
  movement: {
    type: {
      BOX: 'CAIXA',
      BUNK: 'LLITERA',
    },
  },
  MOVIMIENTO: 'Moviment',
  noDeclarant: 'Heu d\'introduir les dades del declarant abans de continuar amb l\'assessorament.',
  onClearText: 'En eliminar aquest servei se salvaran totes les dades d\'altres serveis i les dades que s\'eliminaran, no es podran recuperar. Voleu continuar?',
  PREPARACION: 'Preparació',
  preparation: {
    aconditioning: 'Acondicionament',
    applicant: 'Sol·licitant de pràctica sanitària',
    atHome: 'Realitzar al domicili de defunció',
    clothing: 'Vestir',
    coffin: 'Fèretre',
    coffinArticle: 'Taüt',
    coffinPreparationDetail: 'Detall de preparació fèretre',
    cover: 'Amortallar',
    creu: 'Amb creu',
    deceased: 'Titular de l\'expedient',
    deceasedLocation: 'Ubicació del difunt',
    ESTANDAR: 'Estàndard',
    finalDestination: 'Destí final',
    hairstyle: 'Pentinar',
    makeup: 'Maquillatge',
    pacemaker: 'Marcapassos',
    personalObjects: 'Objectes personals',
    PRACTICA_SANITARIA: 'Pràctica sanitària',
    preparation: 'Tipus',
    sanitaryPractice: 'Pràctica sanitària',
    sanitaryPracticeAuthorization: 'Autorització',
    shaved: 'Afaitar',
    thanatologist: 'Tanatòleg',
    thanatopractor: 'Tanatopractor',
    transfer: 'Transport',
  },
  recipient: {
    address: 'Adreça del destinatari',
    name: 'Nom del destinatari',
    title: 'Destinatari',
  },
  sanitaryPractice: {
    ADOL: 'Adol',
    CELOFANA: 'Cel·lofana',
    MARCAPASOS: 'Marcapassos',
    SERRADURES_SULFAT: 'Serradures sulfat',
    TAPONAR: 'Taponar',
  },
  saveKo: 'S\'ha produït un error a l\'hora d\'actualitzar l\'expedient.',
  saveOk: 'L\'expedient s\'ha actualizat satisfactòriament.',
  sign: {
    signKo: 'S\'ha produït un error a l\'hora d\'actualitzar la signatura.',
    signOk: 'La signatura s\'ha actualizat satisfactòriament.',
  },
  transfer: {
    agency: 'Agència',
    arrivalDate: 'Data d\'arribada',
    arrivalTime: 'Hora d\'arribada',
    borderCrossing: 'Pas fronterer',
    commentDestination: 'Observacions destí',
    commentTransport: 'Observacions transport',
    company: 'Companyia',
    connectionNumber: 'Coneixement aeri',
    deliveryDate: 'Data entrega',
    deliveryTime: 'Hora entrega',
    departureAirport: 'Aeroport sortida',
    departureDate: 'Data sortida',
    departureTime: 'Hora sortida',
    destination: 'Destí',
    destinationAirport: 'Aeroport destí',
    estimatedArrivalDate: 'Data prevista d\'arribada',
    estimatedArrivalTime: 'Hora prevista d\'arribada',
    flightNumber: 'Nº de vol',
    measures: 'Mides caixa',
    scale: 'Escala',
    type: 'Tipus de transport',
    types: {
      CAR: 'Carretera',
      PLANE: 'Avió',
    },
    weight: 'Pes caixa',
  },
  TRANSPORTE: 'Transport',
  VELATORIO: 'Vetlla',
  vigil: {
    address: 'Adreça de vetlla',
    atHome: 'Al domicili',
    entryDate: 'Data entrada vetlla',
    entryTime: 'Hora entrada vetlla',
    exitDate: 'Data sortida vetlla',
    exitTime: 'Hora sortida vetlla',
    externalNumber: 'Nº expedient extern',
    location: 'Lloc de vetlla',
    room: 'Sala',
    transfer: 'Transport',
    vigil: 'Vetlla',
    vigilComplements: 'Complements Vetlla',
    vigilRemarks: 'Observacions de la vetlla',
  },
  workorders: {
    CEREMONIA: 'Cerimònia',
    INCINERACION: 'Incineració',
    INHUMACION: 'Inhumació',
    LAPIDA: 'Làpida',
    MANUAL_CEMENTERIO: 'Manual cementiri',
    MANUAL_TALLER: 'Manual taller',
    MOVIMIENTO: 'Moviment',
    MOVIMIENTO_INTERNO: 'Moviment intern',
    PRACTICA_SANITARIA: 'Practica sanitària',
    PREPARACION: 'Preparació',
    PREPARACION_NICHO: 'Preparació de nínxol',
    RECEPCION: 'Recepció',
    RECOGIDA: 'Recollida',
    TRASLADO: 'Trasllat',
    TRASLADO_RESTOS: 'Trasllat de restes',
  },
};
export default service;
