import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { ServiceTypes } from 'modules/budget/store/data';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q1Form, Q1Response } from './form';

export const Q1: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q1;
  const { RespondQuestion } = useBudget();
  const [responseValue, setResponseValue] = useState<ServiceTypes | null>(null);
  const [autorespond, setautorespond] = useState<boolean>(false);

  const response = useCallback(
    ({ gotoSummary }) => {
      if (responseValue) {
        RespondQuestion({
          gotoSummary,
          question,
          response: { service: responseValue },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [responseValue],
  );

  const onChange = useCallback(
    (responseData: Q1Response) => {
      setResponseValue(responseData.responseValue);
      setautorespond(responseData.autorespond);
    },
    [],
  );

  useEffect(
    () => {
      if (autorespond && responseValue) {
        setautorespond(false);
        response({ gotoSummary: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [autorespond, responseValue],
  );

  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q1Form
        question={BudgetQuestionCodes.Q1}
        onChange={onChange}
      />
      <Row
        className="f-question-actions"
        justifyContent="flex-end"
      >
        <AffectedQuestions question={question} response={{ service: responseValue }} />
        <QConfirmButton
          disabled={!responseValue}
          question={question}
          onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
        />
      </Row>
    </React.Fragment>
  );
};
