import { Middleware } from 'redux';

import { showErrorToast } from 'utils/toasts';

import { i18n } from 'i18n';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes, ArticleType } from 'modules/budget/models/Article/types';
import { Budget } from 'modules/budget/models/Budget';
import { BudgetResponseCodes } from 'modules/budget/models/BudgetQuestion/types';

import { budgetController } from '../../api';
import { SetQ10Simulation } from '../budgetActions';
import { BudgetActionTypes, FetchQ10SimulationPayload } from '../budgetActions/types';
import { BudgetState } from '../budgetReducer/types';

export const FetchQ10Simulation: Middleware = (storeApi) => (next) => async (action) => {
  if (
    action.type === BudgetActionTypes.FETCH_Q10_SIMULATION) {
    const result = next(action);
    const { articleId }: FetchQ10SimulationPayload = action.payload;

    const storedBudget = storeApi.getState().budget as BudgetState;

    const budget = new Budget({
      automaticArticles: storedBudget.automaticArticles,
      collected: storedBudget.collected,
      createdBy: storedBudget.createdBy,
      createdDate: storedBudget.createdDate,
      discardedArticles: storedBudget.discardedArticles,
      id: storedBudget.id,
      idRecord: storedBudget.idRecord,
      idVersion: storedBudget.idVersion,
      questions: {
        ...storedBudget.questions,
        [BudgetQuestionCodes.Q10 as string]: {
          articles: {
            ARTICLE: [new Article({
              amount: 1,
              code: articleId,
              description: '',
              subType: ArticleCollectionTypes.ARTICLE,
              type: ArticleType.CATALOG,
            })],
          },
          code: BudgetQuestionCodes.Q10,
          response: BudgetResponseCodes.R10_1,
        },
      },
      status: storedBudget.status,
    });

    const fetchArticles = async () => budgetController.GetBudgetArticles(budget);

    try {
      const items = await fetchArticles();
      budget.populateArticles(items);
      storeApi.dispatch(SetQ10Simulation({
        articleId,
        budget,
      }));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrorToast(i18n.t(error.message));
    }

    return result;
  }

  return next(action);
};
