import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { isBoolean, isBooleanAndFalse } from 'utils/helpers';

import { Column } from 'components/base/Column';

import { DeathLocationSelector } from 'modules/budget/components/articleSelectors/DeathLocationSelector';
import { CitySelector } from 'modules/budget/components/commons/CitySelector';
import { QHeader2 } from 'modules/budget/components/commons/QHeader2';
import { Separator } from 'modules/budget/components/commons/Separator';
import { YesNoSelector } from 'modules/budget/components/commons/YesNoSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionLabels } from 'modules/budget/hooks/useQuestionLabels';
import { Address } from 'modules/budget/models/Address';
import { Location } from 'modules/budget/models/Location';
import { ILocation } from 'modules/budget/models/Location/types';

export interface Q3Response {
  address: Address | null;
  location: ILocation | null;
  atHome: boolean | undefined;
}

interface Q3FormComponent {
  onChange: (value: Q3Response) => void;
  question: BudgetQuestionCodes;
}

export const Q3Form: FC<Q3FormComponent> = ({ onChange, question }) => {
  const { t } = useTranslation();
  const { GetQuestionValue } = useBudget();
  const { yes, no } = useQuestionLabels();

  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
  const [atHome, setAtHome] = useState<boolean>();

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.atHome !== undefined) {
        setAtHome(Boolean(storedValue?.atHome));
      }

      if (storedValue?.address) {
        setSelectedAddress(new Address(storedValue?.address));
      } else {
        setSelectedAddress(null);
      }

      if (storedValue?.location) {
        setSelectedLocation(storedValue?.location);
      } else {
        setSelectedLocation(null);
      }

      if (storedValue?.address && storedValue?.location) {
        onChange({
          address: new Address(storedValue?.address),
          atHome: storedValue?.atHome,
          location: storedValue?.location,
        });
      }
    },
    [onChange, storedValue?.address, storedValue?.atHome, storedValue?.location],
  );

  return (
    <Formik
      initialValues={{
        address: selectedAddress,
        atHome,
        location: selectedLocation,
      }}
      validate={(values: Q3Response) => {
        onChange({
          address: values.address,
          atHome: values.atHome,
          location: values.location,
        });

        setSelectedAddress(values.address);
        setSelectedLocation(values.location);
        setAtHome(values.atHome);

        return {};
      }}
      enableReinitialize
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues, values,
      }: FormikProps<Q3Response>) => (
        <Column>
          <QHeader2 caption={t('record.deathData.pickupType')} />
          <YesNoSelector
            noCaption={no(question)}
            value={values.atHome}
            yesCaption={yes(question)}
            onChange={(v) => {
              setValues({
                address: values.address,
                atHome: !!v,
                location: null,
              });
            }}
          />
          <Separator />
          {isBoolean(values.atHome)
            ? (
              <form autoComplete="off">
                <CitySelector
                  cityCaption={t('budget.wizard.city.death')}
                  cityCode={values.address?.city || ''}
                  cityWork={Boolean(values.address?.work)}
                  countryCaption={t('budget.wizard.country.death')}
                  countryCode={values.address?.country || ''}
                  disabled={false}
                  provinceCaption={t('budget.wizard.province.death')}
                  provinceCode={values.address?.province || ''}
                  emitPartial
                  onChange={(val) => {
                    setValues({
                      address: new Address(val),
                      atHome: values.atHome,
                      location: values.address?.isEqual(val) ? values.location : null,
                    });
                  }}
                />
              </form>
            ) : <React.Fragment />}
          {isBooleanAndFalse(values.atHome)
            ? (
              <React.Fragment>
                <Separator />
                <DeathLocationSelector
                  city={values.address?.city || ''}
                  disabled={false}
                  value={values.location?.id || ''}
                  onChange={(val) => {
                    setValues({
                      address: values.address,
                      atHome: values.atHome,
                      location: val ? new Location(val) : null,
                    });
                  }}
                />
              </React.Fragment>
            )
            : (<React.Fragment />)}
        </Column>
      )}
    </Formik>
  );
};
