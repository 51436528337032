import BankAccount from 'models/BankAccount';

type BankAssignedType = {
  [entity: string]: BankAccount;
};

const bankAssigned: BankAssignedType = {
  '0008': {
    description: 'BANCO ATLÁNTICO',
    iso: 'ESP',
    bic: 'BSABESBBXXX',
  },
  '0019': {
    description: 'DEUTSCHE BANK, S.A.E.',
    iso: 'ESP',
    bic: 'DEUTESBBASS',
  },
  '0031': {
    description: 'BANCO ETCHEVERRIA, S.A.',
    iso: 'ESP',
    bic: 'ETCHES2GXXX',
  },
  '0046': {
    description: 'BANCO GALLEGO, S.A.',
    iso: 'ESP',
    bic: 'GALEES2GXXX',
  },
  '0049': {
    description: 'BANCO SANTANDER, S.A.',
    iso: 'ESP',
    bic: 'BSCHESMMXXX',
  },
  '0058': {
    description: 'BNP PARIBAS ESPAÑA, S.A.',
    iso: 'ESP',
    bic: 'BNPAESMZXXX',
  },
  '0059': {
    description: 'BANCO DE MADRID, S.A.',
    iso: 'ESP',
    bic: 'MADRESMMXXX',
  },
  '0061': {
    description: 'BANCA MARCH, S.A.',
    iso: 'ESP',
    bic: 'BMARES2MXXX',
  },
  '0065': {
    description: 'BARCLAYS BANK, S.A.',
    iso: 'ESP',
    bic: 'BARCESMMXXX',
  },
  '0075': {
    description: 'BANCO POPULAR ESPAÑOL, S.A.',
    iso: 'ESP',
    bic: 'POPUESMMXXX',
  },
  '0078': {
    description: 'BANCA PUEYO, S.A.',
    iso: 'ESP',
    bic: 'BAPUES22XXX',
  },
  '0081': {
    description: 'BANCO DE SABADELL, S.A.',
    iso: 'ESP',
    bic: 'BSABESBBXXX',
  },
  '0083': {
    description: 'RENTA 4 BANCO, S.A.',
    iso: 'ESP',
    bic: 'RENBESMMXXX',
  },
  '0093': {
    description: 'BANCO DE VALENCIA, S.A.',
    iso: 'ESP',
    bic: 'VALEESVVXXX',
  },
  '0094': {
    description: 'RBC INVESTOR SERVICES ESPAÑA, S.A.',
    iso: 'ESP',
    bic: 'BVALESMMXXX',
  },
  '0099': {
    description: 'BANKIA BANCA PRIVADA, S.A.U',
    iso: 'ESP',
    bic: 'AHCRESVVXXX',
  },
  '0108': {
    description: 'SOCIETE GENERALE, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'SOGEESMMAGM',
  },
  '0122': {
    description: 'CITIBANK ESPAÑA, S.A.',
    iso: 'ESP',
    bic: 'CITIES2XXXX',
  },
  '0128': {
    description: 'BANKINTER, S.A.',
    iso: 'ESP',
    bic: 'BKBKESMMXXX',
  },
  '0130': {
    description: 'BANCO CAIXA GERAL, S.A.',
    iso: 'ESP',
    bic: 'CGDIESMMXXX',
  },
  '0131': {
    description: 'BANCO ESPIRITO SANTO, S.A., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BESMESMMXXX',
  },
  '0136': {
    description: 'ARESBANK, S.A.',
    iso: 'ESP',
    bic: 'AREBESMMXXX',
  },
  '0138': {
    description: 'BANKOA, S.A.',
    iso: 'ESP',
    bic: 'BKOAES22XXX',
  },
  '0144': {
    description: 'BNP PARIBAS SECURITIES SERVICES, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'PARBESMHXXX',
  },
  '0149': {
    description: 'BNP PARIBAS, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BNPAESMHXXX',
  },
  '0152': {
    description: 'BARCLAYS BANK PLC, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BPLCESMMXXX',
  },
  '0154': {
    description:
      'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BSUIESMMXXX',
  },
  '0155': {
    description: 'BANCO DO BRASIL, S.A., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BRASESMMXXX',
  },
  '0156': {
    description: 'THE ROYAL BANK OF SCOTLAND PLC, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'ABNAESMMXXX',
  },
  '0159': {
    description: 'COMMERZBANK AKTIENGESELLSCHAFT, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'COBAESMXTMA',
  },
  '0160': {
    description: 'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BOTKESMXXXX',
  },
  '0162': {
    description: 'HSBC BANK PLC, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'MIDLESMXXXX',
  },
  '0167': {
    description: 'BNP PARIBAS FORTIS, S.A., N.V., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'GEBAESMMBIL',
  },
  '0168': {
    description: 'ING BELGIUM, S.A., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BBRUESMXXXX',
  },
  '0169': {
    description: 'BANCO DE LA NACION ARGENTINA, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'NACNESMMXXX',
  },
  '0182': {
    description: 'BANCO BILBAO VIZCAYA ARGENTARIA, S.A.',
    iso: 'ESP',
    bic: 'BBVAESMMXXX',
  },
  '0186': {
    description: 'BANCO MEDIOLANUM, S.A.',
    iso: 'ESP',
    bic: 'BFIVESBBXXX',
  },
  '0188': {
    description: 'BANCO ALCALA, S.A.',
    iso: 'ESP',
    bic: 'ALCLESMMXXX',
  },
  '0190': {
    description: 'BANCO BPI, S.A., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'BBPIESMMXXX',
  },
  '0196': {
    description: 'PORTIGON AG, SUCURSAL EN ESPAÑA',
    iso: 'ESP',
    bic: 'WELAESMMFUN',
  },
  '0198': {
    description: 'BANCO COOPERATIVO ESPAÑOL, S.A.',
    iso: 'ESP',
    bic: 'BCOEESMMXXX',
  },
  '0211': {
    description: 'EBN BANCO DE NEGOCIOS, S.A.',
    iso: 'ESP',
    bic: 'PROAESMMXXX',
  },
  '0216': {
    description: 'TARGOBANK, S.A.',
    iso: 'ESP',
    bic: 'POHIESMM',
  },
  '0219': {
    description: 'BANQUE MAROCAINE COMMERCE EXTERIEUR INTERNATIONAL, S.A.',
    iso: 'ESP',
    bic: 'BMCEESMMXXX',
  },
  '0226': {
    description: 'UBS BANK, S.A.',
    iso: 'ESP',
    bic: 'UBSWESMMNPB',
  },
  '0229': {
    description: 'BANCO POPULAR-E, S.A.',
    iso: 'ESP',
    bic: 'POPLESMMXXX',
  },
  '0231': {
    description: 'DEXIA SABADELL, S.A.',
    iso: 'ESP',
    bic: 'DSBLESMMXXX',
  },
  '0232': {
    description: 'BANCO INVERSIS, S.A.',
    iso: 'ESP',
    bic: 'INVLESMMXXX',
  },
  '0233': {
    description: 'POPULAR BANCA PRIVADA, S.A.',
    iso: 'ESP',
    bic: 'POPIESMMXXX',
  },
  '0234': {
    description: 'BANCO CAMINOS, S.A.',
    iso: 'ESP',
    bic: 'CCOCESMMXXX',
  },
  '0236': {
    description: 'LLOYDS BANK INTERNATIONAL, S.A.U.',
    iso: 'ESP',
    bic: 'LOYIESMMXXX',
  },
  '0237': {
    description: 'CAJASUR BANCO, S.A',
    iso: 'ESP',
    bic: 'CSURES2CXXX',
  },
  '0487': {
    description: 'BANCO MARE NOSTRUM, S.A.',
    iso: 'ESP',
    bic: 'GBMNESMMXXX',
  },
  '0488': {
    description: 'BANCO FINANCIERO Y DE AHORRO, S.A.',
    iso: 'ESP',
    bic: 'BFASESMM',
  },
  1000: {
    description: 'INSTITUTO DE CREDITO OFICIAL.',
    iso: 'ESP',
    bic: 'ICROESMMXXX',
  },
  1459: {
    description:
      'COOP. CENTR. RAIFFEISEN-BOERENL. (RABOBANK NED.), SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'PRABESMMXXX',
  },
  1460: {
    description: 'CREDIT SUISSE AG, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'CRESESMMXXX',
  },
  1465: {
    description: 'ING DIRECT, N.V., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'INGDESMMXXX',
  },
  1467: {
    description: 'HYPOTHEKENBANK FRANKFURT AG. SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'EHYPESMXXXX',
  },
  1474: {
    description: 'CITIBANK INTERNATIONAL PLC, SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'CITIESMXSEC',
  },
  1479: {
    description: 'NATIXIS, S.A., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'NATXESMMXXX',
  },
  1497: {
    description:
      'BANCO ESPIRITO SANTO DE INVESTIMENTO, S.A., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'ESSIESMMXXX',
  },
  1524: {
    description: 'UBI BANCA INTERNATIONAL, S.A., SUCURSAL EN ESPAÑA.',
    iso: 'ESP',
    bic: 'UBIBESMMXXX',
  },
  2000: {
    description: 'CECABANK, S.A.',
    iso: 'ESP',
    bic: 'CECAESMMXXX',
  },
  2013: {
    description: 'CATALUNYA BANC, S.A.',
    iso: 'ESP',
    bic: 'CESCESBBXXX',
  },
  2038: {
    description: 'BANKIA, S.A.',
    iso: 'ESP',
    bic: 'CAHMESMMXXX',
  },
  2045: {
    description: 'CAJA DE AHORROS Y M.P. DE ONTINYENT.',
    iso: 'ESP',
    bic: 'CECAESMM045',
  },
  2048: {
    description: 'LIBERBANK, S.A.',
    iso: 'ESP',
    bic: 'CECAESMM048',
  },
  2056: {
    description: 'COLONYA - CAIXA D?ESTALVIS DE POLLENSA.',
    iso: 'ESP',
    bic: 'CECAESMM056',
  },
  2080: {
    description: 'NCG BANCO, S.A.',
    iso: 'ESP',
    bic: 'CAGLESMMXXX',
  },
  2085: {
    description: 'IBERCAJA BANCO, S.A.',
    iso: 'ESP',
    bic: 'CAZRES2ZXXX',
  },
  2086: {
    description: 'BANCO GRUPO CAJATRES, S.A.',
    iso: 'ESP',
    bic: 'CECAESMM086',
  },
  2095: {
    description: 'KUTXABANK, S.A.',
    iso: 'ESP',
    bic: 'BASKES2BXXX',
  },
  2100: {
    description: 'CAIXABANK, S.A.',
    iso: 'ESP',
    bic: 'CAIXESBBXXX',
  },
  2103: {
    description: 'UNICAJA BANCO, S.A.',
    iso: 'ESP',
    bic: 'UCJAES2MXXX',
  },
  2105: {
    description: 'CAJA CASTILLA LA MANCHA',
    iso: 'ESP',
    bic: 'CECAESMMXXX',
  },
  2108: {
    description: 'BANCO DE CAJA ESPAÑA DE INVERSIONES, SALAMANCA Y SORIA, S.A.',
    iso: 'ESP',
    bic: 'CSPAES2L108',
  },
  3025: {
    description: 'CAIXA DE C. DELS ENGINYERS C.C. INGENIEROS, S.C.C.',
    iso: 'ESP',
    bic: 'CDENESBBXXX',
  },
  3035: {
    description: 'CAJA LABORAL POPULAR, C.C.',
    iso: 'ESP',
    bic: 'CLPEES2MXXX',
  },
  3058: {
    description: 'CAJAS RURALES UNIDAS, S.C.C.',
    iso: 'ESP',
    bic: 'CCRIES2AXXX',
  },
  3081: {
    description: 'CAJA R. DE CASTILLA-LA MANCHA, S.C.C.',
    iso: 'ESP',
    bic: 'BCOEESMM081',
  },
  3183: {
    description: 'CAJA DE ARQUITECTOS S.C.C.',
    iso: 'ESP',
    bic: 'CASDESBBXXX',
  },
  3501: {
    description: 'RENTA 4 SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'RENTESMMXXX',
  },
  3524: {
    description: 'AHORRO CORPORACION FINANCIERA, S.A., SOCIEDAD DE VALORES',
    iso: 'ESP',
    bic: 'AHCFESMMXXX',
  },
  3563: {
    description: 'MAPFRE INVERSION, SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'MISVESMMXXX',
  },
  3575: {
    description: 'INVERSEGUROS, SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'INSGESMMXXX',
  },
  3604: {
    description: 'CM CAPITAL MARKETS BOLSA, SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'CAPIESMMXXX',
  },
  3641: {
    description: 'LINK SECURITIES, SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'LISEESMMXXX',
  },
  3656: {
    description: 'FINANDUERO, SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'CSSOES2SFIN',
  },
  3661: {
    description:
      'MERRILL LYNCH CAPITAL MARKETS ESPAÑA, S.A., SOCIEDAD DE VALORES.',
    iso: 'ESP',
    bic: 'MLCEESMMXXX',
  },
  3669: {
    description: 'INTERMONEY VALORES, SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'IVALESMMXXX',
  },
  3682: {
    description: 'GVC GAESCO VALORES, SOCIEDAD DE VALORES, S.A.',
    iso: 'ESP',
    bic: 'GVCBESBBETB',
  },
  9000: {
    description: 'BANCO DE ESPAÑA.',
    iso: 'ESP',
    bic: 'ESPBESMMXXX',
  },
  9020: {
    description: 'SOCIEDAD ESPAÑOLA DE SISTEMAS DE PAGO, S.A.',
    iso: 'ESP',
    bic: 'IPAYESMMXXX',
  },
  9091: {
    description: 'SDAD. RECTORA BOLSA VALORES DE BARCELONA, S.A., SRBV.',
    iso: 'ESP',
    bic: 'XBCNESBBXXX',
  },
  9092: {
    description: 'SDAD. RECTORA BOLSA DE VALORES DE BILBAO, S.A., SRBV.',
    iso: 'ESP',
    bic: 'XRBVES2BXXX',
  },
  9093: {
    description: 'SDAD. RECTORA BOLSA VALORES DE VALENCIA, S.A., SRBV.',
    iso: 'ESP',
    bic: 'XRVVESVVXXX',
  },
  9094: {
    description: 'MEFF SOCIEDAD RECTORA DE PRODUCTOS DERIVADOS S.A.U.',
    iso: 'ESP',
    bic: 'MEFFESBBXXX',
  },
  9096: {
    description: 'SOC. GEST. SIST. REGIST. Y LIQUID. VALORES.',
    iso: 'ESP',
    bic: 'IBRCESMMXXX',
  },
};

export { bankAssigned };
