const user = {
  access: 'Accés',
  assignedUsers: 'Usuari assignats',
  assignUser: 'Assignar Usuari',
  changePassword: 'Canviar contrasenya',
  changePasswordKo: 'S\'ha produït un error a l\'hora de canviar la contrasenya.',
  changePasswordOk: 'La contrasenya s\'ha canviat satisfactòriament',
  currentPassword: 'Contrasenya actual',
  customRigths: 'Permisos personalitzats',
  detail: 'Detall d\'usuari',
  duplicated: 'Ja hi ha un usuari registrat amb aquest correu electrònic',
  edit: 'Edició d\'usuari',
  email: 'Email',
  errorFileSize: 'Màxim mida d\'imatge assolit, permès',
  fakeEmail:
    'L\'usuari s\'ha registrat correctament, però no s\'ha pogut enviar el correu electrònic a la direcció proporcionada. Per favor, verifique que la direcció és correcta.',
  forgotPassword: 'L\'he oblidat',
  forgotPasswordOk:
    'S\'ha enviat un correu electrònic amb les instruccions al seu compte de correu.',
  login: 'Login',
  new: 'Nou usuari',
  newPassword: 'Introdueix la nova contrasenya',
  newUserError:
    'Para acceder a esta página, primero debe salir de la aplicación.',
  password: 'Contrasenya',
  profile: {
    allowNotifications: 'Permetre notificacions push',
    changeImage: 'Canviar imatge',
    changePass: 'Canviar contrasenya',
    imageKo: 'Fallada a l\'desar imatge',
    imageOk: 'Imatge guardada correctament',
    saved: 'S\'ha actualitzat amb èxit',
    title: 'Perfil de l\'usuari',
  },
  recover: 'Recupera el teu compte',
  reduceFileSize: 'Redueixi la mida o pes de la imatge',
  repeatNewPassword: 'Repetir nova contrasenya',
  resendPasswordMail: {
    Ko: `
    Hi ha hagut un problema amb l'enviament de correu electrònic per al canvi de contrasenya de l'usuari.`,
    Ok: 'S\'enviarà un correu electrònic per al canvi de contrasenya a l\'email de l\'usuari.',
  },
  role: {
    clone: 'Clonar',
    createdBy: 'Paper creat per',
    deleteKo: 'Fallada a l\'eliminar el paper',
    deleteOk: 'Es eliminar el registre correctament',
    description: 'Descripció de el paper',
    detail: 'Detall de rol',
    edit: 'Edició de paper',
    new: 'Nou rol',
    placeholder: 'Rols d\'usuari',
    title: 'Rols',
  },
  saveKo: 'Fallada a l\'gravar usuari',
  saveOk: 'Usuari guardat correctament',
  search: 'Cercar usuaris',
  title: 'Usuaris',
  userImage: 'Imatge d\'usuari',
  username: 'Nom d\'usuari',
  workplace: 'Centre de treball',
};

export default user;
