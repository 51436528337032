import React, { FC } from 'react';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { sortByCode, useBudgetForm } from '../helper';

import { InsuranceTableComponent } from './types';

export const InsuranceTable: FC<InsuranceTableComponent> = ({ clients, onDelete, name = '' }) => {
  const { insurances } = useBudgetForm();

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {clients
          .map((client) => {
            const insurance = insurances
              .find((insuranceItem) => insuranceItem.code === client.code);
            return insurance;
          })
          .sort(sortByCode)
          .map((client) => (client
            ? (
              <tr key={client.code}>
                <td>{client.code}</td>
                <td data-testid={`${name}-${client.code}`}>{client.description}</td>
                <td style={{ width: '48px' }}>
                  <Button
                    color="transparent"
                    testid={`${name}-remove-${client.code}`}
                    onClick={() => {
                      if (client.code) {
                        onDelete(client);
                      }
                    }}
                  >
                    <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />
                  </Button>
                </td>
              </tr>
            )
            : <React.Fragment />))}
      </tbody>
    </table>
  );
};
