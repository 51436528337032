import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

import './index.scss';

import { Nullable } from 'utils/types/Nullable/types';

import Button from 'components/base/Button';
import Input from 'components/base/Input';

import useWindowSize from 'hooks/useWindowSize';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

import { ImageCrop } from '../image-crop';

import { DocumentCameraComponent } from './types';

const DocumentCamera: FC<DocumentCameraComponent> = ({
  onConfirmPhoto,
  cameraFrameWidth,
  cameraFrameHeight,
  isFrontCameraActive,
  cameraResolutionHeight,
  cameraResolutionWidth,
  children,
  onIsFrontCameraUpdate,
}) => {
  const { t } = useTranslation();
  const webcamRef = React.useRef(null);
  const [capture, setCapture] = useState<Nullable<Base64Data>>(null);
  const [croppedCapture, setCroppedCapture] = useState<Nullable<Base64Data>>();
  const [facingMode, setfacingMode] = useState<{exact: string} | undefined>({ exact: 'environment' });
  const { smOrSmaller } = useWindowSize();

  useEffect(
    () => {
      if (isFrontCameraActive) {
        setfacingMode(undefined);
      } else {
        setfacingMode({ exact: 'environment' });
      }
    },
    [isFrontCameraActive],
  );

  const start = () => {
    setCapture(null);
  };

  const sendPhoto = useCallback(
    () => {
      if (croppedCapture) {
        onConfirmPhoto?.(croppedCapture);
        setCapture(null);
      }
    },
    [croppedCapture, onConfirmPhoto],
  );

  const takePhoto = useCallback(
    () => {
      if (webcamRef) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const webcamScreenShot = (webcamRef.current as any).getScreenshot();
        setCapture(new Base64Data({
          data: Base64Data.getFromDataUrl(webcamScreenShot, 'data') || '',
          mimetype: MimeTypes.IMAGE_PNG,
        }));
      }
    },
    [webcamRef],
  );

  return (
    <div className="document-camera">
      {capture && smOrSmaller
      && (
      <React.Fragment>
        <div className="button-group">
          <Button
            className="photo-camera-button"
            text={t('article.photo.repeat')}
            onClick={start}
          />
          <Button
            className="photo-camera-button"
            text={t('article.photo.confirm')}
            onClick={sendPhoto}
          />
        </div>
      </React.Fragment>
      )}
      {!capture
        && (
        <React.Fragment>
          <div className="action-section">
            <Input
              checked={isFrontCameraActive}
              name="front-camera"
              placeholder={t('common.frontCamera')}
              type="checkbox"
              onChange={() => {
                onIsFrontCameraUpdate(!isFrontCameraActive);
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Webcam
              ref={webcamRef}
              audio={false}
              className="photo-camera-canvas"
              height={`${cameraFrameHeight}px`}
              screenshotFormat={MimeTypes.IMAGE_PNG}
              screenshotQuality={1}
              style={{
                border: '5px solid yellow',
              }}
              videoConstraints={{
                facingMode,
                height: cameraResolutionHeight,
                width: cameraResolutionWidth,
              }}
              width={`${cameraFrameWidth}px`}
              forceScreenshotSourceSize
            />
          </div>
          {children}
          <div className="button-group">
            <Button
              className="photo-camera-button photo-camera-button-take"
              text={t('article.photo.take')}
              onClick={takePhoto}
            />
          </div>
        </React.Fragment>
        )}

      {capture
      && (
      <React.Fragment>
        <ImageCrop
          cameraFrameWidth={cameraFrameWidth}
          image={capture}
          onCropFinish={(image) => {
            setCroppedCapture(image);
          }}
        />
        <div className="button-group">
          <Button
            className="photo-camera-button"
            text={t('article.photo.repeat')}
            onClick={start}
          />
          <Button
            className="photo-camera-button"
            text={t('article.photo.confirm')}
            onClick={sendPhoto}
          />
        </div>
      </React.Fragment>
      )}
    </div>
  );
};

DocumentCamera.defaultProps = {
  onConfirmPhoto: () => false,
};

export { DocumentCamera };
