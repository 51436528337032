import React from 'react';

import { ServiceInformationDto } from 'config/apiFunus/types';
import useCemetery from 'hooks/useCemetery';

import '../index.scss';
import { DateTimeInfo } from '../DateTimeInfo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const IntermentCell = ({ value }: { value: ServiceInformationDto}) => {
  const { getCemetery } = useCemetery(value.city);
  return (
    <React.Fragment>
      <div className="text-align-center">{getCemetery(value.location)?.description || '--'}</div>
      <DateTimeInfo date={value.date} time={`${value.date} ${value.time}`} />
    </React.Fragment>
  );
};
