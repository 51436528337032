import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { budgetAcceptConfirmModalComponent } from './types';

export const BudgetAcceptConfirmModal: FC<budgetAcceptConfirmModalComponent> = (
  {
    show, onCancel, onSuccess, budgetId,
  },
) => {
  const { t } = useTranslation();
  const { AcceptBudget } = useBudget();

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          onClick: () => {
            AcceptBudget(budgetId)
              .then((response) => {
                onSuccess();
                return response;
              })
              .catch(() => false);
          },
          text: t('common.accept'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.actions.accept')}
      onHide={() => false}
    >
      <div>{t('budget.actions.acceptQuestion')}</div>
    </Modal>
  );
};
