import classNames from 'classnames';
import React, {
  useRef,
  FC,
  Fragment,
  useEffect,
  useState,
  MutableRefObject,
} from 'react';
import { useTranslation } from 'react-i18next';

import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import WorkOrderCard from 'components/workOrder/Card';

import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useWindowSize from 'hooks/useWindowSize';
import Workorder from 'models/Workorder';

enum ListStatusCode {
  pending = 'pending',
  inProgress = 'inProgress',
  completed = 'completed',
}

type PriorityState = {
  label: string;
  value: string;
};

type WorkOrderGroupProps = {
  code: ListStatusCode;
  id: number;
  initialRequest: () => void;
  list: Workorder[];
  priorityState: PriorityState;
};

const WorkOrderGroup: FC<WorkOrderGroupProps> = ({
  code,
  list,
  id,
  initialRequest,
  priorityState,
}) => {
  const [filteredList, setFilteredList] = useState<Workorder[]>([]);
  const { t } = useTranslation();
  const { lgOrSmaller, height } = useWindowSize();
  const { user } = useProvidedAuth();
  const cardRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const newList = list.filter(
      (order) => priorityState.value === 'ALL' || order.priority === priorityState.value,
    );
    if (!user && cardRef?.current?.clientHeight) {
      const maxCards = height
        ? Math.floor((height - 192) / cardRef.current.clientHeight) - 1
        : 0;
      const columnsQty = code === ListStatusCode.pending ? 2 : 1;
      newList.splice(maxCards * columnsQty);
    }
    setFilteredList(newList);
  }, [list, height, cardRef?.current?.clientHeight, user, priorityState.value, code]);

  return (
    <div key={id} className={classNames(`work-order-group-${code}`)}>
      {!lgOrSmaller && <h4>{t(`order.status.${code}`)}</h4>}
      <div>
        {filteredList?.length > 0 ? (
          <Fragment>
            {filteredList.map((order, idx) => (
              <WorkOrderCard
                key={order.id}
                callBack={initialRequest}
                cardRef={!idx ? cardRef : undefined}
                order={order}
              />
            ))}
            {filteredList.length < list.length && (
              <div className="more-card">
                <div className="card-number">
                  {list.length - filteredList.length}
                </div>
                <div>{t('order.andOther')}</div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="no-elements">
            <CustomIcon icon={OtherTypes.NO_ELEMENTS} />
            <span>{t('order.noOrders')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkOrderGroup;
