import { FormikProps } from 'formik';
import React, { Fragment } from 'react';

import { IEdit } from 'components/base/FormPage';
import Input from 'components/base/Input';

import RightsFieldsets from 'components/user/Rights';

import { i18n } from 'i18n';
import { Permission, PermissionGroupDto, RoleForm } from 'models/UserRole';

export const getRoleFormFields = (
  {
    errors,
    handleBlur,
    isSubmitting,
    setFieldValue,
    touched,
    values,
  }: FormikProps<RoleForm>,
  disabled?: boolean,
): JSX.Element => (
  <Fragment>
    <Input
      key="name"
      disabled={disabled || isSubmitting}
      error={touched.name && errors.name}
      name="name"
      placeholder={i18n.t('common.name')}
      type="text"
      value={values.name || ''}
      onBlur={handleBlur}
      onChange={(val) => setFieldValue('name', val)}
    />
    <Input
      key="description"
      disabled={disabled || isSubmitting}
      error={touched.description && errors.description}
      name="description"
      placeholder={i18n.t('common.description')}
      type="text"
      value={values.description || ''}
      onBlur={handleBlur}
      onChange={(val) => setFieldValue('description', val)}
    />
    <RightsFieldsets
      key="rightsFieldsSet"
      disabled={disabled || isSubmitting}
      error={touched.permissions && (errors.permissions as string)}
      handleBlur={handleBlur}
      permissions={values.permissions}
      setFieldValue={setFieldValue}
    />
  </Fragment>
);

export const cleanFormAfterSubmit = (
  values: RoleForm | IEdit,
  isEdit: boolean,
  isClone = false,
): RoleForm => {
  const valuesCopy = isEdit || isClone ? { ...(values as IEdit)?.values } : { ...values };
  const permissionsSelectedToBePersisted: Permission[] = [];

  (valuesCopy as RoleForm).permissions.forEach(
    (permission: PermissionGroupDto) => {
      permission.permissionDtos.forEach((permissionDto: Permission) => {
        if (permissionDto.selected) {
          permissionsSelectedToBePersisted.push(permissionDto);
        }
      });
    },
  );

  valuesCopy.permissions = permissionsSelectedToBePersisted;

  let exitData = valuesCopy;
  if (isClone) {
    exitData = {
      description: exitData.description,
      name: exitData.name,
      permissions: exitData.permissions,
      readOnly: false,
    };
  } else if (isEdit) {
    exitData = { id: values.id, values: valuesCopy as RoleForm };
  }

  return exitData as RoleForm;
};
