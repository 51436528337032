import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from 'utils/toasts';

import { orderLineController } from 'config/apiFunus';

import { sampleBaseUrl } from './sampleBaseUrl';

export const useFlowerPhotos = (): {
  fetchPhoto: (id: string | number) => void;
  data?: string;
} => {
  const [data, setData] = useState<string>(sampleBaseUrl);
  const { t } = useTranslation();
  const fetchPhoto = useCallback(
    (id: string | number) => {
      orderLineController
        .GetArticlePhoto(Number(id))
        .then((response) => {
          const [, base64] = response.split(',');
          setData(base64);
          return response;
        })
        .catch((error) => {
          showErrorToast(t(error.message));
        });
    },
    [t],
  );

  return {
    data,
    fetchPhoto,
  };
};
