import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import FormPage from 'components/base/FormPage';

import { getIpFields } from 'components/ip/Form';

import { SearchIpProps } from 'models/Ip';

type PageParams = {
  id: string;
};

const IpForm: FC = () => {
  const [editing, setEditing] = useState(false);
  const { id } = useParams<PageParams>();
  const { t } = useTranslation();
  useEffect(() => {
    const arr = window.location.pathname.split('/');

    if (arr[arr.length - 1] === 'edit') {
      setEditing(true);
    }
  }, []);

  return (
    <div id="ip-form-page">
      <FormPage<SearchIpProps>
        backUrl={config.url.ips}
        editing={editing}
        fields={getIpFields}
        getFunction={config.apiFunus.ips.getIpById}
        id={id}
        initialValues={{}}
        saveFunction={config.apiFunus.ips.saveIp}
        title={{
          add: t('ip.new'),
          edit: t('ip.edit'),
          see: t('ip.detail'),
        }}
        updateFunction={config.apiFunus.ips.editIp}
        validateForm={(values) => config.validator(values, 'television')}
        onError={() => showErrorToast(t('ip.saveKo'))}
        onSave={() => showSuccessToast(t('ip.saveOk'))}
      />
    </div>
  );
};

export default IpForm;
