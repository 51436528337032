import { DeathType } from 'models/Record';
import { ApiFunusMasterData } from './types';
import axios from 'config/axios';

export const masterData: ApiFunusMasterData = {
  getArticles: (erpId, block, article, cityCode) => axios.get(`/articles/${erpId}/${block}/${article}/${cityCode}`),
  getCemeteryByCity: (cityCode) => axios.get(`/masterData/cemetery/${cityCode}`),
  getCemeterySections: (cityCode, cemeteryCode) => axios.get(`/masterData/cemeterySection/${cityCode}/${cemeteryCode}`),
  getCitiesByCountryNProvince: (countryCode, provinceCode) => axios.get(
    `/masterData/city/${provinceCode ? `${countryCode}/${provinceCode}` : countryCode || ''}`
  ),
  getCrematoryByCity: (cityCode) => axios.get(`/masterData/crematory/${cityCode}`),
  getCeremonyLocationsByCity: (cityCode) => axios.get(`/masterData/ceremony/location/${cityCode}`),
  getDeathLocationByCity: (cityCode) => axios.get(`/masterData/deathLocation/${cityCode}`),
  getDepositsByCity: (cityCode) => axios.get(`/masterData/deposit/${cityCode}`),
  getDoctorsByDeathType: (deathType) => axios.get(`/masterData/doctor/${deathType === DeathType.natural ? 'NATURAL' : 'JUDICIAL'}`),
  getMasterData: () => axios.get(`/master/service`),
  getMasterDataByParam: (param) => axios.get(`/masterData/${param}`),
  getOvenByCityNCrematory: (crematoryCode, cityCode) => axios.get(`/masterData/oven/${cityCode}/${crematoryCode}`),
  getProvinceByCountry: (countryCode) => axios.get(`/masterData/province/${countryCode || ''}`),
  getRecordTypes: () => axios.get('/masterData/record/type'),
  getTribunalByCity: (cityCode) => axios.get(`/masterData/tribunal/${cityCode}`),
  getVigilLocationByCity: (cityCode) => axios.get(`/masterData/vigil/location/${cityCode}`),
  getSanitaryPracticeLocationsByCity: (cityCode) => axios.get(`/masterData/sanitaryPracticeLocation/${cityCode}`),
  getVigilRoomByCity: (cityCode) => axios.get(`/masterData/vigil/${cityCode}`),
};
