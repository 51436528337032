export const maxAllowedInKb = 256;
/**
 * @description method to check file size, and call to error method
 * @param fileHere
 * @param callbackError
 * @returns boolean
 */
export const checkSize = (
  fileHere: File,
  callbackError: () => void,
  maxSize = maxAllowedInKb,
): boolean => {
  const fileSizeToKb = fileHere.size / 1000;
  let allowed = true;
  if (fileSizeToKb > maxSize) {
    callbackError();
    allowed = false;
  }
  return allowed;
};
