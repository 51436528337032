import { IAddress } from './types';

export class Address implements IAddress {
  work: boolean;

  city: string;

  country: string;

  province: string;

  constructor(parameters: IAddress) {
    this.city = parameters.city;
    this.country = parameters.country;
    this.province = parameters.province;

    if (typeof parameters.work === 'string') {
      this.work = parameters.work === 'true';
    } else {
      this.work = parameters.work;
    }
  }

  public isFullFilled(): boolean {
    return this.city !== ''
      && this.city !== undefined
      && this.province !== ''
      && this.province !== undefined
      && this.country !== ''
      && this.country !== undefined
      && this.work !== undefined;
  }

  public isEqual(entity: IAddress): boolean {
    const cityMatch = this.city === entity.city;
    const provinceMatch = this.province === entity.province;
    const countryMatch = this.country === entity.country;

    return cityMatch && provinceMatch && countryMatch;
  }
}
