const ip = {
  address: 'Direcció IP',
  detail: 'Detall de television',
  edit: 'Edita television',
  new: 'Nova televisió',
  notAllowed: 'Aquesta televisió no està habilitada. Contacti amb l\'administrador i faciliti-la la següent adreça IP:',
  saveKo: 'S\'ha produït un error a l\'hora de crear la televisió.',
  saveOk: 'La televisió s\'ha creat satisfactòriament.',
  search: 'Cercar televisions',
  title: 'Televisions',
  wrongCenter:
    'El centre o el tipus de centre és incorrecte. Si us plau, comproveu la url.',
};
export default ip;
