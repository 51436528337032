import { useEffect, useState } from 'react';

import { normalizeMasterData } from 'utils/normalizer';

import { getSanitaryPracticeLocations } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { NormalizedType } from 'models/MasterService';

type SanitaryPracticeLocationType = {
  sanitaryPracticeLocations: IMasterDataDto[];
  normalizedSanitaryPracticeLocations: NormalizedType;
};

const useSanitaryPracticeLocation = (
  cityCode?: string,
): SanitaryPracticeLocationType => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  const [
    normalizedSanitaryPracticeLocations,
    setNormalizedSanitaryPracticeLocations,
  ] = useState({});
  const [sanitaryPracticeLocations, setSanitaryPracticeLocations] = useState<
    IMasterDataDto[]
  >([]);

  const getLocations = async () => {
    getSanitaryPracticeLocations(cityCode)
      .then((response) => {
        setSanitaryPracticeLocations(response.data as IMasterDataDto[]);
        return response.data;
      })
      .catch(() => {
        setSanitaryPracticeLocations([]);
      });
  };

  useEffect(() => {
    if (cityCode !== cityLoaded) {
      setCityLoaded(cityCode);
      getLocations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  useEffect(() => {
    setNormalizedSanitaryPracticeLocations(
      normalizeMasterData(sanitaryPracticeLocations),
    );
  }, [sanitaryPracticeLocations]);

  return { normalizedSanitaryPracticeLocations, sanitaryPracticeLocations };
};

export default useSanitaryPracticeLocation;
