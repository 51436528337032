import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Modal from 'components/base/Modal';

import { ArticleStatusRequestDtoEvent } from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';

type CancelRequestModalComponent = {
  show: boolean;
  id: number | undefined;
  onHide: () => void;
  onSuccess: () => void,
}

export const CancelRequestModal
 :FC<CancelRequestModalComponent> = ({
   show,
   id,
   onHide,
   onSuccess,
 }) => {
   const { t } = useTranslation();
   const { updateArticleStatus } = useArticles();

   const cancelRequest = useCallback(
     async () => {
       if (id) {
         await updateArticleStatus(
           id,
           ArticleStatusRequestDtoEvent.CANCEL,
         )
           .then(() => {
             onHide();
             showSuccessToast(t('article.flowers.confirmCancelOK'));
             onSuccess?.();
             return true;
           })
           .catch(() => {
             showErrorToast(t('article.flowers.confirmCancelKO'));
           });
       }
     },
     [id, onHide, onSuccess, t, updateArticleStatus],
   );

   return (
     <Modal
       buttons={[
         {
           color: 'secondary',
           onClick: onHide,
           text: t('common.cancel'),
         },
         {
           onClick: cancelRequest,
           text: t('common.accept'),
         },
       ]}
       show={show}
       showCancel={false}
       title={t('article.flowers.confirmCancelTitle')}
       onHide={onHide}
     >
       <React.Fragment>{t('article.flowers.confirmCancelText')}</React.Fragment>
     </Modal>
   );
 };
