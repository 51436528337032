import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateManager';

import { displayNoValue } from 'components/base/NoData';

import { useCenters } from 'hooks/useCenters';
import useDeposit from 'hooks/useDeposit';
import { useFuneraryOrigins } from 'hooks/useFuneraryOrigins';
import { useReceptionTypes } from 'hooks/useReceptionTypes';
import { useVigilLocation } from 'hooks/useVigilLocation';
import { FittedType } from 'models/MasterService';
import { ReceptionInfo } from 'models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const Reception: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { getCenter } = useCenters();
  const { getReceptionType } = useReceptionTypes();
  const { getFuneraryOrigin } = useFuneraryOrigins();
  const {
    getVigilLocation,
  } = useVigilLocation((order?.info as ReceptionInfo)?.address?.city);
  const { t } = useTranslation();
  const { normalizedDeposits } = useDeposit(
    (order.info as ReceptionInfo)?.deposit?.address?.city,
  );

  useEffect(() => {
    const info: ReceptionInfo = order.info as ReceptionInfo;

    const newDetails: DetailType[] = [
      {
        text: t('order.details.mortuary'),
        value: getFuneraryOrigin(info?.funeraryOrigin)?.description || displayNoValue,
      },
      {
        text: t('common.type'),
        value: getReceptionType(info?.type)?.descriptionInfo || displayNoValue,
      },
      {
        text: t('common.date'),
        value: info?.date ? formatDate(info?.date) : displayNoValue,
      },
      {
        text: t('common.hour'),
        value: info?.time || displayNoValue,
      },
      {
        text: t('order.details.destinationCenter'),
        value: getCenter(info?.destinationCenter)?.description,
      },
      ...info?.type === FittedType.coffin
        ? [{
          text: t('order.details.vigilRoom'),
          value: getVigilLocation(info?.location)?.description || displayNoValue,
        }] : [],
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
      {
        text: t('common.adviserComment'),
        value: info?.observations,
      },
    ];
    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order.info,
    normalizedDeposits,
    t,
    getCenter,
    getVigilLocation,
  ]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
      testid={`workorder-info-${order?.type}`}
    />
  );
};

export default Reception;
