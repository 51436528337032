import { useEffect, useState } from 'react';

import config from 'config';

import {
  checkHaveAtLeastOnePermission,
} from 'utils/permissions';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';

import { useInterval } from './useInterval';
import { useProvidedAuth } from './useProvidedAuth';
import { useWebNotifiactions } from './useWebNotifications';

export const useNotifications = (): void => {
  const { startInterval, stopInterval } = useInterval();
  const { send } = useWebNotifiactions();
  const { user } = useProvidedAuth();

  const [intervalId, setIntervalId] = useState<number>();

  const fetchNotificationsInterval = parseInt(
    process.env.REACT_APP_NOTIFICATIONS_INTERVAL || '60000',
    10,
  );
  const fetchNotifications = async () => {
    const response = await config.apiFunus.notifications.getNotifications();
    const notifications = response.data;
    notifications.forEach((item) => {
      send(item.title, item.content);
    });
  };

  useEffect(() => {
    const userHasNotificationPermission = checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.RECORD_NOTIFY,
        SimpleUserRequestPermissionNames.ENQUIRY_NOTIFY,
        SimpleUserRequestPermissionNames.COLLECTION_NOTIFY,
      ],
      user?.role.permissions,
    );

    if (userHasNotificationPermission) {
      if (intervalId) stopInterval(intervalId);
      setIntervalId(startInterval(fetchNotifications, fetchNotificationsInterval).intervalId);
    } else if (intervalId) stopInterval(intervalId);

    return () => {
      stopInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};
