const menu = {
  addenquiries: 'Nova consulta',
  addips: 'Nova televisió',
  addrecords: 'Nou expedient',
  addroles: 'Afegir rols',
  addsales: 'Nova venda',
  addusers: 'Nou usuari',
  agenda: 'Dietari',
  budgets: 'Tramitació',
  calendar: 'Calendari',
  detailarticles: 'Comandes de Flors',
  detailbudgets: 'Administrador Pressupost',
  detailenquiries: 'Detall de consulta',
  detailips: 'Detall de televisió',
  detailrecords: 'Detall d\'expedient',
  detailroles: 'Detalls de rols',
  detailsales: 'Detall de venda',
  detailservices: 'Serveis',
  detailusers: 'Detall d\'usuari',
  detailworkshop: 'Taller per expedient',
  editarticles: 'Flors expedient',
  editbudgets: 'Administrador Pressupost',
  editenquiries: 'Editar consulta',
  editips: 'Editar televisió',
  editrecords: 'Editar expedient',
  editroles: 'Editar rol',
  editusers: 'Edició d\'usuari',
  enquiries: 'Consultes',
  enquiriesDashboard: 'Panell consultes',
  flowers: 'Flors',
  home: 'Inici',
  information: 'Informació',
  ips: 'Televisions',
  logout: 'Sortir',
  management: 'Gerència',
  notFound: 'Pàgina no trobada',
  operator: 'Operador',
  password: 'Canviar contrasenya',
  reception: 'Recepció',
  records: 'Expedients',
  roles: 'Rols d\'usuari',
  sales: 'Vendes',
  scan: 'Escanejar QR',
  user: 'Perfil d\'usuari',
  userProfile: 'Perfil',
  users: 'Usuaris',
  viewqr: 'QR',
  workshop: 'Taller',
};
export default menu;
