import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';

import WorkOrderInfo from 'components/workOrder/Info';

import { useAdvice } from 'hooks/useAdvice';
import OrderPriority from 'models/OrderPriority';
import { StatusCodes } from 'models/OrderStatus';
import OrderType from 'models/OrderType';
import Workorder from 'models/Workorder';
import { WorkorderEntity } from 'models/WorkorderEntity';

import { CalendarServiceInfoComponent } from './types';

export const CalendarServiceInfo: FC<CalendarServiceInfoComponent> = (
  {
    show, onCancel, serviceId, postCeremonyId, type, erpId,
  },
) => {
  const { t } = useTranslation();

  const { fetchServiceInfo, serviceInfo, loadingServiceInfo } = useAdvice();

  useEffect(() => {
    if (show && serviceId) {
      fetchServiceInfo(serviceId, postCeremonyId);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [serviceId, show]);

  const title = useMemo(() => {
    const detail = t('order.details.details');
    let typeLabel = '';
    if (postCeremonyId) {
      typeLabel = t('order.types.POST_CEREMONIA');
    } else if (type) {
      typeLabel = t(`order.types.${type}`);
    }

    return `${detail} ${typeLabel}. ${t('common.erpId')} ${erpId}`;
  },
  [erpId, postCeremonyId, t, type]);

  const orderData:Workorder = useMemo(() => new WorkorderEntity({
    assignedUsers: [],
    createdBy: '',
    createdDate: '',
    deceasedFirstSurname: '',
    deceasedName: '',
    deceasedSecondSurname: '',
    id: '',
    info: {
      ...serviceInfo,
      type,
    },
    priority: OrderPriority.HIGH,
    startDate: '',
    status: StatusCodes.COMPLETED,
    type: ((type as unknown) as OrderType),
  }), [serviceInfo, type]);

  return (
    <Modal
      buttons={[{
        onClick: () => {
          onCancel();
        },
        text: t('common.close'),
      },
      ]}
      className="calendar-service-info-modal"
      show={show}
      showCancel={false}
      showXButton={false}
      title={title}
      onHide={() => false}
    >
      {loadingServiceInfo && (<Loader />)}
      {serviceInfo && (
      <WorkOrderInfo
        loading={false}
        order={orderData}
        expanded
      />
      )}
    </Modal>
  );
};
