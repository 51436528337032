import { FormikProps } from 'formik';
import React, {
  FC, ReactElement, useCallback, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from 'utils/toasts';

import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import { TableActionsProps } from 'components/base/Table/ActionsColumn/types';

import { IFilter } from 'config/apiFunus/types';
import { IMasterDataDto } from 'models/MasterData';
import { budgetMasterDataClient } from 'modules/budget/api';
import { ArticleTrackingItem } from 'modules/budget/api/budgetMasterDataClient';
import { ArticleType } from 'modules/budget/models/Article/types';

import { BudgetsArticlesColumn, BudgetsArticlesFilter } from './types';

const useBudgetArticlesTable = (): {
  tracking: Array<ArticleTrackingItem>;
  fetchArticleTracking: (filter: IFilter<BudgetsArticlesFilter>) => void
} => {
  const [tracking, settracking] = useState<Array<ArticleTrackingItem>>([]);
  const fetchArticleTracking = useCallback(
    ({
      filter,
      page,
      sort,
    }) => budgetMasterDataClient.GetArticlesTracking({
      filter,
      page,
      sort,
    })
      .then((response) => {
        settracking(response.list);
        return response;
      })
      .catch((error) => {
        showErrorToast(error.message);
        throw error;
      }),
    [],
  );

  return {
    fetchArticleTracking,
    tracking,
  };
};

export const BudgetsArticlesTable: FC<Record<string, unknown>> = () => {
  const { fetchArticleTracking } = useBudgetArticlesTable();
  const { t } = useTranslation();

  const fields = useCallback(
    (
      formikProps: FormikProps<BudgetsArticlesFilter>,
    ): ReactElement[] => [
      <SideFilterField
        key="code"
        filterType="input"
        formikProps={formikProps}
        name="code"
        placeholder={`${t('common.code')}`}
      />,
      <SideFilterField
        key="description"
        filterType="input"
        formikProps={formikProps}
        name="description"
        placeholder={`${t('common.description')}`}
      />,
      <SideFilterField<BudgetsArticlesFilter, IMasterDataDto>
        key="type"
        filterType="select"
        formikProps={formikProps}
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="type"
        options={Object.keys(ArticleType).map((k) => ({
          code: k,
          description: t(`budget.articleTypes.${k}`),
        }))}
        placeholder={`${t('record.type')}`}
      />,
      <SideFilterField
        key="active"
        filterType="tristate"
        formikProps={formikProps}
        name="active"
        placeholder={t('common.active')}
      />,
      <SideFilterField
        key="hasRule"
        filterType="tristate"
        formikProps={formikProps}
        name="hasRule"
        placeholder={t('budget.hasRule')}
      />,
    ],
    [t],
  );

  const columns = useMemo(
    (): BudgetsArticlesColumn[] => {
      const codeColumn: BudgetsArticlesColumn = {
        accessor: 'code',
        Header: `${t('common.id')}`,
        sortable: true,
      };
      const descriptionColumn: BudgetsArticlesColumn = {
        accessor: 'description',
        Header: `${t('common.description')}`,
        sortable: true,
      };

      const activeColumn: BudgetsArticlesColumn = {
        accessor: 'active',
        Cell: ({
          row: {
            original: {
              active,
            },
          },
        }) => (active ? t('common.yes') : t('common.no')),
        Header: `${t('common.active')}`,
        sortable: true,
      };

      const hasRulesColumn: BudgetsArticlesColumn = {
        accessor: 'hasRule',
        Cell: ({
          row: {
            original: {
              hasRule,
            },
          },
        }) => (hasRule ? t('common.yes') : t('common.no')),
        Header: `${t('budget.hasRule')}`,
        sortable: true,
      };

      const hasArticleRuleColumn: BudgetsArticlesColumn = {
        accessor: 'hasArticleRule',
        Cell: ({
          row: {
            original: {
              hasArticleRule,
            },
          },
        }) => (hasArticleRule ? t('common.yes') : t('common.no')),
        Header: `${t('budget.hasArticleRule')}`,
        sortable: false,
      };
      const hasClientRuleColumn: BudgetsArticlesColumn = {
        accessor: 'hasClientRule',
        Cell: ({
          row: {
            original: {
              hasClientRule,
            },
          },
        }) => (hasClientRule ? t('common.yes') : t('common.no')),
        Header: `${t('budget.hasClientRule')}`,
        sortable: false,
      };
      const hasLocationRuleColumn: BudgetsArticlesColumn = {
        accessor: 'hasLocationRule',
        Cell: ({
          row: {
            original: {
              hasLocationRule,
            },
          },
        }) => (hasLocationRule ? t('common.yes') : t('common.no')),
        Header: `${t('budget.hasLocationRule')}`,
        sortable: false,
      };

      const hasResponseRuleColumn: BudgetsArticlesColumn = {
        accessor: 'hasResponseRule',
        Cell: ({
          row: {
            original: {
              hasResponseRule,
            },
          },
        }) => (hasResponseRule ? t('common.yes') : t('common.no')),
        Header: `${t('budget.hasResponseRule')}`,
        sortable: false,
      };
      const sortingColumn: BudgetsArticlesColumn = {
        accessor: 'sorting',
        Header: `${t('budget.sorting')}`,
        sortable: true,
      };

      return [
        codeColumn,
        descriptionColumn,
        activeColumn,
        hasRulesColumn,
        hasArticleRuleColumn,
        hasClientRuleColumn,
        hasLocationRuleColumn,
        hasResponseRuleColumn,
        sortingColumn,
      ];
    },
    [t],
  );

  return (
    <React.Fragment>
      <FilteredPage
        actions={(item: ArticleTrackingItem): TableActionsProps => ({
          edit: {
            icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
            tooltipCaption: t('common.edit'),
            url: `/budgets/article/${item.code}/rules`,
          },
        })}
        apiCall={async ({ filter, page, sort }) => {
          const response = await fetchArticleTracking({
            filter,
            page,
            sort,
          });
          return {
            data: response,
          };
        }}
        columns={columns}
        fields={fields}
        initialValues={{}}
        text={{
          search: t('common.search'),
          title: t('budget.config.title'),
        }}
      />

    </React.Fragment>
  );
};
