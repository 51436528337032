import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCountries } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

import { CountriesHook } from './types';

export const useCountries = ():CountriesHook => {
  const dispatch = useDispatch();
  const { items, normalized } = useSelector(
    (state: RootState) => ({
      items: state.masterData.countries,
      normalized: state.normalized.countries,
    }),
  );

  const getCountry = useCallback(
    (code: string | undefined) => (code && normalized[code]
      ? normalized[code]
      : undefined),
    [normalized],
  );

  useEffect(() => {
    dispatch(getCountries());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountryByName = useCallback(
    (name: string) => {
      const getByName = (i: IMasterDataDto) => i.description?.toUpperCase() === name?.toUpperCase();
      const countries = items
        .filter(getByName);

      if (countries.length === 0) return null;
      return countries.length === 1 ? countries.find(getByName) : countries;
    },
    [items],
  );

  return {
    countries: items || [],
    getCountry,
    getCountryByName,
  };
};
