import { Nullable } from 'utils/types/Nullable/types';

import { ScanDataGenre } from 'modules/ia/models/ScanDataGenre';

import { IAGender } from './types/IAGender';

export const mapGenre = (source: Nullable<IAGender>): Nullable<ScanDataGenre> => {
  if (source === 'M') return 'H';
  if (source === 'F') return 'M';
  return null;
};
