import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCenters } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

export enum Centers {
  DIRECTION = 'DIRECCIO_CENTER',
  MATARO = 'MATARO_CENTER',
  CALELLA= 'CALELLA_CENTER',
  GRANOLLERS = 'GRANOLLERS_CENTER',
  PINEDA = 'PINEDA_CENTER',
  IMCL = 'IMCL',
}

interface CenterHierarchy {
  code: string,
  children: Array<string>
}

interface CentersHook {
  centers: IMasterDataDto[],
  hierarchy: Array<CenterHierarchy>,
  getCenter: (code: string | undefined) => IMasterDataDto,

}

export const useCenters = ():CentersHook => {
  const dispatch = useDispatch();
  const { items, normalized } = useSelector(
    (state: RootState) => ({
      items: state.masterData.centers,
      normalized: state.normalized.centers,
    }),
  );

  const centersHierarchy = [
    {
      children: [
        Centers.MATARO,
        Centers.CALELLA,
        Centers.GRANOLLERS,
        Centers.PINEDA,
        Centers.IMCL,
      ],
      code: Centers.DIRECTION,
    },
    {
      children: [
        Centers.CALELLA,
        Centers.PINEDA,
        Centers.IMCL,
      ],
      code: Centers.MATARO,
    },
    {
      children: [Centers.IMCL],
      code: Centers.PINEDA,
    },
    {
      children: [Centers.IMCL],
      code: Centers.CALELLA,
    },
    {
      children: [Centers.IMCL],
      code: Centers.GRANOLLERS,
    },
    {
      children: [],
      code: Centers.IMCL,
    },
  ];

  const getCenter = useCallback(
    (code: string | undefined) => (code && normalized[code]
      ? normalized[code]
      : undefined),
    [normalized],
  );

  useEffect(() => {
    dispatch(getCenters());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    centers: items || [],
    getCenter,
    hierarchy: centersHierarchy,
  };
};
