import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Datepicker from 'components/base/Datepicker';
import Input from 'components/base/Input';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import { useVigilLocation } from 'hooks/useVigilLocation';
import { VigilLocation } from 'models/MasterData';
import { SaveService } from 'models/MasterService';
import { SaveRecord } from 'models/Record';

type VigilProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
  record?: SaveRecord;
};

const VigilForm: FC<VigilProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const { t } = useTranslation();
  const { vigilLocations, normalizedVigilLocations } = useVigilLocation(
    values.vigilServiceDto?.address?.city,
  );
  // const { normalizedVigilRoom, vigilRoom } = useVigilRoom(values.vigilServiceDto?.address?.city);

  const vigilAtHomeOnChange = (val: boolean) => {
    setFieldValue('vigilServiceDto.vigilAtHome', val);

    if (val && record?.deathData?.pickupAddress) {
      setFieldValue('vigilServiceDto.location', undefined);
      setFieldValue(
        'vigilServiceDto.address.country',
        record.deathData.pickupAddress?.country,
      );
      setFieldValue(
        'vigilServiceDto.address.province',
        record.deathData.pickupAddress?.province,
      );
      setFieldValue(
        'vigilServiceDto.address.city',
        record.deathData.pickupAddress?.city,
      );
      setFieldValue(
        'vigilServiceDto.address.streetName',
        record.deathData.pickupAddress?.streetName,
      );
      setFieldValue(
        'vigilServiceDto.address.postalCode',
        record.deathData.pickupAddress?.postalCode,
      );
    } else {
      setFieldValue('vigilServiceDto.address.streetName', undefined);
    }
  };

  return (
    <div className={classNames('vigil-form', className)}>
      <fieldset>
        <legend>{t('service.VELATORIO')}</legend>
        <SelectCountryProvinceCity
          key="address"
          cityProps={{
            error: getIn(errors, 'vigilServiceDto.address.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue('vigilServiceDto.address.city', val);
              setFieldValue('vigilServiceDto.vigilRoom', undefined);
              setFieldValue('vigilServiceDto.location', undefined);
            },
            placeholder: t('common.city'),
            value: values.vigilServiceDto?.address?.city || '',
          }}
          countryProps={{
            error: getIn(errors, 'vigilServiceDto.address.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('vigilServiceDto.address.country', val),
            placeholder: t('common.country'),
            value: values.vigilServiceDto?.address?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(errors, 'vigilServiceDto.address.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('vigilServiceDto.address.province', val),
            placeholder: t('common.province'),
            value: values.vigilServiceDto?.address?.province || '',
          }}
        />
        <Input
          key="vigilAtHome"
          checked={!!values.vigilServiceDto?.vigilAtHome}
          disabled={isSubmitting || disabled}
          id="vigilAtHome"
          name="vigilAtHome"
          placeholder={t('service.vigil.atHome')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={vigilAtHomeOnChange}
        />
        <Select<VigilLocation>
          key="location"
          disabled={
            isSubmitting
            || disabled
            || !values.vigilServiceDto?.address?.city
            || values.vigilServiceDto?.vigilAtHome
          }
          error={getIn(errors, 'vigilServiceDto.location')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="location"
          options={vigilLocations}
          placeholder={t('common.location')}
          value={
            values.vigilServiceDto?.location
              ? normalizedVigilLocations[values.vigilServiceDto?.location]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => {
            setFieldValue('vigilServiceDto.location', val?.code);
            setFieldValue('vigilServiceDto.address.streetName', val?.location);
          }}
        />
        <Textarea
          key="address.streetName"
          disabled={
            isSubmitting || disabled || !values.vigilServiceDto?.vigilAtHome
          }
          error={getIn(errors, 'vigilServiceDto.address.streetName')}
          id="address.streetName"
          name="address.streetName"
          placeholder={t('common.domicile')}
          value={values.vigilServiceDto?.address?.streetName || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.address.streetName', val)}
        />
        {/* <Select<IMasterDataDto>
        disabled={isSubmitting || disabled || !values.vigilServiceDto?.address?.city}
        error={getIn(errors, 'vigilServiceDto.vigilRoom')}
        key="vigilRoom"
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="vigilRoom"
        placeholder={t('service.vigil.room')}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('vigilServiceDto.vigilRoom', val?.code)}
        options={vigilRoom}
        searchable
        value={
          values.vigilServiceDto?.vigilRoom
            ? normalizedVigilRoom[values.vigilServiceDto?.vigilRoom]
            : undefined
        }
      /> */}
        <Input
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'vigilServiceDto.vigilRoom')}
          id="vigilServiceDto.room"
          name="vigilServiceDto.room"
          placeholder={t('service.vigil.room')}
          type="text"
          value={values.vigilServiceDto?.vigilRoom || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.vigilRoom', val)}
        />
        <Input
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'vigilServiceDto.externalNumber')}
          id="vigilServiceDto.externalNumber"
          name="vigilServiceDto.externalNumber"
          placeholder={t('service.vigil.externalNumber')}
          type="text"
          value={values.vigilServiceDto?.externalNumber || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.externalNumber', val)}
        />
        <Datepicker
          key="entryDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'vigilServiceDto.entryDate')}
          minDate={
            record?.deathData?.deathDate && new Date(record.deathData.deathDate)
          }
          name="entryDate"
          placeholder={t('common.dateIn')}
          selected={values.vigilServiceDto?.entryDate}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.entryDate', val)}
        />
        <Datepicker
          key="entryTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'vigilServiceDto.entryTime')}
          name="entryTime"
          placeholder={t('common.hourIn')}
          selected={values.vigilServiceDto?.entryTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.entryTime', val)}
        />
        <Datepicker
          key="exitDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'vigilServiceDto.exitDate')}
          name="exitDate"
          placeholder={t('common.dateOut')}
          selected={values.vigilServiceDto?.exitDate}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.exitDate', val)}
        />
        <Datepicker
          key="exitTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'vigilServiceDto.exitTime')}
          name="exitTime"
          placeholder={t('common.hourOut')}
          selected={values.vigilServiceDto?.exitTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.exitTime', val)}
        />
        <Textarea
          key="comment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'vigilServiceDto.comment')}
          id="comment"
          name="comment"
          placeholder={t('common.observations')}
          value={values.vigilServiceDto?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('vigilServiceDto.comment', val)}
        />
      </fieldset>
    </div>
  );
};

export default VigilForm;
