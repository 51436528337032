import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { IArticle } from 'modules/budget/models/Article/types';
import { BudgetSumarize } from 'modules/budget/models/BudgetSumarize';
import { Code } from 'modules/budget/models/Code';
import { InvoiceArticle } from 'modules/budget/models/InvoiceArticle/types';

import { Budget } from '../../models/Budget';
import { BudgetQuestionCollection } from '../budgetReducer/types';

import {
  BudgetAction,
  BudgetActionTypes, AnswerQuestionResponse, SetArticleClientPayload, SetArticleProviderPayload,
  FetchQ10SimulationPayload,
  SetArticleClientBulkPayload,
  SetArticlePricePayload,
  SetArticleAmountPayload,
} from './types';

export const FetchQ10Simulation = ({ articleId }: FetchQ10SimulationPayload): BudgetAction => ({
  payload: { articleId },
  type: BudgetActionTypes.FETCH_Q10_SIMULATION,
});

export const SetQ10Simulation = (
  { budget, articleId }: {budget: Budget, articleId: string},
): BudgetAction => ({
  payload: {
    articleId,
    budget,
  },
  type: BudgetActionTypes.SET_Q10_SIMULATION,
});

export const ClearQ10Simulation = ({ articleId }: { articleId: string}):BudgetAction => ({
  payload: { articleId },
  type: BudgetActionTypes.CLEAR_Q10_SIMULATION,
});

export const ClearArticlesList = (): BudgetAction => ({
  type: BudgetActionTypes.CLEAR_ARTICLES,
});

export const ClearQuestion = (
  { questionCode }: {questionCode: BudgetQuestionCodes},
): BudgetAction => ({
  payload: { questionCode },
  type: BudgetActionTypes.CLEAR_QUESTION,
});

export const SetCurrentQuestion = (
  { questionCode }: {questionCode: BudgetQuestionCodes},
) : BudgetAction => ({
  payload: { questionCode },
  type: BudgetActionTypes.SET_CURRENT_QUESTION,
});

export const AddDiscardedArticle = ({ article }: {article: IArticle}): BudgetAction => ({
  payload: { article },
  type: BudgetActionTypes.ADD_DISCARDED_ARTICLE,
});

export const RemoveDiscardedArticle = ({ article }: {article: IArticle}): BudgetAction => ({
  payload: { article },
  type: BudgetActionTypes.REMOVE_DISCARDED_ARTICLE,
});

export const ResetWizard = (): BudgetAction => ({
  type: BudgetActionTypes.RESET,
});

export const AnswerQuestion = ({
  question, response, gotoSummary = false,
}: {
  question: BudgetQuestionCodes,
  response?: AnswerQuestionResponse,
  gotoSummary?: boolean
}): BudgetAction => ({
  payload: {
    gotoSummary,
    question,
    response,
  },
  type: BudgetActionTypes.ANSWER_QUESTION,
});

export const SetArticleCollecitonPrices = (
  { articles, overwrite }: {articles: IArticle[], overwrite: boolean},
): BudgetAction => ({
  payload: {
    articles,
    overwrite,
  },
  type: BudgetActionTypes.SET_ARTICLE_COLLECTION_PRICE,
});

export const SetArticlePrice = (
  { articleId, articleType, price }: SetArticlePricePayload,
): BudgetAction => ({
  payload: {
    articleId,
    articleType,
    price,
  },
  type: BudgetActionTypes.SET_ARTICLE_PRICE,
});

export const SetArticleAmount = (
  { articleId, articleType, amount }: SetArticleAmountPayload,
): BudgetAction => ({
  payload: {
    amount,
    articleId,
    articleType,
  },
  type: BudgetActionTypes.SET_ARTICLE_AMOUNT,
});

export const GetInvoiceArticles = ({ idBudget }: {idBudget: number}): BudgetAction => ({
  payload: { idBudget },
  type: BudgetActionTypes.GET_INVOICE_ARTICLES,
});

export const SetInvoiceArticles = ({ articles }: {articles: InvoiceArticle[]}): BudgetAction => ({
  payload: { articles },
  type: BudgetActionTypes.SET_INVOICE_ARTICLES,
});

export const SetInvoiceClients = ({ clients }: {clients: Code[]}): BudgetAction => ({
  payload: { clients },
  type: BudgetActionTypes.SET_INVOICE_CLIENTS,
});

export const UpdateArticleProvider = (
  { articleId, providerId }: {articleId: number, providerId: string},
): BudgetAction => ({
  payload: {
    articleId,
    providerId,
  },
  type: BudgetActionTypes.UPDATE_ARTICLE_PROVIDER,
});

export const UpdateArticleClient = (
  { articleId, clientId }: {articleId: number, clientId: string},
): BudgetAction => ({
  payload: {
    articleId,
    clientId,
  },
  type: BudgetActionTypes.UPDATE_ARTICLE_CLIENT,
});

export const SetArticleProvider = (
  { article, provider, idBudget }: SetArticleProviderPayload,
): BudgetAction => ({
  payload: {
    article,
    idBudget,
    provider,
  },
  type: BudgetActionTypes.SET_ARTICLE_PROVIDER,
});

export const SetArticleClient = (
  { article, client, idBudget }: SetArticleClientPayload,
): BudgetAction => ({
  payload: {
    article,
    client,
    idBudget,
  },
  type: BudgetActionTypes.SET_ARTICLE_CLIENT,
});

export const SetArticleClientBulk = (
  { articles, client, idBudget }: SetArticleClientBulkPayload,
): BudgetAction => ({
  payload: {
    articles,
    client,
    idBudget,
  },
  type: BudgetActionTypes.SET_ARTICLE_CLIENT_BULK,
});
export const setInvoiceArticlesLoading = ({ loading } : {loading: boolean}): BudgetAction => ({
  payload: {
    loading,
  },
  type: BudgetActionTypes.SET_INVOICE_ARTICLES_LOADING,
});

export const SetBudget = (budget: Budget): BudgetAction => ({
  payload: {
    budget,
  },
  type: BudgetActionTypes.SET_BUDGET,
});

export const GoBack = (): BudgetAction => ({
  type: BudgetActionTypes.GO_BACK,
});

export const GoNextQuestion = (
  {
    currentQuestion,
    questionCollection,
  }: {currentQuestion: BudgetQuestionCodes, questionCollection: BudgetQuestionCollection},
): BudgetAction => ({
  payload: {
    currentQuestion,
    questionCollection,
  },
  type: BudgetActionTypes.GO_NEXT,
});

export const GotoSummary = ():BudgetAction => ({
  type: BudgetActionTypes.GO_SUMMARY,
});

export const LoadBudget = ({ id }: {id: number}): BudgetAction => ({
  payload: { id },
  type: BudgetActionTypes.LOAD_BUDGET,
});

export const SetBudgetLoading = (loading: boolean): BudgetAction => ({
  payload: loading,
  type: BudgetActionTypes.SET_BUDGET_LOADING,
});

export const setSummarizeLoading = (loading: boolean): BudgetAction => ({
  payload: loading,
  type: BudgetActionTypes.SET_SUMMARIZE_LOADING,
});

export const setSummarize = (summarize: BudgetSumarize): BudgetAction => ({
  payload: { summarize },
  type: BudgetActionTypes.SET_SUMMARIZE,
});

export const fetchSummarize = (budgetId: number): BudgetAction => ({
  payload: { budgetId },
  type: BudgetActionTypes.FETCH_SUMMARIZE,
});

export const clearSummarize = (): BudgetAction => ({
  type: BudgetActionTypes.CLEAR_SUMMARIZE,
});

export const saveBudget = ({
  callBack,
}: {
  callBack: () => void
}): BudgetAction => ({
  payload: {
    callBack,
  },
  type: BudgetActionTypes.SAVE_BUDGET,
});
