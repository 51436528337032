const error = {
  500: 'No se puede conectar con el servidor en estos momentos',
  concurrency:
    'Les dades ya han sigut modificades per un altre usuari, es necessari recarregar la pàgina.',
  deceased: {
    exist: 'Ja existeix un expedient per a un mort amb aquest document identificatiu',
  },
  emailFormat: 'Ha de ser un correu electrònic vàlid',
  'error.graph.facebook.generic': 'No s\'ha pogut enviar el missatge, contacteu amb l\'administrador.',
  'error.graph.facebook.token': 'El token per a l\'enviament de missatges ha caducat, contacteu amb l\'administrador.',
  fallbackError: {
    dashboard: 'escriptori',
    error: 'Error',
    go: 'Anar a',
    stacktrace: 'Més informació',
    title: 'ERROR',
  },
  forbidden: 'Reviseu permisos de l\'usuari',
  forgot:
    'S\'ha produït un error. Si us plau, assegureu que el nom d\'usuari és correcte i torneu a provar',
  ia: {
    backSideRequired: 'No s\'ha detectat cap cara posterior',
    frontSideRequired: 'No s\'ha detectat cap cara davantera',
    maxFrontOrBackSidesExceded: 'No hi ha d\'haver més d\'un front o back',
    pdfrequired: 'L\'arxiu ha de ser un PDF',
  },
  incineration: {
    authorization: 'No es pot procedir amb la incineració degut a que NO ES DISPOSA DE TOTA LA DOCUMENTACIÓ PER A INCINERAR',
  },
  invalidToken:
    'El token és invalid, si us plau, demani de nou el canvi de contrasenya.',
  login: {
    generic: 'Alguna cosa ha anat malament',
    request: 'Error de connexió',
    userPass: 'Usuari o contrasenya incorrecte',
  },
  minLength: 'La longitud mínima és',
  passwordMatch: 'Les contrasenyes han de coincidir',
  required: 'Obligatori',
  unprocessableEntity: 'No s\'ha pogut processar la sol·licitud',
  'whatsapp.number.not.valid': 'Número no vàlid per a enviament de Whatsapp',
};

export default error;
