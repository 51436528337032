import classNames from 'classnames';
import React, {
  FC, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'components/base/Row';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { BudgetWizardButtons } from '../commons/BudgetWizardButtons';
import { QHeader } from '../commons/QHeader';

import { BudgetSummaryActions } from './budgetSummaryActions';
import { BudgetSummaryHeader } from './budgetSummaryHeader';
import { BudgetSummaryLine } from './budgetSummaryLine';
import { BudgetSummaryTotal } from './budgetSummaryTotal';
import './index.scss';
import { BudgetSummaryComponent, BudgetSummaryLayout } from './types';
import { useBudgetSumarize } from './useBudgetSumarize';

export const BudgetSummary: FC<BudgetSummaryComponent> = (
  {
    budgetId,
    layout = BudgetSummaryLayout.MAIN,
    showGotoTracking,
  },
) => {
  const { t } = useTranslation();
  const {
    clearArticles,
    summarize,
  } = useBudget();
  const {
    clearSummarize,
  } = useBudgetSumarize();

  useEffect(
    () => () => {
      clearSummarize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearArticles();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isMainLayout = layout === BudgetSummaryLayout.MAIN;

  return (
    <div className="f-budget-summary">
      <BudgetWizardButtons
        budgetId={budgetId}
        companyName={summarize?.companyName}
        showCart={false}
        showGotoTracking={showGotoTracking}
        type={summarize?.type}
      />
      {isMainLayout
        && (
        <React.Fragment>
          <QHeader caption={t('budget.wizard.summary.title')} />
          <BudgetSummaryActions budgetId={budgetId} />
        </React.Fragment>
        )}
      {summarize?.articles.length !== 0
        && (
        <table className="f-budget-summary-detail">
          <BudgetSummaryHeader showActions={isMainLayout} />
          <tbody>
            {summarize?.articles
              .map(
                (article, index) => (
                  <BudgetSummaryLine
                    key={article.code}
                    article={article}
                    budgetId={budgetId}
                    className={classNames({ 'budget-summary-line-odd': !(index % 2) })}
                    showActions={isMainLayout}
                  />
                ),
              )}
            <BudgetSummaryTotal
              net={summarize?.net || 0}
              total={summarize?.total || 0}
              vat={summarize?.vat || 0}
            />
          </tbody>
        </table>
        )}
      {summarize?.articles.length === 0
        && (
        <Row justifyContent="space-around">
          <div>No articles</div>
        </Row>
        )}
    </div>
  );
};
