import { useContext, createContext } from 'react';

import { FullUser } from 'models/User';

export type RoleInfoType = {
  isOperator: boolean;
  isWorkshopAdmin: boolean;
  isAdmin: boolean;
};

export type StateType = {
  landingPage?: string;
  loading: boolean;
  roleInfo: RoleInfoType;
  user?: FullUser | undefined;
};

export type AuthContextType = StateType & {
  exit: () => void;
  setLoading: (loading: boolean) => void;
  setUser: (user: FullUser, cbFunction: () => void) => void;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType,
);

export const useProvidedAuth: () => AuthContextType = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('AuthContext must exist to call useProvidedAuth');
  }
  return context;
};
