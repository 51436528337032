import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDate, formatDateAndHour } from 'utils/dateManager';

import useCrematory from 'hooks/useCrematory';
import useDeposit from 'hooks/useDeposit';
import { CremationOrder } from 'models/OrderInfo';
import { RootState } from 'store';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const CremationInfo: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { normalizedCrematories } = useCrematory(
    (order.info as CremationOrder)?.location?.city,
  );
  const { owners } = useSelector((state: RootState) => state.normalized);
  const { normalizedDeposits } = useDeposit(
    (order.info as CremationOrder)?.deposit?.address?.city,
  );
  const { t } = useTranslation();

  useEffect(() => {
    const info: CremationOrder = order.info as CremationOrder;

    const newDetails: DetailType[] = [];
    if (info?.vigil) {
      newDetails.push({
        text: t('service.vigil.address'),
        value: info?.vigil?.address,
      });
    }
    if (info?.ceremony) {
      newDetails.push({
        text: t('service.workorders.CEREMONIA'),
        value: info?.ceremony?.address,
      });
    }
    if (
      (info?.deposit || info?.cremationDeposit)
      && !info?.vigil
      && !info?.ceremony
    ) {
      newDetails.push({
        text: t('service.deposit.deposit'),
        value:
          (info?.deposit?.deposit
            && normalizedDeposits[info?.deposit?.deposit]?.description)
          || info?.cremationDeposit,
      });
    }
    newDetails.push(
      {
        text: t('service.cremation.crematorium'),
        value:
          info?.crematorium
          && normalizedCrematories?.[info.crematorium]?.description,
      },
      {
        text: t('service.cremation.urnModel'),
        value: info?.urnModels?.length
          ? info.urnModels.reduce(
            (prev, value) => `${prev ? `${prev}, ` : ''}${value}`,
            '',
          )
          : undefined,
      },
      {
        text: t('service.cremation.owner'),
        value: info?.owner && owners?.[info?.owner]?.description,
      },
      {
        text: t('service.cremation.reliquary'),
        value: info?.reliquaries?.length
          ? info.reliquaries.reduce(
            (prev, value) => `${prev ? `${prev}, ` : ''}${value}`,
            '',
          )
          : undefined,
      },
      {
        text: t('service.cremation.entryDate'),
        value: info?.entryDate ? formatDate(info?.entryDate) : null,
      },
      {
        text: t('service.cremation.entryTime'),
        value: info?.entryTime,
      },
      {
        text: t('service.cremation.familyPresence'),
        value: info?.familyPresence,
      },
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
      {
        text: t('article.flowers.totalFlowers'),
        value: info?.totalFlowers,
      },
      {
        text: t('service.cremation.documentationOK'),
        value: info?.cremationDocumentation,
      },
      {
        text: t('service.cremation.documentationUser'),
        value: info?.cremationDocumentationUser,
      },
      {
        text: t('service.cremation.documentationTime'),
        value: info?.cremationDocumentationDatetime
          ? formatDateAndHour(info?.cremationDocumentationDatetime)
          : null,
      },
    );
    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, normalizedCrematories, normalizedDeposits]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default CremationInfo;
