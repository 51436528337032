export const vigilPredefinedSelections = [

  {
    city: '08360',
    country: '011',
    description: 'Tanatori de Mataró',
    location: '99',
    province: '08',
  },
  {
    city: '08285',
    country: '011',
    description: 'Tanatori de Granollers',
    location: '99',
    province: '08',
  },
  {
    city: '08102',
    country: '011',
    description: 'Tanatori de Calella',
    location: '99',
    province: '08',
  },
  {
    city: '08486',
    country: '011',
    description: 'Tanatori de Pineda',
    location: '99',
    province: '08',
  },
  {
    city: '08849',
    country: '011',
    description: 'Tanatori de Tordera',
    location: '99',
    province: '08',
  },
];
