import { Middleware } from 'redux';

import { showErrorToast } from 'utils/toasts';

import { ConflictError } from 'config/apiErrors/ConflictError';
import { i18n } from 'i18n';
import { Budget } from 'modules/budget/models/Budget';

import { budgetController } from '../../api';
import { SetBudget, SetBudgetLoading } from '../budgetActions';
import { BudgetActionTypes } from '../budgetActions/types';
import { BudgetState } from '../budgetReducer/types';

export const SaveBudget: Middleware = (storeApi) => (next) => async (action) => {
  if (
    action.type === BudgetActionTypes.SAVE_BUDGET) {
    const result = next(action);

    const callBack = action.payload?.callBack;
    const storedBudget = storeApi.getState().budget as BudgetState;

    const budget = new Budget({
      automaticArticles: storedBudget.automaticArticles,
      collected: storedBudget.collected,
      createdBy: storedBudget.createdBy,
      createdDate: storedBudget.createdDate,
      discardedArticles: storedBudget.discardedArticles,
      id: storedBudget.id,
      idRecord: storedBudget.idRecord,
      idVersion: storedBudget.idVersion,
      questions: storedBudget.questions,
      status: storedBudget.status,
    });

    const save = async () => budgetController.SaveBudget(budget);

    try {
      storeApi.dispatch(SetBudgetLoading(true));

      const response = await save();

      storeApi.dispatch(SetBudget(response));

      if (callBack) {
        callBack();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error instanceof ConflictError) {
        showErrorToast(i18n.t('error.concurrency'));
      } else {
        showErrorToast(i18n.t(error.message));
      }
    } finally {
      storeApi.dispatch(SetBudgetLoading(false));
    }

    return result;
  }

  return next(action);
};
