import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { FlowersTable } from './table/table';

export type FlowersProps = Record<string, unknown>

type FlowersPageParamns = {
  erpid: string;
};

export const Flowers: FC<FlowersProps> = () => {
  const { erpid } = useParams<FlowersPageParamns>();

  return (<FlowersTable erpId={Number(erpid)} />);
};
