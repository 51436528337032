export type BudgetSummaryComponent = {
  budgetId: number;
  layout?: BudgetSummaryLayout;
  showGotoTracking: boolean;
};

export enum BudgetSummaryLayout {
  MAIN = 'MAIN',
  SIGN = 'SIGN',
}
