import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFuneraryOrigin } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

type FuneraryOriginsHook = {
  funeraryOrigins: IMasterDataDto[]
  getFuneraryOrigin: (code: string) => IMasterDataDto | undefined
  fetchFuneraryOrigins: () => void
}

export const useFuneraryOrigins = (): FuneraryOriginsHook => {
  const dispatch = useDispatch();
  const { funeraryOrigins } = useSelector((state: RootState) => state.masterData);

  const getOrigins = useCallback(
    (code: string) => (funeraryOrigins
      ? funeraryOrigins.find((i: IMasterDataDto) => i.code === code)
      : undefined),
    [funeraryOrigins],
  );

  const fetchFuneraryOrigins = () => {
    dispatch(getFuneraryOrigin());
  };

  return {
    fetchFuneraryOrigins,
    funeraryOrigins,
    getFuneraryOrigin: getOrigins,
  };
};
