import { cloneDeep, omit } from 'lodash';

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeAuditFields = (data: any): any => {
  if (data) {
    if (Array.isArray(data)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return data.map((dataItem) => removeAuditFields(dataItem));
    } if (typeof data === 'object') {
      const dataWithoutOmitted = cloneDeep(omit(
        data,
        [
          'createdBy',
          'createdDate',
          'id',
          'idVersion',
          'negotiators',
          'updatedBy',
          'updatedDate',
          'fullAddress',
          'streetNumber',
          'fullName',
          'whatsappDate',
          'whatsappUser',
          'whatsappNumberLastSend',
        ],
      ));
      return Object.keys(dataWithoutOmitted).reduce((prev, curr) => ({
        ...prev,
        [curr]: removeAuditFields(dataWithoutOmitted[curr]),
      }), {});
    }
    return data;
  }
  return data;
};

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const clearAudit = (data: any) => {
  const t = removeAuditFields(data);
  return t;
};
