import { CoffinSizes } from 'hooks/useCoffins/types';
import { BlocTypes } from 'modules/budget/components/budgetConfig/budgetForm/BlocTypes';

import { BudgetQuestionCodes } from '../../data/budgetQuestionCodes';
import { ArticleCollectionTypes, ArticleType } from '../Article/types';
import { Audit } from '../Audit';
import { BudgetConfigurationLocation } from '../BudgetConfigurationLocation';
import { Code } from '../Code';

import { IBudgetConfiguration } from './types';

export class BudgetConfiguration extends Audit implements IBudgetConfiguration {
  constructor(params: IBudgetConfiguration) {
    super(params);
    this.article = params.article;
    this.excludedArticles = params.excludedArticles.map((codeItem) => new Code(codeItem));
    this.excludedClients = params.excludedClients.map((codeItem) => new Code(codeItem));
    this.excludedLocations = params.excludedLocations
      .map((location) => new BudgetConfigurationLocation(location));
    this.includedArticles = params.includedArticles.map((codeItem) => new Code(codeItem));
    this.includedClients = params.includedClients.map((codeItem) => new Code(codeItem));
    this.includedLocations = params.includedLocations
      .map((location) => new BudgetConfigurationLocation(location));
    this.mandatoryResponses = params.mandatoryResponses.map((codeItem) => new Code(codeItem));
    this.optionalResponses = params.optionalResponses.map((codeItem) => new Code(codeItem));
    this.questionCode = params.questionCode;
    this.type = params.type;
    this.subType = params.subType;
    this.coffinSize = params.coffinSize;
    this.bloc = params.bloc;
    this.image = params.image;
    this.order = params.order;
    this.active = params.active;
  }

  active: boolean;

  article: string | null;

  bloc: BlocTypes | null;

  coffinSize?: CoffinSizes | null;

  excludedArticles: Code[];

  excludedClients: Code[];

  excludedLocations: BudgetConfigurationLocation[];

  image?: string | null;

  includedArticles: Code[];

  includedClients: Code[];

  includedLocations: BudgetConfigurationLocation[];

  mandatoryResponses: Code[];

  optionalResponses: Code[];

  order: number | null;

  questionCode?: BudgetQuestionCodes | null;

  subType: ArticleCollectionTypes;

  type: ArticleType | null;
}
