import { BudgetsTrackingFilterStatus } from '../../components/tracking/table/types';
import { Audit } from '../../models/Audit';

import { IBudgetsTrackingItem } from './types';

export class BudgetsTrackingItem extends Audit implements IBudgetsTrackingItem {
  constructor(data: IBudgetsTrackingItem) {
    super(data);

    this.deceasedFirstSurname = data.deceasedFirstSurname;
    this.deceasedName = data.deceasedName;
    this.deceasedSecondSurname = data.deceasedSecondSurname;
    this.erpId = data.erpId;
    this.id = data.id;
    this.status = data.status;
    this.totalWorkOrders = data.totalWorkOrders;
    this.canAccept = data.canAccept;
    this.processorName = data.processorName;
    this.processorFirstSurname = data.processorFirstSurname;
    this.processorSecondSurname = data.processorSecondSurname;
    this.canBackward = data.canBackward;
  }

  canBackward: boolean;

  processorName: string;

  processorFirstSurname: string;

  processorSecondSurname: string;

  deceasedFirstSurname: string;

  deceasedName: string;

  deceasedSecondSurname: string;

  erpId: number;

  status: BudgetsTrackingFilterStatus;

  totalWorkOrders: number;

  id: number;

  canAccept: boolean;
}
