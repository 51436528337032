import { AxiosError } from 'axios';

import { FunusCustomError } from 'config/apiErrors/FunusCustomError';

export class RequiredFrontSideIAError extends FunusCustomError {
  constructor(error: AxiosError) {
    super(error);
    this.message = 'error.ia.frontSideRequired';
    this.name = 'RequiredPDFTypeIAError';
  }
}
