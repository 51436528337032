import { useEffect, useState } from 'react';

import config from 'config';

import { DashboardEnquiry } from 'models/Enquiry';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useEnquiriesDashboard = () => {
  const [introduced, setIntroduced] = useState<DashboardEnquiry[]>([
  ]);
  const [pending, setPending] = useState<DashboardEnquiry[]>([]);
  const [completed, setCompleted] = useState<DashboardEnquiry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadEnquiries = () => {
    setIsLoading(true);
    config.apiFunus.enquiries
      .getEnquiriesDashboard()
      .then((response) => {
        setIsLoading(false);
        setIntroduced(response.data.introduced);
        setPending(response.data.pending);
        setCompleted(response.data.completed);
        return response;
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadEnquiries();
  }, []);

  return {
    completed,
    introduced,
    isLoading,
    loadEnquiries,
    pending,
  };
};
