import classNames from 'classnames';
import React, {
  cloneElement,
  FC,
  ForwardedRef,
  TextareaHTMLAttributes,
  ReactElement,
  useState,
  ChangeEvent,
  createRef,
  useEffect,
} from 'react';

import Button from 'components/base/Button';

import { Pick } from 'typings/helper';

import './index.scss';

export type TextareaProps = Pick<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  Exclude<keyof TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>
> & {
  clearable?: boolean | undefined;
  error?: string | boolean | undefined;
  innerRef?: ForwardedRef<HTMLDivElement> | undefined;
  leftAddon?: ReactElement | undefined;
  lineHeight?: number | undefined;
  maxRows?: number | undefined;
  minRows?: number | undefined;
  name: string;
  onChange?(value: string | number | boolean | null): void | undefined;
  rightAddon?: ReactElement | undefined;
};

const Textarea: FC<TextareaProps> = ({
  className,
  clearable,
  disabled,
  error,
  id,
  innerRef,
  leftAddon,
  lineHeight = 24,
  maxLength = 1000,
  maxRows = 3,
  minRows = 1,
  name,
  onBlur,
  onChange,
  rightAddon,
  placeholder,
  value,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [rows, setRows] = useState<number>(minRows);
  const textareaRef = createRef<HTMLTextAreaElement>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateRows = (scrollHeight = lineHeight): void => {
    if (textareaRef?.current) {
      const result = {
        rows:
          scrollHeight && lineHeight
            ? Math.floor(scrollHeight / lineHeight)
            : 1,
        scrollTop: lineHeight,
      };

      if (result.rows >= maxRows) {
        result.rows = maxRows;
        result.scrollTop = scrollHeight;
      }

      textareaRef.current.rows = result.rows;
      if (result.scrollTop) {
        textareaRef.current.scrollTop = result.scrollTop;
      }

      setRows(result.rows < maxRows ? result.rows : maxRows);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
    calculateRows(event.target.scrollHeight);
  };

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef.current.rows = minRows;

      calculateRows(textareaRef.current.scrollHeight);
    }
  }, [calculateRows, minRows, textareaRef]);

  const clickClear = () => {
    if (onChange) {
      onChange('');
    }
  };

  return (
    <div
      ref={innerRef}
      className={classNames(
        'funus-textarea',
        {
          disabled,
          error: !!error,
          filled: !!value,
          focused,
          hovered,
          prefixed: !!leftAddon,
        },
        className,
      )}
    >
      {placeholder && <label htmlFor={name}>{placeholder}</label>}
      <div>
        <div>
          {leftAddon
            && cloneElement(leftAddon, {
              className: classNames(
                'textarea-prefix',
                { disabled },
                leftAddon.props.className,
              ),
              id: id ? `${id}-textarea-prefix` : undefined,
            })}
          <textarea
            {...rest}
            ref={textareaRef}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            rows={rows}
            value={value}
            onBlur={(e) => {
              if (!disabled) {
                setFocused(false);

                if (onBlur) {
                  onBlur(e);
                }
              }
            }}
            onChange={handleChange}
            onFocus={(e) => {
              setFocused(true);
              if (rest.onFocus) {
                rest.onFocus(e);
              }
            }}
            onMouseEnter={(e) => {
              setHovered(true);
              if (rest.onMouseEnter) {
                rest.onMouseEnter(e);
              }
            }}
            onMouseLeave={(e) => {
              setHovered(false);
              if (rest.onMouseLeave) {
                rest.onMouseLeave(e);
              }
            }}
          />
          {clearable && !!value && (
            <Button
              className="clear-button"
              color="transparent"
              tabIndex={-1}
              onClick={clickClear}
            />
          )}
          {rightAddon
            && cloneElement(rightAddon, {
              className: classNames(
                'textarea-suffix',
                { disabled },
                rightAddon.props.className,
              ),
              id: id ? `${id}-textarea-suffix` : undefined,
            })}
        </div>
        <div className="bottom-line" />
      </div>
      <span className={classNames({ hidden: !error })}>{error}</span>
    </div>
  );
};

export default Textarea;
