import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import { useCenters } from 'hooks/useCenters';
import { useCities } from 'hooks/useCities';
import { CollectionBaseWorkOrder } from 'models/Workorder';

import { CardDetails, displayHour, WorkshopCardHeaderDetail } from './CardDetails';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PickupDetails = ({ order }: { order: CollectionBaseWorkOrder }) => {
  const { t } = useTranslation();
  const { cities, getCityByNormalizedCode } = useCities();
  const { getCenter, centers } = useCenters();

  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);

  useEffect(() => {
    setDetails([{
      label: t('order.details.creationHour'),
      value: displayHour(`2020-01-01T${order.createdTime}:00.00`),
    },
    {
      label: t('common.city'),
      value: getCityByNormalizedCode(
        order.country,
        order.province,
        order.city,
      )?.description || displayNoValue,
    },
    {
      label: t('order.details.destinationCenter'),
      value: getCenter(order.destinationCenter)?.description || displayNoValue,
    }]);
  }, [
    order,
    t,
    cities,
    getCityByNormalizedCode,
    centers,
    getCenter,
  ]);

  return (<CardDetails details={details} />);
};
