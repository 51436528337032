import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { normalizeMasterData } from 'utils/normalizer';

import { getOven, getOvenByCityNCrematory } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { NormalizedType } from 'models/MasterService';
import { RootState } from 'store';

type OvenType = {
  ovens: IMasterDataDto[];
  cityCrematoryOvens: IMasterDataDto[];
  normalizedOvens: NormalizedType;
  getOven: (
    code: string | undefined) => IMasterDataDto | undefined;

};

const useOven = (crematoryCode?: string, cityCode?: string): OvenType => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  const [crematoryLoaded, setCrematoryLoaded] = useState<string>();
  const [normalizedOvens, setNormalizedOvens] = useState({});
  const [cityCrematoryOvens, setCityCrematoryOvens] = useState<IMasterDataDto[]>([]);
  const { ovens } = useSelector((state: RootState) => state.masterData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cityCode !== cityLoaded || crematoryCode !== crematoryLoaded) {
      setCrematoryLoaded(crematoryCode);
      setCityLoaded(cityCode);
      getOven(cityCode, crematoryCode)
        .then((res) => setCityCrematoryOvens(res.data))
        .catch(() => false);

      dispatch(getOvenByCityNCrematory(crematoryCode, cityCode));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode, crematoryCode]);

  const getOvenByCityCrematory = useCallback(
    (code) => cityCrematoryOvens.find((item: IMasterDataDto) => item.code === code),
    [cityCrematoryOvens],
  );

  useEffect(() => {
    setNormalizedOvens(normalizeMasterData(ovens));
  }, [ovens]);

  return {
    cityCrematoryOvens,
    getOven: getOvenByCityCrematory,
    normalizedOvens,
    ovens,
  };
};

export default useOven;
