import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { BudgetWizard } from 'modules/budget/components/budgetWizard';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useBudgets } from 'modules/budget/hooks/useBudgets';
import './index.scss';

import { LocationParams } from '../wizard/types';

export const WizardNew: FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const { state }: {state: LocationParams} = useLocation();
  const {
    id,
    currentQuestion,
    GoToQuestion,
  } = useBudget();
  const { GetBudgetInfoFromRecord } = useBudgets();

  useEffect(
    () => {
      if (id) {
        history.push(
          {
            pathname: `/budget/wizard/${id}/${currentQuestion}`,
            state: {
              gotoSummary: state?.gotoSummary,
            },
          },
        );
      }
    },
    [currentQuestion, history, id, state?.gotoSummary],
  );

  useEffect(
    () => {
      if (state?.recordId) {
        GoToQuestion(BudgetQuestionCodes.Q1);
        GetBudgetInfoFromRecord(Number(state?.recordId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state?.recordId],
  );

  return (
    <div className="f-budget-wizard-new">
      <div className="f-budget-wizard-wrapper">
        <BudgetWizard />
      </div>
    </div>
  );
};
