import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/base/Select';

import { MasterDataDto } from 'config/apiFunus/generated/data-contracts';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { ArticleBulkClientSelectorComponent } from './types';

export const ArticleBulkClientSelector: FC<ArticleBulkClientSelectorComponent> = (
  { clients, articles, idBudget },
) => {
  const { t } = useTranslation();
  const { SetInvoiceArticleClientBulk } = useBudget();

  return (
    <Select<MasterDataDto>
      key="supplier"
      getLabel={({ description }) => description || ''}
      getValue={({ code }) => code || ''}
      name="supplier"
      options={clients}
      placeholder={t('budget.actions.allArticlesClient')}
      searchable
      onChange={(val) => {
        if (val?.code) {
          SetInvoiceArticleClientBulk(
            articles,
            val.code,
            idBudget,
          );
        }
      }}
    />
  );
};
