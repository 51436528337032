import { useCallback, useEffect, useState } from 'react';

import { normalizeMasterData } from 'utils/normalizer';

import { getCeremonyLocations } from 'actions/masterData';

import { CeremonyType } from 'models/MasterData';

type CeremonyLocationType = {
  ceremonyLocations: CeremonyType[];
  normalizedCeremonyLocations: {
    [key: string]: CeremonyType;
  };
  getCeremonyLocation: (
    code: string | undefined) => CeremonyType | undefined;
};

const useCeremonyLocation = (cityCode?: string): CeremonyLocationType => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [normalizedCeremonyLocations, setNormalizedCeremonyLocations] = useState<any>({});
  const [ceremonyLocations, setCeremonyLocations] = useState<CeremonyType[]>(
    [],
  );

  const getLocations = async () => {
    getCeremonyLocations(cityCode)
      .then((response) => {
        setCeremonyLocations(response.data as CeremonyType[]);
        return response.data;
      })
      .catch(() => {
        setCeremonyLocations([]);
      });
  };

  const getCeremonyLocation = useCallback(
    (code) => normalizedCeremonyLocations && normalizedCeremonyLocations[code],
    [normalizedCeremonyLocations],
  );

  useEffect(() => {
    if (cityCode !== cityLoaded) {
      setCityLoaded(cityCode);
      getLocations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  useEffect(() => {
    setNormalizedCeremonyLocations(normalizeMasterData(ceremonyLocations));
  }, [ceremonyLocations]);

  return {
    ceremonyLocations,
    getCeremonyLocation,
    normalizedCeremonyLocations,
  };
};

export default useCeremonyLocation;
