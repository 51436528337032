import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomIcon from 'components/base/CustomIcon';

import OrderType from 'models/OrderType';
import {
  BaseWorkOrderType,
  CeremonyBaseWorkOrder,
  CollectionBaseWorkOrder,
  CremationBaseWorkOrder,
  IntermentBaseWorkOrder,
  MovementBaseWorkOrder,
  NichePreparationBaseWorkOrder,
  PreparationBaseWorkOrder,
  ReceptionBaseWorkOrder,
  SanitaryPracticeBaseWorkOrder,
  TransferBaseWorkOrder,
} from 'models/Workorder';

import './index.scss';
import { CeremonyDetails } from './CeremonyDetails';
import { IncinerationDetails } from './IncinerationDetails';
import { InhumationDetails } from './InhumationDetails';
import { MovementDetails } from './MovementDetails';
import { NichePreparationDetails } from './NichePreparationDetails';
import { PickupDetails } from './PickupDetails';
import { PreparationDetails } from './PreparationDetails';
import { ReceptionDetails } from './ReceptionDetails';
import { SanitaryPracticeDetails } from './SanitaryPracticeDetails';
import { TransferDetails } from './TransferDetails';

type WorkshopCardHeaderProps = {
  order: BaseWorkOrderType;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WorkshopCardHeader = (
  {
    order,
  }: WorkshopCardHeaderProps,
) => {
  const { t } = useTranslation();

  return (
    <div className="workshop-card-header">
      <div className="workshop-card-header-top">
        <div className="workshop-card-header-top-name">{`${order.deceased?.firstSurname || ''} ${order.deceased?.secondSurname || ''}, ${order.deceased?.name || ''}`}</div>
        <div>{order.erpId}</div>
      </div>
      <div className="workshop-card-header-detail">
        <div className="workshop-card-header-detail-icon">
          <CustomIcon icon={order.type} />
          {t(`service.workorders.${order.type}`)}
        </div>

        {(order.type === OrderType.PICKUP)
        && (<PickupDetails order={(order as CollectionBaseWorkOrder)} />)}
        {(order.type === OrderType.SANITARY_PRACTICE)
        && (<SanitaryPracticeDetails order={(order as SanitaryPracticeBaseWorkOrder)} />)}
        {(order.type === OrderType.PREPARATION)
        && (<PreparationDetails order={(order as PreparationBaseWorkOrder)} />)}
        {(order.type === OrderType.CEREMONY)
        && (<CeremonyDetails order={(order as CeremonyBaseWorkOrder)} />)}
        {(order.type === OrderType.INCINERATION)
        && (<IncinerationDetails order={(order as CremationBaseWorkOrder)} />)}
        {(order.type === OrderType.TRANSFER)
        && (<TransferDetails order={(order as TransferBaseWorkOrder)} />)}
        {(order.type === OrderType.PREPARATION_NICHO)
        && (<NichePreparationDetails order={(order as NichePreparationBaseWorkOrder)} />)}
        {(order.type === OrderType.INHUMATION)
        && (<InhumationDetails order={(order as IntermentBaseWorkOrder)} />)}
        {(order.type === OrderType.RECEPTION)
        && (<ReceptionDetails order={(order as ReceptionBaseWorkOrder)} />)}
        {(order.type === OrderType.MOVEMENT)
        && (<MovementDetails order={(order as MovementBaseWorkOrder)} />)}
      </div>

    </div>
  );
};
