import { Nullable } from 'utils/types/Nullable/types';

export const mapBirthDate = (source: Nullable<string>): Nullable<Date> => {
  let birthDate: Nullable<Date> = null;
  try {
    if (source) {
      const [day, month, year] = source.split('-');

      birthDate = new Date(`${year}-${month}-${day}`);
      const isValidDate = !Number.isNaN(birthDate.valueOf());
      if (!isValidDate) {
        birthDate = null;
      }
    }
  } catch {
    birthDate = null;
  }

  return birthDate;
};
