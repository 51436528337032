import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InternalMovementsInfo } from '../../../models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const InternalMovements: FC<DefaultInfoProps> = ({
  expanded,
  loading,
  order,
}) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const info: InternalMovementsInfo = order.info as InternalMovementsInfo;

    const newDetails: DetailType[] = [
      {
        text: t('common.address'),
        value: info?.cremationService?.address,
      },
      {
        text: t('service.cremation.cremationDate'),
        value: info?.cremationService?.cremationDate,
      },
      {
        text: t('service.cremation.cremationTime'),
        value: info?.cremationService?.cremationTime,
      },
      {
        text: t('service.cremation.crematorium'),
        value: info?.cremationService?.crematorium,
      },
      {
        text: t('common.address'),
        value: info?.intermentService?.intermentAddress,
      },
      {
        text: t('service.interment.cemetery'),
        value: info?.intermentService?.cemetery,
      },
      {
        text: t('service.interment.section'),
        value: info?.intermentService?.nicheSection,
      },
      {
        text: t('service.interment.sepulture'),
        value: info?.intermentService?.niche,
      },
      {
        text: t('service.interment.number'),
        value: info?.intermentService?.nicheNumber,
      },
      {
        text: t('service.interment.floor'),
        value: info?.intermentService?.nicheTier,
      },
      {
        text: t('service.interment.intermentDate'),
        value: info?.intermentService?.intermentDate,
      },
      {
        text: t('service.interment.intermentTime'),
        value: info?.intermentService?.intermentTime,
      },
      {
        text: t('common.location'),
        value: info?.vigilService?.location,
      },
      {
        text: t('service.vigil.room'),
        value: info?.vigilService?.vigilRoom,
      },
      {
        text: t('common.exitDate'),
        value: info?.vigilService?.exitDate,
      },
      {
        text: t('common.exitTime'),
        value: info?.vigilService?.exitTime,
      },
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
    ];
    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default InternalMovements;
