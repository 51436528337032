import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { isBoolean, isBooleanAndFalse } from 'utils/helpers';

import { Column } from 'components/base/Column';

import { InsuranceSelector, mapStringToOnChangePayload } from 'modules/budget/components/articleSelectors/InsuranceSelector';
import { YesNoSelector } from 'modules/budget/components/commons/YesNoSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionLabels } from 'modules/budget/hooks/useQuestionLabels';
import { Article } from 'modules/budget/models/Article';
import { dismissServiceId } from 'modules/budget/store/data';

export interface Q4Response {
  articles: Article[],
  noInsurance?: boolean,
  autorespond: boolean;
}

interface Q4FormComponent {
  onChange: (value: Q4Response) => void;
  question: BudgetQuestionCodes;
}

export const Q4Form: FC<Q4FormComponent> = (
  { onChange, question },
) => {
  const { yes, no } = useQuestionLabels();

  const { GetQuestionValue } = useBudget();

  const [wantService, setWantService] = useState<boolean>();
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.id) {
        setWantService(true);
        setSelectedArticles([new Article({
          amount: 1,
          code: storedValue?.id,
          description: '',
        })]);
        onChange({
          articles: [new Article({
            amount: 1,
            code: storedValue?.id,
            description: '',
          })],
          autorespond: false,
          noInsurance: storedValue === dismissServiceId,
        });
      } else if (storedValue === dismissServiceId) {
        setWantService(false);
        onChange({
          articles: [],
          autorespond: false,
          noInsurance: storedValue === dismissServiceId,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedValue],
  );

  // useEffect(
  //   () => {
  //     onChange({
  //       articles: selectedArticles,
  //       autorespond: false,
  //       noInsurance: isBooleanAndFalse(wantService),
  //     });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [wantService, selectedArticles],
  // );

  return (
    <Formik
      initialValues={{
        articles: selectedArticles,
        autorespond: false,
        noInsurance: isBoolean(wantService) ? !wantService : undefined,
      }}
      validate={(values) => {
        onChange({
          articles: values.articles,
          autorespond: values.autorespond,
          noInsurance: values.noInsurance,
        });
        setWantService(isBoolean(values.noInsurance) ? !values.noInsurance : undefined);
        setSelectedArticles(values.articles);

        return {};
      }}
      enableReinitialize
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues, values,
      }: FormikProps<Q4Response>) => (
        <Column>
          <YesNoSelector
            noCaption={no(question)}
            value={isBoolean(values.noInsurance) ? !values.noInsurance : undefined}
            yesCaption={yes(question)}
            onChange={(v) => {
              setValues({
                articles: [],
                autorespond: isBooleanAndFalse(v),
                noInsurance: isBoolean(v) ? !v : undefined,
              });
            }}
          />
          <InsuranceSelector
            className={classNames({ 'f-budget-hidden': !isBooleanAndFalse(values.noInsurance) })}
            disabled={false}
            question={question}
            value={selectedArticles[0]
              ? mapStringToOnChangePayload(selectedArticles[0].code)
              : undefined}
            onChange={(value) => {
              setValues({
                articles: value?.code
                  ? [new Article({
                    amount: 1,
                    code: value.code,
                    description: '',
                  })]
                  : [],
                autorespond: Boolean(value?.autorespond),
                noInsurance: false,
              });
            }}
          />
        </Column>
      )}
    </Formik>
  );
};
