import { FormikProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/base/Select';

import { useVigilLocation } from 'hooks/useVigilLocation';
import { IMasterDataDto } from 'models/MasterData';
import { SaveRecord } from 'models/Record';
import { ChangeStatusFormProps } from 'models/Workorder';

type VigilProps = {
  disabled?: boolean;
  formikProps: FormikProps<ChangeStatusFormProps>;
  record?: SaveRecord;
};

const VigilData: FC<VigilProps> = ({
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const { t } = useTranslation();
  const { vigilLocations, normalizedVigilLocations } = useVigilLocation(
    values.info?.vigil?.address?.city,
  );

  return (
    <div>
      <fieldset>
        <legend>{t('service.VELATORIO')}</legend>
        <Select<IMasterDataDto>
          key="location"
          disabled={
            isSubmitting
            || disabled
            || !values.info?.vigil?.address?.city
            || values.info?.vigil?.vigilAtHome
          }
          error={getIn(errors, 'info.vigil.location')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="location"
          options={vigilLocations}
          placeholder={t('common.location')}
          value={
            values.info?.vigil?.location
              ? normalizedVigilLocations[values.info?.vigil?.location]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('info.vigil.location', val?.code)}
        />
      </fieldset>
    </div>
  );
};

export default VigilData;
