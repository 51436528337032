import { createContext } from 'react';

import { IdentifierTypes } from 'components/base/IdentifierSelector';

import { IaInfoFormLayout } from '../IAInfoForm/IaInfoFormLayout';

export const IAScanDocumentContext = createContext<{
  documentType: IdentifierTypes,
   infoFormlayout: IaInfoFormLayout }>(
     { documentType: IdentifierTypes.IDENTIFIER, infoFormlayout: 'client' },
   );
