import classNames from 'classnames';
import {
  Form, Formik, FormikProps, getIn,
} from 'formik';
import React, {
  FC, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Nullable } from 'utils/types/Nullable/types';

import { Cities } from 'components/base/Cities';
import { Countries } from 'components/base/Countries';
import Datepicker from 'components/base/Datepicker';
import { IdentifierTypes } from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import { Provinces } from 'components/base/Provinces';
import Select from 'components/base/Select';

import { useValidator } from 'hooks/useValidator';
import { IMasterDataDto } from 'models/MasterData';
import { useIAAddressInformation } from 'modules/ia/hooks/useIAAddressInformation';
import { useIABirthAddressInformation } from 'modules/ia/hooks/useIABirthAddressInformation';
import { RootState } from 'store';

import { DataCollectorForm } from '../../models/DataCollectorForm';
import { IAScanDocumentContext } from '../scan-document-modal/IAScanDocumentContext';
import { ScanQualityMarker } from '../scan-quality-marker';

import { IAInfoFormComponent } from './types';

import './index.scss';

export const IAInfoForm: FC<IAInfoFormComponent> = ({
  docInfo, docScore, onChange,
}) => {
  const {
    IADniSchema, IACIFSchema, IANIESchema, IAPassportSchema,
  } = useValidator();
  const { t } = useTranslation();
  const { documentType, infoFormlayout } = useContext(IAScanDocumentContext);

  const {
    setAddressCountryName,
    addressCountryCode: IAaddressCountryCode,
    addressProvinceCode: IAaddressProvinceCode,
    setAddressProvinceName,
    setAddressCityName,
    addressCityCode: IAaddressCityCode,
  } = useIAAddressInformation();

  const {
    setBirthAddressFirstField,
    setBirthAddressSecondField,
    birthAddressCityCode: IabirthAddressCityCode,
    birthAddressCountryCode: IAbirthAddressCountryCode,
    birthAddressProvinceCode: IabirthAddressProvinceCode,
  } = useIABirthAddressInformation();

  const { genders } = useSelector(
    (state: RootState) => state.masterData,
  );
  const { normalized } = useSelector((state: RootState) => state);

  const [addressCityCode, setAddressCityCode] = useState<Nullable<string>>(null);
  const [addressCountryCode, setAddressCountryCode] = useState<Nullable<string>>(null);
  const [addressProvinceCode, setAddressProvinceCode] = useState<Nullable<string>>(null);

  const [birthAddressCountryCode, setBirthAddressCountryCode] = useState<Nullable<string>>(null);
  const [birthAddressProvinceCode, setBirthAddressProvinceCode] = useState<Nullable<string>>(null);
  const [birthAddressCityCode, setBirthAddressCityCode] = useState<Nullable<string>>(null);

  useEffect(() => {
    if (IAaddressCountryCode) {
      setAddressCountryCode(IAaddressCountryCode);
    }
  }, [IAaddressCountryCode]);

  useEffect(() => {
    if (IAaddressProvinceCode) {
      setAddressProvinceCode(IAaddressProvinceCode);
    }
  }, [IAaddressProvinceCode]);

  useEffect(() => {
    if (IAaddressCityCode) {
      setAddressCityCode(IAaddressCityCode);
    }
  }, [IAaddressCityCode]);

  useEffect(() => {
    if (IabirthAddressCityCode) {
      setBirthAddressCityCode(IabirthAddressCityCode);
    }
  }, [IabirthAddressCityCode]);

  useEffect(() => {
    if (IabirthAddressProvinceCode) {
      setBirthAddressProvinceCode(IabirthAddressProvinceCode);
    }
  }, [IabirthAddressProvinceCode]);

  useEffect(() => {
    if (IAbirthAddressCountryCode) {
      setBirthAddressCountryCode(IAbirthAddressCountryCode);
    }
  }, [IAbirthAddressCountryCode]);

  useEffect(
    () => {
      setAddressCountryName(docInfo.address.country);
      setAddressProvinceName(docInfo.address.province);
      setAddressCityName(docInfo.address.city);

      setBirthAddressFirstField(docInfo.birthAddress.firstField);
      setBirthAddressSecondField(docInfo.birthAddress.secondField);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      docInfo.address.city,
      docInfo.address.country,
      docInfo.address.province,
      docInfo.birthAddress.firstField,
      docInfo.birthAddress.secondField,
    ],
  );

  const validationSchema = useMemo(() => {
    if (documentType === IdentifierTypes.CIF) {
      return IACIFSchema;
    } if (documentType === IdentifierTypes.NIE) {
      return IANIESchema;
    } if (documentType === IdentifierTypes.PASSPORT) {
      return IAPassportSchema;
    }
    return IADniSchema;
  }, [IACIFSchema, IADniSchema, IANIESchema, IAPassportSchema, documentType]);

  return (
    <Formik
      initialValues={{
        address: {
          city: addressCityCode,
          country: addressCountryCode,
          province: addressProvinceCode,
          street: docInfo.address.street,
        },
        birthAddress: {
          city: birthAddressCityCode,
          country: birthAddressCountryCode,
          province: birthAddressProvinceCode,
        },
        birthDate: docInfo.birthDate,
        firstParent: docInfo.firstParent,
        firstSurname: docInfo.firstSurname,
        genre: docInfo.genre,
        idCard: docInfo.idCard,
        name: docInfo.name,
        nationality: docInfo.nationality,
        secondParent: docInfo.secondParent,
        secondSurname: docInfo.secondSurname,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
      onSubmit={() => Promise.resolve()}
    >
      {({
        setFieldValue, values, errors, setValues, isValid,
      }: FormikProps<DataCollectorForm>) => {
        onChange({ isValid, values });

        return (
          <Form autoComplete="off" className="f-ia-info-form">
            <React.Fragment>
              <Input
                id="identifier"
                name="identifier"
                placeholder={t('common.identifierDocument')}
                type="text"
                value={values.idCard || ''}
                onBlur={() => false}
                onChange={(text) => setFieldValue('idCard', text?.toString().toUpperCase())}
              />
              {getIn(docScore, 'idCard') && <ScanQualityMarker value={getIn(docScore, 'idCard') * 100} />}
              {getIn(errors, 'idCard') && <span className={classNames('error-message ', { hidden: !getIn(errors, 'idCard') })}>{getIn(errors, 'idCard')}</span>}

              <div className="form-row">
                <div>
                  <Input
                    id="name"
                    name="name"
                    placeholder={t('common.name')}
                    type="text"
                    value={values.name || ''}
                    onBlur={() => false}
                    onChange={(text) => setFieldValue('name', text)}
                  />
                  {getIn(docScore, 'name') && <ScanQualityMarker value={getIn(docScore, 'name') * 100} />}
                </div>
                <div>
                  <Input
                    id="firstSurname"
                    name="firstSurname"
                    placeholder={t('common.firstSurname')}
                    type="text"
                    value={values.firstSurname || ''}
                    onBlur={() => false}
                    onChange={(text) => setFieldValue('firstSurname', text)}
                  />
                  {getIn(docScore, 'firstSurname') && <ScanQualityMarker value={getIn(docScore, 'firstSurname') * 100} />}
                </div>
                <div>
                  <Input
                    id="secondSurname"
                    name="secondSurname"
                    placeholder={t('common.secondSurname')}
                    type="text"
                    value={values.secondSurname || ''}
                    onBlur={() => false}
                    onChange={(text) => setFieldValue('secondSurname', text)}
                  />
                  {getIn(docScore, 'secondSurname') && <ScanQualityMarker value={getIn(docScore, 'secondSurname') * 100} />}
                </div>

              </div>
              {infoFormlayout === 'deceased' && (
              <div className="form-row">
                <div>
                  <Input
                    id="nationality"
                    name="nationality"
                    placeholder={t('common.nationality')}
                    type="text"
                    value={values.nationality || ''}
                    onBlur={() => false}
                    onChange={(text) => setFieldValue('nationality', text)}
                  />
                  {getIn(docScore, 'nationality') && <ScanQualityMarker value={getIn(docScore, 'nationality') * 100} />}
                </div>

                <div>
                  <Select<IMasterDataDto>
                    getLabel={({ description }) => description}
                    getValue={({ code }) => code}
                    name="genre"
                    options={genders}
                    placeholder={t('common.gender')}
                    value={
                      values.genre
                        ? normalized.genders[values.genre]
                        : undefined
                    }
                    searchable
                    onChange={(val) => setFieldValue('genre', val?.code)}
                  />
                  {getIn(docScore, 'genre') && <ScanQualityMarker value={getIn(docScore, 'genre') * 100} />}
                </div>
                <div>
                  <Datepicker
                    name="birthDate"
                    placeholder={t('common.birthday')}
                    selected={values.birthDate || undefined}
                    onChange={(text) => setFieldValue('birthDate', text)}
                  />
                  {getIn(docScore, 'birthDate') && <ScanQualityMarker value={getIn(docScore, 'birthDate') * 100} />}
                </div>
              </div>
              )}

              {infoFormlayout === 'deceased' && (
              <React.Fragment>
                <legend>{t('ia.parents')}</legend>
                <div className="form-row">
                  <div>
                    <Input
                      id="firstParent"
                      name="firstParent"
                      placeholder={t('record.deceasedData.fatherName')}
                      type="text"
                      value={values.firstParent || ''}
                      onBlur={() => false}
                      onChange={(text) => setFieldValue('firstParent', text)}
                    />
                    {getIn(docScore, 'firstParent') && <ScanQualityMarker value={getIn(docScore, 'firstParent') * 100} />}
                  </div>
                  <div>
                    <Input
                      id="secondParent"
                      name="secondParent"
                      placeholder={t('record.deceasedData.motherName')}
                      type="text"
                      value={values.secondParent || ''}
                      onBlur={() => false}
                      onChange={(text) => setFieldValue('secondParent', text)}
                    />
                    {getIn(docScore, 'secondParent') && <ScanQualityMarker value={getIn(docScore, 'secondParent') * 100} />}
                  </div>
                </div>
              </React.Fragment>
              )}

              {infoFormlayout === 'deceased' && (
              <React.Fragment>
                <legend>{t('ia.birthAddress')}</legend>
                <div className="form-row">
                  <div>
                    <Cities
                      countryCode={values.birthAddress.country || ''}
                      provinceCode={values.birthAddress.province || ''}
                      value={values.birthAddress.city || ''}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          birthAddress: {
                            ...values.address,
                            city: value.code,
                            country: value.countryCode,
                            province: value.provinceCode,
                          },
                        });
                      }}
                    />
                    {getIn(docScore, 'address.city') && <ScanQualityMarker value={getIn(docScore, 'address.city') * 100} />}
                  </div>
                  <div>
                    <Provinces
                      countryCode={values.birthAddress.country || ''}
                      value={values.birthAddress.province || ''}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          birthAddress: {
                            ...values.birthAddress,
                            country: value.countryCode,
                            province: value.code,
                          },
                        });
                      }}
                    />
                    {getIn(docScore, 'address.province') && <ScanQualityMarker value={getIn(docScore, 'address.province') * 100} />}
                  </div>
                  <div>
                    <Countries
                      value={values.birthAddress.country || ''}
                      onChange={(text) => setFieldValue('birthAddress.country', text)}
                    />
                    {getIn(docScore, 'address.country') && <ScanQualityMarker value={getIn(docScore, 'address.country') * 100} />}
                  </div>
                </div>
              </React.Fragment>
              )}

              <legend>{t('common.address')}</legend>
              <div className="form-row">
                <div>
                  <Cities
                    countryCode={values.address.country || ''}
                    provinceCode={values.address.province || ''}
                    value={values.address.city || ''}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          city: value.code,
                          country: value.countryCode,
                          province: value.provinceCode,
                        },
                      });
                    }}
                  />
                  {getIn(docScore, 'address.city') && <ScanQualityMarker value={getIn(docScore, 'address.city') * 100} />}
                </div>
                <div>
                  <Provinces
                    countryCode={values.address.country || ''}
                    value={values.address.province || ''}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          country: value.countryCode,
                          province: value.code,
                        },
                      });
                    }}
                  />
                  {getIn(docScore, 'address.province') && <ScanQualityMarker value={getIn(docScore, 'address.province') * 100} />}
                </div>
                <div>
                  <Countries
                    value={values.address.country || ''}
                    onChange={(text) => setFieldValue('address.country', text)}
                  />
                  {getIn(docScore, 'address.country') && <ScanQualityMarker value={getIn(docScore, 'address.country') * 100} />}
                </div>
              </div>
              <div className="form-row">
                <div>
                  <Input
                    id="address.street"
                    name="address.street"
                    placeholder={t('common.address')}
                    type="text"
                    value={values.address.street || ''}
                    onBlur={() => false}
                    onChange={(text) => setFieldValue('address.street', text)}
                  />
                  {getIn(docScore, 'address.street') && <ScanQualityMarker value={getIn(docScore, 'address.streetName') * 100} />}
                </div>
              </div>
            </React.Fragment>
          </Form>
        );
      }}
    </Formik>
  );
};
