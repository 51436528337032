import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDeathTypes } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

interface DeathTypesHook {
  deathTypes: IMasterDataDto[],
  getDeathType: (code: string | undefined) => IMasterDataDto
}

export const useDeathTypes = ():DeathTypesHook => {
  const dispatch = useDispatch();
  const { items, normalized } = useSelector(
    (state: RootState) => ({
      items: state.masterData.deathTypes,
      normalized: state.normalized.deathTypes,
    }),
  );

  const getDeathType = useCallback(
    (code: string | undefined) => (code && normalized[code]
      ? normalized[code]
      : undefined),
    [normalized],
  );

  useEffect(() => {
    dispatch(getDeathTypes());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    deathTypes: items || [],
    getDeathType,
  };
};
