import classNames from 'classnames';
import React, { FC } from 'react';

import { Column } from 'components/base/Column';

import './index.scss';

interface QContainerComponent {
  className?: string
}

export const QContainer: FC<QContainerComponent> = ({ children, className }) => {
  const containerStyle = {
    display: 'flex',
    height: '100%',
  };
  const columnStyle = { width: '100%' };

  return (
    <div className={classNames('q-container', className)} style={containerStyle}>
      <Column className="f-q-container" justifyContent="space-between" style={columnStyle}>{children}</Column>
    </div>
  );
};
