import { FormikProps } from 'formik';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import FilteredPage from 'components/base/FilteredPage';
import { NoData } from 'components/base/NoData';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import { TableColumn } from 'components/base/Table/types';

import {
  RecordsServicesProps,
  RecordServiceDto,
  IFilter,
  RecordInformationTypeFilter,
  RecordServiceDtoType,
} from 'config/apiTypes';
import { DeathType } from 'models/Record';

import { CeremonyCell } from './ceremonyCell';
import { CremationCell } from './cremationCell';
import { DateTimeInfo } from './DateTimeInfo';
import { IntermentCell } from './intermentCell';
import { VigilCell } from './vigilCell';

import './index.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InformationTable = () => {
  const { t } = useTranslation();

  const search = (params: IFilter<RecordsServicesProps>) => config.apiFunus.record.services(params);
  const serviceTypes: RecordInformationTypeFilter[] = [
    RecordInformationTypeFilter.APPOINTMENT,
    RecordInformationTypeFilter.COLLECTION,
    RecordInformationTypeFilter.CEREMONY,
    RecordInformationTypeFilter.CREMATION,
    RecordInformationTypeFilter.INTERMENT,
    RecordInformationTypeFilter.VIGIL,
  ];

  const informationSearchFields = useCallback((
    formikProps: FormikProps<RecordsServicesProps>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    types: any[],
  ): ReactElement[] => [
    <SideFilterField
      key="erpId"
      filterType="input"
      formikProps={formikProps}
      name="erpId"
      placeholder={`${t('record.number')}`}
    />,
    <SideFilterField
      key="deceasedName"
      filterType="input"
      formikProps={formikProps}
      name="deceasedName"
      placeholder={`${t('common.name')}`}
    />,
    <SideFilterField
      key="deceasedFirstSurname"
      filterType="input"
      formikProps={formikProps}
      name="deceasedFirstSurname"
      placeholder={`${t('common.firstSurname')}`}
    />,
    <SideFilterField
      key="deceasedSecondSurname"
      filterType="input"
      formikProps={formikProps}
      name="deceasedSecondSurname"
      placeholder={`${t('common.secondSurname')}`}
    />,
    <SideFilterField
      key="type"
      filterType="select"
      formikProps={formikProps}
      getLabel={(value) => t(`record.informationFilterType.${value}`)}
      getValue={(value) => value}
      name="type"
      options={types}
      placeholder={`${t('common.type')}`}
    />,
    <SideFilterField
      key="date"
      filterType="date"
      formikProps={formikProps}
      name="date"
      placeholder={`${t('common.date')}`}
    />,
  ], [t]);

  const columns = useMemo(
    (): TableColumn<RecordServiceDto>[] => [
      {
        accessor: 'erpId',
        Cell: ({ cell: { value: val } }) => `${val}`,
        Header: `${t('record.number')}`,
      },
      {
        accessor: 'person',
        Cell: ({ cell: { value: val } }) => `${val?.name || ''} ${val?.firstSurname || ''} ${val?.secondSurname || ''}`,
        Header: `${t('common.name')}`,
      },
      {
        accessor: 'appointmentDateTime',
        Cell: ({ cell: { value: val } }) => (val
          ? <DateTimeInfo date={val} time={val} />
          : <NoData />),
        className: 'text-align-center',
        Header: `${t('record.appointment')}`,
      },
      {
        accessor: 'collectionDateTime',
        Cell: ({ cell: { value: val } }) => (val
          ? <DateTimeInfo date={val} time={val} />
          : <NoData />),
        className: 'text-align-center',
        Header: `${t('record.pickupDate')}`,
      },
      {
        accessor: 'preparationStatus',
        Cell: ({ cell: { value: val } }) => (val ? t(`record.preparationStatusOptions.${val}`) : <NoData />),
        className: 'text-align-center',
        Header: `${t('record.preparationStatus')}`,
      },
      {
        accessor: 'vigil',
        Cell: ({ cell: { value: val } }) => (val
          ? <VigilCell value={val} />
          : <NoData />),
        className: 'text-align-center',
        Header: `${t('service.vigil.vigil')}`,
      },
      {
        accessor: 'ceremony',
        Cell: ({ cell: { value: val } }) => (val
          ? <CeremonyCell value={val} />
          : <NoData />),
        className: 'text-align-center',
        Header: `${t('service.ceremony.ceremony')}`,
      },
      {
        accessor: 'type',
        Cell: ({ row }) => {
          if (row.original.type === RecordServiceDtoType.CREMATION && row.original.cremation) {
            return (<CremationCell value={row.original.cremation} />);
          } if (row.original.type === RecordServiceDtoType.INTERMENT && row.original.interment) {
            return (<IntermentCell value={row.original.interment} />);
          }

          return (<NoData />);
        },
        className: 'text-align-center',
        Header: `${t('service.cremation.cremation')}/${t('service.interment.interment')}`,
      },
    ],
    [t],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowClassName = (index: any, row: RecordsServicesProps) => (row.deathType === DeathType.judicial ? 'is-judicial-death' : '');

  return (
    <FilteredPage<RecordsServicesProps, RecordServiceDto>
      apiCall={(params) => search(params)}
      columns={columns}
      fields={(props) => informationSearchFields(props, serviceTypes)}
      initialValues={{
        date: undefined,
        deceasedFirstSurname: undefined,
        deceasedName: undefined,
        deceasedSecondSurname: undefined,
        erpId: undefined,
        type: undefined,
      }}
      rowClassName={rowClassName}
      text={{
        search: t('record.search'),
        title: t('information.title'),
      }}
    />
  );
};
