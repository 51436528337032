import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useWindow } from 'hooks/useWindow';

import { ComercialPdf } from '../comercialPdf';
import './index.scss';

type PrintComercialModalComponent = {
  show: boolean;
  onHide: () => void;
  erpId: number | undefined;
}

export const PrintComercialModal: FC<PrintComercialModalComponent> = ({
  show, onHide, erpId,
}) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>();
  const onLoadSuccess = ({ objectUrl }: { objectUrl: string }) => {
    setUrl(objectUrl);
  };
  const { openInNewTab } = useWindow();

  const openForPrint = useCallback(() => {
    if (url) {
      openInNewTab(url);
    }
  }, [url, openInNewTab]);

  useEffect(() => {
    if (!show) {
      setUrl(undefined);
    }
  }, [show]);

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onHide();
          },
          text: t('common.cancel'),
        },
        {
          disabled: !url,
          onClick: () => {
            openForPrint();
          },
          text: t('common.print'),
        },

      ]}
      show={show}
      showCancel={false}
      title={t('article.flowers.printComercialTitle')}
      onHide={onHide}
    >
      <ComercialPdf erpId={erpId} onLoadSuccess={onLoadSuccess} />
    </Modal>
  );
};
