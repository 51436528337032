import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from 'components/base/Column';

import { MasterDataQuestionType } from 'modules/budget/api/types';
import { BudgetLocationSelector } from 'modules/budget/components/articleSelectors/BudgetLocationSelector';
import { CitySelector } from 'modules/budget/components/commons/CitySelector';
import { Separator } from 'modules/budget/components/commons/Separator';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Address } from 'modules/budget/models/Address';
import { Location } from 'modules/budget/models/Location';
import { ILocation } from 'modules/budget/models/Location/types';

export interface Q39Response {
  address: Address | null;
  location: ILocation | null;
}

interface Q39FormComponent {
  onChange: (value: Q39Response) => void;
  question: BudgetQuestionCodes;
}

export const Q39Form: FC<Q39FormComponent> = ({ onChange, question }) => {
  const { t } = useTranslation();
  const { GetQuestionValue } = useBudget();
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.address && storedValue?.location) {
        setSelectedAddress(new Address(storedValue?.address));
        setSelectedLocation(storedValue?.location);

        onChange({
          address: new Address(storedValue?.address),
          location: storedValue?.location,
        });
      } else {
        setSelectedAddress(null);
        setSelectedLocation(null);
      }
    },
    [onChange, storedValue?.address, storedValue?.location],
  );

  return (
    <Formik
      initialValues={{
        address: selectedAddress,
        location: selectedLocation,
      }}
      validate={(values) => {
        onChange({
          address: values.address,
          location: values.location,
        });
        setSelectedAddress(values.address);
        setSelectedLocation(values.location);

        return {};
      }}
      enableReinitialize
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        values, setValues,
      }: FormikProps<Q39Response>) => (
        <Column>
          <CitySelector
            cityCode={values.address?.city || ''}
            cityWork={Boolean(values.address?.work)}
            countryCode={values.address?.country || ''}
            disabled={false}
            provinceCode={values.address?.province || ''}
            emitPartial
            onChange={(val) => {
              setValues({
                address: new Address(val),
                location: values.address?.isEqual(val) ? values.location : null,
              });
            }}
          />
          <Separator />
          <BudgetLocationSelector
            caption={t('service.interment.cemetery')}
            city={values.address?.city || ''}
            country={selectedAddress?.country || ''}
            disabled={false}
            province={selectedAddress?.province || ''}
            questionType={MasterDataQuestionType.INTERMENT}
            value={selectedLocation?.id || ''}
            onChange={(val) => {
              setValues({
                address: values.address,
                location: val ? new Location(val) : null,
              });
            }}
          />
        </Column>
      )}
    </Formik>
  );
};
