import React, { FC } from 'react';
import { Settings } from 'react-feather';
import { useTranslation } from 'react-i18next';

import config from 'config';

import Button from 'components/base/Button';
import TopInfo from 'components/base/TopInfo';

import { SaveService } from 'models/MasterService';
import Record from 'models/Record';

import './index.scss';

type RecordTopInfoProps = {
  record: Record | SaveService;
  showLink?: boolean;
};

const RecordTopInfo: FC<RecordTopInfoProps> = ({ record, showLink }) => {
  const { t } = useTranslation();

  return (
    <TopInfo className="record-top-info">
      <div className="record-number">
        <span className="field-header">{t('record.number')}</span>
        {showLink ? (
          <Button
            color="transparent"
            text={`${record.erpId}`}
            to={`${config.url.records}/${record.id}/edit`}
          />
        ) : (
          <span>{record.erpId}</span>
        )}
      </div>
      <div>
        <span className="field-header">{t('record.deceased')}</span>
        <span>
          {record.deceasedData?.name}
          {' '}
          {record.deceasedData?.firstSurname}
          {' '}
          {record.deceasedData?.secondSurname}
        </span>
      </div>
      <div>
        <Button
          className="expedient-button"
          color="transparent"
          rightAddon={<Settings />}
          text={t('record.edit')}
          to={`${config.url.records}/${record.id}/edit`}
        />
      </div>
    </TopInfo>
  );
};

RecordTopInfo.defaultProps = {
  showLink: false,
};

export default RecordTopInfo;
