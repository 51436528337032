import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { i18n } from 'i18n';
import Client from 'models/Client';
import User from 'models/User';

import { IdentifierTypes } from './IdentifierTypes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resendEmail = async (row: User) => {
  try {
    await config.apiFunus.users.resendPasswordMail(row.id);
    showSuccessToast(i18n.t('user.resendPasswordMail.Ok'));
  } catch (err) {
    showErrorToast(i18n.t('user.resendPasswordMail.Ko'));
  }
};

const validateNifLetter = (nif: string): boolean => {
  const nifIsValid = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i.test(nif);

  if (nifIsValid) {
    const letters = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const number = nif.substring(0, nif.length - 1);
    const parseNumber = parseInt(number, 10);
    const remainder = parseNumber % 23;
    const letter = nif.slice(-1);
    const correctLetter = letters.substring(remainder, remainder + 1);

    return correctLetter.toUpperCase() === letter.toUpperCase();
  }

  return false;
};

export const identifiersValidator = (
  type: string,
  identifier: string,
): boolean => {
  let regex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;

  if (type === IdentifierTypes.nie) {
    regex = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
  } else if (type === IdentifierTypes.nif) {
    return validateNifLetter(identifier);
  } else if (type === IdentifierTypes.passport) {
    return true;
  }
  return !!identifier && regex.test(identifier);
};

export const getIdentifier = (client: Client): string => client.identifier || client.passport || client.cif || client.nie || '';
