import { useState } from 'react';

import { DocumentDNIScanData } from 'modules/ia/models/DocumentDNIScanData';
import { DocumentDNIScanScore } from 'modules/ia/models/DocumentDNIScanScore';

import { DniScanInfoHook } from './types';

export const useDniScanInfo = (): DniScanInfoHook => {
  const [docScore, setDocScore] = useState<DocumentDNIScanScore>({
    address: {
      city: null,
      country: null,
      province: null,
      street: null,
    },
    birthAddress: {
      firstField: null,
      secondField: null,
    },
    birthDate: null,
    firstParent: null,
    firstSurname: null,
    genre: null,
    idCard: null,
    name: null,
    nationality: null,
    secondParent: null,
    secondSurname: null,
  });
  const [docInfo, setDocInfo] = useState<DocumentDNIScanData>({
    address: {
      city: null,
      country: null,
      province: null,
      street: null,
    },
    birthAddress: {
      firstField: null,
      secondField: null,
    },
    birthDate: null,
    firstParent: null,
    firstSurname: null,
    genre: null,
    idCard: null,
    name: null,
    nationality: null,
    secondParent: null,
    secondSurname: null,
  });
  return {
    docInfo,
    docScore,
    setDocInfo,
    setDocScore,
  };
};
