import { BudgetQuestionCodes } from '../../data/budgetQuestionCodes';
import { IArticlesCollection } from '../ArticlesCollection/types';
import { Audit } from '../Audit';
import { IBudgetLocation } from '../BudgetLocation/types';

import {
  BudgetQuestionTypes, BudgetResponseCodes, IBudgetQuestion,
} from './types';

export class BudgetQuestion extends Audit implements IBudgetQuestion {
  constructor(params: IBudgetQuestion) {
    super(params);
    this.response = params.response;
    this.code = params.code;
    this.location = params.location;
    this.articles = params.articles;
    this.questionType = params.questionType;
    this.insuranceCode = params.insuranceCode;
    this.date = params.date;
    this.atHome = params.atHome;
  }

  response?: BudgetResponseCodes | undefined;

  code: BudgetQuestionCodes;

  location?: IBudgetLocation | null | undefined;

  articles?: IArticlesCollection | undefined;

  questionType?: BudgetQuestionTypes | null | undefined;

  insuranceCode?: string | null | undefined;

  date?: string | null | undefined;

  atHome?: boolean | undefined;
}
