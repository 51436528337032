import classNames from 'classnames';
import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import {
  ChevronDown,
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
  HeaderGroup,
  Row,
  Cell,
  SortingRule,
  useFilters,
  ColumnInstance,
  Filters,
  HeaderProps,
} from 'react-table';

import Button from 'components/base/Button';
import Input from 'components/base/Input';
import Loader from 'components/base/Loader';
import Select from 'components/base/Select';
// eslint-disable-next-line import/no-cycle
import ActionsColumn from 'components/base/Table/ActionsColumn';
import Tooltip from 'components/base/Tooltip';

import useWindowSize from 'hooks/useWindowSize';
import { i18n } from 'i18n';

import './index.scss';
import { TableActionsProps as TableActions } from './ActionsColumn/types';
import { TableColumn } from './types';

export type TableActionsProps = TableActions;

type TableCell<T extends Record<string, unknown>> = {
  column: TableColumn<T>;
};

export type ServerSide = {
  filter?: boolean;
  pagination?: boolean;
  sort?: boolean;
};

export type TableProps<T extends Record<string, unknown>> = {
  actions?: (row: T, index: number) => TableActionsProps;
  className?: string;
  columns: TableColumn<T>[];
  currentPage?: number;
  data: T[];
  fetchData?: (
    page: number,
    pageSize: number,
    params?: SortingRule<T>[],
    filters?: Filters<T>
  ) => void;
  loading?: boolean;
  pageSize?: number;
  pagination?: boolean;
  rowClassName?: (index: number, row?: T) => string;
  serverSide?: ServerSide;
  totalPages?: number;
};

const DefaultColumnFilter = <T extends Record<string, unknown>>({
  column: { filterValue, setFilter, id },
}: HeaderProps<T>) => (
  <Input
    className="filter-input"
    name={id}
    placeholder={`${i18n.t('common.search')}`}
    value={filterValue || ''}
    clearable
    onChange={(value) => {
      setFilter(value || undefined); // Set undefined to remove the filter entirely
    }}
  />
  );

const Table = <T extends Record<string, unknown>>({
  actions,
  className,
  columns,
  currentPage: currentPageProps,
  data,
  fetchData,
  loading,
  pageSize: pageSizeProps,
  pagination,
  rowClassName,
  serverSide,
  totalPages,
}: TableProps<T>): ReactElement => {
  const [currentFilters, setCurrentFilters] = useState<Filters<T>>();
  const [currentPage, setCurrentPage] = useState(currentPageProps || 0);
  const [selectedRow, setSelectedRow] = useState<string>();
  const [size, setSize] = useState(pageSizeProps || 10);
  const [sort, setSort] = useState<SortingRule<T>[]>([]);
  const { t } = useTranslation();
  const { xlOrSmaller } = useWindowSize();

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    setPageSize,
    state: {
      pageIndex, pageSize, sortBy, filters,
    },
  } = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.hidden)
          .map(({ accessor }) => `${accessor}`),
        pageIndex: currentPage,
        pageSize: pageSizeProps || 10,
      },
      manualFilters: !!serverSide?.filter,
      manualPagination: !!serverSide?.pagination,
      manualSortBy: !!serverSide?.sort,
      pageCount: serverSide?.pagination
        ? totalPages || 0
        : data.length / (pageSizeProps || 10),
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const closeExpanded = () => {
    if (selectedRow && !xlOrSmaller) {
      setSelectedRow(undefined);
    }
  };

  useEffect(() => {
    if (
      (serverSide?.filter || serverSide?.pagination || serverSide?.sort)
      && fetchData
      && (pageIndex !== currentPage
        || size !== pageSize
        || JSON.stringify(sortBy) !== JSON.stringify(sort)
        || JSON.stringify(filters) !== JSON.stringify(currentFilters))
    ) {
      fetchData(pageIndex, pageSize, sortBy, filters);
      setCurrentPage(pageIndex);
      setSize(pageSize);
      setSort(sortBy);
      setCurrentFilters(filters);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, pageIndex, pageSize, sortBy, filters, serverSide]);

  useEffect(() => {
    if (
      (currentPageProps || currentPageProps === 0)
      && currentPage !== currentPageProps
    ) {
      gotoPage(currentPageProps);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageProps]);

  return (
    <div
      className={classNames('funus-table', className, {
        'actions-table': !!actions,
      })}
    >
      {loading && <Loader />}
      <div>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup<T>) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id || 1}
              >
                {headerGroup.headers.map(
                  (column: TableColumn<T> & ColumnInstance<T>) => (
                    <th
                      {...column.getHeaderProps({
                        className: classNames(column.className, {
                          'collapse-text': column.collapse,
                          hidden: column.hidden,
                        }),
                      })}
                      key={column.id}
                    >
                      {column.sortable ? (
                        <div
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...column.getSortByToggleProps()}
                          className="sort-header"
                        >
                          {column.render('Header')}
                          <ChevronDown
                            className={classNames({
                              'inverted-icon': column.isSortedDesc,
                              'not-sorted': !column.isSorted,
                            })}
                          />
                        </div>
                      ) : (
                        <div>{column.render('Header')}</div>
                      )}
                      <div>
                        {column.filterable ? column.render('Filter') : null}
                      </div>
                    </th>
                  ),
                )}
                {!!actions && <th className="actions-header" role="row" />}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading || page?.length > 0 ? (
              page.map((row: Row<T>, index: number) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={row.id}
                    className={classNames(
                      'table-row',
                      row.getRowProps().className,
                      rowClassName?.(index, row.original),
                      {
                        'no-items': loading,
                      },
                    )}
                  >
                    {row.cells.map((cell: TableCell<T> & Cell<T>) => (
                      <td
                        {...cell.getCellProps([
                          {
                            className: classNames(cell.column.className, {
                              'collapse-text': cell.column.collapse,
                              hidden: cell.column.hidden,
                            }),
                          },
                        ])}
                        key={cell.column.id}
                        width={cell.column.width}
                      >
                        {cell.column.collapse ? (
                          <Tooltip content={cell.render('Cell')}>
                            {cell.render('Cell')}
                          </Tooltip>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                    {!loading && actions && !!actions && (
                      <ActionsColumn<T>
                        actions={actions}
                        index={index}
                        row={row}
                        selectedRow={selectedRow}
                        onClose={closeExpanded}
                        onExpand={setSelectedRow}
                      />
                    )}
                  </tr>
                );
              })
            ) : (
              <tr className="no-items">
                <td colSpan={columns?.length}>{t('common.noItems')}</td>
              </tr>
            )}
          </tbody>
          {pagination
            && (data?.length > 10 || (!!totalPages && totalPages > 1)) && (
              <tfoot>
                <tr>
                  <td colSpan={columns.length}>
                    <div className="pagination">
                      <Button
                        className="first-button"
                        disabled={!canPreviousPage}
                        onClick={() => gotoPage(0)}
                      >
                        <ChevronsLeft />
                      </Button>
                      <Button
                        className="prev-button"
                        disabled={!canPreviousPage}
                        onClick={() => gotoPage(pageIndex - 1)}
                      >
                        <ChevronLeft />
                      </Button>
                      <div>
                        {t('common.page')}
                        {' '}
                        <strong>
                          {pageIndex + 1}
                          {' '}
                          {t('common.of')}
                          {' '}
                          {pageCount}
                        </strong>
                      </div>
                      <Button
                        className="next-button"
                        disabled={!canNextPage}
                        onClick={() => gotoPage(pageIndex + 1)}
                      >
                        <ChevronRight />
                      </Button>
                      <Button
                        className="last-button"
                        disabled={!canNextPage}
                        onClick={() => gotoPage(pageCount - 1)}
                      >
                        <ChevronsRight />
                      </Button>
                      {(pageCount > 1 || page?.length > 5) && (
                        <Select<number>
                          getLabel={(e) => `${e}`}
                          getValue={(e) => e}
                          name="pagination"
                          options={[10, 20, 50]}
                          placeholder={t('common.pageSize')}
                          value={pageSize}
                          openUp
                          onChange={(e) => {
                            setPageSize(Number(e));
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};

Table.defaultProps = {
  actions: undefined,
  className: undefined,
  currentPage: undefined,
  fetchData: undefined,
  pageSize: 10,
  pagination: true,
  serverSide: {
    filter: false,
    pagination: false,
    sort: false,
  },
  totalPages: undefined,
};

export default Table;
