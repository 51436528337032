import {
  ApiFunusEnquiries,
} from './types';
import axios from 'config/axios';
import { defaultFilter } from '.';

export const enquiries: ApiFunusEnquiries = {
  editEnquiry: ({ id, values }) => axios.put(`/enquiry/${id}`, values),
  getEnquiries: () => axios.get('/enquiries'),
  getEnquiryById: (id) => axios.get(`/enquiry/${id}`),
  saveEnquiry: (params) => axios.post('/enquiry', params),
  setEnquiries: (params = defaultFilter) => axios.post('/enquiries', params),
  getEnquiriesDashboard: () => axios.get('/enquiries/dashboard'),
  assignUser: (enquiryId, userId, comment = '') => axios.put(`/enquiries/${enquiryId}/assign`, { userId, comment }),
  finish: (enquiryId, comment = '') => axios.put(`/enquiries/dashboard/${enquiryId}`, { comment })
};
