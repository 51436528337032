import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import FormPage from 'components/base/FormPage';

import {
  GetEnquiryFormFields,
  enquiryFormInitialValues,
} from 'components/enquiry/Form';

import { SaveEnquiry } from 'models/Enquiry';

import './index.scss';

type PageParams = {
  id: string;
  action: string;
};

const EnquiryForm: FC = () => {
  const [editing, setEditing] = useState(false);
  const { id, action } = useParams<PageParams>();
  const { t } = useTranslation();

  useEffect(() => {
    setEditing(!!id && !Number.isNaN(id) && action === 'edit');
  }, [id, action]);

  const Form = useCallback(
    () => (
      <div id="enquiry-form-page">
        <FormPage<SaveEnquiry>
          editing={editing}
          fields={GetEnquiryFormFields}
          getFunction={config.apiFunus.enquiries.getEnquiryById}
          id={id}
          initialValues={enquiryFormInitialValues}
          saveFunction={config.apiFunus.enquiries.saveEnquiry}
          title={{
            add: t('enquiry.new'),
            edit: t('enquiry.edit'),
            see: t('enquiry.detail'),
          }}
          updateFunction={config.apiFunus.enquiries.editEnquiry}
          validateForm={(values) => config.validator(values, 'enquiry')}
          onError={() => showErrorToast(t('enquiry.saveKo'))}
          onSave={() => showSuccessToast(t('enquiry.saveOk'))}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editing],
  );

  return <Form />;
};

export default EnquiryForm;
