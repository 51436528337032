import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { useCoffins } from 'hooks/useCoffins';
import { IMasterDataDto } from 'models/MasterData';
import { budgetMasterDataClient } from 'modules/budget/api';
import { MasterDataQuestionType } from 'modules/budget/api/types';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { ArticleCollectionTypes, ArticleType } from 'modules/budget/models/Article/types';
import { BudgetArticle } from 'modules/budget/models/BudgetArticle/types';
import { BudgetResponseCodes } from 'modules/budget/models/BudgetQuestion/types';
import { RootState } from 'store';

import { BlocTypes } from './BlocTypes';
import { useBudgetFormHook } from './types';

export const sortByCode = (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  a: any,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  b: any,
): number => (a.code && b.code && (a.code > b.code) ? 1 : -1);

export const useBudgetForm = (): useBudgetFormHook => {
  const { insurances } = useSelector((state: RootState) => state.masterData);
  const { coffinSizes } = useCoffins();

  const { t } = useTranslation();
  const [articles, setArticles] = useState<BudgetArticle[]>([]);

  const questionCodes: IMasterDataDto[] = useMemo(
    () => Object
      .values(BudgetQuestionCodes)
      .filter((questionCode) => questionCode !== BudgetQuestionCodes.SUMMARY)
      .map((questionCode) => ({
        code: questionCode,
        description: t(`budget.wizard.${questionCode}`),
      })),
    [t],
  );

  const blocTypes = useMemo(
    () => Object
      .values(BlocTypes)
      .map((blocType) => ({
        code: blocType,
        description: blocType,
      })),
    [],
  );

  const responseCodes: IMasterDataDto[] = useMemo(
    () => Object
      .values(BudgetResponseCodes)
      .map((responseCode) => ({
        code: responseCode,
        description: t(`budget.responses.${responseCode}`),
      })),
    [t],
  );

  const locationTypeLabels = useCallback(
    (type) => {
      const locationTypesCollection: Map<MasterDataQuestionType, string> = new Map([
        [MasterDataQuestionType.CEREMONY, t('service.ceremony.location')],
        [MasterDataQuestionType.CREMATION, t('service.cremation.crematorium')],
        [MasterDataQuestionType.DEATH, t(`budget.locationTypes.${MasterDataQuestionType.DEATH}`)],
        [MasterDataQuestionType.INTERMENT, t('service.interment.cemetery')],
        [MasterDataQuestionType.VIGIL, t('service.vigil.location')],
        [MasterDataQuestionType.OBITUARY, t(`budget.locationTypes.${MasterDataQuestionType.OBITUARY}`)],
      ]);
      return locationTypesCollection.get(type) || t('common.location');
    },
    [t],
  );

  const locationTypes: IMasterDataDto[] = useMemo(
    () => Object.values(MasterDataQuestionType)
      .filter((type) => [
        MasterDataQuestionType.CEREMONY,
        MasterDataQuestionType.CREMATION,
        MasterDataQuestionType.DEATH,
        MasterDataQuestionType.INTERMENT,
        MasterDataQuestionType.VIGIL,
        MasterDataQuestionType.OBITUARY,
      ].includes(type))

      .map((locationType) => ({
        code: locationType,
        description: locationTypeLabels(locationType),
      })),
    [locationTypeLabels],
  );

  const fetchArticles = useCallback(
    () => budgetMasterDataClient.GetArticles()
      .then((response) => {
        setArticles(response);
        return response;
      })
      .catch((error) => {
        showErrorToast(error.message);
        throw error;
      }),
    [],
  );

  const getArticleByCode = useCallback(
    (code: string) => articles.find((a) => a.code === code),
    [articles],
  );

  const fetchConfiguration = useCallback(
    (articleId) => budgetMasterDataClient
      .GetConfiguration(articleId),
    [],
  );

  const saveConfiguration = useCallback(
    (articleId, configuration) => budgetMasterDataClient
      .SaveConfiguration(articleId, configuration)
      .then((response) => {
        showSuccessToast(t('budget.actions.ruleSaveSuccess'));
        return response;
      })
      .catch((error) => {
        throw error;
      }),
    [t],
  );

  const articleTypes: IMasterDataDto[] = useMemo(
    () => Object
      .values(ArticleType)
      .map((typeCode) => ({
        code: typeCode,
        description: t(`budget.articleTypes.${typeCode}`),
      })),
    [t],
  );

  const articleSubTypes: IMasterDataDto[] = useMemo(
    () => Object
      .values(ArticleCollectionTypes)
      .map((subTypeCode) => ({
        code: subTypeCode,
        description: t(`budget.articleSubTypes.${subTypeCode}`),
      })),
    [t],
  );

  return {
    articles,
    articleSubTypes,
    articleTypes,
    blocTypes,
    coffinSizes,
    fetchArticles,
    fetchConfiguration,
    getArticleByCode,
    insurances,
    locationTypeLabels,
    locationTypes,
    questionCodes,
    responseCodes,
    saveConfiguration,
  };
};
