import { useState } from 'react';

import { masterController } from 'config/apiFunus';
import { Article } from 'config/apiFunus/generated/data-contracts';

import { MasterDataWithPrice } from '../MasterDataWithPrice';
import { priceWithVATRounded } from '../priceWithVATRounded';

import { GetArticleByCategoryUsingGetHook } from './types';

export const useGetArticleByCategoryUsingGet = (
  errorMessage: (message: string) => void,
): GetArticleByCategoryUsingGetHook => {
  const [articlesByCategory, setArticlesByCategory] = useState<MasterDataWithPrice[]>([]);

  const fetchArticleByCategory = (categoryCode: string) => masterController
    .getArticleByCategoryUsingGet(categoryCode)
    .then((response) => {
      const mapToMasterDataWithPrice = (
        article: Article,
      ): MasterDataWithPrice => ({
        code: article.id,
        description: article.description,
        price: article.price,
        priceWithVAT: article.price ? priceWithVATRounded(article.price) : null,
      });

      const articlesList = response.articles
        ? response.articles
          .map(mapToMasterDataWithPrice)
        : [];
      setArticlesByCategory(articlesList);

      return response;
    })
    .catch((error) => {
      errorMessage(error.message);
      throw error;
    });

  return {
    articlesByCategory,
    fetchArticleByCategory,
  };
};
