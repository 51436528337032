/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { FormikProps } from 'formik';
import React, {
  FC, ReactElement, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import config from 'config';

import { formatDateAndHour } from 'utils/dateManager';
import { formatName } from 'utils/helpers';

import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes, OtherTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import SideFilterField from 'components/base/SideFilters/SideFilterField';

import { IFilter } from 'config/apiFunus/types';
import { BudgetsTrackingItem } from 'modules/budget/api/BudgetsTrackingItem';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useBudgetFeatures } from 'modules/budget/hooks/useBudgetFeatures';
import { useBudgets } from 'modules/budget/hooks/useBudgets';

import { BudgetAcceptConfirmModal } from '../budgetAcceptConfirmModal';
import { BudgetAssignRecordModal } from '../budgetAssignRecordModal';
import { BudgetBackwardConfirmModal } from '../budgetBackwardConfirmModal';
import { BudgetInvoiceModal } from '../budgetInvoiceModal';
import { BudgetPdfModal } from '../budgetPdfModal';
import { BudgetSendModal } from '../budgetSendModal';
import { BudgetSignModal } from '../budgetSignModal';

import { BudgetsTrackingFilterStatus, BudgetsTrackingFilter, BudgetsTrackingColumn } from './types';

import './index.scss';

export const BudgetsTrackingTable: FC<Record<string, unknown>> = () => {
  const { t } = useTranslation();
  const { fetchBudgetsTracking } = useBudgets();
  const {
    DuplicateBudget,
    Reset,
    CancelBudget,
  } = useBudget();
  const {
    canDuplicate,
    canAssign,
    canPrint,
    canCancel,
    canAccept,
    canSign,
    canInvoice,
    canView,
    canEmail,
    canBudgetBackward,
  } = useBudgetFeatures();
  const history = useHistory();
  const [assignSuccessCallback, setAssignSuccessCallback] = useState<() => unknown>();
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [selectedBudged, setselectedBudged] = useState<BudgetsTrackingItem | null>(null);
  const [selectedBudgetId, setSelectedBudgetId] = useState<number>();
  const [selectedRecordId, setSelectedRecordId] = useState<number>();
  const [showAssignRecordModal, setShowAssignRecordModal] = useState<boolean>(false);
  const [showSign, setShowSign] = useState<boolean>(false);
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [showSendBudget, setShowSendBudget] = useState<boolean>(false);
  const [showConfirmAcceptBudget, setShowConfirmAcceptBudget] = useState<boolean>(false);
  const [showBackwardAcceptBudget, setShowBackwardAcceptBudget] = useState<boolean>(false);
  const [signSuccessCallback, setSignSuccessCallback] = useState<() => unknown>();
  const [invoiceSuccessCallback, setInvoiceSuccessCallback] = useState<() => unknown>();
  const [acceptSuccessCallback, setAcceptSuccessCallback] = useState<() => unknown>();
  const [backwardSuccessCallback, setBackwardSuccessCallback] = useState<() => unknown>();

  const loadTracking = useCallback(
    (
      filter: IFilter<BudgetsTrackingFilter>,
    ) => fetchBudgetsTracking(filter)
      .catch(() => false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const fields = useCallback(
    (
      formikProps: FormikProps<BudgetsTrackingFilter>,
    ): ReactElement[] => [
      <SideFilterField
        key="createdDate"
        filterType="date"
        formikProps={formikProps}
        name="createdDate"
        placeholder={`${t('common.date')}`}
      />,
      <SideFilterField
        key="deceasedName"
        filterType="input"
        formikProps={formikProps}
        name="deceasedName"
        placeholder={`${t('common.name')}`}
      />,
      <SideFilterField
        key="deceasedFirstSurname"
        filterType="input"
        formikProps={formikProps}
        name="deceasedFirstSurname"
        placeholder={`${t('common.firstSurname')}`}
      />,
      <SideFilterField
        key="deceadedSecondSurname"
        filterType="input"
        formikProps={formikProps}
        name="deceadedSecondSurname"
        placeholder={`${t('common.secondSurname')}`}
      />,
      <SideFilterField
        key="erpId"
        filterType="input"
        formikProps={formikProps}
        name="erpId"
        placeholder={`${t('record.number')}`}
      />,
      <SideFilterField
        key="id"
        filterType="input"
        formikProps={formikProps}
        name="id"
        placeholder={`${t('budget.budgetNumber')}`}
      />,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <SideFilterField<any, BudgetsTrackingFilterStatus>
        key="status"
        filterType="select"
        formikProps={formikProps}
        getLabel={(value) => t(`budget.states.${value}`)}
        getValue={(value) => value}
        name="status"
        options={Object.values(BudgetsTrackingFilterStatus)}
        placeholder={`${t('common.status')}`}
        clearable
      />,
      <SideFilterField
        key="processor"
        filterType="input"
        formikProps={formikProps}
        name="processor"
        placeholder={`${t('budget.processor')}`}
      />,
    ],
    [t],
  );

  const columns = useMemo(
    (): BudgetsTrackingColumn[] => {
      const erpIdColumn = {
        accessor: 'erpId',
        Header: `${t('common.erpId')}`,
        sortable: true,
      };
      const idColumn: BudgetsTrackingColumn = {
        accessor: 'id',
        Header: `${t('budget.budgetNumber')}`,
        sortable: false,
      };

      const dateColumn: BudgetsTrackingColumn = {
        accessor: 'createdDate',
        Cell: ({
          row: {
            original: {
              createdDate,
            },
          },
        }) => formatDateAndHour(createdDate),
        Header: `${t('common.createdDate')}`,
        sortable: true,
      };
      const deceasedNameColumn: BudgetsTrackingColumn = {
        accessor: 'deceasedName',
        Cell: ({
          row: {
            original: {
              deceasedName, deceasedFirstSurname, deceasedSecondSurname,
            },
          },
        }) => formatName({
          firstSurname: deceasedFirstSurname,
          name: deceasedName,
          secondSurname: deceasedSecondSurname,
        }),
        Header: `${t('common.name')}`,
        sortable: false,
      };

      const statusColumn: BudgetsTrackingColumn = {
        accessor: 'status',
        Cell: ({
          row: {
            original: {
              status,
            },
          },
        }) => t(`budget.states.${status}`),
        Header: `${t('common.status')}`,
        sortable: true,
      };

      const processorColumn: BudgetsTrackingColumn = {
        accessor: 'processor',
        Cell: ({
          row: {
            original: {
              processorName,
              processorFirstSurname,
              processorSecondSurname,
            },
          },
        }) => `${processorName || ''} ${processorFirstSurname || ''} ${processorSecondSurname || ''}`,
        Header: `${t('budget.processor')}`,
        sortable: true,
      };

      return [
        erpIdColumn,
        idColumn,
        deceasedNameColumn,
        statusColumn,
        dateColumn,
        processorColumn,
      ];
    },
    [t],
  );

  return (
    <React.Fragment>
      <FilteredPage
        actions={(item: BudgetsTrackingItem) => ({
          backwardBudget: canBudgetBackward(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.BUDGET_BACKWARD} />,
              isExtra: true,
              tooltipCaption: t('budget.actions.backward'),
              onClick: (onSuccess: () => void) => {
                setBackwardSuccessCallback(() => () => onSuccess());
                setselectedBudged(item);
                setShowBackwardAcceptBudget(true);
              },
            }
            : undefined,
          see: canView(item)
            ? {
              icon: <CustomIcon icon={FeatherIconTypes.EYE} />,
              tooltipCaption: t('common.see'),
              url: `/budget/summary/${item.id}`,
            }
            : undefined,
          showBudgetPdf: canPrint(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.PRINT} />,
              onClick: () => {
                setSelectedBudgetId(item.id);
                setShowPdf(true);
              },
              tooltipCaption: t('common.print'),
            }
            : undefined,
          acceptBudget: canAccept(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.BUDGET_ACCEPT} />,
              onClick: (onSuccess: () => void) => {
                setAcceptSuccessCallback(() => () => onSuccess());
                setSelectedBudgetId(item.id);
                setShowConfirmAcceptBudget(true);
              },
              tooltipCaption: t('budget.actions.accept'),
            }
            : undefined,
          signBudget: canSign(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.SIGN} />,
              onClick: (onSuccess: () => void) => {
                setSelectedBudgetId(item.id);
                setSignSuccessCallback(() => () => onSuccess());
                setShowSign(true);
              },
              tooltipCaption: t('budget.actions.sign'),
            }
            : undefined,
          invoiceBudget: canInvoice(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.INVOICE} />,
              onClick: (onSuccess: () => void) => {
                setSelectedBudgetId(item.id);
                setSelectedRecordId(item.erpId);
                setInvoiceSuccessCallback(() => () => onSuccess());
                setShowInvoice(true);
              },
              tooltipCaption: t('budget.actions.invoice'),
            }
            : undefined,
          duplicateBudget: canDuplicate(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.DUPLICATE} />,
              isExtra: true,
              onClick: (onSuccess: () => void) => {
                DuplicateBudget(item.id)
                  .then(() => onSuccess())
                  .catch(() => false);
              },
              tooltipCaption: t('budget.actions.duplicate'),
            }
            : undefined,
          cancelBudget: canCancel(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />,
              isExtra: true,
              onClick: (onSuccess: () => void) => {
                CancelBudget(item.id)
                  .then(() => onSuccess())
                  .catch(() => false);
              },
              tooltipCaption: t('budget.actions.cancel'),
            }
            : undefined,
          emailBudget: canEmail(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.EMAIL} />,
              isExtra: true,
              onClick: () => {
                setSelectedBudgetId(item.id);
                setShowSendBudget(true);
              },
              tooltipCaption: t('budget.actions.send'),
            }
            : undefined,
          budgetAssignRecord: canAssign(item)
            ? {
              icon: <CustomIcon icon={OtherTypes.ASSIGN_RECORD} />,
              isExtra: true,
              onClick: (onSuccess: () => void) => {
                setAssignSuccessCallback(() => () => onSuccess());
                setSelectedBudgetId(item.id);
                setShowAssignRecordModal(true);
              },
              tooltipCaption: t('budget.actions.assignRecord'),
            }
            : undefined,
        })}
        apiCall={(filter) => loadTracking({
          filter: filter.filter,
          page: filter.page,
          sort: filter.sort?.length !== 0
            ? filter.sort
            : [{
              descending: true,
              name: 'createdDate',
            }],
        })}
        className="f-budgets-table"
        columns={columns}
        create={{
          onClick: () => {
            Reset();
            history.push(config.url.budgetsWizardNew);
          },
          title: t('budget.new'),
        }}
        fields={fields}
        initialValues={{}}
        text={{
          search: t('common.search'),
          title: t('budget.title'),
        }}
      />
      {selectedBudgetId && (
      <BudgetPdfModal
        budgetId={selectedBudgetId}
        show={showPdf}
        onHide={() => {
          setShowPdf(false);
        }}
      />
      )}
      <BudgetAssignRecordModal
        budgetId={Number(selectedBudgetId)}
        show={showAssignRecordModal}
        onCancel={() => {
          setShowAssignRecordModal(false);
        }}
        onSuccess={() => {
          assignSuccessCallback?.();
          setShowAssignRecordModal(false);
        }}
      />
      {selectedBudgetId && (
      <BudgetSignModal
        budgetId={selectedBudgetId}
        show={showSign}
        onCancel={() => {
          setShowSign(false);
        }}
        onSuccess={() => {
          signSuccessCallback?.();
          setShowSign(false);
        }}
      />
      )}
      {selectedBudgetId && selectedRecordId && (
      <BudgetInvoiceModal
        budgetId={selectedBudgetId}
        erpId={selectedRecordId}
        show={showInvoice}
        onCancel={() => {
          setShowInvoice(false);
        }}
        onSuccess={() => {
          invoiceSuccessCallback?.();
          setShowInvoice(false);
        }}
      />
      )}
      {selectedBudgetId && (
      <BudgetAcceptConfirmModal
        budgetId={selectedBudgetId}
        show={showConfirmAcceptBudget}
        onCancel={() => { setShowConfirmAcceptBudget(false); }}
        onSuccess={() => {
          acceptSuccessCallback?.();
          setShowConfirmAcceptBudget(false);
        }}
      />
      )}
      {selectedBudged && (
        <BudgetBackwardConfirmModal
          budget={selectedBudged}
          show={showBackwardAcceptBudget}
          onCancel={() => {
            setShowBackwardAcceptBudget(false);
          }}
          onSuccess={() => {
            backwardSuccessCallback?.();
            setShowBackwardAcceptBudget(false);
          }}
        />
      )}
      {selectedBudgetId && (
      <BudgetSendModal
        budgetId={selectedBudgetId}
        show={showSendBudget}
        onCancel={() => { setShowSendBudget(false); }}
        onSuccess={() => { setShowSendBudget(false); }}
      />
      )}

    </React.Fragment>
  );
};
