import { Audit } from '../Audit';

import { ICode } from './types';

export class Code extends Audit implements ICode {
  public toString(): string {
    return this.code;
  }

  static create(code: string): Code {
    return new Code({ code });
  }

  constructor(data: ICode) {
    super(data);
    this.code = data.code;
  }

  code: string;
}
