import { RefObject, useEffect } from 'react';

const useOnBlur = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
): void => {
  useEffect(() => {
    const el = ref?.current;
    const listener = (event: Event) => {
      // Do nothing if blur ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    if (el) {
      document.addEventListener('focusin', listener);
    }

    return () => {
      if (el) {
        document.removeEventListener('focusin', listener);
      }
    };
  }, [ref, handler]);
};

export default useOnBlur;
