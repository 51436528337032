/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from 'utils/toasts';

import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { AppController } from 'modules/push-notifications/scripts/app-controller';

import { PushNotificationsHook } from './types';

export const usePushNotifications = (): PushNotificationsHook => {
  const { t } = useTranslation();
  const { user } = useProvidedAuth();
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState<boolean>(false);

  const errorHandler = useCallback(
    (e: any) => {
      showErrorToast(t(e.message));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const successHandler = useCallback(
    () => false,
    [],
  );

  const onStateChange = (state: any) => {
    switch (state.id) {
      case 'UNSUPPORTED':
        showErrorToast('Push Not Supported');
        break;
      case 'ERROR':
        showErrorToast('Push notifications status ERROR');
        break;
      default:
        break;
    }
  };

  const onSubscriptionUpdate = (subscription: any) => {
    setPushNotificationsEnabled(!!subscription);
  };

  const [appController] = useState(new AppController(
    onStateChange,
    onSubscriptionUpdate,
    errorHandler,
    successHandler,
  ));

  const enableNotifications = useCallback(
    () => {
      if (window) {
        if (!navigator.serviceWorker) {
          showErrorToast('Service workers are not supported in this browser.');
          return Promise.reject();
        }

        if (!('PushManager' in window)) {
          showErrorToast('Push is not supported in this browser.');
          return Promise.reject();
        }

        return appController
          .registerServiceWorker()
          .then(() => {
            subscribe();
            return null;
          })
          .catch(() => false);
      }
      return Promise.reject();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const subscribe = useCallback(
    () => appController.subscribeDevice(user?.id),
    [appController, user?.id],
  );

  const unSubscribe = useCallback(
    () => appController.unsubscribeDevice(user?.id),
    [appController, user?.id],
  );

  return {
    enabled: pushNotificationsEnabled, enableNotifications, subscribe, unSubscribe,
  };
};
