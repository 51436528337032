import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { Permission } from 'models/UserRole';

export const checkHaveThisPermissions = (
  validatePermissions: SimpleUserRequestPermissionNames[],
  permissions: Permission[] | undefined,
): boolean => {
  let permissionsChecked = 0;
  validatePermissions?.forEach((p) => {
    if (checkPermission(p, permissions)) {
      permissionsChecked++;
    }
  });

  return validatePermissions.length === permissionsChecked;
};

export const checkHasOnlyPermission = (
  permisssion: SimpleUserRequestPermissionNames,
  permissions: Permission[] | undefined,
): boolean => permissions?.length === 1 && checkHaveThisPermissions([permisssion], permissions);

export const checkHaveAtLeastOnePermission = (
  validatePermissions: SimpleUserRequestPermissionNames[],
  permissions: Permission[] | undefined,
): boolean => {
  let haveAlLeastOne = false;
  validatePermissions?.forEach((p) => {
    if (checkPermission(p, permissions)) {
      haveAlLeastOne = true;
    }
  });

  return haveAlLeastOne;
};

export const checkPermission = (
  permission: SimpleUserRequestPermissionNames,
  permissions: Permission[] | undefined,
): boolean => !!permissions?.some((p: Permission) => p.name === permission.valueOf());
