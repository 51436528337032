import { useState } from 'react';

import { showErrorToast } from 'utils/toasts';

import { budgetController } from 'modules/budget/api';

import { arrayBufferToBase64 } from './helpers';
import { RecordHook } from './types';

export const useBudgetPdf = (): RecordHook => {
  const [base64Pdf, setbase64Pdf] = useState<string>();

  return {
    base64Pdf,
    getDocumentPdf: async (id) => budgetController
      .GenerateDocumentUsingGet(id)
      .then((response) => {
        const base64 = arrayBufferToBase64(response);
        setbase64Pdf(base64);

        return base64;
      })
      .catch((error) => {
        showErrorToast(error.message);

        throw new Error(error);
      }),
  };
};
