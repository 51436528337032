import classNames from 'classnames';
import React, { FC } from 'react';

import { RotatingLogo } from 'components/base/RotatingLogo';
import { Row } from 'components/base/Row';

import './index.scss';

interface LoadingItemsComponent {
  className?: string;
}

export const LoadingItems: FC<LoadingItemsComponent> = ({ className }) => (
  <Row
    className={classNames('f-loading-items', className)}
    justifyContent="space-around"
  >
    <RotatingLogo />
  </Row>
);
