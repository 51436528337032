import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast } from 'utils/toasts';

import Modal from 'components/base/Modal';

import { ConflictError } from 'config/apiErrors/ConflictError';
import { EkonError } from 'config/apiErrors/EkonError';

type ConfirmDeleteRecordModalProps = {
  show: boolean;
  id: string | number | undefined;
  onCancel: () => unknown;
  onSuccess: () => unknown;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ConfirmDeleteRecordModal = ({
  show, id, onCancel, onSuccess,
}: ConfirmDeleteRecordModalProps) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDeleteError = useCallback((error: any) => {
    if (error instanceof ConflictError) {
      showErrorToast(t('record.recordDeleteKoConflict'));
    } else if (error instanceof EkonError) {
      showErrorToast(error.message);
    } else {
      showErrorToast(t('record.recordDeleteKo'));
    }
  }, [t]);

  const onDeleteAcceptClick = () => id && config.apiFunus.record.delete(id)
    .then(() => onSuccess())
    .catch(onDeleteError);

  return (
    <Modal
      buttons={[
        {
          disabled: false,
          id: 'accept',
          onClick: onDeleteAcceptClick,
          text: t('common.accept'),
        },
      ]}
      show={show}
      title={t('record.delete')}
      onHide={() => onCancel?.()}
    >
      <p>
        {t('record.confirmDelete')}
      </p>
    </Modal>
  );
};
