/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdviceDtoReq,
  AdviceDtoRes,
  CalendarDto,
  Client,
  ClientRequest,
  GetCalendarUsingGetParams,
  InputStream,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AdviceController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags advice-controller
   * @name GetCalendarUsingGet
   * @summary getCalendar
   * @request GET:/advice/calendar
   * @secure
   * @response `200` `CalendarDto` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCalendarUsingGet = (query: GetCalendarUsingGetParams, params: RequestParams = {}) =>
    this.http.request<CalendarDto, void>({
      path: `/advice/calendar`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags advice-controller
   * @name FindClientsUsingPost
   * @summary findClients
   * @request POST:/advice/clients
   * @secure
   * @response `200` `Client` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  findClientsUsingPost = (request: ClientRequest, params: RequestParams = {}) =>
    this.http.request<Client, void>({
      path: `/advice/clients`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags advice-controller
   * @name GetAdviceDataUsingGet
   * @summary getAdviceData
   * @request GET:/advicedata/{id}
   * @secure
   * @response `200` `AdviceDtoRes` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getAdviceDataUsingGet = (id: number, params: RequestParams = {}) =>
    this.http.request<AdviceDtoRes, void>({
      path: `/advicedata/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags advice-controller
   * @name SaveAdviceDataUsingPut
   * @summary saveAdviceData
   * @request PUT:/advicedata/{id}
   * @secure
   * @response `200` `InputStream` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  saveAdviceDataUsingPut = (id: number, advice: AdviceDtoReq, params: RequestParams = {}) =>
    this.http.request<InputStream, void>({
      path: `/advicedata/${id}`,
      method: "PUT",
      body: advice,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags advice-controller
   * @name ValidateAdviceDataUsingPut
   * @summary validateAdviceData
   * @request PUT:/validate/advice/{id}
   * @secure
   * @response `200` `InputStream` OK
   * @response `201` `void` Created
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  validateAdviceDataUsingPut = (id: number, advice: AdviceDtoReq, params: RequestParams = {}) =>
    this.http.request<InputStream, void>({
      path: `/validate/advice/${id}`,
      method: "PUT",
      body: advice,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

    getServiceInfo = (id: number, postCeremonyId: number | null = null, params: RequestParams = {}) => {
      let queryParams = ''
      if (postCeremonyId) {
        queryParams = `${queryParams}?id_post_ceremonia=${postCeremonyId}`
      }

      return this.http.request<InputStream, void>({
        path: `/services/${id}/info${queryParams}`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        ...params,
      })
    };
}
