import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';
import { PdfViewer, PdfViewrLoadSuccess } from 'components/base/PdfViewer';

import { useRecord } from 'hooks/useRecord';
import { useWindow } from 'hooks/useWindow';
import './index.scss';

type PrintModalComponent = {
  show: boolean;
  id: number | undefined;
  onHide: () => void
}

type ArticlePdfComponent = {
  id: number | undefined;
  onLoadSuccess: ({ objectUrl }: { objectUrl: string }) => void;
}

const ArticlePdfComponent: FC<ArticlePdfComponent> = ({
  id,
  onLoadSuccess,
}) => {
  const { getArticlePdf, base64Pdf } = useRecord();
  const [pdfStream, setPdfStream] = useState<string>();

  useEffect(() => {
    setPdfStream(base64Pdf);
  }, [base64Pdf]);

  useEffect(
    () => {
      if (id) {
        getArticlePdf(id)
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  return (
    <div>
      {pdfStream && <PdfViewer base64={pdfStream} onLoadSuccess={onLoadSuccess} />}
    </div>
  );
};

export const PrintModal: FC<PrintModalComponent> = ({
  show,
  id,
  onHide,
}) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>();
  const onLoadSuccess = ({ objectUrl }: PdfViewrLoadSuccess) => {
    setUrl(objectUrl);
  };
  const { openInNewTab } = useWindow();

  const openForPrint = useCallback(() => {
    if (url) {
      openInNewTab(url);
    }
  }, [url, openInNewTab]);

  useEffect(() => {
    if (!show) {
      setUrl(undefined);
    }
  }, [show]);

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onHide();
          },
          text: t('common.cancel'),
        },
        {
          disabled: !url,
          onClick: () => {
            openForPrint();
          },
          text: t('common.print'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('article.flowers.printTitle')}
      onHide={onHide}
    >
      <ArticlePdfComponent id={id} onLoadSuccess={onLoadSuccess} />
    </Modal>
  );
};
