import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';

const NotFoundPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div id="not-found">
      <p>
        {`${t('common.thePage')}: `}
        <strong>{window.location.pathname}</strong>
        {` ${t('common.notFound').toLowerCase()}.`}
      </p>
    </div>
  );
};

export default NotFoundPage;
