interface IntervalHook {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startInterval: (callback: () => any, delay: number, intervalId?: any) => { intervalId: any}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stopInterval: (intervalId: any) => void
}

export const useInterval = (): IntervalHook => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startInterval = (callback: () => any, delay: number, intervalId?: any) => {
    if (intervalId) clearInterval(intervalId);
    const newIntervalId = setInterval(async () => {
      if (typeof callback === 'function') {
        callback();
      }
    }, delay);

    return { intervalId: newIntervalId };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stopInterval = (intervalId: any) => {
    if (intervalId) clearInterval(intervalId);
  };

  return {
    startInterval,
    stopInterval,
  };
};
