import { useCallback } from 'react';

import { BudgetsTrackingItem } from 'modules/budget/api/BudgetsTrackingItem';
import { BudgetsTrackingFilterStatus } from 'modules/budget/components/tracking/table/types';

import { BudgetFeaturesHook } from './types';

export const useBudgetFeatures = (): BudgetFeaturesHook => {
  const canView = useCallback(
    (item: BudgetsTrackingItem): boolean => item.status !== BudgetsTrackingFilterStatus.CANCELLED,
    [],
  );

  const canAssign = useCallback(
    (item: BudgetsTrackingItem): boolean => item.erpId === null
    && item.status === BudgetsTrackingFilterStatus.PENDING,
    [],
  );

  const canAccept = useCallback(
    (
      item: BudgetsTrackingItem,
    ): boolean => item.canAccept
    && item.status === BudgetsTrackingFilterStatus.PENDING
    && item.erpId !== null,
    [],
  );

  const canSign = useCallback(
    (item: BudgetsTrackingItem): boolean => item.status
    === BudgetsTrackingFilterStatus.PRE_ACCEPTED,
    [],
  );

  const canInvoice = useCallback(
    (item: BudgetsTrackingItem) => item.status === BudgetsTrackingFilterStatus.SIGNED,
    [],
  );

  const canCancel = useCallback(
    (item: BudgetsTrackingItem): boolean => item.status === BudgetsTrackingFilterStatus.PENDING,
    [],
  );

  const canDuplicate = useCallback(
    (item: BudgetsTrackingItem): boolean => item.status === BudgetsTrackingFilterStatus.PENDING,
    [],
  );

  const canEmail = useCallback(
    (item: BudgetsTrackingItem): boolean => item.status === BudgetsTrackingFilterStatus.SIGNED
    || item.status === BudgetsTrackingFilterStatus.INVOICED,
    [],
  );

  const canBudgetBackward = useCallback(
    (item: BudgetsTrackingItem) => item.canBackward,
    [],
  );

  return {
    canAccept,
    canAssign,
    canBudgetBackward,
    canCancel,
    canDuplicate,
    canEmail,
    canInvoice,
    canPrint: () => true,
    canSign,
    canView,
  };
};
