import { Middleware } from 'redux';

import { saveBudget } from '../budgetActions';
import { BudgetActionTypes } from '../budgetActions/types';

export const PersistBudgetWhenDiscardedArticlesChange: Middleware = (
  storeApi,
) => (next) => async (action) => {
  if (action.type === BudgetActionTypes.ADD_DISCARDED_ARTICLE
    || action.type === BudgetActionTypes.REMOVE_DISCARDED_ARTICLE) {
    const result = next(action);

    storeApi.dispatch(saveBudget({
      callBack: () => false,
    }));

    return result;
  }

  return next(action);
};
