import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import config from 'config';

import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from 'utils/toasts';

import FormPage from 'components/base/FormPage';

import { cleanFormAfterSubmit, getRoleFormFields } from 'components/role/Form';

import UserRole, {
  Permission,
  PermissionGroupDto,
  RoleForm as RoleFormType,
} from 'models/UserRole';

import './index.scss';
import { addRoles } from '../../../../actions/roles';

type PageParams = {
  id: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const roleFormInitialValues: any = {
  description: '',
  name: '',
  permissions: [],
  readOnly: false,
};

const RoleForm: FC = () => {
  const [editing, setEditing] = useState(false);
  const [clone, setClone] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams<PageParams>();
  const { t } = useTranslation();

  useEffect(() => {
    const edit = /^\/roles\/[0-9]+\/(edit)/gi;
    if (window.location.pathname.match(edit)) {
      setEditing(true);
    }

    const cloneRegex = /^\/roles\/[0-9]+\/(clone)/gi;
    if (window.location.pathname.match(cloneRegex)) {
      setEditing(true);
      setClone(true);
    }
  }, []);

  const handleError = (error: AxiosError) => {
    if (error?.response?.status === 503) {
      showWarningToast(t('role.fakeEmail'));
      setRedirect(true);
    } else {
      showErrorToast(
        error.code === '509' ? t('role.duplicated') : t('role.saveKo'),
      );
    }
  };

  const handleFormat = (
    data: RoleFormType,
    isEditing: boolean,
    isClone: boolean,
  ) => {
    if (isClone) {
      return { ...data, ...{ name: '' } };
    }
    return data;
  };

  if (redirect) {
    return <Redirect to={config.url.roles} />;
  }

  const onSave = () => {
    dispatch(addRoles());
    showSuccessToast(t('role.saveOk'));
  };

  const validation = (values: RoleFormType) => {
    const allPermissions: Permission[] = [];
    values.permissions.forEach((permissionGroup: PermissionGroupDto) => {
      permissionGroup.permissionDtos.forEach((permission: Permission) => {
        allPermissions.push(permission);
      });
    });

    const valuesModified: UserRole = { ...values, permissions: allPermissions };
    return config.validator(valuesModified, 'userRole');
  };

  return (
    <div id="role-form-page">
      <FormPage<RoleFormType>
        backUrl={config.url.roles}
        cleanBeforeSubmit={cleanFormAfterSubmit}
        clone={clone}
        editing={editing}
        fields={getRoleFormFields}
        formatReceived={handleFormat}
        getFunction={config.apiFunus.roles.retrieveRoleAndPermissions}
        id={id}
        initialValues={
          id && id !== 'add'
            ? roleFormInitialValues
            : { ...roleFormInitialValues }
        }
        saveFunction={config.apiFunus.roles.postRole}
        title={{
          add: t('user.role.new'),
          clone: t('user.role.clone'),
          edit: t('user.role.edit'),
          see: t('user.role.detail'),
        }}
        updateFunction={config.apiFunus.roles.editRole}
        validateForm={(values: RoleFormType) => validation(values)}
        validateOnBlur
        onError={handleError}
        onSave={onSave}
      />
    </div>
  );
};

export default RoleForm;
