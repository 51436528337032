import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

import Button from '../Button';
import './index.scss';
import Input from '../Input';

export type PhotoCameraProps = {
  onConfirmPhoto?: (base64Capture: string) => void;
}

const PhotoCamera: FC<PhotoCameraProps> = ({ onConfirmPhoto }) => {
  const { t } = useTranslation();
  const webcamRef = React.useRef(null);
  const [capture, setCapture] = useState<string | null>(null);
  const [frontCamera, setFrontCamera] = useState<boolean>(false);
  const [facingMode, setfacingMode] = useState<{exact: string} | undefined>({ exact: 'environment' });

  useEffect(
    () => {
      if (frontCamera) {
        setfacingMode(undefined);
      } else {
        setfacingMode({ exact: 'environment' });
      }
    },
    [frontCamera],
  );

  const start = () => {
    setCapture(null);
  };

  const sendPhoto = useCallback(
    () => {
      if (capture) {
        onConfirmPhoto?.(capture);
        setCapture(null);
      }
    },
    [capture, onConfirmPhoto],
  );

  const takePhoto = useCallback(
    () => {
      if (webcamRef) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setCapture((webcamRef.current as any).getScreenshot());
      }
    },
    [webcamRef],
  );

  return (
    <div className="photo-camera">
      {!capture
        && (
        <React.Fragment>
          <div className="button-group">
            <Input
              checked={frontCamera}
              name="front-camera"
              placeholder={t('common.frontCamera')}
              type="checkbox"
              onChange={() => {
                setFrontCamera(!frontCamera);
              }}
            />
          </div>
          <Webcam
            ref={webcamRef}
            audio={false}
            className="photo-camera-canvas"
            height="fit-content"
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode,
            }}
            width="100%"
          />
          <div className="button-group">
            <Button
              className="photo-camera-button"
              text={t('article.photo.take')}
              onClick={takePhoto}
            />
          </div>
        </React.Fragment>
        )}

      {capture
      && (
      <React.Fragment>
        <img alt="capture" src={capture} />
        <div className="button-group">
          <Button
            className="photo-camera-button"
            text={t('article.photo.repeat')}
            onClick={start}
          />
          <Button
            className="photo-camera-button"
            text={t('article.photo.confirm')}
            onClick={sendPhoto}
          />
        </div>
      </React.Fragment>
      )}
    </div>
  );
};

PhotoCamera.defaultProps = {
  onConfirmPhoto: () => false,
};

export { PhotoCamera };
