import { Middleware } from 'redux';

import { showErrorToast } from 'utils/toasts';

import { i18n } from 'i18n';
import { budgetController } from 'modules/budget/api';

import { LoadBudget } from '../budgetActions';
import { BudgetActionTypes, SetArticlePricePayload } from '../budgetActions/types';
import { BudgetState } from '../budgetReducer/types';

export const PersistWhenSetArticlePrice: Middleware = (storeApi) => (next) => async (action) => {
  if (action.type === BudgetActionTypes.SET_ARTICLE_PRICE) {
    const result = next(action);

    const { articleId, articleType, price }: SetArticlePricePayload = action.payload;
    const storedBudget = storeApi.getState().budget as BudgetState;

    const put = async () => budgetController.SetBudgetArticlePriceAmount(
      articleId,
      articleType,
      {
        price,
      },
    );

    try {
      await put();
      if (storedBudget.id) {
        storeApi.dispatch(LoadBudget({
          id: storedBudget.id,
        }));
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrorToast(i18n.t(error.message));
    }

    return result;
  }

  return next(action);
};
