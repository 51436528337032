import React, {
  Dispatch, FC, SetStateAction, useState,
} from 'react';
import { CameraOff, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';

import Button from 'components/base/Button';

import './index.scss';

type QRScanProps = {
  onHide: () => void;
  setData: Dispatch<SetStateAction<string>>;
  showClose?: boolean;
};

const QRScan: FC<QRScanProps> = ({ onHide, setData, showClose = true }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const handleScan = (data: string | null) => {
    if (data) {
      setData(data);
      onHide();
    }
  };

  const handleError = (err: Error) => setError(err.toString());

  return (
    <div className="qr-scan">
      <div className="scanner">
        {showClose && (
          <Button className="close-icon" color="transparent" onClick={onHide}>
            <X />
          </Button>
        )}
        <QrReader delay={100} onError={handleError} onScan={handleScan} />
        {error && <CameraOff className="camera-icon" />}
      </div>
      {error && (
        <p className="color-error">
          {`${t('common.error')}: `}
          <strong>{error}</strong>
        </p>
      )}
    </div>
  );
};

export default QRScan;
