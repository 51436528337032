import axios, { AxiosError, AxiosResponse } from 'axios';
import { FormikErrors, FormikValues } from 'formik';
import { i18n } from 'i18n';
import set from 'lodash/set';

const StatusCode = {
  Unauthorized: 401,
  Forbidden: 403,
  UnprocessableEntity: 422,
  TooManyRequests: 429,
  InternalServerError: 500,
};

/* ~~~~~~~~~~ RESPONSE ~~~~~~~~~~ */

const onFulfilledResponse = (response: AxiosResponse) => {
  return response;
};

const onRejectedResponse = (error: AxiosError) => {
  if (error?.response?.status) {
    if (error.response.status === StatusCode.Unauthorized) {
      // TODO: Handle
    } else if (error.response.status === StatusCode.Forbidden) {
      // TODO: Handle
    } else if (error.response.status === StatusCode.UnprocessableEntity) {
      // TODO: Handle
    } else if (error.response.status === StatusCode.TooManyRequests) {
      // TODO: Handle
    } else if (error.response.status === StatusCode.InternalServerError) {
      // TODO: Handle
    }
  }

  return Promise.reject(error);
};

/* ~~~~~~~~~~ AXIOS INSTANCE ~~~~~~~~~~ */
const validatorInstance = axios.create({
  baseURL: process.env.REACT_APP_VALIDATOR_API_URL,
});

validatorInstance.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

const validator = <T>(values: T, schemaType: string): Promise<FormikErrors<FormikValues>> =>
  validatorInstance.post('/validate', { values, schemaType }).then(({ data: { errors } }) => {
    const res = Object.keys(errors).reduce((formErrors, currentField) => {
      return {
        ...formErrors,
        ...set(
          formErrors,
          currentField,
          i18n.t(`validationRules.${errors[currentField].key}`, {
            ...errors[currentField].values,
          }),
        ),
      };
    }, {});
    return res;
  });

export { validatorInstance };
export default validator;
