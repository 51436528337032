import { HttpClient } from 'config/apiFunus/generated/http-client';
import axiosInstance from 'config/axios';

import { BudgetClient } from './BudgetClient';
import { BudgetMasterDataClient } from './budgetMasterDataClient';

const httpClient = new HttpClient();
httpClient.instance = axiosInstance;

export const budgetController = new BudgetClient(httpClient);
export const budgetMasterDataClient = new BudgetMasterDataClient(httpClient);
