import React, { FC, useMemo, useState } from 'react';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { AffectedQuestionsInfoModal } from './AffectedQuestionsInfoModal';

import './index.scss';

interface AffectedQuestionsComponent {
  question: BudgetQuestionCodes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: any;
}

export const AffectedQuestions: FC<AffectedQuestionsComponent> = ({ question, response }) => {
  const { GetQuestionsAffectedByResponseChange } = useBudget();
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const affectedQuestions = useMemo(
    () => GetQuestionsAffectedByResponseChange({
      question,
      response,
    }),
    [GetQuestionsAffectedByResponseChange, question, response],
  );

  return (
    <div className="f-affected-questions">
      {affectedQuestions.length !== 0
        ? (
          <Button onClick={() => setShowInfo(true)}>
            <CustomIcon icon={OtherTypes.AFFECTED_QUESTIONS_ALERT} />
          </Button>
        )
        : <div>&nbsp;</div> }
      <AffectedQuestionsInfoModal
        affectedQuestions={affectedQuestions}
        show={showInfo}
        onAccept={() => {
          setShowInfo(false);
        }}
      />
    </div>
  );
};
