import { addHours } from 'utils/dateManager';

import { Event, ExtendedEvent } from 'models/Event';

export const formatEvent = (event: Event): ExtendedEvent => {
  const start = new Date(`${event.date} ${event.time}`);
  return {
    ...event,
    resourceId: event.type,
    start,
    end: addHours(start, 1),
  };
};

export const formatEvents = (events: Event[]): ExtendedEvent[] => events.map(
  (event: Event) => formatEvent(event),
);
