import React, { FC } from 'react';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useQuestionSettings } from 'modules/budget/hooks/useQuestionSettings';

import { GalleryQuestion } from '../GalleryQuestion';

export const Q21: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q21;
  const { isMultiselect, isQuantityEnabled } = useQuestionSettings();

  return (
    <GalleryQuestion
      allowSelectQuantity={isQuantityEnabled(question)}
      multiselect={isMultiselect(question)}
      question={question}
    />
  );
};
