import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { showWarningToast } from 'utils/toasts';

import Modal from 'components/base/Modal';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { BudgetsTrackingFilterStatus } from '../table/types';

import { budgetBackwardConfirmModalComponent } from './types';

export const BudgetBackwardConfirmModal: FC<budgetBackwardConfirmModalComponent> = (
  {
    show, onCancel, onSuccess, budget,
  },
) => {
  const { t } = useTranslation();
  const { BackwardBudget } = useBudget();

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          onClick: () => {
            if (budget) {
              BackwardBudget(budget.id)
                .then(() => {
                  if (budget?.status === BudgetsTrackingFilterStatus.INVOICED) {
                    showWarningToast(t('budget.actions.backwardSuccessInvoiced'));
                  }
                  onSuccess();
                  return true;
                })
                .catch(() => false);
            }
          },
          text: t('common.accept'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.actions.accept')}
      onHide={() => false}
    >
      <div>{t(`budget.actions.backwardConfirm.${budget?.status}`)}</div>
    </Modal>
  );
};
