import axios from 'config/axios';
import { ApiFunusDocuments } from './types';

export const documents: ApiFunusDocuments = {
  download: (nodeId) => axios.get(`${process.env.REACT_APP_API_URL}/alfresco/document/${nodeId}`),
  remove: (nodeId) => axios.delete(`/alfresco/document/${nodeId}`),
  upload: (erpId, file) => axios.post(`/alfresco/document/${erpId}`, file, {
    headers: { 'content-type': 'multipart/form-data' },
  }),
};
