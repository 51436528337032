import { difference } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { budgetController } from 'modules/budget/api';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { CeremonyTypes } from 'modules/budget/data/ceremonyTypeCodes';
import { sortedQuestions } from 'modules/budget/data/sortedQuestions';
import { BudgetHelper } from 'modules/budget/helpers/BudgetHelper';
import { IArticle, ArticleCollectionTypes, ArticleType } from 'modules/budget/models/Article/types';
import { BudgetResponseCodes } from 'modules/budget/models/BudgetQuestion/types';
import {
  AddDiscardedArticle,
  AnswerQuestion,
  ClearArticlesList,
  ClearQuestion,
  GetInvoiceArticles,
  GoBack,
  GotoSummary,
  LoadBudget,
  RemoveDiscardedArticle,
  ResetWizard,
  SetArticleAmount,
  SetArticleClient,
  SetArticleClientBulk,
  SetArticlePrice,
  SetArticleProvider,
  SetCurrentQuestion,
} from 'modules/budget/store/budgetActions';
import { BudgetState } from 'modules/budget/store/budgetReducer/types';
import {
  dismissServiceId, ServiceTypes, NicheOwnershipTypes,
} from 'modules/budget/store/data';
import { RootState } from 'store';

import { Budget } from '../../models/Budget';

import { AssignRecordParams, BudgetHook, RespondeQuestionParams } from './types';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useBudget = (): BudgetHook => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [invoiceBudgetLoading, setinvoiceBudgetLoading] = useState<boolean>(false);

  const {
    currentQuestion,
    articles,
    questions,
    id,
    idVersion,
    articlesLoading,
    budgetLoading,
    idRecord,
    collected,
    discardedArticles,
    invoiceArticles,
    invoiceArticlesLoading,
    invoiceClients,
    summarize,
    summarizeLoading,
  } = useSelector((state: RootState) => state.budget as BudgetState);

  const budget = useSelector((state: RootState) => {
    const storedBudget = state.budget as BudgetState;
    return new Budget({
      automaticArticles: storedBudget.automaticArticles,
      collected: storedBudget.collected,
      createdBy: storedBudget.createdBy,
      createdDate: storedBudget.createdDate,
      discardedArticles: storedBudget.discardedArticles,
      id: storedBudget.id,
      idRecord: storedBudget.idRecord,
      idVersion: storedBudget.idVersion,
      questions: storedBudget.questions,
      status: storedBudget.status,
    });
  });

  const GetQuestionValue = useCallback(
    (question: BudgetQuestionCodes) => {
      const Q1 = (questionCode: BudgetQuestionCodes) => {
        const q1response = questions[questionCode]?.response;
        if (q1response === BudgetResponseCodes.R1_1) {
          return ServiceTypes.DEFUNCIO;
        } if (q1response === BudgetResponseCodes.R1_2) {
          return ServiceTypes.AMPUTACIO;
        } if (q1response === BudgetResponseCodes.R1_3) {
          return ServiceTypes.AVORTAMENT;
        } if (q1response === BudgetResponseCodes.R1_4) {
          return ServiceTypes.DESPULLES;
        } if (q1response === BudgetResponseCodes.R1_5) {
          return ServiceTypes.INHUMACIO_CENDRES;
        }

        return null;
      };

      const Q3 = (questionCode: BudgetQuestionCodes) => {
        const q3response = questions[questionCode]?.response;
        const location = questions[questionCode]?.location;
        const atHome = questions[questionCode]?.atHome;
        if (q3response === BudgetResponseCodes.R3_1 || q3response === BudgetResponseCodes.R3_2) {
          return {
            address: {
              city: location?.city,
              country: location?.country,
              province: location?.province,
              work: q3response === BudgetResponseCodes.R3_1,
            },
            atHome,
            location: {
              id: location?.location,
            },
          };
        }

        return null;
      };

      const Q4 = (questionCode: BudgetQuestionCodes) => {
        const q4response = questions[questionCode]?.response;
        const insuranceCode = questions[questionCode]?.insuranceCode;

        if (q4response === BudgetResponseCodes.R4_2) {
          return { id: insuranceCode };
        } if (q4response === BudgetResponseCodes.R4_1) {
          return dismissServiceId;
        }

        return null;
      };

      const Q22 = (questionCode: BudgetQuestionCodes) => {
        const response = questions[questionCode]?.response;
        const responses: Record<string, unknown> = {
          [BudgetResponseCodes.R22_1]: CeremonyTypes.CATOLIC,
          [BudgetResponseCodes.R22_2]: CeremonyTypes.OTHER,
          [BudgetResponseCodes.R22_3]: CeremonyTypes.CIVIL,
        };

        return response && responses[response]
          ? responses[response]
          : null;
      };

      const getArticles = (questionCode: BudgetQuestionCodes) => {
        const articleCollections = questions[questionCode]?.articles;
        const storedArticles = articleCollections
          ? articleCollections[ArticleCollectionTypes.ARTICLE]
          : [];
        const taxes = articleCollections
          ? articleCollections[ArticleCollectionTypes.TAX]
          : [];
        const concessions = articleCollections
          ? articleCollections[ArticleCollectionTypes.CONCESSION]
          : [];
        const transfers = articleCollections
          ? articleCollections[ArticleCollectionTypes.TRANSFER]
          : [];

        return {
          articles: storedArticles,
          concessions,
          taxes,
          transfers,
        };
      };

      const getAddressLocation = (questionCode: BudgetQuestionCodes) => {
        const storeLocation = questions[questionCode]?.location;
        const address = {
          city: storeLocation?.city,
          country: storeLocation?.country,
          province: storeLocation?.province,
        };
        const location = {
          id: storeLocation?.location,
        };

        return {
          address,
          location,
        };
      };

      const Articles = (questionCode: BudgetQuestionCodes, responses: Record<string, unknown>) => {
        const response = questions[questionCode]?.response;

        return response && responses[response]
          ? responses[response]
          : null;
      };

      const YesNo = (questionCode: BudgetQuestionCodes, responses: Record<string, unknown>) => {
        const response = questions[questionCode]?.response;

        return response && responses[response] ? responses[response] : null;
      };

      const AddressLocation = (
        questionCode: BudgetQuestionCodes,
        responses: Record<string, unknown>,
      ) => {
        const response = questions[questionCode]?.response;

        return response && responses[response] ? responses[response] : null;
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataCollector: any = {
        [BudgetQuestionCodes.Q1]: Q1,

        [BudgetQuestionCodes.Q2]:
          (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
            [BudgetResponseCodes.R2_1]: { yesNo: true },
            [BudgetResponseCodes.R2_2]: { yesNo: false },
          }),

        [BudgetQuestionCodes.Q3]: Q3,

        [BudgetQuestionCodes.Q4]: Q4,

        [BudgetQuestionCodes.Q5]:
          (questionCode: BudgetQuestionCodes) => {
            const response = questions[questionCode]?.response;
            const { address, location } = getAddressLocation(questionCode);
            let addressWork: boolean | undefined;
            let locationWork: boolean | undefined;
            if (response === BudgetResponseCodes.R5_1) {
              addressWork = true;
              locationWork = true;
            } else if (response === BudgetResponseCodes.R5_2) {
              addressWork = true;
              locationWork = false;
            } else if (response === BudgetResponseCodes.R5_3) {
              addressWork = false;
            }

            const responseData = {
              address: {
                ...address,
                work: addressWork,
              },
              location: {
                ...location,
                work: locationWork,
              },
            };
            return AddressLocation(questionCode, {
              [BudgetResponseCodes.R5_1]: responseData,
              [BudgetResponseCodes.R5_2]: responseData,
              [BudgetResponseCodes.R5_3]: responseData,
              [BudgetResponseCodes.R5_4]: dismissServiceId,
            });
          },

        [BudgetQuestionCodes.Q6]:
          (questionCode: BudgetQuestionCodes) => {
            const response = questions[questionCode]?.response;
            const { address, location } = getAddressLocation(questionCode);
            let addressWork: boolean | undefined;
            let locationWork: boolean | undefined;
            if (response === BudgetResponseCodes.R6_1) {
              addressWork = true;
              locationWork = true;
            } else if (response === BudgetResponseCodes.R6_2) {
              addressWork = true;
              locationWork = false;
            } else if (response === BudgetResponseCodes.R6_3) {
              addressWork = false;
            }

            const responseData = {
              address: {
                ...address,
                work: addressWork,
              },
              location: {
                ...location,
                work: locationWork,
              },
            };

            return AddressLocation(questionCode, {
              [BudgetResponseCodes.R6_1]: responseData,
              [BudgetResponseCodes.R6_2]: responseData,
              [BudgetResponseCodes.R6_3]: responseData,
              [BudgetResponseCodes.R6_4]: dismissServiceId,
            });
          },

        [BudgetQuestionCodes.Q7]:
          (questionCode: BudgetQuestionCodes) => {
            const response = questions[questionCode]?.response;
            const { address, location } = getAddressLocation(questionCode);
            let addressWork: boolean | undefined;
            let locationWork: boolean | undefined;
            if (response === BudgetResponseCodes.R7_1) {
              addressWork = true;
              locationWork = true;
            } else if (response === BudgetResponseCodes.R7_2) {
              addressWork = true;
              locationWork = false;
            } else if (response === BudgetResponseCodes.R7_3) {
              addressWork = false;
            }

            const responseData = {
              address: {
                ...address,
                work: addressWork,
              },
              location: {
                ...location,
                work: locationWork,
              },
            };

            return AddressLocation(questionCode, {
              [BudgetResponseCodes.R7_1]: responseData,
              [BudgetResponseCodes.R7_2]: responseData,
              [BudgetResponseCodes.R7_3]: responseData,
              [BudgetResponseCodes.R7_4]: dismissServiceId,
            });
          },

        [BudgetQuestionCodes.Q8]:
          (questionCode: BudgetQuestionCodes) => {
            const response = questions[questionCode]?.response;

            const { address, location } = getAddressLocation(questionCode);
            let addressWork: boolean | undefined;
            let locationWork: boolean | undefined;
            if (response === BudgetResponseCodes.R8_1) {
              addressWork = true;
              locationWork = true;
            } else if (response === BudgetResponseCodes.R8_2) {
              addressWork = true;
              locationWork = false;
            } else if (response === BudgetResponseCodes.R8_3) {
              addressWork = false;
            }

            const responseData = {
              address: {
                ...address,
                work: addressWork,
              },
              location: {
                ...location,
                work: locationWork,
              },
            };

            return AddressLocation(questionCode, {
              [BudgetResponseCodes.R8_1]: responseData,
              [BudgetResponseCodes.R8_2]: responseData,
              [BudgetResponseCodes.R8_3]: responseData,
              [BudgetResponseCodes.R8_4]: dismissServiceId,
            });
          },

        [BudgetQuestionCodes.Q9]:
          (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
            [BudgetResponseCodes.R9_1]: { yesNo: true },
            [BudgetResponseCodes.R9_2]: { yesNo: false },
          }),

        [BudgetQuestionCodes.Q10]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R10_1]: { articles: storedArticles },
              [BudgetResponseCodes.R10_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q11]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R11_1]: { articles: storedArticles },
              [BudgetResponseCodes.R11_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q12]: (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
          [BudgetResponseCodes.R12_1]: { yesNo: true },
          [BudgetResponseCodes.R12_2]: { yesNo: false },
        }),

        [BudgetQuestionCodes.Q13]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R13_1]: { articles: storedArticles },
              [BudgetResponseCodes.R13_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q14]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R14_1]: { articles: storedArticles },
              [BudgetResponseCodes.R14_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q15]:
           (questionCode: BudgetQuestionCodes) => {
             const { articles: storedArticles } = getArticles(questionCode);

             return Articles(questionCode, {
               [BudgetResponseCodes.R15_1]: { articles: storedArticles },
               [BudgetResponseCodes.R15_2]: { articles: [] },
             });
           },

        [BudgetQuestionCodes.Q17]:
           (questionCode: BudgetQuestionCodes) => {
             const { address } = getAddressLocation(questionCode);

             return AddressLocation(questionCode, {
               [BudgetResponseCodes.R17_1]: { address },
               [BudgetResponseCodes.R17_2]: dismissServiceId,
             });
           },

        [BudgetQuestionCodes.Q19]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R19_1]: { articles: storedArticles },
              [BudgetResponseCodes.R19_2]: dismissServiceId,
            });
          },

        [BudgetQuestionCodes.Q21]:
        (questionCode: BudgetQuestionCodes) => {
          const { articles: storedArticles } = getArticles(questionCode);

          return Articles(questionCode, {
            [BudgetResponseCodes.R21_1]: { articles: storedArticles },
            [BudgetResponseCodes.R21_2]: { articles: [] },
          });
        },

        [BudgetQuestionCodes.Q22]: Q22,

        [BudgetQuestionCodes.Q23]:
          (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
            [BudgetResponseCodes.R23_1]: { yesNo: true },
            [BudgetResponseCodes.R23_2]: { yesNo: false },
          }),

        [BudgetQuestionCodes.Q24]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R24_1]: { articles: storedArticles },
              [BudgetResponseCodes.R24_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q25]:
          (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
            [BudgetResponseCodes.R25_1]: { yesNo: true },
            [BudgetResponseCodes.R25_2]: { yesNo: false },
          }),

        [BudgetQuestionCodes.Q27]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R27_1]: { articles: storedArticles },
              [BudgetResponseCodes.R27_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q28]:
          (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
            [BudgetResponseCodes.R28_1]: { yesNo: true },
            [BudgetResponseCodes.R28_2]: { yesNo: false },
          }),

        [BudgetQuestionCodes.Q29]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R29_1]: { articles: storedArticles },
              [BudgetResponseCodes.R29_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q30]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R30_1]: { articles: storedArticles },
              [BudgetResponseCodes.R30_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q31]:
          (questionCode: BudgetQuestionCodes) => {
            const { concessions, taxes, transfers } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R31_1]: {
                concessions,
                nameChange: false,
                nicheType: NicheOwnershipTypes.NEW,
                taxes,
              },
              [BudgetResponseCodes.R31_2]: {
                nameChange: false,
                nicheType: NicheOwnershipTypes.OLD,
                taxes,
              },
              [BudgetResponseCodes.R31_3]: {
                nameChange: true,
                nicheType: NicheOwnershipTypes.OLD,
                taxes,
                transfers,
              },
            });
          },

        [BudgetQuestionCodes.Q36]:
          (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
            [BudgetResponseCodes.R36_1]: { yesNo: true },
            [BudgetResponseCodes.R36_2]: { yesNo: false },
          }),

        [BudgetQuestionCodes.Q38]:
           (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
             [BudgetResponseCodes.R38_1]: { yesNo: true },
             [BudgetResponseCodes.R38_2]: { yesNo: false },
           }),

        [BudgetQuestionCodes.Q39]:
          (questionCode: BudgetQuestionCodes) => {
            const { address, location } = getAddressLocation(questionCode);

            return AddressLocation(questionCode, {
              [BudgetResponseCodes.R39_1]: {
                address,
                location,
              },
            });
          },

        [BudgetQuestionCodes.Q40]:
          (questionCode: BudgetQuestionCodes) => YesNo(questionCode, {
            [BudgetResponseCodes.R40_1]: { yesNo: true },
            [BudgetResponseCodes.R40_2]: { yesNo: false },
          }),

        [BudgetQuestionCodes.Q42]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R42_1]: { articles: storedArticles },
              [BudgetResponseCodes.R42_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q43]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R43_1]: { articles: storedArticles },
              [BudgetResponseCodes.R43_2]: { articles: [] },
            });
          },

        [BudgetQuestionCodes.Q44]:
          (questionCode: BudgetQuestionCodes) => {
            const { articles: storedArticles } = getArticles(questionCode);

            return Articles(questionCode, {
              [BudgetResponseCodes.R44_1]: { articles: storedArticles },
              [BudgetResponseCodes.R44_2]: { articles: [] },
            });
          },

      };

      return dataCollector[question as BudgetQuestionCodes](question);
    },
    [questions],
  );

  const GetQuestionsAffectedByResponseChange = useCallback(
    ({ question, response }: RespondeQuestionParams) => {
      const budgetHelper = new BudgetHelper(sortedQuestions);
      const currentQuestionList = budgetHelper.availableQuestions(budget.questions);
      const updatedQuestionList = budgetHelper.availableQuestions(budgetHelper
        .getUpdatedQuestionCollection(budget.questions, question, response));
      const r = difference(currentQuestionList, updatedQuestionList)
        .filter((questionCode) => GetQuestionValue(questionCode) !== null);
      return r;
    },
    [GetQuestionValue, budget.questions],
  );

  const RespondQuestion = useCallback(
    ({ question, response, gotoSummary }: RespondeQuestionParams) => {
      GetQuestionsAffectedByResponseChange({
        question,
        response,
      }).forEach((questionCode) => {
        dispatch(ClearQuestion({ questionCode }));
      });

      dispatch(AnswerQuestion({
        gotoSummary,
        question,
        response,
      }));
    },
    [GetQuestionsAffectedByResponseChange, dispatch],
  );

  const GoPrevQuestion = useCallback(
    () => {
      dispatch(GoBack());
    },
    [dispatch],
  );

  const Reset = useCallback(
    () => {
      dispatch(ResetWizard());
    },
    [dispatch],
  );

  const GetGalleryAmountStep = (questionCode: BudgetQuestionCodes): number => {
    if (questionCode === BudgetQuestionCodes.Q15) return 50;
    return 1;
  };

  const DuplicateBudget = useCallback(
    (erpId: number) => budgetController.DuplicateBudget(erpId)
      .then(() => {
        showSuccessToast(t('budget.actions.duplicateSuccess'));
        return {};
      })
      .catch((error) => {
        showErrorToast(error.message);
      }),
    [t],
  );

  const InvoiceBudget = useCallback(
    (erpId: number) => {
      setinvoiceBudgetLoading(true);

      return budgetController.InvoiceBudget(erpId)
        .then(() => {
          showSuccessToast(t('budget.actions.invoiceSuccess'));
          return {};
        })
        .catch((error) => {
          showErrorToast(error.message);
          throw error;
        })
        .finally(() => {
          setinvoiceBudgetLoading(false);
        });
    },

    [t],
  );

  const AcceptBudget = useCallback(
    (erpId: number) => budgetController.AcceptBudget(erpId)
      .then(() => {
        showSuccessToast(t('budget.actions.acceptSuccess'));
        return {};
      })
      .catch((error) => {
        showErrorToast(error.message);
      }),
    [t],
  );

  const AssignRecord = ({ id: budgetId, erpId }: AssignRecordParams) => budgetController
    .AssignRecord(Number(budgetId), Number(erpId))
    .then((response) => {
      showSuccessToast(t('budget.actions.assignSuccess'));
      return response;
    })
    .catch((error) => {
      showErrorToast(error.message);
    });

  const GetBudget = useCallback(
    (budgetId: number): void => {
      dispatch(LoadBudget({ id: budgetId }));
    },
    [dispatch],
  );

  const GoToQuestion = useCallback(
    (questionCode: BudgetQuestionCodes): void => {
      dispatch(SetCurrentQuestion({ questionCode }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const GoToSummary = useCallback(
    (): void => {
      dispatch(GotoSummary());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const CancelBudget = useCallback(
    (erpId: number) => budgetController.CancelBudget(erpId)
      .then(() => {
        showSuccessToast(t('budget.actions.cancelSuccess'));
        return {};
      })
      .catch((error) => {
        showErrorToast(error.message);
        throw error;
      }),
    [t],
  );

  const BackwardBudget = useCallback(
    (erpId: number) => budgetController.BackwardBudget(erpId)
      .then(() => {
        showSuccessToast(t('budget.actions.backwardSuccess'));
        return {};
      })
      .catch((error) => {
        showErrorToast(error.message);
        throw error;
      }),
    [t],
  );

  const SendBudget = useCallback(
    (
      { budgetId, emails }: {budgetId: number, emails: Array<string>},
    ) => budgetController.SendBudget(budgetId, emails),
    [],
  );

  const SignBudget = useCallback(
    (erpId: number, signature) => budgetController
      .SignBudget(erpId, signature)
      .then(() => {
        showSuccessToast(t('budget.actions.signSuccess'));
        return {};
      })
      .catch((error) => {
        showErrorToast(error.message);
        throw new Error(error);
      }),
    [t],
  );

  const SetInvoiceArticleProvider = useCallback(
    (article, provider, idBudget) => {
      dispatch(SetArticleProvider({
        article,
        idBudget,
        provider,
      }));
    },
    [dispatch],
  );

  const SetInvoiceArticleClient = useCallback(
    (article, client, idBudget) => {
      dispatch(SetArticleClient({
        article,
        client,
        idBudget,
      }));
    },
    [dispatch],
  );

  const SetInvoiceArticleClientBulk = useCallback(
    (a, client, idBudget) => {
      dispatch(SetArticleClientBulk({
        articles: a,
        client,
        idBudget,
      }));
    },
    [dispatch],
  );
  const SetPriceForSingleArticle = useCallback(
    (
      articleId: number,
      articleType: ArticleType,
      price: number,
    ) => {
      dispatch(SetArticlePrice({
        articleId,
        articleType,
        price,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const SetAmountForSingleArticle = useCallback(
    (
      articleId: number,
      articleType: ArticleType,
      amount: number,
    ) => {
      dispatch(SetArticleAmount({
        amount,
        articleId,
        articleType,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const DiscardArticle = useCallback(
    (article: IArticle) => {
      dispatch(AddDiscardedArticle({ article }));
    },
    [dispatch],
  );

  const RestoreDiscardedArticle = useCallback(
    (article: IArticle) => {
      dispatch(RemoveDiscardedArticle({ article }));
    },
    [dispatch],
  );

  const fetchInvoiceArticles = (idBudget: number) => {
    dispatch(GetInvoiceArticles({ idBudget }));
  };

  const clearArticles = () => {
    dispatch(ClearArticlesList());
  };

  return {
    AcceptBudget,
    articles,
    articlesLoading,
    AssignRecord,
    BackwardBudget,
    budget,
    budgetLoading,
    CancelBudget,
    clearArticles,
    collected,
    currentQuestion,
    DiscardArticle,
    discardedArticles,
    DuplicateBudget,
    fetchInvoiceArticles,
    GetBudget,
    GetGalleryAmountStep,
    GetQuestionsAffectedByResponseChange,
    GetQuestionValue,
    GoPrevQuestion,
    GoToQuestion,
    GoToSummary,
    id,
    idRecord,
    idVersion,
    invoiceArticles,
    invoiceArticlesLoading,
    InvoiceBudget,
    invoiceBudgetLoading,
    invoiceClients,
    questions,
    Reset,
    RespondQuestion,
    RestoreDiscardedArticle,
    SendBudget,
    SetArticleAmount: SetAmountForSingleArticle,
    SetArticlePrice: SetPriceForSingleArticle,
    SetInvoiceArticleClient,
    SetInvoiceArticleClientBulk,
    SetInvoiceArticleProvider,
    SignBudget,
    summarize,
    summarizeLoading,
  };
};
