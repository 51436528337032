import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

export const useQuestionLabels = (): {
  yes: (question: BudgetQuestionCodes) => string;
  no: (question: BudgetQuestionCodes) => string;
  dismiss: (question: BudgetQuestionCodes) => string;
} => {
  const { t } = useTranslation();

  const no = useCallback(
    (question) => (t(`budget.wizard.no.${question}`) !== `budget.wizard.no.${question}`
      ? t(`budget.wizard.no.${question}`)
      : t('common.no')),
    [t],
  );

  const yes = useCallback(
    (question) => (t(`budget.wizard.yes.${question}`) !== `budget.wizard.yes.${question}`
      ? t(`budget.wizard.yes.${question}`)
      : t('common.yes')),
    [t],
  );

  const dismiss = useCallback(
    (question) => (t(`budget.wizard.dismissItemCaption.${question}`) !== `budget.wizard.dismissItemCaption.${question}`
      ? t(`budget.wizard.dismissItemCaption.${question}`)
      : t('budget.wizard.commonDismiss')),
    [t],
  );

  return {
    dismiss,
    no,
    yes,
  };
};
