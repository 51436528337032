import React, { FC } from 'react';

import DefaultInfo, { DefaultInfoProps } from './Default';

const EnquiryInfo: FC<DefaultInfoProps> = ({
  expanded,
  loading,
  enquiry,
}) => <DefaultInfo enquiry={enquiry} expanded={expanded} loading={loading} />;

export default EnquiryInfo;
