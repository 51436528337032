import {
  useMemo, useState,
} from 'react';

import { StepPositions, StepsHook } from './types';

export const useSteps = (): StepsHook => {
  const [currentStep, setCurrentStep] = useState<StepPositions>(StepPositions.FIRST);

  const setStep = (newStep: StepPositions) => {
    setCurrentStep(newStep);
  };

  const isFirstStep = useMemo(() => currentStep === StepPositions.FIRST, [currentStep]);
  const isSecondStep = useMemo(() => currentStep === StepPositions.SECOND, [currentStep]);
  const isThirdStep = useMemo(() => currentStep === StepPositions.THIRD, [currentStep]);

  const reset = () => {
    setStep(StepPositions.FIRST);
  };

  return {
    currentStep,
    isFirstStep,
    isSecondStep,
    isThirdStep,
    reset,
    setStep,
  };
};
