import { isNumber } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

// eslint-disable-next-line import/no-cycle
import config from 'config';

import { normalizeFunction, normalizeMasterData } from 'utils/normalizer';
import { showErrorToast } from 'utils/toasts';

import { BlocksEnum } from 'config/apiFunus/generated/data-contracts';
// eslint-disable-next-line import/no-cycle
import { ArticlesEnum } from 'hooks/ArticlesEnum';
import { i18n } from 'i18n';
import {
  CityType,
  CemeteryType,
  CrematoryType,
  IMasterDataDto,
  ProvinceType,
} from 'models/MasterData';
import MasterService, {
  MasterAndNormalized,
  MasterDataTypes,
  NormalizedEntity,
  NormalizedType,
} from 'models/MasterService';
import { DeathType } from 'models/Record';
import { budgetMasterDataClient } from 'modules/budget/api';
import type { RootState } from 'store';
import { masterDataTypes } from 'types/masterData';

export interface MasterDataAction extends AnyAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: (MasterService | IMasterDataDto)[] | any | MasterAndNormalized;
}

let countriesRequested = false;
let provincesRequested = false;
let groupCausesRequested = false;
let citiesRequested = false;
let centersRequested = false;
let deathTypesRequested = false;
let sanitaryPracticesRequested = false;

// option for testing, maybe not passed state
export const addMasterData = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
  state,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    // accesing the state
    state();
    const res = await config.apiFunus.masterData.getMasterData();
    const masterData = res.data;
    dispatch({
      payload: masterData,
      type: masterDataTypes.ADD_MASTER_DATA,
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCeremonies = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.CEREMONY,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);

    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_CEREMONIES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getMaritalStatus = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.MARITAL_STATUS,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);

    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_MARITAL_STATUS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const clearCountries = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  countriesRequested = false;
  return dispatch({
    payload: { masterData: null, normalized: {} },
    type: masterDataTypes.ADD_MASTER_DATA_COUNTRIES,
  });
};

export const getCountries = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    if (countriesRequested) return Promise.resolve();
    countriesRequested = true;
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.COUNTRY,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_COUNTRIES,
    });
  } catch (err) {
    countriesRequested = false;
    return Promise.reject(err);
  }
};

export const clearProvinces = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  provincesRequested = false;
  return dispatch({
    payload: { masterData: null, normalized: {} },
    type: masterDataTypes.ADD_MASTER_DATA_PROVINCES,
  });
};

export const getProvinces = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    if (provincesRequested) return Promise.resolve();
    provincesRequested = true;
    const res = await config.apiFunus.masterData.getProvinceByCountry();
    const masterData: ProvinceType[] = res.data;
    const normalized: NormalizedEntity<ProvinceType> = normalizeFunction(
      masterData,
      (prov: ProvinceType) => `${prov.countryCode}${prov.code}`,
    );
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_PROVINCES,
    });
  } catch (err) {
    provincesRequested = false;
    return Promise.reject(err);
  }
};

export const clearCities = ():
// eslint-disable-next-line @typescript-eslint/no-explicit-any
ThunkAction<Promise<void>, RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  citiesRequested = false;
  return dispatch({
    payload: { masterData: null, normalized: {} },
    type: masterDataTypes.ADD_MASTER_DATA_CITIES,
  });
};

export const
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCities = (): ThunkAction<Promise<void>, RootState, any, MasterDataAction> => async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    try {
      if (citiesRequested) return Promise.resolve();
      citiesRequested = true;
      const res = await config.apiFunus.masterData.getCitiesByCountryNProvince();
      const masterData: CityType[] = res.data;
      const normalized = normalizeFunction(
        masterData,
        (city: CityType) => `${city.countryCode}${city.provinceCode}${city.code}`,
      );

      dispatch({
        payload: {
          masterData,
          normalized,
        },
        type: masterDataTypes.ADD_MASTER_DATA_CITIES,
      });
      return res.data;
    } catch (err) {
      citiesRequested = false;
      return Promise.reject(err);
    }
  };

export const getGenders = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.GENDER,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_GENDERS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getInsurances = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.INSURANCE,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_INSURANCES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTribunals = (cityCode?: string): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getTribunalByCity(cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAbortionTypes = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.ABORTION_TYPE,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_ABORTION_TYPES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCeremonyLocations = (cityCode?: string): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getCeremonyLocationsByCity(cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const clearGroupCauses = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  groupCausesRequested = false;
  return dispatch({
    payload: { masterData: null, normalized: {} },
    type: masterDataTypes.ADD_MASTER_DATA_GROUP_CAUSES,
  });
};

export const getGroupCauses = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    if (groupCausesRequested) return Promise.resolve();
    groupCausesRequested = true;
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.GROUP_CAUSE,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_GROUP_CAUSES,
    });
  } catch (err) {
    groupCausesRequested = false;
    return Promise.reject(err);
  }
};

export const getFuneraryOrigin = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.FUNERARY_ORIGIN,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_FUNERARY_ORIGINS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDeposits = (cityCode?: string): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getDepositsByCity(cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOwner = (): ThunkAction<Promise<void>, RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.OWNER,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_OWNERS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getReliquary = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.RELIQUARY,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_RELIQUARYS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUrn = (): ThunkAction<Promise<void>, RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.URN,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_URNS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const clearCenters = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  centersRequested = false;
  return dispatch({
    payload: { masterData: null, normalized: {} },
    type: masterDataTypes.ADD_MASTER_DATA_CENTERS,
  });
};

export const getCenters = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    if (centersRequested) return Promise.resolve();
    centersRequested = true;
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.CENTER,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_CENTERS,
    });
  } catch (err) {
    centersRequested = false;
    return Promise.reject(err);
  }
};

export const getSanitaryPractices = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    if (sanitaryPracticesRequested) return Promise.resolve();
    sanitaryPracticesRequested = true;
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.SANITARY_PRACTICE,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_SANITARY_PRACTICES,
    });
  } catch (err) {
    sanitaryPracticesRequested = false;
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getVigilLocations = (cityCode?: string): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getVigilLocationByCity(cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSanitaryPracticeLocations = (
  cityCode?: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getSanitaryPracticeLocationsByCity(cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getArticles = (
  erpId: number | string,
  block: BlocksEnum,
  article: ArticlesEnum,
  cityCode: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    return cityCode && erpId && block && article && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getArticles(erpId, block, article, cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDeathLocations = (cityCode?: string): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getDeathLocationByCity(cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCemeterySections = (
  cityCode?: string,
  cemeteryCode?: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    return cityCode
      && cemeteryCode
      && isNumber(Number(cityCode))
      && isNumber(Number(cemeteryCode))
      ? config.apiFunus.masterData.getCemeterySections(cityCode, cemeteryCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAshesDestination = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.ASHES_DESTINATION,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_ASHES_DESTINATIONS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getConcessionType = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.CONCESSION_TYPE,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_CONCESSION_TYPES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getNicheType = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.NICHE_TYPE,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_NICHE_TYPES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getThanatologists = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.THANATOLOGIST,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_THANATOLOGISTS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getThanatopractors = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.THANATOPRACTOR,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_THANATOPRACTORS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAirports = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.AIRPORT,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_AIRPORTS,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const clearDeathTypes = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  deathTypesRequested = false;
  return dispatch({
    payload: { masterData: null, normalized: {} },
    type: masterDataTypes.ADD_MASTER_DATA_DEATH_TYPES,
  });
};

export const getDeathTypes = (): ThunkAction<Promise<void>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    if (deathTypesRequested) return Promise.resolve();
    deathTypesRequested = true;
    const res = await config.apiFunus.masterData.getMasterDataByParam(
      MasterDataTypes.DEATH_TYPE,
    );
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_DEATH_TYPES,
    });
  } catch (err) {
    deathTypesRequested = false;
    return Promise.reject(err);
  }
};

export const getRecordTypes = (): ThunkAction<Promise<void>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const res = await config.apiFunus.masterData.getRecordTypes();
    const masterData: IMasterDataDto[] = res.data;
    const normalized: NormalizedType = normalizeMasterData(masterData);
    return dispatch({
      payload: { masterData, normalized },
      type: masterDataTypes.ADD_MASTER_DATA_RECORD_TYPES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * @description action to control load of masterData from server, by user role
 * @param role role usert to call masterData services to need by role
 * @returns void
 */
export const fillMasterData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
): Promise<void> => new Promise((resolve, reject) => {
  config.storage.setVersion();
  config.storage.setExpiration();
  Promise.all([
    dispatch(clearCities()),
    dispatch(clearProvinces()),
    dispatch(clearCountries()),
    dispatch(clearCenters()),
    dispatch(clearDeathTypes()),
    dispatch(clearGroupCauses()),
    dispatch(getCountries()),
    dispatch(getRecordTypes()),
    dispatch(getAbortionTypes()),
    dispatch(getAirports()),
    dispatch(getAshesDestination()),
    dispatch(getCeremonies()),
    dispatch(getConcessionType()),
    dispatch(getFuneraryOrigin()),
    dispatch(getGenders()),
    dispatch(getInsurances()),
    dispatch(getMaritalStatus()),
    dispatch(getNicheType()),
    dispatch(getOwner()),
    dispatch(getReliquary()),
    dispatch(getSanitaryPractices()),
    dispatch(getUrn()),
    dispatch(getThanatologists()),
    dispatch(getThanatopractors()),
    dispatch(addMasterData()),
  ])
    .then(() => resolve())
    .catch((error) => {
      showErrorToast(i18n.t('common.masterDataKo'));
      reject(error);
    });
});

export const getCemeteryByCity = (
  cityCode?: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ThunkAction<Promise<any>,
  RootState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any, MasterDataAction> => async (dispatch: ThunkDispatch<RootState, any, any>): Promise<any> => {
  try {
    const res = cityCode && isNumber(Number(cityCode))
      ? await config.apiFunus.masterData.getCemeteryByCity(cityCode)
      : { data: [] };
    const masterData: CemeteryType[] = res.data;
    return dispatch({
      payload: {
        cemeteries: masterData,
        cityCode,
      },
      type: masterDataTypes.ADD_MASTER_DATA_CEMETERIES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCrematoryByCity = (
  cityCode?: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ThunkAction<Promise<MasterDataAction>, RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
): Promise<MasterDataAction> => {
  try {
    const res = cityCode && isNumber(Number(cityCode))
      ? await config.apiFunus.masterData.getCrematoryByCity(cityCode)
      : { data: [] };
    const masterData: CrematoryType[] = res.data;
    return dispatch({
      payload: masterData,
      type: masterDataTypes.ADD_MASTER_DATA_CREMATORIES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getVigilRoomByCity = (
  cityCode?: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ThunkAction<Promise<MasterDataAction>, RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
): Promise<MasterDataAction> => {
  try {
    const res = cityCode && isNumber(Number(cityCode))
      ? await config.apiFunus.masterData.getVigilRoomByCity(cityCode)
      : { data: [] };
    const masterData: IMasterDataDto[] = res.data;
    return dispatch({
      payload: masterData,
      type: masterDataTypes.ADD_MASTER_DATA_VIGIL_ROOM,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCoffinSizes = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ThunkAction<Promise<MasterDataAction>, RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
): Promise<MasterDataAction> => {
  try {
    const res = await budgetMasterDataClient.GetCoffinSizes();

    const masterData: IMasterDataDto[] = res;
    return dispatch({
      payload: masterData,
      type: masterDataTypes.ADD_MASTER_DATA_COFFIN_SIZES,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDoctorsByDeathType = (deathType?: DeathType): Promise<any> => {
  try {
    return deathType
      ? config.apiFunus.masterData.getDoctorsByDeathType(deathType)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getOvenByCityNCrematory = (
  crematoryCode?: string,
  cityCode?: string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ThunkAction<Promise<MasterDataAction>, RootState, any, MasterDataAction> => async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<RootState, any, MasterDataAction>,
): Promise<MasterDataAction> => {
  try {
    const res = cityCode && isNumber(Number(cityCode)) && crematoryCode
      ? await config.apiFunus.masterData.getOvenByCityNCrematory(
        crematoryCode,
        cityCode,
      )
      : { data: [] };
    const masterData: IMasterDataDto[] = res.data;
    return dispatch({
      payload: masterData,
      type: masterDataTypes.ADD_MASTER_DATA_OVEN,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOven = (cityCode?: string, crematoryCode?: string): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode)) && crematoryCode
      ? config.apiFunus.masterData.getOvenByCityNCrematory(
        crematoryCode,
        cityCode,
      )
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCemetery = (cityCode?: string): Promise<any> => {
  try {
    return cityCode && isNumber(Number(cityCode))
      ? config.apiFunus.masterData.getCemeteryByCity(cityCode)
      : new Promise((resolve) => resolve({ data: [] }));
  } catch (err) {
    return Promise.reject(err);
  }
};
