import classNames from 'classnames';
import React, {
  FC, ReactElement, ReactNode, useState,
} from 'react';
import { ChevronDown } from 'react-feather';

import Button from 'components/base/Button';

import SidebarButton, { SidebarButtonProps } from '../SidebarButton';

import './index.scss';

export type SidebarDropdownOption = SidebarButtonProps & {
  key: string;
};

export type SidebarDropdownProps = {
  icon?: ReactElement | undefined;
  content: string | ReactElement | ReactNode;
  onClick?(): void | undefined;
  subsections: SidebarDropdownOption[];
};

const SidebarDropdown: FC<SidebarDropdownProps> = ({
  subsections,
  content,
  icon,
  onClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={classNames('sidebar-dropdown', { expanded })}>
      <Button
        color="transparent"
        leftAddon={icon}
        rightAddon={<ChevronDown />}
        onClick={() => setExpanded(!expanded)}
      >
        {content}
      </Button>
      <div className="subsections">
        {subsections.map((child) => (
          <SidebarButton
            key={child.key}
            content={child.content}
            exact={child.exact}
            icon={child.icon}
            url={child.url}
            onClick={child.onClick || onClick}
          />
        ))}
      </div>
    </div>
  );
};

export default SidebarDropdown;
