import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateManager';

import useCemetery from 'hooks/useCemetery';
import useCeremonyLocation from 'hooks/useCeremonyLocation';
import useCrematory from 'hooks/useCrematory';
import useDeposit from 'hooks/useDeposit';
import { useVigilLocation } from 'hooks/useVigilLocation';
import { CeremonyOrder } from 'models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const Ceremony: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();
  const { normalizedCeremonyLocations } = useCeremonyLocation(
    (order.info as CeremonyOrder)?.currentCeremony?.address?.city,
  );
  const { normalizedVigilLocations } = useVigilLocation(
    (order.info as CeremonyOrder)?.vigil?.address?.city,
  );
  const { normalizedCrematories } = useCrematory(
    (order.info as CeremonyOrder)?.cremation?.address?.city,
  );
  const { normalizedCemeteries } = useCemetery(
    (order.info as CeremonyOrder)?.interment?.address?.city,
  );

  const { normalizedDeposits } = useDeposit(
    (order.info as CeremonyOrder)?.deposit?.address?.city,
  );

  useEffect(() => {
    const info: CeremonyOrder = order.info as CeremonyOrder;

    const newDetails: DetailType[] = [
      ...(info?.deposit
        ? [
          {
            text: t('service.deposit.deposit'),
            value:
                info?.deposit?.deposit
                && normalizedDeposits[info?.deposit?.deposit]?.description,
          },
        ]
        : []),
      ...[
        {
          text: t('service.ceremony.location'),
          value:
            info?.currentCeremony?.location
            && normalizedCeremonyLocations[info.currentCeremony.location]
              ?.description,
        },
        {
          text: t('common.address'),
          value: info?.currentCeremony?.address,
        },
        {
          text: t('common.date'),
          value: info?.currentCeremony?.date ? formatDate(info?.currentCeremony?.date) : null,
        },
        {
          text: t('common.hour'),
          value: info?.currentCeremony?.time,
        },
        {
          text: t('order.details.ceremonial'),
          value: `${info?.ceremonial?.name || ''} ${info?.ceremonial?.firstSurname || ''} ${info?.ceremonial?.secondSurname || ''}`,
        },
        {
          text: `${t('service.vigil.room')} ${t(
            'service.VELATORIO',
          ).toLowerCase()}`,
          value:
            info?.vigil?.location
            && normalizedVigilLocations[info?.vigil?.location]?.description,
        },
        {
          text: t('service.cremation.crematorium'),
          value:
            info?.cremation?.crematorium
            && normalizedCrematories[info?.cremation?.crematorium]?.description,
        },
      ],
      ...(!info?.interment?.ashes
        ? [
          {
            text: t('service.interment.cemetery'),
            value:
                info?.interment?.cemetery
                && normalizedCemeteries[info?.interment?.cemetery]?.description,
          },
        ]
        : []),
      ...[
        {
          text: t('common.coordinatorComment'),
          value: info?.coordinatorComment,
        },
        {
          text: t('common.adviserComment'),
          value: info?.currentCeremony?.comment,
        },
        {
          text: t('common.operatorComment'),
          value: info?.operatorComment,
        },
        {
          text: t('article.flowers.totalFlowers'),
          value: info?.totalFlowers,
        },
      ],
      {
        text: t('service.preparation.personalObjects'),
        value: info?.personalItems,
      },
      {
        text: t('service.preparation.coffinPreparationDetail'),
        value: info?.coffinPreparationDetail,
      },
      {
        text: t('service.ceremony.flowers'),
        value: info?.flowers && info?.flowers.length > 0
          ? `· ${info?.flowers[0].description} - ${info?.flowers[0].dedicatory}`
          : null,
      },
      ...(info?.flowers && info?.flowers.length > 1
        ? info?.flowers.slice(1).map((i) => ({
          showDosPuntos: false,
          text: '',
          value: `· ${i.description} - ${i.dedicatory ? i.dedicatory : ''}`,
        }))
        : []),
    ];

    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    normalizedCeremonyLocations,
    normalizedCrematories,
    normalizedVigilLocations,
    normalizedDeposits,
    t,
  ]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default Ceremony;
