import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';
import Textarea from 'components/base/Textarea';

import { AddCityForm } from '../AddCityForm';
import { CityProps } from '../AddCityForm/types';

import { AddCityModalComponent } from './types';

export const AddCityModal: FC<AddCityModalComponent> = ({ show, onCancel, onSuccess }) => {
  const { t } = useTranslation();
  const [selectedProvince, setSelectedProvince] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [newCityDescription, setNewCityDescription] = useState<string>('');
  const [newProvinceDescription, setNewProvinceDescription] = useState<string>('');
  const [newCountryDescription, setNewCountryDescription] = useState<string>('');

  const onChange = useCallback(
    ({ countryId, provinceId }: CityProps): void => {
      setSelectedProvince(provinceId);
      setSelectedCountry(countryId);
    },
    [],
  );
  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          onClick: () => {
            onSuccess();
          },
          text: t('common.accept'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('article.flowers.printTitle')}
      onHide={onCancel}
    >
      <AddCityForm
        value={{
          countryId: selectedCountry || '',
          provinceId: selectedProvince || '',
        }}
        onChange={onChange}
      />
      <Textarea
        id="new-city"
        name="new-city"
        placeholder={t('new.city')}
        value={newCityDescription}
        onChange={(val: string) => setNewCityDescription(val)}
      />
      {!selectedProvince && (
      <Textarea
        id="new-province"
        name="new-province"
        placeholder={t('new.province')}
        value={newProvinceDescription}
        onChange={(val: string) => setNewProvinceDescription(val)}
      />
      )}
      {!selectedCountry && (
      <Textarea
        id="new-country"
        name="new-country"
        placeholder={t('new.country')}
        value={newCountryDescription}
        onChange={(val: string) => setNewCountryDescription(val)}
      />
      )}
    </Modal>
  );
};
