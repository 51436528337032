// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-len */
import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from 'components/base/Column';
import Input from 'components/base/Input';
import { Row } from 'components/base/Row';

import { ItemSelector } from 'modules/budget/components/articleSelectors/ItemSelector';
import { QBackButton } from 'modules/budget/components/commons/QBackButton';
import { QConfirmButton } from 'modules/budget/components/commons/QConfirmButton';
import { QHeader } from 'modules/budget/components/commons/QHeader';
import { Separator } from 'modules/budget/components/commons/Separator';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes } from 'modules/budget/models/Article/types';
import { dismissServiceId, NicheOwnershipTypes } from 'modules/budget/store/data';

export enum Q31FromSteps {
  BEGIN = 'BEGIN',
  CONCESSIONS = 'CONCESSIONS',
  TAXES = 'TAXES',
  TRANSFERS = 'TRANSFERS',
  RESPOND = 'RESPOND',
}

export interface Q31Response {
  nicheType: NicheOwnershipTypes | null;
  nameChange: boolean;
  concessions: Article[];
  dismissConcessions: boolean;
  dismissTaxes: boolean;
  dismissTransfers: boolean;
  taxes: Article[];
  transfers: Article[];
  q31FormStep: Q31FromSteps;
  autorespond: boolean;
}

interface Q31FormComponent {
  onChange: (value: Q31Response) => void;
  question: BudgetQuestionCodes;
}

const hasDismissElement = (
  list: Article[],
): boolean => Boolean(list?.find((item) => item.code === dismissServiceId));

export const Q31Form: FC<Q31FormComponent> = ({ onChange, question }) => {
  const { t } = useTranslation();
  const { GetQuestionValue } = useBudget();

  const [selectedNicheType, setSelectedNicheType] = useState<NicheOwnershipTypes | null>(null);
  const [selectedOwnership, setSelectedOwnership] = useState<Article[]>([]);

  const [selectedConcession, setSelectedConcession] = useState<Article[]>([]);
  const [selectedTax, setSelectedTax] = useState<Article[]>([]);
  const [selectedTransfer, setSelectedTransfer] = useState<Article[]>([]);

  const [selectedNameChange, setSelectedNameChange] = useState<boolean>(false);

  const [selectedQ31Step, setSelectedQ31Step] = useState<Q31FromSteps>(Q31FromSteps.BEGIN);

  const [selectedDismissConcessions, setSelectedDismissConcessions] = useState<boolean>(false);
  const [selectedDismissTaxes, setSelectedDismissTaxes] = useState<boolean>(false);
  const [selectedDismissTransfers, setSelectedDismissTransfers] = useState<boolean>(false);

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue) {
        const {
          nicheType,
          nameChange,
          concessions,
          taxes,
          transfers,
        } = storedValue;

        onChange({
          autorespond: false,
          concessions: concessions || [],
          dismissConcessions: hasDismissElement(concessions),
          dismissTaxes: hasDismissElement(taxes),
          dismissTransfers: hasDismissElement(transfers),
          nameChange,
          nicheType,
          q31FormStep: Q31FromSteps.BEGIN,
          taxes: taxes || [],
          transfers: transfers || [],
        });

        setSelectedDismissConcessions(hasDismissElement(concessions) || !concessions);
        setSelectedDismissTaxes(hasDismissElement(taxes) || !taxes);
        setSelectedDismissTransfers(hasDismissElement(transfers) || !transfers);

        setSelectedQ31Step(Q31FromSteps.BEGIN);

        setSelectedNicheType(nicheType);
        setSelectedOwnership(nicheType !== null
          ? [
            new Article({
              amount: 1,
              code: `${nicheType}`,
              description: '',
            }),
          ]
          : []);

        setSelectedNameChange(nameChange);

        setSelectedConcession(concessions || []);
        setSelectedTax(taxes || []);
        setSelectedTransfer(transfers || []);
      }
    },
    [onChange, storedValue],
  );

  return (
    <Formik
      initialValues={{
        autorespond: false,
        concessions: [],
        dismissConcessions: false,
        dismissTaxes: false,
        dismissTransfers: false,
        nameChange: false,
        nicheType: null,
        q31FormStep: Q31FromSteps.BEGIN,
        taxes: [],
        transfers: [],
      }}
      validate={(values) => {
        if (values.nicheType) {
          onChange({
            autorespond: false,
            concessions: values.concessions,
            dismissConcessions: values.dismissConcessions,
            dismissTaxes: values.dismissTaxes,
            dismissTransfers: values.dismissTransfers,
            nameChange: values.nameChange,
            nicheType: values.nicheType,
            q31FormStep: values.q31FormStep,
            taxes: values.taxes,
            transfers: values.transfers,
          });
          setSelectedQ31Step(values.q31FormStep);

          setSelectedNicheType(values.nicheType);
          setSelectedOwnership(values.nicheType !== null
            ? [
              new Article({
                amount: 1,
                code: `${values.nicheType}`,
                description: '',
              }),
            ]
            : []);

          setSelectedNameChange(values.nameChange);

          setSelectedConcession(values.concessions);
          setSelectedTax(values.taxes);
          setSelectedTransfer(values.transfers);

          setSelectedDismissConcessions(values.dismissConcessions);
          setSelectedDismissTaxes(values.dismissTaxes);
          setSelectedDismissTransfers(values.dismissTransfers);
        }
        return {};
      }}
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues,
      }: FormikProps<Q31Response>) => {
        const canStartRespond = Boolean(selectedNicheType) && selectedQ31Step === Q31FromSteps.BEGIN;
        const canRespondConcession = selectedConcession.length !== 0 || selectedDismissConcessions;
        const canRespondTaxes = selectedTax.length !== 0 || selectedDismissTaxes;
        const canRespondTransfer = selectedTransfer.length !== 0 || selectedDismissTransfers;

        const nextStep = (): Q31FromSteps => {
          const needConcession = selectedNicheType === NicheOwnershipTypes.NEW;
          const needTax = selectedNicheType === NicheOwnershipTypes.NEW || selectedNicheType === NicheOwnershipTypes.OLD;
          const needTransfer = selectedNicheType === NicheOwnershipTypes.OLD && selectedNameChange;

          if (selectedQ31Step === Q31FromSteps.BEGIN) {
            if (needConcession) {
              return Q31FromSteps.CONCESSIONS;
            } if (needTax) {
              return Q31FromSteps.TAXES;
            } if (needTransfer) {
              return Q31FromSteps.TRANSFERS;
            }
            return Q31FromSteps.RESPOND;
          } if (selectedQ31Step === Q31FromSteps.CONCESSIONS) {
            if (needTax) {
              return Q31FromSteps.TAXES;
            } if (needTransfer) {
              return Q31FromSteps.TRANSFERS;
            }
            return Q31FromSteps.RESPOND;
          } if (selectedQ31Step === Q31FromSteps.TAXES) {
            if (needTransfer) {
              return Q31FromSteps.TRANSFERS;
            }
            return Q31FromSteps.RESPOND;
          }

          return Q31FromSteps.RESPOND;
        };

        return (
          <React.Fragment>
            <Column className="q31">
              {(selectedQ31Step === Q31FromSteps.BEGIN || selectedQ31Step === Q31FromSteps.RESPOND)
                ? <QHeader question={question} />
                : <React.Fragment />}
              {selectedQ31Step === Q31FromSteps.BEGIN || selectedQ31Step === Q31FromSteps.RESPOND
                ? (
                  <Column justifyContent="flex-start">
                    <ItemSelector
                      className="niche-ownership-type-selector"
                      question={question}
                      value={selectedOwnership}
                      onChange={(ownershipsTypes: Article[]) => {
                        const own = ownershipsTypes[0]?.code as NicheOwnershipTypes;
                        setValues({
                          autorespond: false,
                          concessions: [],
                          dismissConcessions: selectedDismissConcessions,
                          dismissTaxes: selectedDismissTaxes,
                          dismissTransfers: selectedDismissTransfers,
                          nameChange: own === NicheOwnershipTypes.NEW ? false : selectedNameChange,
                          nicheType: ownershipsTypes[0]?.code as NicheOwnershipTypes,
                          q31FormStep: selectedQ31Step,
                          taxes: own === NicheOwnershipTypes.NEW ? [] : selectedTax,
                          transfers: [],
                        });
                      }}
                    />
                    {selectedNicheType === NicheOwnershipTypes.OLD
                      ? (
                        <Row>
                          <Separator />
                          <Input
                            checked={selectedNameChange}
                            className="name-change-switch"
                            data-testid="name-change-switch"
                            id="name-change"
                            name="name-change"
                            placeholder={t('budget.wizard.nameChange')}
                            type="checkbox"
                            onChange={(val) => {
                              setValues({
                                autorespond: false,
                                concessions: selectedConcession,
                                dismissConcessions: selectedDismissConcessions,
                                dismissTaxes: selectedDismissTaxes,
                                dismissTransfers: selectedDismissTransfers,
                                nameChange: Boolean(val),
                                nicheType: selectedNicheType,
                                q31FormStep: selectedQ31Step,
                                taxes: selectedTax,
                                transfers: selectedTransfer,
                              });
                            }}
                          />
                          <Separator />
                        </Row>
                      )
                      : <React.Fragment />}
                  </Column>
                )
                : <React.Fragment />}

              {(selectedQ31Step === Q31FromSteps.CONCESSIONS)
                ? (
                  <React.Fragment>
                    <Column className="taxes-group">
                      <QHeader caption={t('budget.wizard.concessionQuestion')} />
                      <ItemSelector
                        collectionType={ArticleCollectionTypes.CONCESSION}
                        dismissService={selectedDismissConcessions}
                        multiselect={false}
                        question={question}
                        value={selectedConcession}
                        onChange={(concessions?: Article[]) => {
                          const isDismiss = hasDismissElement(concessions || []);
                          setValues({
                            autorespond: false,
                            concessions: isDismiss ? [] : concessions || [],
                            dismissConcessions: isDismiss,
                            dismissTaxes: selectedDismissTaxes,
                            dismissTransfers: selectedDismissTransfers,
                            nameChange: selectedNameChange,
                            nicheType: selectedNicheType,
                            q31FormStep: concessions?.length === 0
                              ? selectedQ31Step
                              : nextStep(),
                            taxes: selectedTax,
                            transfers: selectedTransfer,
                          });
                        }}
                      />
                    </Column>
                  </React.Fragment>
                )
                : (<React.Fragment />)}

              {selectedQ31Step === Q31FromSteps.TAXES
                ? (
                  <React.Fragment>
                    <Column className="taxes-group">
                      <QHeader caption={t('budget.wizard.cemeteryTaxesQuestion')} />
                      <ItemSelector
                        collectionType={ArticleCollectionTypes.TAX}
                        dismissService={selectedDismissTaxes}
                        question={question}
                        value={selectedTax}
                        multiselect
                        onChange={(taxes?: Article[]) => {
                          const isDismiss = hasDismissElement(taxes || []);
                          setValues({
                            autorespond: false,
                            concessions: selectedConcession,
                            dismissConcessions: selectedDismissConcessions,
                            dismissTaxes: isDismiss,
                            dismissTransfers: selectedDismissTransfers,
                            nameChange: selectedNameChange,
                            nicheType: selectedNicheType,
                            q31FormStep: !isDismiss
                              ? selectedQ31Step
                              : nextStep(),
                            taxes: isDismiss ? [] : taxes || [],
                            transfers: selectedTransfer,
                          });
                        }}
                      />
                    </Column>
                  </React.Fragment>
                )
                : (<React.Fragment />)}

              {selectedQ31Step === Q31FromSteps.TRANSFERS
                ? (
                  <Column className="taxes-group">
                    <QHeader caption={t('budget.wizard.transferQuestion')} />
                    <ItemSelector
                      collectionType={ArticleCollectionTypes.TRANSFER}
                      dismissService={selectedDismissTransfers}
                      question={question}
                      value={selectedTransfer}
                      multiselect
                      onChange={(transfers?: Article[]) => {
                        const isDismiss = hasDismissElement(transfers || []);
                        setValues({
                          autorespond: false,
                          concessions: selectedConcession,
                          dismissConcessions: selectedDismissConcessions,
                          dismissTaxes: selectedDismissTaxes,
                          dismissTransfers: isDismiss,
                          nameChange: selectedNameChange,
                          nicheType: selectedNicheType,
                          q31FormStep: !isDismiss
                            ? selectedQ31Step
                            : nextStep(),
                          taxes: selectedTax,
                          transfers: isDismiss ? [] : transfers || [],
                        });
                      }}
                    />
                  </Column>
                )
                : (<React.Fragment />)}

              {selectedQ31Step === Q31FromSteps.BEGIN
                ? (
                  <Row justifyContent="space-between">
                    <QBackButton question={BudgetQuestionCodes.Q31} />
                    <QConfirmButton
                      allowGotoSummary={false}
                      disabled={!canStartRespond}
                      question={BudgetQuestionCodes.Q31}
                      onClick={() => {
                        setValues({
                          autorespond: false,
                          concessions: selectedConcession,
                          dismissConcessions: selectedDismissConcessions,
                          dismissTaxes: selectedDismissTaxes,
                          dismissTransfers: selectedDismissTransfers,
                          nameChange: selectedNameChange,
                          nicheType: selectedNicheType,
                          q31FormStep: nextStep(),
                          taxes: selectedTax,
                          transfers: selectedTransfer,
                        });
                      }}
                    />
                  </Row>

                )
                : <React.Fragment />}

              {selectedQ31Step === Q31FromSteps.CONCESSIONS
                ? (
                  <QConfirmButton
                    allowGotoSummary={false}
                    disabled={!canRespondConcession}
                    question={BudgetQuestionCodes.Q31}
                    onClick={() => {
                      setValues({
                        autorespond: false,
                        concessions: selectedConcession,
                        dismissConcessions: selectedDismissConcessions,
                        dismissTaxes: selectedDismissTaxes,
                        dismissTransfers: selectedDismissTransfers,
                        nameChange: selectedNameChange,
                        nicheType: selectedNicheType,
                        q31FormStep: nextStep(),
                        taxes: selectedTax,
                        transfers: selectedTransfer,
                      });
                    }}
                  />
                )
                : <React.Fragment />}

              {selectedQ31Step === Q31FromSteps.TAXES
                ? (
                  <QConfirmButton
                    allowGotoSummary={false}
                    disabled={!canRespondTaxes}
                    question={BudgetQuestionCodes.Q31}
                    onClick={() => {
                      setValues({
                        autorespond: false,
                        concessions: selectedConcession,
                        dismissConcessions: selectedDismissConcessions,
                        dismissTaxes: selectedDismissTaxes,
                        dismissTransfers: selectedDismissTransfers,
                        nameChange: selectedNameChange,
                        nicheType: selectedNicheType,
                        q31FormStep: nextStep(),
                        taxes: selectedTax,
                        transfers: selectedTransfer,
                      });
                    }}
                  />
                )
                : <React.Fragment />}

              {selectedQ31Step === Q31FromSteps.TRANSFERS
                ? (
                  <QConfirmButton
                    allowGotoSummary={false}
                    disabled={!canRespondTransfer}
                    question={BudgetQuestionCodes.Q31}
                    onClick={() => {
                      setValues({
                        autorespond: false,
                        concessions: selectedConcession,
                        dismissConcessions: selectedDismissConcessions,
                        dismissTaxes: selectedDismissTaxes,
                        dismissTransfers: selectedDismissTransfers,
                        nameChange: selectedNameChange,
                        nicheType: selectedNicheType,
                        q31FormStep: nextStep(),
                        taxes: selectedTax,
                        transfers: selectedTransfer,
                      });
                    }}
                  />
                )
                : <React.Fragment />}
            </Column>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
