import { FormikHelpers, FormikProps } from 'formik';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { checkHaveAtLeastOnePermission } from 'utils/permissions';
import { sortOrderTypes } from 'utils/workorderUtil';

import SideFilter from 'components/base/SideFilters';
import SideFilterField from 'components/base/SideFilters/SideFilterField';

import { MasterDataDto, SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useCenters } from 'hooks/useCenters';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { i18n } from 'i18n';
import { IMasterDataDto } from 'models/MasterData';
import OrderType from 'models/OrderType';

export type WorkshopFilter = {
  type?: MasterDataDto;
  destinationCenter?: MasterDataDto;
  isAssigned?: boolean;
  serviceDate?: Date;
  erpId?: string;
  deceasedName?: string;
  deceasedSurname?: string;
}

const WorkOrderTypeOptions = [
  OrderType.PICKUP,
  OrderType.PREPARATION,
  OrderType.SANITARY_PRACTICE,
  OrderType.CEREMONY,
  OrderType.INCINERATION,
  OrderType.INHUMATION,
  OrderType.PREPARATION_NICHO,
  OrderType.HANDBOOK,
  OrderType.HANDBOOK_CEMETERY,
  OrderType.RECEPTION,
  OrderType.MOVEMENT,
  OrderType.TRANSFER,
]
  .sort(sortOrderTypes)
  .map((type) => ({
    code: type,
    description: i18n.t(`order.types.${type}`),
  }));

const excludeCenters = (center: IMasterDataDto) => !['IMCL'].includes(center.code);
const fields = (
  formikProps: FormikProps<WorkshopFilter>,
  centers: IMasterDataDto[],
  canFilterByDestinationCenter: boolean,
): ReactElement[] => {
  const fitlerFields = [];
  if (canFilterByDestinationCenter) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fitlerFields.push(<SideFilterField<any, IMasterDataDto>
      key="destinationCenter"
      filterType="select"
      formikProps={formikProps}
      getLabel={({ description }) => description}
      getValue={({ code }) => code}
      name="destinationCenter"
      options={centers.filter(excludeCenters)}
      placeholder={`${i18n.t('order.details.destinationCenter')}`}
    />);
  }

  return [
    ...fitlerFields,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SideFilterField<any, IMasterDataDto>
      key="type"
      filterType="select"
      formikProps={formikProps}
      getLabel={({ description }) => description}
      getValue={({ code }) => code}
      name="type"
      options={WorkOrderTypeOptions}
      placeholder={`${i18n.t('common.type')}`}
    />,
    <SideFilterField
      key="isAssigned"
      filterType="tristate"
      formikProps={formikProps}
      name="isAssigned"
      placeholder={`${i18n.t('order.assigned')}`}
    />,
    <SideFilterField
      key="serviceDate"
      filterType="date"
      formikProps={formikProps}
      name="serviceDate"
      placeholder={`${i18n.t('common.date')}`}
    />,
    <SideFilterField
      key="erpId"
      filterType="input"
      formikProps={formikProps}
      name="erpId"
      placeholder={`${i18n.t('order.record')}`}
    />,
    <SideFilterField
      key="deceasedName"
      filterType="input"
      formikProps={formikProps}
      name="deceasedName"
      placeholder={`${i18n.t('order.deceasedName')}`}
    />,
    <SideFilterField
      key="deceasedSurname"
      filterType="input"
      formikProps={formikProps}
      name="deceasedSurname"
      placeholder={`${i18n.t('order.deceasedSurname')}`}
    />,
  ];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WorkOrderFilter = ({ onSearch, onCreateManualOT }: {
  onSearch: (filters: WorkshopFilter) => void,
  onCreateManualOT: () => void }) => {
  const { t } = useTranslation();
  const { centers } = useCenters();
  const { user } = useProvidedAuth();
  const canFilterByDestinationCenter = useMemo(
    () => checkHaveAtLeastOnePermission([
      SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_ADD,
      SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_WRITE,
    ], user?.role.permissions),
    [user?.role.permissions],
  );

  return (
    <SideFilter
      fields={(formikProps) => fields(formikProps, centers, canFilterByDestinationCenter)}
      initialValues={{}}
      title={t('common.search')}
      showAddManualOT
      onCreateManualOT={onCreateManualOT}
      onSearch={(filters: WorkshopFilter, formikActions: FormikHelpers<WorkshopFilter>) => {
        formikActions.setSubmitting(false);
        onSearch(filters);
      }}
    />
  );
};
