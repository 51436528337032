import { CoffinSizes } from 'hooks/useCoffins/types';
import { IMasterDataDto } from 'models/MasterData';
import { MasterDataQuestionType } from 'modules/budget/api/types';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { ArticleCollectionTypes, ArticleType } from 'modules/budget/models/Article/types';
import { IAudit } from 'modules/budget/models/Audit/types';
import { BudgetConfiguration } from 'modules/budget/models/BudgetConfiguration';
import { IBudgetConfigurationLocation } from 'modules/budget/models/BudgetConfigurationLocation/types';
import { BudgetResponseCodes } from 'modules/budget/models/BudgetQuestion/types';
import { Code } from 'modules/budget/models/Code';

import { BlocTypes } from './BlocTypes';

interface BudgetFormAddress {
  city: string;
  country: string;
  location: string;
  province: string;
}

export interface IBudgetFormProps extends IAudit {
  active: boolean;
  article: string | null;
  bloc: BlocTypes | null;
  order: number | null;
  coffinSize?: CoffinSizes | null;
  excludedArticles: Code[];
  excludedClients: Code[];
  excludedLocations: Array<IBudgetConfigurationLocation>;
  image?: string | null;
  includedArticles: Code[];
  includedClients: Code[];
  includedLocations: Array<IBudgetConfigurationLocation>;
  mandatoryResponses: Code[];
  optionalResponses: Code[];
  questionCode?: BudgetQuestionCodes | null;
  subType: ArticleCollectionTypes;
  type: ArticleType | null;
  workingAddress: BudgetFormAddress;
  workingArticle?: string;
  workingClient?: string;
  workingExcludedAddress: BudgetFormAddress;
  workingExcludedArticles?: string;
  workingExcludedClient?: string;
  workingExcludedLocationType?: MasterDataQuestionType;
  workingExcludedResponseCode?: BudgetResponseCodes;
  workingLocationType?: MasterDataQuestionType;
  workingRelatedArticle?: string;
  workingResponseCode?: BudgetResponseCodes;
  workingShowOnlyNotActive: boolean;
}

export interface BudgetFormComponent {
  value: BudgetConfiguration;
  onChange: (articleId: string, values: BudgetConfiguration) => void;
  onAccept:(value: BudgetConfiguration) => void;
  articleCode?: string;
  loading: boolean;
  coffinSizes: IMasterDataDto[];
}

export type BudgetFormArticle = IMasterDataDto & {
  active: boolean;
};

export interface useBudgetFormHook {
  questionCodes: IMasterDataDto[];
  responseCodes: IMasterDataDto[];
  articles: BudgetFormArticle[];
  fetchArticles: () => Promise<BudgetFormArticle[]>;
  getArticleByCode: (code: string) => BudgetFormArticle | undefined;
  saveConfiguration: (articleId: string, data:BudgetConfiguration) => Promise<unknown>;
  locationTypes: IMasterDataDto[];
  insurances: IMasterDataDto[];
  locationTypeLabels: (type?: MasterDataQuestionType) => string;
  fetchConfiguration: (articleId: string) => Promise<BudgetConfiguration>;
  articleTypes: IMasterDataDto[];
  articleSubTypes: IMasterDataDto[];
  coffinSizes: IMasterDataDto[];
  blocTypes: IMasterDataDto[];
}

export enum BUDGET_FORM_FIELS {
  ACTIVE = 'active',
  ARTICLE = 'article',
  BLOC = 'bloc',
  COFFIN_SIZE = 'coffinSize',
  EXCLUDED_ARTICLES = 'excludedArticles',
  EXCLUDED_CLIENTS = 'excludedClients',
  EXCLUDED_LOCATIONS = 'excludedLocations',
  INCLUDED_ARTICLES = 'includedArticles',
  INCLUDED_CLIENTS = 'includedClients',
  INCLUDED_LOCATIONS = 'includedLocations',
  MANDATORY_RESPONSES = 'mandatoryResponses',
  OPTIONAL_RESPONSES = 'optionalResponses',
  ORDER = 'order',
  QUESTION_CODE = 'questionCode',
  SUB_TYPE = 'subType',
  TYPE = 'type',
}
