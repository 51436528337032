import React, { FC, useState } from 'react';

import Button from '../Button';
import CustomIcon from '../CustomIcon';
import { OtherTypes } from '../CustomIcon/types';

import { AddCityModal } from './AddCityModal';

export const AddCity: FC<Record<string, unknown>> = () => {
  const [showAdd, setShowAdd] = useState<boolean>(false);

  return (
    <div>
      <Button
        color="transparent"
        onClick={() => {
          setShowAdd(true);
        }}
      >
        <CustomIcon icon={OtherTypes.ADD} />
      </Button>
      <AddCityModal
        show={showAdd}
        onCancel={() => setShowAdd(false)}
        onSuccess={() => setShowAdd(false)}
      />
    </div>
  );
};
