import { DocumentDNIScanScore } from 'modules/ia/models/DocumentDNIScanScore';

import { ApiDNIScanResponseScore } from './types/ApiDNIScanResponseScore';

export const mapIAScores = (score: ApiDNIScanResponseScore): DocumentDNIScanScore => ({
  firstSurname: score.firstSurname,
  birthAddress: {
    firstField: score.birthAddress.firstField,
    secondField: score.birthAddress.secondField,
  },
  birthDate: score.birthDate,
  firstParent: score.firstParent,
  genre: score.genre,
  nationality: score.nationality,
  secondParent: score.secondParent,
  name: score.name,
  secondSurname: score.secondSurname,
  idCard: score.idCard,
  address: {
    country: score.address.country,
    street: score.address.street,
    province: score.address.province,
    city: score.address.city,
  },
});
