import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from 'utils/toasts';

import Button from 'components/base/Button';
import Loader from 'components/base/Loader';

import { useSteps } from 'hooks/useSteps';
import { StepPositions } from 'hooks/useSteps/types';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';
import { useDniScanInfo } from 'modules/ia/hooks/useDniScanInfo';
import { useIA } from 'modules/ia/hooks/useIa';

import { DataCollectorForm } from '../../models/DataCollectorForm';
import { IAInfoForm } from '../IAInfoForm';
import { PassportCamera } from '../passport-camera';
import { IAScanDocumentContext } from '../scan-document-modal/IAScanDocumentContext';

import { PassportDataCollectorByPhotoComponent } from './types';

import './index.scss';

export const PassportDataCollectorByPhoto: FC<PassportDataCollectorByPhotoComponent> = (
  {
    onConfirm, onCancel,
  },
) => {
  const { documentType } = useContext(IAScanDocumentContext);
  const { t } = useTranslation();
  const { fetchInfoFromPicture, loading } = useIA();
  const [isFrontCameraActive, setIsFrontCameraActive] = useState<boolean>(false);

  const {
    setStep,
    isFirstStep: isTakeFrontPhotoStep,
    isSecondStep: isReviewDataFormStep,
  } = useSteps();

  const [canAccept, setCanAccept] = useState<boolean>(false);
  const [selectedValues, setselectedValues] = useState<DataCollectorForm>();

  const [frontPicture, setFrontPicture] = useState<Base64Data>();
  const {
    setDocInfo, docInfo, docScore, setDocScore,
  } = useDniScanInfo();

  const getInfo = useCallback(
    ({
      frontPicture: front,
    }: { frontPicture: Base64Data; }) => {
      fetchInfoFromPicture(documentType, { frontPicture: front })
        .then((response) => {
          setDocInfo(response.data);
          setDocScore(response.score);

          setStep(StepPositions.SECOND);

          return response;
        })
        .catch((e: Error) => {
          showErrorToast(e.message);

          setStep(StepPositions.SECOND);

          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className="document-dni-data-collector-component">
      {isTakeFrontPhotoStep
        && !loading
        && (
          <React.Fragment>
            <div className="step-header">{t('ia.front')}</div>
            <PassportCamera
              isFrontCameraActive={isFrontCameraActive}
              onAccept={(picture) => {
                setFrontPicture(picture);
                if (picture) {
                  getInfo({ frontPicture: picture });
                }
              }}
              onIsFrontCameraUpdate={(v) => {
                setIsFrontCameraActive(v);
              }}
            />
          </React.Fragment>
        )}

      {isTakeFrontPhotoStep
        && loading
        && (
          <React.Fragment>
            <div className="step-header">{`${t('ia.scanningDocument')}`}</div>
            <Loader />
            <div
              className="summary-photo-preview"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {frontPicture && (
                <img
                  alt="DNI front"
                  className="document-dni-data-collector-image"
                  src={frontPicture.toDataUrl()}
                />
              )}
            </div>
          </React.Fragment>
        )}

      {isReviewDataFormStep
        && (
          <React.Fragment>
            <div className="step-header">{t('ia.reviewData')}</div>
            <div
              className="summary-photo-preview"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              {frontPicture && (
                <div className="document-dni-data-collector-image">
                  <div>{t('ia.front')}</div>
                  <img
                    alt="DNI front"
                    src={frontPicture.toDataUrl()}
                  />
                </div>
              )}
            </div>
            <IAInfoForm
              docInfo={docInfo}
              docScore={docScore}
              onChange={({ isValid, values }) => {
                setCanAccept(isValid);
                setselectedValues(values);
              }}
            />
            <div className="dni-data-collector-buttons">
              <Button
                className="photo-camera-button"
                text={t('common.cancel')}
                type="button"
                onClick={onCancel}
              />
              <Button
                className="photo-camera-button"
                disabled={!canAccept}
                text={t('common.accept')}
                type="button"
                onClick={() => {
                  if (selectedValues) {
                    onConfirm(
                      {
                        ...selectedValues,
                        frontCapture: frontPicture,
                      },
                      MimeTypes.IMAGE_PNG,
                    );
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}
    </div>
  );
};
