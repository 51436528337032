import React, {
  FC, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import { Row } from 'components/base/Row';
import Tooltip from 'components/base/Tooltip';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { sortedQuestions } from 'modules/budget/data/sortedQuestions';
import { BudgetHelper } from 'modules/budget/helpers/BudgetHelper';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { IArticle } from 'modules/budget/models/Article/types';

import { summaryHeaderFixedQuestionButtons, summaryHeaderQuestionButtons } from '../../../data/summaryHeaderQuestionButtons';
import { BudgetDiscardedModal } from '../../tracking/budgetDiscardedModal';
import { BudgetPdfModal } from '../../tracking/budgetPdfModal';
import { BudgetsTrackingFilterStatus } from '../../tracking/table/types';

import { BudgetSummaryActionsComponent } from './types';

import './index.scss';

export const BudgetSummaryActions: FC<BudgetSummaryActionsComponent> = ({ budgetId }) => {
  const { t } = useTranslation();

  const {
    budget, discardedArticles, articlesLoading, questions,
    articles,
    GoToQuestion,
  } = useBudget();
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [showDiscarded, setShowDiscarded] = useState<boolean>(false);

  const enableActions = useMemo(
    () => {
      const blockedActionsStates = new Set([
        BudgetsTrackingFilterStatus.SIGNED,
        BudgetsTrackingFilterStatus.INVOICED,
      ]);

      return !blockedActionsStates.has(budget.status);
    },
    [budget.status],
  );

  const availableQuestionButtons: BudgetQuestionCodes[] = useMemo(
    () => {
      const budgetHelper = new BudgetHelper(sortedQuestions);
      let toolBarQuestions: BudgetQuestionCodes[] = [];
      const mapArticleToQuestionCode = (
        article: IArticle,
      ): BudgetQuestionCodes | undefined => article.questionCode;

      budgetHelper
        .availableQuestions(questions).forEach((questionCode) => {
          if (summaryHeaderQuestionButtons.includes(questionCode)) {
            toolBarQuestions.push(questionCode);
          }
        });

      summaryHeaderFixedQuestionButtons.forEach((questionCode) => {
        if (!toolBarQuestions.includes(questionCode)) {
          toolBarQuestions.push(questionCode);
        }
      });

      const excludeQuestionsWithArticleSelected = (
        questionCode: BudgetQuestionCodes,
      ): boolean => !articles
        .map(mapArticleToQuestionCode)
        .includes(questionCode);

      toolBarQuestions = toolBarQuestions
        .filter(excludeQuestionsWithArticleSelected);

      return toolBarQuestions;
    },
    [articles, questions],
  );

  return (
    <React.Fragment>
      <Row className="f-budget-summary-actions" justifyContent="flex-start">
        {enableActions
          ? (
            <div className="f-budget-summary-actions-group">
              <Tooltip
                key={`question-${BudgetQuestionCodes.Q1}`}
                className="f-budget-summary-actions-group-item"
                content={t('common.edit')}
              >
                <Button
                  color="secondary"
                  onClick={() => {
                    GoToQuestion(BudgetQuestionCodes.Q1);
                  }}
                >
                  <CustomIcon icon={OtherTypes.BUDGET_EDIT_QUESTION} />
                </Button>
              </Tooltip>
            </div>
          )
          : <React.Fragment />}

        <div className="f-budget-summary-actions-group">
          <Tooltip content={t('common.print')}>
            <Button
              color="transparent"
              disabled={articlesLoading}
              onClick={() => {
                setShowPdf(true);
              }}
            >
              <CustomIcon icon={OtherTypes.PRINT} />
            </Button>
          </Tooltip>
        </div>

        {enableActions && discardedArticles.length !== 0
          ? (
            <div className="f-budget-summary-actions-group">
              <Tooltip content={t('budget.discarded')}>
                <Button
                  color="secondary"
                  disabled={articlesLoading}
                  onClick={() => {
                    setShowDiscarded(true);
                  }}
                >
                  <CustomIcon icon={OtherTypes.RESTORE} />
                </Button>
              </Tooltip>
            </div>
          )
          : <React.Fragment />}

        {enableActions && !articlesLoading && availableQuestionButtons.length > 0
          ? (
            <Row className="f-budget-summary-actions-group">
              {availableQuestionButtons.map((questionCode) => (
                <Tooltip
                  key={`question-${questionCode}`}
                  className="f-budget-summary-actions-group-item"
                  content={t(`budget.wizard.${questionCode}`)}
                >
                  <Button
                    color="secondary"
                    onClick={() => {
                      GoToQuestion(questionCode);
                    }}
                  >
                    {t(`budget.wizard.short.${questionCode}`)}
                  </Button>
                </Tooltip>
              ))}

            </Row>
          )
          : <React.Fragment />}

        {enableActions && !articlesLoading
          ? (
            <Row className="f-budget-summary-actions-group">
              <Tooltip
                key={`question-${BudgetQuestionCodes.Q44}`}
                className="f-budget-summary-actions-group-item"
                content={t(`budget.wizard.${BudgetQuestionCodes.Q44}`)}
              >
                <Button
                  color="secondary"
                  onClick={() => {
                    GoToQuestion(BudgetQuestionCodes.Q44);
                  }}
                >
                  {t(`budget.wizard.short.${BudgetQuestionCodes.Q44}`)}
                </Button>
              </Tooltip>
            </Row>
          )
          : <React.Fragment />}
      </Row>
      <BudgetPdfModal
        budgetId={Number(budgetId)}
        show={showPdf}
        onHide={() => {
          setShowPdf(false);
        }}
      />
      <BudgetDiscardedModal
        budgetId={0}
        show={showDiscarded}
        onCancel={() => setShowDiscarded(false)}
      />
    </React.Fragment>
  );
};
