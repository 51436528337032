import React from 'react';
import {
  Home,
  Clipboard,
  PhoneIncoming,
  Key,
  Trello,
  Tv,
  Calendar,
  Book,
  Codesandbox,
  Server,
  Info,
  Camera,
  Users,
} from 'react-feather';

import config from 'config';

import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import { SidebarOption } from 'components/base/Sidebar';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { i18n } from 'i18n';
import { Permission } from 'models/UserRole';

export const home: SidebarOption = {
  content: i18n.t('menu.home'),
  key: 'home',
  icon: <Home />,
  exact: true,
  url: config.url.landing,
};

export const roles: SidebarOption = {
  content: i18n.t('menu.roles'),
  key: 'roles',
  icon: <Key />,
  exact: true,
  url: config.url.roles,
  permission: [SimpleUserRequestPermissionNames.USER_READ],
};

export const ips: SidebarOption = {
  content: i18n.t('menu.ips'),
  key: 'ips',
  icon: <Tv />,
  exact: true,
  url: config.url.ips,
  permission: [SimpleUserRequestPermissionNames.USER_READ],
};

export const enquiries: SidebarOption = {
  content: i18n.t('menu.enquiries'),
  key: 'enquiries',
  icon: <PhoneIncoming />,
  url: config.url.enquiries,
  permission: [SimpleUserRequestPermissionNames.ENQUIRY_READ],
};

export const enquiriesDashboard: SidebarOption = {
  content: i18n.t('menu.enquiries'),
  key: 'enquiriesDashboard',
  icon: <Server />,
  url: config.url.enquiriesDashboard,
  permission: [SimpleUserRequestPermissionNames.ENQUIRY_READ],
};

export const records: SidebarOption = {
  content: i18n.t('menu.records'),
  key: 'records',
  icon: <Clipboard />,
  url: config.url.records,
  permission: [SimpleUserRequestPermissionNames.RECORD_READ],
};

export const workshop: SidebarOption = {
  content: i18n.t('menu.workshop'),
  key: 'workshop',
  icon: <Trello />,
  exact: true,
  url: config.url.workshop,
  permission: [
    SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ,
    SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ,
  ],
};

export const calendar: SidebarOption = {
  content: i18n.t('menu.calendar'),
  key: 'calendar',
  icon: <Calendar />,
  exact: true,
  url: config.url.calendarNoInfo,
  permission: [
    SimpleUserRequestPermissionNames.RECORD_ADD,
    SimpleUserRequestPermissionNames.RECORD_ASSIGN,
  ],
};

export const agenda: SidebarOption = {
  content: i18n.t('menu.agenda'),
  key: 'agenda',
  icon: <Book />,
  exact: true,
  url: config.url.agendaNoInfo,
  permission: [
    SimpleUserRequestPermissionNames.RECORD_ADD,
    SimpleUserRequestPermissionNames.RECORD_ASSIGN,
    SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_ADD,
    SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_ADD,
  ],
};

export const QRRead: SidebarOption = {
  content: i18n.t('menu.viewqr'),
  key: 'qrread',
  icon: <Codesandbox />,
  exact: true,
  url: config.url.viewqr,
  permission: [SimpleUserRequestPermissionNames.RECORD_QR_READ],
};

export const adviceInfo: SidebarOption = {
  content: i18n.t('menu.information'),
  key: 'information',
  icon: <Info />,
  exact: true,
  url: config.url.information,
  permission: [SimpleUserRequestPermissionNames.ADVICE_INFO],
};

export const takeDeliveryPhoto: SidebarOption = {
  content: i18n.t('article.takeDeliveredPhoto'),
  key: 'deliveryPhoto',
  icon: <Camera data-testid="icon-camera" />,
  exact: true,
  url: config.url.articlePhoto,
  permission: [],
};

export const flowerTraking: SidebarOption = {
  content: i18n.t('menu.flowers'),
  key: 'flowers',
  icon: <CustomIcon icon={OtherTypes.FLOWERS} />,
  exact: true,
  url: config.url.flowersTraking,
  permission: [SimpleUserRequestPermissionNames.ARTICLE_READ],
};

export const scanArticleQR: SidebarOption = {
  content: i18n.t('menu.scan'),
  key: 'deliveryPhoto',
  icon: <Camera data-testid="icon-scan-qr" />,
  exact: true,
  url: config.url.articleInfo,
  permission: [SimpleUserRequestPermissionNames.ARTICLE_QR_READ],
};

export const budgetsTracking: SidebarOption = {
  content: i18n.t('budget.title'),
  key: 'budgetsTracking',
  icon: <Users />,
  exact: true,
  url: config.url.budgetsTracking,
  permission: [SimpleUserRequestPermissionNames.BUDGET_WRITE],
};

export const budgetsConfig: SidebarOption = {
  content: i18n.t('budget.config.title'),
  key: 'budgetsConfig',
  icon: <CustomIcon icon={OtherTypes.BUDGET_CONFIG} />,
  exact: true,
  url: config.url.budgetsArticles,
  permission: [SimpleUserRequestPermissionNames.BUDGET_RULES_WRITE],
};

// TODO: Waiting for the client to add
// const sales = {
//   content: i18n.t('menu.sales'),
//   key: 'sales',
//   icon: <DollarSign />,
//   url: config.url.sales,
//   permission: SimpleUserRequestPermissionNames.DIRECT_SALE_READ,
// };

export const getMenuByPermissions = (
  permissions: Permission[],
): SidebarOption[] => {
  const options: SidebarOption[] = [
    roles,
    ips,
    records,
    enquiries,
    enquiriesDashboard,
    workshop,
    calendar,
    agenda,
    QRRead,
    adviceInfo,
    flowerTraking,
    scanArticleQR,
    budgetsTracking,
    budgetsConfig,
  ];

  let menu: SidebarOption[] = [home];

  const menuFiltered: SidebarOption[] = [];

  permissions.forEach((permission) => {
    menu = [
      ...menu,
      ...options.filter(
        (optionSidebar: SidebarOption) => optionSidebar
          .permission?.includes(permission.name),
      ),
    ];

    menu.forEach((optionSidebar: SidebarOption) => {
      if (
        !menuFiltered.find((o: SidebarOption) => optionSidebar.key === o.key)
      ) {
        menuFiltered.push(optionSidebar);
      }
    });
  });
  return menuFiltered;
};

export default getMenuByPermissions;
