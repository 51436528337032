import { toString } from 'utils/helpers';
import { showErrorToast } from 'utils/toasts';

import { ContentType, HttpClient, RequestParams } from 'config/apiFunus/generated/http-client';
import { IFilter } from 'config/apiFunus/types';
import { IMasterDataDto } from 'models/MasterData';
import { BudgetsArticlesFilter } from 'modules/budget/components/Articles/table/types';
import { BudgetFormArticle } from 'modules/budget/components/budgetConfig/budgetForm/types';
import { ArticleCollectionTypes, ArticleType } from 'modules/budget/models/Article/types';
import { BudgetConfiguration } from 'modules/budget/models/BudgetConfiguration';
import { IBudgetConfiguration } from 'modules/budget/models/BudgetConfiguration/types';
import { Code } from 'modules/budget/models/Code';

import { MasterDataQuestionType, MasterDataWithWork } from '../types';

import { BudgetArticleResponse, ISaveConfigurationResponse, SaveConfigurationRequest } from './types';

interface GetArticlesTrackingResponse {
  numberOfItems: number;
  numberOfPages: number;
  list: Array<{
    code: string;
    description: string;
    hasRule: boolean;
    type: ArticleType;
    active: boolean;
    sorting: number;
    hasMandatoryResponse: boolean;
    hasOptionalResponse: boolean;
    hasIncludedClient: boolean;
    hasExcludedClient: boolean;
    hasIncludedArticle: boolean;
    hasExcludedArticle: boolean;
    hasIncludedLocation: boolean;
    hasExcludedLocation: boolean;
  }>;
}

export type ArticleTrackingItem = {
  code: string;
  description: string;
  type: ArticleType;
  active: boolean;
  hasRule: boolean;
  sorting: number;
  hasResponseRule: boolean;
  hasClientRule: boolean;
  hasArticleRule: boolean;
  hasLocationRule: boolean;
}

export class BudgetMasterDataClient<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  GetLocations = async (
    questionType: MasterDataQuestionType,
    countryId: string,
    provinceId: string,
    cityId: string,
    params: RequestParams = {},
  ): Promise<MasterDataWithWork[]> => this.http.request<MasterDataWithWork[], void>({
    method: 'GET',
    path: `/budgets/masters/locations/${questionType}/${countryId}/${provinceId}/${cityId}`,
    secure: true,
    type: ContentType.Json,
    ...params,
  })

  GetArticles = async (
    params: RequestParams = {},
  ): Promise<BudgetFormArticle[]> => this.http.request<BudgetArticleResponse, void>({
    method: 'GET',
    path: '/masters/articles',
    secure: true,
    type: ContentType.Json,
    ...params,
  }).then((resposne) => resposne)

  GetArticlesTracking = async (
    filter: IFilter<BudgetsArticlesFilter>,
    params: RequestParams = {},
  ): Promise<{
    numberOfItems: number;
    numberOfPages: number;
    list: Array<ArticleTrackingItem>,
  }> => {
    const body: IFilter<BudgetsArticlesFilter> = {
      filter: filter.filter,
      page: filter.page,
      sort: filter.sort,
    };

    return this.http.request<GetArticlesTrackingResponse, void>({
      body,
      method: 'POST',
      path: '/masters/articles/trackings',
      secure: true,
      type: ContentType.Json,
      ...params,
    })
      .then((response) => ({
        list: response.list.map((trackingItem): ArticleTrackingItem => ({
          active: trackingItem.active,
          code: trackingItem.code,
          description: trackingItem.description,
          hasArticleRule: trackingItem.hasExcludedArticle || trackingItem.hasIncludedArticle,
          hasClientRule: trackingItem.hasExcludedClient || trackingItem.hasIncludedClient,
          hasLocationRule: trackingItem.hasExcludedLocation || trackingItem.hasIncludedLocation,
          hasResponseRule: trackingItem.hasOptionalResponse || trackingItem.hasMandatoryResponse,
          hasRule: trackingItem.hasRule,
          sorting: trackingItem.sorting,
          type: trackingItem.type,
        })),
        numberOfItems: response.numberOfItems,
        numberOfPages: response.numberOfPages,
      }));
  }
  // Promise.resolve({
  //   list: [{
  //     active: true,
  //     code: 'anyString',
  //     description: 'anydescrpition',
  //     hasExcludedArticle: true,
  //     hasExcludedClient: true,
  //     hasExcludedLocation: true,
  //     hasIncludedArticle: true,
  //     hasIncludedClient: true,
  //     hasIncludedLocation: true,
  //     hasMandatoryResponse: true,
  //     hasOptionalResponse: true,
  //     hasRule: true,
  //     sorting: 1,
  //     type: ArticleType.AUTOMATIC,
  //   }],
  //   numberOfItems: 10,
  //   numberOfPages: 1,
  // })

  GetCoffinSizes = async (params: RequestParams = {}): Promise<IMasterDataDto[]> => this.http
    .request<{types: Array<string>}, void>({
      method: 'GET',
      path: '/coffins/sizes',
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => response.types.map((coffinSizeCode) => ({
      code: coffinSizeCode,
      description: coffinSizeCode,
    })))

  GetConfiguration = async (
    articleId: string,
    params: RequestParams = {},
  ): Promise<BudgetConfiguration> => this.http.request<ISaveConfigurationResponse, void>({
    method: 'GET',
    path: `/masters/articles/${articleId}/rules`,
    secure: true,
    type: ContentType.Json,
    ...params,
  }).then((response) => new BudgetConfiguration({
    active: response.active,
    article: articleId,
    bloc: response.bloc,
    coffinSize: response.coffinSize,
    excludedArticles: response.excludedArticles.map(Code.create),
    excludedClients: response.excludedClients.map(Code.create),
    excludedLocations: response.excludedLocations,
    image: response.image,
    includedArticles: response.includedArticles.map(Code.create),
    includedClients: response.includedClients.map(Code.create),
    includedLocations: response.includedLocations,
    mandatoryResponses: response.mandatoryResponses.map(Code.create),
    optionalResponses: response.optionalResponses.map(Code.create),
    order: response.sorting,
    questionCode: response.questionCode,
    subType: response.subType === null
      ? ArticleCollectionTypes.ARTICLE
      : response.subType,
    type: response.type,
  }))

  SaveConfiguration = async (
    articleId: string,
    configuration: IBudgetConfiguration,
    params: RequestParams = {},
  ): Promise<void> => this.http.request<void, void>({
    body: new SaveConfigurationRequest({
      active: configuration.active,
      bloc: configuration.bloc,
      coffinSize: configuration.coffinSize,
      excludedArticles: configuration.excludedArticles.map(toString),
      excludedClients: configuration.excludedClients.map(toString),
      excludedLocations: configuration.excludedLocations,
      image: configuration.image,
      includedArticles: configuration.includedArticles.map(toString),
      includedClients: configuration.includedClients.map(toString),
      includedLocations: configuration.includedLocations,
      mandatoryResponses: configuration.mandatoryResponses.map(toString),
      optionalResponses: configuration.optionalResponses.map(toString),
      questionCode: configuration.questionCode || null,
      sorting: configuration.order,
      subType: configuration.subType === ArticleCollectionTypes.ARTICLE
        ? null
        : configuration.subType,
      type: configuration.type || ArticleType.CATALOG,
    }),
    method: 'PUT',
    path: `/masters/articles/${articleId}/rules`,
    secure: true,
    type: ContentType.Json,
    ...params,
  })
    .catch((error) => {
      showErrorToast(error.message);
      throw error;
    })
}
