import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { BudgetSummary } from 'modules/budget/components/budgetSummary';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import './index.scss';

import { BudgetSummaryLocationParams, BudgetSummaryPageComponent } from './types';

export const BudgetSummaryPage: FC<BudgetSummaryPageComponent> = () => {
  const history = useHistory();
  const { state }: BudgetSummaryLocationParams = useLocation();
  const [gotoQuestionDone, setGotoQuestionDone] = useState(false);
  const { budgetId } = useParams<{
    budgetId: string;
  }>();

  const { GetBudget, currentQuestion, GoToSummary } = useBudget();

  useEffect(
    () => {
      GoToSummary();
      setGotoQuestionDone(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (gotoQuestionDone && budgetId && currentQuestion !== BudgetQuestionCodes.SUMMARY) {
        history.push({
          pathname: `/budget/wizard/${budgetId}/${currentQuestion}`,
          state: { gotoSummary: true },
        });
      }
    },
    [budgetId, currentQuestion, history, gotoQuestionDone],
  );

  useEffect(
    () => {
      if (budgetId && !state?.isBudgetLoaded) {
        GetBudget(Number(budgetId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [budgetId, state?.isBudgetLoaded],
  );

  return (
    <div className="f-budget-summary-page">
      <BudgetSummary
        budgetId={Number(budgetId)}
        showGotoTracking
      />
    </div>
  );
};
