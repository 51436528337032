export const priceFormat = (value: number): string => `${value.toLocaleString('es-ES', {
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'currency',
  useGrouping: true,
})}`;

export const priceFormatWithoutCurrency = (value: number): string => `${value.toLocaleString('es-ES', {
  maximumFractionDigits: 4,
  minimumFractionDigits: 2,
  useGrouping: false,
})}`;
