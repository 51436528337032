import classNames from 'classnames';
import {
  Formik,
  Form,
  FormikProps,
  FormikErrors,
  FormikValues,
  getIn,
} from 'formik';
import React, {
  ReactElement, useState, KeyboardEvent,
} from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { objectsAreDifferent } from 'utils/helpers';
import { showErrorToast } from 'utils/toasts';

import Button from 'components/base/Button';
import Loader from 'components/base/Loader';

import './index.scss';
import { clearAudit } from './helper';
import { FormStep, StepsFormProps } from './types';

const StepsForm = <T extends Record<string, unknown>>({
  buttons,
  className,
  disabled,
  initialValues,
  onButtonCallback,
  onSave,
  rawEntity,
  sendOnEnter,
  steps,
  title,
  subtitle,
  validateForm,
  validateOnBlur,
  validateOnChange,
}: StepsFormProps<T>): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number>(0);
  const { t } = useTranslation();

  const goTo = (index: number) => {
    setSelected(index);
  };

  const goBack = (index: number, stepsResults: FormStep<T>[]) => {
    if (index >= 0 && index < stepsResults.length) {
      if (stepsResults[index].disabled) {
        goBack(index - 1, stepsResults);
      } else {
        goTo(index);
      }
    }
  };

  const goNext = (index: number, stepsResults: FormStep<T>[]) => {
    if (index >= 0 && index < stepsResults.length) {
      if (stepsResults[index].disabled) {
        goNext(index + 1, stepsResults);
      } else {
        goTo(index);
      }
    }
  };

  const getStepErrors = (fields: string[], errors: FormikErrors<T>): number => {
    let total = 0;
    if (fields?.length) {
      fields.forEach((field) => getIn(errors, field) && total++);
    }
    return total;
  };

  const onKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (
      !sendOnEnter
      && event.key === 'Enter'
      && event.target.toString() !== '[object HTMLTextAreaElement]'
    ) {
      event.preventDefault();
    }
  };

  const onClickButton = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise: (values: FormikValues) => Promise<any>,
    values: FormikValues,
  ) => {
    setLoading(true);
    promise(values)
      .then((res) => {
        setLoading(false);
        if (onButtonCallback) {
          onButtonCallback();
        }
        return res;
      })
      .catch((err) => {
        showErrorToast(err.message);
        setLoading(false);
      });
  };

  return (
    <div className={classNames('funus-step-form', className)}>
      {loading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        onSubmit={onSave}
      >
        {(props: FormikProps<T>) => {
          const stepsResults = steps(props.values);
          const hasErrors = !props.isValid;
          return (
            <Form autoComplete="off" className="fields" onKeyDown={onKeyDown}>
              <Prompt
                message={t('common.unsaved')}
                when={
                  !disabled
                  && objectsAreDifferent<T>(
                    clearAudit(rawEntity) || clearAudit(initialValues),
                    clearAudit(props.values),
                  )
                }
              />
              <div className="form-steps">
                <div>
                  <div className="form-title">
                    {typeof title === 'function' ? title(props.values) : title}
                  </div>
                  {subtitle && typeof subtitle === 'function' && (
                  <div className="form-title">{subtitle(props.values)}</div>
                  )}
                  <div className="steps-buttons">
                    {stepsResults.map((step, idx) => {
                      const total = getStepErrors(
                        step.formFields,
                        props.errors,
                      );

                      return (
                        <Button
                          key={step.key}
                          className={classNames({
                            errors: total > 0,
                            selected: selected === idx,
                          })}
                          color="transparent"
                          disabled={step.disabled}
                          text={step.title}
                          onClick={() => setSelected(idx)}
                        >
                          {total > 0 && (
                          <div className="errors-total">{total}</div>
                          )}
                        </Button>
                      );
                    })}
                  </div>
                </div>
                {buttons
                  && buttons(props.values).map((button) => (
                    <Button
                      key={button.key}
                      color={button.color}
                      disabled={
                        disabled
                        || hasErrors
                        || props.isSubmitting
                        || button.disabled
                      }
                      text={button.text}
                      onClick={() => onClickButton(button.onClickPromise, props.values)}
                    />
                  ))}
                <Button
                  color="special"
                  disabled={disabled || hasErrors || props.isSubmitting}
                  text={t('common.save')}
                  type="submit"
                />
              </div>
              <div className="form-body">
                {stepsResults.map((step, idx) => (
                  <div
                    key={step.key}
                    className={classNames('step-content', {
                      previous: (!selected && selected !== 0) || selected > idx,
                      selected: selected === idx,
                    })}
                  >
                    <Button
                      className={classNames('prev-button', {
                        hidden: idx <= 0,
                      })}
                      color="secondary"
                      disabled={idx <= 0 || selected !== idx}
                      leftAddon={<ChevronLeft />}
                      text={t('common.prev')}
                      onClick={() => goBack(idx - 1, stepsResults)}
                    />
                    {selected === idx && (
                    <div className="step-info">
                      <div className="step-title">{step.title}</div>
                      <div className="step-fields">
                        {step.fields(props, disabled)}
                      </div>
                    </div>
                    )}
                    <Button
                      className={classNames('next-button', {
                        hidden: idx >= stepsResults.length - 1,
                      })}
                      color="secondary"
                      disabled={
                        idx >= stepsResults.length - 1 || selected !== idx
                      }
                      rightAddon={<ChevronRight />}
                      text={t('common.next')}
                      onClick={() => goNext(idx + 1, stepsResults)}
                    />
                  </div>
                ))}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

StepsForm.defaultProps = {
  buttons: undefined,
  className: undefined,
  disabled: undefined,
  onButtonCallback: undefined,
  rawEntity: undefined,
  sendOnEnter: false,
  subtitle: undefined,
  title: undefined,
  validateForm: false,
  validateOnBlur: true,
  validateOnChange: true,
};

export default StepsForm;
