import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { Column } from 'components/base/Column';

import { CeremonyTypeSelector } from 'modules/budget/components/articleSelectors/CeremonyTypeSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { CeremonyTypes } from 'modules/budget/data/ceremonyTypeCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';

export interface Q22Response {
  responseValue: CeremonyTypes | null;
  autorespond: boolean;
}

interface Q22FormComponent {
  onChange: (value: Q22Response) => void
  question: BudgetQuestionCodes;
}

export const Q22Form: FC<Q22FormComponent> = ({ onChange, question }) => {
  const { GetQuestionValue } = useBudget();
  const [selectedType, setSelectedType] = useState<CeremonyTypes | null>();

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue) {
        onChange({
          autorespond: false,
          responseValue: storedValue,
        });
        setSelectedType(storedValue);
      }
    },
    [onChange, storedValue],
  );

  return (
    <Formik
      initialValues={{
        autorespond: false,
        responseValue: null,
      }}
      validate={(values) => {
        onChange({
          autorespond: values.autorespond,
          responseValue: values.responseValue,
        });
        setSelectedType(values.responseValue);

        return {};
      }}
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues,
      }: FormikProps<Q22Response>) => (
        <Column>
          <CeremonyTypeSelector
            value={selectedType || ''}
            onChange={(val: string | undefined) => {
              setValues({
                autorespond: true,
                responseValue: val as CeremonyTypes,
              });
            }}
          />
        </Column>
      )}
    </Formik>
  );
};
