import { OrderLineChannel, ClientDto } from 'config/apiFunus/generated/data-contracts';

import { IOrderDraft } from './types';

export class OrderDraft implements IOrderDraft {
  constructor(params: IOrderDraft) {
    this.category = params.category;
    this.channel = params.channel;
    this.client = params.client;
    this.code = params.code;
    this.comments = params.comments;
    this.date = params.date;
    this.dedicatory = params.dedicatory;
    this.erpId = params.erpId;
    this.observations = params.observations;
    this.price = params.price;
    this.time = params.time;
    this.idSupplier = params.idSupplier;
    this.recordId = params.recordId;
    this.articleDescription = params.articleDescription;
  }

  recordId: number;

  idSupplier: string;

  category?: string | undefined;

  channel: OrderLineChannel;

  client?: ClientDto | undefined;

  code?: string | undefined;

  comments?: string | undefined;

  date?: string | undefined;

  dedicatory?: string | undefined;

  erpId: number;

  observations?: string | undefined;

  price?: number | undefined;

  time?: string | undefined;

  articleDescription?: string | undefined;
}
