import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import { useVigilLocation } from 'hooks/useVigilLocation';
import { PreparationBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, displayDate, displayHour, WorkshopCardHeaderDetail,
} from './CardDetails';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PreparationDetails = ({ order }: { order: PreparationBaseWorkOrder }) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);
  const { getVigilLocation, vigilLocations } = useVigilLocation(order.city || '');

  useEffect(() => {
    const preparationDetails = [{
      label: t('order.details.vigilDate'),
      value: displayDate(order.entryVigilDate),
    },
    {
      label: t('order.details.vigilHour'),
      value: displayHour(order.entryVigilTime),
    },
    {
      label: t('order.details.vigilLocation'),
      value: getVigilLocation(order.vigilLocation)?.description || displayNoValue,
    }];

    setDetails(preparationDetails);
  }, [
    order,
    t,
    getVigilLocation,
    vigilLocations,

  ]);

  return (<CardDetails details={details} />);
};
