import React, { FC, useState } from 'react';

interface HoverContainerComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultStyle: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hoverStyle: any;
}

export const HoverContainer: FC<HoverContainerComponent> = (
  { defaultStyle = {}, hoverStyle = {}, children },
) => {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <div
      style={!hovered ? defaultStyle : hoverStyle}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {children}
    </div>
  );
};
