const user = {
  access: 'Acceso',
  assignedUsers: 'Usuarios asignados',
  assignUser: 'Asignar Usuario',
  changePassword: 'Cambiar contraseña',
  changePasswordKo: 'Se ha producido un error al cambiar contraseña',
  changePasswordOk: 'La contraseña se ha cambiado correctamente',
  currentPassword: 'Contraseña actual',
  customRigths: 'Permisos personalizados',
  detail: 'Detalle de usuario',
  duplicated: 'Ya hay un usuario registrado con ese email',
  edit: 'Edición de usuario',
  email: 'Email',
  errorFileSize: 'Máximo tamaño de imagen alcanzado, permitido',
  fakeEmail: 'El usuario se ha registrado correctamente, pero no se ha podido enviar el email a la dirección proporcionada. Por favor, verifique que la dirección es correcta.',
  forgotPassword: 'La he olvidado',
  forgotPasswordOk:
    'Se ha enviado un email con las instrucciones a su cuenta de correo.',
  login: 'Entrar',
  new: 'Nuevo usuario',
  newPassword: 'Introduce la nueva contraseña',
  newUserError: 'Per accedir a aquesta pàgina, primer heu de sortir de l\'aplicació.',
  password: 'Contraseña',
  profile: {
    allowNotifications: 'Permitir notificaciones push',
    changeImage: 'Cambiar imagen',
    changePass: 'Cambiar contraseña',
    imageKo: 'Fallo al subir imagen',
    imageOk: 'Imagen guardada correctamente',
    saved: 'Datos actualizados',
    title: 'Perfil de usuario',
  },
  recover: 'Recupera tu cuenta',
  reduceFileSize: 'Reduzca el tamaño o peso de la imagen',
  repeatNewPassword: 'Repetir nueva contraseña',
  resendPasswordMail: {
    Ko: 'Ha habido un problema con el envio de email para el cambio de contraseña del usuario.',
    Ok: 'Se enviará un email para el cambio de contraseña al email del usuario.',
  },
  role: {
    clone: 'Clonar',
    createdBy: 'Rol Creado por',
    deleteKo: 'Fallo al eliminar el rol',
    deleteOk: 'Registro eliminado correctamente',
    description: 'Descripción de rol',
    detail: 'Detalles de rol',
    edit: 'Edición de rol',
    new: 'Nuevo rol',
    placeholder: 'Roles de usuario',
    title: 'Roles',
  },
  saveKo: 'Fallo al grabar usuario',
  saveOk: 'Usuario guardado correctamente',
  search: 'Buscar usuarios',
  title: 'Usuarios',
  userImage: 'Imagen de usuario',
  username: 'Nombre de usuario',
  workplace: 'Centro de trabajo',
};
export default user;
