import classNames from 'classnames';
import React, {
  FC, useMemo,
} from 'react';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { BudgetSummaryLineActions } from '../../BudgetActions';
import { BudgetsTrackingFilterStatus } from '../../tracking/table/types';
import { BudgetSummaryLineAmount } from '../budgetSummaryLineAmount/indext';
import { BudgetSummaryLinePrices } from '../budgetSummaryLinePrices';

import './index.scss';
import { BudgetSummaryLineComponent } from './types';

export const BudgetSummaryLine: FC<BudgetSummaryLineComponent> = (
  {
    article, budgetId, showActions, className = '',
  },
) => {
  const { budget } = useBudget();

  const enableActions = useMemo(
    () => {
      const blockedActionsStates = new Set([
        BudgetsTrackingFilterStatus.SIGNED,
        BudgetsTrackingFilterStatus.INVOICED,
      ]);
      return !blockedActionsStates.has(budget.status);
    },
    [budget.status],
  );

  return (
    <tr className={classNames('budget-summary-line', className)}>
      <td className="budget-summary-line-code">{article.code}</td>
      <td className="budget-summary-line-description">{article.description}</td>
      <td className="budget-summary-line-amount">
        <BudgetSummaryLineAmount
          article={article}
          disabled={!enableActions}
        />
      </td>
      <td className="budget-summary-line-price">
        <BudgetSummaryLinePrices
          article={article}
          disabled={!enableActions}
        />
      </td>
      {showActions && (
      <td className="budget-summary-line-actions">
        {enableActions
          ? <BudgetSummaryLineActions article={article} budgetId={budgetId} />
          : <React.Fragment />}
      </td>
      )}
    </tr>
  );
};
