const service = {
  CEREMONIA: 'Ceremonia',
  ceremony: {
    accompanimentCars: 'Coches de acompañamiento',
    agency: 'Gestoría',
    ceremonial: 'Ceremonial',
    ceremony: 'Ceremonia',
    currentCeremony: 'Ceremonia actual',
    flowers: 'Flores',
    hearses: 'Coches fúnebres',
    location: 'Ubicación de la ceremonia',
    music: 'Música',
    obituariesReminders: 'Esquelas y recordatorios',
    postInterment: 'Ceremonia post entierro',
    pressCommunication: 'Comunicación a la prensa',
    pressObituaries: 'Esquelas prensa',
    previousCeremony: 'Ceremonia anterior',
    streaming: 'Grabación / Streaming',
    type: 'Tipo de celebración',
  },
  ceremonyType: {
    LAICA: 'Laica',
    RELIGIOSA: 'Religiosa',
  },
  clear: 'Limpiar pantalla',
  clientAssigned: 'El cliente está asociado a un artículo. Antes de eliminarlo, debe eliminar dicha asociación.',
  coffinType: {
    ABIERTO: 'Abierto',
    CERRADO: 'Cerrado',
  },
  cremation: {
    ashes: 'Cenizas',
    ashesDeliveryDate: 'Fecha entrega cenizas',
    ashesDeliveryHandler: 'Tramitador entrega cenizas',
    ashesDestiny: 'Destino cenizas',
    ashesReceiver: 'Receptor cenizas',
    ashesReceiverIdentifier: 'Documento identificativo receptor',
    cremation: 'Incineración',
    cremationDate: 'Fecha incineración',
    cremationTime: 'Hora incineración',
    crematorium: 'Crematorio',
    documentationOK: 'OK Documentación para Incinerar',
    documentationOkDetail2: 'Indica que se dispone de toda la documentación para proceder a la incineración: <br/>MUERTE NORMAL: <br/>* Solicitud Familia<br/>* CMD<br/>* Licencia<br/>MUERTE JUDICIAL:<br/>* Solicitud Familia<br/>* Carta Orden<br/>* Permiso Judicial<br/>* Licencia',
    documentationTime: 'Ok Documentación Fecha y hora',
    documentationUser: 'OK Documentación Usuario',
    entryDate: 'Fecha llegada',
    entryTime: 'Hora llegada',
    familyPresence: 'Familia presente',
    jewels: 'Joyas / Colgantes',
    judicial: 'Judicial',
    operator: 'Operario',
    originMortuary: 'Funeraria procedencia',
    oven: 'Horno',
    owner: 'Propietario urna',
    reliquary: 'Relicario',
    urnModel: 'Modelo de urna',
    zincBox: 'Caja zinc',
  },
  deceasedLocation: {
    CAMARA: 'Cámara',
    CENTRO: 'Sin cámara',
  },
  deposit: {
    deposit: 'Depósito',
    entryDate: 'Fecha llegada',
    entryTime: 'Hora llegada',
  },
  DEPOSIT: 'Depósito',
  duplicatedClient: 'El cliente ya está incluido en el listado.',
  finalDestination: {
    CEMENTERIO: 'Cementerio',
    CREMATORIO: 'Crematorio',
  },
  INCINERACION: 'Incineracion',
  INHUMACION: 'Inhumacion',
  interment: {
    ashes: 'Inhumación cenizas',
    cemetery: 'Cementerio',
    concession: {
      beneficiary: 'Beneficiario',
      holder: 'Titular',
      temporality: 'Temporalidad',
      title: 'Concesión',
      type: 'Tipo',
    },
    familyPresence: 'Familia presente',
    floor: 'Piso',
    idCard: 'DNI',
    interment: 'Inhumación',
    intermentDate: 'Fecha inhumación',
    intermentTime: 'Hora inhumación',
    judicial: 'Judicial',
    measurementsAndPhotos: 'Tomar medidas y fotos',
    nichePreparationDate: 'Fecha preparación nicho',
    nichePreparationTime: 'Hora preparación nicho',
    number: 'Número',
    section: 'Sección',
    sepulture: 'Sepultura',
    zincBox: 'Caja zinc',
  },
  loadKo: 'Se ha producido un error al cargar el expediente.',
  movement: {
    type: {
      BOX: 'CAJA',
      BUNK: 'CAMILLA',
    },
  },
  MOVIMIENTO: 'Movimiento',
  noDeclarant: 'Debe introducir los datos del declarante antes de continuar con el asesoramiento.',
  onClearText: 'Al eliminar este servicio se guardarán todos los datos de otros servicios y los datos que se van a eliminar, no se podrán recuperar. ¿Desea continuar?',
  PREPARACION: 'Preparación',
  preparation: {
    aconditioning: 'Acondicionar',
    applicant: 'Solicitante de práctica sanitaria',
    atHome: 'Realizar en domicilio de defunción',
    clothing: 'Vestimenta',
    coffin: 'Féretro',
    coffinArticle: 'Ataúdes',
    coffinPreparationDetail: 'Detalle preparación féretro',
    cover: 'Amortallar',
    creu: 'Con cruz',
    deceased: 'Titular del expediente',
    deceasedLocation: 'Ubicación del difunto',
    ESTANDAR: 'Estándar',
    finalDestination: 'Destino final',
    hairstyle: 'Peinado',
    makeup: 'Maquillaje',
    pacemaker: 'Marcapasos',
    personalObjects: 'Objetos personales',
    PRACTICA_SANITARIA: 'Práctica sanitaria',
    preparation: 'Tipo',
    sanitaryPractice: 'Práctica sanitaria',
    sanitaryPracticeAuthorization: 'Autorización',
    shaved: 'Afeitado',
    thanatologist: 'Tanatólogo',
    thanatopractor: 'Tanatopractor',
    transfer: 'Transporte',
  },
  recipient: {
    address: 'Dirección del destinatario',
    name: 'Nombre del destinatario',
    title: 'Destinatario',
  },
  sanitaryPractice: {
    ADOL: 'Adol',
    CELOFANA: 'Celofán',
    MARCAPASOS: 'Marcapasos',
    SERRADURES_SULFAT: 'Serrín sulfato',
    TAPONAR: 'Taponar',
  },
  saveKo: 'Se ha producido un error al guardar el expediente.',
  saveOk: 'El expediente se ha guardado satisfactoriamente.',
  sign: {
    signKo: 'Se ha producido un error al guardar la firma.',
    signOk: 'La firma se ha guardado satisfactoriamente',
  },
  transfer: {
    agency: 'Agencia',
    arrivalDate: 'Fecha llegada',
    arrivalTime: 'Hora llegada',
    borderCrossing: 'Paso fronterizo',
    commentDestination: 'Observaciones destino',
    commentTransport: 'Observaciones transporte',
    company: 'Empresa',
    connectionNumber: 'Nº de conexión',
    deliveryDate: 'Fecha entrega',
    deliveryTime: 'Hora entrega',
    departureAirport: 'Aeropuerto salida',
    departureDate: 'Fecha salida',
    departureTime: 'Hora salida',
    destination: 'Destino',
    destinationAirport: 'Aeropuerto destino',
    estimatedArrivalDate: 'Fecha llegada estimada',
    estimatedArrivalTime: 'Hora llegada estimada',
    flightNumber: 'Nº de vuelo',
    measures: 'Medidas caja',
    scale: 'Escala',
    type: 'Tipo de transporte',
    types: {
      CAR: 'Carretera',
      PLANE: 'Avión',
    },
    weight: 'Peso caja',
  },
  TRANSPORTE: 'Transporte',
  VELATORIO: 'Velatorio',
  vigil: {
    address: 'Dirección de la vigilia',
    atHome: 'En el domicilio',
    entryDate: 'Fecha entrada vigilia',
    entryTime: 'Hora entrada vigilia',
    exitDate: 'Fecha salida vigilia',
    exitTime: 'Hora salida vigilia',
    externalNumber: 'Nº expediente externo',
    location: 'Lugar de la vigilia',
    room: 'Sala',
    transfer: 'Transporte',
    vigil: 'Vigilia',
    vigilComplements: 'Complementos velatorio',
    vigilRemarks: 'Observaciones de la vigilia',
  },
  workorders: {
    CEREMONIA: 'Ceremonia',
    INCINERACION: 'Incineracion',
    INHUMACION: 'Inhumacion',
    LAPIDA: 'Lápida',
    MANUAL_CEMENTERIO: 'Manual cementerio',
    MANUAL_TALLER: 'Manual taller',
    MOVIMIENTO: 'Movimento',
    MOVIMIENTO_INTERNO: 'Movimiento interno',
    PRACTICA_SANITARIA: 'Practica sanitaria',
    PREPARACION: 'Preparacion',
    PREPARACION_NICHO: 'Preparacion de nicho',
    RECEPCION: 'Recepcion',
    RECOGIDA: 'Recogida',
    TRASLADO: 'Traslado',
    TRASLADO_RESTOS: 'Traslado de restos',
  },
};
export default service;
