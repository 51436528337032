import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { FC, ReactElement, useMemo } from 'react';
import { Mail } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from 'config';

import { checkHaveThisPermissions, checkPermission } from 'utils/permissions';
import { resendEmail } from 'utils/userUtils';

import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import { TableActionsProps } from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { i18n } from 'i18n';
import User, { SearchUserProps } from 'models/User';
import UserRole from 'models/UserRole';
import { RootState } from 'store';

type RoleExtended = UserRole & {
  translation: string;
};

const fields = (formikProps: FormikProps<SearchUserProps>): ReactElement[] => [
  <SideFilterField
    key="name"
    filterType="input"
    formikProps={formikProps}
    name="name"
    placeholder={`${i18n.t('common.name')}`}
  />,
  <SideFilterField
    key="email"
    filterType="input"
    formikProps={formikProps}
    name="email"
    placeholder={`${i18n.t('common.email')}`}
  />,
];

const UserTable: FC = () => {
  const { normalizedRoles } = useSelector((state: RootState) => state.roles);
  const { t } = useTranslation();
  const { user } = useProvidedAuth();
  const columns = useMemo(
    (): TableColumn<User>[] => [
      { accessor: 'id', Header: 'Id', hidden: true },
      {
        accessor: 'name',
        Cell: ({
          row: {
            original: { name, firstSurname, secondSurname },
          },
        }) => classNames(name, firstSurname, secondSurname) || '',
        Header: `${t('common.name')}`,
        sortable: true,
      },
      {
        accessor: 'email',
        className: 'lowercase',
        Header: `${t('common.email')}`,
        sortable: true,
      },
      {
        accessor: 'idrole',
        Cell: ({ cell: { value } }) => {
          const role = normalizedRoles ? normalizedRoles[value] as RoleExtended : null;
          return role?.name || '';
        },
        Header: `${t('user.role.title')}`,
        sortable: true,
      },
    ],
    [t, normalizedRoles],
  );

  const getActions = (row: User) => {
    const actions: TableActionsProps = { extra: [] };
    if (
      checkPermission(SimpleUserRequestPermissionNames.USER_READ, user?.role.permissions)
      && !checkPermission(SimpleUserRequestPermissionNames.USER_WRITE, user?.role.permissions)
    ) {
      actions.see = {
        icon: <CustomIcon icon={FeatherIconTypes.EYE} />,
        tooltipCaption: t('common.see'),
        url: `${config.url.users}/${row.id}`,
      };
    }

    if (
      checkHaveThisPermissions(
        [SimpleUserRequestPermissionNames.USER_READ, SimpleUserRequestPermissionNames.USER_WRITE],
        user?.role.permissions,
      )
    ) {
      actions.edit = {
        icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
        tooltipCaption: t('common.edit'),
        url: `${config.url.users}/${row.id}/edit`,
      };
      actions.email = {
        icon: <Mail />,
        onClick: () => resendEmail(row),
        tooltipCaption: t('common.email'),
      };
    }

    return actions;
  };

  return (
    <FilteredPage<SearchUserProps, User>
      actions={getActions}
      apiCall={config.apiFunus.users.getUsers}
      columns={columns}
      create={{
        title: t('user.new'),
        url: config.url.newUsers,
      }}
      fields={fields}
      initialValues={{
        name: '',
      }}
      text={{
        search: t('user.search'),
        title: t('user.title'),
      }}
    />
  );
};

export default UserTable;
