import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import useCemetery from 'hooks/useCemetery';
import { IntermentBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, displayDate, displayHour, WorkshopCardHeaderDetail,
} from './CardDetails';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InhumationDetails = ({ order }:
  { order: IntermentBaseWorkOrder }) => {
  const { t } = useTranslation();
  const { getCemetery, normalizedCemeteries } = useCemetery(order.city);

  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);

  useEffect(() => {
    setDetails([{
      label: t('common.date'),
      value: displayDate(order.intermentdate),
    },
    {
      label: t('common.hour'),
      value: displayHour(order.time),
    },
    {
      label: t('order.details.cemetery'),
      value: getCemetery(order.cemetery)?.description || displayNoValue,
    },
    ]);
  }, [
    order,
    t,
    getCemetery,
    normalizedCemeteries,
  ]);

  return (<CardDetails details={details} />);
};
