import classNames from 'classnames';
import React, { FC } from 'react';
import ReactDOM from 'react-dom';

import logo from 'assets/images/logo_funus.svg';

import './index.scss';

type LoaderProps = {
  className?: string;
  fullscreen?: boolean;
};
const loaderRoot = document.createElement('div');
loaderRoot.setAttribute('id', 'loader-root');
document.body.appendChild(loaderRoot);
const element = document.createElement('div');
loaderRoot.appendChild(element);

const Loader: FC<LoaderProps> = ({ className, fullscreen = true }) => {
  if (fullscreen) {
    return ReactDOM.createPortal(
      <div className={classNames('loader fullscreen-loader', className)}>
        <div className="rotating">
          <img alt="funus-loader" src={logo} />
        </div>
      </div>,
      element,
    );
  }
  return (
    <div className={classNames('loader', className)}>
      <div className="rotating">
        <img alt="funus-loader" src={logo} />
      </div>
    </div>
  );
};

export default Loader;
