import { Middleware } from 'redux';

import { showErrorToast } from 'utils/toasts';

import { i18n } from 'i18n';

import { budgetController } from '../../api';
import { SetInvoiceArticles, SetInvoiceClients, setInvoiceArticlesLoading } from '../budgetActions';
import { BudgetActionTypes } from '../budgetActions/types';

export const FetchInvoiceArticles: Middleware = (
  storeApi,
) => (next) => async (action) => {
  if (
    action.type === BudgetActionTypes.GET_INVOICE_ARTICLES) {
    const result = next(action);

    const load = async () => budgetController.GetInvoiceArticles(action.payload.idBudget);

    try {
      storeApi.dispatch(setInvoiceArticlesLoading({ loading: true }));
      const budgetArticlesInfo = await load();
      storeApi.dispatch(setInvoiceArticlesLoading({ loading: false }));

      storeApi.dispatch(SetInvoiceArticles({ articles: budgetArticlesInfo.articles }));
      storeApi.dispatch(SetInvoiceClients({ clients: budgetArticlesInfo.clients }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      storeApi.dispatch(setInvoiceArticlesLoading({ loading: false }));
      showErrorToast(i18n.t(error.message));
    }

    return result;
  }

  return next(action);
};
