import React, {
  FC, Fragment, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SelectProps } from 'components/base/Select';

import { useCities } from 'hooks/useCities';
import { useCountries } from 'hooks/useCountries';
import { useProvinces } from 'hooks/useProvinces';
import { CityType, IMasterDataDto, ProvinceType } from 'models/MasterData';

import { AddCity } from '../AddCity';
import { Row } from '../Row';

const initCountry = { code: '011', description: 'ESPAÑA' };

type BaseSelectProps<T> = Omit<
  Omit<Omit<SelectProps<T>, 'options'>, 'value'>,
  'onChange'
> & {
  onChange(value?: string, work?: boolean): void;
  onChangeFull?: (value?: T) => void;
  value: string;
};

type SelectorType = {
  cityProps: BaseSelectProps<CityType>;
  countryProps: BaseSelectProps<IMasterDataDto>;
  disabled?: boolean | undefined;
  initialize?: boolean;
  provinceProps: BaseSelectProps<ProvinceType>;
  showAdd?: boolean;
};

const SelectCountryProvinceCity: FC<SelectorType> = ({
  cityProps,
  countryProps,
  disabled,
  initialize = true,
  provinceProps,
  showAdd,
}) => {
  const { cities, getCityByNormalizedCode } = useCities();
  const { provinces, getProvinceByNormalizedCode } = useProvinces();
  const { countries, getCountry } = useCountries();

  const [filteredProvinces, setFilteredProvinces] = useState<ProvinceType[]>([]);

  useEffect(
    () => {
      setFilteredProvinces(provinces);
    },
    [provinces],
  );

  const { t } = useTranslation();

  const handleChangeCountry = (e?: string) => {
    countryProps.onChange(e);

    setFilteredProvinces(e
      ? provinces.filter(
        (province: ProvinceType) => province.countryCode === e,
      )
      : provinces);

    if (provinceProps.value) {
      provinceProps.onChange();
    }
    if (cityProps.value) {
      cityProps.onChange();
    }
  };

  const handleChangeProvince = (prov?: ProvinceType) => {
    provinceProps.onChange(prov?.code);
    countryProps.onChange(prov?.countryCode);
    if (cityProps.value) {
      cityProps.onChange();
    }
  };

  const handleChangeCity = (city?: CityType) => {
    cityProps.onChange(city?.code, city?.work);
    provinceProps.onChange(city?.provinceCode);
    countryProps.onChange(city?.countryCode);

    setFilteredProvinces(city?.code
      ? provinces.filter(
        (province: ProvinceType) => province.countryCode === cities.find(
          (c: CityType) => c.code === city?.code,
        )?.countryCode,
      )
      : provinces);

    if (cityProps.onChangeFull && city) {
      const newCity: CityType = { ...city };
      newCity.postalCode = city?.postalCode?.toLowerCase() === 'null' ? '' : `${city?.postalCode}`;
      cityProps.onChangeFull(newCity);
    }
  };

  useEffect(() => {
    if (initialize) {
      countryProps.onChange(initCountry.code);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Row>
        <Select<CityType>
          data-testid="cities-selector"
          disabled={cityProps.disabled || disabled}
          error={cityProps.error}
          getLabel={cityProps.getLabel}
          getValue={cityProps.getValue}
          limit={100}
          name={cityProps.name}
          options={cities}
          placeholder={cityProps.placeholder || t('common.city')}
          value={getCityByNormalizedCode(countryProps.value, provinceProps.value, cityProps.value)}
          searchable
          onBlur={cityProps.onBlur}
          onChange={handleChangeCity}
        />
        {showAdd ? <AddCity /> : <React.Fragment /> }
      </Row>
      <Select<ProvinceType>
        disabled={provinceProps.disabled || disabled}
        error={provinceProps.error}
        getLabel={provinceProps.getLabel}
        getValue={provinceProps.getValue}
        limit={15}
        name={provinceProps.name}
        options={filteredProvinces}
        placeholder={provinceProps.placeholder || t('common.province')}
        testid="provinces-selector"
        value={getProvinceByNormalizedCode(provinceProps.value, countryProps.value)}
        searchable
        onBlur={provinceProps.onBlur}
        onChange={handleChangeProvince}
      />
      <Select
        data-testid="countries-selector"
        disabled={countryProps.disabled || disabled}
        error={countryProps.error}
        getLabel={countryProps.getLabel}
        getValue={countryProps.getValue}
        name={countryProps.name}
        options={countries}
        placeholder={countryProps.placeholder || t('common.country')}
        value={getCountry(countryProps.value)}
        searchable
        onBlur={countryProps.onBlur}
        onChange={(val) => handleChangeCountry(val?.code)}
      />
    </Fragment>
  );
};

export default SelectCountryProvinceCity;
