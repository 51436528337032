import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/base/Select';

import { useCountries } from 'hooks/useCountries';
import { useProvinces } from 'hooks/useProvinces';
import { ProvinceType } from 'models/MasterData';

import { AddCityFormComponent } from './types';

export const AddCityForm: FC<AddCityFormComponent> = ({ onChange }) => {
  const { t } = useTranslation();
  const { provinces, getProvinceByNormalizedCode } = useProvinces();
  const { countries, getCountry } = useCountries();

  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedProvince, setSelectedProvince] = useState<string>('');

  const [filteredProvinces, setFilteredProvinces] = useState<ProvinceType[]>([]);

  useEffect(
    () => {
      onChange({
        countryId: selectedCountry,
        provinceId: selectedProvince,
      });
    },
    [onChange, selectedCountry, selectedProvince],
  );

  useEffect(
    () => {
      setFilteredProvinces(provinces);
    },
    [provinces],
  );

  const handleChangeCountry = useCallback(
    (e?: string) => {
      setSelectedCountry(e || '');
      setSelectedProvince('');
      setFilteredProvinces(e
        ? provinces.filter(
          (province: ProvinceType) => province.countryCode === e,
        )
        : provinces);
    },
    [provinces],
  );

  const handleChangeProvince = useCallback(
    (prov?: ProvinceType) => {
      setSelectedProvince(prov?.code || '');
      setSelectedCountry(prov?.countryCode || '');
    },
    [],
  );

  return (
    <React.Fragment>
      <Select
        data-testid="countries-selector"
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="new-country"
        options={countries}
        placeholder={t('common.country')}
        value={getCountry(selectedCountry)}
        searchable
        onChange={(val) => handleChangeCountry(val?.code)}
      />
      <Select<ProvinceType>
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        limit={15}
        name="new-province"
        options={filteredProvinces}
        placeholder={t('common.province')}
        testid="provinces-selector"
        value={getProvinceByNormalizedCode(selectedProvince, selectedCountry)}
        searchable
        onChange={handleChangeProvince}
      />
    </React.Fragment>

  );
};
