import classNames from 'classnames';
import {
  Form, Formik, FormikProps, getIn,
} from 'formik';
import React, {
  FC, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import articlePlaceholder from 'assets/images/article-placeholder.svg';

import { toString } from 'utils/helpers';

import BackButton from 'components/base/BackButton';
import Button from 'components/base/Button';
import { Column } from 'components/base/Column';
import Input from 'components/base/Input';
import Loader from 'components/base/Loader';
import { Panel } from 'components/base/Panel';
import RadioButton from 'components/base/RadioButton';
import { Row } from 'components/base/Row';
import Select from 'components/base/Select';

import Upload from 'components/user/Upload';

import { useValidator } from 'hooks/useValidator';
import { IMasterDataDto } from 'models/MasterData';
import { MasterDataQuestionType } from 'modules/budget/api/types';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { ArticleCollectionTypes, ArticleType } from 'modules/budget/models/Article/types';
import { BudgetConfigurationLocation } from 'modules/budget/models/BudgetConfigurationLocation';
import { Code } from 'modules/budget/models/Code';

import { BudgetLocationSelector } from '../../articleSelectors/BudgetLocationSelector';
import { CitySelector } from '../../commons/CitySelector';

import { ArticlesTable } from './ArticlesTable';
import { useBudgetForm } from './helper';
import { InsuranceTable } from './InsuranceTable';
import { LocationsTable } from './LocationsTable';
import { ResponsesTable } from './ResponsesTable';
import {
  BudgetFormComponent,
  IBudgetFormProps,
  BUDGET_FORM_FIELS,
  BudgetFormArticle,
} from './types';

import './index.scss';

const labelWithCode = ({ code, description }: { code: string; description: string }) => `${code} - ${description}`;

export const BudgetArticleRuleForm: FC<BudgetFormComponent> = ({
  value, onChange, onAccept, articleCode, loading, coffinSizes,
}) => {
  const { validateBudgetRule } = useValidator();

  const { t } = useTranslation();
  const {
    articles,
    articleSubTypes,
    articleTypes,
    blocTypes,
    fetchArticles,
    insurances,
    locationTypeLabels,
    locationTypes,
    questionCodes,
    getArticleByCode,
    responseCodes,
  } = useBudgetForm();

  useEffect(
    () => {
      fetchArticles()
        .catch(() => false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const allowNotActiveArticles = (
    articleItem: BudgetFormArticle,
  ): boolean => (articleItem.active === false);

  return (
    <Formik
      initialValues={{
        ...value,
        workingAddress: {
          city: '',
          country: '',
          location: '',
          province: '',
        },
        workingClient: undefined,
        workingExcludedAddress: {
          city: '',
          country: '',
          location: '',
          province: '',
        },
        workingExcludedArticles: undefined,
        workingExcludedClient: undefined,
        workingExcludedLocationType: undefined,
        workingExcludedResponseCode: undefined,
        workingLocationType: undefined,
        workingRelatedArticle: undefined,
        workingResponseCode: undefined,
        workingShowOnlyNotActive: false,
      }}
      validate={(values) => {
        if (values.article) {
          onChange(
            values.article || '',
            {
              active: values.active,
              article: values.article,
              bloc: values.bloc,
              coffinSize: values.coffinSize,
              createdBy: values.createdBy,
              createdDate: values.createdDate,
              excludedArticles: values.excludedArticles,
              excludedClients: values.excludedClients,
              excludedLocations: values.excludedLocations,
              id: values.id,
              idVersion: values.idVersion,
              image: values.image,
              includedArticles: values.includedArticles,
              includedClients: values.includedClients,
              includedLocations: values.includedLocations,
              mandatoryResponses: values.mandatoryResponses,
              optionalResponses: values.optionalResponses,
              order: values.order,
              questionCode: values.questionCode,
              subType: values.subType,
              type: values.type || ArticleType.AUTOMATIC,
            },
          );
        }

        return validateBudgetRule({
          active: values.active,
          bloc: values.bloc,
          coffinSize: values.coffinSize,
          excludedArticles: values.excludedArticles.map(toString),
          excludedClients: values.excludedClients.map(toString),
          excludedLocations: values.excludedLocations,
          image: null,
          includedArticles: values.includedArticles.map(toString),
          includedClients: values.includedClients.map(toString),
          includedLocations: values.includedLocations,
          mandatoryResponses: values.mandatoryResponses.map(toString),
          optionalResponses: values.optionalResponses.map(toString),
          questionCode: values.questionCode || null,
          sorting: values.order,
          subType: values.subType === ArticleCollectionTypes.ARTICLE
            ? null
            : values.subType,
          type: values.type || ArticleType.CATALOG,
        });
      }}
      enableReinitialize
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        handleBlur, errors, values, setFieldValue, setValues, isValid,
      }: FormikProps<IBudgetFormProps>) => {
        const displayQuestionField = values.type === ArticleType.CATALOG;
        const displaySubType = values.questionCode === BudgetQuestionCodes.Q31;
        const displayCoffinField = values.questionCode === BudgetQuestionCodes.Q10;
        const displayForm = !values.article;

        const selectOptionsLimit = 50;

        return (
          <Form autoComplete="off" className="f-budget-form">
            <Column
              justifyContent="flex-start"
              style={{ height: '100%' }}
            >
              <Row justifyContent="space-between">
                <BackButton />
                {values.article
                  ? (<div className="f-budget-form-title" style={{ flexGrow: 'unset' }}>{getArticleByCode(values.article)?.description}</div>)
                  : <React.Fragment />}
                <div style={{ flexGrow: 'unset' }} />
              </Row>
              {!articleCode
                ? (
                  <Panel>
                    <Row>
                      <Input
                        checked={values.workingShowOnlyNotActive}
                        disabled={false}
                        id="budget.showNotActiveArticles"
                        name="budget.showNotActiveArticles"
                        placeholder={t('budget.showNotActiveArticles')}
                        type="checkbox"
                        onBlur={handleBlur}
                        onChange={(val) => {
                          setFieldValue(
                            'workingShowOnlyNotActive',
                            !!val,
                          );
                        }}
                      />
                    </Row>

                    <Row>
                      <Select<IMasterDataDto>
                        error={getIn(errors, BUDGET_FORM_FIELS.ARTICLE)}
                        getLabel={({ description, code }) => `${code} - ${description}`}
                        getValue={({ code }) => code}
                        limit={selectOptionsLimit}
                        name={BUDGET_FORM_FIELS.ARTICLE}
                        options={values.workingShowOnlyNotActive
                          ? articles.filter(allowNotActiveArticles)
                          : articles}
                        placeholder={t('budget.article')}
                        value={articles.find((article) => article.code === values.article)}
                        clearable
                        searchable
                        onBlur={handleBlur}
                        onChange={(val) => {
                          setFieldValue(
                            BUDGET_FORM_FIELS.ARTICLE,
                            val?.code,
                          );
                        }}
                      />
                    </Row>
                  </Panel>
                )
                : (<React.Fragment />)}

              {loading ? (<Loader />) : <React.Fragment />}
              <Column className={classNames({ 'f-hidden': displayForm || loading })}>
                <Panel>
                  <Row>
                    <Upload
                      disabled={false}
                      name="image"
                      placeholder={t('budget.articleImage')}
                      placeholderImage={articlePlaceholder}
                      value={values.image || undefined}
                      onChange={(val) => setFieldValue('image', val)}
                    />
                  </Row>
                </Panel>

                <Panel>
                  <Row>
                    <Select<IMasterDataDto>
                      error={getIn(errors, BUDGET_FORM_FIELS.BLOC)}
                      getLabel={({ description }) => description}
                      getValue={({ code }) => code}
                      limit={10}
                      name={BUDGET_FORM_FIELS.BLOC}
                      options={blocTypes}
                      placeholder={t('budget.block')}
                      value={blocTypes.find((article) => article.code === values.bloc)}
                      clearable
                      searchable
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue(
                          BUDGET_FORM_FIELS.BLOC,
                          val?.code,
                        );
                      }}
                    />
                    <Input
                      error={errors.order}
                      min={0}
                      name="order"
                      placeholder={t('common.order')}
                      type="number"
                      value={values.order || ''}
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue(
                          BUDGET_FORM_FIELS.ORDER,
                          val,
                        );
                      }}
                    />
                    <Input
                      checked={values.active}
                      name="active"
                      placeholder={t('common.active')}
                      type="checkbox"
                      onChange={() => {
                        setFieldValue(
                          BUDGET_FORM_FIELS.ACTIVE,
                          !values.active,
                        );
                      }}
                    />
                  </Row>
                </Panel>

                <Panel>
                  <Row>
                    <RadioButton
                      checked={values.type || ''}
                      className="f-budget-options"
                      disabled={false}
                      error={getIn(errors, BUDGET_FORM_FIELS.TYPE)}
                      getLabel={({ description }) => description}
                      getValue={({ code }) => code}
                      name={BUDGET_FORM_FIELS.TYPE}
                      options={articleTypes}
                      placeholder={`${t('budget.articleType')}:`}
                      onChange={(val) => {
                        setValues({
                          ...values,
                          [BUDGET_FORM_FIELS.TYPE.toString()]: val,
                          [BUDGET_FORM_FIELS.QUESTION_CODE.toString()]:
                            val === ArticleType.CATALOG
                              ? values.questionCode
                              : undefined,
                        });
                      }}
                    />
                  </Row>
                </Panel>

                <Panel
                  className={classNames(
                    { 'f-hidden': !displayQuestionField },
                  )}
                >
                  <Row>
                    <Select<IMasterDataDto>
                      error={getIn(errors, BUDGET_FORM_FIELS.QUESTION_CODE)}
                      getLabel={labelWithCode}
                      getValue={({ code }) => code}
                      name={BUDGET_FORM_FIELS.QUESTION_CODE}
                      options={questionCodes}
                      placeholder={t('budget.question')}
                      value={questionCodes
                        .find(
                          (questionCodeItem) => questionCodeItem.code === values.questionCode,
                        )}
                      clearable
                      searchable
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setValues({
                          ...values,
                          [BUDGET_FORM_FIELS.QUESTION_CODE.toString()]: val?.code,
                          [BUDGET_FORM_FIELS.SUB_TYPE]: val?.code !== BudgetQuestionCodes.Q31
                            ? ArticleCollectionTypes.ARTICLE
                            : values.subType,
                          [BUDGET_FORM_FIELS.COFFIN_SIZE]:
                            val?.code === BudgetQuestionCodes.Q10
                              ? values.coffinSize
                              : null,
                        });
                      }}
                    />
                  </Row>
                </Panel>

                <Panel
                  className={classNames(
                    { 'f-hidden': !displaySubType },
                  )}
                >
                  <Row>
                    <RadioButton
                      checked={values.subType}
                      className="f-budget-options"
                      disabled={false}
                      error={getIn(errors, BUDGET_FORM_FIELS.SUB_TYPE)}
                      getLabel={({ description }) => description}
                      getValue={({ code }) => code}
                      name={BUDGET_FORM_FIELS.SUB_TYPE}
                      options={articleSubTypes}
                      placeholder={`${t('budget.articleSubType')}:`}
                      onChange={(val) => {
                        setFieldValue(
                          BUDGET_FORM_FIELS.SUB_TYPE,
                          val,
                        );
                      }}
                    />
                  </Row>
                </Panel>

                <Panel
                  className={classNames(
                    { 'f-hidden': !displayCoffinField },
                  )}
                >
                  <Row>
                    <RadioButton
                      checked={values.coffinSize?.toString()}
                      className="f-budget-options"
                      disabled={false}
                      error={getIn(errors, BUDGET_FORM_FIELS.COFFIN_SIZE)}
                      getLabel={({ description }) => description}
                      getValue={({ code }) => code}
                      name={BUDGET_FORM_FIELS.COFFIN_SIZE}
                      options={coffinSizes || []}
                      placeholder={`${t('budget.coffinSize')}:`}
                      onChange={(val) => {
                        setFieldValue(
                          BUDGET_FORM_FIELS.COFFIN_SIZE,
                          val,
                        );
                      }}
                    />
                  </Row>
                </Panel>

                <Panel>
                  <div className="f-budget-form-panel-header">{t('budget.mandatoryResponses')}</div>
                  <Row>
                    <Select<IMasterDataDto>
                      error={getIn(errors, BUDGET_FORM_FIELS.MANDATORY_RESPONSES)}
                      getLabel={labelWithCode}
                      getValue={({ code }) => code}
                      name={BUDGET_FORM_FIELS.MANDATORY_RESPONSES}
                      options={responseCodes}
                      placeholder={t('budget.response')}
                      value={responseCodes
                        .find(
                          (
                            responseCodeItem,
                          ) => responseCodeItem.code === values.workingResponseCode,
                        )}
                      clearable
                      searchable
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue(
                          'workingResponseCode',
                          val?.code,
                        );
                        if (val?.code) {
                          setFieldValue(
                            BUDGET_FORM_FIELS.MANDATORY_RESPONSES,
                            [
                              ...values.mandatoryResponses
                                .filter(
                                  (mandatoryResponse) => mandatoryResponse.code !== val?.code,
                                ),
                              Code.create(val?.code),
                            ],
                          );
                        }
                      }}
                    />
                  </Row>
                  <ResponsesTable
                    name="budget-form-included-response"
                    responseCodes={responseCodes}
                    responses={values.mandatoryResponses}
                    onDelete={(response) => {
                      setFieldValue(
                        BUDGET_FORM_FIELS.MANDATORY_RESPONSES,
                        [
                          ...values.mandatoryResponses
                            .filter(
                              (mandatoryResponse) => mandatoryResponse.code !== response.code,
                            ),
                        ],
                      );
                    }}
                  />
                </Panel>

                <Panel>
                  <div className="f-budget-form-panel-header">{t('budget.optionalResponses')}</div>
                  <Row>
                    <Select<IMasterDataDto>
                      error={getIn(errors, BUDGET_FORM_FIELS.OPTIONAL_RESPONSES)}
                      getLabel={labelWithCode}
                      getValue={({ code }) => code}
                      name={BUDGET_FORM_FIELS.OPTIONAL_RESPONSES}
                      options={responseCodes}
                      placeholder={t('budget.excludedResponse')}
                      value={responseCodes
                        .find(
                          (responseCodesItem) => responseCodesItem.code
                            === values.workingExcludedResponseCode,
                        )}
                      clearable
                      searchable
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue(
                          'workingExcludedResponseCode',
                          val?.code,
                        );
                        if (val?.code) {
                          setFieldValue(
                            BUDGET_FORM_FIELS.OPTIONAL_RESPONSES,
                            [
                              ...values.optionalResponses
                                .filter(
                                  (optionalResponse) => optionalResponse.code !== val?.code,
                                ),
                              Code.create(val?.code),
                            ],
                          );
                        }
                      }}
                    />
                  </Row>
                  <ResponsesTable
                    name="budget-form-excluded-response"
                    responseCodes={responseCodes}
                    responses={values.optionalResponses}
                    onDelete={(response) => {
                      setFieldValue(
                        BUDGET_FORM_FIELS.OPTIONAL_RESPONSES,
                        [
                          ...values.optionalResponses
                            .filter(
                              (optionalResponse) => optionalResponse.code !== response.code,
                            ),
                        ],
                      );
                    }}
                  />
                </Panel>

                <Panel>
                  <Column className="f-location-panel">
                    <div className="f-budget-form-panel-header">{t('budget.includedLocations')}</div>
                    <Row>
                      <CitySelector
                        cityCaption={t('common.city')}
                        cityCode={values.workingAddress?.city || ''}
                        countryCaption={t('common.country')}
                        countryCode={values.workingAddress?.country || ''}
                        disabled={false}
                        provinceCaption={t('common.province')}
                        provinceCode={values.workingAddress?.province || ''}
                        cityWork
                        emitPartial
                        onChange={(val) => {
                          setFieldValue(
                            'workingAddress',
                            val,
                          );
                        }}
                      />
                      <Select<IMasterDataDto>
                        getLabel={({ description }) => description}
                        getValue={({ code }) => code}
                        name="responseCodes"
                        options={locationTypes}
                        placeholder={t('budget.locationType')}
                        value={locationTypes
                          .find((locationTypesItem) => locationTypesItem.code
                            === values.workingLocationType)}
                        onBlur={handleBlur}
                        onChange={(val) => {
                          setFieldValue(
                            'workingLocationType',
                            val?.code,
                          );
                          if (val?.code === MasterDataQuestionType.OBITUARY) {
                            setFieldValue(
                              'workingAddress',
                              {
                                ...values.workingAddress,
                                location: null,
                              },
                            );
                          }
                        }}
                      />

                      {values.workingLocationType !== MasterDataQuestionType.OBITUARY
                        ? (
                          <BudgetLocationSelector
                            caption={locationTypeLabels(values.workingLocationType)}
                            city={values.workingAddress?.city || ''}
                            country={values.workingAddress?.country || ''}
                            disabled={false}
                            province={values.workingAddress?.province || ''}
                            questionType={
                              values.workingLocationType || MasterDataQuestionType.VIGIL
                            }
                            value={values.workingAddress.location || ''}
                            onChange={(val) => {
                              if (val?.id) {
                                setFieldValue(
                                  'workingAddress',
                                  {
                                    ...values.workingAddress,
                                    location: val.id,
                                  },
                                );
                              }
                            }}
                          />
                        )
                        : (<React.Fragment />)}

                    </Row>
                    <Row>
                      <Button
                        disabled={!values.workingAddress.country || !values.workingLocationType}
                        text={t('budget.configurationAdd')}
                        onClick={() => {
                          if (values.workingAddress.country && values.workingLocationType) {
                            setFieldValue(
                              BUDGET_FORM_FIELS.INCLUDED_LOCATIONS,
                              [
                                ...values.includedLocations.filter((includedLocation) => !(
                                  includedLocation.city === values.workingAddress.city
                                  && includedLocation.province === values.workingAddress.province
                                  && includedLocation.country === values.workingAddress.country
                                  && includedLocation.location === values.workingAddress.location
                                  && includedLocation.type === values.workingLocationType)),
                                new BudgetConfigurationLocation({
                                  city: values.workingAddress.city,
                                  country: values.workingAddress.country,
                                  location: values.workingAddress.location,
                                  province: values.workingAddress.province,
                                  type: values.workingLocationType,
                                }),
                              ],
                            );
                          }
                        }}
                      />
                    </Row>
                    <Row>
                      <LocationsTable
                        locations={values.includedLocations}
                        name="budget-form-included-location"
                        onDelete={(location) => {
                          setFieldValue(
                            BUDGET_FORM_FIELS.INCLUDED_LOCATIONS,
                            [
                              ...values.includedLocations.filter((includedLocation) => !(
                                includedLocation.city === location?.city
                                && includedLocation.province === location?.province
                                && includedLocation.country === location?.country
                                && includedLocation.location === location?.location
                                && includedLocation.type === location?.type)),
                            ],
                          );
                        }}
                      />
                    </Row>
                  </Column>
                </Panel>

                <Panel>
                  <Column className="f-location-panel">
                    <div className="f-budget-form-panel-header">{t('budget.excludedLocations')}</div>
                    <Row>
                      <CitySelector
                        cityCaption={t('common.city')}
                        cityCode={values.workingExcludedAddress?.city || ''}
                        countryCaption={t('common.country')}
                        countryCode={values.workingExcludedAddress?.country || ''}
                        disabled={false}
                        provinceCaption={t('common.province')}
                        provinceCode={values.workingExcludedAddress?.province || ''}
                        cityWork
                        emitPartial
                        onChange={(val) => {
                          setFieldValue(
                            'workingExcludedAddress',
                            val,
                          );
                        }}
                      />
                      <Select<IMasterDataDto>
                        getLabel={({ description }) => description}
                        getValue={({ code }) => code}
                        name="responseCodes"
                        options={locationTypes}
                        placeholder={t('budget.locationType')}
                        value={locationTypes
                          .find(
                            (
                              locationType,
                            ) => locationType.code === values.workingExcludedLocationType,
                          )}
                        onBlur={handleBlur}
                        onChange={(val) => {
                          setFieldValue(
                            'workingExcludedLocationType',
                            val?.code,
                          );
                          if (val?.code === MasterDataQuestionType.OBITUARY) {
                            setFieldValue(
                              'workingExcludedAddress',
                              {
                                ...values.workingExcludedAddress,
                                location: null,
                              },
                            );
                          }
                        }}
                      />

                      {values.workingExcludedLocationType !== MasterDataQuestionType.OBITUARY
                        ? (
                          <BudgetLocationSelector
                            caption={locationTypeLabels(values.workingExcludedLocationType)}
                            city={values.workingExcludedAddress?.city || ''}
                            country={values.workingExcludedAddress?.country || ''}
                            disabled={false}
                            province={values.workingExcludedAddress?.province || ''}
                            questionType={values.workingExcludedLocationType
                              || MasterDataQuestionType.VIGIL}
                            value={values.workingExcludedAddress.location || ''}
                            onChange={(val) => {
                              if (val?.id) {
                                setFieldValue(
                                  'workingExcludedAddress',
                                  {
                                    ...values.workingExcludedAddress,
                                    location: val.id,
                                  },
                                );
                              }
                            }}
                          />
                        )
                        : (<React.Fragment />)}

                    </Row>
                    <Row>
                      <Button
                        disabled={!values.workingExcludedAddress.country
                          || !values.workingExcludedLocationType}
                        text={t('budget.configurationAdd')}
                        onClick={() => {
                          setFieldValue(
                            BUDGET_FORM_FIELS.EXCLUDED_LOCATIONS,
                            [
                              ...values.excludedLocations.filter((excludedLocation) => !(
                                excludedLocation.city === values.workingExcludedAddress.city
                                && excludedLocation.province
                                === values.workingExcludedAddress.province
                                && excludedLocation.country
                                === values.workingExcludedAddress.country
                                && excludedLocation.location
                                === values.workingExcludedAddress.location
                                && excludedLocation.type
                                === values.workingExcludedLocationType
                              )),
                              {
                                city: values.workingExcludedAddress.city,
                                country: values.workingExcludedAddress.country,
                                location: values.workingExcludedAddress.location,
                                province: values.workingExcludedAddress.province,
                                type: values.workingExcludedLocationType,
                              },
                            ],
                          );
                        }}
                      />
                    </Row>
                    <Row>
                      <LocationsTable
                        locations={values.excludedLocations}
                        name="budget-form-excluded-location"
                        onDelete={(location) => {
                          setFieldValue(
                            BUDGET_FORM_FIELS.EXCLUDED_LOCATIONS,
                            [
                              ...values.excludedLocations.filter((excludedLocation) => !(
                                excludedLocation.city === location?.city
                                && excludedLocation.province === location?.province
                                && excludedLocation.country === location?.country
                                && excludedLocation.location === location?.location
                                && excludedLocation.type === location?.type)),
                            ],
                          );
                        }}
                      />
                    </Row>
                  </Column>
                </Panel>

                <Panel>
                  <div className="f-budget-form-panel-header">{t('budget.includedInsurances')}</div>
                  <Row>
                    <Select<IMasterDataDto>
                      key="insurance-selector"
                      disabled={false}
                      getLabel={({ description }) => description}
                      getValue={({ code }) => code}
                      limit={selectOptionsLimit}
                      name="insurance-selector"
                      options={insurances.filter((i) => i.code !== '000100')}
                      placeholder={t('budget.insurance')}
                      value={insurances
                        .find((insuranceItem) => insuranceItem.code === values.workingClient)}
                      clearable
                      searchable
                      onChange={(val) => {
                        setFieldValue('workingClient', val?.code);
                        if (val?.code) {
                          setFieldValue(
                            BUDGET_FORM_FIELS.INCLUDED_CLIENTS,
                            [
                              ...values.includedClients
                                .filter((includedClient) => includedClient.code !== val.code),
                              Code.create(val.code),
                            ],
                          );
                        }
                      }}
                    />
                  </Row>
                  <InsuranceTable
                    clients={values.includedClients}
                    name="budget-form-included-client"
                    onDelete={(client) => {
                      setFieldValue(
                        BUDGET_FORM_FIELS.INCLUDED_CLIENTS,
                        [
                          ...values.includedClients
                            .filter((includedClient) => includedClient.code !== client.code),
                        ],
                      );
                    }}
                  />
                </Panel>

                <Panel>
                  <div className="f-budget-form-panel-header">{t('budget.excludedInsurances')}</div>
                  <Row>
                    <Select<IMasterDataDto>
                      key="insurance-selector"
                      disabled={false}
                      getLabel={({ description }) => description}
                      getValue={({ code }) => code}
                      limit={selectOptionsLimit}
                      name="insurance-selector"
                      options={insurances.filter((insuranceItem) => insuranceItem.code !== '000100')}
                      placeholder={t('budget.insurance')}
                      value={insurances
                        .find(
                          (insuranceItem) => insuranceItem.code === values.workingExcludedClient,
                        )}
                      clearable
                      searchable
                      onChange={(val) => {
                        setFieldValue('workingExcludedClient', val?.code);
                        if (val?.code) {
                          setFieldValue(
                            BUDGET_FORM_FIELS.EXCLUDED_CLIENTS,
                            [
                              ...values.excludedClients
                                .filter((excludedClient) => excludedClient.code !== val.code),
                              Code.create(val.code),
                            ],
                          );
                        }
                      }}
                    />
                  </Row>
                  <InsuranceTable
                    clients={values.excludedClients}
                    name="budget-form-excluded-client"
                    onDelete={(client) => {
                      setFieldValue(
                        BUDGET_FORM_FIELS.EXCLUDED_CLIENTS,
                        [
                          ...values.excludedClients
                            .filter((excludedClient) => excludedClient.code !== client.code),
                        ],
                      );
                    }}
                  />
                </Panel>

                <Panel>
                  <div className="f-budget-form-panel-header">{t('budget.includedArticles')}</div>
                  <Row>
                    <Select<IMasterDataDto>
                      error={getIn(errors, BUDGET_FORM_FIELS.INCLUDED_ARTICLES)}
                      getLabel={({ description, code }) => `${code} - ${description}`}
                      getValue={({ code }) => code}
                      limit={selectOptionsLimit}
                      name={BUDGET_FORM_FIELS.INCLUDED_ARTICLES}
                      options={articles.filter(
                        (article) => values.workingArticle !== article.code,
                      )}
                      placeholder={t('budget.relatedArticles')}
                      value={articles.find(
                        (article) => article.code === values.workingRelatedArticle,
                      )}
                      clearable
                      searchable
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue('workingRelatedArticle', val?.code);
                        if (val?.code && articles.find((article) => article.code === val?.code)) {
                          setFieldValue(
                            BUDGET_FORM_FIELS.INCLUDED_ARTICLES,
                            [
                              ...values.includedArticles
                                .filter((article) => article.code !== val?.code),
                              Code.create(val.code),
                            ],
                          );
                        }
                      }}
                    />
                  </Row>
                  <Row>
                    <ArticlesTable
                      articleList={articles}
                      articles={values.includedArticles}
                      name="budget-form-included-article"
                      onDelete={(article) => {
                        setFieldValue(
                          BUDGET_FORM_FIELS.INCLUDED_ARTICLES,
                          [
                            ...values.includedArticles
                              .filter((includedArticle) => includedArticle.code !== article.code),
                          ],
                        );
                      }}
                    />
                  </Row>
                </Panel>

                <Panel>
                  <div className="f-budget-form-panel-header">{t('budget.excludedArticles')}</div>
                  <Row>
                    <Select<IMasterDataDto>
                      error={getIn(errors, BUDGET_FORM_FIELS.EXCLUDED_ARTICLES)}
                      getLabel={({ description, code }) => `${code} - ${description}`}
                      getValue={({ code }) => code}
                      limit={selectOptionsLimit}
                      name={BUDGET_FORM_FIELS.EXCLUDED_ARTICLES}
                      options={articles.filter(
                        (article) => values.workingArticle !== article.code,
                      )}
                      placeholder={t('budget.excludedArticles')}
                      value={articles
                        .find((article) => article.code === values.workingExcludedArticles)}
                      clearable
                      searchable
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue('workingExcludedArticles', val?.code);
                        if (val?.code && articles.find((article) => article.code === val?.code)) {
                          setFieldValue(
                            BUDGET_FORM_FIELS.EXCLUDED_ARTICLES,
                            [
                              ...values.excludedArticles
                                .filter((article) => article.code !== val?.code),
                              Code.create(val.code),
                            ],
                          );
                        }
                      }}
                    />
                  </Row>
                  <Row>
                    <ArticlesTable
                      articleList={articles}
                      articles={values.excludedArticles}
                      name="budget-form-excluded-article"
                      onDelete={(article) => {
                        setFieldValue(
                          BUDGET_FORM_FIELS.EXCLUDED_ARTICLES,
                          [
                            ...values.excludedArticles
                              .filter((excludedArticle) => excludedArticle.code !== article.code),
                          ],
                        );
                      }}
                    />
                  </Row>
                </Panel>

                <Row justifyContent="flex-end">
                  <Button
                    color="primary"
                    disabled={!isValid}
                    text={t('common.save')}
                    onClick={() => {
                      if (values.type && values.article) {
                        onAccept({
                          active: values.active,
                          article: values.article,
                          bloc: values.bloc,
                          coffinSize: values.coffinSize,
                          createdBy: value.createdBy || null,
                          createdDate: value.createdDate || null,
                          excludedArticles: values.excludedArticles,
                          excludedClients: values.excludedClients,
                          excludedLocations: values.excludedLocations,
                          id: value.id || null,
                          idVersion: value.idVersion,
                          image: value.image,
                          includedArticles: values.includedArticles,
                          includedClients: values.includedClients,
                          includedLocations: values.includedLocations,
                          mandatoryResponses: values.mandatoryResponses,
                          optionalResponses: values.optionalResponses,
                          order: values.order,
                          questionCode: values.questionCode,
                          subType: values.subType,
                          type: values.type,
                        });
                      }
                    }}
                  />
                </Row>
              </Column>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
};
