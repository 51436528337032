import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import useOven from 'hooks/useOven';
import { CremationBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, displayDate, displayHour, WorkshopCardHeaderDetail,
} from './CardDetails';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const IncinerationDetails = ({ order }: { order: CremationBaseWorkOrder }) => {
  const { t } = useTranslation();
  const { getOven, cityCrematoryOvens } = useOven(order.crematory, order.city);

  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);

  useEffect(() => {
    setDetails([
      {
        label: t('common.date'),
        value: displayDate(order.cremationdate),
      },
      {
        label: t('common.hour'),
        value: displayHour(order.time),
      },
      {
        label: t('order.details.oven'),
        value: getOven(order.oven)?.description || displayNoValue,
      },

    ]);
  }, [
    order,
    t,
    cityCrematoryOvens,
    getOven,
  ]);

  return (<CardDetails details={details} />);
};
