import React, { FC } from 'react';

import { Column } from 'components/base/Column';
import Modal from 'components/base/Modal';

import { GalleryImagePicture } from '../GalleryImagePicture';

interface PictureZoomComponent {
  image: string;
  show: boolean;
  onHide: () => void
}

export const PictureZoom: FC<PictureZoomComponent> = ({ show, image, onHide }) => (
  <Modal
    className="picture-modal"
    show={show}
    showCancel={false}
    title=""
    closeOnFocusLost
    showXButton
    onHide={(e) => {
      e?.stopPropagation();
      onHide?.();
    }}
  >
    <Column justifyContent="space-around">
      <GalleryImagePicture
        className="gallery-image-picture-image-full"
        url={image}
      />
    </Column>
  </Modal>
);
