import { FormikProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/base/Input';

import { SaveRecord } from 'models/Record';

type BillingProps = {
  disabled?: boolean;
  props: FormikProps<SaveRecord>;
};

const Billing: FC<BillingProps> = ({
  disabled,
  props: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('record.steps.billing')}</legend>
      <Input
        checked={values.sendByEmail}
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'sendByEmail')}
        name="sendByEmail"
        placeholder={t('record.sendByEmail')}
        type="checkbox"
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('sendByEmail', val)}
      />
    </fieldset>
  );
};

export const billingFields = (
  props: FormikProps<SaveRecord>,
  disabled?: boolean,
): JSX.Element => <Billing disabled={disabled} props={props} />;
