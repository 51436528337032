import React from 'react';

import { ServiceInformationDto } from 'config/apiFunus/types';
import useCrematory from 'hooks/useCrematory';

import '../index.scss';
import { DateTimeInfo } from '../DateTimeInfo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CremationCell = ({ value }: { value: ServiceInformationDto}) => {
  const { getCrematory } = useCrematory(value.city);

  return (
    <React.Fragment>
      <div className="text-align-center">{getCrematory(value.location)?.description || '--'}</div>
      <DateTimeInfo date={value.date} time={`${value.date} ${value.time}`} />
    </React.Fragment>
  );
};
