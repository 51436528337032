import { useEffect, useState } from 'react';

import { Nullable } from 'utils/types/Nullable/types';

import { useCities } from 'hooks/useCities';
import { useCountries } from 'hooks/useCountries';
import { useProvinces } from 'hooks/useProvinces';
import { MasterDataDtoCode } from 'models/dataTypes';
import { CityType, ProvinceType } from 'models/MasterData';

import { IAAddressInformationHook } from './types';

export const useIAAddressInformation = (): IAAddressInformationHook => {
  const { getCountryByName } = useCountries();
  const { getProvinceByName } = useProvinces();
  const { getCitiesByName } = useCities();

  const [countryCode, setCountryCode] = useState<Nullable<MasterDataDtoCode>>(null);
  const [countryName, setCountryName] = useState<Nullable<string>>(null);

  const [provinceCode, setProvinceCode] = useState<Nullable<MasterDataDtoCode>>(null);
  const [provinceName, setProvinceName] = useState<Nullable<string>>(null);

  const [cityCode, setCityCode] = useState<Nullable<MasterDataDtoCode>>(null);
  const [cityName, setCityName] = useState<Nullable<string>>(null);

  useEffect(
    () => {
      if (countryName) {
        const country = getCountryByName(countryName);
        if (country && !Array.isArray(country)) {
          setCountryCode(country.code);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryName],
  );

  useEffect(
    () => {
      if (provinceName) {
        const province = getProvinceByName(provinceName);
        if (province && !Array.isArray(province)) {
          setProvinceCode(province.code);
          setCountryCode(province.countryCode);
        } else if (province && Array.isArray(province) && countryCode) {
          const getProvinceByCountryCode = (i: ProvinceType) => i.countryCode === countryCode;
          const provincesByCountry = province.filter(getProvinceByCountryCode);
          if (provincesByCountry.length === 1) {
            setProvinceCode(provincesByCountry[0].code);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryCode, provinceName],
  );

  useEffect(
    () => {
      if (cityName) {
        const city = getCitiesByName(cityName);
        if (city && !Array.isArray(city)) {
          setCityCode(city.code);
          setProvinceCode(city.provinceCode);
          setCountryCode(city.countryCode);
        } else if (city && Array.isArray(city) && provinceCode && countryCode) {
          const getCityBy = (i: CityType) => i.provinceCode === provinceCode
            && i.countryCode === countryCode;
          const citiesByProvinceAndCountry = city.filter(getCityBy);
          if (citiesByProvinceAndCountry.length === 1) {
            setCityCode(citiesByProvinceAndCountry[0].code);
            setProvinceCode(citiesByProvinceAndCountry[0].provinceCode);
            setCountryCode(citiesByProvinceAndCountry[0].countryCode);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityName, countryCode, provinceCode],
  );

  return {
    addressCityCode: cityCode,
    addressCountryCode: countryCode,
    addressProvinceCode: provinceCode,
    setAddressCityName: setCityName,
    setAddressCountryName: setCountryName,
    setAddressProvinceName: setProvinceName,
  };
};
