import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { normalizeMasterData } from 'utils/normalizer';

import { getCrematoryByCity } from 'actions/masterData';

import { CrematoryType } from 'models/MasterData';
import { RootState } from 'store';

type CrematoryHook = {
  crematories: CrematoryType[];
  normalizedCrematories: {
    [key: string]: CrematoryType;
  };
  getCrematory: (code: string) => CrematoryType | undefined

};

const useCrematory = (cityCode?: string): CrematoryHook => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [normalizedCrematories, setNormalizedCrematories] = useState<any>({});
  const { crematories } = useSelector((state: RootState) => state.masterData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cityCode !== cityLoaded) {
      setCityLoaded(cityCode);
      dispatch(getCrematoryByCity(cityCode));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  useEffect(() => {
    setNormalizedCrematories(normalizeMasterData(crematories));
  }, [crematories]);

  const getCrematory = useCallback(
    (code) => normalizedCrematories && normalizedCrematories[code],
    [normalizedCrematories],
  );

  return { crematories, getCrematory, normalizedCrematories };
};

export default useCrematory;
