import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import { isBooleanAndTrue } from 'utils/helpers';

import { Row } from 'components/base/Row';

import useWindowSize from 'hooks/useWindowSize';
import { Article } from 'modules/budget/models/Article';
import { IArticle } from 'modules/budget/models/Article/types';
import { dismissServiceId } from 'modules/budget/store/data';

import { Classable } from '../../types/helpers';
import { SelectableItem } from '../SelectableItem';

import './index.scss';

interface OptionSelectorComponent extends Classable {
  items: IArticle[];
  onChange: (articles: Article[]) => void;
  highlightFirst?: boolean;
  multiselect?: boolean;
  value?: Article[]
  dismissService?: boolean;
}
export const OptionSelector: FC<OptionSelectorComponent> = (
  {
    items, onChange, className, highlightFirst, multiselect, value = [], dismissService,
  },
) => {
  const [style, setStyle] = useState<React.CSSProperties>({ overflow: 'auto' });

  const { height } = useWindowSize();

  useEffect(
    () => {
      setStyle({ overflow: 'auto' });
    },
    [height],
  );

  return (
    <div className={classNames('option-selector', className)} style={style}>
      <Row>
        {items.map((item, itemIndex) => (
          <SelectableItem
            key={item.code}
            caption={item.description}
            className={classNames(highlightFirst && itemIndex === 0 ? 'selectable-item-hightlight' : '')}
            id={item.code}
            selected={value.findIndex((art) => art.code === item.code) !== -1
              || (item.code === dismissServiceId && isBooleanAndTrue(dismissService))}
            onClick={(id) => {
              let temporalSelection = value.slice();
              const index = temporalSelection
                .findIndex((selectionItem) => selectionItem.code === id);
              if (index !== -1 && multiselect) {
                temporalSelection.splice(index, 1);
              } else if (index === -1) {
                temporalSelection.push(new Article({
                  ...item,
                  amount: 1,
                }));
              }
              if (!multiselect) {
                temporalSelection = temporalSelection
                  .filter((selectionItem) => selectionItem.code === id);
              }
              onChange(temporalSelection);
            }}
          />
        ))}
      </Row>
    </div>
  );
};
