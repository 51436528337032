import { Formik, FormikProps } from 'formik';
import React, { FC } from 'react';

import { Column } from 'components/base/Column';

import { YesNoSelector } from 'modules/budget/components/commons/YesNoSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { BudgetResponseValue } from 'modules/budget/store/types';

interface Q2Response {
  responseValue: BudgetResponseValue
}

interface Q2FormComponent {
  onChange: (value: boolean) => void;
  question: BudgetQuestionCodes;
}

export const Q2Form: FC<Q2FormComponent> = ({ onChange, question }) => (
  <Formik
    initialValues={{
      responseValue: null,
    }}
    validate={(values) => {
      onChange(Boolean(values.responseValue));
      return {};
    }}
    validateOnBlur
    validateOnChange
    onSubmit={() => Promise.resolve()}
  >
    {({
      setFieldValue,
    }: FormikProps<Q2Response>) => (
      <Column>
        <YesNoSelector
          question={question}
          onChange={(v) => {
            setFieldValue('responseValue', v);
          }}
        />
      </Column>
    )}
  </Formik>
);
