import React, { FC, useEffect, useState } from 'react';

import { useRecord } from 'hooks/useRecord';

import { DeceasedStatusInfoComponent } from './types';
import './index.scss';

export const DeceasedStatusInfo: FC<DeceasedStatusInfoComponent> = (
  { recordId, caption = (status) => status },
) => {
  const { getInfo, getRecord } = useRecord();
  const [erpId, setErpid] = useState<number>();
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    getRecord(recordId)
      .then((response) => {
        setErpid(response.erpId);
        return response;
      })
      .catch(() => false);
  }, [recordId, getRecord]);

  useEffect(
    () => {
      if (erpId) {
        getInfo(erpId)
          .then((response) => {
            if (response.deceasedStatus) {
              setStatus(response.deceasedStatus);
            }
            return response;
          })
          .catch(() => false);
      }
    },
    [erpId, getInfo],
  );

  return (
    <span className="f-deceased-status-info">
      {caption(status) }
    </span>
  );
};
