import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cat from './cat';
import esp from './esp';

const resources = {
  cat: {
    translation: { ...cat },
  },
  esp: {
    translation: { ...esp },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    lng: 'cat',
    resources,
  });

export { i18n, resources };
