import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateManager';

import { displayNoValue } from 'components/base/NoData';

import { useCities } from 'hooks/useCities';
import { useVigilLocation, useVigilLocationDescription } from 'hooks/useVigilLocation';
import { MovementOrderInfo } from 'models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

export const MovementInfo: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const { t } = useTranslation();
  const {
    getVigilLocation,
  } = useVigilLocation((order.info as MovementOrderInfo)?.address?.city);
  const { description, setCityCode, setCode } = useVigilLocationDescription();
  const { getCityByNormalizedCode } = useCities();
  const [details, setDetails] = useState<DetailType[]>([]);
  const [info, setInfo] = useState<MovementOrderInfo>();

  useEffect(() => {
    if (info?.address?.city && setCityCode) {
      setCityCode(info?.address?.city);
    }
  }, [info?.address?.city, setCityCode]);

  useEffect(() => {
    if (info?.vigilPlace) {
      setCode(info?.vigilPlace);
    }
  }, [info?.vigilPlace, setCode]);

  useEffect(
    () => {
      setInfo((order.info as MovementOrderInfo));
    },
    [order.info],
  );

  useEffect(
    () => {
      setDetails([
        {
          text: t('common.type'),
          value: t(`service.movement.type.${info?.type}`).toString(),
        },
        {
          text: t('common.location'),
          value: info?.vigilPlace
            ? getVigilLocation(info?.vigilPlace)?.description
            : displayNoValue,
        },
        {
          text: t('common.domicile'),
          value: description,
        },
        {
          text: t('common.city'),
          value: getCityByNormalizedCode(
            info?.address?.country,
            info?.address?.province,
            info?.address?.city,
          )?.description,
        },
        {
          text: t('common.date'),
          value: info?.date ? formatDate(info?.date) : displayNoValue,
        },
        {
          text: t('common.hour'),
          value: info?.time || displayNoValue,
        },
        {
          text: t('common.adviserComment'),
          value: info?.comment,
        },
        {
          text: t('common.coordinatorComment'),
          value: info?.coordinatorComment,
        },
        {
          text: t('common.operatorComment'),
          value: info?.operatorComment,
        },
      ]);
    },
    [t, info, getVigilLocation, getCityByNormalizedCode, description],
  );

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};
