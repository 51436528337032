import { useCallback } from 'react';

import { EnvHook } from './types';

export const useEnv = (): EnvHook => {
  const isFeatureActive = (value: unknown) => value === 'true';

  const unused = () => isFeatureActive(
    true,
  );

  const iaEnableScanDocument = useCallback(
    () => isFeatureActive(
      process.env.REACT_APP_FEATURE_IA_ENABLE_SCAN_DOCUMENT,
    ),
    [],
  );

  return {
    iaEnableScanDocument,
    unused,
  };
};
