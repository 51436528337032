import {
  useCallback,
  useEffect, useState,
} from 'react';

import { checkHaveAtLeastOnePermission, checkPermission } from 'utils/permissions';

import {
  ArticleTrackingDto,
  ArticleTrackingDtoStatus,
  SimpleUserRequestPermissionNames,
} from 'config/apiFunus/generated/data-contracts';
import { StatusCodes } from 'models/OrderStatus';
import OrderType from 'models/OrderType';
import Record from 'models/Record';
import Workorder from 'models/Workorder';

import { useProvidedAuth } from '../useProvidedAuth';

import { FeaturesHook } from './types';

export const useFeatures = (): FeaturesHook => {
  const [canViewFlowers, setCanViewFlowers] = useState<boolean>(false);
  const { user } = useProvidedAuth();

  useEffect(
    () => {
      setCanViewFlowers(
        (checkHaveAtLeastOnePermission(
          [
            SimpleUserRequestPermissionNames.ARTICLE_READ,
            SimpleUserRequestPermissionNames.ARTICLE_SUPPLIER,
          ],
          user?.role.permissions,
        )),
      );
    },
    [
      user?.role.permissions,
    ],
  );

  const canDeleteRequest = useCallback(
    (articleTrackingItem: ArticleTrackingDto) => checkPermission(
      SimpleUserRequestPermissionNames.ARTICLE_DELETE,
      user?.role.permissions,
    )
    && articleTrackingItem.status !== ArticleTrackingDtoStatus.CANCELLED,
    [user?.role.permissions],
  );

  const canCancelRequest = useCallback(
    (
      articleTrackingItem: ArticleTrackingDto,
    ): boolean => (articleTrackingItem.status === ArticleTrackingDtoStatus.PENDING
      || articleTrackingItem.status === ArticleTrackingDtoStatus.DRAFT)
    && checkPermission(
      SimpleUserRequestPermissionNames.ARTICLE_CANCEL,
      user?.role.permissions,
    )
    && !canDeleteRequest(articleTrackingItem),
    [canDeleteRequest, user?.role.permissions],
  );

  const canProvideQr = useCallback(
    (
      order: Workorder,
    ) => order.assignedUsers
      .some((assigned) => assigned.id === user?.id)
    && order.type === OrderType.PICKUP
    && order.status === StatusCodes.IN_PROGRESS,
    [user?.id],
  );

  const canAcceptFlowersRequest = useCallback(
    (
      articleTrackingItem: ArticleTrackingDto,
    ) => articleTrackingItem.status === ArticleTrackingDtoStatus.PENDING
      && checkPermission(
        SimpleUserRequestPermissionNames.ARTICLE_SUPPLIER,
        user?.role.permissions,
      ),
    [user?.role.permissions],
  );
  const isArticleSupplier = useCallback(() => checkPermission(
    SimpleUserRequestPermissionNames.ARTICLE_SUPPLIER,
    user?.role.permissions,
  ), [user?.role.permissions]);

  const canViewPrintable = useCallback(
    (
      articleTrackingItem: ArticleTrackingDto,
    ) => {
      if (articleTrackingItem.status) {
        const hasArticleReadPermission = checkPermission(
          SimpleUserRequestPermissionNames.ARTICLE_READ,
          user?.role.permissions,
        );
        let allowedStates: ArticleTrackingDtoStatus[] = [
        ];
        if (isArticleSupplier()) {
          allowedStates = [
            ArticleTrackingDtoStatus.IN_PROGRESS,
            ArticleTrackingDtoStatus.RECEIVED,
            ArticleTrackingDtoStatus.FINISHED,
          ];
        } else if (hasArticleReadPermission) {
          allowedStates = [
            ArticleTrackingDtoStatus.IN_PROGRESS,
            ArticleTrackingDtoStatus.RECEIVED,
            ArticleTrackingDtoStatus.FINISHED,
            ArticleTrackingDtoStatus.CANCELLED,
            ArticleTrackingDtoStatus.PENDING,
          ];
        }

        return allowedStates.includes(articleTrackingItem.status);
      }
      return false;
    },
    [isArticleSupplier, user?.role.permissions],
  );

  const canAddArticleDisagreement = useCallback(
    (articleTrackingItem: ArticleTrackingDto) => checkPermission(
      SimpleUserRequestPermissionNames.ARTICLE_DISAGREEMENT,
      user?.role.permissions,
    ) && articleTrackingItem.status !== ArticleTrackingDtoStatus.CANCELLED
      && articleTrackingItem.status !== ArticleTrackingDtoStatus.DRAFT,
    [user?.role.permissions],
  );

  const canViewComercialPrintable = (record: Record) => Boolean(record.hasCommercial);

  const canAddFlowers = useCallback(
    () => checkPermission(
      SimpleUserRequestPermissionNames.ARTICLE_ADD,
      user?.role.permissions,
    ),
    [user?.role.permissions],
  );

  const canAddBudget = useCallback(
    (record) => checkPermission(
      SimpleUserRequestPermissionNames.BUDGET_WRITE,
      user?.role.permissions,
    ) && record.canCreateBudget,
    [user?.role.permissions],
  );

  const canEditArticleRequestDraft = useCallback(
    (articleTrackingItem: ArticleTrackingDto) => checkPermission(
      SimpleUserRequestPermissionNames.ARTICLE_ADD,
      user?.role.permissions,
    ) && articleTrackingItem.status === ArticleTrackingDtoStatus.DRAFT,
    [user?.role.permissions],
  );

  const canShowFlowersPhoto = (
    articleTrackingItem: ArticleTrackingDto,
  ) => articleTrackingItem.hasImage;

  return {
    canAcceptFlowersRequest,
    canAddArticleDisagreement,
    canAddBudget,
    canAddFlowers,
    canCancelRequest,
    canDeleteRequest,
    canEditArticleRequestDraft,
    canProvideQr,
    canShowFlowersPhoto,
    canViewComercialPrintable,
    canViewFlowers,
    canViewPrintable,
    isArticleSupplier,
  };
};
