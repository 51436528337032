import {
  Formik, Form, FormikProps, FormikHelpers, getIn,
} from 'formik';
import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  object, string,
} from 'yup';

import Button from 'components/base/Button';
import InputQRScan from 'components/base/InputQRScan';
import Loader from 'components/base/Loader';
import Select from 'components/base/Select';
import SelectButton from 'components/base/SelectButton';
import Textarea from 'components/base/Textarea';

import { useCoffins } from 'hooks/useCoffins';
import { i18n } from 'i18n';
import { IMasterDataDto } from 'models/MasterData';
import { CollectionOrder, DeceasedPreparation } from 'models/OrderInfo';
import { StatusCodes } from 'models/OrderStatus';
import OrderType from 'models/OrderType';
import { DefaultSelectType } from 'models/Others';
import Workorder, { ChangeStatusFormProps } from 'models/Workorder';

import DepositData from '../Deposit';
import VigilData from '../Vigil';

enum PickupFinishSteps {
  PickupStepOne = 'pickupStep1',
  PickupStepTwo= 'pickupStep2'
}

const options: DefaultSelectType[] = [
  {
    label: i18n.t('service.DEPOSIT'),
    value: 'deposit',
  },
  {
    label: i18n.t('service.VELATORIO'),
    value: 'vigil',
  },
];

type ChangeStatusProps = {
  loading?: boolean;
  onSubmit: (
    props: ChangeStatusFormProps,
    actions: FormikHelpers<ChangeStatusFormProps>
  ) => void;
  order: Workorder;
  showComment?: boolean;
  showDeposit?: boolean;
  showSecondQr?: boolean;
  showVigil?: boolean;
};

const ChangeStatus: FC<ChangeStatusProps> = ({
  loading,
  onSubmit,
  order,
  showComment,
  showDeposit,
  showVigil,
}) => {
  const [selected, setSelected] = useState<DefaultSelectType>(options[0]);
  const [pickupStep, setPickupStep] = useState<PickupFinishSteps>(PickupFinishSteps.PickupStepOne);
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [changeStatusSchema, setChangeStatusSchema] = useState <any>();
  const { coffinSizes, getCoffinSize } = useCoffins();

  const changeSelected = useCallback(
    (
      option: DefaultSelectType,
      setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    ) => {
      const orderinfo = order.info as DeceasedPreparation;
      setSelected(option);
      if (option.value === options[0].value) {
        setFieldValue('info.vigil', orderinfo?.vigil);
      } else {
        setFieldValue('info.deposit', orderinfo?.deposit);
      }
    },
    [order.info],
  );

  const displayAcceptButton = useMemo(
    () => order?.type !== OrderType.PICKUP
  || (order?.type === OrderType.PICKUP
      && (order?.status === StatusCodes.PENDING
        || (order?.status === StatusCodes.IN_PROGRESS
          && pickupStep === PickupFinishSteps.PickupStepTwo))),
    [
      order?.status,
      order?.type,
      pickupStep],
  );

  const displayNextButton = useMemo(
    () => order?.type === OrderType.PICKUP
    && order?.status === StatusCodes.IN_PROGRESS
    && pickupStep === PickupFinishSteps.PickupStepOne,
    [
      order?.type,
      order?.status,
      pickupStep,
    ],
  );

  const displayDeposit = useMemo(
    () => showDeposit
    && (order?.type !== OrderType.PICKUP
      || (order?.type === OrderType.PICKUP
        && pickupStep === PickupFinishSteps.PickupStepOne))
    && (!showVigil || selected.value === options[0].value),
    [
      showDeposit,
      order?.type,
      pickupStep,
      selected.value,
      showVigil,
    ],
  );

  const displayVigil = useMemo(
    () => showVigil
    && (!showDeposit || selected.value === options[1].value),
    [
      showVigil,
      showDeposit,
      selected.value,
    ],
  );

  useEffect(
    () => {
      setChangeStatusSchema(object().shape({
        info: object().shape({
          coffinSize: order.infoQr?.requiredCoffinSize
            ? string().required(t('error.required'))
            : string().nullable(),
          deceasedQr: order.infoQr?.deceasedQr
          && (order?.type !== OrderType.PICKUP
            || (order?.type === OrderType.PICKUP
              && (order?.status === StatusCodes.PENDING
                || pickupStep === PickupFinishSteps.PickupStepTwo)))
            ? string().required(t('error.required'))
            : string().nullable(),
        }),
      }));
    },
    [
      order.infoQr?.deceasedQr,
      order.infoQr?.requiredCoffinSize,
      order?.status,
      order?.type,
      pickupStep,
      t,
    ],
  );

  return !!order?.info && !!order?.infoQr && !loading ? (
    <Formik
      initialValues={{
        id: order.id,
        info: order.info
          ? {
            ...order.info,
            deposit: (order.info as DeceasedPreparation).deposit || undefined,
            observation: (order.info as DeceasedPreparation)?.observation,
            vehicle: (order.info as CollectionOrder)?.vehicle,
            vigil: (order.info as DeceasedPreparation)?.vigil,
          }
          : {},
        infoQr: order.infoQr || {},
        status: order.status,
        type: order.type,
      }}
      validationSchema={changeStatusSchema}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<ChangeStatusFormProps>) => (
        <Form autoComplete="off">
          {props.isSubmitting && <Loader />}
          {order.infoQr?.coffinQr && (
            <InputQRScan
              data={props.values.info?.coffinQr as string}
              disabled={props.isSubmitting}
              error={getIn(props.errors, 'info.coffinQr')}
              placeholder={t('order.qr.coffinQr')}
              setData={(val) => props.setFieldValue('info.coffinQr', val)}
            />
          )}
          {order.infoQr?.deceasedQr
            && (order?.type !== OrderType.PICKUP
              || (order?.type === OrderType.PICKUP
                && (order?.status === StatusCodes.PENDING
                  || pickupStep === PickupFinishSteps.PickupStepTwo)))
            && (
            <InputQRScan
              data={props.values.info?.deceasedQr as string}
              disabled={props.isSubmitting}
              error={getIn(props.errors, 'info.deceasedQr')}
              placeholder={t('order.qr.deceasedQr')}
              setData={(val) => props.setFieldValue('info.deceasedQr', val)}
            />
            )}
          {order.infoQr?.personalItemsQr && (
            <InputQRScan
              data={props.values.info?.personalItemsQr as string}
              disabled={props.isSubmitting}
              error={getIn(props.errors, 'info.personalItemsQr')}
              placeholder={t('order.qr.personalItemsQr')}
              setData={(val) => props.setFieldValue('info.personalItemsQr', val)}
            />
          )}
          {order.infoQr?.urnQr && (
            <InputQRScan
              data={props.values.info?.urnQr as string}
              disabled={props.isSubmitting}
              error={getIn(props.errors, 'info.urnQr')}
              placeholder={t('order.qr.urnQr')}
              setData={(val) => props.setFieldValue('info.urnQr', val)}
            />
          )}
          {showComment
            && (order?.type !== OrderType.PICKUP
              || (order?.type === OrderType.PICKUP
                && pickupStep === PickupFinishSteps.PickupStepTwo))
            && (
            <Textarea
              disabled={props.isSubmitting || props.isSubmitting}
              error={getIn(props.errors, 'info.operatorComment')}
              id="info.operatorComment"
              maxLength={100}
              name="info.operatorComment"
              placeholder={order?.type === OrderType.PICKUP ? t('record.collectionComment') : t('common.operatorComment')}
              value={props.values.info.operatorComment || ''}
              onBlur={props.handleBlur}
              onChange={(val) => props.setFieldValue('info.operatorComment', val)}
            />
            )}

          {order?.type === OrderType.PICKUP
                && pickupStep === PickupFinishSteps.PickupStepTwo
            && (
              <React.Fragment>
                <Textarea
                  disabled={props.isSubmitting || props.isSubmitting}
                  error={getIn(props.errors, 'info.personalObjects')}
                  id="info.personalObjects"
                  maxLength={100}
                  name="info.personalObjects"
                  placeholder={t('common.personalObjects')}
                  value={props.values.info.personalObjects || ''}
                  onBlur={props.handleBlur}
                  onChange={(val) => props.setFieldValue('info.personalObjects', val)}
                />
                <Select<IMasterDataDto>
                  key="coffinSize"
                  disabled={props.isSubmitting || props.isSubmitting}
                  error={getIn(props.errors, 'info.coffinSize')}
                  getLabel={({ description }) => description}
                  getValue={({ code }) => code}
                  name="coffinSize"
                  options={coffinSizes || []}
                  placeholder={t('common.size')}
                  value={props.values.info?.coffinSize
                    ? getCoffinSize(props.values.info?.coffinSize)
                    : undefined}
                  searchable
                  onChange={(val) => props.setFieldValue('info.coffinSize', val?.code)}
                />
                <Textarea
                  disabled={props.isSubmitting || props.isSubmitting}
                  error={getIn(props.errors, 'info.defunctStatus')}
                  id="info.defunctStatus"
                  maxLength={100}
                  name="info.defunctStatus"
                  placeholder={t('order.details.defunctStatus')}
                  value={props.values.info.defunctStatus || ''}
                  onBlur={props.handleBlur}
                  onChange={(val) => props.setFieldValue('info.defunctStatus', val)}
                />
              </React.Fragment>
            )}
          {showDeposit && showVigil && (
            <SelectButton<DefaultSelectType>
              disabled={props.isSubmitting}
              error={getIn(props.errors, 'transferServiceDto.type')}
              getLabel={({ label }) => label}
              getValue={({ value }) => value}
              options={options}
              placeholder={t('service.preparation.finalDestination')}
              value={selected}
              onChange={(elem: DefaultSelectType) => changeSelected(elem, props.setFieldValue)}
            />
          )}
          {displayDeposit
            && (<DepositData disabled={props.isSubmitting} formikProps={props} />)}
          {displayVigil
            && (<VigilData disabled={props.isSubmitting} formikProps={props} />)}
          {displayNextButton
            && (
            <Button
              disabled={props.isSubmitting}
              id="accept"
              text={t('common.next')}
              onClick={() => setPickupStep(PickupFinishSteps.PickupStepTwo)}
            />
            )}
          {displayAcceptButton
            && (
            <Button
              disabled={props.isSubmitting || !props.isValid}
              id="accept"
              text={t('common.accept')}
              type="submit"
            />
            )}
        </Form>
      )}
    </Formik>
  ) : (
    <div />
  );
};

export default ChangeStatus;
