import React, { FC, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { BudgetWizard } from 'modules/budget/components/budgetWizard';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import './index.scss';

import { WizardPageParams, LocationParams } from './types';

export const Wizard: FC<Record<string, unknown>> = () => {
  const history = useHistory();
  const { state }: {state: LocationParams} = useLocation();
  const { questionCode } = useParams<WizardPageParams>();
  const {
    id,
    currentQuestion,
    GoToQuestion,
    clearArticles,
  } = useBudget();

  useEffect(
    () => {
      if (id) {
        if (currentQuestion === BudgetQuestionCodes.SUMMARY) {
          history.push({
            pathname: `/budget/summary/${id}`,
            state: { isBudgetLoaded: true },
          });
        } else {
          history.push(
            {
              pathname: `/budget/wizard/${id}/${currentQuestion}`,
              state: {
                gotoSummary: state?.gotoSummary,
              },
            },
          );
        }
      }
    },
    [currentQuestion, history, id, state?.gotoSummary],
  );

  useEffect(
    () => {
      if (questionCode) {
        GoToQuestion(questionCode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearArticles();
      GoToQuestion(BudgetQuestionCodes.SUMMARY);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className="f-budget-wizard">
      <div className="f-budget-wizard-wrapper">
        <BudgetWizard />
      </div>
    </div>
  );
};
