import classNames from 'classnames';
import React from 'react';

import { formatDate, formatHour } from 'utils/dateManager';

import { NoData } from 'components/base/NoData';

import './index.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DateTimeInfo = (
  { date, time }: { date: string, time: string},
) => (
  <div className={classNames('date-hour-cell')}>
    <div>
      <span>{date ? `${formatDate(date)}` : (<NoData />)}</span>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <span>{time ? `${formatHour(time)}` : (<NoData />)}</span>
    </div>
  </div>
);
