import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';
import { PdfViewrLoadSuccess } from 'components/base/PdfViewer';

import { useWindow } from 'hooks/useWindow';

import { BudgetPdfDocument } from '../budgetPdfDocument';

import { BudgetPdfModalComponent } from './types';

export const BudgetPdfModal: FC<BudgetPdfModalComponent> = ({
  show,
  onHide,
  budgetId,
}) => {
  const { openInNewTab } = useWindow();
  const { t } = useTranslation();

  const [url, setUrl] = useState<string>();
  const onLoadSuccess = ({ objectUrl }: PdfViewrLoadSuccess) => {
    setUrl(objectUrl);
  };
  const openForPrint = useCallback(
    () => {
      if (url) {
        openInNewTab(url);
      }
    },
    [url, openInNewTab],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onHide();
          },
          text: t('common.cancel'),
        },
        {
          disabled: !url,
          onClick: () => {
            openForPrint();
          },
          text: t('common.print'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('article.flowers.printTitle')}
      onHide={onHide}
    >
      {show && (<BudgetPdfDocument id={budgetId} onLoadSuccess={onLoadSuccess} />)}
    </Modal>
  );
};
