import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { orderLineController } from 'config/apiFunus';
import { DisagreementTypeResponseDto, MasterDataDto } from 'config/apiFunus/generated/data-contracts';

export type DisagreementTypesHook = {
  types: MasterDataDto[],
  getDisagreementType: (code: string) => MasterDataDto | undefined,
  fetchTypes: () => Promise<MasterDataDto[]>
}

export const useDisagreementTypes = (): DisagreementTypesHook => {
  const { t } = useTranslation();
  const [types, setTypes] = useState<MasterDataDto[]>([]);

  const fetchTypes = () => orderLineController.getDisagreementTypeUsingGet()
    .then((response: DisagreementTypeResponseDto) => {
      if (response?.types) {
        setTypes(response?.types?.map((e) => ({
          code: e,
          description: t(`article.types.${e}`),
        })));
      }
      return true;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((error: any) => error);

  return {
    fetchTypes,
    getDisagreementType: (code: string) => types.find((e) => e.code === code),
    types,
  };
};
