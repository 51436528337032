import classNames from 'classnames';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import React, {
  createRef, FC, useEffect, useState,
} from 'react';
import {
  CheckCircle, Save, UserPlus, Users,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams, useHistory } from 'react-router-dom';

import config from 'config';

import { objectsAreDifferent } from 'utils/helpers';
import { checkHaveThisPermissions, checkPermission } from 'utils/permissions';
import { showErrorToast, showSuccessToast } from 'utils/toasts';

import BackButton from 'components/base/BackButton';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import ExpandableBox from 'components/base/ExpandableBox';
import { HoverContainer } from 'components/base/HoverContainer';
import Loader from 'components/base/Loader';
import Message from 'components/base/Message';
import Modal from 'components/base/Modal';
import SignModal from 'components/base/SignModal';

import Clients from 'components/Clients';
import { AddFlowersRequestModal } from 'components/pages/articles/addFlowersRequestModal';
import { PrintModal } from 'components/pages/articles/printModal';
import RecordTopInfo from 'components/record/TopInfo';
import CeremonyForm from 'components/service/CeremonyForm';
import CremationForm from 'components/service/CremationForm';
import DepositForm from 'components/service/DepositForm';
import IntermentForm from 'components/service/IntermentForm';
import { MovementForm } from 'components/service/MovementForm';
import PreparationForm from 'components/service/PreparationForm';
import ServiceSidebar from 'components/service/Sidebar';
import TransferForm from 'components/service/TransferForm';
import VigilForm from 'components/service/VigilForm';

import { ConflictError } from 'config/apiErrors/ConflictError';
import { OrderLineChannel, MasterServiceDtoType, SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useFeatures } from 'hooks/useFeatures/useFeatures';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import MasterService, {
  SaveService,
} from 'models/MasterService';
import { RecordStateEnum, SaveRecord } from 'models/Record';
import './index.scss';
import { SchemaTypes } from 'models/ValidationSchemas';
import { BudgetModal } from 'modules/budget/components/budgetModal';

import { clearAudit } from '../../../base/StepsForm/helper';

type PageParams = {
  id: string;
};

const ServicesPage: FC = () => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [validating, setValidating] = useState(false);
  const [record, setRecord] = useState<SaveRecord>();
  const [serviceInfo, setServiceInfo] = useState<SaveService>();
  const [selected, setSelected] = useState<MasterService>();
  const [seeView, setSeeView] = useState<boolean>(false);
  const { user } = useProvidedAuth();
  const { id } = useParams<PageParams>();
  const { t } = useTranslation();
  const billRef = createRef<HTMLDivElement>();
  const {
    canViewFlowers,
    canAddFlowers,
    canAddBudget,
  } = useFeatures();
  const [showAddFlowersRequestModal, setShowAddFlowersRequestModal] = useState<boolean>(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [showBudget, setShowBudget] = useState<boolean>(false);

  const [articleId, setArticleId] = useState<number>();

  const loadService = () => config.apiFunus.adviser
    .getAdviserInfo(id)
    .then((res) => {
      setServiceInfo({ ...res.data, id });
      return res.data;
    })
    .catch((err) => {
      showErrorToast(err.message ? err.message : t('service.loadKo'));
    });

  const loadRecord = () => {
    if (
      !checkHaveThisPermissions(
        [SimpleUserRequestPermissionNames.ADVICE_WRITE],
        user?.role.permissions,
      )
    ) {
      setSeeView(false);
      return undefined;
    }

    return config.apiFunus.record.getRecordById(id).then((res) => {
      // FIXME: no borrar el comentario. sirve para que sólo puedan
      // editar los usuarios asignados a los expedientes
      // const { negotiators } = res?.data;

      // if (negotiators) {
      //   const keys = Object.keys(negotiators);
      //   let isNegotiator = false;
      //   keys.forEach((key) => {
      //     if (negotiators[key].id === user?.id) {
      //       isNegotiator = true;
      //     }
      //   });

      //   if (!isNegotiator) {
      //     setSeeView(true);
      //   }
      // }
      setRecord(res.data);
      return res.data;
    });
  };

  useEffect(() => {
    if (id && !loading) {
      setLoading(true);
      Promise.all([loadService(), loadRecord()])
        .then((values) => {
          setLoading(false);

          return values;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSave = (
    values: SaveService,
    actions: FormikHelpers<SaveService>,
    clearing?: boolean,
  ) => {
    setLoading(true);
    const sendValues = { ...values };
    delete sendValues.id;

    const func = validating && !clearing
      ? config.apiFunus.adviser.validateAdviser
      : config.apiFunus.adviser.saveAdviser;

    func(sendValues, id)
      .then((res) => {
        setServiceInfo({ ...res.data, id });
        actions.setValues({ ...res.data, id });
        setLoading(false);
        actions.setSubmitting(false);
        setShowDeleteModal(false);
        showSuccessToast(t('service.saveOk'));
        return undefined;
      })
      .catch((err) => {
        if (err instanceof ConflictError) {
          showErrorToast(t(err.message));
        } else {
          showErrorToast(err.message ? err.message : t('service.saveKo'));
        }
        setLoading(false);
        actions.setSubmitting(false);
      });
  };

  const clear = (props: FormikProps<SaveService>) => {
    const values = { ...props.values };
    if (selected?.type === MasterServiceDtoType.CEREMONIA) {
      values.ceremonyServiceDto = undefined;
      props.setFieldValue('ceremonyServiceDto', null);
      if (
        values.vigilServiceDto
        && Object.keys(values.vigilServiceDto).length > 0
      ) {
        values.vigilServiceDto.exitTime = values.transferServiceDto?.roadTransport?.exitTime
          || values.transferServiceDto?.planeTransport?.flights?.[0]
            ?.departureTime
          || undefined;
        props.setFieldValue(
          'vigilServiceDto.exitTime',
          values.transferServiceDto?.roadTransport?.exitTime
            || values.transferServiceDto?.planeTransport?.flights?.[0]
              ?.departureTime
            || undefined,
        );
        values.vigilServiceDto.exitDate = values.transferServiceDto?.roadTransport?.exitDate
          || values.transferServiceDto?.planeTransport?.flights?.[0]
            ?.departureDate
          || undefined;
        props.setFieldValue(
          'vigilServiceDto.exitDate',
          values.transferServiceDto?.roadTransport?.exitDate
            || values.transferServiceDto?.planeTransport?.flights?.[0]
              ?.departureDate
            || undefined,
        );
      }
      props.setErrors({
        ...(props.errors || {}),
        ceremonyServiceDto: undefined,
      });
    } else if (selected?.type === MasterServiceDtoType.INCINERACION) {
      values.cremationServiceDto = undefined;
      props.setFieldValue('cremationServiceDto', null);
      props.setErrors({
        ...(props.errors || {}),
        cremationServiceDto: undefined,
      });
    } else if (selected?.type === MasterServiceDtoType.DEPOSIT) {
      values.depositServiceDto = undefined;
      props.setFieldValue('depositServiceDto', null);
      props.setErrors({
        ...(props.errors || {}),
        depositServiceDto: undefined,
      });
    } else if (selected?.type === MasterServiceDtoType.INHUMACION) {
      values.intermentServiceDto = undefined;
      props.setFieldValue('intermentServiceDto', null);
      props.setErrors({
        ...(props.errors || {}),
        intermentServiceDto: undefined,
      });
    } else if (selected?.type === MasterServiceDtoType.PREPARACION) {
      values.preparationServiceDto = undefined;
      props.setFieldValue('preparationServiceDto', null);
      props.setErrors({
        ...(props.errors || {}),
        preparationServiceDto: undefined,
      });
    } else if (selected?.type === MasterServiceDtoType.TRANSPORTE) {
      values.transferServiceDto = undefined;
      props.setFieldValue('transferServiceDto', null);
      if (
        values.vigilServiceDto
        && Object.keys(values.vigilServiceDto).length > 0
        && (!values.ceremonyServiceDto
          || !Object.keys(values.ceremonyServiceDto).length)
      ) {
        props.setFieldValue('vigilServiceDto.exitTime', undefined);
        values.vigilServiceDto.exitTime = undefined;
        props.setFieldValue('vigilServiceDto.exitDate', undefined);
        values.vigilServiceDto.exitDate = undefined;
      }
      props.setErrors({
        ...(props.errors || {}),
        transferServiceDto: undefined,
      });
    } else if (selected?.type === MasterServiceDtoType.VELATORIO) {
      values.vigilServiceDto = undefined;
      props.setFieldValue('vigilServiceDto', null);
      props.setErrors({ ...(props.errors || {}), vigilServiceDto: undefined });
    } else if (selected?.type === MasterServiceDtoType.MOVIMIENTO) {
      values.movementServiceDto = undefined;
      props.setFieldValue('movementServiceDto', null);
      props.setErrors({ ...(props.errors || {}), movementServiceDto: undefined });
    }
    onSave(values, props, true);
  };

  const disableClear = (props: FormikProps<SaveService>): boolean => {
    if (selected?.type === MasterServiceDtoType.CEREMONIA) {
      return !props.values.ceremonyServiceDto;
    }
    if (selected?.type === MasterServiceDtoType.INCINERACION) {
      return !props.values.cremationServiceDto;
    }
    if (selected?.type === MasterServiceDtoType.DEPOSIT) {
      return !props.values.depositServiceDto;
    }
    if (selected?.type === MasterServiceDtoType.INHUMACION) {
      return !props.values.intermentServiceDto;
    }
    if (selected?.type === MasterServiceDtoType.PREPARACION) {
      return !props.values.preparationServiceDto;
    }
    if (selected?.type === MasterServiceDtoType.TRANSPORTE) {
      return !props.values.transferServiceDto;
    }
    if (selected?.type === MasterServiceDtoType.VELATORIO) {
      return !props.values.vigilServiceDto;
    }
    if (selected?.type === MasterServiceDtoType.MOVIMIENTO) {
      return !props.values.movementServiceDto;
    }
    return true;
  };

  const expand = () => {
    setExpanded(true);
  };

  const collapse = () => {
    setExpanded(false);
  };

  return (
    <div className="services-page">
      {loading && <Loader />}
      {serviceInfo && (
        <RecordTopInfo
          record={serviceInfo}
          showLink={
            user?.role.permissions
            && checkPermission(
              SimpleUserRequestPermissionNames.RECORD_READ,
              user?.role.permissions,
            )
          }
        />
      )}
      <div className="services-content">
        {!!serviceInfo?.id && (
          <Formik
            initialValues={serviceInfo as SaveService}
            validate={(values) => (validating
              ? config.validator(values, `${SchemaTypes.ADVISER}`)
              : config.validator(values, `${SchemaTypes.SAVEADVISER}`))}
            validateOnChange={false}
            validateOnBlur
            onSubmit={onSave}
          >
            {(props: FormikProps<SaveService>) => {
              const isDeclarantNameProvided = !!props.values.declarantData?.name;

              return (
                <Form autoComplete="off" className="fields">
                  <Prompt
                    message={t('common.unsaved')}
                    when={objectsAreDifferent<SaveService>(
                      clearAudit(serviceInfo),
                      clearAudit(props.values),
                    )}
                  />
                  <Modal
                    buttons={[
                      {
                        onClick: () => clear(props),
                        text: t('common.accept'),
                      },
                    ]}
                    show={showDeleteModal}
                    title={t('service.clear')}
                    onHide={() => setShowDeleteModal(false)}
                  >
                    <div>{t('service.onClearText')}</div>
                  </Modal>
                  <ServiceSidebar
                    erpId={Number(record?.erpId)}
                    formikProps={props}
                    selected={selected}
                    serviceInfo={props.values}
                    onChangeService={setSelected}
                  />
                  {!!selected && (
                  <div className="selected-service">
                    <h3>
                      <BackButton />
                      {t(`service.${selected?.type}`)}
                      <Button
                        className={classNames('clear-button', {
                          hidden:
                          !props.values?.declarantData?.name
                          || record?.state === RecordStateEnum.FINISHED,
                        })}
                        disabled={
                        seeView
                        || disableClear(props)
                        || props.isSubmitting
                        || !props.values?.declarantData?.name
                        || record?.state === RecordStateEnum.FINISHED
                      }
                        text={t('service.clear')}
                        onClick={() => setShowDeleteModal(true)}
                      />
                    </h3>
                    <Box className="selected-service-info">
                      {!props.values.declarantData?.name && (
                      <Message type="error">
                        {t('service.noDeclarant')}
                      </Message>
                      )}
                      {selected?.type === MasterServiceDtoType.CEREMONIA && (
                      <CeremonyForm
                        className={
                          selected?.type === MasterServiceDtoType.CEREMONIA
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                        }
                        formikProps={props}
                        record={record}
                      />
                      )}
                      {selected?.type === MasterServiceDtoType.VELATORIO && (
                      <VigilForm
                        className={
                          selected?.type === MasterServiceDtoType.VELATORIO
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                        }
                        formikProps={props}
                        record={record}
                      />
                      )}
                      {selected?.type === MasterServiceDtoType.PREPARACION && (
                      <PreparationForm
                        className={
                          selected?.type === MasterServiceDtoType.PREPARACION
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                          || Boolean(props.values.preparationServiceDto?.readOnly)
                        }
                        formikProps={props}
                        record={record}
                      />
                      )}
                      {selected?.type === MasterServiceDtoType.INCINERACION && (
                      <CremationForm
                        className={
                          selected?.type === MasterServiceDtoType.INCINERACION
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                        }
                        formikProps={props}
                        record={record}
                      />
                      )}
                      {selected?.type === MasterServiceDtoType.INHUMACION && (
                      <IntermentForm
                        className={
                          selected?.type === MasterServiceDtoType.INHUMACION
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                        }
                        formikProps={props}
                        record={record}
                      />
                      )}
                      {selected?.type === MasterServiceDtoType.TRANSPORTE && (
                      <TransferForm
                        className={
                          selected?.type === MasterServiceDtoType.TRANSPORTE
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                        }
                        formikProps={props}
                        record={record}
                      />
                      )}
                      {selected?.type === MasterServiceDtoType.DEPOSIT && (
                      <DepositForm
                        className={
                          selected?.type === MasterServiceDtoType.DEPOSIT
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                        }
                        formikProps={props}
                        record={record}
                      />
                      )}
                      {selected?.type === MasterServiceDtoType.MOVIMIENTO && (
                      <MovementForm
                        className={
                          selected?.type === MasterServiceDtoType.MOVIMIENTO
                            ? undefined
                            : 'hidden-service'
                        }
                        disabled={
                          seeView
                          || record?.state === RecordStateEnum.FINISHED
                          || !props.values.declarantData?.name
                        }
                        formikProps={props}
                      />
                      )}
                    </Box>

                    <div className="action-buttons">
                      {isDeclarantNameProvided
                        && (
                        <div className="action-button">
                          <Button
                            className="inverted"
                            disabled={seeView || props.isSubmitting || loading}
                            testid="add-client-button"
                            onClick={expand}
                          >
                            <UserPlus />
                          </Button>
                          <span>{t('common.clients')}</span>
                        </div>
                        )}
                      {false
                        && (
                        <div className="action-button">
                          <SignModal
                            disabled={
                                record?.state === RecordStateEnum.FINISHED
                              }
                            recordId={record?.id || ''}
                            value={record?.signature}
                            onSign={loadRecord}
                          />
                          <span>{t('common.sign')}</span>
                        </div>
                        )}
                      {record
                        && canAddBudget(record)
                        && (
                        <React.Fragment>
                          <div className="action-button">
                            <Button
                              className="inverted"
                              disabled={false}
                              testid="add-budget-button"
                              onClick={() => {
                                history.push({
                                  pathname: config.url.budgetsWizardNew,
                                  state: { recordId: id },
                                });
                              }}
                            >
                              <Users />
                            </Button>
                            <span>{t('budget.processing')}</span>
                          </div>
                          <BudgetModal show={showBudget} onHide={() => setShowBudget(false)} />
                        </React.Fragment>
                        )}
                      {isDeclarantNameProvided
                        && canViewFlowers
                        && record?.state !== RecordStateEnum.FINISHED
                        && canAddFlowers()
                        && (
                        <div className="action-button">
                          <Button
                            className="inverted"
                            disabled={
                              seeView || props.isSubmitting || loading
                            }
                            testid="add-flowers-button"
                            onClick={() => setShowAddFlowersRequestModal(true)}
                          >
                            <HoverContainer defaultStyle={{ stroke: '#a29800' }} hoverStyle={{ stroke: '#ffffff' }}>
                              <CustomIcon icon={OtherTypes.FLOWERS} />
                            </HoverContainer>
                          </Button>
                          <span>{t('article.addFlowers')}</span>
                        </div>
                        )}
                      {isDeclarantNameProvided
                        && record?.state !== RecordStateEnum.FINISHED
                        && (
                        <div className="action-button">
                          <Button
                            className="inverted"
                            disabled={
                                  seeView || props.isSubmitting || loading
                                }
                            testid="record-save-button"
                            type="submit"
                            onClick={() => setValidating(false)}
                          >
                            <Save />
                          </Button>
                          <span>{t('common.save')}</span>
                        </div>
                        )}
                      {isDeclarantNameProvided
                        && record?.state !== RecordStateEnum.FINISHED
                        && (
                        <div className="action-button">
                          <Button
                            disabled={
                                  seeView || props.isSubmitting || loading
                                }
                            testid="record-validate-button"
                            type="submit"
                            onClick={() => setValidating(true)}
                          >
                            <CheckCircle />
                          </Button>
                          <span>{t('common.validate')}</span>
                        </div>
                        )}
                    </div>
                    <ExpandableBox
                      divRef={billRef}
                      expanded={expanded}
                      onClose={collapse}
                    >
                      <Clients
                        disabled={record?.state === RecordStateEnum.FINISHED}
                        formikProps={props}
                      />
                    </ExpandableBox>
                  </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      {canViewFlowers && (
      <AddFlowersRequestModal
        erpId={record?.erpId}
        id={record?.id}
        show={showAddFlowersRequestModal}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        vigilDate={(serviceInfo?.defaultArticleDate as any)}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        vigilHour={(serviceInfo?.defaultArticleTime as any)}
        onCancel={() => setShowAddFlowersRequestModal(false)}
        onSuccess={(requestChannel, newArticleId) => {
          setShowAddFlowersRequestModal(false);
          if (newArticleId) {
            setArticleId(newArticleId);
          }
          if (requestChannel !== OrderLineChannel.PROCESSING) {
            setShowPrintModal(true);
          }
        }}
      />
      )}
      <PrintModal
        id={articleId}
        show={showPrintModal}
        onHide={() => {
          setShowPrintModal(false);
        }}
      />
    </div>
  );
};

export default ServicesPage;
