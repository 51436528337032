import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object, SchemaOf } from 'yup';

interface ArticleAmountValidationHook {
  validate: () => SchemaOf<{
    amount?: number;
  }>;
}

export const useArticleAmountValidation = (): ArticleAmountValidationHook => {
  const { t } = useTranslation();
  const validate = useCallback(
    () => object().shape({
      amount: number()
        .integer()
        .min(1, t('validationRules.number.min', { min: 1 }))
        .typeError(t('validationRules.typeError'))
        .required(t('validationRules.required')),
    }),
    [t],
  );

  return {
    validate,
  };
};
