import { RefObject, useEffect } from 'react';

type EventsProps = {
  onClickDown?: (event: KeyboardEvent) => void;
  onClickLeft?: (event: KeyboardEvent) => void;
  onClickEnter?: (event: KeyboardEvent) => void;
  onClickEscape?: (event: KeyboardEvent) => void;
  onClickRight?: (event: KeyboardEvent) => void;
  onClickUp?: (event: KeyboardEvent) => void;
};

const useKeyboardClick = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  events: EventsProps,
): void => {
  useEffect(() => {
    const el = ref?.current;
    const listener = (event: KeyboardEvent) => {
      if (el && el.contains(event.target as Node)) {
        switch (event.key) {
          case 'ArrowRight':
            if (events.onClickRight) {
              events.onClickRight(event);
            }
            break;
          case 'ArrowLeft':
            if (events.onClickLeft) {
              events.onClickLeft(event);
            }
            break;
          case 'ArrowUp':
            if (events.onClickUp) {
              events.onClickUp(event);
            }
            break;
          case 'ArrowDown':
            if (events.onClickDown) {
              events.onClickDown(event);
            }
            break;
          case 'Enter':
            if (events.onClickEnter) {
              events.onClickEnter(event);
            }
            break;
          case 'Escape':
            if (events.onClickEscape) {
              events.onClickEscape(event);
            }
            break;
          default:
            break;
        }
      }
    };
    if (el) {
      el.addEventListener('keydown', listener);
    }

    return () => {
      if (el) {
        el.removeEventListener('keydown', listener);
      }
    };

    // Reload only if ref or handler changes
  }, [ref, events]);
};

export default useKeyboardClick;
