import { Middleware } from 'redux';

import { SetArticleClient } from '../budgetActions';
import { BudgetActionTypes, SetArticleClientBulkPayload } from '../budgetActions/types';

export const PersistArticleClientBulk: Middleware = (storeApi) => (next) => async (action) => {
  if (
    action.type === BudgetActionTypes.SET_ARTICLE_CLIENT_BULK) {
    const result = next(action);

    const { articles, client, idBudget }: SetArticleClientBulkPayload = action.payload;

    articles.forEach((article) => {
      storeApi.dispatch(SetArticleClient({
        article,
        client,
        idBudget,
      }));
    });

    return result;
  }

  return next(action);
};
