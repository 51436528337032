import { Audit } from '../Audit';

import { IBudgetLocation } from './types';

export class BudgetLocation extends Audit implements IBudgetLocation {
  constructor(params: BudgetLocation) {
    super(params);
    this.city = params.city;
    this.province = params.province;
    this.country = params.country;
    this.location = params.location;
  }

  city: string;

  province: string;

  country: string;

  location?: string | undefined;
}
