import React from 'react';

import { NoData } from 'components/base/NoData';

import { ServiceInformationDto } from 'config/apiFunus/types';
import useCeremonyLocation from 'hooks/useCeremonyLocation';

import '../index.scss';
import { DateTimeInfo } from '../DateTimeInfo';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CeremonyCell = ({ value }: { value: ServiceInformationDto}) => {
  const { getCeremonyLocation } = useCeremonyLocation(value.city);
  const displayTime = (value.date && value.time) ? `2020-01-01T${value.time}` : null;

  return (
    <React.Fragment>
      <div className="text-align-center">
        {getCeremonyLocation(value.location)?.description || (<NoData />)}
      </div>
      {displayTime ? <DateTimeInfo date={value.date} time={displayTime} /> : <NoData />}
    </React.Fragment>
  );
};
