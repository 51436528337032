import { FunusMovementTypes } from 'config/apiFunus/generated/data-contracts';
import Address from 'models/Address';
import { DeathType, DeceasedData } from 'models/Record';

import { DateType } from './dataTypes';
import { Declarant } from './Declarant';
import {
  CeremonyService,
  CremationService,
  VigilService,
  PreparationService,
  DepositService,
  IntermentService,
  RoadTransport,
  PlaneTransport,
  SanitaryPractice,
  FittedType,
} from './MasterService';
import { SimpleUser } from './User';

export enum DeceasedLocationType {
  CENTRO = 'CENTRO',
  CAMARA = 'CAMARA',
}

export enum FinalDestinationType {
  CEMENTERIO = 'CEMENTERIO',
  CREMATORIO = 'CREMATORIO',
}

export enum CollectionType {
  HOSPITAL = 'HOSPITAL',
  RESIDENCIA = 'RESIDENCIA',
  DOMICILIO = 'DOMICILIO',
  JUDICIAL = 'JUDICIAL',
}

export enum PreparationType {
  ESTANDAR = 'ESTANDAR',
  PRACTICA_SANITARIA = 'PRACTICA_SANITARIA',
}

export enum OutfitType {
  VESTIMENTA = 'VESTIMENTA',
  MARCAPASOS = 'MARCAPASOS',
  OBJETOS_PERSONALES = 'OBJETOS_PERSONALES',
  AFEITADO = 'AFEITADO',
  PEINADO = 'PEINADO',
  MAQUILLAJE = 'MAQUILLAJE',
}

export enum SanitaryPracticeType {
  TAPONAR = 'TAPONAR',
  ADOL = 'ADOL',
  CELOFANA = 'CELOFANA',
  SERRADURES_SULFAT = 'SERRADURES_SULFAT',
  MARCAPASOS = 'MARCAPASOS',
}

export enum LocationType {
  CAMARA_FRIGORIFICA = 'CAMARA_FRIGORIFICA',
  FERETRO = 'FERETRO',
}

export enum TargetType {
  INSTALACION = 'INSTALACION',
  ENTREGA = 'ENTREGA',
}

export enum TransportType {
  CARRETERA = 'CARRETERA',
  AVION = 'AVION',
}

type CommonOrderInfo = {
  advisorComment?: string;
  coordinatorComment?: string;
  deceasedQr?: string;
  mainNegociator?: string;
  observation?: string;
  observations?: string;
  operatorComment?: string;
  secondaryNegociator?: string;
};

export type CeremonyOrder = CommonOrderInfo & {
  cremation?: {
    address?: Address;
    crematorium?: string;
    entryDate?: Date;
    entryTime?: Date;
  };
  currentCeremony?: {
    address?: Address;
    comment?: string;
    date?: Date;
    location?: string;
    time?: Date;
    type?: string;
  };
  deceased?: DeceasedData;
  declarantName?: string;
  deposit?: DepositService;
  interment?: Interment;
  observations?: string;
  previousCeremony?: {
    address?: Address;
    comment?: string;
    date?: Date;
    location?: string;
    time?: Date;
    type?: string;
  };
  vigil?: {
    address?: Address;
    exitDate?: Date;
    exitTime?: Date;
    place?: string;
    room?: string;
    location?: string;
  };
  ceremonial?: SimpleUser;
  totalFlowers?: number;
  personalItems?: string;
  coffinPreparationDetail?: string;
  flowers?: Array<{description: string; dedicatory: string;}>;
};

export type CollectionOrder = CommonOrderInfo & {
  deceased?: DeceasedData;
  deathLocation?: string;
  declarant?: Declarant;
  destination?: string;
  location?: LocationType | string;
  pickupLocation?: Address;
  remarks?: string;
  vehicle?: string;
  deathComment?: string;
  driver?: string;
  observation?: string;
  coordinatorComment?: string;
  phone?: string;
  deposit?: DepositService;
};

export type CremationOrder = CommonOrderInfo & {
  location?: Address;
  crematorium?: string;
  cremationDeposit?: string;
  declarant?: Declarant;
  entryDate?: Date;
  entryTime?: Date;
  exitDate?: Date;
  exitTime?: Date;
  owner?: string;
  pacemaker?: boolean;
  reliquaries?: string[];
  urnModels?: string[];
  familyPresence?: boolean;
  ceremony?: CeremonyService;
  deposit?: DepositService;
  vigil?: {
    address?: Address;
    atHome?: boolean;
    comment?: string;
    date?: Date;
    location?: string;
    place?: string;
    room?: string;
    time?: Date;
  };
  totalFlowers?: number;
  cremationDocumentation?: boolean;
  cremationDocumentationUser?: string;
  cremationDocumentationDatetime?: string;
};

export type DeceasedPreparation = CommonOrderInfo & {
  ceremony?: CeremonyService;
  comment?: string;
  death?: {
    cause?: string;
    comment?: string;
    date?: Date;
    disease?: string;
    time?: Date;
    type?: DeathType;
  };
  deceased?: {
    nickname?: string;
  };
  deposit?: DepositService;
  vigil?: {
    address?: Address;
    atHome?: boolean;
    comment?: string;
    entryDate?: Date;
    location?: string;
    place?: string;
    room?: string;
    entryTime?: Date;
  };
  clothing: boolean;
  clothingComment: string;
  coffin: string;
  coffinArticle: string;
  coffinPreparationDetail: string;
  finalDestination: string;
  hairstyle: boolean;
  hairstyleComment: string;
  makeup: boolean;
  makeupComment: string;
  observation: string;
  pacemaker: boolean;
  shaved: boolean;
  shavedComment: string;
  zincBox: boolean;
  personalItems: string;
  creu: boolean | null;
  creuComment: string;
  cover: boolean | null;
  coverComment: string;
  aconditioning?: boolean | null;
};

export type GravestoneInfo = CommonOrderInfo & {
  design?: string;
  destination?: string;
};

export type Interment = CommonOrderInfo & {
  cemetery?: string;
  comment?: string;
  date?: Date;
  deceased?: DeceasedData;
  declarantName?: string;
  familiarPresentPreparation?: boolean;
  holder?: string;
  location?: Address;
  address?: Address;
  niche?: string;
  nicheNumber?: string;
  nicheSection?: string;
  nicheTier?: string;
  takeMeasurementsPhoto?: boolean;
  time?: Date;
  ashes?: boolean;
  totalFlowers?: number;
};

export type VigilWorkorder = CommonOrderInfo & {
  entryDate: string;
  entryTime: string;
  exitDate: string;
  exitTime: string;
  address: Address;
  location: string;
  atHome: boolean;
  place: string;
  room: string;
  comment: string;
};

export type InternalMovementsInfo = CommonOrderInfo & {
  cremationService?: CremationService;
  depositService?: DepositService;
  intermentService?: IntermentService;
  vigilService?: VigilService;
};

export type MovementOrderInfo = CommonOrderInfo & {
  type: FunusMovementTypes;
  vigilPlace?: string;
  address?: Address;
  destination?: string;
  street?: string;
  date?: DateType;
  time?: string;
  vehicle?: string;
  comment?: string;
};

export type NichePreparation = CommonOrderInfo & {
  cemetery?: string;
  comment?: string;
  familyPresence?: boolean;
  date?: Date;
  holder?: string;
  location?: Address;
  measurementsAndPhotos?: boolean;
  niche?: string;
  nicheNumber?: number;
  nicheSection?: string;
  nicheTier?: number;
  time?: Date;
  ashes?: boolean;
};

export type ReceptionInfo = CommonOrderInfo & {
  preparation?: PreparationService;
  deposit?: DepositService;
  date?: string;
  time?: string;
  observations?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location?: any;
  funeraryOrigin: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destinationCenter?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vigilRoom?: any;
  type: FittedType;
  coffin?: boolean;
  address?: Address;
};

export type Recipient = CommonOrderInfo & {
  address?: Address;
  email?: string;
  name?: string;
  phone?: string;
  remarks?: string;
};

export type SanitaryPracticeInfo = CommonOrderInfo & {
  death?: {
    cause: string;
    comment: string;
    date: Date;
    disease: string;
    time: Date;
    type: string;
  };
  sanitaryPractices: SanitaryPractice[];
};

export type Transport = CommonOrderInfo & {
  planeTransport?: PlaneTransport;
  roadTransport?: RoadTransport;
  type?: TransportType;
};

export type Transfer = CommonOrderInfo & {
  deceased?: DeceasedData;
  deposit?: DepositService;
  recipient?: Recipient;
  transport?: Transport;
  vigil?: VigilService;
};

export type TransferRemainder = CommonOrderInfo & {
  cemeteryDestination?: string;
  cemeteryOrigin?: string;
  deceased?: DeceasedData;
  location?: LocationType | string;
  nicheDestination?: string;
  nicheOrigin?: string;
};

export type Handmade = CommonOrderInfo & {
  description: string;
  vehicle?: string;
};

type OrderInfo =
  | CeremonyOrder
  | CollectionOrder
  | CremationOrder
  | DeceasedPreparation
  | Interment
  | InternalMovementsInfo
  | MovementOrderInfo
  | NichePreparation
  | ReceptionInfo
  | GravestoneInfo
  | SanitaryPracticeInfo
  | Transfer
  | TransferRemainder
  | Handmade;

export default OrderInfo;
