import classNames from 'classnames';
import React, { ReactElement } from 'react';

import Button from 'components/base/Button';

import './index.scss';

export type SelectButtonProps<T> = {
  className?: string | undefined;
  color?: 'primary' | 'secondary' | 'attention' | 'special' | 'transparent';
  disabled?: boolean | undefined;
  error?: string | boolean | undefined;
  getLabel: (elem: T) => string;
  getValue: (elem: T) => string | number;
  name?: string;
  onChange(elem?: T): void;
  options: T[];
  placeholder?: string | undefined;
  value?: T | undefined;
};

function SelectButton<T>({
  className,
  color,
  disabled,
  error,
  getLabel,
  getValue,
  onChange,
  options,
  placeholder,
  value,
}: SelectButtonProps<T>): ReactElement {
  const onClickOption = (elem?: T) => {
    onChange(elem);
  };

  return (
    <div
      className={classNames('funus-select-button', className, {
        error: !!error,
      })}
    >
      {placeholder && <label>{placeholder}</label>}
      <div className="select-button-options">
        {options.map((opt) => (
          <Button
            key={getValue(opt)}
            className={classNames({ selected: value === opt })}
            color={value === opt ? color : 'secondary'}
            disabled={disabled}
            text={getLabel(opt)}
            onClick={() => onClickOption(opt)}
          />
        ))}
      </div>
      <span className={classNames({ hidden: !error })}>{error}</span>
    </div>
  );
}

export default SelectButton;
