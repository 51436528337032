const ip = {
  address: 'Dirección IP',
  detail: 'Detalle de televisión',
  edit: 'Editar television',
  new: 'Nueva televisión',
  notAllowed: 'Esta televisión no está habilitada. Contacte con el administrador y facilítele la siguiente IP:',
  saveKo: 'Se ha producido un error al crear la televisión.',
  saveOk: 'La televisión se ha creado satisfactoriamente.',
  search: 'Buscar televisiones',
  title: 'Televisiones',
  wrongCenter:
    'El centro o el tipo de centro es incorrecto. Por favor, compruebe la url.',
};
export default ip;
