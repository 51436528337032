import { Form, Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';

import { OptionSelector } from 'modules/budget/components/commons/OptionSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionOptions } from 'modules/budget/hooks/useQuestionOptions';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes } from 'modules/budget/models/Article/types';
import { ServiceTypes } from 'modules/budget/store/data';

export interface Q1Response {
  responseValue: ServiceTypes | null
  autorespond: boolean;
}

interface Q1FormComponent {
  onChange: (value: Q1Response) => void;
  question: BudgetQuestionCodes;
}

export const Q1Form: FC<Q1FormComponent> = ({ onChange, question }) => {
  const { fetchOptions, items } = useQuestionOptions();
  const { GetQuestionValue } = useBudget();
  const [selection, setSelection] = useState<Article[]>([]);

  useEffect(
    () => {
      fetchOptions(question, ArticleCollectionTypes.ARTICLE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question],
  );

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue) {
        onChange({
          autorespond: false,
          responseValue: storedValue,
        });
        setSelection([new Article({
          amount: 1,
          code: storedValue,
          description: '',
        })]);
      } else {
        setSelection([]);
      }
    },
    [onChange, storedValue],
  );

  return (
    <Formik
      initialValues={{
        autorespond: false,
        responseValue: null,
      }}
      validate={(values) => {
        onChange({
          autorespond: values.autorespond,
          responseValue: values.responseValue,
        });
        return {};
      }}
      enableReinitialize
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        setValues,
      }: FormikProps<Q1Response>) => (
        <Form autoComplete="off">
          <OptionSelector
            items={items}
            value={selection}
            highlightFirst
            onChange={(articles: Article[]) => {
              if (articles.length === 1) {
                setSelection([new Article({
                  ...articles[0],
                  amount: 1,
                })]);
                setValues({
                  autorespond: true,
                  responseValue: articles[0].code as ServiceTypes,
                });
              } else {
                setSelection([]);
                setValues({
                  autorespond: false,
                  responseValue: null,
                });
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
