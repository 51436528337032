import React, { FC, useEffect, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';

import useWindowSize from 'hooks/useWindowSize';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

import { ImageCropComponent } from './types';

import 'react-image-crop/src/ReactCrop.scss';
import './index.scss';

export const ImageCrop: FC<ImageCropComponent> = ({
  image, onCropFinish, cameraFrameWidth,
}) => {
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const [cameraFrameHeight, setCameraFrameHeight] = useState(0);

  const [crop, setCrop] = useState<Crop>({
    height: cameraFrameHeight,
    unit: 'px',
    width: cameraFrameWidth,
    x: 0,
    y: 0,
  });

  const previewRef = React.useRef(null);
  const [cropX, setCropX] = useState<number>(0);
  const [cropY, setCropY] = useState<number>(0);
  const [cropWidth, setCropWidth] = useState<number>(cameraFrameWidth);
  const [cropHeight, setCropHeight] = useState<number>(cameraFrameHeight);

  const [croppedImage, setCroppedImage] = useState<string>();

  useEffect(() => {
    setCrop({
      height: cameraFrameHeight,
      unit: 'px',
      width: cameraFrameWidth,
      x: 0,
      y: 0,
    });
    setCropX(0);
    setCropY(0);
    setCropWidth(cameraFrameWidth);
    setCropHeight(cameraFrameHeight);
  }, [cameraFrameHeight, cameraFrameWidth, windowHeight, windowWidth]);

  useEffect(
    () => {
      if (previewRef) {
        const originalImage = new Image();
        originalImage.src = image.toDataUrl();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const canvas = (previewRef.current as any);
        const ctx = canvas?.getContext('2d');

        originalImage.addEventListener('load', () => {
          const imageScale = originalImage.naturalWidth / cameraFrameWidth;

          if (!ctx) {
            throw new Error('No 2d context');
          }

          canvas.width = cropWidth * imageScale;
          canvas.height = cropHeight * imageScale;

          ctx.drawImage(
            originalImage,

            cropX * imageScale,
            cropY * imageScale,
            cropWidth * imageScale,
            cropHeight * imageScale,

            0,
            0,
            cropWidth * imageScale,
            cropHeight * imageScale,
          );

          setCroppedImage(canvas.toDataURL(MimeTypes.IMAGE_PNG));

          onCropFinish(new Base64Data({
            data: Base64Data.getFromDataUrl(canvas.toDataURL(MimeTypes.IMAGE_PNG), 'data') || '',
            mimetype: MimeTypes.IMAGE_PNG,
          }));
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cropHeight, cropWidth, image, cropX, cropY, cameraFrameWidth],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onImageLoad = (e: any) => {
    const { naturalWidth: imageWidth, naturalHeight: imageHeight } = e.currentTarget;
    const imageScale = imageWidth / cameraFrameWidth;

    setCameraFrameHeight(imageHeight / imageScale);

    setCrop({
      height: imageHeight * imageScale,
      unit: 'px',
      width: imageWidth * imageScale,
      x: 0,
      y: 0,
    });
  };

  return (
    <React.Fragment>
      <div className="image-crop-component">
        <ReactCrop
          crop={crop}
          onChange={(cropData) => {
            setCrop(cropData);
          }}
          onComplete={(cropData) => {
            setCropX(cropData.x);
            setCropY(cropData.y);
            setCropWidth(cropData.width);
            setCropHeight(cropData.height);
          }}
        >
          <img
            alt="crop"
            src={image.toDataUrl()}
            style={{
              width: `${cameraFrameWidth}px`,
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        {croppedImage ? (
          <img
            alt="crop-preview"
            className="crop-preview"
            src={croppedImage}
            style={{
              maxHeight: `${cameraFrameHeight}px`,
              maxWidth: `${cameraFrameWidth}px`,
            }}
          />
        ) : <div>&nbsp;</div>}
      </div>
      <canvas
        ref={previewRef}
        id="canvas"
        style={{ display: 'none', width: '100%' }}
      />
    </React.Fragment>

  );
};
