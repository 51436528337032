import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import ErrorBoundary from 'components/error/ErrorBoundary';
import Authorized from 'components/pages/Authorized';
import Layout from 'components/pages/Layout';

import AuthProvider from 'context/AuthProvider';

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/_animations.scss';
import 'assets/_fonts.scss';
import 'assets/_layout.scss';

const App: FC = () => (
  <ErrorBoundary>
    <ToastContainer closeOnClick hideProgressBar pauseOnHover />
    <AuthProvider>
      <Authorized>
        <Layout />
      </Authorized>
    </AuthProvider>
  </ErrorBoundary>
);

export default App;
