import classNames from 'classnames';
import React, { FC } from 'react';

import './index.scss';

type TopInfoProps = {
  className?: string;
};

const TopInfo: FC<TopInfoProps> = ({ children, className }) => (
  <div className={classNames('top-info', className)}>{children}</div>
);

export default TopInfo;
