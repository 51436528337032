import { Form, Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { formatHour, getCurrentDate } from 'utils/dateManager';

import Datepicker from 'components/base/Datepicker';
import Input from 'components/base/Input';
import Select from 'components/base/Select';

import { MasterDataDto, OrderLineChannel } from 'config/apiFunus/generated/data-contracts';
import { useArticles } from 'hooks/useArticles';
import { useValidator } from 'hooks/useValidator';

export type ExternalFlower = {
  amount: number;
  channel: OrderLineChannel;
  category?: string;
  extra?: string;
  description?: string;
  dedicatory?: string;
  date?: Date;
  time?: Date;
  supplier: string;
  sender?: string;
  observations?: string;
}

type AddExternalFlowersFormÇomponent = {
  onChange: (payload: ExternalFlower) => void;
  onIsValidUpdate?: (isValid: boolean) => void;
  erpId?: string | number | undefined;
}

export const AddExternalFlowersForm: FC<AddExternalFlowersFormÇomponent> = (
  { onChange, onIsValidUpdate, erpId },
) => {
  const { validateArticleAddRequest } = useValidator();
  const { t } = useTranslation();
  const {
    fetchArticleCategories, articleCategories,
  } = useArticles();

  const [customExternalFlowerTypes, setCustomExternalFlowerTypes] = useState<MasterDataDto[]>([]);

  useEffect(() => {
    setCustomExternalFlowerTypes([
      ...articleCategories,
      {
        code: 'OTHER',
        description: t('common.other'),
      },
    ]);
  }, [articleCategories, t]);

  const [initialValues] = useState<ExternalFlower>({
    amount: 1,
    category: undefined,
    channel: OrderLineChannel.EXTERNAL,
    date: getCurrentDate(),
    dedicatory: '',
    description: '',
    observations: '',
    sender: '',
    supplier: '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    time: formatHour(getCurrentDate()) as any,
  });

  useEffect(
    () => {
      onChange({
        amount: 1,
        category: undefined,
        channel: OrderLineChannel.EXTERNAL,
        date: undefined,
        dedicatory: '',
        description: '',
        observations: '',
        sender: '',
        supplier: '',
        time: undefined,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      fetchArticleCategories()
        .catch(() => false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const request = {
          ...values,
          erpId,
        };
        onChange(request);
        return validateArticleAddRequest(request);
      }}
      validateOnBlur
      validateOnChange
      validateOnMount
      onSubmit={() => Promise.resolve()}
    >
      {({
        setFieldValue, values, errors, isValid,
      }: FormikProps<ExternalFlower>) => {
        onIsValidUpdate?.(isValid);

        return (
          <Form autoComplete="off">
            <Select<MasterDataDto>
              key="article.externalFlowerType"
              error={errors.category}
              getLabel={({ description }) => description || ''}
              getValue={({ code }) => code || ''}
              name="article.externalFlowerType"
              options={customExternalFlowerTypes}
              placeholder={t('common.type')}
              value={values?.category
                ? customExternalFlowerTypes.find((e) => e.code === values.category)
                : undefined}
              searchable
              onChange={(val) => {
                setFieldValue('category', val?.code);
              }}
            />
            {values.category === 'OTHER' && (
            <Input
              error={errors.extra}
              id="article.extra"
              name="article.extra"
              placeholder={t('article.otherCategoryComment')}
              type="text"
              value={values.extra || ''}
              onBlur={() => false}
              onChange={(val) => setFieldValue('extra', val)}
            />
            )}
            <Input
              error={errors.description}
              id="article.externalFlowerDescription"
              name="article.externalFlowerDescription"
              placeholder={t('common.description')}
              type="text"
              value={values.description || ''}
              onBlur={() => false}
              onChange={(val) => setFieldValue('description', val)}
            />
            <Input
              error={errors.dedicatory}
              id="article.externalFlowerDedicatory"
              name="article.externalFlowerDedicatory"
              placeholder={t('article.flowers.dedicatory')}
              type="text"
              value={values.dedicatory || ''}
              onBlur={() => false}
              onChange={(val) => setFieldValue('dedicatory', val)}
            />
            <Datepicker
              key="date"
              error={errors.date}
              name="date"
              placeholder={t('common.date')}
              selected={values.date}
              onChange={(val) => setFieldValue('date', val)}
            />
            <Datepicker
              key="time"
              error={errors.time}
              name="time"
              placeholder={t('common.hour')}
              selected={values.time}
              showTimeSelectOnly
              onChange={(val) => setFieldValue('time', val)}
            />
            <Input
              error={errors.supplier}
              id="article.externalFlowerSupplier"
              name="article.externalFlowerSupplier"
              placeholder={t('common.provider')}
              type="text"
              value={values.supplier || ''}
              onBlur={() => false}
              onChange={(val) => setFieldValue('supplier', val)}
            />
            <Input
              error={errors.sender}
              id="article.externalFlowerSender"
              name="article.externalFlowerSender"
              placeholder={t('article.externalFlowerSender')}
              type="text"
              value={values.sender || ''}
              onBlur={() => false}
              onChange={(val) => setFieldValue('sender', val)}
            />
            <Input
              error={errors.observations}
              id="article.externalFlowerObservations"
              name="article.externalFlowerObservations"
              placeholder={t('common.observations')}
              type="text"
              value={values.observations || ''}
              onBlur={() => false}
              onChange={(val) => setFieldValue('observations', val)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
