import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { DiscardedArticles } from '../discardedArticles';

import { BudgetDiscardedModalComponent } from './types';

export const BudgetDiscardedModal: FC<BudgetDiscardedModalComponent> = ({ show, onCancel }) => {
  const { t } = useTranslation();
  const { discardedArticles } = useBudget();

  useEffect(
    () => {
      if (show && discardedArticles.length === 0) {
        onCancel();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [discardedArticles.length, show],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onCancel();
          },
          text: t('common.close'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.discarded')}
      onHide={onCancel}
    >
      <DiscardedArticles />
    </Modal>
  );
};
