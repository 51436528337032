import { MasterDataAction } from 'actions/masterData';

import {
  CityType,
  CemeteryByCity,
  CrematoryType,
  IMasterDataDto,
  ProvinceType,
} from 'models/MasterData';
import MasterService, {
  MasterAndNormalized,
  MasterAndNormalizedType,
} from 'models/MasterService';
import { InsuranceCompany } from 'models/Record';
import { masterDataTypes } from 'types/masterData';

export interface IMasterDataReducerState {
  abortionTypes: IMasterDataDto[];
  airports: IMasterDataDto[];
  ashesDestinations: IMasterDataDto[];
  cemeteries: CemeteryByCity[];
  centers: IMasterDataDto[] | null;
  ceremonies: IMasterDataDto[];
  cities: CityType[];
  coffinSizes: IMasterDataDto[] | null;
  concessionTypes: IMasterDataDto[];
  countries: IMasterDataDto[];
  crematories: CrematoryType[];
  deathTypes: IMasterDataDto[] | null;
  funeraryOrigins: IMasterDataDto[];
  genders: IMasterDataDto[];
  groupCauses: IMasterDataDto[];
  insurances: InsuranceCompany[];
  maritalStatus: IMasterDataDto[];
  masterData: MasterService[];
  nicheTypes: IMasterDataDto[];
  ovens: IMasterDataDto[];
  owners: IMasterDataDto[];
  provinces: ProvinceType[];
  recordTypes: IMasterDataDto[];
  rehydrated: boolean;
  reliquaries: IMasterDataDto[];
  sanitaryPractices: IMasterDataDto[];
  thanatologists: IMasterDataDto[];
  thanatopractors: IMasterDataDto[];
  urns: IMasterDataDto[];
  vigilRoom: IMasterDataDto[];
}

export const initState: IMasterDataReducerState = {
  abortionTypes: [],
  airports: [],
  ashesDestinations: [],
  cemeteries: [],
  centers: null,
  ceremonies: [],
  cities: [],
  coffinSizes: null,
  concessionTypes: [],
  countries: [],
  crematories: [],
  deathTypes: null,
  funeraryOrigins: [],
  genders: [],
  groupCauses: [],
  insurances: [],
  maritalStatus: [],
  masterData: [],
  nicheTypes: [],
  ovens: [],
  owners: [],
  provinces: [],
  recordTypes: [],
  rehydrated: false,
  reliquaries: [],
  sanitaryPractices: [],
  thanatologists: [],
  thanatopractors: [],
  urns: [],
  vigilRoom: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateCemeteryByCity = (cemeteryList: CemeteryByCity[], payload: any) => {
  const updatedCemeteryByCityList = cemeteryList.slice();
  const cemeteryIndexByCity = cemeteryList.findIndex(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cemetery: any) => cemetery.cityCode === payload.cityCode,
  );

  if (cemeteryIndexByCity !== -1) {
    updatedCemeteryByCityList[cemeteryIndexByCity] = {
      cemeteries: payload.cemeteries,
      cityCode: payload.cityCode,
    };
  } else {
    updatedCemeteryByCityList.push({
      cemeteries: payload.cemeteries,
      cityCode: payload.cityCode,
    });
  }

  return updatedCemeteryByCityList;
};

export default (
  state: IMasterDataReducerState = initState,
  action: MasterDataAction,
): IMasterDataReducerState => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(action.key === 'masterData' ? (action.payload as any) : {}),
        rehydrated: state.rehydrated || action.key === 'masterData',
      };
    case masterDataTypes.CLEAR_MASTERDATA:
      return { ...initState };
    case masterDataTypes.ADD_MASTER_DATA:
      return {
        ...state,
        masterData: action.payload as MasterService[],
      };
    case masterDataTypes.ADD_MASTER_DATA_CEMETERIES:
      return {
        ...state,
        cemeteries: updateCemeteryByCity(state.cemeteries, action.payload),
      };
    case masterDataTypes.ADD_MASTER_DATA_CENTERS:
      return {
        ...state,
        centers: (action.payload as MasterAndNormalizedType<InsuranceCompany>)
          .masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_AIRPORTS:
      return {
        ...state,
        airports: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_CEREMONIES:
      return {
        ...state,
        ceremonies: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_COUNTRIES:
      return {
        ...state,
        countries: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_RECORD_TYPES:
      return {
        ...state,
        recordTypes: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_DEATH_TYPES:
      return {
        ...state,
        deathTypes: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_FUNERARY_ORIGINS:
      return {
        ...state,
        funeraryOrigins: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_GENDERS:
      return {
        ...state,
        genders: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_INSURANCES:
      return {
        ...state,
        insurances: (
          action.payload as MasterAndNormalizedType<InsuranceCompany>
        ).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_ABORTION_TYPES:
      return {
        ...state,
        abortionTypes: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_GROUP_CAUSES:
      return {
        ...state,
        groupCauses: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_OWNERS:
      return {
        ...state,
        owners: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_RELIQUARYS:
      return {
        ...state,
        reliquaries: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_URNS:
      return {
        ...state,
        urns: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_SANITARY_PRACTICES:
      return {
        ...state,
        sanitaryPractices: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_ASHES_DESTINATIONS:
      return {
        ...state,
        ashesDestinations: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_CONCESSION_TYPES:
      return {
        ...state,
        concessionTypes: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_NICHE_TYPES:
      return {
        ...state,
        nicheTypes: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_CREMATORIES:
      return {
        ...state,
        crematories: action.payload as IMasterDataDto[],
      };
    case masterDataTypes.ADD_MASTER_DATA_PROVINCES:
      return {
        ...state,
        provinces: (action.payload as MasterAndNormalizedType<ProvinceType>)
          .masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_CITIES:
      return {
        ...state,
        cities: (action.payload as MasterAndNormalizedType<CityType>)
          .masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_OVEN:
      return {
        ...state,
        ovens: action.payload as IMasterDataDto[],
      };
    case masterDataTypes.ADD_MASTER_DATA_VIGIL_ROOM:
      return {
        ...state,
        vigilRoom: action.payload as IMasterDataDto[],
      };
    case masterDataTypes.ADD_MASTER_DATA_COFFIN_SIZES:
      return {
        ...state,
        coffinSizes: action.payload as IMasterDataDto[],
      };
    case masterDataTypes.ADD_MASTER_DATA_MARITAL_STATUS:
      return {
        ...state,
        maritalStatus: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_THANATOLOGISTS:
      return {
        ...state,
        thanatologists: (action.payload as MasterAndNormalized).masterData,
      };
    case masterDataTypes.ADD_MASTER_DATA_THANATOPRACTORS:
      return {
        ...state,
        thanatopractors: (action.payload as MasterAndNormalized).masterData,
      };
    default:
      return state;
  }
};
