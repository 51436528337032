import React, {
  FC,
  Fragment,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';

import { formatName } from 'utils/helpers';

import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import Select from 'components/base/Select';
// eslint-disable-next-line import/no-cycle
import Table from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';
import Textarea from 'components/base/Textarea';

import { i18n } from 'i18n';
import Enquiry, { DashboardEnquiry } from 'models/Enquiry';
import { AssignUsersParams } from 'models/OrderAssign';
import { SimpleUser } from 'models/User';

import './index.scss';

type AssignUsersProps = {
  assignedUsers: SimpleUser[];
  onlyOne?: boolean;
  placeholder?: string;
  selectClassName?: string;
  comment?: string;
  setAssignedUsers: Dispatch<SetStateAction<SimpleUser[]>>;
  setComment: Dispatch<SetStateAction<string>>;
  setInfo?: (params: AssignUsersParams) => void;
  setUserOptions?: Dispatch<SetStateAction<SimpleUser[]>>;
  testId?: string;
  title?: string;
  userOptions: SimpleUser[];
  enquiry: DashboardEnquiry | Enquiry;
};

const getSimpleUserCols = (): TableColumn<SimpleUser>[] => [
  { accessor: 'id', Header: 'Id', hidden: true },
  {
    accessor: 'name',
    Cell: ({ row: { original } }) => formatName(original),
    Header: `${i18n.t('common.fullName')}`,
  },
];

const AssignUsers: FC<AssignUsersProps> = ({
  assignedUsers,
  setAssignedUsers,
  setComment,
  placeholder,
  selectClassName,
  testId,
  comment,
  title,
  userOptions,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const simpleUserCols = useMemo(() => getSimpleUserCols(), [t]);

  const onChangeUser = (option: SimpleUser) => {
    if (option) {
      setAssignedUsers([option]);
    }
  };

  const removeUser = () => {
    setAssignedUsers([]);
  };

  return (
    <Fragment>
      <Select
        aria-label="enquiry assign"
        className={selectClassName || 'mb-3'}
        data-testid={testId || 'assign-select'}
        disabled={
          (assignedUsers.length === 1)
        }
        getLabel={(option: SimpleUser) => `${option.firstSurname} ${option.secondSurname || ''}, ${option.name}`}
        getValue={(option: SimpleUser) => option.id}
        name="assign-user-selector"
        options={userOptions}
        placeholder={placeholder || t('user.assignUser')}
        value={undefined}
        searchable
        onChange={onChangeUser}
      />
      <div className="assigned-users">
        <span>{title || t('user.assignedUsers')}</span>
        <Table<SimpleUser>
          actions={() => ({
            remove: {
              icon: <CustomIcon icon={FeatherIconTypes.TRASH} />,
              onClick: () => removeUser(),
              tooltipCaption: t('common.remove'),
            },
          })}
          columns={simpleUserCols}
          data={assignedUsers}
          pagination={false}
        />
      </div>
      <Textarea
        aria-label="enquiry comment"
        maxLength={100}
        name="enquiry comment"
        placeholder={t('common.comment')}
        value={comment}
        onChange={(val: string) => setComment(val)}
      />

    </Fragment>
  );
};

export default AssignUsers;
