import OrderInfo from 'models/OrderInfo';
import OrderPriority from 'models/OrderPriority';
import { StatusCodes } from 'models/OrderStatus';
import OrderType from 'models/OrderType';
import { SimpleUser } from 'models/User';
import Workorder, { InfoQr } from 'models/Workorder';

export class WorkorderEntity implements Workorder {
  constructor(params: Workorder) {
    this.id = params.id;
    this.createdBy = params.createdBy;
    this.createdDate = params.createdDate;
    this.updatedBy = params.updatedBy;
    this.updatedDate = params.updatedDate;
    this.assignedUsers = params.assignedUsers;
    this.deceasedFirstSurname = params.deceasedFirstSurname;
    this.deceasedName = params.deceasedName;
    this.deceasedSecondSurname = params.deceasedSecondSurname;
    this.dueDate = params.dueDate;
    this.erpId = params.erpId;
    this.estimatedTime = params.estimatedTime;
    this.finishDate = params.finishDate;
    this.info = params.info;
    this.infoQr = params.infoQr;
    this.canProvideQr = params.canProvideQr;
    this.priority = params.priority;
    this.recordId = params.recordId;
    this.startDate = params.startDate;
    this.status = params.status;
    this.type = params.type;
    this.destinationCenter = params.destinationCenter;
  }

  id: string | number;

  createdBy: string;

  createdDate: string;

  updatedBy?: string | undefined;

  updatedDate?: string | undefined;

  assignedUsers: SimpleUser[];

  deceasedFirstSurname: string;

  deceasedName: string;

  deceasedSecondSurname: string;

  dueDate?: string | undefined;

  erpId?: string | number | undefined;

  estimatedTime?: number | undefined;

  finishDate?: string | undefined;

  info: OrderInfo;

  infoQr?: InfoQr | undefined;

  canProvideQr?: boolean | undefined;

  priority: OrderPriority;

  recordId?: string | number | undefined;

  startDate: string;

  status: StatusCodes;

  type: OrderType;

  destinationCenter?: string | undefined;
}
