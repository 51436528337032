import { IBudgetLocation } from 'modules/budget/models/BudgetLocation/types';

export const encodeLocation = (country = '', province = '', city = '', location = ''): string => `${country}-${province}-${city}-${location}`;
export const decodeLocation = (code: string): IBudgetLocation => {
  const [country, province, city, location] = code.split('-');

  return {
    city,
    country,
    location,
    province,
  };
};
