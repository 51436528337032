import { useCallback, useEffect, useState } from 'react';

import { normalizeMasterData } from 'utils/normalizer';

import { getTribunals } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { NormalizedType } from 'models/MasterService';

type TribunalType = {
  tribunals: IMasterDataDto[];
  getTribunalByCode: (code: string | undefined) => IMasterDataDto | undefined
};

const useTribunal = (cityCode?: string): TribunalType => {
  const [cityLoaded, setCityLoaded] = useState<string>();
  const [normalizedTribunals, setNormalizedTribunals] = useState<NormalizedType>({});
  const [tribunals, setTribunals] = useState<IMasterDataDto[]>([]);

  const getTribunal = async () => {
    getTribunals(cityCode)
      .then((response) => {
        setTribunals(response.data as IMasterDataDto[]);
        return response.data;
      })
      .catch(() => {
        setTribunals([]);
      });
  };

  useEffect(() => {
    if (cityCode !== cityLoaded) {
      setCityLoaded(cityCode);
      getTribunal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode]);

  useEffect(() => {
    setNormalizedTribunals(normalizeMasterData(tribunals));
  }, [tribunals]);

  const getTribunalByCode = useCallback(
    (code: string | undefined) => (code && normalizedTribunals[code]
      ? normalizedTribunals[code]
      : undefined),
    [normalizedTribunals],
  );

  return {
    getTribunalByCode,
    tribunals,
  };
};

export default useTribunal;
