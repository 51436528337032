import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayNoValue } from 'components/base/NoData';

import { useVigilLocation } from 'hooks/useVigilLocation';
import { MovementBaseWorkOrder } from 'models/Workorder';

import {
  CardDetails, WorkshopCardHeaderDetail, displayDate, displayHour,
} from './CardDetails';

type MovementDetailsProps = {
  order: MovementBaseWorkOrder
}

export const MovementDetails: FC<MovementDetailsProps> = ({ order }) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<WorkshopCardHeaderDetail[]>([]);

  const { getVigilLocation } = useVigilLocation(order.city);
  const [
    vigilLocationDescription,
    setVigilLocationDescription,
  ] = useState<string | undefined>(displayNoValue);

  useEffect(
    () => {
      setVigilLocationDescription(getVigilLocation(order.location)?.description);
    },
    [getVigilLocation, order.location],
  );

  useEffect(
    () => {
      setDetails([
        {
          label: t('order.details.vigilLocation'),
          value: vigilLocationDescription
            || displayNoValue,
        },
        {
          label: t('common.date'),
          value: order.date
            ? displayDate(order.date)
            : displayNoValue,
        },
        {
          label: t('common.hour'),
          value: order.time
            ? displayHour(order.time)
            : displayNoValue,
        }]);
    },
    [order.city, order.date, order.location, order.time, t, vigilLocationDescription],
  );

  return (<CardDetails details={details} />);
};
