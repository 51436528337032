import { Nullable } from 'utils/types/Nullable/types';

import { MimeTypes } from './MimeTypes';
import { IBase64Data } from './types';

export class Base64Data implements IBase64Data {
  static BASE64_GLU = ';base64,';

  constructor(data: IBase64Data) {
    this.mimetype = data.mimetype;
    this.data = data.data;
  }

  toDataUrl(): string {
    return `data:${this.mimetype}${Base64Data.BASE64_GLU}${this.data}`;
  }

  toBlob(): string {
    const byteCharacters = atob(this.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: this.mimetype });
    return URL.createObjectURL(blob);
  }

  public static getFromDataUrl(d: string, info: 'mime' | 'data'): Nullable<string> {
    try {
      const c = d.split(Base64Data.BASE64_GLU);
      if (c.length < 2) return null;
      if (info === 'mime') return c[0];
      if (info === 'data') return c[1];
    } catch {
      return null;
    }
    return null;
  }

  mimetype: MimeTypes;

  data: string
  ;
}
