import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import OrderInfo from 'models/OrderInfo';
import Workorder from 'models/Workorder';

import Info, { DetailType } from './Info';

export type DefaultInfoProps = {
  expanded?: boolean;
  loading?: boolean;
  order: Workorder;
};

const DefaultInfo: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (order) {
      const newDetails = Object.keys(order.info || {}).map((text) => ({
        text: t(`order.details.${text}`),
        value: order.info[text as keyof OrderInfo],
      }));
      setDetails(newDetails);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Info
      assignedUsers={[...(order.assignedUsers || [])]}
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order?.priority}
    />
  );
};

export default DefaultInfo;
