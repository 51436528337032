import { MasterData } from 'models/MasterDataDto';

import { InvoiceArticle as IInvoiceArticle, InvoiceArticleType } from './types';

export class InvoiceArticle implements IInvoiceArticle {
  constructor(params: IInvoiceArticle) {
    this.id = params.id;
    this.code = params.code;
    this.type = params.type;
    this.description = params.description;
    this.provider = params.provider;
    this.client = params.client;
    this.providers = params.providers
      .map((provider) => new MasterData(provider));
  }

  id: number;

  code: string;

  type: InvoiceArticleType;

  description: string;

  provider: string;

  client: string;

  providers: MasterData[];
}
