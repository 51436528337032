import { useEffect, useState } from 'react';

import { Nullable } from 'utils/types/Nullable/types';

import { useCities } from 'hooks/useCities';
import { useCountries } from 'hooks/useCountries';
import { useProvinces } from 'hooks/useProvinces';
import { MasterDataDtoCode } from 'models/dataTypes';
import { CityType, ProvinceType } from 'models/MasterData';

import { IABirthAddressInformationHook } from './types';

export const useIABirthAddressInformation = (): IABirthAddressInformationHook => {
  const { getProvinceByName } = useProvinces();
  const { getCitiesByName } = useCities();
  const { getCountryByName } = useCountries();

  const [countryCode, setCountryCode] = useState<Nullable<MasterDataDtoCode>>(null);

  const [provinceCode, setProvinceCode] = useState<Nullable<MasterDataDtoCode>>(null);
  const [secondField, setBirthAddressSecondField] = useState<Nullable<string>>(null);

  const [cityCode, setCityCode] = useState<Nullable<MasterDataDtoCode>>(null);
  const [firstField, setBirthAddressFirstField] = useState<Nullable<string>>(null);

  useEffect(
    () => {
      if (secondField) {
        const country = getCountryByName(secondField);
        if (country && !Array.isArray(country)) {
          setCountryCode(country?.code);

          if (firstField) {
            const province = getProvinceByName(firstField);
            const isMultipleProvince = province && Array.isArray(province);
            if (province && !Array.isArray(province)) {
              setProvinceCode(province.code);
            } else if (isMultipleProvince) {
              const provincesByCountry = province?.filter(
                (p: ProvinceType) => p.countryCode === country.code,
              );
              if (provincesByCountry?.length === 1) {
                setProvinceCode(provincesByCountry[0].code);
              }
            }
          }
        } else if (!country) {
          setCountryCode('011');

          const province = getProvinceByName(secondField);
          const isMultipleProvince = province && Array.isArray(province);
          if (province && !Array.isArray(province)) {
            setProvinceCode(province.code);

            if (firstField) {
              const city = getCitiesByName(firstField);
              const isMultipeCity = city && Array.isArray(city);
              if (city && !Array.isArray(city)) {
                setCityCode(city.code);
                setProvinceCode(city.provinceCode);
                setCountryCode(city.countryCode);
              } else if (isMultipeCity) {
                const citiesByProvince = city?.filter(
                  (c: CityType) => c.provinceCode === province.code,
                );
                if (citiesByProvince?.length === 1) {
                  setCityCode(citiesByProvince[0].code);
                  setProvinceCode(citiesByProvince[0].provinceCode);
                  setCountryCode(citiesByProvince[0].countryCode);
                }
              }
            }
          } else if (isMultipleProvince) {
            if (firstField) {
              const city = getCitiesByName(firstField);
              if (city && !Array.isArray(city)) {
                setCityCode(city.code);
                setProvinceCode(city.provinceCode);
                setCountryCode(city.countryCode);
              }
            }
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstField, countryCode, provinceCode, secondField],
  );

  return {
    birthAddressCityCode: cityCode,
    birthAddressCountryCode: countryCode,
    birthAddressProvinceCode: provinceCode,
    setBirthAddressFirstField,
    setBirthAddressSecondField,
  };
};
