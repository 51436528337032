import React, { FC, useEffect } from 'react';

import Modal from 'components/base/Modal';
import { Row } from 'components/base/Row';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { BudgetWizard } from '../budgetWizard';
import './index.scss';

interface BudgetModalComponent {
  onHide: () => void;
  show: boolean;
}

export const BudgetModal: FC<BudgetModalComponent> = ({ onHide, show }) => {
  const {
    Reset,
  } = useBudget();

  useEffect(
    () => {
      Reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <Modal
      className="budget-modal"
      closeOnFocusLost={false}
      headerContent={(<Row justifyContent="space-around"><React.Fragment /></Row>)}
      show={show}
      showFooter={false}
      title=""
      onHide={onHide}
    >
      <BudgetWizard />
    </Modal>
  );
};
