const record = {
  abortion: {
    declarantIsMother: 'El declarante es la madre del feto',
    father: 'del padre',
    gestationWeeks: 'Semanas de gestación',
  },
  addAppointmentKo: 'Se ha producido un error al crear la cita',
  adviserAssigned: '¡La lista de asesores ha sido actualizada con éxito!',
  advisers: 'Asesores',
  ageConfidential: 'Confidencialidad de edad',
  amputation: {
    member: 'Miembro amputado',
  },
  appointment: 'Compromiso familia',
  ashesDelivery: 'Entrega cenizas',
  ashesDeliveryKo: 'Se ha producido un error al entregar las cenizas',
  ashesDeliveryOk: 'Las cenizas se han enctregado correctamente',
  assignAdviser: 'Asignar asesor',
  assignAdviserError: 'Error al intentar actualizar la lista de asesores assignados',
  assignedRecords: 'Expedientes asignados',
  billingData: {
    sendByEmail: 'Envío de factura por email',
  },
  client: 'Cliente',
  clients: {
    paymentTerm: 'Plazos de pago',
    primaryLanguage: 'Idioma principal',
    secondaryLanguage: 'Idioma secundario',
  },
  close: 'Finalizar expediente',
  closedKo: 'Se ha producido un error al finalizar el expediente. Por favor, vuelva a intentarlo.',
  closedOk: 'El expediente se ha finalizado correctamente.',
  closeText: 'Va a finalizar el expediente',
  coffinSizes: {
    SIZE_060: '60',
    SIZE_080: '80',
    SIZE_100: '100',
    SIZE_140: '140',
    SIZE_190: '190',
    SIZE_205: '205',
    SIZE_205_SUPER: '205 SUPER',
    SIZE_205_SUPER_EXTRA: '205 SUPER EXTRA',
  },
  collectionComment: 'Comentario recogida',
  confirmDelete: '¿Seguro que quiere eliminar el expediente?',
  createdBy: 'Creado por',
  createPickup: 'Crear orden de recogida',
  createReception: 'Crear orden de recepción',
  createWorkOrder: 'Crear orden de trabajo',
  creationDate: 'Fecha de creación',
  deathData: {
    approximatedDate: 'Fecha aproximada',
    atHome: 'En casa',
    deathCause: 'Causa de la defunción',
    deathDate: 'Fecha de la defunción',
    deathPlace: 'Lugar de defunción',
    deathTime: 'Hora de la defunción',
    deathType: 'Tipo de defunción',
    destinationCenter: 'Centro destino',
    diseases: 'Grupo causa',
    doctor: 'Doctor',
    errands: 'Diligencias',
    home: 'Domicilio',
    medicalCertificate: 'Se dispone del certificado médico de defunción',
    pickup: 'Recogida',
    pickupAddress: 'Lugar de recogida',
    pickupType: 'Tipo de recogida',
    remarks: 'Observaciones de la defunción',
    trialCourt: 'Juzgado de instrucción',
    type: {
      judicial: 'Judicial',
      normal: 'Natural',
    },
  },
  deceased: 'Titular del expediente',
  deceasedData: {
    birth: 'Nacimiento',
    childrenNames: 'Nombre hijos',
    fatherName: 'Nombre padre',
    holder: 'Titular del expediente',
    home: 'Residencia',
    maritalStatus: 'Estado civil',
    motherName: 'Nombre madre',
    nationality: 'Nacionalidad',
    nickname: 'Apodo',
    partnerName: 'Nombre pareja',
  },
  declarant: 'Declarante',
  declarantData: 'El solicitante es el declarante',
  delete: 'Eliminar expediente',
  deliveryObjects: 'Entrega de objetos personales',
  detail: 'Detalle de expediente',
  document: {
    removeKo: 'Se ha producido un error al eliminar el archivo.',
    removeOk: 'El archivo se ha eliminado correctamente',
    sure: 'Va a eliminar el fichero',
    uploadKo: 'Se ha producido un error al subir el archivo.',
    uploadOk: 'El archivo se ha subido correctamente',
  },
  draft: 'Borrador',
  edit: 'Editar expediente',
  editKo: 'Se ha producido un error al actualizar el expediente',
  editOk: 'El expediente se ha actualizado satisfactoriamente',
  erpId: 'Expediente',
  firstNegotiator: 'Asesor',
  info: 'Datos del expediente',
  informationFilterType: {
    APPOINTMENT: 'Compromiso familia',
    CEREMONY: 'Ceremonia',
    COLLECTION: 'Fecha recogida',
    CREMATION: 'Incineración',
    INTERMENT: 'Inhumación',
    VIGIL: 'Velatorio',
  },
  insuranceData: {
    agent: 'Agente',
    amount: 'Coberturas',
    companyDelegation: 'Delegación de la compañía',
    companyName: 'Nombre de la empresa',
    hasPolicy: 'Dispone de póliza',
    idPaymentLocation: 'Localización cobro',
    maxCoverage: 'Importe máximo de cobertura',
    policyNumber: 'Número de la póliza',
    renounce: 'Renuncia de capital de póliza',
    sinister: 'Número de siniestro',
  },
  last: 'Últimos expedientes',
  manage: 'Administrar',
  myRecords: 'Mis expedientes',
  new: 'Nuevo expediente',
  noAppointment: 'Sin cita',
  notAssignedRecords: 'Expedientes sin asignar',
  number: 'Nº expediente',
  objectsDelivered: '¡La entrega de objetos personales ha sido guardada con éxito!',
  objectsDeliveryError: 'Error al intentar guardar la entrega de objetos personales',
  pickupDate: 'Fecha recogida',
  preneed: 'Prenecesidad',
  preparationStatus: 'Estado preparación',
  preparationStatusOptions: {
    FINISHED: 'Instalado',
    ON_GOING: 'Acondicionante',
  },
  processing: 'Tramitación',
  publishCeremony: 'Ceremonia visible en la web',
  publishVigil: 'Velatorio visible en la web',
  reception: {
    entryDate: 'Fecha llegada',
    entryTime: 'Hora llegada',
    funeraryOrigin: 'Funeraria de procedencia',
    isReception: 'Se trata de una recepción',
    receptionType: 'Tipo de recepción',
  },
  receptionType: {
    withCoffin: 'Con ataud',
    withoutCoffin: 'Sin ataud',
  },
  receptionTypeInfo: {
    withCoffin: 'Encajado',
    withoutCoffin: 'Protocolo camilla',
  },
  recordDeleteKo: 'Error al eliminar el expdiente',
  recordDeleteKoConflict: 'Expediente no cumple validación',
  recordDeleteKoEkon: 'Fallo invocación EKON',
  requestError: 'Error al solicitar los datos de la tabla',
  restRecords: 'Resto de expedientes asignados',
  saveKo: 'Se ha producido un error al crear el expediente',
  saveOk: 'El expediente se ha creado satisfactoriamente',
  search: 'Buscar expedientes',
  secondNegotiator: 'Tramitador ceremonial',
  selectHeadlineAdviser: 'Asignar tramitador titular',
  selectSecondaryAdviser: 'Asignar tramitador secundario',
  sendByEmail: 'Envío de factura por email',
  state: 'Estado',
  states: {
    FINISHED: 'Finalizado',
    INVOICED: 'Facturado',
    INVOICED_PENDING: 'Pendiente facturación',
    OPENED: 'Abierto',
  },
  steps: {
    abortion: 'Aborto',
    amputation: 'Amputación',
    billing: 'Facturación',
    death: 'Defunción',
    deceased: 'Titular del expediente',
    declarant: 'Declarante',
    documents: 'Documentos',
    general: 'Datos generales',
    insurance: 'Seguro',
    other: 'Otros',
    pickup: 'Recogida',
    reception: 'Recepción',
  },
  title: 'Expedientes',
  type: 'Tipo',
  validate: 'Validar',
  whatsapp: {
    language: 'Idioma del mensaje',
    lastUpdate: 'Último envío realizado por {{user}} el {{date}} al número {{number}}',
    number: 'Numero telefono',
    numberUpdated: 'Número envío de Whatsapp actualizado',
    sendSuccess: 'Envío del mensaje procesado',
    title: 'Envío WhatsApp',
    whatsappConfirmation: 'S\'enviarà un missatge amb les dades de la defunció al telèfon {{number}}',
  },
  withAppointment: 'Con cita',
};
export default record;
