import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';

import './index.scss';

export type BoxProps = {
  className?: string | undefined;
  headerContent?: ReactElement | ReactNode | undefined;
  icon?: ReactElement | undefined;
  title?: string | undefined;
};

const Box: FC<BoxProps> = ({
  className,
  children,
  headerContent,
  icon,
  title,
}) => (
  <div className={classNames('funus-box', className)}>
    {(icon || title || headerContent) && (
      <div className="box-header">
        {(icon || title) && (
          <div className="header-main">
            {icon}
            <span>{title}</span>
          </div>
        )}
        {headerContent}
      </div>
    )}
    {children}
  </div>
);

export default Box;
