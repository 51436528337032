import React, { FC, useMemo } from 'react';

import { priceFormat } from '../../budgetSummary/tools';

import { BudgetArticleDisplayPriceComponent } from './types';
import './index.scss';

export const BudgetArticleDisplayPrice: FC<BudgetArticleDisplayPriceComponent> = ({ article }) => {
  const displayPrcie = useMemo(
    () => {
      if (article.prices && article.prices.length !== 0) {
        const price = article.prices[0];
        return price.price + (price.price * price.vat);
      }

      return 0;
    },
    [article.prices],
  );

  return (
    <span className="f-budget-article-display-price">{priceFormat(displayPrcie)}</span>);
};
