import { AxiosError } from "axios";
import { FunusCustomError } from "./FunusCustomError";

export class GenericError extends FunusCustomError {
  constructor(error: AxiosError) {
    super(error);
    if (typeof error.response?.data === 'string') {
      this.message = error.response?.data;
    }
    this.name = "GenericError";
  }
}
