import axios from 'config/axios';
import { ApiFunusLogin } from './types';

export const login: ApiFunusLogin = {
  autologin: () => axios.get(`/autologin`),
  changePassword: (params, id) => axios.put(`/password/${id}`, params),
  forgot: (username) => axios.get(`/password/forgot?username=${username}`),
  login: (params) => axios.post('/login', params),
  restorePassword: (params) => axios.put('/password/restore', params),
  verifyToken: (token) => axios.get(`/login/forgot/${token}`),
};
