import classNames from 'classnames';
import React, {
  ChangeEvent,
  cloneElement,
  createRef,
  FC,
  FocusEvent,
  ForwardedRef,
  InputHTMLAttributes,
  ReactElement,
  useState,
} from 'react';

import Button from 'components/base/Button';

import { Pick } from 'typings/helper';

import './index.scss';

export type InputProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  Exclude<keyof InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'>
> & {
  clearable?: boolean | undefined;
  disabled?: boolean | undefined;
  error?: string | boolean | undefined;
  id?: string | undefined;
  innerRef?: ForwardedRef<HTMLDivElement> | undefined;
  inputRef?: ForwardedRef<HTMLInputElement> | undefined;
  leftAddon?: ReactElement | undefined;
  name: string;
  onBlur?(e?: FocusEvent | ChangeEvent): void;
  onChange?(value: string | number | boolean | File | null | undefined): void;
  placeholder?: string | undefined;
  rightAddon?: ReactElement | undefined;
};

const Input: FC<InputProps> = ({
  accept,
  className,
  clearable,
  disabled,
  error,
  id,
  innerRef,
  inputRef,
  leftAddon,
  name,
  onBlur,
  onChange,
  onClick,
  readOnly,
  rightAddon,
  placeholder,
  type,
  value,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const fileInputRef = createRef<HTMLInputElement>();
  const isFile = type === 'file';
  const isRadio = type === 'radio';
  const isCheckbox = type === 'checkbox';

  const clickClear = () => {
    if (onChange) {
      onChange('');
    }
  };

  const changeFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file: File | null = e?.target?.files?.length
      ? (e.target.files[0] as File)
      : null;

    if (onChange) {
      onChange(file);
    }
  };

  const clickInput = () => {
    if (!disabled && fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      ref={innerRef}
      className={classNames(
        'funus-input',
        {
          checkbox: isCheckbox,
          disabled,
          error: !!error,
          filled: !!value,
          focused,
          hovered,
          prefixed: !!leftAddon,
          radio: isRadio,
        },
        className,
      )}
    >
      {placeholder && <label htmlFor={name}>{placeholder}</label>}
      <div>
        <div>
          {leftAddon
            && cloneElement(leftAddon, {
              className: classNames(
                'input-prefix',
                { disabled },
                leftAddon.props.className,
              ),
            })}
          {isFile && (
            <input
              ref={fileInputRef}
              accept={accept}
              className="hidden-input"
              disabled={disabled}
              id="file-upload"
              name={name}
              type="file"
              onChange={changeFile}
            />
          )}
          <input
            {...rest}
            ref={inputRef}
            autoComplete="off"
            disabled={disabled}
            id={id}
            name={name}
            readOnly={readOnly || isFile}
            type={isFile ? 'text' : type}
            value={value}
            onBlur={(e) => {
              if (!disabled) {
                setFocused(false);

                if (onBlur) {
                  onBlur(e);
                }
              }
            }}
            onChange={(e) => {
              if (onChange && !isFile) {
                onChange(e.target[isCheckbox ? 'checked' : 'value']);
              }
              if ((isCheckbox || isRadio) && onBlur) {
                onBlur(e);
              }
            }}
            onClick={isFile ? clickInput : onClick}
            onFocus={(e) => {
              setFocused(true);
              if (rest.onFocus) {
                rest.onFocus(e);
              }
              if (e.target.autocomplete) {
                e.target.autocomplete = 'off';
              }
            }}
            onMouseEnter={(e) => {
              setHovered(true);
              if (rest.onMouseEnter) {
                rest.onMouseEnter(e);
              }
            }}
            onMouseLeave={(e) => {
              setHovered(false);
              if (rest.onMouseLeave) {
                rest.onMouseLeave(e);
              }
            }}
          />
          {(isCheckbox || isRadio) && <span className="checkmark" />}
          {clearable && !isCheckbox && !isRadio && !!value && (
            <Button
              className="clear-button"
              color="transparent"
              tabIndex={-1}
              onClick={clickClear}
            />
          )}
          {rightAddon
            && cloneElement(rightAddon, {
              className: classNames(
                'input-suffix',
                { disabled },
                rightAddon.props.className,
              ),
            })}
        </div>
        <div className="bottom-line" />
      </div>
      <span className={classNames({ hidden: !error })}>{error}</span>
    </div>
  );
};

export default Input;
