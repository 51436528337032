import classNames from 'classnames';
import React, { FC } from 'react';

import './index.scss';
import { Classable } from 'modules/budget/components/types/helpers';

interface GalleryImagePictureComponent extends Classable {
  url: string;
  onClick?: () => void
}

export const GalleryImagePicture: FC<GalleryImagePictureComponent> = ({
  url, onClick, className,
}) => (
  <button
    className={classNames('gallery-image-picture', className)}
    type="button"
    onClick={(e) => {
      e.stopPropagation();
      onClick?.();
    }}
  >
    <img
      alt="GalleryImage"
      className="gallery-image-picture-image"
      src={url}
    />
  </button>
);
