import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useProvinces } from 'hooks/useProvinces';
import { ProvinceType } from 'models/MasterData';

import Select from '../Select';

import { ProvincesComponent } from './types';

export const Provinces: FC<ProvincesComponent> = ({
  disabled = false, value, onChange, countryCode,
}) => {
  const { t } = useTranslation();
  const { provinces, getProvinceByNormalizedCode } = useProvinces();

  const province = getProvinceByNormalizedCode(value, countryCode);

  return (
    <Select<ProvinceType>
      disabled={disabled}
      getLabel={(element) => element.description}
      getValue={(element) => element.code}
      limit={15}
      name="provinces"
      options={provinces}
      placeholder={t('common.province')}
      value={province || undefined}
      searchable
      onChange={(element) => {
        onChange({
          code: element?.code || '',
          countryCode: element?.countryCode || '',
        });
      }}
    />
  );
};
