import React, {
  Component, ErrorInfo, ReactElement, ReactNode,
} from 'react';

import GeneralErrorFallback from 'components/error/GeneralErrorFallback';

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

type ErrorBoundaryProps = {
  children: ReactNode;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch = (error: Error, errorInfo: ErrorInfo): void => {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  };

  render = (): ReactElement | ReactNode => {
    const { errorInfo, error } = this.state;
    const { children } = this.props;
    if (errorInfo && error) {
      // Error path
      return (
        <div>
          <GeneralErrorFallback
            componentStack={errorInfo.componentStack}
            error={error}
          />
        </div>
      );
    }
    // Normally, just render children
    return children;
  };
}

export default ErrorBoundary;
