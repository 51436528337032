import classNames from 'classnames';
import React, { FC, useEffect } from 'react';

import Select from 'components/base/Select';

import { IMasterDataDto } from 'models/MasterData';
import { MasterDataQuestionType } from 'modules/budget/api/types';
import { useBudgetLocations } from 'modules/budget/hooks/useBudgetLocations';
import { ILocation } from 'modules/budget/models/Location/types';

import { Classable } from '../../types/helpers';

interface BudgetLocationSelectorComponent extends Classable {
  city: string;
  country: string;
  province: string;
  disabled: boolean;
  questionType: MasterDataQuestionType;
  caption: string;
  value: string;
  onChange: (value: ILocation | null) => void
}

export const BudgetLocationSelector: FC<BudgetLocationSelectorComponent> = (
  {
    caption,
    city,
    className,
    country,
    disabled,
    onChange,
    province,
    questionType,
    value,
  },
) => {
  const { fetchLocations, locations, getLocation } = useBudgetLocations();

  useEffect(
    () => {
      if (country && province && city) {
        fetchLocations(questionType, country, province, city);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [city, country, province, questionType],
  );

  return (
    <Select<IMasterDataDto>
      key={`${questionType}Place`}
      className={classNames('f-budget-location-selector', className)}
      disabled={disabled}
      getLabel={({ description }) => description}
      getValue={({ code }) => code}
      name={`${questionType}Place`}
      options={locations}
      placeholder={caption}
      value={value ? getLocation(value) : undefined}
      searchable
      onChange={(val) => {
        if (val?.code) {
          onChange({
            id: val?.code,
            work: Boolean(getLocation(val?.code)?.work),
          });
        } else {
          onChange(null);
        }
      }}
    />
  );
};
