import { AxiosError } from "axios";

export class FunusCustomError extends Error {
  status: number | undefined;
  constructor(error: AxiosError) {
    super(error.message);
    try {
      this.status = (error.toJSON() as any).status;
    } catch { }
  }
}
