import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { NavigateComponent } from './types';
import './index.scss';

export const Navigate: FC<NavigateComponent> = ({ to, children }) => {
  const history = useHistory();
  const onNavigate = useCallback(
    () => {
      history.push({
        pathname: to,
      });
    },
    [history, to],
  );

  return (
    <div className="f-navigate" role="link" tabIndex={0} onClick={onNavigate} onKeyDown={() => false}>
      {children}
    </div>
  );
};
