const ia = {
  back: 'Part posterior',
  birthAddress: 'Direcció de naixement',
  both: 'Part davantera i posterior',
  firstField: 'Ciutat/Província',
  front: 'Part davantera',
  parents: 'Pares',
  reviewData: 'Reviseu les dades i confirmeu',
  scanDocument: 'Escanejar document',
  scanningDocument: 'Escanejant document',
  secondField: 'Província/País',
  streetNumber: 'Número',
  takePhoto: 'Fer fotografia amb webcam',
  uploadFileOne: 'Pujar un sol fitxer',
  uploadFileQuestion: 'Quants fitxers voleu pujar?',
  uploadFiles: 'Pujar fitxers',
  uploadFileTwo: 'Pujar dos fitxers',
};
export default ia;
