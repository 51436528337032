import classNames from 'classnames';
import React, { FC } from 'react';

import { ScanQualityMarkerComponent } from './types';

import './index.scss';

export const ScanQualityMarker: FC<ScanQualityMarkerComponent> = (
  { value },
) => (
  <div
    className={classNames('scan-quality-marker', { 'scan-quality-marker-error': value < 33, 'scan-quality-marker-success': value >= 66, 'scan-quality-marker-warn': value >= 33 && value < 66 })}
    style={{
      width: `${value}%`,
    }}
  />
);
