import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { bankAssigned } from 'utils/bankManager';

import Input from 'components/base/Input';

import BankAccountModel from 'models/BankAccount';

import './index.scss';

export type BankAccountProps = {
  className?: string;
  disabled?: boolean;
  error?: BankAccountModel;
  name: string;
  onChange?: (param: string, value: string) => void;
  value?: BankAccountModel;
};

const BankAccount: FC<BankAccountProps> = ({
  className,
  disabled,
  error,
  name,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const changeParam = (param: string, val: string): void => {
    if (onChange) {
      onChange(param, val);
    }
  };

  return (
    <div className={classNames('funus-bank-account', className)}>
      <div className="bank-inputs">
        <Input
          disabled={disabled}
          error={error?.country}
          maxLength={2}
          name={`${name}-country`}
          placeholder={t('common.bank.account')}
          value={value?.country || ''}
          onChange={(val: string) => changeParam('country', val)}
        />
        <Input
          disabled={disabled || !value?.country}
          error={error?.controlIban}
          maxLength={2}
          name={`${name}-controlIban`}
          value={value?.controlIban || ''}
          onChange={(val: string) => changeParam('controlIban', val)}
        />
        <Input
          className="bank-2"
          disabled={disabled || !value?.country || !value?.controlIban}
          error={error?.entity}
          maxLength={4}
          name={`${name}-entity`}
          value={value?.entity || ''}
          onChange={(val: string) => {
            changeParam('entity', val);
            if (value?.country?.toLowerCase() === 'es') {
              const bank = bankAssigned[val];
              changeParam('description', bank?.description || '');
              changeParam('iso', bank?.iso || '');
              changeParam('bic', bank?.bic || '');
            }
          }}
        />
        <Input
          className="bank-2"
          disabled={
            disabled || !value?.country || !value?.controlIban || !value?.entity
          }
          error={error?.office}
          maxLength={4}
          name={`${name}-office`}
          value={value?.office || ''}
          onChange={(val: string) => changeParam('office', val)}
        />
        <Input
          disabled={
            disabled
            || !value?.country
            || !value?.controlIban
            || !value?.entity
            || !value?.office
          }
          error={error?.control}
          maxLength={2}
          name={`${name}-control`}
          value={value?.control || ''}
          onChange={(val: string) => changeParam('control', val)}
        />
        <Input
          className="bank-3"
          disabled={
            disabled
            || !value?.country
            || !value?.controlIban
            || !value?.entity
            || !value?.office
            || !value?.control
          }
          error={error?.account}
          maxLength={10}
          name={`${name}-account`}
          value={value?.account || ''}
          onChange={(val: string) => changeParam('account', val)}
        />
      </div>
      {value?.bic && (
        <div className="bank-info">
          <span>{value.bic}</span>
          <span>{value.description}</span>
          <span>{value.iso}</span>
        </div>
      )}
    </div>
  );
};

export default BankAccount;
