import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatHour } from 'utils/dateManager';

import { SanitaryPractice as SanitaryPracticeType } from 'models/MasterService';
import { SanitaryPracticeInfo } from 'models/OrderInfo';
import { RootState } from 'store';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const SanitaryPractice: FC<DefaultInfoProps> = ({
  expanded,
  loading,
  order,
}) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();

  const { normalized } = useSelector((state: RootState) => state);

  useEffect(() => {
    const info: SanitaryPracticeInfo = order.info as SanitaryPracticeInfo;

    const newDetails: DetailType[] = [
      {
        text: t('order.details.creationHour'),
        value: formatHour(order.createdDate),
      },
      {
        text: t('record.deathData.diseases'),
        value: info?.death?.disease
          ? normalized.groupCauses[info.death.disease]?.description
          : undefined,
      },
      {
        text: t('record.deathData.deathCause'),
        value: info?.death?.cause,
      },
    ];
    if (info?.sanitaryPractices && info?.sanitaryPractices?.length > 0) {
      info?.sanitaryPractices.forEach(
        (sanitaryPractice: SanitaryPracticeType) => {
          newDetails.push(
            {
              text: t('common.type'),
              value:
                sanitaryPractice?.type
                && normalized.sanitaryPractices[sanitaryPractice.type]
                  ?.description,
            },
            {
              text: t('service.preparation.thanatologist'),
              value:
                sanitaryPractice?.thanatologist
                && normalized.thanatologists[sanitaryPractice.thanatologist]
                  ?.description,
            },
            {
              text: t('service.preparation.thanatopractor'),
              value:
                sanitaryPractice?.thanatopractor
                && normalized.thanatopractors[sanitaryPractice.thanatopractor]
                  ?.description,
            },
          );
        },
      );
    }
    newDetails.push(
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
    );
    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default SanitaryPractice;
