import React, {
  FC, Fragment, ReactElement, ReactNode,
} from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/base/Button';

export type SidebarButtonProps = {
  className?: string | undefined;
  exact?: boolean | undefined;
  icon?: ReactElement | undefined;
  content?: string | ReactElement | ReactNode | undefined;
  onClick?(): void | undefined;
  url?: string | undefined;
};

const SidebarButton: FC<SidebarButtonProps> = ({
  content,
  className,
  exact,
  icon,
  url,
  onClick,
}) => (
  <Fragment>
    {url ? (
      <NavLink className={className} exact={exact} to={url} onClick={onClick}>
        <div>
          {icon}
          {content}
        </div>
      </NavLink>
    ) : (
      <Button className={className} color="transparent" onClick={onClick}>
        <div>
          {icon}
          {content}
        </div>
      </Button>
    )}
  </Fragment>
);

export default SidebarButton;
