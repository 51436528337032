import { Middleware } from 'redux';

import { showErrorToast } from 'utils/toasts';

import { i18n } from 'i18n';

import { budgetController } from '../../api';
import { UpdateArticleProvider } from '../budgetActions';
import { SetArticleProviderPayload, BudgetActionTypes } from '../budgetActions/types';

export const PersistArticleProvider: Middleware = (storeApi) => (next) => async (action) => {
  if (
    action.type === BudgetActionTypes.SET_ARTICLE_PROVIDER) {
    const result = next(action);

    const { article, provider }: SetArticleProviderPayload = action.payload;
    const put = async () => budgetController.SetInvoiceArticleProvider(
      article.id,
      article.type,
      provider,
    );

    try {
      await put();

      storeApi.dispatch(UpdateArticleProvider({
        articleId: article.id,
        providerId: provider,
      }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrorToast(i18n.t(error.message));
    }
    return result;
  }

  return next(action);
};
