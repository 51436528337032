import { useCallback, useState } from 'react';

import { useDeathLocation } from 'hooks/useDeathLocation';

import { budgetMasterDataClient } from '../api';
import { MasterDataQuestionType, MasterDataWithWork } from '../api/types';

export interface BudgetLocationsHook {
  fetchLocations: (
    locationType: MasterDataQuestionType, country: string, province: string, city: string) => void;
  locations: MasterDataWithWork[];
  getLocation: (code: string) => MasterDataWithWork | undefined
}

export const useBudgetLocations = (): BudgetLocationsHook => {
  const [locations, setLocations] = useState<MasterDataWithWork[]>([]);
  const {
    fetchDeathLocations,
  } = useDeathLocation();

  const fetchLocations = async (
    locationType: MasterDataQuestionType,
    country: string,
    province: string,
    city: string,
  ) => {
    if (locationType === MasterDataQuestionType.DEATH) {
      await fetchDeathLocations(city)
        .then((response) => {
          setLocations(response);
          return response;
        })
        .catch(() => {
          setLocations([]);
        });
    } else {
      await budgetMasterDataClient
        .GetLocations(locationType, country, province, city)
        .then((response) => {
          setLocations(response);
          return response;
        })
        .catch(() => {
          setLocations([]);
        });
    }
  };

  const getLocation = useCallback(
    (code: string) => locations?.find((location) => location.code === code),
    [locations],
  );

  return {
    fetchLocations,
    getLocation,
    locations,
  };
};
