import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Article } from 'modules/budget/models/Article';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q42Form, Q42Response } from './form';

export const Q42: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q42;
  const { RespondQuestion } = useBudget();
  const [responseValue, setResponseValue] = useState<Article[]>([]);
  const [autorespond, setautorespond] = useState<boolean>(false);

  const response = useCallback(
    ({ gotoSummary }) => {
      if (responseValue) {
        RespondQuestion({
          gotoSummary,
          question,
          response: { articles: responseValue },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [responseValue],
  );

  const onChange = useCallback(
    (responseData: Q42Response) => {
      setResponseValue(responseData.articles);
      setautorespond(responseData.autorespond);
    },
    [],
  );

  useEffect(
    () => {
      if (autorespond) {
        setautorespond(false);
        response({ gotoSummary: false });
      }
    },
    [autorespond, response],
  );

  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q42Form
        question={question}
        onChange={onChange}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{ articles: responseValue }}
          />
          <QConfirmButton
            disabled={false}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
