import classNames from 'classnames';
import React, { FC } from 'react';

interface PanelComponent {
  style?: Record<string, unknown>;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

export const Panel: FC<PanelComponent> = ({ children, className, style }) => {
  const clones = React.Children.map(
    children,
    (child) => React.cloneElement(child, { style: {} }),
  );

  return (
    <div
      className={classNames('f-panel', className)}
      style={style}
    >
      {clones}
    </div>
  );
};
