import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSanitaryPractices } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

interface SanitaryPracticesHook {
  getSanitaryPractice: (
    code: string | undefined) => IMasterDataDto | undefined;
}

export const useSanitaryPractices = (): SanitaryPracticesHook => {
  const dispatch = useDispatch();

  const { normalized } = useSelector((state: RootState) => state);

  const getSanitaryPractice = useCallback(
    (code) => (normalized.sanitaryPractices && normalized.sanitaryPractices[code]
      ? normalized.sanitaryPractices[code]
      : undefined),
    [normalized],
  );

  useEffect(() => {
    dispatch(getSanitaryPractices());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getSanitaryPractice,
  };
};
