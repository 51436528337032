import addNotification from 'react-push-notification';

interface WebNotificationsHook {
  send: (title: string, content: string) => void
}

export const useWebNotifiactions = (): WebNotificationsHook => {
  const send = (title: string, content: string) => {
    const notificationInfo = {
      subtitle: content,
      title,
    };
    addNotification({
      ...notificationInfo,
      duration: 120000,
      native: false,
      theme: 'light',
    });
    addNotification({
      ...notificationInfo,
      native: true,
    });
  };

  return {
    send,
  };
};
