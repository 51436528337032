import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import Modal from 'components/base/Modal';
import { Row } from 'components/base/Row';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { EmailForm } from '../EmailForm';

import { BudgetSendModalComponent } from './types';

export const BudgetSendModal: FC<BudgetSendModalComponent> = ({
  onCancel, show, onSuccess, budgetId,
}) => {
  const { t } = useTranslation();
  const { SendBudget } = useBudget();
  const [emailList, setList] = useState <Array<string>>([]);

  const confirmSend = useCallback(
    () => {
      SendBudget({
        budgetId,
        emails: emailList,
      }).then(() => {
        setList([]);
        showSuccessToast(t('budget.actions.sendSuccess'));
        onSuccess();
        return {};
      }).catch((e: Error) => {
        showErrorToast(e.message);
      });
    },
    [SendBudget, budgetId, emailList, onSuccess, t],
  );

  return (
    <Modal
      buttons={[
        {
          onClick: () => {
            setList([]);
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          disabled: emailList.length === 0,
          onClick: () => {
            confirmSend();
          },
          text: t('common.accept'),
        },
      ]}
      show={show}
      showCancel={false}
      showXButton={false}
      title={t('budget.actions.send')}
      onHide={onCancel}
    >
      <EmailForm
        onChange={(val) => {
          setList([
            ...emailList.filter((e) => e !== val.email),
            val.email,
          ]);
        }}
      />
      {Array.from(emailList).map((e) => (
        <Row
          key={e}
          justifyContent="space-around"
          style={{
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>{e}</div>
          <Button
            color="transparent"
            onClick={() => {
              setList([
                ...emailList.filter((l) => l !== e),
              ]);
            }}
          >
            <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />
          </Button>

        </Row>
      ))}
    </Modal>
  );
};
