import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatName } from 'utils/helpers';

import Button from 'components/base/Button';
import { Column } from 'components/base/Column';
import CustomIcon from 'components/base/CustomIcon';
import { Navigate } from 'components/base/Navigate';
import { Row } from 'components/base/Row';
import Tooltip from 'components/base/Tooltip';

import { ExtendedEvent } from 'models/Event';

import './index.scss';
import { CalendarServiceInfo } from '../CalendarServiceInfo';

type EventCalendarProps = {
  event: ExtendedEvent;
};

const EventCalendar = ({ event }: EventCalendarProps): ReactElement => {
  const { t } = useTranslation();
  const [showExtra, setShowExtra] = useState<boolean>(false);

  const onShowInfoClick = useCallback(() => {
    if (event.serviceId) {
      setShowExtra(true);
    }
  },
  [event.serviceId]);

  return (
    <React.Fragment>
      <div className="event-calendar">
        <Column style={{ overflow: 'hidden' }}>
          <Row className="f-event-calendar-header" justifyContent="flex-start" style={{ alignItems: 'center' }}>
            <Tooltip className="f-event-calendar-header-icon" content={t('order.details.details')}>
              <Button
                className="f-exta-info-button"
                color="transparent"
                rightAddon={<CustomIcon icon={event.type} />}
                onClick={onShowInfoClick}
              />
            </Tooltip>
            <div className="event-users">
              {event.deceased && (
              <Navigate
                to={`/records/${event.recordId}/edit`}
              >
                {formatName(event.deceased)}
              </Navigate>
              )}
            </div>
          </Row>
        </Column>
      </div>
      <CalendarServiceInfo
        erpId={Number(event.erpId)}
        postCeremonyId={event.postCeremonyId || null}
        serviceId={event.serviceId}
        show={showExtra}
        type={event.type}
        onCancel={() => setShowExtra(false)}
      />
    </React.Fragment>

  );
};

export default EventCalendar;
