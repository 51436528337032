import classNames from 'classnames';
import {
  Formik, Form, FormikProps, FormikHelpers,
} from 'formik';
import React, { createRef, ReactElement, useState } from 'react';
import { ChevronLeft, FilePlus, Search } from 'react-feather';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';

import CreateUpdate from 'components/workOrder/CreateUpdate';

import useClickOutside from 'hooks/useClickOutside';

import './index.scss';

type SideFilterProps<T> = {
  fields: (formikProps: FormikProps<T>) => ReactElement[];
  initialValues: T;
  onSearch(values: T, actions?: FormikHelpers<T>): void;
  title: string;
  showAddManualOT?: boolean;
  onCreateManualOT?: () => void;
};

const SideFilter = <T extends Record<string, unknown>>({
  fields,
  initialValues,
  onSearch,
  title,
  showAddManualOT,
  onCreateManualOT,
}: SideFilterProps<T>): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const [showManualOTModal, setshowManualOTModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const sidefilterRef = createRef<HTMLDivElement>();
  useClickOutside(sidefilterRef, () => setExpanded(false));

  return (
    <div ref={sidefilterRef} className={classNames('sidefilter', { expanded })}>
      <div className="sidefilter-header">
        {title}
        <Button
          className={classNames('expand-sidefilter', { expanded })}
          color="transparent"
          testid="expand-sidefilter"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ChevronLeft /> : <Search />}
        </Button>
        {showAddManualOT
        && !expanded
        && (
        <Button
          className={classNames('createWorkOrder', { expanded })}
          color="transparent"
          testid="createWorkOrder"
          onClick={() => setshowManualOTModal(true)}
        >
          <FilePlus />
        </Button>
        )}

      </div>
      <Formik initialValues={initialValues || {}} onSubmit={onSearch}>
        {(formikProps: FormikProps<T>) => (
          <Form autoComplete="off" className="filters">
            {fields(formikProps).map((field) => field)}
            <Button
              color="special"
              disabled={formikProps.isSubmitting}
              text={t('common.search')}
              type="submit"
              onClick={() => setExpanded(false)}
            />
          </Form>
        )}
      </Formik>
      <CreateUpdate
        show={showManualOTModal}
        title={t('order.create')}
        onHide={() => {
          setshowManualOTModal(false);
          onCreateManualOT?.();
        }}
      />
    </div>
  );
};

SideFilter.defaultProps = {
  onCreateManualOT: () => false,
  showAddManualOT: false,
};

export default SideFilter;
