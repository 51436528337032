import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, { Fragment, InputHTMLAttributes, ReactElement } from 'react';

import { formatDateForSearch } from 'utils/dateManager';

import Datepicker, { DatepickerProps } from 'components/base/Datepicker';
import Input from 'components/base/Input';
import Select, { SelectProps } from 'components/base/Select';
import SelectButton, { SelectButtonProps } from 'components/base/SelectButton';

import { i18n } from 'i18n';
import { DefaultSelectType } from 'models/Others';

type InputFilterProps = InputHTMLAttributes<HTMLInputElement> & {
  filterType: 'input';
};

type DateFilterProps = DatepickerProps & {
  filterType: 'date';
};

type SelectFilterProps<K> = Omit<
  Omit<Omit<SelectProps<K>, 'getLabel'>, 'getValue'>,
  'onChange'
> & {
  filterType: 'select';
};

type SelectButtonFilterProps<K> = Omit<
  Omit<Omit<Omit<SelectButtonProps<K>, 'getLabel'>, 'getValue'>, 'options'>,
  'onChange'
> & {
  filterType: 'tristate';
};

enum TristateValues {
  all = 'all',
  yes = 'yes',
  no = 'no',
}

const tristateOptions: DefaultSelectType[] = [
  { label: i18n.t('common.all'), value: TristateValues.all },
  { label: i18n.t('common.yes'), value: TristateValues.yes },
  { label: i18n.t('common.no'), value: TristateValues.no },
];

export const tristateEquivalence = {
  all: undefined,
  no: false,
  yes: true,
};

const getValueTristate = (value: boolean | undefined): DefaultSelectType => {
  if (value) {
    return tristateOptions[1];
  }
  if (value === false) {
    return tristateOptions[2];
  }
  return tristateOptions[0];
};

const getInputValue = (value: unknown): boolean | undefined => {
  if (value === false || value === true) {
    return value;
  }

  return undefined;
};

export type SideFilterFieldProps<T, K> = (
  | InputFilterProps
  | DateFilterProps
  | SelectFilterProps<T>
  | SelectButtonFilterProps<K>
) & {
  clearable?: boolean | undefined;
  formikProps: FormikProps<T>;
  getLabel?: (elem: K) => string;
  getValue?: (elem: K) => string | number;
  key: string;
  name: string;
  placeholder: string;
  options?: K[];
  type?: string;
};

const SideFilterField = <
  T extends Record<string, unknown>,
  K = Record<string, unknown>
>({
    className,
    clearable,
    filterType,
    formikProps,
    getLabel,
    getValue,
    name,
    options,
    placeholder,
    type,
  }: SideFilterFieldProps<T, K>): ReactElement => {
  const {
    values, handleBlur, isSubmitting, setFieldValue,
  } = formikProps;

  const classes = classNames('sidefilter-field', className);
  const fieldValue = getIn(values, name);

  if (filterType === 'input') {
    const isCheckbox = type === 'checkbox';
    return (
      <Input
        key={name}
        checked={isCheckbox ? getInputValue(fieldValue) : undefined}
        className={classes}
        clearable={clearable === undefined ? true : clearable}
        disabled={isSubmitting}
        name={name}
        placeholder={placeholder}
        type={type || 'text'}
        value={isCheckbox ? undefined : `${fieldValue || ''}`}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue(name, val)}
      />
    );
  }
  if (filterType === 'date') {
    return (
      <Datepicker
        key={name}
        className={classes}
        clearable={clearable === undefined ? true : clearable}
        disabled={isSubmitting}
        name={name}
        placeholder={placeholder}
        selected={fieldValue ? new Date(fieldValue) : undefined}
        onBlur={handleBlur}
        onChange={(date: Date) => setFieldValue(name, formatDateForSearch(date))}
      />
    );
  }
  if (filterType === 'select' && getLabel && getValue && options) {
    return (
      <Select<K>
        key={name}
        className={classes}
        clearable={clearable === undefined ? true : clearable}
        disabled={isSubmitting}
        getLabel={getLabel}
        getValue={getValue}
        name={name}
        options={options}
        placeholder={placeholder}
        value={fieldValue as K}
        onBlur={handleBlur}
        onChange={(elem: K) => setFieldValue(name, elem)}
      />
    );
  }
  if (filterType === 'tristate') {
    return (
      <SelectButton<DefaultSelectType>
        key={name}
        className={classes}
        disabled={isSubmitting}
        getLabel={({ label }: DefaultSelectType) => label}
        getValue={({ value }: DefaultSelectType) => value}
        name={name}
        options={tristateOptions}
        placeholder={placeholder}
        value={getValueTristate(fieldValue as boolean)}
        onChange={(elem: DefaultSelectType) => setFieldValue(
          name,
            tristateEquivalence[elem.value as TristateValues] as boolean,
        )}
      />
    );
  }
  return <Fragment />;
};

export default SideFilterField;
