import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { isBoolean, isBooleanAndFalse, isBooleanAndTrue } from 'utils/helpers';

import { Column } from 'components/base/Column';

import { MasterDataQuestionType } from 'modules/budget/api/types';
import { BudgetLocationSelector } from 'modules/budget/components/articleSelectors/BudgetLocationSelector';
import { CitySelector } from 'modules/budget/components/commons/CitySelector';
import { CustomLocationSelector } from 'modules/budget/components/commons/CustomLocationSelector';
import { encodeLocation } from 'modules/budget/components/commons/CustomLocationSelector/tools';
import { Separator } from 'modules/budget/components/commons/Separator';
import { SeparatorStyles } from 'modules/budget/components/commons/Separator/types';
import { YesNoSelector } from 'modules/budget/components/commons/YesNoSelector';
import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { useQuestionLabels } from 'modules/budget/hooks/useQuestionLabels';
import { Address } from 'modules/budget/models/Address';
import { Location } from 'modules/budget/models/Location';
import { ILocation } from 'modules/budget/models/Location/types';
import { dismissServiceId } from 'modules/budget/store/data';

import { vigilPredefinedSelections } from '../../../../data/vigilPredefinedSelections';

export interface Q5Response {
  dismissService?: boolean;
  address: Address | null;
  location: ILocation | null;
  autorespond: boolean;
}

interface Q5FormComponent {
  onChange: (value: Q5Response) => void;
  question: BudgetQuestionCodes;
}

export const Q5Form: FC<Q5FormComponent> = ({ onChange, question }) => {
  const { t } = useTranslation();
  const { yes, no } = useQuestionLabels();
  const { GetQuestionValue } = useBudget();
  const [wantService, setWantService] = useState<boolean>();
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

  const storedValue = useMemo(
    () => GetQuestionValue(question),
    [GetQuestionValue, question],
  );

  useEffect(
    () => {
      if (storedValue?.address && storedValue?.location) {
        setSelectedAddress(new Address(storedValue?.address));
        setSelectedLocation(storedValue?.location);
        setWantService(true);

        onChange({
          address: new Address(storedValue?.address),
          autorespond: false,
          dismissService: false,
          location: storedValue?.location,
        });
      } else if (storedValue === dismissServiceId) {
        onChange({
          address: null,
          autorespond: false,
          dismissService: true,
          location: null,
        });
        setWantService(false);
        setSelectedAddress(null);
        setSelectedLocation(null);
      } else {
        setSelectedAddress(null);
        setSelectedLocation(null);
      }
    },
    [onChange, storedValue],
  );

  return (
    <Formik
      initialValues={{
        address: selectedAddress,
        autorespond: false,
        dismissService: isBooleanAndTrue(wantService) || isBooleanAndFalse(wantService)
          ? !wantService
          : undefined,
        location: selectedLocation,
      }}
      validate={(values) => {
        onChange({
          address: values.address,
          autorespond: values.autorespond,
          dismissService: values.dismissService,
          location: values.location,
        });
        setWantService(isBooleanAndTrue(values.dismissService)
          || isBooleanAndFalse(values.dismissService)
          ? !values.dismissService
          : undefined);
        setSelectedAddress(values.address);
        setSelectedLocation(values.location);

        return {};
      }}
      enableReinitialize
      validateOnBlur
      validateOnChange
      onSubmit={() => Promise.resolve()}
    >
      {({
        values, setValues,
      }: FormikProps<Q5Response>) => {
        const hideElements = values.dismissService === undefined
          || isBooleanAndTrue(values.dismissService);

        return (
          <Column>
            <YesNoSelector
              noCaption={no(question)}
              value={isBooleanAndTrue(values.dismissService)
                || isBooleanAndFalse(values.dismissService)
                ? !values.dismissService
                : undefined}
              yesCaption={yes(question)}
              onChange={(v) => {
                setValues({
                  address: null,
                  autorespond: isBooleanAndFalse(v),
                  dismissService: isBoolean(v) ? !v : undefined,
                  location: null,
                });
              }}
            />
            <Separator
              className={classNames({ 'f-budget-hidden': hideElements })}
              layout={SeparatorStyles.BORDER_ONE}
            />

            <CustomLocationSelector
              className={classNames({ 'f-budget-hidden': hideElements })}
              items={vigilPredefinedSelections.map(({
                city, country, description, location, province,
              }) => ({
                code: encodeLocation(country, province, city, location),
                description,
              }))}
              questionType={MasterDataQuestionType.VIGIL}
              value={{
                city: values.address?.city || '',
                country: values.address?.country || '',
                location: values.location?.id || '',
                province: values.address?.province || '',
              }}
              onChange={(data) => {
                if (data) {
                  setValues({
                    address: new Address({
                      city: data?.city || '',
                      country: data?.country || '',
                      province: data?.province || '',
                      work: true,
                    }),
                    autorespond: true,
                    dismissService: false,
                    location: new Location({
                      id: data?.location || '',
                      work: Boolean(data?.locationWork),
                    }),
                  });
                } else {
                  setValues({
                    address: null,
                    autorespond: false,
                    dismissService: false,
                    location: null,
                  });
                }
              }}
            />
            <Separator
              className={classNames({ 'f-budget-hidden': hideElements })}
              layout={SeparatorStyles.BORDER_ONE}
            />
            <CitySelector
              cityCaption={t('budget.wizard.city.vigil')}
              cityCode={values.address?.city || ''}
              cityWork={Boolean(values.address?.work)}
              className={classNames({ 'f-budget-hidden': hideElements })}
              countryCaption={t('budget.wizard.country.vigil')}
              countryCode={values.address?.country || ''}
              disabled={Boolean(values.dismissService)}
              provinceCaption={t('budget.wizard.province.vigil')}
              provinceCode={values.address?.province || ''}
              emitPartial
              onChange={(val) => {
                setValues({
                  address: new Address(val),
                  autorespond: values.autorespond,
                  dismissService: values.dismissService,
                  location: values.address?.isEqual(val) ? values.location : null,
                });
              }}
            />
            <Separator />
            <BudgetLocationSelector
              caption={t('service.vigil.location')}
              city={values.address?.city || ''}
              className={classNames({ 'f-budget-hidden': hideElements })}
              country={values.address?.country || ''}
              disabled={Boolean(values.dismissService)}
              province={values.address?.province || ''}
              questionType={MasterDataQuestionType.VIGIL}
              value={values.location?.id || ''}
              onChange={(val) => {
                setValues({
                  address: values.address,
                  autorespond: values.autorespond,
                  dismissService: values.dismissService,
                  location: val ? new Location(val) : null,
                });
              }}
            />
          </Column>
        );
      }}
    </Formik>
  );
};
