import { AxiosError } from "axios";
import errorCat from 'i18n/cat/error';

export class BackendUnmanagedError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = error.message || errorCat[500];
    this.name = "BackendUnmanagedError";
  }
}
