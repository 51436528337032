import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from 'components/base/Column';
import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

import './index.scss';
import { LogoCabre } from '../../LogoCabre';

interface QHeaderComponent {
  question?: BudgetQuestionCodes;
  caption?: string
}
export const QHeader: FC<QHeaderComponent> = ({ question, caption }) => {
  const { t } = useTranslation();

  return (
    <Row className="f-q-header">
      <Column justifyContent="flex-start">
        <Row justifyContent="center">
          <LogoCabre />
        </Row>
        <div className="q-header" data-question-id={question}>{question ? t(`budget.wizard.${question}`) : caption}</div>
      </Column>
    </Row>
  );
};
