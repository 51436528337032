import { questionsWithMinInvoiceAmount } from 'modules/budget/data/questionsWithMinInvoiceAmount';
import { BudgetQuestionCollection } from 'modules/budget/store/budgetReducer/types';

import { BudgetsTrackingFilterStatus } from '../../components/tracking/table/types';
import { Article } from '../Article';
import { IArticle } from '../Article/types';
import { Audit } from '../Audit';

import { IBudget } from './types';

export class Budget extends Audit implements IBudget {
  constructor(params: IBudget) {
    const mapToArticle = (article: IArticle): Article => new Article(article);

    super(params);

    this.questions = params.questions;
    this.automaticArticles = params.automaticArticles
      ? params.automaticArticles.map(mapToArticle)
      : [];
    this.idRecord = params.idRecord;
    this.collected = params.collected;
    this.discardedArticles = params.discardedArticles
      ? params.discardedArticles.map(mapToArticle)
      : [];
    this.status = params.status;
    this.articles = [];
  }

  public populateArticles(articles: IArticle[]): void {
    this.articles = articles;
  }

  public totalPriceWithVat(articleId?: string): number {
    let price = 0;

    const getTargetArticle = (article: IArticle) => {
      if (article.questionCode && article.subType) {
        const targetQuestion = this.questions[article.questionCode];
        const articleCollection = targetQuestion?.articles?.[article.subType];
        return articleCollection?.find(
          (collectionArticle: IArticle) => collectionArticle.code === article.code,
        );
      }

      const isAutomaticArticleAlreadyInBudget = this.automaticArticles.find(
        (automaticArticle: IArticle) => automaticArticle.code === article.code,
      );

      return isAutomaticArticleAlreadyInBudget || {
        ...article,
        price: article?.prices && article?.prices.length !== 0 ? article?.prices[0].price : 0,
        vat: article?.prices && article?.prices.length !== 0 ? article?.prices[0].vat : 0,
      };
    };

    this.articles?.forEach((article) => {
      if (article.code === articleId) {
        const targetArticle = getTargetArticle(article);
        const amount = targetArticle?.amount || 1;
        const selectedArticle = this.articles?.find((art) => art.code === articleId);
        if (selectedArticle?.prices) {
          const articlePrice = selectedArticle?.prices[0]?.price;
          const articleVat = selectedArticle?.prices[0].vat;
          price += ((articlePrice * articleVat) + articlePrice) * amount;
        }
      } else if (article.questionCode && article.subType) {
        const targetArticle = getTargetArticle(article);

        const invoiceAmountInfo = article.questionCode
        && questionsWithMinInvoiceAmount
          .find((i) => i.questionCode === article.questionCode);

        const amount = invoiceAmountInfo
          && targetArticle?.amount && targetArticle?.amount < invoiceAmountInfo.minInvoiceAmount
          ? invoiceAmountInfo.minInvoiceAmount
          : (targetArticle?.amount || 1);

        if (targetArticle
          && targetArticle.price !== undefined
          && targetArticle.price !== null
          && targetArticle.vat !== undefined
          && targetArticle.vat !== null) {
          price += ((targetArticle.price * targetArticle.vat) + targetArticle.price) * amount;
        }
      } else {
        const targetArticle = getTargetArticle(article);

        if (targetArticle
          && targetArticle.price !== undefined
          && targetArticle.price !== null
          && targetArticle.vat !== undefined
          && targetArticle.vat !== null) {
          price += ((targetArticle.price * targetArticle.vat) + targetArticle.price);
        }
      }
    });

    return price;
  }

  status: BudgetsTrackingFilterStatus;

  discardedArticles: Article[];

  collected: boolean;

  idRecord: number | null;

  automaticArticles: Article[];

  questions: BudgetQuestionCollection;

  articles?: IArticle[];
}
