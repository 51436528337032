/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AbortionDataDto1,
  FunusMovementTypes,
  ArticleCategoryTypes,
  BlocksEnum,
  AbortionDataDto7,
  AbortionDataDto8,
  AbortionDataDto9,
  ArticleByCategoryResponseDto,
  ArticleSupplierResponseDto,
  CategoryByArticleResponseDto,
  MasterDataCityDto,
  MasterDataDeathLocationDto,
  MasterDataDto,
  MasterDataProvinceDto,
  MasterDataVigilLocationDto,
  MasterDataWithCityDto,
  MasterDataWithLocalsDto,
  MasterServiceDto,
  MasterWorkOrderDto,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class MasterController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags master-controller
   * @name GetArticleMasterDataUsingGet
   * @summary getArticleMasterData
   * @request GET:/articles/{erpId}/{blockId}/{familyId}/{cityId}
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getArticleMasterDataUsingGet = (
    blockId: BlocksEnum,
    cityId: string,
    erpId: string,
    familyId: AbortionDataDto7,
    params: RequestParams = {},
  ) =>
    this.http.request<MasterDataDto[], void>({
      path: `/articles/${erpId}/${blockId}/${familyId}/${cityId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name FindAllServicesUsingGet
   * @summary findAllServices
   * @request GET:/master/service
   * @secure
   * @response `200` `(MasterServiceDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  findAllServicesUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterServiceDto[], void>({
      path: `/master/service`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetServiceUsingGet
   * @summary getService
   * @request GET:/master/service/{type}
   * @secure
   * @response `200` `MasterServiceDto` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getServiceUsingGet = (type: AbortionDataDto9, params: RequestParams = {}) =>
    this.http.request<MasterServiceDto, void>({
      path: `/master/service/${type}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name FindAllWorkOrdersUsingGet
   * @summary findAllWorkOrders
   * @request GET:/master/workorder
   * @secure
   * @response `200` `(MasterWorkOrderDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  findAllWorkOrdersUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterWorkOrderDto[], void>({
      path: `/master/workorder`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetWorkOrdersUsingGet
   * @summary getWorkOrders
   * @request GET:/master/workorder/{service}
   * @secure
   * @response `200` `(MasterWorkOrderDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getWorkOrdersUsingGet = (service: AbortionDataDto1, params: RequestParams = {}) =>
    this.http.request<MasterWorkOrderDto[], void>({
      path: `/master/workorder/${service}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetAbortionTypeMasterDataUsingGet
   * @summary getAbortionTypeMasterData
   * @request GET:/masterData/abortion/type
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getAbortionTypeMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/abortion/type`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetAirportMasterDataUsingGet
   * @summary getAirportMasterData
   * @request GET:/masterData/airport
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getAirportMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/airport`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCategoryByArticleUsingGet
   * @summary getCategoryByArticle
   * @request GET:/masterData/articles/{articleType}/categories
   * @secure
   * @response `200` `CategoryByArticleResponseDto` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCategoryByArticleUsingGet = (articleType: ArticleCategoryTypes, params: RequestParams = {}) =>
    this.http.request<CategoryByArticleResponseDto, void>({
      path: `/masterData/articles/${articleType}/categories`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetArticleByCategoryUsingGet
   * @summary getArticleByCategory
   * @request GET:/masterData/articles/{categoryType}
   * @secure
   * @response `200` `ArticleByCategoryResponseDto` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getArticleByCategoryUsingGet = (categoryType: string, params: RequestParams = {}) =>
    this.http.request<ArticleByCategoryResponseDto, void>({
      path: `/masterData/articles/${categoryType}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetArticleSupplierUsingGet
   * @summary getArticleSupplier
   * @request GET:/masterData/articles/{code}/suppliers
   * @secure
   * @response `200` `ArticleSupplierResponseDto` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getArticleSupplierUsingGet = (code: string, params: RequestParams = {}) =>
    this.http.request<ArticleSupplierResponseDto, void>({
      path: `/masterData/articles/${code}/suppliers`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetAshesDestinationMasterDataUsingGet
   * @summary getAshesDestinationMasterData
   * @request GET:/masterData/ashes/destination
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getAshesDestinationMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/ashes/destination`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCemeteryMasterDataUsingGet
   * @summary getCemeteryMasterData
   * @request GET:/masterData/cemetery/{town}
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCemeteryMasterDataUsingGet = (town: string, params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/cemetery/${town}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCemeterySectionMasterDataUsingGet
   * @summary getCemeterySectionMasterData
   * @request GET:/masterData/cemeterySection/{town}/{cemetery}
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCemeterySectionMasterDataUsingGet = (cemetery: string, town: string, params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/cemeterySection/${town}/${cemetery}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCenterMasterDataUsingGet
   * @summary getCenterMasterData
   * @request GET:/masterData/center
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCenterMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/center`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCeremoniesUsingGet
   * @summary getCeremonies
   * @request GET:/masterData/ceremony
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCeremoniesUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/ceremony`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCeremonyLocationMasterDataUsingGet
   * @summary getCeremonyLocationMasterData
   * @request GET:/masterData/ceremony/location/{cityId}
   * @secure
   * @response `200` `(MasterDataWithCityDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCeremonyLocationMasterDataUsingGet = (cityId: string, params: RequestParams = {}) =>
    this.http.request<MasterDataWithCityDto[], void>({
      path: `/masterData/ceremony/location/${cityId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCityMasterDataUsingGet
   * @summary getCityMasterData
   * @request GET:/masterData/city
   * @secure
   * @response `200` `(MasterDataCityDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCityMasterDataUsingGet = (country: string, province: string, params: RequestParams = {}) =>
    this.http.request<MasterDataCityDto[], void>({
      path: `/masterData/city`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCityMasterDataUsingGet1
   * @summary getCityMasterData
   * @request GET:/masterData/city/{country}/{province}
   * @secure
   * @response `200` `(MasterDataCityDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCityMasterDataUsingGet1 = (country: string, province: string, params: RequestParams = {}) =>
    this.http.request<MasterDataCityDto[], void>({
      path: `/masterData/city/${country}/${province}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetConcessionTypeMasterDataUsingGet
   * @summary getConcessionTypeMasterData
   * @request GET:/masterData/concession/type
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getConcessionTypeMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/concession/type`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCountryMasterDataUsingGet
   * @summary getCountryMasterData
   * @request GET:/masterData/country
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCountryMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/country`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetCrematoryMasterDataUsingGet
   * @summary getCrematoryMasterData
   * @request GET:/masterData/crematory/{town}
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getCrematoryMasterDataUsingGet = (town: string, params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/crematory/${town}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetDeathMasterDataUsingGet
   * @summary getDeathMasterData
   * @request GET:/masterData/death/type
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getDeathMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/death/type`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetDeathLocationMasterDataUsingGet
   * @summary getDeathLocationMasterData
   * @request GET:/masterData/deathLocation/{cityId}
   * @secure
   * @response `200` `(MasterDataDeathLocationDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getDeathLocationMasterDataUsingGet = (cityId: string, params: RequestParams = {}) =>
    this.http.request<MasterDataDeathLocationDto[], void>({
      path: `/masterData/deathLocation/${cityId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetDepositMasterDataUsingGet
   * @summary getDepositMasterData
   * @request GET:/masterData/deposit/{cityId}
   * @secure
   * @response `200` `(MasterDataWithCityDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getDepositMasterDataUsingGet = (cityId: string, params: RequestParams = {}) =>
    this.http.request<MasterDataWithCityDto[], void>({
      path: `/masterData/deposit/${cityId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetDiseaseTypeMasterDataUsingGet
   * @summary getDiseaseTypeMasterData
   * @request GET:/masterData/disease/type
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getDiseaseTypeMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/disease/type`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetDoctorMasterDataUsingGet
   * @summary getDoctorMasterData
   * @request GET:/masterData/doctor/{deathType}
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getDoctorMasterDataUsingGet = (deathType: AbortionDataDto8, params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/doctor/${deathType}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetGenderMasterDataUsingGet
   * @summary getGenderMasterData
   * @request GET:/masterData/gender
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getGenderMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/gender`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetGroupCauseMasterDataUsingGet
   * @summary getGroupCauseMasterData
   * @request GET:/masterData/group/cause
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getGroupCauseMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/group/cause`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetInsuranceMasterDataUsingGet
   * @summary getInsuranceMasterData
   * @request GET:/masterData/insurance
   * @secure
   * @response `200` `(MasterDataWithLocalsDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getInsuranceMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataWithLocalsDto[], void>({
      path: `/masterData/insurance`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetMasterStatusMasterDataUsingGet
   * @summary getMasterStatusMasterData
   * @request GET:/masterData/maritalStatus
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getMasterStatusMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/maritalStatus`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetMovementTypesUsingGet
   * @summary getMovementTypes
   * @request GET:/masterData/movements/types
   * @secure
   * @response `200` `(FunusMovementTypes)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getMovementTypesUsingGet = (params: RequestParams = {}) =>
    this.http.request<FunusMovementTypes[], void>({
      path: `/masterData/movements/types`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetNicheTypeMasterDataUsingGet
   * @summary getNicheTypeMasterData
   * @request GET:/masterData/niche/type
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getNicheTypeMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/niche/type`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetOriginFuneralHomeMasterDataUsingGet
   * @summary getOriginFuneralHomeMasterData
   * @request GET:/masterData/originFuneralHome
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getOriginFuneralHomeMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/originFuneralHome`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetOvenMasterDataUsingGet
   * @summary getOvenMasterData
   * @request GET:/masterData/oven/{town}/{crematory}
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getOvenMasterDataUsingGet = (crematory: string, town: string, params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/oven/${town}/${crematory}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetOwnerMasterDataUsingGet
   * @summary getOwnerMasterData
   * @request GET:/masterData/owner
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getOwnerMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/owner`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetProvinceMasterDataUsingGet
   * @summary getProvinceMasterData
   * @request GET:/masterData/province
   * @secure
   * @response `200` `(MasterDataProvinceDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getProvinceMasterDataUsingGet = (country: string, params: RequestParams = {}) =>
    this.http.request<MasterDataProvinceDto[], void>({
      path: `/masterData/province`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetProvinceMasterDataUsingGet1
   * @summary getProvinceMasterData
   * @request GET:/masterData/province/{country}
   * @secure
   * @response `200` `(MasterDataProvinceDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getProvinceMasterDataUsingGet1 = (country: string, params: RequestParams = {}) =>
    this.http.request<MasterDataProvinceDto[], void>({
      path: `/masterData/province/${country}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetRecordTypeMasterDataUsingGet
   * @summary getRecordTypeMasterData
   * @request GET:/masterData/record/type
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getRecordTypeMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/record/type`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetReliquaryMasterDataUsingGet
   * @summary getReliquaryMasterData
   * @request GET:/masterData/reliquary
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getReliquaryMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/reliquary`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetSanitaryPracticeMasterDataUsingGet
   * @summary getSanitaryPracticeMasterData
   * @request GET:/masterData/sanitaryPractice
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getSanitaryPracticeMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/sanitaryPractice`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetSanitaryPracticeLocationMasterDataUsingGet
   * @summary getSanitaryPracticeLocationMasterData
   * @request GET:/masterData/sanitaryPracticeLocation/{cityId}
   * @secure
   * @response `200` `(MasterDataWithCityDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getSanitaryPracticeLocationMasterDataUsingGet = (cityId: string, params: RequestParams = {}) =>
    this.http.request<MasterDataWithCityDto[], void>({
      path: `/masterData/sanitaryPracticeLocation/${cityId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetThanatologistMasterDataUsingGet
   * @summary getThanatologistMasterData
   * @request GET:/masterData/thanatologist
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getThanatologistMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/thanatologist`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetThanatopractorMasterDataUsingGet
   * @summary getThanatopractorMasterData
   * @request GET:/masterData/thanatopractor
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getThanatopractorMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/thanatopractor`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetTribunalMasterDataUsingGet
   * @summary getTribunalMasterData
   * @request GET:/masterData/tribunal/{cityId}
   * @secure
   * @response `200` `(MasterDataWithCityDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getTribunalMasterDataUsingGet = (cityId: string, params: RequestParams = {}) =>
    this.http.request<MasterDataWithCityDto[], void>({
      path: `/masterData/tribunal/${cityId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetUrnMasterDataUsingGet
   * @summary getUrnMasterData
   * @request GET:/masterData/urn
   * @secure
   * @response `200` `(MasterDataDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getUrnMasterDataUsingGet = (params: RequestParams = {}) =>
    this.http.request<MasterDataDto[], void>({
      path: `/masterData/urn`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags master-controller
   * @name GetVigilLocationMasterDataUsingGet
   * @summary getVigilLocationMasterData
   * @request GET:/masterData/vigil/location/{cityId}
   * @secure
   * @response `200` `(MasterDataVigilLocationDto)[]` OK
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  getVigilLocationMasterDataUsingGet = (cityId: string, params: RequestParams = {}) =>
    this.http.request<MasterDataVigilLocationDto[], void>({
      path: `/masterData/vigil/location/${cityId}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
