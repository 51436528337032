import classNames from 'classnames';
import React, { FC } from 'react';

import Loader from 'components/base/Loader';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';

import { BudgetWizardButtons } from '../commons/BudgetWizardButtons';
import { QContainer } from '../commons/QContainer';
import { Q1 } from '../questions/Q1';
import { Q10 } from '../questions/Q10';
import { Q11 } from '../questions/Q11';
import { Q12 } from '../questions/Q12';
import { Q13 } from '../questions/Q13';
import { Q14 } from '../questions/Q14';
import { Q15 } from '../questions/Q15';
import { Q17 } from '../questions/Q17';
import { Q19 } from '../questions/Q19';
import { Q2 } from '../questions/Q2';
import { Q21 } from '../questions/Q21';
import { Q22 } from '../questions/Q22';
import { Q23 } from '../questions/Q23';
import { Q24 } from '../questions/Q24';
import { Q25 } from '../questions/Q25';
import { Q27 } from '../questions/Q27';
import { Q28 } from '../questions/Q28';
import { Q29 } from '../questions/Q29';
import { Q3 } from '../questions/Q3';
import { Q30 } from '../questions/Q30';
import { Q31 } from '../questions/Q31';
import { Q36 } from '../questions/Q36';
import { Q38 } from '../questions/Q38';
import { Q39 } from '../questions/Q39';
import { Q4 } from '../questions/Q4';
import { Q40 } from '../questions/Q40';
import { Q42 } from '../questions/Q42';
import { Q43 } from '../questions/Q43';
import { Q44 } from '../questions/Q44';
import { Q5 } from '../questions/Q5';
import { Q6 } from '../questions/Q6';
import { Q7 } from '../questions/Q7';
import { Q8 } from '../questions/Q8';
import { Q9 } from '../questions/Q9';

import './index.scss';

type BudgetWizardComponent = Record<string, unknown>

export const BudgetWizard: FC<BudgetWizardComponent> = () => {
  const {
    currentQuestion,
    id,
    budgetLoading,
  } = useBudget();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wizardQuestions: Record<string, any> = {
    [BudgetQuestionCodes.Q1]: (<Q1 />),
    [BudgetQuestionCodes.Q2]: (<Q2 />),
    [BudgetQuestionCodes.Q3]: (<Q3 />),
    [BudgetQuestionCodes.Q4]: (<Q4 />),
    [BudgetQuestionCodes.Q5]: (<Q5 />),
    [BudgetQuestionCodes.Q6]: (<Q6 />),
    [BudgetQuestionCodes.Q7]: (<Q7 />),
    [BudgetQuestionCodes.Q8]: (<Q8 />),
    [BudgetQuestionCodes.Q9]: (<Q9 />),
    [BudgetQuestionCodes.Q10]: (<Q10 />),
    [BudgetQuestionCodes.Q11]: (<Q11 />),
    [BudgetQuestionCodes.Q12]: (<Q12 />),
    [BudgetQuestionCodes.Q13]: (<Q13 />),
    [BudgetQuestionCodes.Q14]: (<Q14 />),
    [BudgetQuestionCodes.Q15]: (<Q15 />),
    [BudgetQuestionCodes.Q17]: (<Q17 />),
    [BudgetQuestionCodes.Q19]: (<Q19 />),
    [BudgetQuestionCodes.Q21]: (<Q21 />),
    [BudgetQuestionCodes.Q22]: (<Q22 />),
    [BudgetQuestionCodes.Q23]: (<Q23 />),
    [BudgetQuestionCodes.Q24]: (<Q24 />),
    [BudgetQuestionCodes.Q25]: (<Q25 />),
    [BudgetQuestionCodes.Q27]: (<Q27 />),
    [BudgetQuestionCodes.Q28]: (<Q28 />),
    [BudgetQuestionCodes.Q29]: (<Q29 />),
    [BudgetQuestionCodes.Q30]: (<Q30 />),
    [BudgetQuestionCodes.Q31]: (<Q31 />),
    [BudgetQuestionCodes.Q36]: (<Q36 />),
    [BudgetQuestionCodes.Q38]: (<Q38 />),
    [BudgetQuestionCodes.Q39]: (<Q39 />),
    [BudgetQuestionCodes.Q40]: (<Q40 />),
    [BudgetQuestionCodes.Q42]: (<Q42 />),
    [BudgetQuestionCodes.Q43]: (<Q43 />),
    [BudgetQuestionCodes.Q44]: (<Q44 />),
  };

  return (
    <QContainer className={classNames('f-budget-wizard', `current-question-${currentQuestion}`)}>
      <BudgetWizardButtons
        budgetId={id || undefined}
        showCart
        showGotoTracking
      />
      {budgetLoading ? (<Loader />) : <React.Fragment />}
      {currentQuestion && wizardQuestions[currentQuestion]
        ? wizardQuestions[currentQuestion]
        : <React.Fragment />}
    </QContainer>
  );
};
