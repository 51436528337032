import { useCallback, useState } from 'react';

import { formatDateForRequest } from 'utils/dateManager';

import { orderLineController } from 'config/apiFunus';
import { ArticleStatusRequestDtoEvent, OrderLine } from 'config/apiFunus/generated/data-contracts';
import { OrderDraft } from 'models/OrderDraft';

import { ArticleDraftHook } from './types';

export const useArticleDraft = (
  errorMessage: (message: string) => void,
): ArticleDraftHook => {
  const [articleDraft, setArticleDraft] = useState<OrderDraft | null>(null);

  const getArticleDraft = useCallback(
    (code: number) => orderLineController
      .getArticleDraftInfo(code)
      .then((response) => {
        setArticleDraft(response);

        return response;
      })
      .catch((error) => {
        errorMessage(error.message);

        throw error;
      }),
    [errorMessage],
  );

  const acceptDraft = useCallback(
    (code, data: OrderLine) => orderLineController.updateStatusUsingPut(code, {
      articleDto: {
        articleDescription: data.articleDescription,
        category: data.category,
        channel: data.channel,
        client: data.client,
        code: data.code,
        comments: data.comments,
        date: data.date ? formatDateForRequest(data.date) : undefined,
        dedicatory: data.dedicatory,
        erpId: data.erpId,
        idSupplier: data.idSupplier,
        observations: data.observations,
        price: data.price,
        quantity: data.amount,
        supplier: data.supplier,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        time: data.time as any,
      },
      event: ArticleStatusRequestDtoEvent.MODIFY,
    }),
    [],
  );

  return {
    acceptDraft,
    articleDraft,
    getArticleDraft,
  };
};
