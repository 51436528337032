import React, { FC, useEffect, useState } from 'react';

import { PdfViewer } from 'components/base/PdfViewer';

import { useBudgetPdf } from 'modules/budget/hooks/useBudgetPdf';

import { BudgetPdfDocumentComponent } from './types';

export const BudgetPdfDocument: FC<BudgetPdfDocumentComponent> = ({
  id,
  onLoadSuccess,
}) => {
  const { getDocumentPdf, base64Pdf } = useBudgetPdf();
  const [pdfStream, setPdfStream] = useState<string>();

  useEffect(() => {
    setPdfStream(base64Pdf);
  }, [base64Pdf]);

  useEffect(
    () => {
      if (id) {
        getDocumentPdf(id)
          .catch(() => false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  return (
    <div className="f-budget-pdf-document">
      {pdfStream && <PdfViewer base64={pdfStream} onLoadSuccess={onLoadSuccess} />}
    </div>
  );
};
