import React, {
  FC, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { otherCeremonyTypeCode } from 'modules/budget/data/otherCeremonyTypeCode';
import { useQuestionOptions } from 'modules/budget/hooks/useQuestionOptions';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes } from 'modules/budget/models/Article/types';

import { OptionSelector } from '../../commons/OptionSelector';

interface CeremonyServiceComponent {
  onChange: (id: string | undefined) => void;
  value: string;
}

export const CeremonyTypeSelector:FC<CeremonyServiceComponent> = ({
  onChange, value,
}) => {
  const { t } = useTranslation();
  const { fetchOptions, items } = useQuestionOptions();

  const sel: Article[] = useMemo(
    () => {
      if (value) {
        return [
          new Article({
            amount: 1,
            code: value,
            description: '',
          }),
        ];
      }
      return [];
    },
    [value],
  );

  useEffect(
    () => {
      fetchOptions(BudgetQuestionCodes.Q22, ArticleCollectionTypes.ARTICLE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <OptionSelector
      items={items.map((item) => {
        if (item.code === otherCeremonyTypeCode) {
          return {
            ...item,
            description: t('budget.wizard.otherCeremonyTypes'),
          };
        }
        return item;
      })}
      value={sel}
      onChange={(selection: Article[]) => {
        if (selection.length === 1) {
          onChange(selection[0].code);
        } else {
          onChange(undefined);
        }
      }}
    />
  );
};
