import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatHour } from 'utils/dateManager';

import Datepicker from 'components/base/Datepicker';
import Modal from 'components/base/Modal';

import './index.scss';

type AppoinmentModalProps = {
  show: boolean;
  onHide: () => unknown;
  onAccept: (recordId: string, dateTime: Date | null) => void;
  dateTime: Date | null;
  recordId: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AppointmentModal = ({
  show, onHide, onAccept, dateTime, recordId,
}: AppoinmentModalProps) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | null>(dateTime);
  const [selectedTime, setSelectedTime] = useState<Date | null>(dateTime);

  useEffect(() => { setSelectedDate(dateTime); }, [dateTime]);
  useEffect(() => { setSelectedTime(dateTime); }, [dateTime]);

  const onAcceptClick = useCallback(
    () => {
      const hour = moment.isDate(selectedTime) ? formatHour(selectedTime) : selectedTime;
      const updatedDate = selectedDate ? new Date(`${moment(selectedDate).format('YYYY-MM-DD')}T${hour}:00.000`) : null;

      onAccept(
        recordId,
        updatedDate,
      );
    },
    [selectedDate, selectedTime, onAccept, recordId],
  );

  return (
    <Modal
      buttons={[
        {
          disabled: (!!selectedDate && !selectedTime) || (!selectedDate && !!selectedTime),
          id: 'accept',
          onClick: onAcceptClick,
          text: t('common.accept'),
        },
      ]}
      className="appointment-modal"
      show={show}
      title={t('record.appointment')}
      onHide={onHide}
    >
      <Datepicker
        name="date"
        placeholder={t('common.date')}
        selected={selectedDate}
        clearable
        onChange={(newValue: Date) => setSelectedDate(newValue)}
      />

      <Datepicker
        name="time"
        placeholder={t('common.hour')}
        selected={selectedTime}
        clearable
        showTimeSelectOnly
        onChange={(newValue: Date) => setSelectedTime(newValue)}
      />
    </Modal>
  );
};
