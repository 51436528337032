import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GravestoneInfo } from 'models/OrderInfo';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const Gravestone: FC<DefaultInfoProps> = ({ expanded, loading, order }) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const info: GravestoneInfo = order.info as GravestoneInfo;

    const newDetails: DetailType[] = [
      {
        text: t(''), // TODO
        value: info?.design,
      },
      {
        text: t(''), // TODO
        value: info?.destination,
      },
    ];
    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default Gravestone;
