import {
  Form, Formik, FormikProps,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  object, string,
} from 'yup';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Button from 'components/base/Button';
import InputQRScan from 'components/base/InputQRScan';
import Modal from 'components/base/Modal';

import Workorder from 'models/Workorder';

type AddDeceasedQRModalProps = {
  show: boolean;
  order: Workorder;
  onHide: () => void
  onSuccess: () => void
}

type AddDeceasedQRForm = {info: {deceasedQr?: string}}

export const AddDeceasedQRModal: FC<AddDeceasedQRModalProps> = (
  {
    show, onHide, onSuccess, order,
  },
) => {
  const { t } = useTranslation();
  const addDeceasedQrSchema = object().shape({
    info: object().shape({
      deceasedQr: string().required(),
    }),
  });

  const update = (
    params: AddDeceasedQRForm,
  ) => {
    config.apiFunus.workOrder
      .changeStatus(order.id, {
        ...order,
        changeState: false,
        id: order.id,
        info: {
          ...order.info,
          deceasedQr: params.info.deceasedQr,
        },
        infoQr: order.infoQr || {},
        status: order.status,
        type: order.type,
      })
      .then(() => {
        showSuccessToast(t('order.addDeceasedQROK'));
        onSuccess();
        return true;
      })
      .catch(() => {
        showErrorToast(t('order.addDeceasedQRKO'));
      });
  };

  return (
    <Modal
      className="workorder-card-modal"
      show={show}
      showCancel={false}
      title={t('order.addDeceasedQR')}
      onHide={onHide}
    >
      <Formik
        initialValues={{
          info: {
            deceasedQr: order?.info?.deceasedQr,
          },
        }}
        validationSchema={addDeceasedQrSchema}
        validateOnMount
        onSubmit={update}
      >
        {({
          setFieldValue, values, isValid,
        }: FormikProps<AddDeceasedQRForm>) => (
          <Form autoComplete="off">
            <InputQRScan
              data={values.info.deceasedQr || ''}
              placeholder={t('order.qr.deceasedQr')}
              setData={(val) => {
                setFieldValue('info.deceasedQr', val);
              }}
            />
            <Button
              disabled={!isValid}
              id="accept"
              text={t('common.accept')}
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
