import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, {
  FC, Fragment, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDate } from 'utils/dateManager';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import Datepicker from 'components/base/Datepicker';
import Input from 'components/base/Input';
import Select from 'components/base/Select';
import SelectButton from 'components/base/SelectButton';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Table from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';
import Textarea from 'components/base/Textarea';

import { i18n } from 'i18n';
import { CityType, IMasterDataDto } from 'models/MasterData';
import { Flight, SaveService, TransferType } from 'models/MasterService';
import { DefaultSelectType } from 'models/Others';
import { SaveRecord } from 'models/Record';
import { RootState } from 'store';
import './index.scss';

type TransferProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
  record?: SaveRecord;
};

const options: DefaultSelectType[] = Object.values(TransferType).map(
  (value) => ({
    label: i18n.t(`service.transfer.types.${value}`),
    value,
  }),
);

const TransferForm: FC<TransferProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const [disableForm, setDisableForm] = useState(true);
  const [fields, setFields] = useState<Flight>({});
  const [selected, setSelected] = useState<number>();
  const { t } = useTranslation();
  const { airports } = useSelector((state: RootState) => state.masterData);
  const { normalized } = useSelector((state: RootState) => state);
  const flightCols = useMemo(
    (): TableColumn<Flight>[] => [
      {
        accessor: 'departureAirport',
        Cell: ({ cell: { value } }) => (value ? normalized.airports[value]?.description : ''),
        className: 'primary-dark-color',
        Header: `${t('service.transfer.departureAirport')}`,
      },
      {
        accessor: 'deliveryDate',
        Cell: ({ cell: { value } }) => (value ? formatDate(value as Date) : ''),
        Header: `${t('service.transfer.deliveryDate')}`,
      },
      {
        accessor: 'deliveryTime',
        Header: `${t('service.transfer.deliveryTime')}`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const changeType = (val: DefaultSelectType) => {
    setFieldValue('transferServiceDto.type', val?.value);
    if (val.value === TransferType.CAR) {
      setFieldValue('transferServiceDto.planeTransport', undefined);
    } else {
      setFieldValue('transferServiceDto.roadTransport', undefined);
    }
  };

  const isByCar = values.transferServiceDto?.type === TransferType.CAR;

  const getTransferValue = () => {
    if (values.transferServiceDto?.type === TransferType.CAR) {
      return options[0];
    }
    return options[1];
  };

  const clear = () => {
    setFields({});
    setSelected(undefined);
  };

  const save = () => {
    // TODO: validate
    const newFlights = values.transferServiceDto?.planeTransport?.flights
      ?.length
      ? [...values.transferServiceDto?.planeTransport?.flights]
      : [];
    if (selected === 0 || selected) {
      newFlights[selected] = fields;
    } else {
      newFlights.push(fields);
    }
    setFieldValue('transferServiceDto.planeTransport.flights', newFlights);
    clear();
  };

  const removeFlight = (row: Flight, idx: number) => {
    const newFlights = values.transferServiceDto?.planeTransport?.flights
      ? [...values.transferServiceDto?.planeTransport?.flights]
      : [];
    newFlights.splice(idx, 1);
    setFieldValue('transferServiceDto.planeTransport.flights', newFlights);
  };

  const changeHour = (val: Date) => {
    if (
      !values.ceremonyServiceDto?.ceremonyTime
      && values.vigilServiceDto
      && Object.keys(values.vigilServiceDto).length > 0
    ) {
      setFieldValue('vigilServiceDto.exitTime', val);
    }
  };

  const changeDate = (val: Date) => {
    if (
      !values.ceremonyServiceDto?.ceremonyTime
      && values.vigilServiceDto
      && Object.keys(values.vigilServiceDto).length > 0
    ) {
      setFieldValue('vigilServiceDto.exitDate', val);
    }
  };

  useEffect(() => {
    setDisableForm(!Object.values(fields).some((val) => !!val));
  }, [fields]);

  return (
    <div className={classNames('transfer-form', className)}>
      <fieldset>
        <legend>{t('service.recipient.title')}</legend>
        <Input
          className="full-width"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'transferServiceDto.recipientName')}
          name="transferServiceDto.recipientName"
          placeholder={t('service.recipient.name')}
          type="text"
          value={values.transferServiceDto?.recipientName || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('transferServiceDto.recipientName', val)}
        />

        <SelectCountryProvinceCity
          cityProps={{
            error:
              getIn(errors, 'transferServiceDto.recipientAddress.city')
              || (typeof getIn(errors, 'transferServiceDto.recipientAddress')
                !== 'object'
                && getIn(errors, 'transferServiceDto.recipientAddress')),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'transferServiceDto.recipientAddress.c',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('transferServiceDto.recipientAddress.city', val),
            onChangeFull: (val?: CityType) => setFieldValue(
              'transferServiceDto.recipientAddress.postalCode',
              val?.postalCode,
            ),
            placeholder: t('common.city'),
            value: values.transferServiceDto?.recipientAddress?.city || '',
          }}
          countryProps={{
            error:
              getIn(errors, 'transferServiceDto.recipientAddress.country')
              || (typeof getIn(errors, 'transferServiceDto.recipientAddress')
                !== 'object'
                && getIn(errors, 'transferServiceDto.recipientAddress')),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'transferServiceDto.recipientAddress.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('transferServiceDto.recipientAddress.country', val),
            placeholder: t('common.country'),
            value: values.transferServiceDto?.recipientAddress?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error:
              getIn(errors, 'transferServiceDto.recipientAddress.province')
              || (typeof getIn(errors, 'transferServiceDto.recipientAddress')
                !== 'object'
                && getIn(errors, 'transferServiceDto.recipientAddress')),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'transferServiceDto.recipientAddress.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'transferServiceDto.recipientAddress.province',
              val,
            ),
            placeholder: t('common.province'),
            value: values.transferServiceDto?.recipientAddress?.province || '',
          }}
        />
        <Textarea
          disabled={disabled || isSubmitting}
          error={
            getIn(errors, 'transferServiceDto.recipientAddress.streetName')
            || (typeof getIn(errors, 'transferServiceDto.recipientAddress')
              !== 'object'
              && getIn(errors, 'transferServiceDto.recipientAddress'))
          }
          name="transferServiceDto.recipientAddress.streetName"
          placeholder={t('common.address')}
          value={values.transferServiceDto?.recipientAddress?.streetName || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('transferServiceDto.recipientAddress.streetName', val)}
        />
        <Input
          disabled={disabled || isSubmitting}
          error={
            getIn(errors, 'transferServiceDto.recipientAddress.postalCode')
            || (typeof getIn(errors, 'transferServiceDto.recipientAddress')
              !== 'object'
              && getIn(errors, 'transferServiceDto.recipientAddress'))
          }
          name="transferServiceDto.recipientAddress.postalCode"
          placeholder={t('common.postalCode')}
          type="text"
          value={values.transferServiceDto?.recipientAddress?.postalCode || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('transferServiceDto.recipientAddress.postalCode', val)}
        />
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'transferServiceDto.recipientPhone')}
          name="transferServiceDto.recipientPhone"
          placeholder={t('common.phone')}
          type="text"
          value={values.transferServiceDto?.recipientPhone || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('transferServiceDto.recipientPhone', val)}
        />
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'transferServiceDto.recipientEmail')}
          name="transferServiceDto.recipientEmail"
          placeholder={t('common.email')}
          type="text"
          value={values.transferServiceDto?.recipientEmail || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('transferServiceDto.recipientEmail', val)}
        />
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'transferServiceDto.comment')}
          name="transferServiceDto.comment"
          placeholder={t('common.observations')}
          value={values.transferServiceDto?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('transferServiceDto.comment', val)}
        />
      </fieldset>
      <fieldset>
        <legend>{t('service.TRANSPORTE')}</legend>
        <SelectButton<DefaultSelectType>
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'transferServiceDto.type')}
          getLabel={({ label }) => label}
          getValue={({ value }) => value}
          options={options}
          placeholder={t('service.transfer.type')}
          value={
            values.transferServiceDto?.type ? getTransferValue() : undefined
          }
          onChange={changeType}
        />
        {values.transferServiceDto?.type && isByCar && (
          <Fragment>
            <Input
              disabled={disabled || isSubmitting}
              error={getIn(
                errors,
                'transferServiceDto.roadTransport.destination',
              )}
              name="transferServiceDto.roadTransport.destination"
              placeholder={t('service.transfer.destination')}
              type="text"
              value={
                values.transferServiceDto?.roadTransport?.destination || ''
              }
              onBlur={handleBlur}
              onChange={(val) => setFieldValue(
                'transferServiceDto.roadTransport.destination',
                val,
              )}
            />
            <Input
              disabled={disabled || isSubmitting}
              error={getIn(
                errors,
                'transferServiceDto.roadTransport.borderCrossing',
              )}
              name="transferServiceDto.roadTransport.borderCrossing"
              placeholder={t('service.transfer.borderCrossing')}
              type="text"
              value={
                values.transferServiceDto?.roadTransport?.borderCrossing || ''
              }
              onBlur={handleBlur}
              onChange={(val) => setFieldValue(
                'transferServiceDto.roadTransport.borderCrossing',
                val,
              )}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={getIn(errors, 'transferServiceDto.roadTransport.exitDate')}
              minDate={
                record?.deathData?.deathDate
                && new Date(record.deathData.deathDate)
              }
              name="transferServiceDto.roadTransport.exitDate"
              placeholder={t('common.exitDate')}
              selected={values.transferServiceDto?.roadTransport?.exitDate}
              onBlur={handleBlur}
              onChange={(val: Date) => {
                setFieldValue('transferServiceDto.roadTransport.exitDate', val);
                changeDate(val);
              }}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={getIn(errors, 'transferServiceDto.roadTransport.exitTime')}
              name="transferServiceDto.roadTransport.exitTime"
              placeholder={t('common.exitTime')}
              selected={values.transferServiceDto?.roadTransport?.exitTime}
              showTimeSelectOnly
              onBlur={handleBlur}
              onChange={(val: Date) => {
                setFieldValue('transferServiceDto.roadTransport.exitTime', val);
                changeHour(val);
              }}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={getIn(
                errors,
                'transferServiceDto.roadTransport.estimatedArrivalDate',
              )}
              minDate={
                values.transferServiceDto?.roadTransport?.exitDate
                && new Date(values.transferServiceDto?.roadTransport?.exitDate)
              }
              name="transferServiceDto.roadTransport.estimatedArrivalDate"
              placeholder={t('service.transfer.estimatedArrivalDate')}
              selected={
                values.transferServiceDto?.roadTransport?.estimatedArrivalDate
              }
              onBlur={handleBlur}
              onChange={(val) => setFieldValue(
                'transferServiceDto.roadTransport.estimatedArrivalDate',
                val,
              )}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={getIn(
                errors,
                'transferServiceDto.roadTransport.estimatedArrivalTime',
              )}
              name="transferServiceDto.roadTransport.estimatedArrivalTime"
              placeholder={t('service.transfer.estimatedArrivalTime')}
              selected={
                values.transferServiceDto?.roadTransport?.estimatedArrivalTime
              }
              showTimeSelectOnly
              onBlur={handleBlur}
              onChange={(val) => setFieldValue(
                'transferServiceDto.roadTransport.estimatedArrivalTime',
                val,
              )}
            />
          </Fragment>
        )}
        {values.transferServiceDto?.type && !isByCar && (
          <Fragment>
            {/* TODO: Handle errors */}
            <Input
              disabled={disabled || isSubmitting}
              error={getIn(errors, 'transferServiceDto.planeTransport.weight')}
              name="transferServiceDto.planeTransport.weight"
              placeholder={t('service.transfer.weight')}
              type="text"
              value={values.transferServiceDto?.planeTransport?.weight || ''}
              onBlur={handleBlur}
              onChange={(val) => setFieldValue('transferServiceDto.planeTransport.weight', val)}
            />
            <Input
              disabled={disabled || isSubmitting}
              error={getIn(
                errors,
                'transferServiceDto.planeTransport.measures',
              )}
              name="transferServiceDto.planeTransport.measures"
              placeholder={t('service.transfer.measures')}
              type="text"
              value={values.transferServiceDto?.planeTransport?.measures || ''}
              onBlur={handleBlur}
              onChange={(val) => setFieldValue('transferServiceDto.planeTransport.measures', val)}
            />
            <Select<IMasterDataDto>
              key="planeTransportType"
              disabled={isSubmitting || disabled}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].departureAirport`,
                )
              }
              getLabel={({ description }) => description}
              getValue={({ code }) => code}
              name="planeTransportType"
              options={airports}
              placeholder={t('service.transfer.departureAirport')}
              value={
                fields.departureAirport
                  ? normalized.airports[fields.departureAirport]
                  : undefined
              }
              searchable
              onBlur={handleBlur}
              onChange={(val) => setFields({ ...fields, departureAirport: val?.code })}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].departureDate`,
                )
              }
              minDate={
                record?.deathData?.deathDate
                && new Date(record.deathData.deathDate)
              }
              name="transferServiceDto.planeTransport.departureDate"
              placeholder={t('service.transfer.departureDate')}
              selected={fields.departureDate}
              onBlur={handleBlur}
              onChange={(val: Date) => {
                if (!selected) {
                  changeDate(val);
                }
                setFields({ ...fields, departureDate: val });
              }}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].departureTime`,
                )
              }
              name="transferServiceDto.planeTransport.departureTime"
              placeholder={t('service.transfer.departureTime')}
              selected={fields.departureTime}
              showTimeSelectOnly
              onBlur={handleBlur}
              onChange={(val: Date) => {
                if (!selected) {
                  changeHour(val);
                }
                setFields({ ...fields, departureTime: val });
              }}
            />
            <Datepicker
              disabled={disabled || isSubmitting || !fields?.departureDate}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].deliveryDate`,
                )
              }
              minDate={fields?.departureDate && new Date(fields?.departureDate)}
              name="transferServiceDto.planeTransport.deliveryDate"
              placeholder={t('service.transfer.deliveryDate')}
              selected={fields.deliveryDate}
              onBlur={handleBlur}
              onChange={(val: Date) => setFields({ ...fields, deliveryDate: val })}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].deliveryTime`,
                )
              }
              name="transferServiceDto.planeTransport.deliveryTime"
              placeholder={t('service.transfer.deliveryTime')}
              selected={fields.deliveryTime}
              showTimeSelectOnly
              onBlur={handleBlur}
              onChange={(val: Date) => setFields({ ...fields, deliveryTime: val })}
            />
            <Input
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].agency`,
                )
              }
              name="transferServiceDto.planeTransport.agency"
              placeholder={t('service.transfer.agency')}
              type="text"
              value={fields.agency || ''}
              onBlur={handleBlur}
              onChange={(val: string) => setFields({ ...fields, agency: val })}
            />
            <Input
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].connectionNumber`,
                )
              }
              name="transferServiceDto.planeTransport.connectionNumber"
              placeholder={t('service.transfer.connectionNumber')}
              type="text"
              value={fields.connectionNumber || ''}
              onBlur={handleBlur}
              onChange={(val: string) => setFields({ ...fields, connectionNumber: val })}
            />
            <Input
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].company`,
                )
              }
              name="transferServiceDto.planeTransport.company"
              placeholder={t('service.transfer.company')}
              type="text"
              value={fields.company || ''}
              onBlur={handleBlur}
              onChange={(val: string) => setFields({ ...fields, company: val })}
            />
            <Input
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].flightNumber`,
                )
              }
              name="transferServiceDto.planeTransport.flightNumber"
              placeholder={t('service.transfer.flightNumber')}
              type="text"
              value={fields.flightNumber || ''}
              onBlur={handleBlur}
              onChange={(val: string) => setFields({ ...fields, flightNumber: val })}
            />
            <Select<IMasterDataDto>
              key="planeTransportType"
              disabled={isSubmitting || disabled}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].destinationAirport`,
                )
              }
              getLabel={({ description }) => description}
              getValue={({ code }) => code}
              name="planeTransportType"
              options={airports}
              placeholder={t('service.transfer.destinationAirport')}
              value={
                fields.destinationAirport
                  ? normalized.airports[fields.destinationAirport]
                  : undefined
              }
              searchable
              onBlur={handleBlur}
              onChange={(val) => setFields({ ...fields, destinationAirport: val?.code })}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].arrivalDate`,
                )
              }
              name="transferServiceDto.planeTransport.arrivalDate"
              placeholder={t('service.transfer.arrivalDate')}
              selected={fields.arrivalDate}
              onBlur={handleBlur}
              onChange={(val: Date) => setFields({ ...fields, arrivalDate: val })}
            />
            <Datepicker
              disabled={disabled || isSubmitting}
              error={
                selected !== undefined
                && selected > -1
                && getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${selected}].arrivalTime`,
                )
              }
              name="transferServiceDto.planeTransport.arrivalTime"
              placeholder={t('service.transfer.arrivalTime')}
              selected={fields.arrivalTime}
              showTimeSelectOnly
              onBlur={handleBlur}
              onChange={(val: Date) => setFields({ ...fields, arrivalTime: val })}
            />
            <div className="transfer-buttons">
              <Button
                disabled={disabled || disableForm}
                text={t('common.add')}
                onClick={save}
              />
              <Button
                color="secondary"
                disabled={disabled || disableForm}
                text={t('common.clear')}
                onClick={clear}
              />
            </div>
            <Table<Flight>
              actions={(row: Flight, idx: number) => ({
                edit: {
                  icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
                  onClick: () => {
                    setFields(row);
                    setSelected(idx);
                  },
                  tooltipCaption: t('common.edit'),
                },
                remove: {
                  icon: <CustomIcon icon={FeatherIconTypes.TRASH} />,
                  onClick: () => removeFlight(row, idx),
                  tooltipCaption: t('common.remove'),
                },
              })}
              columns={flightCols}
              data={values.transferServiceDto?.planeTransport?.flights || []}
              pagination={false}
              rowClassName={(index) => classNames({
                error: getIn(
                  errors,
                  `transferServiceDto.planeTransport.flights[${index}]`,
                ),
              })}
            />
          </Fragment>
        )}
      </fieldset>
    </div>
  );
};

export default TransferForm;
