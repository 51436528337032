import { TableColumn } from 'components/base/Table/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BudgetsTrackingColumn = TableColumn<any>
export type BudgetsTrackingFilter = {
  deceasedFirstSurname?: string;
  deceasedName?: string;
  deceasedSecondSurname?: string;
  erpId?: number;
  id?: number;
  status?: BudgetsTrackingFilterStatus;
  processor?: string;
};
export enum BudgetsTrackingFilterStatus {
  CANCELLED = 'CANCELLED',
  INVOICED = 'INVOICED',
  PENDING = 'PENDING',
  PRE_ACCEPTED = 'PRE_ACCEPTED',
  SIGNED = 'SIGNED'
}
