const ia = {
  back: 'Part posterior',
  birthAddress: 'Dirección de nacimiento',
  both: 'Parte delantera y trasera',
  firstField: 'Ciudad/Provincia',
  front: 'Part davantera',
  parents: 'Padres',
  reviewData: 'Revise los datos y confirme',
  scanDocument: 'Escanear documento',
  scanningDocument: 'Escaneando documento',
  secondField: 'Provincia/País',
  streetNumber: 'Número',
  takePhoto: 'Hacer fotografía con webcam',
  uploadFileOne: 'Subir un solo fichero',
  uploadFileQuestion: '¿Cuantos ficheros quiere subir?',
  uploadFiles: 'Subir archivos',
  uploadFileTwo: 'Subir dos ficheros',
};
export default ia;
