import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { isBooleanAndFalse } from 'utils/helpers';

import { Row } from 'components/base/Row';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useBudget } from 'modules/budget/hooks/useBudget';
import { Address } from 'modules/budget/models/Address';
import { ILocation } from 'modules/budget/models/Location/types';

import { AffectedQuestions } from '../../commons/AffectedQuestions';
import { QBackButton } from '../../commons/QBackButton';
import { QConfirmButton } from '../../commons/QConfirmButton';
import { QHeader } from '../../commons/QHeader';

import { Q3Form, Q3Response } from './form';

export const Q3: FC<Record<string, unknown>> = () => {
  const question = BudgetQuestionCodes.Q3;
  const { RespondQuestion } = useBudget();

  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
  const [atHome, setAtHome] = useState<boolean>();
  const [canRespond, setCanRespond] = useState<boolean>(false);

  useEffect(
    () => {
      const fullFilled = selectedAddress?.isFullFilled() || false;
      if (atHome) {
        setCanRespond(fullFilled);
      } else if (isBooleanAndFalse(atHome)) {
        setCanRespond(fullFilled && Boolean(selectedLocation));
      } else {
        setCanRespond(false);
      }
    },
    [atHome, selectedAddress, selectedLocation],
  );

  const response = useCallback(
    ({ gotoSummary }) => {
      if (canRespond && selectedAddress) {
        RespondQuestion({
          gotoSummary,
          question,
          response: {
            address: selectedAddress,
            atHome,
            location: selectedLocation,
          },
        });
      }
    },
    [RespondQuestion, atHome, canRespond, question, selectedAddress, selectedLocation],
  );

  const onChange = useCallback(
    (responseData: Q3Response): void => {
      setSelectedAddress(responseData.address);
      setSelectedLocation(responseData.location);
      setAtHome(responseData.atHome);
    },
    [],
  );

  return (
    <React.Fragment>
      <QHeader question={question} />
      <Q3Form
        question={BudgetQuestionCodes.Q3}
        onChange={onChange}
      />
      <Row justifyContent="space-between">
        <QBackButton question={question} />
        <Row
          className="f-question-actions"
          justifyContent="flex-end"
        >
          <AffectedQuestions
            question={question}
            response={{
              address: selectedAddress,
              location: selectedLocation,
            }}
          />
          <QConfirmButton
            disabled={!canRespond}
            question={question}
            onClick={(payload) => response({ gotoSummary: payload?.gotoSummary })}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};
