import { normalizeFunction } from 'utils/normalizer';

import { MasterDataAction } from 'actions/masterData';

import { CityType, ProvinceType } from 'models/MasterData';
import {
  MasterAndNormalized,
  MasterAndNormalizedType,
  NormalizedEntity,
  NormalizedType,
} from 'models/MasterService';
import { InsuranceCompany } from 'models/Record';
import { masterDataTypes } from 'types/masterData';

export interface NormalizedReducer {
  abortionTypes: NormalizedType;
  airports: NormalizedType;
  ashesDestinations: NormalizedType;
  centers: NormalizedType;
  ceremonies: NormalizedType;
  cities: NormalizedEntity<CityType>;
  concessionTypes: NormalizedType;
  countries: NormalizedType;
  deathTypes: NormalizedType;
  funeraryOrigins: NormalizedType;
  genders: NormalizedType;
  groupCauses: NormalizedType;
  insurances: NormalizedEntity<InsuranceCompany>;
  maritalStatus: NormalizedType;
  nicheTypes: NormalizedType;
  owners: NormalizedType;
  provinces: NormalizedEntity<ProvinceType>;
  recordTypes: NormalizedType;
  reliquaries: NormalizedType;
  rehydrated: boolean;
  sanitaryPractices: NormalizedType;
  thanatologists: NormalizedType;
  thanatopractors: NormalizedType;
  urns: NormalizedType;
}

export const initState: NormalizedReducer = {
  abortionTypes: {},
  airports: {},
  ashesDestinations: {},
  centers: {},
  ceremonies: {},
  cities: {},
  concessionTypes: {},
  countries: {},
  deathTypes: {},
  funeraryOrigins: {},
  genders: {},
  groupCauses: {},
  insurances: {},
  maritalStatus: {},
  nicheTypes: {},
  owners: {},
  provinces: {},
  recordTypes: {},
  reliquaries: {},
  rehydrated: false,
  sanitaryPractices: {},
  thanatologists: {},
  thanatopractors: {},
  urns: {},
};

const getState = (
  state: NormalizedReducer = initState,
  action: MasterDataAction,
) => {
  if (!action) {
    return {};
  }
  const act: MasterDataAction = { ...action, payload: action.payload || {} };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (act.key === 'normalized' && (act.payload as any)?.cities) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (act.payload as any).cities;
  }
  const est = act.key === 'normalized'
    ? {
      ...state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(act.payload as any),
      cities: state.cities,
      rehydrated: true,
    }
    : {
      ...state,
      cities: normalizeFunction(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (act.payload as any).cities,
        (city: CityType) => `${city.countryCode}${city.provinceCode}${city.code}`,
      ),
    };
  return est;
};

export default (
  state: NormalizedReducer = initState,
  action: MasterDataAction,
): NormalizedReducer => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return getState(state, action);
    case masterDataTypes.CLEAR_MASTERDATA:
      return { ...initState };
    case masterDataTypes.ADD_MASTER_DATA_AIRPORTS:
      return {
        ...state,
        airports: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_CENTERS:
      return {
        ...state,
        centers: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_CEREMONIES:
      return {
        ...state,
        ceremonies: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_COUNTRIES:
      return {
        ...state,
        countries: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_RECORD_TYPES:
      return {
        ...state,
        recordTypes: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_DEATH_TYPES:
      return {
        ...state,
        deathTypes: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_FUNERARY_ORIGINS:
      return {
        ...state,
        funeraryOrigins: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_GENDERS:
      return {
        ...state,
        genders: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_INSURANCES:
      return {
        ...state,
        insurances: (
          action.payload as MasterAndNormalizedType<InsuranceCompany>
        ).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_ABORTION_TYPES:
      return {
        ...state,
        abortionTypes: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_GROUP_CAUSES:
      return {
        ...state,
        groupCauses: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_OWNERS:
      return {
        ...state,
        owners: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_RELIQUARYS:
      return {
        ...state,
        reliquaries: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_URNS:
      return {
        ...state,
        urns: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_SANITARY_PRACTICES:
      return {
        ...state,
        sanitaryPractices: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_ASHES_DESTINATIONS:
      return {
        ...state,
        ashesDestinations: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_CONCESSION_TYPES:
      return {
        ...state,
        concessionTypes: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_NICHE_TYPES:
      return {
        ...state,
        nicheTypes: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_PROVINCES:
      return {
        ...state,
        provinces: (action.payload as MasterAndNormalizedType<ProvinceType>)
          .normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_CITIES:
      return {
        ...state,
        cities: (action.payload as MasterAndNormalizedType<CityType>)
          .normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_MARITAL_STATUS:
      return {
        ...state,
        maritalStatus: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_THANATOLOGISTS:
      return {
        ...state,
        thanatologists: (action.payload as MasterAndNormalized).normalized,
      };
    case masterDataTypes.ADD_MASTER_DATA_THANATOPRACTORS:
      return {
        ...state,
        thanatopractors: (action.payload as MasterAndNormalized).normalized,
      };

    default:
      return state;
  }
};
